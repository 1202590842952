import jwtDecode from 'jwt-decode';

export interface TokenData {
  id: number;
  mongo_id: string;
  email: string;
  name: string | null;
  phone: string | null;
  is_active: string | null;
  role: {
    id: number;
    name: string;
    description: string;
  };
  organization: {
    id: number;
    name: string;
    country: string;
  };
  permissions: [string];
  modules: [string];
  iat: number;
  exp: number;
  country: string;
  banner?: boolean;
  klaim_super_admin?: boolean;
}

export const decodeJWT = (token: string): TokenData => {
  return jwtDecode(token);
};
