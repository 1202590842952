import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {ErxData, ErxSearchQuery, ErxInsurersList, ErxPayerClassification, ErxError, InitialState} from './types';

export const getErxList = createAsyncThunk<ErxData, ErxSearchQuery, {rejectValue: ErxError}>(
  'erxList/getErxList',
  async (query: ErxSearchQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/erx/list`, {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPayersFromInsurers = createAsyncThunk<ErxInsurersList, ErxPayerClassification, {rejectValue: ErxError}>(
  'erxList/getPayersFromInsurers',
  async (query: ErxPayerClassification, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/payers`, {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: InitialState = {
  data: {
    count: 0,
    data: [],
  },
  dataInsurers: {
    data: [],
  },
  isLoading: false,
  error: undefined,
};

const erxListSlice = createSlice({
  name: 'erxList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getErxList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getErxList.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getErxList.fulfilled, (state, action) => {
        state.data.data = action.payload.data;
        state.data.count = action.payload.count;
        state.isLoading = false;
      });
    builder
      .addCase(getPayersFromInsurers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPayersFromInsurers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPayersFromInsurers.fulfilled, (state, action) => {
        state.dataInsurers.data = action.payload.data;
        state.isLoading = false;
      });
  },
});

export const erxListSelect = (state: RootState) => state.pharmacy.erxList;
export default erxListSlice.reducer;
