import React from 'react';
import dayjs from 'dayjs';
import {set as setStorage, get as getStorage} from 'local-storage';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import KlaimCalendar from 'modules/Common/components/KlaimCalendar';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {headerSelect, setDateRange} from 'modules/Header/headerSlice';
import callendarIcon from 'assets/calendar_icon.svg';
import {useLocation} from 'react-router';

interface ComponentProps {
  showDescription: boolean;
  dateRangePicker?: boolean;
  showMonthYearPicker?: boolean;
  formatMonth?: boolean;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  title?: string;
}

const MONTH_FORMAT = 'MMM/YYYY';
const DAY_FORMAT = 'YYYY-MM-DD';
const DATE_RANGE = 'dateRange';

const DateRange: React.FC<ComponentProps> = ({
  showDescription = true,
  dateRangePicker = false,
  showMonthYearPicker = false,
  title = 'Filter by Date Range',
  minDate,
  maxDate,
  formatMonth,
  className,
}) => {
  const dispatch = useAppDispatch();
  const {startDate, endDate} = useAppSelector(headerSelect);
  const [dateRangeValue, setDateRangeValue] = React.useState<string>('');
  const [dateValue] = React.useState<Date[]>([new Date(new Date().getFullYear(), 0, 1), new Date()]);
  const location = useLocation();

  const formatDate = (date: Date) => {
    if (showMonthYearPicker) {
      return dayjs(date).format(MONTH_FORMAT);
    }
    return dayjs(date).format(DAY_FORMAT);
  };

  React.useEffect(() => {
    const localDateRange = JSON.parse(getStorage(DATE_RANGE) || '[]');
    if (localDateRange.length < 1) {
      const [start, end] = dateValue;
      const startDateFormat = formatDate(start);
      const endDateFormat = end !== null ? formatDate(end) : '';
      dispatch(
        setDateRange({
          start: startDateFormat,
          end: end !== null ? endDateFormat : '',
        }),
      );
      onDateChange(startDateFormat, endDateFormat);
    } else {
      const [start, end] = localDateRange;
      const startDateFormat = formatDate(start);
      const endDateFormat = end !== null ? formatDate(end) : '';
      dispatch(
        setDateRange({
          start: startDateFormat,
          end: end !== null ? endDateFormat : '',
        }),
      );
      onDateChange(startDateFormat, endDateFormat);
    }
  }, []);

  const onDateChange = (start: string, end: string) => {
    if (dateRangePicker) {
      setDateRangeValue(`${start} - ${end}`);
    } else {
      setDateRangeValue(`${start}`);
    }
    setStorage(DATE_RANGE, JSON.stringify([start, end]));
  };

  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    const startDateFormat = formatDate(start);
    const endDateFormat = end !== null ? formatDate(end) : '';
    if (start && end) {
      dispatch(
        setDateRange({
          start,
          end,
        }),
      );
      onDateChange(startDateFormat, endDateFormat);
      setStorage(DATE_RANGE, JSON.stringify([startDateFormat, endDateFormat]));
    }
  };

  return (
    <KlaimDropdownContainer
      showDescription={showDescription}
      title={title}
      leftIcon={callendarIcon}
      value={dateRangeValue}
      className={className}
    >
      {title && title}
      <KlaimCalendar
        value={startDate && endDate ? [new Date(startDate), new Date(endDate)] : dateValue}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={endDate ? new Date(endDate) : undefined}
        onChange={dateRangeChange}
        selectsRange={dateRangePicker}
        showMonthYearPicker={showMonthYearPicker}
        minDate={minDate}
        maxDate={maxDate}
      />
    </KlaimDropdownContainer>
  );
};

export default DateRange;
