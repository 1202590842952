import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {toast, removeToast} from 'modules/Common/components/KlaimToast/toastSlice';
import errorIcon from 'assets/notification_error.svg';
import successIcon from 'assets/notification_success.svg';
import warningIcon from 'assets/notification_warning.svg';
import infoIcon from 'assets/notification_info.svg';
import closeIcon from 'assets/notification_close.svg';
import downloadIcon from 'assets/notification_download.svg';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    '@keyframes slideRight': {
      from: {right: '-100%'},
      to: {right: '0%'},
    },
    toastContainer: {
      position: 'fixed',
      bottom: '10px',
      right: '10px',
      zIndex: 1001,
      width: 450,
    },
    toastMsg: {
      margin: 5,
      position: 'relative',
      width: 'calc(100% - 80px)',
    },
    toastDrawer: {
      width: '100%',
      background: Colors.white,
      boxShadow: `${Colors.RMSC.toastShadow} 1px 1px 3px`,
      padding: '20px 30px',
      borderRadius: '10px',
      textAlign: 'left',
      position: 'absolute',
      animationName: '$slideRight',
      animationDuration: '0.5s',
    },
    imgBox: {
      display: 'inline-block',
      width: 50,
      verticalAlign: 'middle',
    },
    textBox: {
      display: 'inline-block',
      width: 'calc(100% - 115px)',
      verticalAlign: 'middle',
      marginLeft: 15,
    },
    xBox: {
      paddingTop: 10,
      display: 'inline-block',
      width: '30px',
      verticalAlign: 'middle',
      marginLeft: 15,
      cursor: 'pointer',
    },
    title: {
      fontSize: '16px',
      fontWeight: 'Bold',
      margin: '0',
      color: Colors.blackLight,
    },
    message: {
      fontSize: '13px',
      margin: '0',
      color: Colors.grayLighter,
    },
  };
});

interface ComponentProps {
  overrideContent?: any | undefined;
}

export const KlaimToast: FC<ComponentProps> = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {toasts} = useAppSelector(toast);

  const remove = (id: string) => {
    dispatch(removeToast(id));
  };

  const cards = toasts.map((item, i) => {
    let ic = successIcon;
    if (item.variant === 'error') ic = errorIcon;
    if (item.variant === 'warning') ic = warningIcon;
    if (item.variant === 'info') ic = infoIcon;
    if (item.variant === 'download') ic = downloadIcon;
    return (
      <div key={`toast_${item.id}`} className={classes.toastMsg} style={{bottom: (i + 1) * 120}}>
        <div className={classes.toastDrawer}>
          <img alt="logo" src={ic} className={classes.imgBox} />
          <div className={classes.textBox}>
            <div className={classes.title}>{item.title}</div>
            <div className={classes.message}>{item.message}</div>
          </div>
          <div role="button" className={classes.xBox} onClick={() => remove(item.id)}>
            <img width="30" alt="close" src={closeIcon} />
          </div>
        </div>
      </div>
    );
  });

  return <div className={classes.toastContainer}>{cards}</div>;
};
