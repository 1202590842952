import React from 'react';
import KlaimContainer from '../../../KlaimContainer';

interface ComponentProps {
  variant?: 'primary' | 'secondary' | 'primary-outline';
  style?: React.CSSProperties;
}

export interface ICollapsible {
  isCollapse: boolean;
  setIsCollapse: (isCollapse: boolean) => void;
}

export const CollapsibleContext = React.createContext<ICollapsible>({
  isCollapse: true,
  setIsCollapse: () => {},
});

const KlaimCollapsible: React.FC<ComponentProps> = ({children, variant, style}) => {
  const [isCollapse, setIsCollapse] = React.useState<boolean>(true);
  return (
    <KlaimContainer variant={variant} style={style}>
      <CollapsibleContext.Provider value={{isCollapse, setIsCollapse}}>{children}</CollapsibleContext.Provider>
    </KlaimContainer>
  );
};

export default KlaimCollapsible;
