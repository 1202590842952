import React from 'react';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Common/components-v2/KlaimModalv2/components/KlaimModalFooter/KlaimModalFooter';
import {ModalContext} from 'modules/Common/components-v2/KlaimModalv2/components/KlaimModal';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';

const KlaimModalFooter: React.FC<ComponentProps> = ({
  children,
  submitButtonTitle,
  cancelButtonTitle,
  onSubmit,
  buttonType = 'button',
  showCancelButton = true,
  submitLoading,
  hideSubmit,
}) => {
  const classes = useStylesFromThemeFunction();
  const {open, setOpen} = React.useContext(ModalContext);

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };
  return (
    <div className={classes.container}>
      {!children && (
        <div className={classes.defaultContainer}>
          {showCancelButton && (
            <KlaimButton onClick={() => setOpen(!open)} className={classes.cancelButton} variant="primary-outline">
              {cancelButtonTitle || 'Cancel'}
            </KlaimButton>
          )}
          {/*{onSubmit && (*/}
          {!hideSubmit && (
            <KlaimButton type={buttonType} onClick={handleSubmit} disabled={submitLoading}>
              {submitLoading ? 'Uploading...' : submitButtonTitle || 'Submit'}
            </KlaimButton>
          )}
          {/*)}*/}
        </div>
      )}
      {children && children}
    </div>
  );
};

export default KlaimModalFooter;
