import React from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import CalendarIconPrev from 'assets/component/CalendarIconPrev';
import CalendarIconNext from 'assets/component/CalendarIconNext';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Common/components-v2/KlaimForm/components/KlaimCalendarV2/KlaimCalendarV2';
import {Colors} from 'modules/Common/constants/Colors';
import CalendarIcon from 'assets/component/CalendarIcon';
import KlaimIconButton from 'modules/Common/components-v2/KlaimForm/components/KlaimIconButton';

const KlaimCalendarV2: React.FC<ComponentProps> = ({
  onChange,
  selectsRange = false,
  showMonthYearPicker,
  maxDate,
  minDate,
  value,
  label,
  placeholder,
  optional,
  isError = false,
  isTouched = false,
  // inline = false,
  variant = 'primary',
}) => {
  const classes = useStylesFromThemeFunction();
  const [newDate, setNewDate] = React.useState<Date>();
  const [expandCalendar, setExpandCalendar] = React.useState<boolean>(variant === 'with-input');
  const [datePlaceholder, setDatePlaceholder] = React.useState<string>('');
  const [focus, setFocus] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const node = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setOpen(false);
    setFocus(false);
  };

  // React.useEffect(() => {
  //   if (value === undefined) {
  //     const initialDateRange = dayjs().toDate();
  //     setNewDate(initialDateRange);
  //   }
  // }, []);

  React.useEffect(() => {
    if (selectsRange) {
      if (expandCalendar === false) {
        setDatePlaceholder(`${dayjs(newDate).format('MMM DD,YYYY')}`);
      }
    }
  }, [expandCalendar]);

  const onDateChange = (date: any) => {
    setNewDate(date);
    if (onChange) onChange(date);
  };

  // const isValidated = (error: boolean, touched: boolean) => {
  //   return error && touched ? classes.error : classes.validated;
  // };

  // const isValidatedChild = (error: boolean, touched: boolean) => {
  //   return error && touched ? classes.errorChild : classes.validatedChild;
  // };
  type InputProps = React.HTMLProps<HTMLInputElement>;

  const CalendarInput = React.forwardRef<HTMLDivElement, InputProps>((props, ref) => (
    <div className={classes.inputClass} ref={ref}>
      <input {...props} />
      <KlaimIconButton
        type="button"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <CalendarIcon />
      </KlaimIconButton>
    </div>
  ));

  return (
    <div ref={node}>
      {label && (
        <label
          className={`${classes.label} ${isTouched && classes.textValid} ${!focus && isError && classes.textError}`}
        >
          {label}
          {optional && <span>(Optional)</span>}
        </label>
      )}
      {variant === 'primary' && (
        <div
          role="button"
          onClick={() => setExpandCalendar(!expandCalendar)}
          className={`${expandCalendar ? classes.dropCalendarFocused : ''} ${classes.dropCalendarContainer} `}
        >
          <span
            style={{
              color: datePlaceholder ? Colors.grayLabel : Colors.grayLighter,
              font: 'inherit',
            }}
          >
            {datePlaceholder || placeholder || 'Select Date'}
          </span>
        </div>
      )}
      {expandCalendar && (
        <div
          className={`${variant === 'primary' ? classes.calendarContainer : classes.calendarInlineContainer} ${
            focus && classes.calendarFocus
          } ${isTouched && !isError && classes.calendarValid} ${!focus && isError && classes.calendarError} `}
        >
          <DatePicker
            selected={newDate}
            calendarClassName={classes.calendarWrapper}
            customInput={<CalendarInput />}
            onChange={(date) => {
              onDateChange(date);
              setOpen(false);
            }}
            onFocus={() => {
              setOpen(true);
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
              setOpen(false);
            }}
            open={open}
            inline={variant === 'primary'}
            minDate={minDate && new Date(minDate)}
            maxDate={maxDate && new Date(maxDate)}
            placeholderText={placeholder}
            selectsRange={selectsRange}
            showMonthYearPicker={showMonthYearPicker}
            renderCustomHeader={({date, changeYear, decreaseMonth, increaseMonth}: any) => {
              const shouldDisable = (_date: Date) => {
                let disable = false;
                if (showMonthYearPicker)
                  disable = _date ? dayjs(_date).format('YYYY') === dayjs(date).format('YYYY') : false;
                else disable = _date ? dayjs(_date).format('MMMM') === dayjs(date).format('MMMM') : false;
                return disable;
              };
              const disableDecrement = minDate ? shouldDisable(minDate) : false;
              const disableIncrement = maxDate ? shouldDisable(maxDate) : false;
              return (
                <div className={classes.headerContainer}>
                  <button
                    type="button"
                    disabled={disableDecrement}
                    onClick={() => {
                      if (showMonthYearPicker) {
                        changeYear(Number(dayjs(date).format('YYYY').toString()) - 1);
                      } else {
                        decreaseMonth();
                      }
                    }}
                  >
                    <CalendarIconPrev fill={disableDecrement ? Colors.gray : Colors.purple} />
                  </button>
                  <div>
                    <h1>{dayjs(date).format('YYYY').toString()}</h1>
                    {!showMonthYearPicker && <p>{dayjs(date).format('MMMM').toString().toUpperCase()}</p>}
                  </div>
                  <button type="button" disabled={disableIncrement} onClick={() => setNewDate(undefined)}>
                    X
                  </button>
                  <button
                    type="button"
                    disabled={disableIncrement}
                    onClick={() => {
                      if (showMonthYearPicker) {
                        changeYear(Number(dayjs(date).format('YYYY').toString()) + 1);
                      } else {
                        increaseMonth();
                      }
                    }}
                  >
                    <CalendarIconNext fill={disableIncrement ? Colors.gray : Colors.purple} />
                  </button>
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default KlaimCalendarV2;
