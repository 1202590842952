import {combineReducers} from '@reduxjs/toolkit';
import erxListReducer from './features/ErxList/erxListSlice';
import erxDescriptionReducer from './features/ErxDescription/erxDescriptionSlice';
import erxRefPrescriptionReducer from './features/ErxReferencePrescription/erxReferenceSlice';
import erxDrugSearchReducer from './components/ErxDrugSearch/erxDrugSlice';
import erxDispenseReducer from './features/ErxDispense/erxDispenseSlice';

export default combineReducers({
  erxList: erxListReducer,
  erxDescription: erxDescriptionReducer,
  erxRefPrescription: erxRefPrescriptionReducer,
  erxDrug: erxDrugSearchReducer,
  erxDispense: erxDispenseReducer,
});
