import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export interface Values {
  [field: string]: any;
}

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    maxWidth: 1080,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    '& h1': {
      fontSize: 15,
    },
    '& button': {
      fontWeight: 500,
      letterSpacing: 1.2,
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputContainer: {
    width: 600,
    padding: 10,
    display: 'grid',
    gap: '1rem',
  },
  labelContainer: {
    fontSize: 12,
    fontWeight: 800,
  },
  inputNumber: {
    paddingLeft: 10,
    border: 'solid',
    borderWidth: '1px',
    borderColor: Colors.grayLight,
    borderRadius: '10px',
    backgroundColor: Colors.blueGrayLight,
    '& .PhoneInputInput': {
      border: 'none',
      font: 'inherit',
      fontSize: '15px',
      backgroundColor: Colors.blueGrayLight,
      '&:focus': {
        outline: '0px',
        border: 'none',
      },

      padding: '10px',

      color: Colors.grayLabel,
      '&::placeholder': {
        color: Colors.grayInputPlaceholder,
      },
    },
    '& .PhoneInputCountrySelect': {
      '& option': {
        padding: 2,
        font: 'inherit',
        fontSize: '15px',
      },
    },
  },
  buttonTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    letterSpacing: 0.5,
  },
});
