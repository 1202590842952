import React from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import {Colors} from 'modules/Common/constants/Colors';
import {usePreAuthFormContext} from '../../PreAuthFormContext';

interface ComponentsProps {
  open: boolean;
  error: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}
const ErrorModal: React.FC<ComponentsProps> = ({open, setOpen, onClose, error}) => {
  const {constants} = usePreAuthFormContext();

  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title="Error" onClose={() => onClose()} />
      <KlaimModalBody style={{padding: 20}}>
        Something went wrong on your data, the validation of the data throws an error. Please check your data again.
        <ul style={{margin: 10, padding: 10, fontWeight: 600}}>
          {constants.errorMessages.length > 0 &&
            constants.errorMessages.map((errorMessage) => <li style={{color: Colors.red}}>{errorMessage}</li>)}
          {error.length > 0 && error.map((errorMessage) => <li style={{color: Colors.red}}>{errorMessage}</li>)}
        </ul>
      </KlaimModalBody>
      <KlaimModalFooter
        submitButtonTitle="Ok"
        onSubmit={() => {
          onClose();
          setOpen(false);
        }}
        showCancelButton={false}
      />
    </KlaimModal>
  );
};

export default ErrorModal;
