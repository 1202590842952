import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    modal: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteUserContainer: {
      width: 472,
      height: 359,
      borderRadius: 10,
      backgroundColor: Colors.blueGrayLight,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerContainer: {
      '& h1': {
        fontSize: 18,
        fontWeight: 'normal',
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: Colors.white,
      padding: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    messageContainer: {
      textAlign: 'center',
      color: Colors.blueGrayDark,
      '& h2': {
        fontSize: 18,
        fontWeight: 600,
      },
      '& p': {
        fontSize: 13,
      },
    },
    nameText: {
      color: Colors.purple,
      fontWeight: 600,
    },
    optionsButtonContainer: {
      display: 'flex',
      padding: 25,
      justifyContent: 'flex-end',
      gap: 10,
      backgroundColor: Colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    backButton: {
      background: Colors.white,
      color: Colors.black,
      border: `1px solid ${Colors.black}`,
    },
    borderBottom: {
      borderBottom: `1px solid ${Colors.grayLight}`,
    },
    membersAsigned: {
      color: Colors.blueGrayDark,
      flex: '1',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    roleListTableRow: {
      alignItems: 'center',
      display: 'flex',
      height: '40px',
      justifyContent: 'space-between',
      padding: '0 30px',
      paddingTop: 8,
      paddingBottom: 8,
    },
    roleName: {
      alignItems: 'center',
      color: Colors.blueGrayDark,
      display: 'flex',
      flex: '1',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0px',
    },
    iconContainer: {
      flex: '1',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
    },
    icon: {
      width: '25px',
      height: '25px',
    },
    optionsHiddenContainer: {
      display: 'none',
    },
    // button: {
    //   color: Colors.black,
    //   backgroundColor: Colors.white,
    // },
    optionsContainer: {
      position: 'absolute',
      width: '196px',
      padding: '10px',
      backgroundColor: Colors.white,
      borderRadius: '10px',
      right: 30,
      boxShadow: `0px 5px 10px ${Colors.RMSC.shadow}`,
      top: 30,
      isolation: 'isolate',
      zIndex: 5,
    },
    isActiveContainer: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 2,
      paddingBottom: 2,
      borderRadius: 999,
      fontWeight: 500,
      border: '1px solid',
      borderWidth: 1,
    },
    active: {
      backgroundColor: 'rgba(7, 111, 3, 0.2)',
      color: 'rgba(7, 111, 3, 1)',
      borderColor: 'rgba(7, 111, 3, 0.3)',
    },
    disabled: {
      backgroundColor: 'rgba(219, 13, 29, 0.2)',
      color: 'rgba(219, 13, 29, 1)',
      borderColor: 'rgba(219, 13, 29, 0.3)',
    },

    button: {
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: 6,
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    buttonIcon: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      height: 14,
    },
    modalText: {
      marginBottom: 10,
    },
    modalButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingLeft: 80,
      paddingRight: 80,
    },
    modalYesButton: {
      backgroundColor: Colors.purple,
    },
    modalCancelButton: {
      backgroundColor: Colors.red,
    },
    modalButton: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
      width: 100,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: Colors.blueGrayLight,
      borderWidth: 1,
      color: Colors.white,
      fontWeight: 600,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: 14,
      paddingBottom: 40,
      paddingTop: 40,
    },
    navLink: {
      textDecoration: 'none',
    },
  };
});
