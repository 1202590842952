import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {
  ErxDetailsByRefId,
  ErxRefId,
  ErxPrescriptionError,
  UserBranchesObject,
  InitialState,
  ResponseNewErxId,
  SaveNewErx,
  DefaultAttributes,
} from './types';

export const getErxByRefId = createAsyncThunk<ErxDetailsByRefId, ErxRefId, {rejectValue: ErxPrescriptionError}>(
  'erxRefPrescription/getErxByRefId',
  async (data: ErxRefId, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/erx/prescription/${data.erxRefId}`, {params: {...data}});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const saveNewERX = createAsyncThunk<ResponseNewErxId, SaveNewErx, {rejectValue: ErxPrescriptionError}>(
  'erxRefPrescription/saveNewERX',
  async (data: SaveNewErx, {rejectWithValue}) => {
    try {
      const response = await axios.post(`/erx`, data);
      return response.data.id;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getCurrentUserBranch = createAsyncThunk<
  UserBranchesObject[],
  DefaultAttributes,
  {rejectValue: ErxPrescriptionError}
>('erxRefPrescription/getCurrentUserBranch', async (data: DefaultAttributes, {rejectWithValue}) => {
  try {
    const response = await axios.get(`/users/current/branches`);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

const initialState: InitialState = {
  branches: undefined,
  id: undefined,
  data: undefined,
  isLoading: false,
  error: undefined,
};

const erxRefPrescriptionSlice = createSlice({
  name: 'erxRefPrescription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getErxByRefId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getErxByRefId.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getErxByRefId.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(saveNewERX.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveNewERX.rejected, (state, action) => {
        console.log(action.payload);
      })
      .addCase(saveNewERX.fulfilled, (state, action) => {
        state.id = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(getCurrentUserBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUserBranch.rejected, (state, action) => {
        console.log(action.payload);
      })
      .addCase(getCurrentUserBranch.fulfilled, (state, action) => {
        state.branches = action.payload;
        state.isLoading = false;
      });
  },
});

export const erxRefPrescriptionSelect = (state: RootState) => state.pharmacy.erxRefPrescription;
export default erxRefPrescriptionSlice.reducer;
