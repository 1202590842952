import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  questionContainer: {
    '& p': {
      fontSize: 14,
    },
    '& span': {
      fontSize: 14,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    marginTop: 14,
  },
  headerContainer: {
    '& h1': {
      fontSize: 24,
    },
    '& p': {
      fontSize: 12,
    },
  },

  input: {
    flex: '1 1 0%',
  },
  tooltipContainer: {},
  error: {
    color: Colors.red,
  },
});
