import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    // marginTop: 14,
  },
  headerContainer: {
    '& h1': {
      fontSize: 24,
      // marginTop: ,
    },
    '& p': {
      fontSize: 12,
      // marginTop: 10,
    },
  },
  slugContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
  },
  hintContainer: {
    marginTop: 12,
  },
  otherInputContainer: {
    padding: 0,
  },
});
