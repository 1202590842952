import React, {FC, useState} from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import Section from './Section';
import vs from '../../../../../assets/vital-signs.jpg';
import {KlaimDropdownSearch, KlaimForm, KlaimInput} from '../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from './MidTableForm';
import {deleteDiagnosis, patchDiagnosis, postDiagnosis} from '../../../midTableSlice';
import DiagnosisCodeDropdown from '../../../../Diagnosis/features/DiagnosisCodeDropdown';

const ClaimDiagnosis: FC<any> = ({data, providerId}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (diagnosisId) {
      dispatch(
        patchDiagnosis({
          ...{diagnosisOnAdmission, diagnosisType, diagnosisCode, diagnosisDescription, providerId: `${providerId}`},
          claimId: data.id,
          id: diagnosisId,
        }),
      );
    } else {
      dispatch(
        postDiagnosis({
          payload: {
            ...{diagnosisOnAdmission, diagnosisType, diagnosisDescription, providerId: `${providerId}`, diagnosisCode},
          },
          id: data.id,
        }),
      );
    }
  };

  const [diagnosisOnAdmission, setDiagnosisOnAdmission] = useState(null);
  const [diagnosisType, setDiagnosisType] = useState(null);
  const [diagnosisCode, setDiagnosisCode] = useState(null);
  const [diagnosisDescription, setDiagnosisDescription] = useState(null);
  const [diagnosisId, setDiagnosisId] = useState(null);

  return (
    <Section
      title={'Claim Diagnosis'}
      img={vs}
      headerRight={
        <div className={classes.buttonsRow}>
          {diagnosisId ? (
            <button className={classes.update} onClick={handleSubmit}>
              Update
            </button>
          ) : (
            <button className={classes.save} onClick={handleSubmit}>
              Add
            </button>
          )}
        </div>
      }
    >
      <KlaimForm
        enableReinitialize
        initialValues={{}}
        validationSchema={Yup.object({})}
        onSubmit={() => {
          console.log();
        }}
        id="diagnoses-formss"
        customClass={classes.form}
      >
        <div className={classes.respRow}>
          <KlaimDropdownSearch
            style={{flex: '1 1 10%'}}
            variant="secondary"
            label={'ONA'}
            options={[
              {
                label: 'Yes',
                value: 'y',
              },
              {
                label: 'No',
                value: 'n',
              },
            ]}
            placeholder={'ONA'}
            value={diagnosisOnAdmission}
            onChange={setDiagnosisOnAdmission}
          />
          <KlaimDropdownSearch
            style={{flex: '1 1 20%'}}
            variant="secondary"
            label={'Type'}
            options={
              data?.diagnoses?.some((el) => el.diagnosisType === 'principal')
                ? [
                    {
                      label: 'Secondary',
                      value: 'secondary',
                    },
                  ]
                : [
                    {
                      label: 'Principal',
                      value: 'principal',
                    },
                    {
                      label: 'Secondary',
                      value: 'secondary',
                    },
                  ]
            }
            placeholder={'Select type'}
            value={diagnosisType}
            onChange={setDiagnosisType}
          />
          <div style={{flex: '1 1 60%'}}>
            <DiagnosisCodeDropdown
              label="ICD10 Diagnosis"
              authorityCode="NPHIES"
              value={diagnosisCode}
              onChange={(v) => {
                setDiagnosisCode(v.diagnosisCode);
                setDiagnosisDescription(v.longDescription);
              }}
            />
          </div>
        </div>
        <div
          className={classes.respTableRow}
          style={{
            backgroundColor: '#F5F7FB',
            width: 'calc(100% + 32px)',
            marginLeft: '-16px',
            padding: '9px 16px',
          }}
        >
          <div className={classes.value} style={{flex: '1 1 10%'}}>
            Type
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            ONA
          </div>
          <div className={classes.value} style={{flex: '1 1 40%'}}>
            ACTIVITY
          </div>
          <div className={classes.value} style={{flex: '1 1 40%'}}>
            DESCRIPTION
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}} />
        </div>
        {data?.diagnoses?.map((el: any) => (
          <div
            className={classes.respTableRow}
            style={{
              width: 'calc(100% + 32px)',
              marginLeft: '-16px',
              padding: '0px 16px',
              borderBottom: 'solid 1px #E7ECF5',
              marginTop: 0,
            }}
          >
            <div className={classes.label} style={{flex: '1 1 10%'}}>
              {el.diagnosisType}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              {el.diagnosisOnAdmission}
            </div>
            <div className={classes.label} style={{flex: '1 1 40%'}}>
              {el.diagnosisCode}
            </div>
            <div className={classes.label} style={{flex: '1 1 40%'}}>
              {el.diagnosisDescription}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              <div className={classes.buttonsRow}>
                <button
                  className={classes.delete}
                  onClick={() => {
                    dispatch(deleteDiagnosis({id: el.id}));
                  }}
                >
                  Delete
                </button>
                <button
                  className={classes.update}
                  onClick={() => {
                    setDiagnosisType(el.diagnosisType);
                    setDiagnosisOnAdmission(el.diagnosisOnAdmission);
                    setDiagnosisCode(el.diagnosisCode);
                    setDiagnosisId(el.id);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        ))}
      </KlaimForm>
    </Section>
  );
};

export default ClaimDiagnosis;
