import React from 'react';
import {Switch} from 'react-router';
import {IRoute} from 'interfaces/route.interface';
import {useStylesFromThemeFunction} from 'modules/Eligibility/Eligibility';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import Header from 'modules/Header';
import {useAppDispatch} from 'modules/App/store';
import {setNavOptions} from 'modules/Header/headerSlice';
import {NavigationMenuItem} from 'interfaces/header.interfaces';

const Egligibility: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();

  const navigations: NavigationMenuItem[] = [
    {link: '/eligibility', title: 'Eligibility History', isHidden: false},
    {link: '/eligibility/form', title: 'Eligibility Form', isHidden: true},
  ];

  React.useEffect(() => {
    dispatch(
      setNavOptions({
        navOptions: navigations,
      }),
    );
  }, []);
  return (
    <div className={classes.container} id="eligibility-container">
      <Header />
      <div className={classes.eligibilityBody} id="eligibility-body">
        <Switch>
          {routes &&
            routes.map(({routeKey, ...route}) => {
              return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
            })}
        </Switch>
      </div>
    </div>
  );
};

export default Egligibility;
