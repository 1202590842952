interface DropdownOptionString {
  label: string;
  value: any;
}

interface DropdownOptionAny {
  label: string;
  value: any;
}

export const gender: DropdownOptionString[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
export const maritalStatus: DropdownOptionString[] = [
  {
    label: 'Single',
    value: 'L',
  },
  {
    label: 'Divorced',
    value: 'D',
  },
  {
    label: 'Married',
    value: 'M',
  },
  {
    label: 'Unmarried',
    value: 'U',
  },
  {
    label: 'Widowed',
    value: 'W',
  },
];
export const idType: DropdownOptionString[] = [
  {
    label: 'National Identity',
    value: 'NI',
  },
  {
    label: 'Permanent Resident Number',
    value: 'PRC',
  },
  {
    label: 'Border Number ',
    value: 'BN',
  },
  {
    label: 'Visa Number',
    value: 'VS',
  },
  {
    label: 'Passport Number',
    value: 'PPN',
  },
  {
    label: 'Displaced Person Number  ( لآجئ )',
    value: 'DP',
  },
];
export const relationship: DropdownOptionString[] = [
  {
    label: 'Child',
    value: 'child',
  },
  {
    label: 'Parent',
    value: 'parent',
  },
  {
    label: 'Spouse',
    value: 'spouse',
  },
  {
    label: 'Common',
    value: 'common',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Self',
    value: 'self',
  },
  {
    label: 'Injured',
    value: 'injured',
  },
];

export const addressType: DropdownOptionString[] = [
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Billing',
    value: 'billing',
  },
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Temporary',
    value: 'temporary',
  },
  {
    label: 'Old',
    value: 'old',
  },
];

export const costToBeneficiaryTypes: DropdownOptionString[] = [
  {
    label: 'General Practitioner Visit',
    value: 'gpvisit',
  },
  {
    label: 'Specialist Practitioner Visit',
    value: 'spvisit',
  },
  {
    label: 'Co Pay Percentage',
    value: 'copaypct',
  },
  {
    label: 'Co Pay Amount',
    value: 'copay',
  },
  {
    label: 'Deductable',
    value: 'deductible',
  },
  {
    label: 'Max Out of Pocket',
    value: 'maxoutofpocket',
  },
];

export const exceptionTypes: DropdownOptionString[] = [
  {
    label: 'Retired',
    value: 'retired',
  },
  {
    label: 'Foster Child',
    value: 'foster',
  },
];
export const coverageRel: DropdownOptionString[] = [
  {
    label: 'child',
    value: 'child',
  },
  {
    label: 'parent',
    value: 'parent',
  },
  {
    label: 'spouse',
    value: 'spouse',
  },
  {
    label: 'common',
    value: 'common',
  },
  {
    label: 'other',
    value: 'other',
  },
  {
    label: 'self',
    value: 'self',
  },
  {
    label: 'injured',
    value: 'injured',
  },
];
export const policyHolder: DropdownOptionString[] = [
  {
    label: 'patient',
    value: 'patient',
  },
  {
    label: 'organization',
    value: 'organization',
  },
];
export const supportingInfoCategory: DropdownOptionString[] = [
  {
    label: 'Info',
    value: 'info',
  },
  {
    label: 'Onset',
    value: 'onset',
  },
  {
    label: 'Attachment',
    value: 'attachment',
  },
  {
    label: 'Missing Tooth',
    value: 'missingtooth',
  },
  {
    label: 'Hospitalized',
    value: 'hospitalized',
  },
  {
    label: 'Employment Impacted',
    value: 'employmentImpacted',
  },
  {
    label: 'Laboratory Test',
    value: 'lab-test',
  },
  {
    label: 'Reason For Visit',
    value: 'reason-for-visit',
  },
  {
    label: 'Days Supply',
    value: 'days-supply',
  },
  {
    label: 'Vital Sign Weight',
    value: 'vital-sign-weight',
  },
  {
    label: 'Vital Sign Height',
    value: 'vital-sign-height',
  },
  {
    label: 'Vital Sign Systolic',
    value: 'vital-sign-systolic',
  },
  {
    label: 'Vital Sign Diastolic',
    value: 'vital-sign-diastolic',
  },
  {
    label: 'Icu Hours',
    value: 'icu-hours',
  },
  {
    label: 'Ventilation Hours',
    value: 'ventilation-hours',
  },
  {
    label: 'Chief Complaint',
    value: 'chief-complaint',
  },
  {
    label: 'Birth Weight',
    value: 'birth-weight',
  },
  {
    label: 'temperature',
    value: 'temperature',
  },
  {
    label: 'pulse',
    value: 'pulse',
  },
  {
    label: 'Oxygen Saturation',
    value: 'oxygen-saturation',
  },
  {
    label: 'Respiratory Rate',
    value: 'respiratory-rate',
  },
  {
    label: 'Last Menstrual Period',
    value: 'last-menstrual-period',
  },
];
export const supportingInfoCodeSystem: DropdownOptionString[] = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '2',
    value: '2',
  },
];
export const supportingReasonSystem: DropdownOptionString[] = [
  {
    label: 'Info Reason',
    value: 1,
  },
  {
    label: 'Missing Tooth Reason',
    value: 2,
  },
];
export const supportingInfoReason: DropdownOptionString[] = [
  {
    label: 'Info-Correct',
    value: 'Info-Correct',
  },
  {
    label: 'Missing-attach',
    value: 'Missing-attach',
  },
  {
    label: 'Missing-info',
    value: 'Missing-info',
  },
];
export const supportingMissingToothReason: DropdownOptionString[] = [
  {
    label: 'Extraction',
    value: 'e',
  },
  {
    label: 'Congenital',
    value: 'c',
  },
  {
    label: 'Unknown',
    value: 'u',
  },
  {
    label: 'Other',
    value: 'o',
  },
];
export const careTeamCodes: DropdownOptionString[] = [
  {
    label: 'doctor',
    value: 'doctor',
  },
  {
    label: 'pharmacist',
    value: 'pharmacist',
  },
  {
    label: 'researcher',
    value: 'researcher',
  },
  {
    label: 'teacher',
    value: 'teacher',
  },
  {
    label: 'dentist',
    value: 'dentist',
  },
  {
    label: 'physio',
    value: 'physio',
  },
  {
    label: 'speech',
    value: 'speech',
  },
  {
    label: 'ict',
    value: 'ict',
  },
];
export const careTeamRoleCodes: DropdownOptionString[] = [
  {
    label: 'primary',
    value: 'primary',
  },
  {
    label: 'assist',
    value: 'assist',
  },
  {
    label: 'supervisor',
    value: 'supervisor',
  },
  {
    label: 'other',
    value: 'other',
  },
];
export const diagnosisType: DropdownOptionString[] = [
  {
    label: 'Admitting',
    value: 'admitting',
  },

  // {
  //   label: 'Clinical',
  //   value: 'clinical',
  // },
  {
    label: 'Differential',
    value: 'differential',
  },
  {
    label: 'Secondary',
    value: 'secondary',
  },
  {
    label: 'Discharge',
    value: 'discharge',
  },
  // {
  //   label: 'Laboratory',
  //   value: 'laboratory',
  // },
  // {
  //   label: 'Nursing',
  //   value: 'nursing',
  // },
  // {
  //   label: 'Prenatal',
  //   value: 'prenatal',
  // },
  {
    label: 'Principal',
    value: 'principal',
  },
  // {
  //   label: 'Radiology',
  //   value: 'radiology',
  // },
  // {
  //   label: 'Remote',
  //   value: 'remote',
  // },
  // {
  //   label: 'Retrospective',
  //   value: 'retrospective',
  // },
  // {
  //   label: 'Self',
  //   value: 'self',
  // },
];
export const diagnosisOnAdmission: DropdownOptionString[] = [
  {
    label: 'Yes',
    value: 'y',
  },
  {
    label: 'No',
    value: 'n',
  },
  // {
  //   label: 'u',
  //   value: 'u',
  // },
];
export const itemSystems: DropdownOptionAny[] = [
  {
    label: 'Ambulance and Transport Services',
    value: 1,
  },
  {
    label: 'Imaging Services',
    value: 2,
  },
  {
    label: 'Laboratory and Pathology Services',
    value: 3,
  },
  {
    label: 'Medical Devices / Consumables',
    value: 4,
  },
  {
    label: 'Dental Services (In-Patient)',
    value: 5,
  },
  {
    label: 'Dental Services (Out Patient)',
    value: 6,
  },
  {
    label: 'Services',
    value: 7,
  },
  {
    label: 'Procedures',
    value: 8,
  },
  // {
  //   label: 'Medication',
  //   value: '8',
  // },
];

export const relatedToPriorClaimRel: DropdownOptionString[] = [
  {
    label: 'prior',
    value: 'prior',
  },
  {
    label: 'associated',
    value: 'associated',
  },
  {
    label: 'extend',
    value: 'extend',
  },
];
export const claimType: DropdownOptionString[] = [
  {
    label: 'Institutional',
    value: 'institutional',
  },
  {
    label: 'Oral',
    value: 'oral',
  },
  {
    label: 'Pharmacy',
    value: 'pharmacy',
  },
  {
    label: 'Professional',
    value: 'professional',
  },
  {
    label: 'Vision',
    value: 'vision',
  },
];
export const visitType: DropdownOptionString[] = [
  {
    label: 'Out-Patient',
    value: 'op',
  },
  {
    label: 'In-Patient',
    value: 'ip',
  },
  {
    label: 'Emergency',
    value: 'emr',
  },
];
export const syncedType: DropdownOptionString[] = [
  {
    label: 'synced',
    value: true,
  },
  {
    label: 'not synced',
    value: false,
  },
];

export const extendedClaimType: DropdownOptionString[] = [
  {
    label: 'Inpatient / EMR',
    value: '0',
  },
  {
    label: 'Out Patient',
    value: '1',
  },
  {
    label: 'Dental Out Patient',
    value: '2',
  },
  {
    label: 'Dental In Patient',
    value: '3',
  },
  {
    label: 'Medication Only',
    value: '4',
  },
  {
    label: 'Optical',
    value: '5',
  },
];
export const specialtiesMapping = [
  {value: '01.00', label: 'Anesthesiology Specialty'},
  {value: '01.01', label: 'Ambulatory Anesthesia'},
  {value: '01.02', label: 'Anesthesia Cardiology'},
  {value: '01.03', label: 'Neuro-Anesthesia'},
  {value: '01.04', label: 'Obstetrics Anesthesia'},
  {value: '01.05', label: 'Pediatrics Anesthesia'},
  {value: '01.06', label: 'Pediatrics Cardiac Anesthesia'},
  {value: '01.07', label: 'Regional Anesthesia'},
  {value: '01.08', label: 'Vascular / Thoracic Anesthesia'},
  {value: '02.00', label: 'Community Medicine Specialty'},
  {value: '02.01', label: 'Community Health'},
  {value: '03.00', label: 'Dermatology Specialty'},
  {value: '03.01', label: 'Dermatology Surgery'},
  {value: '03.02', label: 'Hair Implant Dermatology'},
  {value: '03.03', label: 'Pediatrics Dermatology'},
  {value: '04.00', label: 'Emergency Medicine Specialty'},
  {value: '04.01', label: 'Adult Emergency Medicine'},
  {value: '04.02', label: 'Pediatrics Emergency Medicine'},
  {value: '05.00', label: 'Ear, Nose & Throat Specialty'},
  {value: '05.01', label: 'Adult ENT'},
  {value: '05.02', label: 'Laryngology'},
  {value: '05.03', label: 'Neuro - Otology & Otology'},
  {value: '05.04', label: 'Nose, Ear Surgery'},
  {value: '05.05', label: 'Oral & Maxillofacial Surgery'},
  {value: '05.06', label: 'Otolaryngology'},
  {value: '05.07', label: 'Pediatrics ENT'},
  {value: '05.08', label: 'Pediatrics Otolaryngology'},
  {value: '05.09', label: 'Rhinology'},
  {value: '05.10', label: 'Audiology'},
  {value: '06.00', label: 'Family Medicine Specialty'},
  {value: '06.01', label: 'Family Medicine'},
  {value: '06.02', label: 'Primary Care / Ophthalmology'},
  {value: '06.03', label: 'Primary Care / Pulmonary'},
  {value: '06.04', label: 'Primary Care Preventive Pediatrics'},
  {value: '06.05', label: 'Primary Health Care'},
  {value: '07.00', label: 'Forensic Medicine Specialty'},
  {value: '08.00', label: 'Internal Medicine Specialty'},
  {value: '08.01', label: 'Adolescent Medicine'},
  {value: '08.02', label: 'Cardiology'},
  {value: '08.03', label: 'Diabetics Medicine'},
  {value: '08.04', label: 'Endocrinology'},
  {value: '08.05', label: 'Gastrology/Gastroenterology'},
  {value: '08.06', label: 'Geriatrics'},
  {value: '08.07', label: 'Hematology'},
  {value: '08.08', label: 'Infectious Diseases'},
  {value: '08.09', label: 'Nephrology'},
  {value: '08.10', label: 'Nuclear Medicine'},
  {value: '08.11', label: 'Oncology'},
  {value: '08.12', label: 'Palliative Medicine'},
  {value: '08.13', label: 'Pulmonology/Chest Medicine'},
  {value: '08.14', label: 'Rheumatology'},
  {value: '08.15', label: 'Sleep Medicine'},
  {value: '08.16', label: 'Sport Medicine'},
  {value: '08.17', label: 'Hepatology'},
  {value: '08.18', label: 'Neurology'},
  {value: '08.19', label: 'Radiation Oncology'},
  {value: '08.20', label: 'Diabetes Foot Care'},
  {value: '08.21', label: 'Head & Neck Oncology'},
  {value: '08.22', label: 'Hematology - Stem Cell'},
  {value: '08.23', label: 'Congenital Heart Disease'},
  {value: '08.24', label: 'Bariatric Medicine'},
  {value: '08.25', label: 'Cardiothoracic'},
  {value: '08.26', label: 'General Medicine'},
  {value: '09.00', label: 'Microbiology Specialty'},
  {value: '10.00', label: 'Obstetrics & Gynecology Specialty'},
  {value: '10.01', label: 'Gynecology Oncology'},
  {value: '10.02', label: 'Infertility'},
  {value: '10.03', label: 'IVF'},
  {value: '10.04', label: 'Perinatology'},
  {value: '10.05', label: 'Urogynecology'},
  {value: '10.06', label: 'Obstetrics'},
  {value: '10.07', label: 'Reproductive Endocrinology & Infertility'},
  {value: '10.08', label: 'Gynecology'},
  {value: '10.09', label: 'Maternal Fetal Medicine'},
  {value: '11.00', label: 'Ophthalmology Specialty'},
  {value: '11.01', label: 'Comprehensive Ophthalmology'},
  {value: '11.02', label: 'Diseases & Surgery of the Retina'},
  {value: '11.03', label: 'Glaucoma'},
  {value: '11.04', label: 'Neuro - Ophthalmology'},
  {value: '11.05', label: 'Ocular Oncology'},
  {value: '11.06', label: 'Oculoplastic'},
  {value: '11.07', label: 'Ophthalmology'},
  {value: '11.08', label: 'Pediatrics Ophthalmology & Strabismus'},
  {value: '11.09', label: 'Primary Care / Ophthalmology'},
  {value: '11.10', label: 'Uveitis / Medical Retina'},
  {value: '11.11', label: 'Optometric'},
  {value: '11.12', label: 'Anterior Segment'},
  {value: '11.13', label: 'Anaplastology'},
  {value: '11.14', label: 'Macular Dystrophy'},
  {value: '11.15', label: 'Abyplopia'},
  {value: '11.16', label: 'Ophthalmic Photography'},
  {value: '12.00', label: 'Orthopedic Specialty'},
  {value: '12.01', label: 'Oncology Orthopedic'},
  {value: '12.02', label: 'Orthopedic Surgery'},
  {value: '12.03', label: 'Pediatrics Orthopedic'},
  {value: '12.04', label: 'Upper Limp Orthopedic'},
  {value: '13.00', label: 'Pathology Specialty'},
  {value: '13.01', label: 'Bone & Soft Tissue Pathology'},
  {value: '13.02', label: 'Dermatopathology'},
  {value: '13.03', label: 'Gast. & Hepat Pathology'},
  {value: '13.04', label: 'Histopathology'},
  {value: '13.05', label: 'Lymphoma Pathology'},
  {value: '13.06', label: 'Pathology Dermatology'},
  {value: '13.07', label: 'Renal Pathology'},
  {value: '14.00', label: 'Pediatric Specialty'},
  {value: '14.01', label: 'Fetal Medicine'},
  {value: '14.02', label: 'Neonatal Intensive Care (NICU)'},
  {value: '14.03', label: 'Pediatrics Imaging'},
  {value: '14.04', label: 'Pediatrics Endocrinology'},
  {value: '14.05', label: 'Pediatrics Gastroenterology'},
  {value: '14.06', label: 'Pediatrics Genetics'},
  {value: '14.07', label: 'Pediatrics Rheumatology'},
  {value: '14.08', label: 'Pediatrics Sleep Medicine'},
  {value: '14.09', label: 'Pediatrics Orthopedic'},
  {value: '14.10', label: 'Pediatrics Hematology'},
  {value: '14.11', label: 'Pediatrics Infectious Diseases'},
  {value: '14.12', label: 'Pediatrics Intensive Care'},
  {value: '14.13', label: 'Pediatrics Nephrology'},
  {value: '14.14', label: 'Pediatrics Pulmonary Diseases'},
  {value: '14.15', label: 'Primary Care Preventive Pediatrics'},
  {value: '14.16', label: 'Pediatric Neurology'},
  {value: '14.17', label: 'Fetal Cardiology'},
  {value: '14.18', label: 'Neonatology'},
  {value: '14.19', label: 'Pediatric Allergy'},
  {value: '14.20', label: 'Pediatric Cardiology'},
  {value: '15.00', label: 'Pediatrics Surgery Specialty'},
  {value: '15.01', label: 'Pediatrics Cardiology'},
  {value: '15.02', label: 'Pediatrics Neurosurgery'},
  {value: '15.03', label: 'Pediatrics Oncology'},
  {value: '15.04', label: 'Pediatrics Plastic Surgery'},
  {value: '15.05', label: 'Pediatrics General Surgery'},
  {value: '15.06', label: 'Pediatrics Hematology/Oncology'},
  {value: '16.00', label: 'Physical Medicine & Rehabilitation Specialty'},
  {value: '16.01', label: 'Physical Medicine & Rehabilitation'},
  {value: '16.02', label: 'Occupational Medicine'},
  {value: '17.00', label: 'Psychiatry Specialty'},
  {value: '17.01', label: 'Addiction Medicine'},
  {value: '17.02', label: 'Child / Adolescent Psychiatry'},
  {value: '17.03', label: 'Consultation - Liaison Psychiatry'},
  {value: '17.04', label: 'Forensic Psychiatry'},
  {value: '17.05', label: 'Geriatric Psychiatry'},
  {value: '17.06', label: 'Mental Health'},
  {value: '17.07', label: 'Mood Disorders Psychiatry'},
  {value: '17.08', label: 'Psychiatry'},
  {value: '17.09', label: 'Rehabilitation Psychiatry'},
  {value: '17.10', label: 'Schizophrenia'},
  {value: '17.11', label: 'Pediatric Behavior'},
  {value: '17.12', label: 'Youth Stress Reduction'},
  {value: '18.00', label: 'Radiology Specialty'},
  {value: '18.01', label: 'Body Imaging'},
  {value: '18.02', label: 'Breast Imaging'},
  {value: '18.03', label: 'Cardiac Imaging'},
  {value: '18.04', label: 'Chest Imaging'},
  {value: '18.05', label: 'Diagnostic Neuroradiology'},
  {value: '18.06', label: 'Diagnostic Radiology'},
  {value: '18.07', label: 'Emergency Radiology'},
  {value: '18.08', label: 'Interventional Neuroradiology'},
  {value: '18.09', label: 'Interventional Radiology'},
  {value: '18.10', label: 'Musculoskeletal Imaging'},
  {value: '18.11', label: 'Pediatrics Imaging'},
  {value: '18.12', label: "Women's Imaging"},
  {value: '19.00', label: 'Surgery Specialty'},
  {value: '19.01', label: 'Arthroplasty Surgery'},
  {value: '19.02', label: 'Bariatric Surgery'},
  {value: '19.03', label: 'Cosmetic Surgery'},
  {value: '19.04', label: 'Craniofacial Surgery'},
  {value: '19.05', label: 'Endocrinology Surgery'},
  {value: '19.06', label: 'Facioplastic'},
  {value: '19.07', label: 'Foot & Ankle Surgery'},
  {value: '19.08', label: 'General Surgery'},
  {value: '19.09', label: 'Hand Surgery'},
  {value: '19.10', label: 'Hepatobiliary & Upper GI Surgery'},
  {value: '19.11', label: 'Neurosurgery (Spinal Surgery)'},
  {value: '19.12', label: 'Neurosurgery / Oncology'},
  {value: '19.13', label: 'Neurosurgery Vascular'},
  {value: '19.14', label: 'Plastic Surgery & Reconstruction'},
  {value: '19.15', label: 'Skull Base Surgery'},
  {value: '19.16', label: 'Spine Surgery'},
  {value: '19.17', label: 'Thoracic Surgery/Chest Surgery'},
  {value: '19.18', label: 'Trauma Surgery'},
  {value: '19.19', label: 'Vascular Surgery'},
  {value: '19.20', label: 'Colorectal Surgery'},
  {value: '19.21', label: 'Transplant Surgery'},
  {value: '19.22', label: 'Liver Transplant Surgery'},
  {value: '19.23', label: 'Renal and Pancreas Transplant Surgery'},
  {value: '19.24', label: 'Breast Surgery'},
  {value: '19.25', label: 'Cardiothoracic Surgery'},
  {value: '19.26', label: 'Burns'},
  {value: '20.00', label: 'Urology Specialty'},
  {value: '20.01', label: 'Gynecology Urology'},
  {value: '20.02', label: 'Laparoscopic Urology'},
  {value: '20.03', label: 'Neuro - Urology'},
  {value: '20.04', label: 'Oncology Urology'},
  {value: '20.05', label: 'Pediatrics Urology'},
  {value: '20.06', label: 'Reconstruction Urology'},
  {value: '21.00', label: 'Critical Care'},
  {value: '21.01', label: 'Pediatric Critical Care (PICU)'},
  {value: '21.02', label: 'Intensive Care (ICU)'},
  {value: '22.00', label: 'Dental'},
  {value: '22.01', label: 'Pediatric Dental'},
  {value: '22.02', label: 'Prosthodontics'},
  {value: '22.03', label: 'Endodontics'},
  {value: '22.04', label: 'Periodontics'},
  {value: '22.05', label: 'Orthodontics'},
  {value: '22.06', label: 'Dental Implants'},
  {value: '22.07', label: 'Dental Hygiene'},
  {value: '22.08', label: 'Special Needs Dentistry'},
  {value: '23.00', label: 'Neurophysiology'},
  {value: '24.00', label: 'Speech/Speech Language Pathology'},
  {value: '25.00', label: 'Infection Control'},
];
export const extendedClaimTypeMapping = {
  0: {claimType: 'institutional', claimSubtype: 'ip'},
  1: {claimType: 'professional', claimSubtype: 'op'},
  2: {claimType: 'oral', claimSubtype: 'op'},
  3: {claimType: 'institutional', claimSubtype: 'ip'},
  4: {claimType: 'pharmacy', claimSubtype: 'op'},
  5: {claimType: 'vision', claimSubtype: 'op'},
};
export const eligibilityDescription = {
  eligible: 'Eligible',
  'not-active': 'Member’s insurance policy is not active',
  'not-covered': 'Policy does not cover the requested services',
  'not-direct-billing': 'Patient is not covered on direct billing basis',
  'out-network': 'Provider outside member Network',
  'limit-exhausted': 'Patient policy/benefit limit is exhausted',
  'coverage-suspended': 'Patient coverage is suspended',
  'provider-contract-suspended': 'Provider contract is suspended',
};

export const claimSubtype: DropdownOptionString[] = [
  {
    label: 'In-Patient',
    value: 'ip',
  },
  {
    label: 'Out-Patient',
    value: 'op',
  },
  {
    label: 'Emergency',
    value: 'emr',
  },
];
export const visionSubType: DropdownOptionString[] = [
  {
    label: 'Lens',
    value: 'lens',
  },
  {
    label: 'Contact',
    value: 'contact',
  },
];
export const claimPriority: DropdownOptionString[] = [
  {
    label: 'Immediate',
    value: 'stat',
  },
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Deferred',
    value: 'deferred',
  },
];

export const relationshipToPatient: DropdownOptionString[] = [
  {
    value: 'child',
    label: 'Child',
  },
  {
    value: 'parent',
    label: 'Parent',
  },
  {
    value: 'spouse',
    label: 'Spouse',
  },
  {
    value: 'common',
    label: 'Common',
  },
  {
    label: 'other',
    value: 'Other',
  },
  {
    value: 'self',
    label: 'Self',
  },
  {
    value: 'injured',
    label: 'Injured',
  },
];

export const policyHolderType = [
  {
    value: 'patient',
    label: 'Patient',
  },
  {
    value: 'organization',
    label: 'Organization',
  },
];

export const additionalInfoCodeSystem = [
  {
    label: 'Tooth Surface',
    value: 1,
  },
  {
    label: 'Oral Region',
    value: 2,
  },
  {
    label: 'loinc',
    value: 3,
  },
  {
    label: 'Visit Reason',
    value: 4,
  },
  {
    label: 'icd-10-am',
    value: 5,
  },
];

export const resubmissionTypes = [
  {
    label: 'Prior Claim',
    value: 'prior',
  },
  {
    label: 'Associated Claim',
    value: 'associated',
  },
  {
    label: 'Authorization to extend',
    value: 'extend',
  },
];

export const toothSurfacedCode1 = [
  {label: 'Mesial', value: 'M'},
  {label: 'Occlusal', value: 'O'},
  {label: 'Incisal', value: 'I'},
  {label: 'Distal', value: 'D'},
  {label: 'Buccal', value: 'B'},
  {label: 'Ventral', value: 'V'},
  {label: 'Lingual', value: 'L'},
  {label: 'Mesioclusal', value: 'MO'},
  {label: 'Distoclusal', value: 'DO'},
  {label: 'Distoincisal', value: 'DI'},
  {label: 'Mesioclusodistal', value: 'MOD'},
];

export const fdiOralRegionCode2 = [
  {label: 'UPPER RIGHT; PERMANENT TEETH # 1', value: '11'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 2', value: '12'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 3', value: '13'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 4', value: '14'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 5', value: '15'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 6', value: '16'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 7', value: '17'},
  {label: 'UPPER RIGHT; PERMANENT TEETH # 8', value: '18'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 1', value: '21'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 2', value: '22'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 3', value: '23'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 4', value: '24'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 5', value: '25'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 6', value: '26'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 7', value: '27'},
  {label: 'UPPER LEFT; PERMANENT TEETH # 8', value: '28'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 1', value: '31'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 2', value: '32'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 3', value: '33'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 4', value: '34'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 5', value: '35'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 6', value: '36'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 7', value: '37'},
  {label: 'LOWER LEFT; PERMANENT TEETH # 8', value: '38'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 1', value: '41'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 2', value: '42'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 3', value: '43'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 4', value: '44'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 5', value: '45'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 6', value: '46'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 7', value: '47'},
  {label: 'LOWER RIGHT; PERMANENT TEETH # 8', value: '48'},
  {label: 'UPPER RIGHT; DECIDUOUS TEETH # 1', value: '51'},
  {label: 'UPPER RIGHT; DECIDUOUS TEETH # 2', value: '52'},
  {label: 'UPPER RIGHT; DECIDUOUS TEETH # 3', value: '53'},
  {label: 'UPPER RIGHT; DECIDUOUS TEETH # 4', value: '54'},
  {label: 'UPPER RIGHT; DECIDUOUS TEETH # 5', value: '55'},
  {label: 'UPPER LEFT; DECIDUOUS TEETH # 1', value: '61'},
  {label: 'UPPER LEFT; DECIDUOUS TEETH # 2', value: '62'},
  {label: 'UPPER LEFT; DECIDUOUS TEETH # 3', value: '63'},
  {label: 'UPPER LEFT; DECIDUOUS TEETH # 4', value: '64'},
  {label: 'UPPER LEFT; DECIDUOUS TEETH # 5', value: '65'},
  {label: 'LOWER LEFT; DECIDUOUS TEETH # 1', value: '71'},
  {label: 'LOWER LEFT; DECIDUOUS TEETH # 2', value: '72'},
  {label: 'LOWER LEFT; DECIDUOUS TEETH # 3', value: '73'},
  {label: 'LOWER LEFT; DECIDUOUS TEETH # 4', value: '74'},
  {label: 'LOWER LEFT; DECIDUOUS TEETH # 5', value: '75'},
  {label: 'LOWER RIGHT; DECIDUOUS TEETH # 1', value: '81'},
  {label: 'LOWER RIGHT; DECIDUOUS TEETH # 2', value: '82'},
  {label: 'LOWER RIGHT; DECIDUOUS TEETH # 3', value: '83'},
  {label: 'LOWER RIGHT; DECIDUOUS TEETH # 4', value: '84'},
  {label: 'LOWER RIGHT; DECIDUOUS TEETH # 5', value: '85'},
];

export const visitReasonCode4 = [
  {label: 'New Visit', value: 'new-visit'},
  {label: 'Follow Up', value: 'follow-up'},
  {label: 'Refill', value: 'refill'},
  {label: 'Walk in', value: 'walk-in'},
  {label: 'Referral', value: 'referral'},
];

export const bodySiteSystem = [
  {label: 'Body Site', value: '1'},
  {label: 'FDI Oral Region', value: '2'},
];

export const drugSelectionReason = [
  {
    label: 'Requested by patient - Insured requested to be dispensed a drug with a specific trade name',
    value: 'patient-request',
  },
  {label: 'Generic - Generic drug as per SFDA classification', value: 'generic'},
  {
    label: 'Innovative without Generic - innovative drug where no generic is available as per SFDA classification',
    value: 'innovative-noGeneric',
  },
  {
    label: 'Requested by physician - treating physician requested not to replace the brand name for innovative drug',
    value: 'physician-request',
  },
  {label: 'Out of pharmacy stock - drug out of pharmacy stock', value: 'drug-pharmacyUnavailable'},
  {
    label:
      'Drug market unavailability - drug not available in the local market, in case of suspension, withdrawal or stopping any of the drugs',
    value: 'drug-marketUnavailable',
  },
  {
    label: 'SFDA Irreplaceable drug - Irreplaceable drug products according to the regulations',
    value: 'Irreplaceable-drug',
  },
];

export const bodySiteCode = [
  {label: 'Upper left, eyelid ', value: 'E1'},
  {label: 'Lower left, eyelid ', value: 'E2'},
  {label: 'Upper right, eyelid ', value: 'E3'},
  {label: 'Lower right, eyelid ', value: 'E4'},
  {label: 'Left hand, second digit', value: 'F1'},
  {label: 'Left hand, third digit', value: 'F2'},
  {label: 'Left hand, fourth digit', value: 'F3'},
  {label: 'Left hand, fifth digit', value: 'F4'},
  {label: 'Right hand, thumb ', value: 'F5'},
  {label: 'Right hand, second digit', value: 'F6'},
  {label: 'Right hand, third digit', value: 'F7'},
  {label: 'Right hand, fourth digit', value: 'F8'},
  {label: 'Right hand, fifth digit', value: 'F9'},
  {label: 'Left hand, thumb ', value: 'FA'},
  {label: 'Left circumflex coronary artery', value: 'LC'},
  {label: 'Left anterior descending coronary artery         ', value: 'LD'},
  {label: 'Left main coronary artery', value: 'LM'},
  {label: 'Left side (used to identify procedures performed on the left side of the body)', value: 'LT'},
  {label: 'Right coronary artery ', value: 'RC'},
  {label: 'Ramus intermedius coronary artery', value: 'RI'},
  {label: 'Right side (used to identify procedures performed on the right side of the body)', value: 'RT'},
  {label: 'Left foot, second digit', value: 'T1'},
  {label: 'Left foot, third digit', value: 'T2'},
  {label: 'Left foot, fourth digit', value: 'T3'},
  {label: 'Left foot, fifth digit', value: 'T4'},
  {label: 'Right foot, great toe', value: 'T5'},
  {label: 'Right foot, second digit', value: 'T6'},
  {label: 'Right foot, third digit', value: 'T7'},
  {label: 'Right foot, fourth digit', value: 'T8'},
  {label: 'Right foot, fifth digit', value: 'T9'},
  {label: 'Left foot, great toe', value: 'TA'},
  {label: 'right eye  ', value: 'RIV'},
  {label: 'left eye  ', value: 'LIV'},
];

export const subSiteSystem = [
  {label: 'Sub Site', value: 1},
  {label: 'FDI Tooth Surface', value: 2},
];

export const subSiteCode = [
  {label: 'Right', value: 'R'},
  {label: 'Left', value: 'L'},
  {label: 'Upper', value: 'U'},
  {label: 'Down', value: 'D'},
  {label: 'Anterior', value: 'A'},
  {label: 'Posterior', value: 'P'},
  {label: 'interior', value: 'I'},
  {label: 'Exterior', value: 'E'},
];

export const practitionerSpecialityOptions = [
  {label: 'Anesthesiology Specialty ', value: '01.00'},
  {label: 'Ambulatory Anesthesia', value: '01.01'},
  {label: 'Anesthesia Cardiology', value: '01.02'},
  {label: 'Neuro-Anesthesia', value: '01.03'},
  {label: 'Obstetrics Anesthesia ', value: '01.04'},
  {label: 'Pediatrics Anesthesia', value: '01.05'},
  {label: 'Pediatrics Cardiac Anesthesia ', value: '01.06'},
  {label: 'Regional Anesthesia', value: '01.07'},
  {label: 'Vascular / Thoracic Anesthesia', value: '01.08'},
  {label: 'Community Medicine Specialty', value: '02.00'},
  {label: 'Community Health', value: '02.01'},
  {label: 'Dermatology Specialty', value: '03.00'},
  {label: 'Dermatology Surgery ', value: '03.01'},
  {label: 'Hair Implant Dermatology ', value: '03.02'},
  {label: 'Pediatrics Dermatology ', value: '03.03'},
  {label: 'Emergency Medicine Specialty ', value: '04.00'},
  {label: 'Adult Emergency Medicine ', value: '04.01'},
  {label: 'Pediatrics Emergency Medicine ', value: '04.02'},
  {label: 'Ear, Nose & Throat Specialty ', value: '05.00'},
  {label: 'Adult ENT', value: '05.01'},
  {label: 'Laryngology', value: '05.02'},
  {label: 'Neuro - Otology & Otology ', value: '05.03'},
  {label: 'Nose, Ear Surgery ', value: '05.04'},
  {label: 'Oral & Maxillofacial Surgery ', value: '05.05'},
  {label: 'Otolaryngology', value: '05.06'},
  {label: 'Pediatrics ENT ', value: '05.07'},
  {label: 'Pediatrics Otolaryngology', value: '05.08'},
  {label: 'Rhinology ', value: '05.09'},
  {label: 'Audiology ', value: '05.10'},
  {label: 'Family Medicine Specialty ', value: '06.00'},
  {label: 'Family Medicine ', value: '06.01'},
  {label: 'Primary Care / Ophthalmology ', value: '06.02'},
  {label: 'Primary Care / Pulmonary ', value: '06.03'},
  {label: 'Primary Care Preventive Pediatrics', value: '06.04'},
  {label: 'Primary Health Care ', value: '06.05'},
  {label: 'Forensic Medicine Specialty ', value: '07.00'},
  {label: 'Internal Medicine Specialty ', value: '08.00'},
  {label: 'Adolescent Medicine ', value: '08.01'},
  {label: 'Cardiology ', value: '08.02'},
  {label: 'Diabetics Medicine', value: '08.03'},
  {label: 'Endocrinology ', value: '08.04'},
  {label: 'Gastrology/Gastroenterology', value: '08.05'},
  {label: 'Geriatrics ', value: '08.06'},
  {label: 'Hematology ', value: '08.07'},
  {label: 'Infectious Diseases ', value: '08.08'},
  {label: 'Nephrology', value: '08.09'},
  {label: 'Nuclear Medicine', value: '08.10'},
  {label: 'Oncology ', value: '08.11'},
  {label: 'Palliative Medicine ', value: '08.12'},
  {label: 'Pulmonology/Chest Medicine ', value: '08.13'},
  {label: 'Rheumatology ', value: '08.14'},
  {label: 'Sleep Medicine ', value: '08.15'},
  {label: 'Sport Medicine', value: '08.16'},
  {label: 'Hepatology ', value: '08.17'},
  {label: 'Neurology ', value: '08.18'},
  {label: 'Radiation Oncology ', value: '08.19'},
  {label: 'Diabetes Foot Care ', value: '08.20'},
  {label: 'Head & Neck Oncology', value: '08.21'},
  {label: 'Hematology - Stem Cell', value: '08.22'},
  {label: 'Congenital Heart Disease ', value: '08.23'},
  {label: 'Bariatric Medicine ', value: '08.24'},
  {label: 'Cardiothoracic', value: '08.25'},
  {label: 'General Medicine ', value: '08.26'},
  {label: 'Microbiology Specialty ', value: '09.00'},
  {label: 'Obstetrics & Gynecology Specialty ', value: '10.00'},
  {label: 'Gynecology Oncology ', value: '10.01'},
  {label: 'Infertility ', value: '10.02'},
  {label: 'IVF', value: '10.03'},
  {label: 'Perinatology ', value: '10.04'},
  {label: 'Urogynecology ', value: '10.05'},
  {label: 'Obstetrics ', value: '10.06'},
  {label: 'Reproductive Endocrinology & Infertility ', value: '10.07'},
  {label: 'Gynecology ', value: '10.08'},
  {label: 'Maternal Fetal Medicine ', value: '10.09'},
  {label: 'Ophthalmology Specialty ', value: '11.00'},
  {label: 'Comprehensive Ophthalmology ', value: '11.01'},
  {label: 'Diseases & Surgery of the Retina ', value: '11.02'},
  {label: 'Glaucoma ', value: '11.03'},
  {label: 'Neuro - Ophthalmology ', value: '11.04'},
  {label: 'Ocular Oncology ', value: '11.05'},
  {label: 'Oculoplastic ', value: '11.06'},
  {label: 'Ophthalmology ', value: '11.07'},
  {label: 'Pediatrics Ophthalmology & Strabismus ', value: '11.08'},
  {label: 'Primary Care / Ophthalmology ', value: '11.09'},
  {label: 'Uveitis / Medical Retina ', value: '11.10'},
  {label: 'Optometric ', value: '11.11'},
  {label: 'Anterior Segment ', value: '11.12'},
  {label: 'Anaplastology ', value: '11.13'},
  {label: 'Macular Dystrophy ', value: '11.14'},
  {label: 'Abyplopia ', value: '11.15'},
  {label: 'Ophthalmic Photography ', value: '11.16'},
  {label: 'Orthopedic Specialty ', value: '12.00'},
  {label: 'Oncology Orthopedic ', value: '12.01'},
  {label: 'Orthopedic Surgery ', value: '12.02'},
  {label: 'Pediatrics Orthopedic ', value: '12.03'},
  {label: 'Upper Limp Orthopedic', value: '12.04'},
  {label: 'Pathology Specialty ', value: '13.00'},
  {label: 'Bone & Soft Tissue Pathology ', value: '13.01'},
  {label: 'Dermatopathology ', value: '13.02'},
  {label: 'Gast. & Hepat Pathology', value: '13.03'},
  {label: 'Histopathology ', value: '13.04'},
  {label: 'Lymphoma Pathology', value: '13.05'},
  {label: 'Pathology Dermatology ', value: '13.06'},
  {label: 'Renal Pathology', value: '13.07'},
  {label: 'Pediatric Specialty', value: '14.00'},
  {label: 'Fetal Medicine ', value: '14.01'},
  {label: 'Neonatal Intensive Care (NICU)', value: '14.02'},
  {label: 'Pediatrics Imaging ', value: '14.03'},
  {label: 'Pediatrics Endocrinology ', value: '14.04'},
  {label: 'Pediatrics Gastroenterology ', value: '14.05'},
  {label: 'Pediatrics Genetics ', value: '14.06'},
  {label: 'Pediatrics Rheumatology', value: '14.07'},
  {label: 'Pediatrics Sleep Medicine ', value: '14.08'},
  {label: 'Pediatrics Orthopedic', value: '14.09'},
  {label: 'Pediatrics Hematology ', value: '14.10'},
  {label: 'Pediatrics Infectious Diseases', value: '14.11'},
  {label: 'Pediatrics Intensive Care ', value: '14.12'},
  {label: 'Pediatrics Nephrology', value: '14.13'},
  {label: 'Pediatrics Pulmonary Diseases ', value: '14.14'},
  {label: 'Primary Care Preventive Pediatrics ', value: '14.15'},
  {label: 'Pediatric Neurology', value: '14.16'},
  {label: 'Fetal Cardiology', value: '14.17'},
  {label: 'Neonatology ', value: '14.18'},
  {label: 'Pediatric Allergy ', value: '14.19'},
  {label: 'Pediatric Cardiology', value: '14.20'},
  {label: 'Pediatrics Surgery Specialty', value: '15.00'},
  {label: 'Pediatrics Cardiology ', value: '15.01'},
  {label: 'Pediatrics Neurosurgery ', value: '15.02'},
  {label: 'Pediatrics Oncology ', value: '15.03'},
  {label: 'Pediatrics Plastic Surgery ', value: '15.04'},
  {label: 'Pediatrics General Surgery ', value: '15.05'},
  {label: 'Pediatrics Hematology/Oncology ', value: '15.06'},
  {label: 'Physical Medicine & Rehabilitation Specialty ', value: '16.00'},
  {label: 'Physical Medicine & Rehabilitation ', value: '16.01'},
  {label: 'Occupational Medicine', value: '16.02'},
  {label: 'Psychiatry Specialty ', value: '17.00'},
  {label: 'Addiction Medicine ', value: '17.01'},
  {label: 'Child / Adolescent Psychiatry ', value: '17.02'},
  {label: 'Consultation - Liaison Psychiatry ', value: '17.03'},
  {label: 'Forensic Psychiatry ', value: '17.04'},
  {label: 'Geriatric Psychiatry ', value: '17.05'},
  {label: 'Mental Health ', value: '17.06'},
  {label: 'Mood Disorders Psychiatry ', value: '17.07'},
  {label: 'Psychiatry', value: '17.08'},
  {label: 'Rehabilitation Psychiatry ', value: '17.09'},
  {label: 'Schizophrenia', value: '17.10'},
  {label: 'Pediatric Behavior ', value: '17.11'},
  {label: 'Youth Stress Reduction ', value: '17.12'},
  {label: 'Radiology Specialty ', value: '18.00'},
  {label: 'Body Imaging ', value: '18.01'},
  {label: 'Breast Imaging ', value: '18.02'},
  {label: 'Cardiac Imaging ', value: '18.03'},
  {label: 'Chest Imaging ', value: '18.04'},
  {label: 'Diagnostic Neuroradiology', value: '18.05'},
  {label: 'Diagnostic Radiology', value: '18.06'},
  {label: 'Emergency Radiology ', value: '18.07'},
  {label: 'Interventional Neuroradiology ', value: '18.08'},
  {label: 'Interventional Radiology ', value: '18.09'},
  {label: 'Musculoskeletal Imaging ', value: '18.10'},
  {label: 'Pediatrics Imaging ', value: '18.11'},
  {label: "Women's Imaging", value: '18.12'},
  {label: 'Surgery Specialty ', value: '19.00'},
  {label: 'Arthroplasty Surgery ', value: '19.01'},
  {label: 'Bariatric Surgery ', value: '19.02'},
  {label: 'Cosmetic Surgery ', value: '19.03'},
  {label: 'Craniofacial Surgery ', value: '19.04'},
  {label: 'Endocrinology Surgery ', value: '19.05'},
  {label: 'Facioplastic ', value: '19.06'},
  {label: 'Foot & Ankle Surgery ', value: '19.07'},
  {label: 'General Surgery ', value: '19.08'},
  {label: 'Hand Surgery ', value: '19.09'},
  {label: 'Hepatobiliary & Upper GI Surgery ', value: '19.10'},
  {label: 'Neurosurgery (Spinal Surgery) ', value: '19.11'},
  {label: 'Neurosurgery / Oncology ', value: '19.12'},
  {label: 'Neurosurgery Vascular ', value: '19.13'},
  {label: 'Plastic Surgery & Reconstruction ', value: '19.14'},
  {label: 'Skull Base Surgery ', value: '19.15'},
  {label: 'Spine Surgery', value: '19.16'},
  {label: 'Thoracic Surgery/Chest Surgery ', value: '19.17'},
  {label: 'Trauma Surgery', value: '19.18'},
  {label: 'Vascular Surgery ', value: '19.19'},
  {label: 'Colorectal Surgery ', value: '19.20'},
  {label: 'Transplant Surgery ', value: '19.21'},
  {label: 'Liver Transplant Surgery ', value: '19.22'},
  {label: 'Renal and Pancreas Transplant Surgery ', value: '19.23'},
  {label: 'Breast Surgery ', value: '19.24'},
  {label: 'Cardiothoracic Surgery ', value: '19.25'},
  {label: 'Burns', value: '19.26'},
  {label: 'Urology Specialty ', value: '20.00'},
  {label: 'Gynecology Urology ', value: '20.01'},
  {label: 'Laparoscopic Urology ', value: '20.02'},
  {label: 'Neuro - Urology ', value: '20.03'},
  {label: 'Oncology Urology ', value: '20.04'},
  {label: 'Pediatrics Urology ', value: '20.05'},
  {label: 'Reconstruction Urology', value: '20.06'},
  {label: 'Critical Care ', value: '21.00'},
  {label: 'Pediatric Critical Care (PICU) ', value: '21.01'},
  {label: 'Intensive Care (ICU) ', value: '21.02'},
  {label: 'Dental', value: '22.00'},
  {label: 'Pediatric Dental', value: '22.01'},
  {label: 'Prosthodontics ', value: '22.02'},
  {label: 'Endodontics ', value: '22.03'},
  {label: 'Periodontics ', value: '22.04'},
  {label: 'Orthodontics', value: '22.05'},
  {label: 'Dental Implants ', value: '22.06'},
  {label: 'Dental Hygiene ', value: '22.07'},
  {label: 'Special Needs Dentistry ', value: '22.08'},
  {label: 'Neurophysiology', value: '23.00'},
  {label: 'Speech/Speech Language Pathology ', value: '24.00'},
  {label: 'Infection Control ', value: '25.00'},
];

export const practitionerSpeciality = practitionerSpecialityOptions.map(practitioner => ({
  label: `${practitioner.label} (${practitioner.value})`,
  value: practitioner.value,
}));

export const practitionerTypes = [
  {label: 'Medical Doctor', value: 'MD'},
  {label: 'Registered Nurse', value: 'RN'},
  {label: 'Pharmacist', value: 'RPH'},
];

export const encounterStatuses = [
  {label: 'Planned', value: 'planned'},
  {label: 'Arrived', value: 'arrived'},
  {label: 'Triaged', value: 'triaged'},
  {label: 'In Progress', value: 'in-progress'},
  {label: 'On Leave', value: 'onleave'},
  {label: 'Finished', value: 'finished'},
  {label: 'Cancelled', value: 'cancelled'},
  {label: 'Entered in Error', value: 'entered-in-error'},
  {label: 'Unknown', value: 'unknown'},
];

export const encounterTypes = [
  {label: 'Acute Inpatient Serv', value: '237'},
  {label: 'Rehabilitation', value: '576'},
  {label: 'General Maintenance', value: '356'},
  {label: 'Complex Maintenance', value: '621'},
  {label: 'palliative medicine', value: '179'},
];

export const encounterPriorities = [
  {label: 'ASAP', value: 'A'},
  {label: 'callback for scheduling', value: 'CS'},
  {label: 'callback placer for scheduling', value: 'CSP'},
  {label: 'contact recipient for scheduling', value: 'CSR'},
  {label: 'elective', value: 'EL'},
  {label: 'emergency', value: 'EM'},
  {label: 'preop', value: 'P'},
  {label: 'as needed', value: 'PRN'},
  {label: 'routine', value: 'R'},
  {label: 'rush reporting', value: 'RR'},
  {label: 'stat', value: 'S'},
  {label: 'timing critical', value: 'T'},
  {label: 'use as directed', value: 'UD'},
  {label: 'urgent', value: 'UR'},
];

export const hospitalDischargeDispositions = [
  {label: 'Home', value: 'home'},
  {label: 'Alternative home', value: 'alt-home'},
  {label: 'Other healthcare facility', value: 'other-hcf'},
  {label: 'Hospice', value: 'hosp'},
  {label: 'Long-term care', value: 'long'},
  {label: 'Left against advice', value: 'aadvice'},
  {label: 'Expired', value: 'exp'},
  {label: 'Psychiatric hospital', value: 'psy'},
  {label: 'Rehabilitation', value: 'rehab'},
  {label: 'Skilled nursing facility', value: 'snf'},
  {label: 'Other', value: 'oth'},
];

export const hospitalAdmitSources = [
  {label: 'Emergency Admission by referral from government primary healthcare center', value: 'EGPHC'},
  {label: 'Emergency Admission by referral from general government hospital', value: 'EGGH'},
  {label: 'Emergency Admission by referral from private primary healthcare center', value: 'EPPHC '},
  {label: 'Emergency Admission by referral from private hospital', value: 'EPH'},
  {label: 'Emergency Admission by insurance company', value: 'EIC'},
  {label: 'Elective waiting list admission government free Scheme', value: 'EWGS'},
  {label: 'Elective waiting list admission self-payment Scheme', value: 'EWSS'},
  {label: 'Elective waiting list admission insurance coverage Scheme', value: 'EWIS'},
  {label: 'Emergency Maternity Birth Admission', value: 'EMBA'},
  {label: 'Planned Maternity Birth Admission', value: 'PMBA'},
  {label: 'Others', value: 'Others'},
];

export const encounterClasses = [
  {label: 'AMB', value: 'AMB'},
  {label: 'EMER', value: 'EMER'},
  {label: 'HH', value: 'HH'},
  {label: 'IMP', value: 'IMP'},
  {label: 'SS', value: 'SS'},
];
export const accidentTypes = [
  {label: 'Motor vehicle accident', value: 'MVA'},
  {label: 'School Accident', value: 'SCHOOL'},
  {label: 'Sporting Accident', value: 'SPT'},
  {label: 'Workplace accident', value: 'WPA'},
];

export const statusOptions: DropdownOptionString[] = [
  {label: 'Submitting', value: 'submitting'},
  {label: 'Submitted', value: 'submitted'},
  {label: 'Cancelling', value: 'cancelling'},
  {label: 'Cancelled', value: 'cancelled'},
  {label: 'Recieved', value: 'recieved'},
  {label: 'Error', value: 'error'},
  {label: 'Approved', value: 'approved'},
  {label: 'Partial Approved', value: 'partial-approved'},
  {label: 'Rejected', value: 'rejected'},
  {label: 'Communication Request', value: 'mission-information'},
  {label: 'Acknowledged', value: 'acknowledged'},
  {label: 'Resubmitting', value: 'resubmitting'},
];

export const MidtableGenders = [
  {
    label: 'male',
    value: 'male',
  },
  {
    label: 'female',
    value: 'female',
  },
  {
    label: 'other',
    value: 'other',
  },
  {
    label: 'unknown',
    value: 'unknown',
  },
];
export const MidtableMaritalStatuses = [
  {
    label: 'Legally Separated',
    value: 'L',
  },
  {
    label: 'Divorced',
    value: 'D',
  },
  {
    label: 'Married',
    value: 'M',
  },
  {
    label: 'Unmarried',
    value: 'U',
  },
  {
    label: 'Widowed',
    value: 'W',
  },
];

export default {
  gender,
  maritalStatus,
  idType,
  relationship,
  addressType,
  costToBeneficiaryTypes,
  exceptionTypes,
  coverageRel,
  policyHolder,
  supportingInfoCategory,
  supportingInfoCodeSystem,
  supportingReasonSystem,
  supportingInfoReason,
  supportingMissingToothReason,
  careTeamCodes,
  careTeamRoleCodes,
  diagnosisType,
  diagnosisOnAdmission,
  itemSystems,
  relatedToPriorClaimRel,
  claimType,
  claimSubtype,
  claimPriority,
  relationshipToPatient,
  additionalInfoCodeSystem,
  resubmissionTypes,
  fdiOralRegionCode2,
  visitReasonCode4,
  toothSurfacedCode1,
  bodySiteSystem,
  bodySiteCode,
  subSiteSystem,
  subSiteCode,
  statusOptions,
  MidtableGenders,
  MidtableMaritalStatuses,
};
