import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from 'services/axios';
import {RootState} from 'modules/App/store';
import {MyKnownError} from 'interfaces/error-interface';
import eligibility from '@klaim-ai/klaim-interfaces/eligibility';

interface Hipd {
  response: eligibility.Model.EligibilityHidpResponse | undefined;
}

interface InitialState {
  hipd: Hipd;
  isLoading: boolean;
  error: MyKnownError;
}

interface HipdPayload {
  providerId: string;
  documentId: string;
}

const initialState: InitialState = {
  hipd: undefined,
  isLoading: false,
  error: undefined,
};

export const getHipd = createAsyncThunk<Hipd, HipdPayload, {rejectValue: MyKnownError}>(
  'hipd',
  async (payload: HipdPayload, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/hipd`, {
        ...payload,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
const hipdSlice = createSlice({
  name: 'hipd',
  initialState,
  reducers: {
    cleanUpHipd: (state) => {
      state.hipd = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHipd.fulfilled, (state, action) => {
        state.hipd = action.payload;
        state.isLoading = false;
      })
      .addCase(getHipd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHipd.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {cleanUpHipd} = hipdSlice.actions;
export const hipdSelect = (state: RootState) => state.hipd;
export default hipdSlice.reducer;
