import React, {FC} from 'react';
import {createUseStyles} from 'react-jss';
import {ClaimStatus} from '../../api/types';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    statusColumn: {
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    statusDot: {
      marginRight: '5px',
      width: 12,
      height: 12,
      borderRadius: '100%',
    },
  };
});

interface ComponentProps {
  status?: ClaimStatus;
}

enum StatusDotColor {
  submitted = '#F7F764',
  submitting = '#DE9743',
  denied = '#D90B0B',
  partially_paid = '#24A5C4',
  paid = '#41D8B6',
  draft = '#222B45',
}

export enum ClaimStatusName {
  submitting = 'Submitting',
  submitted = 'Submitted',
  paid = 'Paid',
  partially_paid = 'Partially Paid',
  rejected = 'Rejected',
  draft = 'Draft',
}

const ClaimStatusField: FC<ComponentProps> = ({status = ''}) => {
  const classes = useStylesFromThemeFunction();
  const setClaimStatusDot = (claimStatus: string) => {
    switch (claimStatus) {
      case ClaimStatus.submitted:
        status = ClaimStatusName.submitted;
        return StatusDotColor.submitted;
      case ClaimStatus.submitting:
        status = ClaimStatusName.submitting;
        return StatusDotColor.submitting;
      case ClaimStatus.paid:
        status = ClaimStatusName.paid;
        return StatusDotColor.paid;
      case ClaimStatus.partially_paid:
        status = ClaimStatusName.partially_paid;
        return StatusDotColor.partially_paid;
      case ClaimStatus.rejected:
        status = ClaimStatusName.rejected;
        return StatusDotColor.denied;
      case ClaimStatus.draft:
        status = ClaimStatusName.draft;
        return StatusDotColor.draft;
      default:
        return '#ffffff';
    }
  };

  return (
    <div className={classes.statusColumn}>
      <div className={classes.statusDot} style={{background: setClaimStatusDot(status)}} />
      {status}
    </div>
  );
};
export default ClaimStatusField;
