import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    bodyTitle: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    bodysubTitle: {
      fontSize: 12,
      marginBottom: 30,
    },
    sectionHeader: {
      background: Colors.grayBackground,
      borderRadius: 5,
      padding: '5px 20px',
      fontWeight: 'bold',
      fontSize: 14,
    },
    keyValContainer: {
      padding: 30,
      paddingTop: 0,
      '& div': {
        display: 'block',
      },
      '& .title': {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 10,
      },
      '& .value': {
        fontSize: 15,
      },
    },
  };
});
