import React from 'react';
import {ErrorMessage, Field, FieldProps} from 'formik';
import PasswordEye from 'assets/component/PasswordEye';
import PasswordEyeSlashed from 'assets/component/PasswordEyeSlashed';
import helpIcon from 'assets/help_icon.svg';
import {ComponentProps, useStylesFromThemeFunction} from 'modules/Common/components/KlaimInput/KlaimInput';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimTooltip from '../KlaimTooltip';

const KlaimInput: React.FC<ComponentProps> = ({
  label,
  name,
  placeholder,
  type = 'text',
  containerClassName,
  inputContainerClassName,
  inputClassName,
  labelClassName,
  errorClassName,
  isDisabled,
  isReadOnly = false,
  optional = false,
  value,
  showTooltip,
}) => {
  const classes = useStylesFromThemeFunction();
  const [toggle, setToggle] = React.useState(false);

  const isValidated = (error: boolean, touched: boolean) => {
    return error && touched ? classes.error : classes.validated;
  };

  const isPasswordValidated = (error: boolean, touched: boolean) => {
    return error && touched ? classes.passwordError : classes.passwordValid;
  };

  const requirements = [
    {message: 'At least 8 characters - the more characters, the better.'},
    {message: 'A mixture of both uppercase and lowercase letters.'},
    {message: 'Contain mix of letters, numbers, and/or symbols.'},
    {message: 'Cannot start or end with a blank space.'},
  ];

  const handleInput = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    return formattedPhoneNumber;
  };

  const handleNameInput = (e: any) => {
    const updatedName = e.target.value;
    return updatedName;
  };

  const formatPhoneNumber = (InputValue: any) => {
    const phoneNumber = InputValue.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
      return phoneNumber;
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `+(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 5)} ${phoneNumber.slice(5, 12)}`;
  };

  const typeCheck = () => {
    if (type === 'number') {
      return (
        <div>
          <Field name={name}>
            {({field, meta}: FieldProps) => {
              return (
                <div
                  className={`${classes.contentContainer} ${containerClassName} ${
                    (isDisabled && classes.disabled) ||
                    (meta.touched ? isValidated(meta.error !== undefined, meta.touched) : '')
                  }`}
                >
                  {label && (
                    <label className={classes.label} htmlFor={name}>
                      {label}
                      {optional && <span>(Optional)</span>}
                    </label>
                  )}
                  <div
                    className={`${classes.inputContainer} ${
                      inputContainerClassName !== undefined ? inputContainerClassName : ''
                    }`}
                  >
                    <input
                      className={`${classes.input} ${inputClassName !== undefined ? inputClassName : ''}`}
                      type="text"
                      placeholder={placeholder}
                      {...field}
                      disabled={isDisabled}
                      autoComplete="on"
                      readOnly={isReadOnly}
                      onInput={(e) => {
                        value = handleInput(e);
                      }}
                      value={value}
                      // maxLength={30}
                    />
                  </div>
                </div>
              );
            }}
          </Field>
          <ErrorMessage name={name}>
            {(errorMessage) => (
              <div className={errorClassName !== undefined ? errorClassName : classes.error}>{errorMessage}</div>
            )}
          </ErrorMessage>
        </div>
      );
    }
    return (
      <div>
        <Field name={name}>
          {({field, meta}: FieldProps) => {
            return (
              <div
                className={`${classes.contentContainer} ${containerClassName} ${
                  (isDisabled && classes.disabled) ||
                  (meta.touched ? isValidated(meta.error !== undefined, meta.touched) : '')
                }`}
              >
                {label && (
                  <label className={classes.label} htmlFor={name}>
                    {label}
                    {optional && <span>(Optional)</span>}
                  </label>
                )}
                <div
                  className={`${classes.inputContainer} ${
                    inputContainerClassName !== undefined ? inputContainerClassName : ''
                  }`}
                >
                  <input
                    className={`${classes.input} ${inputClassName !== undefined ? inputClassName : ''}`}
                    type={type}
                    placeholder={placeholder}
                    {...field}
                    disabled={isDisabled}
                    autoComplete="on"
                    readOnly={isReadOnly}
                    onInput={(e) => {
                      value = handleNameInput(e);
                    }}
                    value={value}
                    // maxLength={30}
                  />
                </div>
                <ErrorMessage name={name}>
                  {(errorMessage) => (
                    <div className={errorClassName !== undefined ? errorClassName : classes.error}>{errorMessage}</div>
                  )}
                </ErrorMessage>
              </div>
            );
          }}
        </Field>
      </div>
    );
  };

  return type === 'password' ? (
    <div className={`${isDisabled && classes.disabled} `}>
      <Field name={name}>
        {({field, meta}: FieldProps) => {
          return (
            <div
              className={`${classes.contentContainer} ${containerClassName} ${
                (isDisabled && classes.disabled) ||
                (meta.touched ? isValidated(meta.error !== undefined, meta.touched) : '')
              }`}
            >
              {label && (
                <label className={classes.label} htmlFor={name}>
                  {label}
                  {optional && <span>(Optional)</span>}
                </label>
              )}
              <div
                className={`${classes.inputContainer} ${
                  inputContainerClassName !== undefined ? inputContainerClassName : ''
                }`}
              >
                <div
                  className={`${classes.passwordContainer} ${
                    meta.touched ? isPasswordValidated(meta.error !== undefined, meta.touched) : ''
                  }`}
                >
                  <div
                    className={`${classes.passwordSubContainer} ${
                      meta.touched ? isPasswordValidated(meta.error !== undefined, meta.touched) : ''
                    }`}
                  >
                    <input
                      className={classes.passwordInput}
                      type={toggle ? 'text' : 'password'}
                      placeholder={placeholder}
                      {...field}
                      disabled={isDisabled}
                      autoComplete="on"
                      readOnly={isReadOnly}
                      // maxLength={40}
                    />
                    <button className={classes.passwordIcon} type="button" onClick={() => setToggle(!toggle)}>
                      {toggle ? <PasswordEye fill={Colors.purple} /> : <PasswordEyeSlashed fill={Colors.purple} />}
                    </button>
                  </div>
                  {showTooltip && (
                    <div>
                      <KlaimTooltip requirements={requirements} icon={helpIcon} />
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage name={name}>
                {(errorMessage) => (
                  <div className={errorClassName !== undefined ? errorClassName : classes.error}>{errorMessage}</div>
                )}
              </ErrorMessage>
            </div>
          );
        }}
      </Field>
    </div>
  ) : (
    typeCheck()
  );
};

export default KlaimInput;
