import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import {logout} from 'modules/Auth/v3/features/Auth/authSlice';
import * as Toast from 'modules/Common/utils/toast';
import axios from 'services/axios';

export interface ISaveAccountAction {
  payload: IProfile;
}

interface GetProfileError {
  error: string;
}

interface IProfile {
  id?: number;
  email: string;
  name: string;
  phone: string;
  admin?: boolean;
  avatar?: string;
  is_active?: boolean;
  is_organization_owner?: boolean;
}

interface InitialState {
  user: IProfile;
  isLoading: boolean;
  redirectTo: string;
  toggle: boolean;
  isChangePassword: boolean;
  errors?: MyKnownError;
  flag: boolean;
}

export const getProfile = createAsyncThunk<IProfile, void, {rejectValue: GetProfileError}>(
  'account/getProfile',
  async (_: void) => {
    const response = await axios.get('/users/profile');
    return response.data;
  },
);

interface ChangePasswordAttribute {
  password: string;
  oldPassword: string;
}

interface ChangePasswordData {
  password: string;
  oldPassword: string;
}

interface MyKnownError {
  stack: {
    message: string;
    name: string;
    response: string;
    status: number;
  };
  statusCode: number;
  status: number;
  message: string;
}
export const changePassword = createAsyncThunk<
  ChangePasswordData,
  ChangePasswordAttribute,
  {rejectValue: MyKnownError}
>('account/changePassword', async (data: ChangePasswordAttribute, {rejectWithValue}) => {
  try {
    const response = await axios.put('/users/update-password', data);
    // return {
    //   password: 'string',
    //   oldPassword: 'string',
    // };
    return response.data;
  } catch (error: any) {
    Toast.error('You have entered an incorrect current password, Please try again');
    return rejectWithValue(error.response.data);
  }
});

interface SaveAttribute {
  email: string;
  name: string;
  phone: string;
}

interface SaveData {
  email: string;
  name: string;
  phone: string;
}

export const saveAccount = createAsyncThunk<SaveData, SaveAttribute>('account/save', async (data: IProfile) => {
  const response = await axios.put('/users/profile', data);
  return response.data;
});
const initialState: InitialState = {
  isChangePassword: false,
  toggle: false,
  user: {
    id: 0,
    email: '',
    name: '',
    phone: '',
    admin: false,
    avatar: '',
    is_active: false,
    is_organization_owner: false,
  },
  isLoading: false,
  redirectTo: '',
  errors: undefined,
  flag: false,
};
const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    redirectCleanUp: (state) => {
      state.redirectTo = '';
    },
    changePasswordCleanup: (state) => {
      state.isChangePassword = false;
    },
    returnFlagStatus: (state) => {
      state.flag = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAccount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveAccount.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveAccount.fulfilled, (state, action) => {
        state.redirectTo = '/settings/account';
        state.user.name = action.payload.name;
        state.user.phone = action.payload.phone;
        state.user.email = action.payload.email;
        state.toggle = !state.toggle;
        state.isLoading = false;
        state.flag = true;
      });
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.toggle = !state.toggle;
        state.isChangePassword = true;
        state.isLoading = false;
      });
    builder.addCase(logout.fulfilled, (state) => {
      state.isChangePassword = false;
      state.toggle = false;
      state.user = {
        id: 0,
        email: '',
        name: '',
        phone: '',
        admin: false,
        avatar: '',
        is_active: false,
        is_organization_owner: false,
      };
      state.isLoading = false;
      state.redirectTo = '';
    });
  },
});

export const {redirectCleanUp, changePasswordCleanup, returnFlagStatus} = AccountSlice.actions;

export const accountSelect = (state: RootState) => state.settings.account;

export default AccountSlice.reducer;
