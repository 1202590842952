import React from 'react';
import {createUseStyles} from 'react-jss';
import EnterIcon from 'assets/component/EnterIcon';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';
import dayjs from 'dayjs';

interface ComponentProps {
  eligibility: any;
  onClick: (value: any) => void;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    gridRow: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      display: 'grid',
      padding: 8,
      borderRadius: 8,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.container.background.secondary,
      },
    },
    value: {
      fontSize: 14,
      color: theme.formElement.color.tertiary,
    },
  };
});
const EligibilityDropdownRow: React.FC<ComponentProps> = ({onClick, eligibility}) => {
  const classes = useStylesFromThemeFunction();
  const handleSelect = () => {
    onClick(eligibility);
  };

  return (
    <KlaimIconButton onClick={handleSelect} style={{width: '100%'}}>
      <div className={classes.gridRow}>
        <div style={{display: 'flex', alignItems: 'center', gridColumn: 'span 3 / span 3'}}>
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: 99999,
              backgroundColor: 'gray',
              marginRight: 4,
            }}
          />
          <div>
            <span className={classes.value}>{`${dayjs(eligibility.date).format('MMMM DD YYYY - HH:MM')} - ${
              eligibility.status
            }`}</span>
          </div>
        </div>
        <div style={{display: 'flex', WebkitJustifyContent: 'flex-end', alignItems: 'center'}}>
          <span style={{marginRight: 10, fontSize: 12}}>Select</span>
          <div style={{width: 15}}>
            <EnterIcon />
          </div>
        </div>
      </div>
    </KlaimIconButton>
  );
};

export default EligibilityDropdownRow;
