import React from 'react';
import {createUseStyles} from 'react-jss';
import AnimateHeight from 'react-animate-height';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {CollapsibleContext} from '../KlaimCollapsible';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      transition: [['height', '3000ms']],
    },
  };
});

interface ComponentProps {
  style?: React.CSSProperties;
}

const KlaimCollapsibleBody: React.FC<ComponentProps> = ({children, style}) => {
  const {isCollapse} = React.useContext(CollapsibleContext);
  const classes = useStylesFromThemeFunction();
  const [height, setHeight] = React.useState(isCollapse ? '0%' : 'auto');
  React.useEffect(() => {
    setHeight(isCollapse ? '0%' : 'auto');
  }, [isCollapse]);

  return (
    <div className={classes.container}>
      <AnimateHeight duration={500} height={height}>
        <div style={style}>{children}</div>
      </AnimateHeight>
    </div>
  );
};

export default KlaimCollapsibleBody;
