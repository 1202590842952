import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {claimListSchema} from 'modules/App/schemas';
import {NormalizedList} from 'modules/App/types/normalizr';
import {ClaimData, ClaimList} from '../../../Claims/api/types';

const claimList = createSlice({
  initialState: {
    allIds: [],
    byId: {} as NormalizedList<ClaimList> | undefined,
  },
  name: 'claimList',
  reducers: {
    getClaimList: (state, {payload}: PayloadAction<ClaimData>) => {
      const normalizedClaimList = normalize(payload.data.data, claimListSchema);
      state.allIds = normalizedClaimList.result;
      state.byId = normalizedClaimList.entities.claim;
    },
  },
});

export const {getClaimList} = claimList.actions;

export default claimList;
