import {FC, ReactNode} from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import CollapsibleSection from 'modules/Common/components/CollapsibleSection';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    approved: {
      background: '#41D8B6',
      borderRadius: 100,
      height: 15,
      margin: '0 12px',
      width: 15,
    },
    approvedText: {
      color: '#41D8B6',
    },
    collapsedContainerClassName: {
      background: 'transparent',
      boxShadow: 'none',
      height: 48,
      position: 'relative',
      zIndex: 1,
    },
    collapsedContentClassName: {
      '& b': {
        fontSize: 13,
        fontWeight: '600',
      },
      borderLeft: '2px solid #6135FB',
      color: '#2C273C',
      fontSize: 14,
      marginLeft: 45,
      paddingLeft: 15,
      paddingRight: 20,
      paddingTop: 0,
    },
    collapsibleHeaderTitle: {
      alignItems: 'center',
      color: '#6135FB',
      display: 'flex',
      fontSize: 14,
      fontWeight: 600,
    },
    collapsibleSubsection: {
      background: ' #F5F7FB',
      left: -30,
      position: 'relative',
    },
    expandedContainerClassName: {
      background: 'transparent',
      paddingBottom: 10,
      position: 'relative',
    },
    rejected: {
      background: '#D90B0B',
      borderRadius: 100,
      height: 15,
      margin: '0 12px',
      width: 15,
    },
    rejectedText: {
      color: '#D90B0B',
    },
  };
});

interface ComponentProps {
  renderContent: () => ReactNode;
  renderSubContent?: () => ReactNode;
  headerText: string;
  statusText?: string;
  subHeaderText?: string;
}

const CollapsiblePreAuthDetails: FC<ComponentProps> = ({
  headerText,
  statusText,
  renderContent,
  subHeaderText,
  renderSubContent,
}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <CollapsibleSection
      collapsed={false}
      contentClassName={classes.collapsedContentClassName}
      expandedContainerClassName={classes.expandedContainerClassName}
      collapsedContainerClassName={classes.collapsedContainerClassName}
      renderHeader={() => (
        <div className={classes.collapsibleHeaderTitle}>
          {headerText}
          {statusText && (
            <>
              <div className={statusText === 'Approved' ? classes.approved : classes.rejected} />
              <div className={statusText === 'Approved' ? classes.approvedText : classes.rejectedText}>
                {statusText}
              </div>
            </>
          )}
        </div>
      )}
      renderContent={() => (
        <>
          {renderContent()}
          {renderSubContent && (
            <div className={classes.collapsibleSubsection}>
              <CollapsibleSection
                collapsed={false}
                contentClassName={classes.collapsedContentClassName}
                expandedContainerClassName={classes.expandedContainerClassName}
                collapsedContainerClassName={classes.collapsedContainerClassName}
                renderHeader={() => <div className={classes.collapsibleHeaderTitle}>{subHeaderText}</div>}
                renderContent={() => renderSubContent()}
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default CollapsiblePreAuthDetails;
