import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  isConfirmDisableOpen: boolean;
  setConfirmDisableOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRequestClose?: () => void;
  index: number;
  isActive: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useStylesFromThemeFunction = createUseStyles({
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmDisableContainer: {
    width: '472px',
    height: '446px',
    borderRadius: '10px',
    backgroundColor: Colors.white,
  },
  headerContainer: {
    '& h1': {
      fontSize: 18,
      fontWeight: 'normal',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  descriptionContainer: {
    fontSize: 13,
    fontWeight: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    background: Colors.blueGrayLight,
  },
  usersAssignedContainer: {
    '& h3': {
      fontSize: 13,
      fontWeight: 'normal',
    },
    '& p': {
      fontSize: 13,
    },
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
    borderBottom: `1px solid ${Colors.grayLight}`,
  },
  optionsButtonContainer: {
    display: 'flex',
    flex: 1,
    padding: 25,
    justifyContent: 'flex-end',
  },
  optionsButtonSubContainer: {
    display: 'flex',
    gap: 10,
    '& button': {
      fontWeight: 'normal',
    },
  },
  backButton: {
    background: Colors.white,
    color: Colors.black,
    border: `1px solid ${Colors.black}`,
  },
});
