export enum SupportedCurrencies {
  SAR = 'SAR',
  AED = 'AED',
}

export enum ToggleSwitchActivePane {
  Left = 'left',
  Right = 'right',
}

export const klaimDataStartDate = new Date('01/01/1990');

export const DownloadFileTypes: {label: string; value: string}[] = [
  {label: 'CSV', value: 'csv'},
  {label: 'XLS/XLSX', value: 'xlsx'},
];
