import React, {FC, useEffect} from 'react';
import {Col, Row} from 'react-grid-system';
import queryString from 'query-string';
import {useHistory, useLocation} from 'react-router';
import {actions, events, Mixpanel} from 'services/mixpanel';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {get} from 'lodash';
import {Branches, headerSelect, setModalVisibility, setRequiredFilters} from 'modules/Header/headerSlice';
import dayjs from 'dayjs';
import {useStylesFromThemeFunction} from './PaymentDetails';
import {getPaymentDetailsByID, paymentDetailsSelect} from './PaymentDetailsSlice';
import SendNotice from './modals/sendNotice';

const DATE_FORMAT = 'DD MMM YYYY';

const ClaimDetails: FC = () => {
  const classes = useStylesFromThemeFunction();
  const location = useLocation();
  const {paymentId} = queryString.parse(location.search);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      setRequiredFilters({
        navigation: true,
        search: false,
        dateRangeFilterDays: false,
        branchesFilter: false,
        insurersFilter: false,
      }),
    );
  }, [location]);

  useEffect(() => {
    if (paymentId) {
      Mixpanel.track(events.payments.paymentView, actions.payments.paymentView);
    } else {
      history.push('/payments');
    }
  }, [paymentId]);
  const {data} = useAppSelector(paymentDetailsSelect);
  const {selectedBranches: branches} = useAppSelector(headerSelect);
  const providerId = branches.map((branch: Branches) => branch.value).join(',');

  useEffect(() => {
    if (get(data, 'responses[0].response.outcome') === 'complete')
      dispatch(
        setRequiredFilters({
          paymentButton: true,
        }),
      );
  }, [data]);
  useEffect(() => {
    dispatch(
      getPaymentDetailsByID({
        paymentId,
        providerId,
      }),
    );
  }, []);

  const {paymentModal} = useAppSelector(headerSelect);

  const formatDate = (date: any) => {
    date = new Date(date.toString());
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const getDot = {
    true: (
      <div className={classes.row}>
        <div className={classes.payed} /> <span>Paid</span>
      </div>
    ),
    false: (
      <div className={classes.row}>
        <div className={classes.cleared} /> <span>Cleared</span>
      </div>
    ),
  };

  const ClaimDetail = () => {
    return (
      <div className={classes.card}>
        <span className={classes.title}>Payment Advice</span>
        <Row className={classes.content}>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>PAYMENT STATUS</span>
              <span className={classes.value}>
                {getDot[`${get(data, 'responses[0].response.outcome') === 'complete'}`]}
              </span>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>PAYMENT #</span>
              <span className={classes.value}>{data?.id}</span>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>FIRST RECON. DATE</span>
              <span className={classes.value}>
                {data?.firstReconciliationDate && formatDate(data?.firstReconciliationDate)}
              </span>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>PAYER / NETWORK</span>
              <span className={classes.value}>{data?.payerId}</span>
            </div>
          </Col>
          <Col lg={12}>
            <div className={classes.hr} />
          </Col>

          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>PAYMENT DATE</span>
              <span className={classes.value}>
                {dayjs(
                  get(
                    data?.responses.filter((el) => el.type === 'payment-reconciliation'),
                    '[0].response.paymentDate',
                    '',
                  ),
                  'YYYY-MM-DD',
                ).format(DATE_FORMAT)}
              </span>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>BRANCH</span>
              <span className={classes.value}>{data?.tenantId}</span>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>CLAIM ID</span>
              <span className={classes.value}>{data?.claim}</span>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>PAYMENT ADVICE AMOUNT</span>
              <span className={classes.value}>{`${get(data, 'responses[0].response.totalPayment.value')} ${get(
                data,
                'responses[0].response.totalPayment.currency',
              )}`}</span>
            </div>
          </Col>
          <Col lg={12}>
            <div className={classes.hr} />
          </Col>
          <Col lg={3}>
            <div className={classes.col}>
              <span className={classes.label}>MESSAGE</span>
              <span className={classes.value}>{get(data, 'responses[0].response.message')}</span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <ClaimDetail />
      <SendNotice open={paymentModal} setOpen={(v) => dispatch(setModalVisibility(false))} payment={data} />
    </>
  );
};

export default ClaimDetails;
