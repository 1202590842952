import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    bodyWrapper: {
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        marginBottom: '28px',
      },
    },
    error: {
      color: Colors.red,
    },
    loadingContainer: {
      height: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    formContainer: {
      width: '450px',
    },
    uploadBox: {
      padding: 0,
    },
    uploadNote: {
      padding: '20px 10px',
      color: Colors.blueGrayDark,
      fontSize: 13,
      '& span': {
        color: Colors.purple,
      },
    },
  };
});
