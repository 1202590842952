import axios, {AxiosError, AxiosInstance} from 'axios';
import {get as getStorage} from 'local-storage';
import store from 'modules/App/store';
import {logout} from 'modules/Auth/v3/features/Auth/authSlice';
import {dispatchNotif} from 'modules/Common/utils/toast';

const accessToken = getStorage('accessToken');

export const {CancelToken} = axios;

const getHeaders = () => {
  if (accessToken) {
    return {Authorization: `Bearer ${accessToken}`};
  }
  return {};
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}/midtable-proxy`,
  headers: getHeaders(),
});

const responseHandle = (response: any) => {
  return response;
};
const errorResponseHandler = (error: AxiosError) => {
  if (error.response?.status === 401) {
    dispatchNotif('Your access token has expired. Please login again.', 'Unauthorized', 'error');
    store.dispatch(logout());
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => responseHandle(response),
  (error) => errorResponseHandler(error),
);

export default axiosInstance;

export const axiosKSAInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_KLAIM_KSA,
  headers: {
    Authorization: process.env.REACT_APP_KLAIM_KSA_TOKEN,
  },
});

axiosKSAInstance.interceptors.response.use(
  (response) => responseHandle(response),
  (error) => errorResponseHandler(error),
);

export const axiosCheetahInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_KLAIM_CHEETAH,
  headers: {
    Authorization: process.env.REACT_APP_KLAIM_CHEETAH_TOKEN,
  },
});

axiosCheetahInstance.interceptors.response.use(
  (response) => responseHandle(response),
  (error) => errorResponseHandler(error),
);
