import React from 'react';
import * as Yup from 'yup';
import {FormikHelpers} from 'formik';
import {useHistory} from 'react-router-dom';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Forgot/EmailPasswordInstructions/EmailPasswordInstructions';
import sentEmail from 'assets/email_sent_v3.svg';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';

export interface Values {
  [field: string]: any;
}

const EmailPasswordInstructions: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const onSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {};

  return (
    <div className={classes.emailPasswordInstructions}>
      <div className={classes.subEmailPasswordInstructions}>
        <div className={classes.headerContainer}>
          <h1>Email has been sent</h1>
          <p className={classes.message}>Instructions for resetting your password have been sent to user@email.com</p>
        </div>
        <div className={classes.imageContainer}>
          <img src={sentEmail} alt="email_sent" />
        </div>
        <div className={classes.questionContainer}>
          <p className={classes.message}>
            You will receive an email within ~1 minute. Be sure to check your spam folder, too.
          </p>
          <p>
            No email? <KlaimSpan>Send again</KlaimSpan>
          </p>
        </div>
        <div className={classes.footerContainer}>
          <Button onClick={() => history.push('/auth')} title="Done" />
        </div>
      </div>
    </div>
  );
};

export default EmailPasswordInstructions;
