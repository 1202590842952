import React, {useState} from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import {useTranslation} from 'react-i18next';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import SelectOptions from 'modules/Common/components/Select';
import Input from 'modules/Common/components/Input';
import KlaimDatePicker from 'modules/Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {PaymentNoticeInitialValues} from '../../types';
import {sendPaymentNotice} from '../../PaymentDetailsSlice';
import {Branches, headerSelect} from '../../../../../Header/headerSlice';
import {useAppSelector} from '../../../../../App/store';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      margin: 32,
      gap: 16,
    },
    label: {color: '#222b45', fontSize: 13, fontWeight: 'bold', paddingLeft: 10, paddingRight: 10, paddingBottom: 4},
    selectInput: {
      width: '25%',
    },
    rowInput: {
      flexGrow: 1,
    },
  };
});

interface ComponentsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  payment: any;
}

const validationSchema = Yup.object().shape({
  paymentAmount: Yup.number().required('payment amount is required.'),
  paymentStatus: Yup.string().required('payment status is required.'),
  paymentDate: Yup.string().required('payment date is required.'),
});

const SendNotice: React.FC<ComponentsProps> = ({open, setOpen, payment}) => {
  const {t} = useTranslation();
  const classes = useStylesFromThemeFunction();
  const {selectedBranches: branches} = useAppSelector(headerSelect);
  const providerId = branches.map((branch: Branches) => branch.value).join(',');

  return (
    <Formik
      initialValues={PaymentNoticeInitialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await sendPaymentNotice(
          {
            providerId,
            payerId: payment?.payerId,
            receiverId: payment?.receiverId,
            paymentNotice: {
              reconSystem: payment?.reconSystem,
              reconIdentifier: payment?.reconIdentifier,
              paymentDate: values.paymentDate,
              paymentAmount: {
                value: values.paymentAmount,
                currency: 'SAR',
              },
              paymentStatus: values.paymentStatus,
            },
          },
          payment?.id,
        );
        setOpen(false);
      }}
    >
      {({handleChange, values, handleSubmit, setFieldValue}) => (
        <KlaimModal open={open} setOpen={setOpen} maxWidth={700}>
          <KlaimModalHeader title="Send Payment Notice" />
          <KlaimModalBody>
            <div className={classes.container}>
              <SelectOptions
                name={`paymentStatus`}
                options={[{label: 'cleared', value: 'cleared'}]}
                label={t('Status')}
                value={values.paymentStatus}
                onChange={(e) => {
                  setFieldValue('paymentStatus', e);
                }}
                divClassName={classes.selectInput}
                placeholder={'Select status'}
                isClearable={false}
              />
              <KlaimDatePicker
                label="Received on"
                variant="secondary"
                onChange={(value) => setFieldValue('paymentDate', value)}
                value={values.paymentDate}
              />
              <Input
                name="paymentAmount"
                label="Amount Received"
                className={`${classes.rowInput}`}
                inputProps={{type: 'number'}}
                value={values.paymentAmount}
                onChange={handleChange}
              />
            </div>
          </KlaimModalBody>
          <KlaimModalFooter submitButtonTitle="Send Notice" onSubmit={handleSubmit} cancelButtonTitle={'Cancel'} />
        </KlaimModal>
      )}
    </Formik>
  );
};

export default SendNotice;
