import React, {useState} from 'react';
import {get} from 'lodash';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import {
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
} from '../../../../Common/components-v2/KlaimForm';
import KlaimDatePicker from '../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {useStylesFromThemeFunction} from '../../../../MidTable/features/MidTableForm/Components/MidTableForm';
import {CancelRequest, resubmitClaim, sendCommRequest} from '../../../claimsSlice';

interface ComponentsProps {
  open: boolean;
  comm?: any;
  submission?: any;
  id?: any;
  providerId?: any;
  status?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Component: React.FC<ComponentsProps> = ({open, setOpen, comm, submission, status, id, providerId}) => {
  const [reason, setReason] = useState();
  const dispatch = useDispatch();

  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title={'Cancel Request'} />
      <KlaimModalBody style={{padding: 20, maxHeight: 600}}>
        <KlaimForm
          enableReinitialize
          initialValues={{
            reason,
          }}
          validationSchema={Yup.object({})}
          onSubmit={() => {}}
          id="patient-form"
        >
          <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', marginTop: 16}}>
            <KlaimFieldWrapper name={'title'} onChange={(value) => setReason(value)}>
              <KlaimDropdownSearch
                variant="secondary"
                label={'Cancellation reason'}
                options={[
                  {
                    label: 'wrong information',
                    value: 'WI',
                  },
                  {
                    label: 'service not performed',
                    value: 'NP',
                  },
                  {
                    label: 'transaction already submitted',
                    value: 'TAS',
                  },
                ]}
                placeholder={'Select type'}
                value={reason}
              />
            </KlaimFieldWrapper>
          </div>
        </KlaimForm>
      </KlaimModalBody>
      <KlaimModalFooter
        cancelButtonTitle={'Cancel'}
        submitLoading={false}
        onSubmit={() => {
          const {senderId, receiverId, payerId} = submission;
          dispatch(
            CancelRequest({
              senderId,
              receiverId,
              payerId,
              id,
              claimId: id,
              providerId,
              type: 'cancel-request',
              claimSystem: 'http://klaim.ai/fhir/Claim',
              cancelRequest: {
                reason: 'WI',
              },
            }),
          );
          setOpen(false);
        }}
        submitButtonTitle={'Send cancel request'}
      />
    </KlaimModal>
  );
};

export default Component;
