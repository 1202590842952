import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    bodyTitle: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    bodysubTitle: {
      fontSize: 12,
      marginBottom: 30,
    },
    sectionHeader: {
      background: Colors.grayBackground,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      padding: '5px 20px',
      fontSize: 14,
    },
    keyValContainer: {
      padding: 30,
      paddingTop: 0,
      '& div': {
        display: 'block',
      },
      '& .title': {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 10,
      },
      '& .value': {
        fontSize: 15,
      },
    },
    inputWrap: {
      paddingTop: 10,
    },
    formRow: {
      padding: '5px 15px',
    },
    formGroup: {
      background: Colors.grayBackground,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      padding: 20,
    },
    errorMessage: {
      padding: '10px 0',
      color: 'red',
    },
  };
});
