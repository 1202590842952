import React from 'react';
import {createUseStyles} from 'react-jss';
import {EL} from '@klaim-ai/klaim-interfaces';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import CheckSuccess from 'assets/component/CheckSuccess';
import CheckFailed from 'assets/component/CheckFailed';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import {Colors} from 'modules/Common/constants/Colors';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {eligibilityErrorCleanup, eligibilitySelect} from 'modules/Eligibility/eligibilitySlice';
import {useRouteMatch, useHistory} from 'react-router-dom';

interface ComponentProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eligibility: EL.Model.Eligibility | undefined;
  isEligibilityCheckSuccess: boolean;
  selectedTPA: any;
}

const useStylesFromThemeFunction = createUseStyles({
  modalBodyContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    background: Colors.blueGrayLight,
    padding: 10,
    '& p': {
      color: Colors.grayLabel,
      fontSize: 13,
    },
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    letterSpacing: 0.5,
  },
});

const EligibilityFormModal: React.FC<ComponentProps> = ({
  isOpen,
  setOpen,
  eligibility,
  isEligibilityCheckSuccess,
  selectedTPA,
}) => {
  const classes = useStylesFromThemeFunction();
  const {error} = useAppSelector(eligibilitySelect);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const eligibilityModalBody = () => {
    if (!isEligibilityCheckSuccess && !eligibility) {
      return (
        <div className={classes.modalBodyContainer}>
          <LoadingSpinner /> <p>This process can run in the background.</p>
        </div>
      );
    }
    if (isEligibilityCheckSuccess && eligibility?.status === 'inForce') {
      return (
        <div className={classes.modalBodyContainer}>
          <CheckSuccess />{' '}
          <p>
            {eligibility.patient.name} is Eligible at {selectedTPA?.name}
          </p>
        </div>
      );
    }
    if (isEligibilityCheckSuccess && eligibility?.status === 'notInForce') {
      return (
        <div className={classes.modalBodyContainer}>
          <CheckFailed />
          <p>{eligibility.patient.name} is Ineligible</p>
        </div>
      );
    }
    return (
      <div className={classes.modalBodyContainer}>
        <CheckFailed />
        {error && <p>{error.message}</p>}
      </div>
    );
  };

  const onClose = () => {
    dispatch(eligibilityErrorCleanup());
  };

  const modalInfo = () => {
    if (!isEligibilityCheckSuccess && !eligibility) {
      return {
        title: 'Checking patient Eligibility',
        buttonTitle: 'OK, Continue',
      };
    }
    if (isEligibilityCheckSuccess && eligibility?.status === 'inForce') {
      return {
        title: 'Patient is Eligible',
        buttonTitle: 'OK, Continue',
      };
    }
    if (isEligibilityCheckSuccess && eligibility?.status === 'notInForce') {
      return {
        title: 'Patient is Ineligible',
        buttonTitle: 'Change Insurance',
      };
    }
    if (error) {
      return {
        title: 'Error',
        buttonTitle: 'OK, continue',
      };
    }
    return {
      title: '',
      buttonTitle: '',
    };
  };

  return (
    <KlaimModal open={isOpen} setOpen={setOpen} onClose={onClose}>
      <KlaimModalHeader title={modalInfo().title} />
      <KlaimModalBody removePadding>{eligibilityModalBody()}</KlaimModalBody>
      <KlaimModalFooter>
        <div className={classes.footerContainer}>
          <KlaimButton
            onClick={() => {
              if (isEligibilityCheckSuccess && eligibility?.status === 'notInForce')
                history.push(`/eligibility/form/${eligibility.id}?providerId=${eligibility.tenantId}`);
              else if (isEligibilityCheckSuccess && eligibility && eligibility.id) {
                history.push(`/eligibility/details/${eligibility.id}?providerId=${eligibility.tenantId}`);
              }
              setOpen(false);
            }}
          >
            <div className={classes.buttonTitle}>{modalInfo().buttonTitle}</div>
          </KlaimButton>
        </div>
      </KlaimModalFooter>
    </KlaimModal>
  );
};

export default EligibilityFormModal;
