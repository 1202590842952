import React from 'react';
import ReactModal from 'react-modal';
import {Colors} from 'modules/Common/constants/Colors';
import {isBlock} from 'typescript';
import CloseIcon from 'assets/component/CloseIcon';
import {styles} from './CustomErrorPromptModal';
import './PromptModal.css';

interface ComponentProps {
  message?: string;

  cleanUp: (value: boolean) => void;
}

const CustomErrorPromptModal: React.FC<ComponentProps> = ({message, cleanUp}) => {
  const cancel = () => cleanUp(false);
  const ok = () => cleanUp(true);
  ReactModal.setAppElement('#custom-prompt');
  return (
    <ReactModal
      isOpen
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          maxWidth: 525,
          width: '100%',
          background: 'white',
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          position: 'static',
          padding: 0,
          fontFamily: 'Poppins',
        },
      }}
    >
      <div style={styles.headerContainer}>
        <div style={styles.headerSubContainer}>
          <h3 style={styles.headerTextStyle}>Cancel new patient entry</h3>
          <button style={styles.headerButtonContainer} onClick={() => cancel()}>
            <CloseIcon />
          </button>
        </div>
      </div>
      <div style={styles.bodyContainer}>
        <p style={styles.paragraph}>
          By cancelling patient creation all entered data will be deleted! Are you sure you want to continue?
        </p>
      </div>
      <div style={styles.footerContainer}>
        <div style={styles.footerSubContainer}>
          <button style={{...styles.buttonDefault, ...styles.primaryOutline}} onClick={() => cancel()}>
            No, Keep editing
          </button>
          <button style={{...styles.buttonDefault, ...styles.primary}} onClick={() => ok()}>
            Yes, Delete entry
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default CustomErrorPromptModal;
