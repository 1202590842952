import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    dFlex: {
      display: 'flex',
    },
    editErxBtn: {
      '& p': {
        color: `${Colors.purple}`,
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    paginationContainer: {
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
    },
    paginationContainer2: {
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: `${Colors.white}`,
    },
    patientListContainer: {
      background: `${Colors.white}`,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
    },
    tableWrapper: {
      flexGrow: 1,
    },
    tableRow: {},
    totalAmountText: {
      marginBottom: 0,
      marginTop: 0,
    },
    viewErxBtn: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    viewErx: {
      fontWeight: 500,
      marginLeft: 10,
    },
    searchWrapper: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
    },
    inputWrapper: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inputContainer: {
      '& input': {
        paddingLeft: 45,
      },
      marginLeft: '20px',
      width: '400px',
    },
    navIcons: {
      alignItems: 'center',
      background: '#f5f7fb',
      borderRadius: '10px',
      cursor: 'pointer',
      display: 'flex',
      padding: '10px',
      marginRight: '20px',
    },
    mr0: {
      marginRight: '0 !important',
    },
    ml20: {
      marginLeft: '20px',
    },
    mr20: {
      marginLeft: '20px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },
  };
});
