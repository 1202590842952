import React from 'react';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Common/components/KlaimDropdownContainer/KlaimDropdownContainer';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {Colors} from '../../constants/Colors';

const KlaimDropdownContainer: React.FC<ComponentProps> = ({
  title,
  value = '',
  leftIcon,
  rightIcon,
  children,
  isIconButton,
  icon,
  isButtonRight,
  customClass,
  showDescription,
  isOpen,
  position,
  className,
  style,
}) => {
  const node = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(isOpen || false);
  const classes = useStylesFromThemeFunction();
  let exitTimeout: NodeJS.Timeout;
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setOpen(false);
  };

  const dropPosition = () => {
    switch (position) {
      case 'top':
        return classes.top;
      case 'bottom':
        return classes.bottom;
      case 'left':
        return classes.left;
      case 'right':
        return classes.right;
      case 'topLeft':
        return classes.topLeft;
      case 'topRight':
        return classes.topRight;
      case 'bottomLeft':
        return classes.bottomLeft;
      case 'bottomRight':
        return classes.bottomRight;
      default:
        return classes.default;
    }
  };

  return (
    <div
      onMouseEnter={() => {
        // if (exitTimeout) {
        //   clearTimeout(exitTimeout);
        // }
        if (children) setOpen(true);
      }}
      ref={node}
      onMouseLeave={() => {
        // exitTimeout = setTimeout(() => {
        //   if (children) setOpen(false);
        // }, 500);
        setOpen(false);
      }}
      className={`${classes.container} ${className} container`}
    >
      {isIconButton ? (
        <div className={classes.iconContainer}>
          <img src={icon} alt="dropdown-icon" />
        </div>
      ) : (
        <div className={`${classes.dropdownButtonContainer} dropCont`}>
          <div className={`${classes.titleContainer} titleContainer`}>
            {leftIcon && <img className={`${classes.titleIcon} leftIcon`} src={leftIcon} alt="left" />}

            {showDescription && (
              <div className="valueCont">
                {value !== '' ? (
                  <span className={`${classes.titleText} titleText`}>
                    {title && title} {value}
                  </span>
                ) : (
                  <span className={`${classes.titleText} titleText`}> {title === undefined ? 'Dropdown' : title}</span>
                )}
              </div>
            )}

            {rightIcon && <img className={`${classes.titleIcon} rightIcon`} src={rightIcon} alt="right" />}
          </div>
          {isButtonRight && (
            <button type="button" className={classes.button} onClick={() => setOpen(!open)}>
              <ChevronArrowDown fill={Colors.white} />
            </button>
          )}
        </div>
      )}

      {open && (
        <div className={classes.bodyContainer}>
          <div className={`${classes.paddingContainer} ${dropPosition()}  ${customClass}`}>
            <div className={classes.childContainer}>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KlaimDropdownContainer;
