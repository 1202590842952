import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Common/components-v2/KlaimModalv2/components/KlaimModalBody/KlaimModalBody';

interface ComponentProps {
  removePadding?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const KlaimModalBody: React.FC<ComponentProps> = ({removePadding, children, style, className}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div style={style} className={`${className} ${classes.container} ${removePadding ? '' : classes.addPadding}`}>
      {children}
    </div>
  );
};

export default KlaimModalBody;
