import React from 'react';

import CrossIcon from 'assets/cross_icon.svg';
import AddIcon from 'assets/add_icon.svg';

import {useStylesFromThemeFunction} from './Permission';

interface ComponentProps {
  permission: any;
  index: number;
  isAdded?: boolean;
  onAdd?: (permission: any) => void;
  onRemove?: (permission: any) => void;
  setInitialValue?: (value: any[]) => void;
}

const Permission: React.FC<ComponentProps> = ({permission, index, isAdded, onRemove, onAdd, setInitialValue}) => {
  const classes = useStylesFromThemeFunction();

  const backgroundColors = (permIndex: number) => {
    let ColorIndex;
    if (permIndex >= 8) {
      ColorIndex = permIndex - Math.trunc(permIndex / 8) * 8;
    } else {
      ColorIndex = permIndex;
    }

    switch (ColorIndex) {
      case 0:
        return classes.backgroundColor1;
      case 1:
        return classes.backgroundColor2;
      case 2:
        return classes.backgroundColor3;
      case 3:
        return classes.backgroundColor4;
      case 4:
        return classes.backgroundColor5;
      case 5:
        return classes.backgroundColor6;
      case 6:
        return classes.backgroundColor7;
      case 7:
        return classes.backgroundColor8;
      case 8:
        return classes.backgroundColor9;
      default:
        break;
    }
  };

  return (
    <div className={`${classes.container} ${backgroundColors(index)}`}>
      {isAdded ? null : (
        <img
          src={AddIcon}
          alt="cross-icon"
          onClick={() => {
            if (onAdd) {
              onAdd(permission);
            }
          }}
        />
      )}
      <span className={isAdded ? classes.borderRight : classes.borderLeft}>{permission.name}</span>
      {isAdded ? (
        <img
          src={CrossIcon}
          alt="cross-icon"
          onClick={() => {
            if (onRemove) {
              onRemove(permission);
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default Permission;
