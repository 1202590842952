import React from 'react';
import * as Yup from 'yup';
import {ErrorMessage, Field, FieldProps} from 'formik';

import sentIcon from 'assets/sent_icon.svg';

import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import Header from 'modules/Settings/components/SubHeader';
import KlaimSpan from 'modules/Common/components/KlaimSpan';

import {IValues, useStylesFromThemeFunction} from 'modules/Settings/features/UserManagement/AddUser/AddUser';
import {branchManagementSelect, getBranches} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import {accessManagementSelect, getRoles} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  addUserCleanup,
  emailCleanUp,
  addUser,
  userManagementSelect,
} from 'modules/Settings/features/UserManagement/userManagementSlice';
import {useHistory} from 'react-router-dom';
import KlaimMultiSelect from 'modules/Common/components/KlaimMultiSelect';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {Branches} from 'modules/Header/headerSlice';
import * as Toast from 'modules/Common/utils/toast';

interface IOption {
  value: string;
  label: string;
}

const AddStaffMember: React.FC = () => {
  const {branches} = useAppSelector(branchManagementSelect);
  const {roles} = useAppSelector(accessManagementSelect);
  const {error, isAdded, email: invitedEmail} = useAppSelector(userManagementSelect);
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [branchesOption, setBranchesOption] = React.useState<IOption[]>([]);
  const [rolesOption, setRolesOption] = React.useState<IOption[]>([]);
  const [disableEmail, setDisableEmail] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(
      getBranches({
        limit: 100,
        offset: 0,
      }),
    );
    dispatch(
      getRoles({
        limit: 100,
        offset: 0,
      }),
    );
  }, []);

  React.useEffect(() => {
    const branchesSanitize = branches.data.branches.map((branch) => {
      return {
        value: branch.id.toString(),
        label: `[${branch.identifier}] ${branch.name}`,
      };
    });

    const rolesSanitize = roles.data.roles.map((role) => {
      return {
        value: role.id.toString(),
        label: role.name,
      };
    });
    setBranchesOption(branchesSanitize);
    setRolesOption(rolesSanitize);
  }, [branches.data.branches, roles.data.roles]);

  React.useEffect(() => {
    dispatch(emailCleanUp());
    return () => {
      dispatch(addUserCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (isAdded) Toast.success('User invite sent successfully', 'Success');
  }, [isAdded]);

  const initialValues: IValues = {
    email: '',
    role: '',
    branch: [],
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
    role: Yup.string().required('Role is required'),
  });

  const onSubmit = (values: IValues) => {
    if (isAdded) {
      history.push('/settings/user-management');
    } else {
      const {email, role, branch} = values;
      dispatch(addUser({email, role: role.value, branches: branch}));
    }
  };

  const branchesDisplay = (selBranches: Branches[]) => {
    return `${selBranches && selBranches[0] !== undefined ? selBranches[0].label : 'Select Branches'}${
      selBranches && selBranches.length > 1 ? `, +${selBranches.length - 1} more` : ''
    }`;
  };

  return (
    <KlaimForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Header
        title="Add User"
        description={<p>Please use the form below to add new user to your organization.</p>}
        backlink="/settings/user-management"
        buttonTitle={isAdded ? 'Done' : 'Invite'}
        buttonType="submit"
        body={
          <div className={classes.bodyWrapper}>
            {error && <span className={classes.error}>{error?.details || error?.message}</span>}
            <KlaimInput isDisabled={disableEmail} name="email" label="Email" placeholder="Email@email.com" />
            {isAdded && (
              <span className={classes.emailSent}>
                <img src={sentIcon} alt="sent-icon" />
                Email will be sent to {invitedEmail}
              </span>
            )}

            {disableEmail && (
              <p className={classes.wrongEmail}>
                Typed in a wrong email?.
                <KlaimSpan
                  onClick={() => {
                    setDisableEmail(false);
                    dispatch(addUserCleanup());
                  }}
                >
                  Edit Email
                </KlaimSpan>
              </p>
            )}

            {/* <KlaimDropdown options={rolesOption} name="role" label="Select Role" /> */}
            {/* <KlaimDropdown options={branchesOption} isOptional name="branch" label="Select Branch(s)" /> */}
            {isAdded === false && (
              <>
                <Field name="role">
                  {({field, form, meta}: FieldProps) => {
                    return (
                      <div style={{padding: 6}}>
                        <label className={classes.label}>Select Role</label>
                        <div className={classes.dropDownContainer}>
                          <KlaimDropdownContainer
                            value={meta.value.label}
                            title="Please Select Role"
                            isButtonRight
                            showDescription
                          >
                            <div>
                              {rolesOption.map((option) => (
                                <div className={classes.buttonContainer} key={option.value}>
                                  <button type="button" onClick={() => form.setFieldValue(field.name, option)}>
                                    <span className={classes.optionText}>{option.label}</span>
                                  </button>
                                </div>
                              ))}
                            </div>
                          </KlaimDropdownContainer>
                        </div>

                        <ErrorMessage name={field.name}>
                          {(errorMessage) => <div className={classes.error}>{errorMessage}</div>}
                        </ErrorMessage>
                      </div>
                    );
                  }}
                </Field>

                <Field name="branch">
                  {({field, form, meta}: FieldProps) => {
                    return (
                      <div style={{padding: 6}}>
                        <label className={classes.label}>Select Branch(s) (Optional)</label>
                        <div className={classes.dropDownContainer}>
                          <KlaimDropdownContainer
                            value={branchesDisplay(meta.value)}
                            title="Please Select Branches (Optional)"
                            isButtonRight
                            showDescription
                          >
                            <KlaimMultiSelect
                              className={classes.fullWidth}
                              onChange={(selBranches: Branches[]) => {
                                form.setFieldValue(
                                  field.name,
                                  selBranches.length > 0
                                    ? selBranches.map((branch) => {
                                        return {...branch, id: branch.value};
                                      })
                                    : undefined,
                                );
                              }}
                              options={branchesOption}
                              selectAllTitle="ALL BRANCHES"
                              value={meta.value}
                            />
                          </KlaimDropdownContainer>
                        </div>

                        <ErrorMessage name={field.name}>
                          {(errorMessage) => <div className={classes.error}>{errorMessage}</div>}
                        </ErrorMessage>
                      </div>
                    );
                  }}
                </Field>
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default AddStaffMember;
