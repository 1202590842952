import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    loading: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      position: 'relative',
      padding: '32px 20px 0',
    },
    header: {
      padding: 24,
      background: '#FFFFFF',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto',
      gap: '12px 95px',
      // minWidth: '1200px',
    },
    statusCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexGap: 10,
    },
    status: {
      height: 12,
      width: 12,
      borderRadius: 12,
      backgroundColor: Colors.successTextGreen,
      '&.submitting, &.submitted, &.resubmitting, &.acknowledged': {backgroundColor: Colors.purple},
      '&.cancelling, &.cancelled': {backgroundColor: Colors.grayMediumLight},
      '&.error': {backgroundColor: Colors.red},
      '&.approved': {backgroundColor: Colors.lightGreen},
      '&.acknowledgement': {backgroundColor: Colors.lightDimGreen},
    },
    statusText: {
      marginLeft: 10,
      textTransform: 'capitalize',
    },
    labelValue: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    checkBox: {
      marginTop: 10,
    },
    label: {
      letterSpacing: '0.42px',
      color: '#222B45',
      fontSize: 14,
    },
    spacedRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexGrow: 1,
      maxWidth: 150,
    },
    approvedStatus: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#33E5BE',
      fontSize: 14,
      padding: '2px 2px',
      backgroundColor: '#CFF5ED',
      borderRadius: 5,
      width: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    deniedStatus: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#E64444',
      fontSize: 14,
      padding: '2px 2px',
      backgroundColor: '#FFA7A7',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    date: {
      font: 'normal normal normal 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#3F465D',
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 4,
    },
    value: {
      letterSpacing: '1.5px',
      color: '#696F81',
      textTransform: 'uppercase',
      font: 'normal normal medium 10px/16px Poppins',
      fontSize: 10,
      fontWeight: 500,
    },
    tableContainer: {
      backgroundColor: 'white',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      marginTop: 26,
      marginBottom: 26,
      position: 'relative',
      width: '100%',
      '& tr > td.ant-table-cell-row-hover:not(tr.ant-table-row-selected > td)': {
        background: '#FCFDFE !important',
      },
      '& .ant-table-footer': {
        backgroundColor: '#FFF',
      },
    },
    tableBodyContainer: {
      width: '100%',
    },
    tableHeaderContainer: {},
    tableHeader: {
      borderBottom: '1px solid #E7ECF5',
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'row',
    },
    tableRow: {
      padding: '16px 24px',
      display: 'flex',
      flexDirection: 'row',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
    },
    absolute: {
      position: 'absolute',
      right: 0,
      top: 8,
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'red',
    },
    pagination: {
      // minWidth: '1200px',
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
    },
    update: {
      padding: '6px 16px',
      backgroundColor: 'lightblue',
      color: 'white',
      borderRadius: 4,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
    button: {
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: '5px 15px',
      borderRadius: 10,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    navLink: {
      textDecoration: 'none',
    },
    footerContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    totalAmountText: {
      fontSize: '10px !important',
      color: '#696F81 !important',
    },
  };
});
