import React, {FC} from 'react';
import dayjs from 'dayjs';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import moreIcon from 'assets/more_icon.svg';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {PreAuthHistory} from 'interfaces/pre-auth.interface';
import {useAppDispatch} from 'modules/App/store';
import {Colors} from 'modules/Common/constants/Colors';
import {ClaimHistory} from 'interfaces/claim.interface';
import {get} from 'lodash';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    genderIcon: {
      marginRight: 20,
    },
    genderIconTd: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    paginationContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
    },
    patientListContainer: {
      background: 'white',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
    },
    tableWrapper: {
      flexGrow: 1,
    },
    totalAmountText: {
      marginBottom: 0,
      marginTop: 0,
    },
    viewPatientBtn: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    viewPatientText: {
      fontWeight: theme.font.weightSemibold,
      marginLeft: 10,
    },
    button: {
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: '5px 15px',
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    buttonIcon: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      height: 20,
    },
    navLink: {
      textDecoration: 'none',
    },
    statusCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexGap: 10,
    },
    status: {
      height: 12,
      width: 12,
      borderRadius: 12,
      backgroundColor: Colors.successTextGreen,
      '&.submitting, &.submitted, &.resubmitting, &.acknowledged': {backgroundColor: Colors.purple},
      '&.cancelling, &.cancelled': {backgroundColor: Colors.grayMediumLight},
      '&.rejected, &.error, &.missing-information': {backgroundColor: Colors.red},
      '&.approved': {backgroundColor: Colors.lightGreen},
      '&.partial-approved': {backgroundColor: Colors.yellow},
    },
    statusText: {
      marginLeft: 10,
      textTransform: 'capitalize',
    },
  };
});

interface ComponentProps {
  data: ClaimHistory;
  handleView: (id: any, providerId: string) => void;
  handleResubmit: (id: any, providerId: string) => void;
  handleViewEligibilityDetails: (id: any, providerId: string) => void;
  openModal: any;
  setItem: any;
  setModalContent: any;
}

const formatDate = (date: string) => {
  return dayjs(date).format('D MMM YYYY');
};

const ClaimsListItem: FC<ComponentProps> = ({
  data,
  openModal,
  setItem,
  handleView,
  handleResubmit,
  handleViewEligibilityDetails,
  setModalContent,
}) => {
  const getAmountValue = (category) => {
    return get(
      get(data, 'responses[0].response.claimTotal', []).find((el) => el.category === category),
      'amount',
      '--',
    );
  };
  const classes = useStylesFromThemeFunction({});
  const dispatch = useAppDispatch();
  const {name} = data.submissions?.[0]?.patient || {name: ''};
  const {servicedDate} = data.submissions?.[0]?.claim?.item?.[0] || {};

  const stateIndicator = (state: string) => {
    return (
      <div className={classes.statusCont}>
        <div className={`${classes.status} ${state}`} />
        <div className={classes.statusText}>{state}</div>
      </div>
    );
  };
  const getControls = () => (
    <KlaimDropdownContainer isIconButton icon={moreIcon} position="bottomLeft">
      <div
        role="button"
        onClick={() => handleView(data.id, data.submissions[0].senderId)}
        className={`${classes.button} ${classes.navLink}`}
      >
        View
      </div>
      <div
        role="button"
        onClick={() => handleResubmit(data.id, data.submissions[0].senderId)}
        className={`${classes.button} ${classes.navLink}`}
      >
        Resubmit
      </div>
      {data.eligibilityId && (
        <div
          role="button"
          onClick={() => handleViewEligibilityDetails(data.eligibilityId, data.submissions[0].senderId)}
          className={`${classes.button} ${classes.navLink}`}
        >
          View Eligibility Details
        </div>
      )}

      {data.status !== 'cancelling' && data.status !== 'cancelled' && data.status !== 'error' && (
        <div
          role="button"
          onClick={() => {
            openModal(true);
            setItem(data);
            setModalContent('cancellation');
          }}
          className={`${classes.button} ${classes.navLink}`}
        >
          Cancellation
        </div>
      )}
      {data.status !== 'cancelling' && data.status !== 'cancelled' && data.status !== 'error' && (
        <div
          role="button"
          onClick={() => {
            openModal(true);
            setItem(data);
            setModalContent('communication');
          }}
          className={`${classes.button} ${classes.navLink}`}
        >
          Communication Request
        </div>
      )}
    </KlaimDropdownContainer>
  );
  return (
    <tr>
      <td>{data.id}</td>
      <td className={classes.genderIconTd}>{name}</td>
      <td>{getAmountValue('submitted')}</td>
      <td>--</td>
      <td>{getAmountValue('deductible')}</td>
      <td>--</td>
      <td>{getAmountValue('tax')}</td>
      <td>{stateIndicator(data.status)}</td>
      <td>{get(data, 'responses[0].response.processNote[0]', '--')}</td>
      <td>{get(data, 'responses[0].response.bundleIds.request', '--')}</td>
      <td>{get(data, 'responses[0].response.submittedTotal', '--')}</td>
      <td>{getControls()}</td>
    </tr>
  );
};

export default ClaimsListItem;
