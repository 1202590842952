import React, {FC, useEffect} from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
} from '../../../../Common/components-v2/KlaimForm';
import doctor from '../../../../../assets/doc.jpg';
import KlaimDatePicker from '../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {useStylesFromThemeFunction} from './MidTableForm';
import Section from './Section';
import {patchClaim} from '../../../midTableSlice';
import {MidtableGenders, MidtableMaritalStatuses} from '../../../../Common/constants/DropdownOptions';

const Patient: FC<any> = ({data, providerId, nationalities, onPreAuthChange}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (data.patientId) {
      dispatch(patchClaim({...values, id: data.id, claimId: data.id, providerId: `${providerId}`}));
    } else {
      // dispatch(postClaim({...values, ...data}));
    }
  };

  return (
    <Section
      title={'Patient Information'}
      img={doctor}
      headerRight={
        <div className={classes.buttonsRow}>
          {data?.patientId ? (
            <button className={classes.update} form={'patient-form'}>
              Update
            </button>
          ) : (
            <button className={classes.save} form={'patient-form'}>
              Save
            </button>
          )}
        </div>
      }
    >
      <KlaimForm
        enableReinitialize
        initialValues={{
          patientNo: data?.patientNo,
          patientId: data?.patientId,
          memberId: data?.memberId,
          policyNumber: data?.policyNumber,
          class: data?.class,
          visitType: data?.visitType,
          patientName: data?.patientName,
          patientPhone: data?.patientPhone || '',
          gender: data?.gender,
          patientDob: data?.patientDob,
          patientNationality: data?.patientNationality,
          maritalStatus: data?.maritalStatus,
          // encounterType: data?.encounterType,
          eligibilityRefNo: data?.eligibilityRefNo,
          preAuthNo: data?.preAuthNo,
        }}
        validationSchema={Yup.object({
          patientNo: Yup.string(),
          patientId: Yup.string(),
          memberId: Yup.string(),
          policyNumber: Yup.string(),
          class: Yup.string(),
          visitType: Yup.string(),
          patientName: Yup.string(),
          patientPhone: Yup.string(),
          gender: Yup.number(),
          patientDob: Yup.string(),
          patientNationality: Yup.string(),
          maritalStatus: Yup.number(),
          // encounterType: Yup.string(),
          eligibilityRefNo: Yup.string(),
          preAuthNo: Yup.string(),
        })}
        onSubmit={handleSubmit}
        id="patient-form"
        customClass={classes.form}
      >
        <div className={classes.respRow}>
          <KlaimFieldWrapper name="patientNo" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="1331000003" label="Patient No" isDisabled />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="patientId" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="1331000003" label="NID" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="memberId" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="1331000003" label="Member ID" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="policyNumber" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="1331000003" label="Policy Number" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'class'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="class" label="Class" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'visitType'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimDropdownSearch
              variant="secondary"
              label={'Visit type'}
              options={[
                {
                  label: 'New visit',
                  value: '1',
                },
                {
                  label: 'Follow up',
                  value: '2',
                },
                {
                  label: 'Transfer',
                  value: '3',
                },
                {
                  label: 'New Born',
                  value: '4',
                },
              ]}
              placeholder={'Select Visit type'}
            />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            name="patientName"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            style={{flex: '1 1 6%'}}
          >
            <KlaimInput variant="secondary" placeholder="1331000003" label="Full Name" isDisabled />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="patientPhone"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            style={{flex: '2 1 0'}}
          >
            <KlaimInput
              variant="secondary"
              type="phone"
              label="Mobile Number"
              defaultCountry="SA"
              placeholder="+966 000 XXX XXXX"
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'gender'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimDropdownSearch
              variant="secondary"
              label={'Gender'}
              options={MidtableGenders}
              placeholder={'Select Gender'}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'patientDob'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimDatePicker label="Date of Birth" name="dateOfBirth" variant="secondary" maxDate={new Date()} />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'patientNationality'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimDropdownSearch
              variant="secondary"
              label={'Nationality'}
              options={nationalities?.map((el) => ({
                label: el.name,
                value: el.code,
              }))}
              placeholder={'Select Nationality'}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'maritalStatus'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimDropdownSearch
              variant="secondary"
              label={'Marital'}
              options={MidtableMaritalStatuses}
              placeholder={'Select Marital'}
            />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          {/* <KlaimFieldWrapper name={'encounterType'} onChange={() => {}} className={classes.respFieldWrapper}> */}
          {/*  <KlaimDropdownSearch */}
          {/*    variant="secondary" */}
          {/*    label={'Encounter Type'} */}
          {/*    options={[ */}
          {/*      { */}
          {/*        label: 'Label', */}
          {/*        value: 'Value', */}
          {/*      }, */}
          {/*    ]} */}
          {/*    placeholder={'Select Encounter Type'} */}
          {/*  /> */}
          {/* </KlaimFieldWrapper> */}
          <KlaimFieldWrapper name={'eligibilityRefNo'} onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" label="Eligibility Ref Number" placeholder="xxx" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'preAuthNo'} className={classes.respFieldWrapper}>
            <KlaimInput
              variant="secondary"
              onChange={(v) => {
                onPreAuthChange(v.target.value);
              }}
              placeholder="1331000003"
              label="Pre - Authorisation Ref Number or ID"
            />
          </KlaimFieldWrapper>
        </div>
      </KlaimForm>
    </Section>
  );
};

export default Patient;
