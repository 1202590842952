import React from 'react';
import {ILoincResponse} from 'interfaces/pre-auth.interface';
import {KlaimDropdownSearch} from 'modules/Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {diagnosisSelect, getDiagnosis, getLoincs} from '../../diagnosisSlice';

interface Option {
  label: string;
  value: string;
}

interface ComponentProps {
  onChange?: (value: any) => void;
  isError?: boolean;
  authorityCode?: string;
  label?: string;
  isSupportInfo?: boolean;
}

const LoincCodeDropdown: React.FC<ComponentProps> = ({
  onChange,
  isError = false,
  authorityCode = 'HAAD',
  label,
  isSupportInfo,
}) => {
  const dispatch = useAppDispatch();
  const {loinc} = useAppSelector(diagnosisSelect);
  const [limit] = React.useState<number>(10);
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');
  const [loincOption, setDiagnosisOption] = React.useState<Option[]>([]);

  React.useEffect(() => {
    dispatch(
      getLoincs({
        authorityCode,
        search: search.replace('-', ''),
        limit,
      }),
    );
  }, [search]);

  React.useEffect(() => {
    if (loinc) {
      const newData = loinc.map((diag: ILoincResponse) => {
        return {
          label: diag.description,
          value: diag.code,
        };
      });
      setDiagnosisOption(newData);
    }
  }, [loinc]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const handleOnChange = (value: any) => {
    if (isSupportInfo) {
      if (onChange) {
        onChange(value);
      }
    } else {
      const loincDetails = loinc.find((diag: ILoincResponse) => diag.code === value);
      if (onChange) {
        onChange(loincDetails && loincDetails ? loincDetails : {});
      }
    }
  };

  const handleInputChange = (newValue: any) => {
    setSearchTyping(newValue);
  };

  return (
    <KlaimDropdownSearch
      isError={isError}
      label={label || 'Search Diagnosis'}
      options={loincOption}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
    />
  );
};

export default LoincCodeDropdown;
