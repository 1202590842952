import React from 'react';
import {RouteComponentProps} from 'react-router';
import * as Yup from 'yup';
import {ErrorMessage, Field, FieldProps} from 'formik';

import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import Header from 'modules/Settings/components/SubHeader';

import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {getBranches, branchManagementSelect} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import KlaimMultiSelect from 'modules/Common/components/KlaimMultiSelect';
import {Branches} from 'modules/Header/headerSlice';
import {accessManagementSelect, getRoles} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import {
  getUser,
  updateUser,
  resendInvitation,
  editUserCleanup,
  userManagementSelect,
} from 'modules/Settings/features/UserManagement/userManagementSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {IValues, useStylesFromThemeFunction} from './EditUser';

interface IOption {
  value: string;
  label: string;
}

interface RouteParams {
  id: string;
}

const AddStaffMember: React.FC<RouteComponentProps<RouteParams>> = ({match, history}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {user, isEdit, isLoading} = useAppSelector(userManagementSelect);
  const {roles} = useAppSelector(accessManagementSelect);
  const {branches} = useAppSelector(branchManagementSelect);
  const [branchesOption, setBranchesOption] = React.useState<IOption[]>([]);
  const [rolesOption, setRolesOption] = React.useState<IOption[]>([]);

  React.useEffect(() => {
    dispatch(
      getUser({
        id: match.params.id,
      }),
    );
    dispatch(
      getBranches({
        limit: 100,
        offset: 0,
      }),
    );
    dispatch(
      getRoles({
        limit: 100,
        offset: 0,
      }),
    );

    return () => {
      dispatch(editUserCleanup());
    };
  }, [match.params.id]);

  React.useEffect(() => {
    const branchesSanitize = branches.data.branches.map((branch) => {
      return {
        value: branch.id.toString(),
        label: `[${branch.identifier}] ${branch.name}`,
      };
    });

    const rolesSanitize = roles.data.roles.map((role) => {
      return {
        value: role.id.toString(),
        label: role.name,
      };
    });
    setBranchesOption(branchesSanitize);
    setRolesOption(rolesSanitize);
  }, [branches.data.branches, roles.data.roles]);

  React.useEffect(() => {
    if (isEdit) history.push('/settings/user-management');
  }, [isEdit]);

  const initialValues: IValues = {
    email: user ? user?.email : '',
    role: user ? user?.role?.id.toString() : '',
    branch: user
      ? user?.branches.map((branch) => {
          return {
            value: branch.id.toString(),
            label: branch.name,
          };
        })
      : [],
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    role: Yup.string().required(),
  });

  const onSubmit = (values: IValues) => {
    dispatch(
      updateUser({
        id: user ? user.id : 0,
        role: values.role.value ? values.role.value : values.role,
        branches: values.branch.map((data: IOption) => {
          return {
            id: data.value,
          };
        }),
      }),
    );
  };

  const branchesDisplay = (selBranches: Branches[]) => {
    return `${selBranches && selBranches[0] !== undefined ? selBranches[0].label : 'Select Branches'}${
      selBranches && selBranches.length > 1 ? `, +${selBranches.length - 1} more` : ''
    }`;
  };

  const resendClick = () => {
    dispatch(resendInvitation({id: user ? user.id : 0}));
  };
  return (
    <KlaimForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      <Header
        title="Edit User"
        description={<p>Update the user’s role and the branches they have access to.</p>}
        backlink="/settings/user-management"
        buttonTitle="Done"
        buttonType="submit"
        body={
          isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className={classes.bodyWrapper}>
              <KlaimInput name="email" label="Email" placeholder="Email@email.com" isDisabled value={user?.email} />
              {/* <div className={classes.buttonContainer}>
                <KlaimButton type="button" title="Resend invitation" onClick={resendClick} />
              </div> */}

              <Field name="role">
                {({field, form, meta}: FieldProps) => {
                  const currentRole = rolesOption.find((option: IOption) => option.value === meta.value);
                  // form.setFieldValue(field.name, currentRole);
                  return (
                    <div style={{padding: 6}}>
                      <label className={classes.label}>Select Role</label>
                      <KlaimDropdownContainer
                        value={meta.value.label ? meta.value.label : currentRole && currentRole?.label}
                        title="Please Select Role"
                        isButtonRight
                        showDescription
                      >
                        <div>
                          {rolesOption.map((option) => (
                            <div
                              className={`${classes.dropdownButtonContainer} ${
                                option.value === currentRole?.value && classes.buttonActive
                              }`}
                              key={option.value}
                            >
                              <button type="button" onClick={() => form.setFieldValue(field.name, option)}>
                                <span className={classes.optionText}>{option.label}</span>
                              </button>
                            </div>
                          ))}
                        </div>
                      </KlaimDropdownContainer>
                      <ErrorMessage name={field.name}>
                        {(errorMessage) => <div className={classes.error}>{errorMessage}</div>}
                      </ErrorMessage>
                    </div>
                  );
                }}
              </Field>
              <Field name="branch">
                {({field, form, meta}: FieldProps) => {
                  return (
                    <div style={{padding: 6}}>
                      <label className={classes.label}>Select Branch(s) (Optional)</label>
                      <KlaimDropdownContainer
                        value={branchesDisplay(meta.value)}
                        title="Please Select Branches"
                        isButtonRight
                        showDescription
                      >
                        <KlaimMultiSelect
                          className={classes.fullWidth}
                          onChange={(selBranches: Branches[]) => {
                            form.setFieldValue(
                              field.name,
                              selBranches.length > 0
                                ? selBranches.map((branch) => {
                                    return {...branch, id: branch.value};
                                  })
                                : undefined,
                            );
                          }}
                          options={branchesOption}
                          selectAllTitle="ALL BRANCHES"
                          value={meta.value}
                        />
                      </KlaimDropdownContainer>
                      <ErrorMessage name={field.name}>
                        {(errorMessage) => <div className={classes.error}>{errorMessage}</div>}
                      </ErrorMessage>
                    </div>
                  );
                }}
              </Field>
            </div>
          )
        }
      />
    </KlaimForm>
  );
};

export default AddStaffMember;
