import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    RowWrapper: {
      alignItems: 'center',
      background: Colors.white,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0',
      top: '2.5em',
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
    },
    // rowData: {
    //   color: Colors.blueGrayDark,
    //   flex: '1',
    //   fontSize: '14px',
    //   letterSpacing: '0px',
    // },
    rowData: {
      color: Colors.blueGrayDark,
      flex: '1',
      fontSize: '14px',
      letterSpacing: '0px',
      textTransform: 'capitalize',
      '& .bordered': {
        width: 'fit-content',
        padding: '5px 15px',
        borderRadius: 3,
        fontWeight: 'bold',
        '&.active': {
          color: Colors.successTextGreen,
          background: Colors.successBackgroundGreen,
        },
        '&.inactive': {
          color: Colors.errorTextRed,
          background: Colors.errorBackgroundRed,
        },
      },
    },
    topNone: {
      top: 70,
    },
    treeViewList: {
      listStyle: 'none',
      margin: '0',
      padding: '15px 30px',
      borderBottom: `1px solid ${Colors.grayLight}`,
      '&:last-child': {border: 0},
    },
    button: {
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: 6,
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    buttonIcon: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      height: 14,
    },
    modalText: {
      marginBottom: 10,
    },
    modalButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingLeft: 80,
      paddingRight: 80,
    },
    modalYesButton: {
      backgroundColor: Colors.purple,
    },
    modalCancelButton: {
      backgroundColor: Colors.red,
    },
    modalButton: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
      width: 100,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: Colors.blueGrayLight,
      borderWidth: 1,
      color: Colors.white,
      fontWeight: 600,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: 14,
      paddingBottom: 40,
      paddingTop: 40,
    },
    navLink: {
      textDecoration: 'none',
    },
  };
});
