import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SelectOptions from 'modules/Common/components/Select';
import {Branch} from 'modules/Pharmacy/features/PriorRequest/types';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    inputError: {
      color: 'red',
      fontSize: 12,
      marginBottom: 0,
      marginTop: 0,
      position: 'absolute',
      bottom: '-20px',
      left: '15px',
    },
    inputWrapper: {
      position: 'relative',
    },
  };
});

interface ComponentProps {
  className?: string;
  value?: string;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  disabled?: boolean;
}

const BranchDropdown: FC<ComponentProps> = ({className, value, onChange, name, error, disabled = false}) => {
  const classes = useStylesFromThemeFunction();
  const [userBranches, setBranches] = useState<Array<any>>([]);
  const {t} = useTranslation();

  const getUserBranches = () => {
    const branches = localStorage.getItem('userBranches');
    if (branches != null) {
      setBranches(JSON.parse(branches));
    }
  };

  useEffect(() => {
    getUserBranches();
  }, []);

  return (
    <div className={classes.inputWrapper}>
      <h2>{t('Choose Branch')}</h2>
      <SelectOptions
        options={userBranches}
        selectClassName={className}
        label={t('Branch')}
        value={value}
        onChange={(e) => (onChange ? onChange(e) : undefined)}
        name={name}
        placeholder={t('Choose Branch')}
        disabled={disabled}
        isClearable={false}
      />
      <span className={classes.inputError}>{error}</span>
    </div>
  );
};

export default BranchDropdown;
