import React from 'react';
import {FormikHelpers} from 'formik';
import {useSelector} from 'react-redux';
import {useHistory, RouteComponentProps} from 'react-router-dom';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Invite/Invite';
import {authSelect, registerCleanUp} from 'modules/Auth/v3/features/Auth/authSlice';
import AccountSetup from 'modules/Auth/v3/features/Invite/AccountSetup';
import CreateAccount from 'modules/Auth/v3/features/Invite/CreateAccount';
import {useAppDispatch} from 'modules/App/store';
import Loading from 'modules/Loading';
import {
  emailInviteValidate,
  inviteSelect,
  inviteCleanUp,
  acceptInvite,
} from 'modules/Auth/v3/features/Invite/inviteSlice';
import ExpiredLink from 'modules/Auth/v3/components/ExpiredLink';

export interface Values {
  [field: string]: any;
}

interface RouteParams {
  hash: string;
}

const Invite: React.FC<RouteComponentProps<RouteParams>> = ({match}) => {
  const {
    params: {hash},
  } = match;
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {success} = useSelector(authSelect);
  const {isValidHash, isHashValidated, isLoading, user, error} = useSelector(inviteSelect);
  const [currentStep, setCurrentStep] = React.useState(0);

  React.useEffect(() => {
    dispatch(emailInviteValidate({hash}));
  }, [hash]);

  React.useEffect(() => {
    if (success) {
      history.push('/auth/registration-complete');
    }
    return () => {
      dispatch(registerCleanUp());
      dispatch(inviteCleanUp());
    };
  }, [success]);

  React.useEffect(() => {
    if (error) {
      setCurrentStep(0);
    }
  }, [error]);

  React.useEffect(() => {
    setData({
      email: user?.email || '',
      password: '',
      name: '',
      phone: '',
    });
  }, [isLoading]);

  const [data, setData] = React.useState({
    email: user?.email || '',
    password: '',
    name: '',
    phone: '',
  });

  const makeRequest = (
    values: Values,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    // TODO INVITE
    dispatch(
      acceptInvite({
        hash,
        values: {
          email: values.email || '',
          password: values.password || '',
          name: values.name || '',
          phone: values.phone || '',
        },
      }),
    );
    // dispatch(tempError());
  };

  const handleNextStep = (
    newData: any,
    final = false,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    setData((prev) => ({...prev, ...newData}));

    if (final) {
      makeRequest(newData, formikHelpers);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev) => ({...prev, ...newData}));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <CreateAccount next={handleNextStep} data={data} />,
    <AccountSetup next={handleNextStep} prev={handlePrevStep} data={data} />,
  ];

  if (isLoading) {
    return <Loading customContainer={classes.loadingContainer} />;
  }
  if (isValidHash === true && isHashValidated === true) {
    return (
      <div className={classes.createAccount}>
        <div className={classes.formContainer}>{steps[currentStep]}</div>
      </div>
    );
  }
  return <ExpiredLink />;
};

export default Invite;
