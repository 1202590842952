import React from 'react';
import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom';
import Avatar from 'assets/Avatar.png';
import hospitalLogo from 'assets/hospital_logo.png';
import StaffProfile from 'modules/Settings/components/StaffProfile';
import moreIcon from 'assets/more_icon.svg';
import EditIcon from 'assets/component/EditIcon';
import DeleteIcon from 'assets/component/DeleteIcon';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {
  IBranch,
  branchManagementSelect,
  deleteBranch,
  deleteCleanup,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import closeIcon from 'assets/close_icon.svg';
import Button from 'modules/Common/components/Button';
import {useStylesFromThemeFunction} from 'modules/Settings/features/BranchManagement/Branches/BranchCollapsibleRow/BranchCollapsibleRow';
import KlaimModal from 'modules/Common/components/KlaimModal';
import {useAppSelector} from 'modules/App/store';
import * as Toast from 'modules/Common/utils/toast';

interface ComponentProps {
  branch: IBranch;
  index: number;
  offset: number;
  limit: number;
}
const BranchCollapsibleRow: React.FC<ComponentProps> = ({
  branch: {id, name, identifier, users, authority},
  index,
  offset,
  limit,
}) => {
  const {isDeleted} = useAppSelector(branchManagementSelect);
  const classes = useStylesFromThemeFunction();
  const dispatch = useDispatch();
  const [rowsOpen, setRowsOpen] = React.useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const triggerDelete = () => {
    dispatch(deleteBranch(id));
  };

  React.useEffect(() => {
    if (isDeleted) {
      Toast.success('Branch is deleted successfully', 'Success');
      setDeleteOpen(false);
    }
    return () => {
      dispatch(deleteCleanup);
    };
  }, [isDeleted]);

  return (
    <li className={classes.treeViewList} key={id}>
      <KlaimModal contentClass={classes.modal} isOpen={deleteOpen}>
        <div className={classes.deleteBranchContainer}>
          <div className={classes.headerContainer}>
            <h1>Delete Branch</h1>
            <img onClick={() => setDeleteOpen(false)} src={closeIcon} alt="Close-icon" />
          </div>
          <div className={classes.messageContainer}>
            <div>
              <h2>Are you sure you want to delete?</h2>
              <p className={classes.nameText}>{name}</p>
            </div>
            <hr />
          </div>
          <div className={classes.optionsButtonContainer}>
            <Button title="No, Go back" onClick={() => setDeleteOpen(false)} buttonClassName={classes.backButton} />
            <Button
              title="Delete Branch"
              onClick={() => {
                setDeleteOpen(false);
                triggerDelete();
              }}
            />
          </div>
        </div>
      </KlaimModal>
      <div
        className={`${classes.branchManagementTableRow} ${classes.topNone}`}
        role="button"
        onClick={() => setRowsOpen(!rowsOpen)}
      >
        <div className={classes.numberOfBranchesWrapper}>
          <div className={classes.numberOfBranches}>
            <div>{(offset - 1) * limit + index + 1}</div>
          </div>
        </div>
        <div className={classes.hospitalInfo}>
          <img src={hospitalLogo} alt="avatar" /> {name}
        </div>
        <div className={classes.rowData}>{identifier}</div>
        <div className={classes.rowData}>{authority}</div>
        <div className={classes.staffProfile}>
          <StaffProfile imageSrc={[Avatar, Avatar, Avatar]} />
          <span>{users.length}</span>
        </div>

        <KlaimDropdownContainer isIconButton icon={moreIcon}>
          {/* <NavLink to={`/settings/branch-management/edit/${id}`} className={`${classes.button} ${classes.navLink}`}>
            <div className={classes.buttonIcon}>
              <EditIcon />
            </div>
            Edit Branch
          </NavLink> */}
          <button className={classes.button} onClick={() => setDeleteOpen(true)}>
            <div className={classes.buttonIcon}>
              <DeleteIcon />
            </div>
            Delete Branch
          </button>
        </KlaimDropdownContainer>
      </div>
    </li>
  );
};
export default BranchCollapsibleRow;
