import React from 'react';
import {NavLink} from 'react-router-dom';
import moreIcon from 'assets/more_icon.svg';
import EditIcon from 'assets/component/EditIcon';
import EllipsisText from 'react-ellipsis-text';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {Practitioner} from 'modules/Settings/features/Practitioners/PractitionersSlice';
import {useStylesFromThemeFunction} from 'modules/Settings/features/Practitioners/PractitionersList/ListItem/ListItem';
import KlaimModal from 'modules/Common/components/KlaimModal';

interface ComponentProps {
  practitioner: Practitioner;
  onClick: any;
}
const ListItem: React.FC<ComponentProps> = ({
  practitioner: {id, name, active, gender, providerId, roles, branches, license_number},
  onClick,
}) => {
  const classes = useStylesFromThemeFunction();
  const [rowsOpen, setRowsOpen] = React.useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const status: string = active ? 'active' : 'inactive';
  const branchPlus = branches.length > 1 ? `... + ${branches.length - 1}` : '';
  const branchDisplay = `${branches[0].name} ${branchPlus}`;
  return (
    <li className={classes.treeViewList} key={id} onClick={onClick}>
      <KlaimModal
        isOpen={deleteOpen}
        onRequestClose={() => setDeleteOpen(false)}
        contentStyle={{
          left: '50%',
          maxHeight: '250px',
          maxWidth: '550px',
          padding: 10,
          top: '35%',
          transform: 'translateX(-50%)',
        }}
      >
        <div className={classes.modalContainer}>
          <p className={classes.modalText}>
            Are you sure you want to delete practitioner: <span>{name}</span>
          </p>
          <div className={classes.modalButtonContainer}>
            <button className={`${classes.modalButton} ${classes.modalYesButton}`} onClick={() => {}}>
              Yes
            </button>
            <button
              className={`${classes.modalButton} ${classes.modalCancelButton}`}
              onClick={() => setDeleteOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </KlaimModal>
      <div className={`${classes.RowWrapper} ${classes.topNone}`} role="button" onClick={() => setRowsOpen(!rowsOpen)}>
        <div className={classes.rowData}>{name}</div>
        <div className={classes.rowData}>{gender}</div>
        <div className={classes.rowData}>{roles[0] ? roles[0].name : '-'}</div>
        <div className={classes.rowData}>
          <EllipsisText text={branchDisplay} length={'40'} />
        </div>
        <div className={classes.rowData}>
          <div className={`bordered ${status}`}>{status}</div>
        </div>

        <KlaimDropdownContainer isIconButton icon={moreIcon}>
          <NavLink to={`/settings/practitioners/edit/${id}`} className={`${classes.button} ${classes.navLink}`}>
            <div className={classes.buttonIcon}>
              <EditIcon />
            </div>
            Edit Practitioner
          </NavLink>
        </KlaimDropdownContainer>
      </div>
    </li>
  );
};
export default ListItem;
