import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  KlaimButton,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
  KlaimToggle,
} from '../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from '../styles';
import {contractsManagementSelect, errorCleanUp, patchContract, postContract, getContract} from '../ContractSlice';
import * as Toast from '../../../../Common/utils/toast';
import {useAppSelector} from '../../../../App/store';
import {Card, Header, Row, Wrapper} from '../styled';
import {
  branchManagementSelect,
  getBranches,
  getUserCurrentBranches,
} from '../../BranchManagement/branchManagementSlice';
import {Branch} from '../../../../../interfaces/branches.interfaces';
import {IOptions} from '../../../../../interfaces/general.interfaces';
import {getInsurances, insuranceSelect} from '../../../../Insurance/insuranceSlice';
import {Payer} from '../../../../../interfaces/payers.interfaces';
import {insurancesManagementSelect} from '../../Insurance/InsuranceSlice';
import {headerSelect} from '../../../../Header/headerSlice';

const Component: React.FC = () => {
  const s = useStylesFromThemeFunction();
  const history = useHistory();
  const [selectedBranch, setSelectedBranch] = React.useState<Branch>();
  const [branchOptions, setBranchOptions] = React.useState<IOptions[]>([]);
  const {startDate, endDate, submitOnClick} = useAppSelector(headerSelect);

  const {id}: {id: string} = useParams();
  const {isLoading, error, apiCallSuccess, contract} = useAppSelector(contractsManagementSelect);

  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (branches.length > 0 && id)
      dispatch(
        getContract({
          id,
          providerId: getProviderIds(),
        }),
      );
  }, [branches, id]);

  const handleSubmit = (data) => {
    if (id) {
      dispatch(patchContract({...data, id, discount: +data.discount || 0, unitPrice: +data.unitPrice}));
    }
  };

  useEffect(() => {
    if (error) {
      Toast.error('There was an error while treating your request');
      dispatch(errorCleanUp());
    }
  }, [error]);

  useEffect(() => {
    if (apiCallSuccess) {
      Toast.success('Operation succeeded');
      history.push('/settings/contracts-management');
      dispatch(errorCleanUp());
    }
  }, [apiCallSuccess]);
  const {userBranches} = useAppSelector(branchManagementSelect);

  React.useEffect(() => {
    dispatch(getUserCurrentBranches({}));
    // dispatch(getInsurances({authorityCode: 'NPHIES', limit: 300}));
  }, []);

  React.useEffect(() => {
    if (userBranches) {
      setBranchOptions(
        userBranches.map((branch) => {
          return {
            label: branch.name,
            value: branch.identifier,
          };
        }),
      );
    }
  }, [userBranches]);
  const [selectedTPA, setSelectedTPA] = React.useState(null);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);

  const {
    insurances: {data: insurances, count: dataCount},
  } = useAppSelector(insurancesManagementSelect);

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  useEffect(() => {
    dispatch(
      getInsurances({
        limit: 100,
        offset: 0,
        startDate,
        endDate,
        providerId: getProviderIds(),
      }),
    );
  }, [startDate, endDate, branches]);

  React.useEffect(() => {
    if (insurances && insurances.length > 0) {
      setInsuranceOptions(
        insurances.map((payer: any) => {
          return {
            label: `${payer.longName} ${payer.tpas.length > 0 ? ` - ${payer.tpas.map((el) => el.longName)}` : ''}`,
            value: payer._id,
          };
        }),
      );
    }
  }, [insurances]);
  return (
    <Wrapper>
      <Card>
        <Header>
          <h1 className={s.header} style={{marginBottom: 0}}>
            Contract Management
          </h1>
          <div />
        </Header>
      </Card>
      <br />
      <Card style={{padding: 12, paddingLeft: 32}}>
        <KlaimForm
          enableReinitialize
          initialValues={{
            hcpCode: contract?.hcpCode,
            hcpDescription: contract?.hcpDescription,
            nphiesCode: contract?.nphiesCode,
            isPackageDrug: contract?.isPackageDrug,
            isPackageService: contract?.isPackageService,
            unitPrice: contract?.unitPrice,
            discount: contract?.discount,
            hci: contract?.hci,
          }}
          validationSchema={Yup.object({
            hcpCode: Yup.string().required(),
            hcpDescription: Yup.string().required(),
            nphiesCode: Yup.string().required(),
            isPackageDrug: Yup.boolean(),
            isPackageService: Yup.boolean(),
            unitPrice: Yup.number().required(),
            discount: Yup.number(),
            hci: Yup.string().required(),
          })}
          onSubmit={handleSubmit}
          style={{maxWidth: 500, gap: 16, display: 'flex', flexDirection: 'column'}}
          id="list-claim-form"
        >
          <b>Manual Entry</b>Configure the list of services by HIC/TPA and Branch
          {userBranches.length > 0 && (
            <KlaimFieldWrapper
              name={'providerId'}
              onChange={(value: any) => {
                setSelectedBranch(userBranches.find((branch) => value === branch.identifier));
              }}
            >
              <KlaimDropdownSearch
                options={branchOptions}
                placeholder={'Select Branch'}
                name="patient"
                label="Choose Branch"
                variant={'secondary'}
              />
            </KlaimFieldWrapper>
          )}
          <KlaimFieldWrapper name={'hci'} onChange={setSelectedTPA}>
            <KlaimDropdownSearch variant="secondary" label="HIC/TPA" options={insuranceOptions} />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="hcpCode" onChange={(data) => {}}>
            <KlaimInput variant="secondary" placeholder="HCP Code" label="HCP Code" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="hcpDescription" onChange={(data) => {}}>
            <KlaimInput variant="secondary" placeholder="HCP Description" label="HCP Description" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="nphiesCode" onChange={(data) => {}}>
            <KlaimInput variant="secondary" placeholder="NPHIES Code" label="NPHIES Code" />
          </KlaimFieldWrapper>
          <Row>
            <KlaimFieldWrapper name="isPackageDrug" onChange={(data) => {}} style={{flexGrow: 1}}>
              <KlaimToggle width={56} title="is drug" />
            </KlaimFieldWrapper>
            <KlaimFieldWrapper name="isPackageService" onChange={(data) => {}} style={{flexGrow: 1}}>
              <KlaimToggle width={56} title="is Package service" />
            </KlaimFieldWrapper>
          </Row>
          <Row>
            <KlaimFieldWrapper name="unitPrice" onChange={(data) => {}} style={{flexGrow: 1}}>
              <KlaimInput type="number" variant="secondary" label="Unit Price" placeholder="Unit Price" />
            </KlaimFieldWrapper>
            <KlaimFieldWrapper name="discount" onChange={(data) => {}} style={{flexGrow: 1}}>
              <KlaimInput
                name="discount"
                variant="secondary"
                label="Discount"
                placeholder="Discount %"
                min={'0'}
                max={'1'}
                onChange={(value) => {}}
              />
            </KlaimFieldWrapper>
          </Row>
          <div>
            <KlaimButton variant="primary" type={'submit'}>
              Save Service
            </KlaimButton>
          </div>
        </KlaimForm>
      </Card>
    </Wrapper>
  );
};

export default Component;
