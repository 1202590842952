import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export interface ComponentProps {
  value?: any;
  name?: string;
  label?: string;
  placeholder?: string;
  errors?: string;
  variant?: 'primary' | 'secondary';
  onChange?: (value: any | undefined) => void;
  isError?: boolean;
  isTouched?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const useStylesFromThemeFunction = (variant: string) => {
  return createUseStyles((theme: KlaimTheme) => {
    return {
      calendar: {
        '& .inputError': {
          color: 'red',
          fontSize: 12,
          marginBottom: 0,
          marginTop: 0,
        },
        '& .rdt > input': {
          backgroundColor: `${
            variant === 'primary' ? theme.container.background.primary : theme.container.background.secondary
          } !important`,
          padding: '2px 8px',
          height: 36,
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '10px',
          borderColor: theme.formElement.border.primary,
        },
        '& .rdtActive': {
          backgroundColor: `${theme.formButton.background.secondary} !important`,
        },
        '& .rdtToday::before': {
          borderBottomColor: `${theme.formButton.background.secondary} !important`,
        },
      },
      inputContainerDefault: {
        '& .rdt > input': {
          backgroundColor: theme.formElement.background.primary,
          borderColor: theme.formElement.border.primary,
        },
      },
      inputContainerValidated: {
        '& .rdt > input': {
          borderColor: theme.formElement.border.validated,
        },
      },
      inputContainerError: {
        '& .rdt > input': {
          borderColor: theme.formElement.border.error,
        },
      },
    };
  })();
};
