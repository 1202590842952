import React from 'react';
import {useTranslation} from 'react-i18next';
import {FieldArray, getIn} from 'formik';
import RemoveIcon from '../../../../assets/component/RemoveIcon';
import DateInput from '../../../Common/components/DateInput';
import AsyncSearchSelect from '../../../Common/components/AsyncSearchSelect';
import Input from '../../../Common/components/Input';
import SelectOptions from '../../../Common/components/Select';
import {ACTIVITY_TYPE, ActivityCode, ClaimActivity, Clinician, Observation} from '../../api/types';
import Button from '../../../Common/components/Button';
import ActivityObservation from '../ActivityObservation';
import {getActivityCode, getClinicians} from '../../api';
// import * as Toast from '../../../Common/utils/toast';

interface ComponentProps {
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  setFieldValue: any;
  classes: any;
  handleBlur?: any;
}

const Activity: React.FC<ComponentProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  classes,
  handleBlur,
}) => {
  const {t} = useTranslation();

  const handleInputChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    if (typeof event === 'object') {
      return;
    }
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const handleInputIDChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event.target.value !== undefined ? event.target.value : '';
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const getCliniciansList = async (clinicianId: string) => {
    if (clinicianId && clinicianId.length > 0) {
      try {
        const {data} = await getClinicians(clinicianId, values.authorityCode);
        return data.data
          .map((code: Clinician) => {
            if (!code.name)
              return {
                label: `${code.clinicianId}`,
                value: code.clinicianId,
              };
            return {label: `${code.clinicianId} - ${code.name}`, value: code.clinicianId};
          })
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getCliniciansOrderingList = async (clinicianId: string) => {
    if (clinicianId && clinicianId.length > 0) {
      try {
        const {data} = await getClinicians(clinicianId, values.authorityCode);
        return data.data
          .map((code: Clinician) => {
            if (!code.name)
              return {
                label: `${code.orderingClinicianId}`,
                value: code.orderingClinicianId,
              };
            if (code.orderingClinicianId === undefined) {
              return {label: `${code.clinicianId} - ${code.name}`, value: code.clinicianId};
            }
            return {label: `${code.orderingClinicianId} - ${code.name}`, value: code.orderingClinicianId};
          })
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getActivitiesCode = async (searchString: string, activityType: string) => {
    if (searchString && searchString.length > 0) {
      try {
        const {data} = await getActivityCode(searchString, activityType, values.authorityCode);
        return data.data
          .map((code: ActivityCode) => {
            if (code.drug)
              return {
                label: `${code.drug.name} – ${code.drug.strength}, ${code.drug.dosage_form_package}, ${code.drug.manufacturer}`,
                value: code.activityCode,
              };
            if (code.shortDescription !== '') {
              return {label: `${code.activityCode} - ${code.shortDescription}`, value: code.activityCode};
            }
            return {label: `${code.activityCode} - ${code.longDescription}`, value: code.activityCode};
          })
          .sort((a: ActivityCode, b: ActivityCode) => (a.activityCode > b.activityCode ? 1 : -1));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const scrollToElementId = (elementId: string) => {
    setTimeout(() => {
      const itemToScrollTo = document.getElementById(elementId);
      if (itemToScrollTo) itemToScrollTo.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, 0);
  };

  const scrollToActivity = (activities: any) => {
    const activityId = activities.length + 1;
    scrollToElementId(`activity-${activityId}`);
  };

  const getLastActivityId = (activity: Array<ClaimActivity>) => {
    return activity[activity.length - 1].activityIds;
  };

  const getLastObservationId = (observation: Array<Observation>) => {
    return observation[observation.length - 1]?.observationId ?? '0';
  };

  const onDateInputChange = (before: string, after: string, field: string) => {
    const pattern: [number, string][] = [
      [2, '/'],
      [5, '/'],
      [10, ' '],
      [13, ':'],
    ];
    const newValue = getAutoFormattedValue(after, before, pattern);
    if (!newValue) return;
    setFieldValue(field, newValue);
  };

  const getAutoFormattedValue = (after: string, before: string, pattern: [number, string][]) => {
    const isDeleting = !after || !before || after.length <= before.length;
    if (isDeleting) return undefined;

    if (before.length > 0) {
      const lastCharBefore = before[before.length - 1];
      const newChar = after[after.length - 1];
      const newCharShouldBeIgnored = ['/', '', ':'].includes(lastCharBefore) && ['/', '', ':'].includes(newChar);
      if (newCharShouldBeIgnored) return before;
    }

    const autoFormattingNewChar = pattern.find(([position]) => position === after.length);
    if (autoFormattingNewChar) return after + autoFormattingNewChar[1];

    const autoFormattingMissingChar = pattern.find(
      ([position, char]) => position === before.length && after[after.length - 1] !== char,
    );
    if (autoFormattingMissingChar)
      return after.slice(0, after.length - 1) + autoFormattingMissingChar[1] + after[after.length - 1];
  };

  return (
    <FieldArray
      name="activities"
      render={(activityHelper) => (
        <>
          {values.activities &&
            values.activities.length &&
            values.activities.map((activity: any, index: number) => {
              return (
                <div key={activity.activityIds}>
                  <div className={classes.formSectionElementsWrapper} id={`activity-${index + 1}`}>
                    <h2 className={`${classes.dFlex} ${classes.justifyContentBetween}`}>
                      {t(`Activity #${index + 1}`)}
                      {values.activities.length !== 1 && (
                        <>
                          <div className={classes.separatorBorder} />
                          <div
                            role="button"
                            className={classes.removeActivityBtn}
                            onClick={() => {
                              activityHelper.remove(values.activities.indexOf(activity));
                            }}
                          >
                            <RemoveIcon />
                          </div>
                        </>
                      )}
                    </h2>
                    <div className={classes.rowInputs}>
                      <Input
                        label="Start Date"
                        className={classes.rowInput}
                        placeholder="DD/MM/YYYY HH:MM"
                        name={`activities[${index}].start`}
                        value={values.activities[index].start}
                        onChange={(e) => handleInputIDChangeSecond(e, values.activities[index], 'start')}
                        onValueChange={(e) =>
                          onDateInputChange(values.activities[index].start, e, `activities[${index}].start`)
                        }
                        maxLength={16}
                        error={
                          getIn(errors, `activities[${index}].start`) && getIn(touched, `activities[${index}].start`)
                            ? getIn(errors, `activities[${index}].start`)
                            : null
                        }
                        onBlur={handleBlur}
                      />
                      <AsyncSearchSelect
                        name={`activities[${index}].clinicianId`}
                        className={classes.rowInputRight}
                        placeholder={t('Enter Clinician')}
                        loadOptions={getCliniciansList}
                        value={values.activities[index].clinicianId}
                        defaultValue={values.activities[index].clinicianValue}
                        onChange={(e) => {
                          handleInputChangeSecond(e, values.activities[index], 'clinicianId');
                        }}
                        label="Clinician"
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].clinicianId &&
                          getIn(touched, `activities[${index}].clinicianId`)
                            ? errors.activities[index].clinicianId
                            : null
                        }
                      />
                      <AsyncSearchSelect
                        name={`activities[${index}].orderingClinicianId`}
                        className={classes.rowInputRight}
                        placeholder={t('Enter Ordering Clinician')}
                        loadOptions={getCliniciansOrderingList}
                        value={values.activities[index].orderingClinicianId}
                        defaultValue={values.activities[index].orderingClinicianValue}
                        onChange={(e) => {
                          handleInputChangeSecond(e, values.activities[index], 'orderingClinicianId');
                        }}
                        label="Ordering Clinician"
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].orderingClinicianId &&
                          getIn(touched, `activities[${index}].orderingClinicianId`)
                            ? errors.activities[index].orderingClinicianId
                            : null
                        }
                      />
                    </div>
                    <Input
                      name={`activities[${index}].priorAuthorizationId`}
                      value={activity.priorAuthorizationId}
                      onChange={(e) => {
                        handleInputIDChangeSecond(e, values.activities[index], 'priorAuthorizationId');
                      }}
                      label="Prior Authorization ID"
                      placeholder={t('Enter Prior Authorization ID')}
                      className={classes.formElementWithBottomSpacing}
                    />
                    <div className={`${classes.rowInputs} ${classes.rowInputSelect}`}>
                      <SelectOptions
                        name={`activities[${index}].type`}
                        options={ACTIVITY_TYPE}
                        label={t('Type')}
                        value={values.activities[index].type}
                        onChange={(e) => setFieldValue(`activities[${index}].type`, e)}
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].type &&
                          getIn(touched, `activities[${index}].type`)
                            ? errors.activities[index].type
                            : null
                        }
                        selectClassName={classes.rowInput}
                        placeholder={t('Select Type')}
                        isClearable={false}
                      />
                      <AsyncSearchSelect
                        name={`activities[${index}].code`}
                        className={classes.rowInputRight}
                        placeholder={t('Enter Code')}
                        loadOptions={(e: any) => getActivitiesCode(e, values.activities[index].type)}
                        value={activity.code}
                        defaultValue={values.activities[index].activityPerformed}
                        onChange={(e) => {
                          handleInputChangeSecond(e, values.activities[index], 'code');
                        }}
                        label="Code"
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].code &&
                          getIn(touched, `activities[${index}].code`)
                            ? errors.activities[index].code
                            : null
                        }
                      />
                    </div>
                    <div className={classes.rowInputs}>
                      <Input
                        name={`activities[${index}].quantity`}
                        value={values.activities[index].quantity ? values.activities[index].quantity : ''}
                        onChange={handleChange}
                        label="Quantity"
                        placeholder="0"
                        className={classes.positionRelative}
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].quantity &&
                          getIn(touched, `activities[${index}].quantity`)
                            ? errors.activities[index].quantity
                            : null
                        }
                      />
                      <Input
                        name={`activities[${index}].gross`}
                        value={values.activities[index].gross ? values.activities[index].gross : ''}
                        onChange={(e) => {
                          handleInputChangeSecond(e, values.activities[index], 'gross');
                        }}
                        label="Gross"
                        placeholder="0.00"
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].gross &&
                          getIn(touched, `activities[${index}].gross`)
                            ? errors.activities[index].gross
                            : null
                        }
                        className={classes.positionRelative}
                      />
                      <Input
                        name={`activities[${index}].net`}
                        value={values.activities[index].net ? values.activities[index].net : ''}
                        onChange={(e) => {
                          handleInputChangeSecond(e, values.activities[index], 'net');
                        }}
                        label="Net"
                        placeholder="0.00"
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].net &&
                          getIn(touched, `activities[${index}].net`)
                            ? errors.activities[index].net
                            : null
                        }
                        className={classes.positionRelative}
                      />
                      <Input
                        name={`activities[${index}].patientShare`}
                        value={
                          values.activities[index].patientShare !== undefined
                            ? values.activities[index].patientShare
                            : ''
                        }
                        onChange={(e) => {
                          handleInputChangeSecond(e, values.activities[index], 'patientShare');
                        }}
                        label="Patient Share"
                        placeholder="0.00"
                        error={
                          errors.activities &&
                          errors.activities[index] &&
                          errors.activities[index].patientShare &&
                          getIn(touched, `activities[${index}].patientShare`)
                            ? errors.activities[index].patientShare
                            : null
                        }
                        className={classes.positionRelative}
                      />
                    </div>
                  </div>
                  <FieldArray
                    name={`activities[${index}].observations`}
                    render={(observationHelpers) => (
                      <>
                        {values.activities[index].observations.map((observation: Observation, i: number) => (
                          <ActivityObservation
                            handleChange={handleChange}
                            observation={observation}
                            activityIndex={index}
                            observationIndex={i}
                            errors={errors}
                            touched={touched}
                            setFieldTouched={setFieldTouched}
                            observationHelper={observationHelpers}
                            activities={values.activities}
                            setFieldValue={setFieldValue}
                            values={values}
                            classes={classes}
                          />
                        ))}
                      </>
                    )}
                  />
                  {index + 1 === values.activities.length && (
                    <div className={classes.formSectionElementsWrapper} key={`addActivity-${index}`}>
                      <div className={`${classes.dFlex} ${classes.justifyContentBetween}`}>
                        <h2>{t(`Activity #${index + 2}`)}</h2>
                        <div className={classes.separatorBorder} />
                        <Button
                          title="+"
                          type="button"
                          buttonClassName={classes.addActivityBtn}
                          onClick={() => {
                            activityHelper.push({
                              activityIds: (+getLastActivityId(values.activities) + 1).toString(),
                              start: values.activities[0].start,
                              type: 3,
                              code: '',
                              quantity: 0,
                              gross: 0,
                              net: 0,
                              patientShare: 0,
                              clinicianId: '',
                              priorAuthorizationId: '',
                              observations: [
                                {
                                  type: '',
                                  code: '',
                                  value: '',
                                  valueType: '',
                                  observationId: (
                                    +getLastObservationId(values.activities[index].observations) + 1
                                  ).toString(),
                                },
                              ],
                            });
                            scrollToActivity(values.activities);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </>
      )}
    />
  );
};

export default Activity;
