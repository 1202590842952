import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    loading: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      paddingTop: 30,
      width: '100%',
      gap: 30,
    },
    header: {
      padding: 24,
      background: '#FFFFFF 0% 0% no-repeat',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto auto',
      gap: '12px 95px',
      // minWidth: '1200px',
    },
    statusCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexGap: 10,
    },
    status: {
      height: 12,
      width: 12,
      borderRadius: 12,
      backgroundColor: Colors.successTextGreen,
      '&.submitting, &.submitted, &.resubmitting, &.acknowledged': {backgroundColor: Colors.purple},
      '&.cancelling, &.cancelled': {backgroundColor: Colors.grayMediumLight},
      '&.error': {backgroundColor: Colors.red},
      '&.approved': {backgroundColor: Colors.lightGreen},
      '&.acknowledgement': {backgroundColor: Colors.lightDimGreen},
    },
    statusText: {
      marginLeft: 10,
      textTransform: 'capitalize',
    },
    labelValue: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      font: 'normal normal normal 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#222B45',
      fontSize: 14,
    },
    spacedRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexGrow: 1,
      maxWidth: 200,
    },
    approvedStatus: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#33E5BE',
      fontSize: 14,
      padding: '4px 7px',
      backgroundColor: '#CFF5ED',
      borderRadius: 5,
      '&:hover': {
        '& $popup': {display: 'block'},
      },
    },
    deniedStatus: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#E64444',
      fontSize: 14,
      padding: '4px 7px',
      backgroundColor: '#FFA7A7',
      borderRadius: 5,
    },
    popup: {
      position: 'absolute',
      top: 30,
      left: 0,
      display: 'none',
      width: 350,
      backgroundColor: 'white',
      boxShadow: '0px 3px 8px #60617061;',
      padding: 12,
      borderRadius: 8,
      '& h1': {
        font: 'normal normal normal 16px/12px Poppins',
        letterSpacing: 0.16,
        color: '#222B45',
      },
      '& p': {
        font: 'normal normal normal 12px/16px Poppins;',
        letterSpacing: 0.4,
        color: '#696F81',
        marginTop: 8,
      },
    },
    date: {
      font: 'normal normal normal 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#3F465D',
      fontSize: 14,
    },
    value: {
      letterSpacing: '1.5px',
      color: '#696F81',
      textTransform: 'uppercase',
      font: 'normal normal medium 10px/16px Poppins',
      fontSize: 10,
    },
    tableContainer: {
      backgroundColor: 'white',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      marginTop: 26,
      position: 'relative',
      overflowX: 'auto',
      width: 'auto',
    },
    tableBodyContainer: {},
    tableHeaderContainer: {},
    tableHeader: {
      borderBottom: '1px solid #E7ECF5',
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'row',
    },
    tableRow: {
      padding: '16px 24px',
      display: 'flex',
      flexDirection: 'row',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
    },
    pagination: {
      // width: 1200,
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      backgroundColor: 'white',
      borderRadius: 10,
      padding: 16,
      boxShadow: '0px 0.5px 2px #60617029',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20,
    },
    sectionIcon: {width: 26, height: 26},
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
    },
    sectionHeader: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px solid #E7ECF5',
      paddingBottom: 12,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sectionContent: {},
    sectionTitle: {
      letterSpacing: 0.5,
      color: '#222B45',
      font: 'normal normal normal 16px/24px Poppins',
      fontSize: 14,
    },
    respRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 26,
      marginTop: 22,
      '@media screen and (max-width: 1400px)': {
        flexWrap: 'wrap',
      },
    },
    respFieldWrapper: {
      flex: '1 1 0',
      '@media screen and (max-width: 1400px)': {
        flex: '1 1 25% !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    responsiveRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 26,
      marginTop: 22,
    },
    respTableRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 26,
      marginTop: 22,
      '@media screen and (max-width: 1400px)': {
        width: '100% !important',
      },
    },
    fieldWrapper: {
      flex: '1 1 0',
      width: 0,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
    },
    save: {
      padding: '6px 16px',
      backgroundColor: '#28a745',
      color: 'white',
      borderRadius: 4,
      '&:hover': {
        opacity: 0.8,
      },
    },
    update: {
      padding: '6px 16px',
      backgroundColor: '#007bff',
      color: 'white',
      borderRadius: 4,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
    delete: {
      padding: '6px 16px',
      backgroundColor: '#dc3545',
      color: 'white',
      borderRadius: 4,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
    buttonsRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      cursor: 'pointer',
    },
    absoluteButton: {position: 'absolute', right: 0, bottom: 0, cursor: 'pointer'},
    button: {
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: '5px 15px',
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    navLink: {
      textDecoration: 'none',
    },
  };
});

// careTeam: [
//   {
//     onSite: true,
//     code: 'doctor',
//     practitionerType: 'MD',
//     practitionerCode: '123',
//     specialty: '08.00',
//     careTeamRoleCode: 'primary',
//   },
// ];

// item: {
//   "itemCareTeamSeq": [
//     1
//   ],
//     "invoiceNo": {
//     "number": "2",
//       "system": "www.gnp.sa"
//   },
//   "itemIsPackage": false,
//     "itemTax": 19.13,
//     "itemPatientShare": 0,
//     "itemCode": "73050-23-80",
//     "itemCodeHcp": "1",
//     "itemCodeDisplay": "LDL-CHOLESTEROL",
//     "servicedDate": "2022-08-09",
//     "factor": 0.85,
//     "itemQty": 1,
//     "itemUnitPrice": 150
// }

// {
//   "itemIndex": 1,
//   "itemOutcome": "approved",
//   "adjudication": [
//   {
//     "category": "eligible",
//     "amount": "0.00",
//     "reasonIfNotApproved": null
//   },
//   {
//     "category": "benefit",
//     "amount": "9.60",
//     "reasonIfNotApproved": null
//   },
//   {
//     "category": "tax",
//     "amount": "1.44",
//     "reasonIfNotApproved": null
//   },
//   {
//     "category": "copay",
//     "amount": "1.14",
//     "reasonIfNotApproved": null
//   },
//   {
//     "category": "deductible",
//     "amount": "0.00",
//     "reasonIfNotApproved": null
//   },
//   {
//     "category": "approved-quantity",
//     "amount": null,
//     "reasonIfNotApproved": null
//   }
// ]
// }
