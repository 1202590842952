import React, {FC, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {headerSelect, setSubmitAllButtonOnClick, setSubmitButtonOnClick} from 'modules/Header/headerSlice';
import {events, Mixpanel} from 'services/mixpanel';
import dayjs from 'dayjs';
import timeLogo from 'assets/time.svg';
import UserLogo from 'assets/user_small.svg';
import exclamationIcon from 'assets/exclamation.svg';
import queryString from 'query-string';
import {get} from 'lodash';
import {Pagination, PaginationProps, Table, Typography} from 'custom-test-antd';
import {ColumnsType} from 'antd/lib/table';
import {Col, Row} from 'antd';
import {useAppDispatch, useAppSelector} from '../../../App/store';
import {getClaimType, data, visitTypeData} from './data';
import {useStylesFromThemeFunction} from './MidTableList';
import {
  errorCleanUp,
  getClaims,
  getClaimsTotal,
  midtableSelect,
  postClaimsAll,
  postClaimsBulk,
} from '../../midTableSlice';
import * as Toast from '../../../Common/utils/toast';
import KlaimDropdownContainer from '../../../Common/components/KlaimDropdownContainer';
import moreIcon from '../../../../assets/more_icon_2.svg';
import {Midtable} from '../../../../interfaces/midtable.interface';

const DATE_FORMAT = `DD MMM 'YY`;

const formatBranchDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};

const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

const Component: FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const {
    startDate,
    endDate,
    submitOnClick,
    submitAllOnClick,
    selectedPayer,
    selectedReceiver,
    selectedVisits,
    selectedSynced,
    selectedType,
    selectedSpecialties,
  } = useAppSelector(headerSelect);
  const {selectedBranches} = useAppSelector(headerSelect);
  const {
    midtables: {data: dataList, count: dataCount},
    totals,
    error,
    apiCallSuccess,
  } = useAppSelector(midtableSelect);

  const {search} = queryString.parse(location.search);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const newDataSource = dataList.map((dataEl) => ({
      ...dataEl,
      key: dataEl.hisId,
    }));
    setDataSource(newDataSource);
  }, [dataList]);

  const getAdditionalRequestParams = () => {
    const prId =
      selectedBranches.length > 0 ? {providerId: selectedBranches.map((provider) => provider.value).join(',')} : {};
    const pId = selectedPayer.length > 0 ? {payerId: selectedPayer.map((el: any) => el.value).join(',')} : {};
    const rId = selectedReceiver.length > 0 ? {receiverId: selectedReceiver.map((el: any) => el.value).join(',')} : {};
    const vts = selectedVisits.length > 0 ? {claimType: selectedVisits.map((el: any) => el.value).join(',')} : {};
    const synced = selectedSynced.length === 1 ? {isSynchronized: selectedSynced.some((select) => select.value)} : {};
    const tps = selectedType.length > 0 ? {claimTypeMain: selectedType.map((el: any) => el.value).join(',')} : {};
    const spcts =
      selectedSpecialties.length > 0 ? {claimSpeciality: selectedSpecialties.map((el: any) => el.value).join(',')} : {};

    return {
      ...pId,
      ...prId,
      ...vts,
      ...tps,
      ...synced,
      ...spcts,
      ...rId,
    };
  };

  useEffect(() => {
    if (submitOnClick) {
      dispatch(
        postClaimsBulk({
          providerId: selectedBranches
            .map((provider) => provider.value)
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            })
            .join(','),
          hisIds: selectedRowKeys,
        }),
      );
      dispatch(setSubmitButtonOnClick(false));
    }
    if (submitAllOnClick) {
      dispatch(
        postClaimsAll({
          batchDateStart: formatBranchDate(startDate),
          batchDateEnd: formatBranchDate(endDate),
          ...getAdditionalRequestParams(),
          search: search as string,
        }),
      );
      dispatch(setSubmitAllButtonOnClick(false));
    }
  }, [submitOnClick, submitAllOnClick]);

  useEffect(() => {
    if (error) {
      dispatch(errorCleanUp());
    }
  }, [error]);

  useEffect(() => {
    if (apiCallSuccess) {
      setSelectedRowKeys([]);
      Toast.success('Operation succeeded');
      setTimeout(() => {
        dispatch(
          getClaims({
            limit,
            offset: (offset - 1) * limit,
            batchDateStart: formatBranchDate(startDate),
            batchDateEnd: formatBranchDate(endDate),
            ...getAdditionalRequestParams(),
            search: search as string,
          }),
        );
      }, 1000);
      dispatch(errorCleanUp());
    }
  }, [apiCallSuccess]);

  useEffect(() => {
    dispatch(
      getClaimsTotal({
        limit,
        offset: (offset - 1) * limit,
        batchDateStart: formatBranchDate(startDate),
        batchDateEnd: formatBranchDate(endDate),
        ...getAdditionalRequestParams(),
        search: search as string,
      }),
    );

    dispatch(
      getClaims({
        limit,
        offset: (offset - 1) * limit,
        batchDateStart: formatBranchDate(startDate),
        batchDateEnd: formatBranchDate(endDate),
        ...getAdditionalRequestParams(),
        search: search as string,
      }),
    );
  }, [
    limit,
    offset,
    startDate,
    endDate,
    selectedBranches,
    selectedPayer,
    selectedVisits,
    selectedSynced,
    search,
    selectedType,
    selectedSpecialties,
    selectedReceiver,
  ]);

  useEffect(() => {
    Mixpanel.track(events.pageViews.midTableListView);
  }, []);

  const labelValue = (label: string, value: string) => {
    return (
      <div className={classes.labelValue}>
        <div className={classes.value}>{label}</div>
        <div className={classes.label}>{value}</div>
      </div>
    );
  };

  const getControls = (item) => (
    <KlaimDropdownContainer isIconButton icon={moreIcon} position="bottomLeft">
      <div
        role="button"
        onClick={() => history.push(`/midtable/form/${item.id}/${item.tenantId}`)}
        className={`${classes.button} ${classes.navLink}`}
      >
        View
      </div>
    </KlaimDropdownContainer>
  );

  const getHeader = () => {
    const arr = Object.keys(data).map((key) => ({label: data[key].label, value: totals[key] || data[key].value}));
    arr.push({label: 'DEDUCTIBLE AMOUNT', value: totals.total_copayment_amount});
    arr.push({label: 'TOTAL NET WITH VAT', value: Number(totals.total_net_amount) + Number(totals.total_vat_amount)});
    return arr;
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns: ColumnsType<Midtable> = [
    {
      title: 'CLAIM DETAILS',
      width: 170,
      render: (_, {hisId, created_at, claimTypeMain, speciality}) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <span>{hisId}</span>
            <span className={classes.date}>
              <img src={timeLogo} alt="timeLogo" />
              {formatDate(created_at)}
            </span>
            <span className={classes.date}>
              <b>{claimTypeMain}</b>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'PAYER / RECEIVER',
      width: 140,
      render: (_, item) => (
        <div className={classes.label}>
          {item.payerName}
          <br />
          {item.receiverName}
        </div>
      ),
    },
    {
      title: 'PATIENT',
      width: 160,
      render: (_, {patientId, patientName, patientNo}) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <span>{patientId}</span>
            <span className={classes.date}>
              <img src={UserLogo} alt="user logo" />
              {patientName}
            </span>
            <span className={classes.date}>
              <b>{patientNo}</b>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'ADMISSION / DISCHARGE',
      width: 250,
      render: (_, {admissionDate, dischargeDate}) => (
        <div className={classes.label}>
          {`${admissionDate ? formatDate(admissionDate) : 'No Date'} - ${
            dischargeDate ? formatDate(dischargeDate) : 'No Date'
          }`}
        </div>
      ),
    },
    {
      title: 'CLINIC',
      width: 150,
      render: (_, {doctorName, clinicName, visitType}) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <span className={classes.date}>{clinicName}</span>
            <span className={classes.date}>
              <img src={UserLogo} alt="user logo" />
              <b>{doctorName}</b>
            </span>
            <span className={classes.date}>
              <b>{visitTypeData[visitType]}</b>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Synced',
      width: 80,
      render: (_, {isSynchronized}) => (
        <div className={classes.label}>
          <div className={classes.col}>
            {isSynchronized ? (
              <span className={classes.approvedStatus}>YES</span>
            ) : (
              <span className={classes.deniedStatus}>
                <img src={exclamationIcon} alt="timeLogo" />
                NO
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'TOTAL AMOUNTS IN SAR',
      fixed: 'right',
      width: 400,
      align: 'right',
      render: (_, {totalGrossAmount, totalNetAmount, totalDiscountAmount, totalVatAmount, totalCopaymentAmount}) => (
        <div className={classes.label}>
          <Row gutter={48}>
            <Col span={12}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>Gross</div>
                <div className={classes.label}>{totalGrossAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>DISCOUNT</div>
                <div className={classes.label}>{totalDiscountAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>COPAY</div>
                <div className={classes.label}>{totalCopaymentAmount}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>NET</div>
                <div className={classes.label}>{totalNetAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>VAT</div>
                <div className={classes.label}>{totalVatAmount}</div>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: 'ACTION',
      fixed: 'right',
      align: 'right',
      width: 90,
      render: (_, item) => <div className={classes.label}>{getControls(item)}</div>,
    },
  ];

  const paginationProps: PaginationProps = {
    current: offset,
    onChange: (page) => setOffset(page),
    total: dataCount,
    showSizeChanger: true,
    onShowSizeChange: (current, size) => setLimit(size),
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>{getHeader().map((el) => labelValue(el.label, el.value))}</div>
      <Table
        whiteHeader
        firstColumnPadding={false}
        className={classes.tableContainer}
        columns={columns}
        dataSource={dataSource}
        scroll={{x: 1490}}
        footer={() => (
          <div className={classes.footerContent}>
            <Typography.Title level={5} className={classes.totalAmountText}>
              TOTAL AMOUNT OF ENTRIES: {dataCount}
            </Typography.Title>
            <Pagination {...paginationProps} />
          </div>
        )}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            disabled: record.isSynchronized,
          }),
        }}
      />
    </div>
  );
};

export default Component;
