import React from 'react';
import {IDiagnosisResponse} from 'interfaces/pre-auth.interface';
import {KlaimDropdownSearch} from 'modules/Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {diagnosisSelect, getDiagnosis} from '../../diagnosisSlice';

interface Option {
  label: string;
  value: string;
}

interface ComponentProps {
  onChange?: (value: any) => void;
  isError?: boolean;
  authorityCode?: string;
  label?: string;
  value?: string;
  isSupportInfo?: boolean;
  optional?: boolean;
}

const DiagnosisCodeDropdown: React.FC<ComponentProps> = ({
  onChange,
  isError = false,
  authorityCode = 'HAAD',
  label,
  isSupportInfo,
  value,
  optional,
}) => {
  const dispatch = useAppDispatch();
  const {diagnosis} = useAppSelector(diagnosisSelect);
  const [limit] = React.useState<number>(10);
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');
  const [diagnosisOption, setDiagnosisOption] = React.useState<Option[]>([]);

  React.useEffect(() => {
    dispatch(
      getDiagnosis({
        authorityCode,
        search: search.replace('-', ''),
        limit,
      }),
    );
  }, [search]);

  React.useEffect(() => {
    if (diagnosis) {
      const newData = diagnosis.map((diag: IDiagnosisResponse) => {
        return {
          label: diag.longDescription,
          value: diag.diagnosisCode,
        };
      });
      setDiagnosisOption(newData);
    }
  }, [diagnosis]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const handleOnChange = (v: any) => {
    if (isSupportInfo) {
      if (onChange) {
        onChange(v);
      }
    } else {
      const diagnosisDetails = diagnosis.find((diag: IDiagnosisResponse) => diag.diagnosisCode === v);
      if (onChange) {
        onChange(diagnosisDetails && diagnosisDetails ? diagnosisDetails : {});
      }
    }
  };

  const handleInputChange = (newValue: any) => {
    setSearchTyping(newValue);
  };

  return (
    <KlaimDropdownSearch
      isError={isError}
      label={label || 'Search Diagnosis'}
      options={diagnosisOption}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      value={value}
      isOptional={optional}
    />
  );
};

export default DiagnosisCodeDropdown;
