import {createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import {login} from 'modules/Auth/v3/features/Login/loginSlice';
import {getBranches, logout, validate} from 'modules/Auth/v3/features/Auth/authSlice';
import {register} from 'modules/Auth/v3/features/Register/registerSlice';
import {
  changePassword,
  forgotPassword,
  forgotPasswordValidate,
} from 'modules/Auth/v3/features/Forgot/forgotPasswordSlice';
import {acceptInvite, emailInviteValidate} from 'modules/Auth/v3/features/Invite/inviteSlice';

export interface AuthState {
  isLoading: boolean;
  isCheckLoading: boolean;
  isHashValidating: boolean;
  isValidated: boolean;
  isBranchesFulfilled: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  isCheckLoading: false,
  isHashValidating: false,
  isValidated: false,
  isBranchesFulfilled: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validate.pending, (state) => {
        state.isCheckLoading = true;
      })
      .addCase(validate.fulfilled, (state) => {
        state.isCheckLoading = false;
      })
      .addCase(validate.rejected, (state) => {
        state.isCheckLoading = false;
      });
    builder.addCase(getBranches.fulfilled, (state) => {
      state.isBranchesFulfilled = true;
    });
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isCheckLoading = false;
        state.isHashValidating = false;
        state.isValidated = false;
        state.isBranchesFulfilled = false;
      })
      .addCase(logout.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(emailInviteValidate.pending, (state) => {
        state.isHashValidating = true;
      })
      .addCase(emailInviteValidate.fulfilled, (state) => {
        state.isHashValidating = false;
        state.isValidated = true;
        state.isLoading = false;
      })
      .addCase(emailInviteValidate.rejected, (state) => {
        state.isValidated = false;
        state.isHashValidating = false;
        state.isLoading = false;
      });
    builder
      .addCase(forgotPasswordValidate.pending, (state) => {
        state.isHashValidating = true;
      })
      .addCase(forgotPasswordValidate.fulfilled, (state) => {
        state.isHashValidating = false;
        state.isValidated = true;
      })
      .addCase(forgotPasswordValidate.rejected, (state) => {
        state.isHashValidating = false;
        state.isValidated = false;
      });
    builder
      .addCase(acceptInvite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptInvite.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(acceptInvite.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const loadingSelect = (state: RootState) => state.auth.loading;
export default loadingSlice.reducer;
