import {ClaimDraft} from '../../Claims/api/types';

const selectClaimDraft = (state: any) => {
  if (state.claimDraft.claimDraft.byId) return Object.values(state.claimDraft.claimDraft.byId) as ClaimDraft[];
  return [];
};

const selectClaimDraftById = (state: any, id: any) => {
  return state.claimDraft.claimDraft.byId[id];
};

export {selectClaimDraft, selectClaimDraftById};
