import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    branchManagement: {
      background: `${Colors.white} 0% 0% no-repeat padding-box`,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      boxShadow: `0px 0.5px 2px ${Colors.RMSC.shadow}`,
      display: 'flex',
      flex: '1 1 0%',
      justifyContent: 'space-between',
      padding: '0 34px',
    },
    branchManagementTable: {
      width: '100%',
      padding: 0,
    },
    branchManagementWrapper: {
      flex: '1 1',
      flexDirection: 'column',
    },
    scrollWrapper: {
      height: '100vh',
      overflow: 'scroll',
    },
    tableName: {
      color: Colors.blueGrayDark,
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.42px',
      marginBottom: '10px',
      marginTop: '10px',
      opacity: '0.48',
      padding: '0 34px',
    },
    loadingContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 200,
    },
  };
});
