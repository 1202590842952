import React from 'react';
import klaimLogo from 'assets/klaim_logo_faded.svg';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/components/Sidebar/Sidebar';

const Sidebar: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.sidebarContainer}>
      <img src={klaimLogo} alt="KlaimLogo" />
      <div>
        <a
          className={classes.privacy}
          target="_blank"
          rel="noreferrer"
          href="https://register.klaim.ai/privacy-policy/"
        >
          PRIVACY POLICY
        </a>
        {/* <p>COOKIE POLICY</p> */}
        <p>Copyright © 2022 Klaim All rights reserved </p>
      </div>
    </div>
  );
};

export default Sidebar;
