import React from 'react';
import {Switch} from 'react-router-dom';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import {IRoute} from 'interfaces/route.interface';
import {useStylesFromThemeFunction} from './Patients';

const Patients: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.container}>
      <div className={classes.containerBody}>
        <div className={classes.scrollContainer}>
          <Switch>
            {routes &&
              routes.map(({routeKey, ...route}) => {
                return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
              })}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Patients;
