import React from 'react';
import {KlaimScreenContext} from '../../index';

interface IProps {
  name: string;
  index?: number;
}

interface ComponentProps {
  style?: React.CSSProperties;
  children: React.ReactElement<IProps>[] | React.ReactElement<IProps>;
}

const KlaimScreenList: React.FC<ComponentProps> = ({children, style}) => {
  const {setScreens} = React.useContext(KlaimScreenContext);
  React.useEffect(() => {
    const allScreen = React.Children.map(children, (child: React.ReactElement<IProps>, i) => {
      return {
        name: child.props.name,
        index: i,
      };
    });
    setScreens(allScreen);
  }, []);
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {index: i});
    }
    return child;
  });
  return <div style={style}>{childrenWithProps}</div>;
};

export default KlaimScreenList;
