import React from 'react';
import {NavLink} from 'react-router-dom';
import moreIcon from 'assets/more_icon.svg';
import {useStylesFromThemeFunction} from 'modules/Settings/features/AccessManagement/RoleRow/RoleRow';
import EditIcon from 'assets/component/EditIcon';
import DeleteIcon from 'assets/component/DeleteIcon';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import KlaimModal from 'modules/Common/components/KlaimModal';
import {useAppDispatch} from 'modules/App/store';
import Button from 'modules/Common/components/Button';
import {deleteRole} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import * as Toast from 'modules/Common/utils/toast';
import closeIcon from 'assets/close_icon.svg';
import DeleteModal from './DeleteModal';

interface ComponentProps {
  role: {
    created_at: string;
    deleted_at: string;
    description: string;
    id: number;
    name: string;
    isActive: boolean;
    permissions: [string];
    updated_at: string;
    userCount?: number;
  };
  index: number;
}

const RoleRow: React.FC<ComponentProps> = ({role: {id, name, isActive, userCount}, index}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState(false);
  const classes = useStylesFromThemeFunction();

  const triggerDelete = () => {
    dispatch(deleteRole({id}));
  };

  const membersCheck = () => {
    Toast.error('Role has associated users, It cant be deleted');
  };

  return (
    <>
      <KlaimModal contentClass={classes.modal} isOpen={deleteOpen} onRequestClose={() => setDeleteOpen(false)}>
        <div className={classes.deleteUserContainer}>
          <div className={classes.headerContainer}>
            <h1>Delete Role</h1>
            <img onClick={() => setDeleteOpen(false)} src={closeIcon} alt="Close-icon" />
          </div>
          <div className={classes.messageContainer}>
            <div>
              <h2>Are you sure you want to delete the role?</h2>
              <p className={classes.nameText}>{name}</p>
            </div>
            <hr />
            <p>Role won’t be able to access the organization on the system.</p>
          </div>
          <div className={classes.optionsButtonContainer}>
            <Button title="No, Go back" onClick={() => setDeleteOpen(false)} buttonClassName={classes.backButton} />
            <Button
              title="Delete Role"
              onClick={() => {
                setDeleteOpen(false);
                triggerDelete();
              }}
            />
          </div>
        </div>
      </KlaimModal>

      <div key={id} className={`${classes.roleListTableRow} ${classes.borderBottom}`}>
        <div className={classes.roleName}>{name}</div>
        <div className={classes.membersAsigned}>
          Members assigned to: <span>{userCount === undefined ? 0 : userCount}</span>
        </div>
        {/* <div className={`${classes.isActiveContainer} ${isActive ? classes.active : classes.disabled}`}>
          {isActive ? 'Active' : 'Disabled'}
        </div> */}

        <KlaimDropdownContainer isIconButton icon={moreIcon}>
          <NavLink to={`access-management/edit-roles/${id}`} className={`${classes.button} ${classes.navLink}`}>
            <div className={classes.buttonIcon}>
              <EditIcon />
            </div>
            Edit Role
          </NavLink>
          <button
            className={classes.button}
            onClick={() => {
              if (userCount === undefined || userCount === 0) {
                setDeleteOpen(true);
              } else {
                membersCheck();
              }
            }}
          >
            <div className={classes.buttonIcon}>
              <DeleteIcon />
            </div>
            Delete Role
          </button>
        </KlaimDropdownContainer>
      </div>
      <DeleteModal
        index={index}
        isActive={isActive}
        setOpen={setOpen}
        isConfirmDisableOpen={openModal}
        setConfirmDisableOpen={setOpenModal}
      />
    </>
  );
};

export default RoleRow;
