import {createUseStyles} from 'react-jss';
import {Colors} from '../../../Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    collapsedContainerClassName: {
      background: 'transparent',
      boxShadow: 'none',
      height: 48,
      position: 'relative',
      zIndex: 1,
    },
    collapsedContainerHeaderClassName: {
      position: 'sticky',
      top: 0,
    },
    collapsibleHeaderContainer: {
      '& h5': {
        color: Colors.purpleDark,
        marginBottom: 0,
        marginTop: 0,
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: 75,
      justifyContent: 'space-between',
    },
    containerContentClassName: {padding: 0},
    expandedContainerClassName: {
      paddingBottom: 0,
    },
    form: {paddingBottom: 75},
    formElementWithBottomSpacing: {
      marginBottom: 30,
      width: '100%',
    },
    formSectionElementsWrapper: {
      '@media (max-width: 776px)': {
        marginLeft: 100,
        marginRight: 100,
      },
      '@media (max-width: 530px)': {
        marginLeft: 50,
        marginRight: 50,
      },
      marginLeft: 200,
      marginRight: 200,
      paddingTop: 40,

      '& > div': {
        position: 'relative',
        '& p': {
          position: 'absolute',
          top: '65px',
          left: '15px',
        },
      },
    },
    rowInputs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 30,
      position: 'relative',
    },
    rowInput: {
      width: '204px',
      '& input': {
        width: '204px',
      },
    },
    rowInputRight: {
      width: '100%',
      paddingLeft: '30px',
      position: 'relative',
    },
    rowInputSelect: {
      '& select': {
        width: '157px',
      },
    },
    submitPreAuthButton: {
      marginRight: 20,
      width: 200,
    },
    dFlex: {
      display: 'flex',
    },
    positionRelative: {
      position: 'relative',
    },
    addActivityBtn: {
      alignSelf: 'center',
      background: Colors.purple,
      borderRadius: '7px',
      height: '24px',
      width: '24px',
      padding: '0',
      fontSize: '22px',
    },
    separatorBorder: {
      borderBottom: `1px solid ${Colors.grayLight}`,
      height: 'fit-content',
      width: '80%',
      alignSelf: 'center',
    },
    justifyContentBetween: {
      justifyContent: 'space-between',
    },
    cardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        marginTop: 15,
      },
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      letterSpacing: '0.13px',
      color: Colors.pinkDark,
      fontWeight: 600,
      borderBottom: 'none !important',
    },
    w100: {
      width: '100%',
    },
    pm0: {
      '& p': {
        margin: 0,
      },
    },
    removeDrugBtn: {
      background: Colors.grayLight,
      borderRadius: '7px',
      height: '24px',
      width: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  };
});
