import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import KlaimScreens from 'modules/Common/components-v2/KlaimScreens';
import KlaimScreen from 'modules/Common/components-v2/KlaimScreens/components/KlaimScreen';
import KlaimScreenHeader from 'modules/Common/components-v2/KlaimScreens/components/KlaimScreenHeader';
import KlaimScreenButtonWrapper from 'modules/Common/components-v2/KlaimScreens/components/KlaimScreenButtonWrapper';
import KlaimScreenList from 'modules/Common/components-v2/KlaimScreens/components/KlaimScreenList';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {
  KlaimIconButton,
  KlaimForm,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
} from 'modules/Common/components-v2/KlaimForm';
import {IActivityForm, ISupportingInfo} from 'interfaces/pre-auth.interface';
import SubActivity from './components/SubActivity';
import {useStylesFromThemeFunction} from './PackageModal';
import ItemForm from '../ActivityModal/components/ItemForm';

interface ComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (value: any) => void;
  supportingInfo?: ISupportingInfo[];
}

const PackageModal: React.FC<ComponentProps> = ({isOpen, setIsOpen, onSubmit, supportingInfo = []}) => {
  const classes = useStylesFromThemeFunction();
  const initialValues: IActivityForm = {
    item: {
      itemIsPackage: true,
      itemSystem: undefined,
      itemTax: 0,
      itemPatientShare: undefined,
      itemCode: '',
      itemQty: 0,
      itemUnitPrice: 0,
      itemCodeHcp: '',
      itemCodeSfda: '',
      itemCodeSfdaDisplay: '',
      itemCodeHcpDisplay: '',
      itemCodeDisplay: '',
      factor: undefined,
      subSite: undefined,
      bodySite: undefined,
      shadowBilling: 'No',
      informationSequence: [],
      diagnosisSequence: [],
      itemCareTeamSeq: [],
    },
  };
  const validationSchema = Yup.object({
    item: Yup.object({
      // itemSystem: Yup.number().required('Activity Type is required.'),
      itemCode: Yup.string().required('Activity Code is required.'),
      detail: Yup.array().of(
        Yup.object({
          itemSystem: Yup.number(),
          itemCode: Yup.string(),
        }),
      ),
    }),
  });

  const handleSubmit = (value: any) => {
    if (onSubmit)
      onSubmit({
        ...value,
        item: Object.keys(value.item)
          .filter((k) => {
            if (typeof value.item[k] === 'number' || typeof value.item[k] === 'boolean') {
              return true;
            }
            return !_.isEmpty(value.item[k]);
          })
          .reduce((a, k) => ({...a, [k]: value.item[k]}), {}),
      });
    setIsOpen(false);
  };

  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen}>
      <KlaimForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <KlaimModalHeader title="Add Package" />
        <KlaimModalBody>
          <div style={{margin: 20}}>
            <KlaimScreens>
              <KlaimScreenHeader />
              <KlaimScreenList>
                <KlaimScreen name="Package">
                  <div>Please Fill in the fields below to add new activity.</div>

                  <ItemForm />
                  <KlaimFieldWrapper name={'item.informationSequence'}>
                    <KlaimDropdownSearch
                      variant="secondary"
                      label="Select Additional Info"
                      isMulti
                      isOptional
                      options={supportingInfo.map((info, index) => {
                        return {
                          label: `${info.category} - ${info.code}`,
                          value: index + 1,
                        };
                      })}
                    />
                  </KlaimFieldWrapper>
                  <div style={{display: 'grid', gap: '1rem', marginTop: 12}}>
                    <KlaimScreenButtonWrapper goToIndex={1}>
                      <div className={classes.additionalInfoContainer}>
                        <div>
                          <h3>Sub Activity</h3>
                          <p>Add sub activities to this package</p>
                        </div>
                        <KlaimIconButton>
                          <div style={{transform: 'rotate(-90deg)'}}>
                            <ChevronArrowDown />
                          </div>
                        </KlaimIconButton>
                      </div>
                    </KlaimScreenButtonWrapper>
                  </div>
                </KlaimScreen>
                <KlaimScreen name="subActivity">
                  <SubActivity />
                </KlaimScreen>
                <KlaimScreen name="additional">{/* <AdditionalInfo isSubmit={isSubmit} {...rest} /> */}</KlaimScreen>
              </KlaimScreenList>
            </KlaimScreens>
          </div>
        </KlaimModalBody>
        <KlaimModalFooter buttonType={'submit'} submitButtonTitle="Add Package" />
      </KlaimForm>
    </KlaimModal>
  );
};

export default PackageModal;
