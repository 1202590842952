import React from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import {
  Practitioner,
  PractitionerBranch,
  PractitionerRole,
} from 'modules/Settings/features/Practitioners/PractitionersSlice';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import Avatar from 'assets/avatar_icon.svg';

interface ComponentProps {
  isModalOpen: boolean;
  setIsModalOpen: any;
  data: Practitioner;
  history: any;
}

const useStylesFromThemeFunction = createUseStyles({
  container: {
    width: 'calc(100% - 40px)',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 30,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: '100%',
    border: `1px solid ${Colors.grayLight}`,
    marginRight: 20,
    textAlign: 'center',
    '& > img': {
      margin: '16px auto',
    },
  },
  profile: {
    padding: '10px 0',
    borderBottom: '1px solid #e9e9e9',
    position: 'relative',
    '& > div': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > div > div': {
      display: 'block',
    },
    '& .name': {
      fontWeight: 'bold',
    },
    '& .status': {
      position: 'absolute',
      top: 10,
      right: 0,
      width: 'fit-content',
      padding: '5px 15px',
      borderRadius: 3,
      fontWeight: 400,
      '&.active': {
        color: Colors.successTextGreen,
        background: Colors.successBackgroundGreen,
      },
      '&.inactive': {
        color: Colors.errorTextRed,
        background: Colors.errorBackgroundRed,
      },
    },
  },
  listTitle: {
    fontSize: 12,
    margin: '20px 0 5px 20px',
    fontWeight: 'bold',
    color: Colors.grayInputPlaceholder,
  },
  listBox: {
    border: '1px solid #e9e9e9',
    borderRadius: 5,
    listStyle: 'none',
    padding: '10px 20px',
    fontSize: 14,
    margin: 0,
    '& li': {
      padding: '5px 0',
    },
  },
});

const PractitionerModalView: React.FC<ComponentProps> = ({data, isModalOpen, setIsModalOpen, history}) => {
  const onEdit = (id: any) => {
    history.push(`/settings/practitioners/edit/${id}`);
  };
  const classes = useStylesFromThemeFunction();

  let branchesList: any = 'No Branch';
  if (data?.roles?.length > 0)
    branchesList = data.branches.map((item: PractitionerBranch) => <li key={item.id}>{item.name}</li>);

  let specialityList: any = 'No Speciality';
  if (data?.speciality?.length > 0)
    specialityList = data.roles.map((item: PractitionerRole) => <li key={item.id}>{item.name}</li>);

  return (
    <KlaimModal maxWidth={600} open={isModalOpen} setOpen={setIsModalOpen}>
      <KlaimModalHeader title="Practitioner Details" />
      <KlaimModalBody>
        <div className={classes.container}>
          <div className={classes.profile}>
            <div className={classes.avatar}>
              <img src={Avatar} alt="profile" />
            </div>
            <div>
              <div className="name">{data.name}</div>
              <div className="speciality">{data.speciality || 'No speciality'}</div>
              <div className="gender">{data.gender}</div>
              <div className={`status ${data.active ? 'active' : 'inactive'}`}>
                {data.active ? 'Active' : 'Inactive'}
              </div>
            </div>
          </div>
          <div className={classes.listTitle}>ROLES</div>
          <ul className={classes.listBox}>{specialityList}</ul>
          <div className={classes.listTitle}>BRANCHES</div>
          <ul className={classes.listBox}>{branchesList}</ul>
        </div>
      </KlaimModalBody>
      <KlaimModalFooter onSubmit={() => onEdit(data.id)} submitButtonTitle="Edit" />
    </KlaimModal>
  );
};

export default PractitionerModalView;
