import React, {useContext} from 'react';
import dayjs from 'dayjs';
import {
  claimPriority,
  claimSubtype,
  claimType,
  extendedClaimType,
  extendedClaimTypeMapping,
} from 'modules/Common/constants/DropdownOptions';
import {KlaimRadioSelect, KlaimDropdownSearch, KlaimToggle} from 'modules/Common/components-v2/KlaimForm';
import {
  ICareTeam,
  IClaim,
  IClaimPriority,
  IClaimSubType,
  IClaimType,
  IDiagnosis,
  IItem,
  IActivityForm,
  ISupportingInfo,
  VisionPrescription,
} from 'interfaces/pre-auth.interface';
import DiagnosisSubForm from 'modules/Diagnosis/features/DiagnosisSubForm';
import CareTeamSubForm from 'modules/CareTeam/features/CareTeamSubForm';
import SupportingInfoSubForm from 'modules/SupportingInfo/features/SupportingInfoSubForm';
import ActivitySubForm from 'modules/Activity/features/ActivitySubForm';
import {useStylesFromThemeFunction} from './Claim';
import Vision from './Vision';
import {usePreAuthFormContext} from '../PreAuthFormContext';

interface ComponentProps {
  claim: IClaim;
  setClaim: React.Dispatch<React.SetStateAction<IClaim>>;
}

const Claim: React.FC<ComponentProps> = ({claim, setClaim}) => {
  const classes = useStylesFromThemeFunction();
  const {
    constants: {activities},
    setter: {setActivities},
  } = usePreAuthFormContext();
  const handleCareTypeChange = (value: IClaimType) => {
    setClaim({...claim, ...extendedClaimTypeMapping[value]});
  };

  const handleSubTypeChange = (value: IClaimSubType) => {
    setClaim({...claim, claimSubtype: value});
  };

  const handlePriorityChange = (value: IClaimPriority) => {
    setClaim({...claim, claimPriority: value});
  };

  const handleDiagnosisChange = (value: IDiagnosis[]) => {
    setClaim({...claim, diagnosis: value});
  };

  const handleCareTeamChange = (value: ICareTeam[]) => {
    setClaim({...claim, careTeam: value});
  };

  const handleSupportInfoChange = (value: ISupportingInfo[]) => {
    setClaim({...claim, supportingInfo: value});
  };

  const handleVisionChange = (value: VisionPrescription) => {
    setClaim({...claim, visionPresciption: value});
  };

  const handleReferralToggle = (value: boolean) => {
    setClaim({...claim, referral: value});
  };

  const handleRemoveActivityRelatedToCareTeam = (index: number) => {
    setActivities(
      activities.filter((activity) => {
        return !activity.item.itemCareTeamSeq?.includes(index);
      }),
    );
  };
  const [selectedActivityIndex, setSelectedActivityIndex] = React.useState<number>(undefined);

  React.useEffect(() => {
    const item: IItem[] = [];
    activities.forEach((activity: IActivityForm) => {
      const itemPayload = {
        ...activity.item,
        itemTax: parseInt(activity.item.itemTax ? activity.item.itemTax.toString() : '0', 10),
      };

      item.push(itemPayload);
    });
    setClaim({...claim, item});
  }, [activities]);

  const getCareType = () => {
    if (claim.claimType && claim.claimSubtype) {
      const arr = [];
      Object.keys(extendedClaimTypeMapping).map((el) => arr.push({...extendedClaimTypeMapping[el], key: el}));
      const v = arr.filter((el) => el.claimType === claim.claimType && claim.claimSubtype === el.claimSubtype);
      if (v.length > 0) return v[0].key;
    }
    return null;
  };
  return (
    <div>
      <p style={{fontSize: 16, color: '#302E38', marginTop: 10, marginBottom: 10}}>
        Please fill in the fields below to complete your Pre-authorization request.
      </p>
      <div className={classes.formContainer}>
        <KlaimDropdownSearch
          value={getCareType()}
          initialValue={getCareType()}
          options={extendedClaimType}
          name="patient"
          onChange={handleCareTypeChange}
          label="Care Type"
          variant={'secondary'}
          style={{gridColumn: 'span 2 / span 2'}}
        />
        <div style={{display: 'flex', alignItems: 'end', gridColumn: 'span 2 / span 2'}}>
          <div style={{flex: '1 1 0%'}}>
            <KlaimToggle title="Transfer" onChange={handleReferralToggle} variant="secondary" />
          </div>
        </div>
        {/* {claim.claimType === 'vision' ? (
          <Vision onChange={handleVisionChange} />
        ) : (
          <div
            style={{
              gridColumn: 'span 4 / span 4',
              display: 'grid',
              gap: 10,
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            }}
          >
            <KlaimRadioSelect
              onChange={handleSubTypeChange}
              value={claim.claimSubtype}
              label={'Type'}
              selections={claimSubtype}
            />
            <KlaimRadioSelect
              onChange={handlePriorityChange}
              value={claim.claimPriority}
              label={'Priority'}
              selections={claimPriority}
            />
          </div>
        )} */}
      </div>

      <CareTeamSubForm
        onChange={handleCareTeamChange}
        initialValues={claim.careTeam || []}
        removeRelatedActivity={handleRemoveActivityRelatedToCareTeam}
      />

      <DiagnosisSubForm
        onChange={handleDiagnosisChange}
        initialValues={claim.diagnosis || []}
        disableOnAdmission={claim.claimSubtype !== 'ip'}
      />
      <SupportingInfoSubForm
        onChange={handleSupportInfoChange}
        initialValues={claim.supportingInfo || []}
        diagnosis={claim.diagnosis}
      />
      <ActivitySubForm
        diagnosis={claim.diagnosis}
        careTeam={claim.careTeam}
        supportingInfo={claim.supportingInfo}
        supportingInfos={claim.supportingInfo}
        activity={activities}
        setActivity={setActivities}
        selectedActivityIndex={selectedActivityIndex}
        setSelectedActivityIndex={setSelectedActivityIndex}
      />
    </div>
  );
};

export default Claim;
