import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowX: 'hidden',
      width: '100%',
    },
    dropdownClassName: {
      paddingRight: 0,
      width: 200,
    },
    disabled: {
      pointerEvents: 'none',
      opacity: '0.7',
    },
  };
});
