import React from 'react';
import {
  useStylesFromThemeFunction,
  ComponentProps,
} from 'modules/Common/components-v2/KlaimForm/components/KlaimTextArea/KlaimTextArea';

const KlaimTextArea: React.FC<ComponentProps> = ({
  resizable,
  label,
  placeholder,
  variant = 'primary',
  onChange,
  optional,
  name,
  isError,
  isTouched,
  value,
}) => {
  const classes = useStylesFromThemeFunction();

  // const isValidated = (error: boolean, touched: boolean) => {
  //   return error && touched ? classes.error : classes.validated;
  // };

  // const isValidatedChild = (error: boolean, touched: boolean) => {
  //   return error && touched ? classes.errorChild : classes.validatedChild;
  // };

  const inputContainerClass = (v: string) => {
    switch (v) {
      case 'secondary': {
        return classes.textAreaSecondVariant;
      }
      default: {
        return '';
      }
    }
  };

  const validateLabel = (touched?: boolean, error?: boolean) => {
    if (touched && error) {
      return classes.labelError;
    }
    if (touched) {
      return classes.labelValidated;
    }
    // return classes.labelDefault;
  };

  return (
    <div className={`${classes.container} `}>
      <label className={`${classes.label} ${validateLabel(isTouched, isError)}`} htmlFor={name}>
        {label}
        {optional && <span>(Optional)</span>}
      </label>
      <textarea
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        placeholder={placeholder}
        value={value}
        className={`${classes.textArea} ${inputContainerClass(variant)} ${resizable ? classes.resizeDisabled : ''} `}
      />
    </div>
  );
};

export default KlaimTextArea;
