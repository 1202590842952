import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  title?: string;
  onChange?: (e: boolean) => void;
  value?: boolean;
  width?: number | string;
  style?: React.CSSProperties;
  variant?: 'primary' | 'secondary';
}

export const useStylesFromThemeFunction = createUseStyles({
  label: {
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontSize: '13px',
    },
    fontWeight: 600,
  },
  secondary: {
    backgroundColor: '#F5F7FB',
  },
  toggleContainer: {
    minHeight: 30,
    display: 'flex',
    justifyContent: 'space-between',
    // border: `1px ${Colors.grayLight} solid`,
    alignItems: 'center',
    borderRadius: 10,
    padding: 6,
    '& .switch input:checked + .slider:before': {
      borderColor: '#6135FB !important',
    },
    '& .switch input:checked + .slider': {
      backgroundColor: '#6135FB !important',
    },
    '& .switch': {
      position: 'relative',
      display: 'inline-block',
      width: 55,

      '& input': {
        opacity: 0,
        height: 0,
        width: 0,
      },
      '& .slider': {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        background: Colors.blueGrayLight,
        borderRadius: 50,
        transition: '0.4s',

        '&:before': {
          position: 'absolute',
          content: '""',
          height: 24,
          width: 24,
          left: 0,
          border: `2px ${Colors.blueGrayLightDim} solid`,
          background: Colors.white,
          transition: '0.4s',
          borderRadius: 50,
        },
      },
      '& input:checked + .slider': {
        background: Colors.lightGreen,
      },
      '& input:checked + .slider:before': {
        transform: 'translateX(27px)',
        borderColor: Colors.lightGreen,
      },
    },
  },
});
