import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axiosInstance from 'services/axios';
import {MyKnownError} from 'interfaces/error-interface';
import {ICreatePatientRequest, IPatientResponse} from 'interfaces/patient.interface';
import {IQuery, IData} from 'interfaces/general.interfaces';

interface InitialState {
  patients: {
    data: IPatientResponse[];
  };
  isLoading: boolean;
  error: any;
  isCreatePatientSuccess: boolean;
  isCreatePreAuthSuccess: boolean;
}

const initialState: InitialState = {
  patients: {
    data: [],
  },
  isLoading: false,
  error: undefined,
  isCreatePatientSuccess: false,
  isCreatePreAuthSuccess: false,
};

interface PatientData extends IData {
  data: IPatientResponse[];
}

interface PatientQuery extends IQuery {
  providerId: string;
}

export const createPatient = createAsyncThunk<null, ICreatePatientRequest, {rejectValue: MyKnownError}>(
  'preAuthV2/createPatient',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post('/patients', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getPatients = createAsyncThunk<PatientData, PatientQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/getPatients',
  async (query: PatientQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/patients', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.fulfilled, (state, action) => {
        state.patients = action.payload;
        state.isLoading = false;
      })
      .addCase(getPatients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createPatient.fulfilled, (state, action) => {
        state.isCreatePatientSuccess = true;
        state.isLoading = false;
      })
      .addCase(createPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreatePatientSuccess = false;
        state.error = action.payload;
      });
  },
});

export const patientSelect = (state: RootState) => state.patient;
export default patientSlice.reducer;
