import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';

const reportProgressStatus = createSlice({
  initialState: {
    progress: false,
    errorStatus: false,
    downloadProgressID: '',
  },
  name: 'reportProgressStatus',
  reducers: {
    getReportProgressStatus: (state, {payload}: PayloadAction<boolean>) => {
      state.progress = payload;
    },
    getReportErrorStatus: (state, {payload}: PayloadAction<boolean>) => {
      state.errorStatus = payload;
    },
    getDownloadProgressID: (state, {payload}: PayloadAction<string>) => {
      state.downloadProgressID = payload;
    },
  },
});

export const {getReportProgressStatus, getReportErrorStatus, getDownloadProgressID} = reportProgressStatus.actions;

export const reportProgressSelect = (state: RootState) => state.reportProgressStatus.reportProgressStatus;

export default reportProgressStatus;
