import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  createAccount: {
    background: Colors.white,
    width: 471,
    height: 547,
    borderRadius: 10,
    padding: 20,
  },
  formContainer: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    display: 'flex',
    '& h1': {
      fontsize: '24px',
    },
    '& p': {
      fontsize: '12px',
    },
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  loadingContainer: {
    justifyContent: 'start',
  },
});
