import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from 'services/axios';
import {RootState} from 'modules/App/store';
import {MyKnownError} from 'interfaces/error-interface';
import {Contract, ContractData, ContractQuery} from 'interfaces/contract.interface';
import axios from '../../../../services/axios';

interface InitialState {
  contracts: Contract[];
  totals: any;
  contract?: Contract;
  isLoading: boolean;
  error: any;
  apiCallSuccess: boolean;
  count: number;
}

const initialState: InitialState = {
  contracts: [],
  totals: {},
  contract: undefined,
  isLoading: false,
  error: undefined,
  apiCallSuccess: false,
  count: 0,
};

export const getContracts = createAsyncThunk<ContractData, ContractQuery, {rejectValue: MyKnownError}>(
  'contracts',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(
        `/contract-management?providerId=${data.providerId}${data.hci ? `&hcpCode=${data.hci}` : ''}&limit=${
          data.limit
        }&offset=${data.offset}${data.search ? `&search=${data.search}` : ''}`,
        {
          data,
        },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const getContract = createAsyncThunk<ContractData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'contract',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/contract-management/${query.id}`, {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const postContract = createAsyncThunk<Contract, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'postContract',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/contract-management`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const patchContract = createAsyncThunk<Contract, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'patchContract',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.patch(
        `/contract-management/${data.id}/?providerId=${data.providerId}`,
        data,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const deleteContract = createAsyncThunk<Contract, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteContract',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/contract-management/${data.id}/?providerId=${data.providerId}`, {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const uploadBulk = (file: any, providerId: any, hic: any, tpas: any) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('providerId', providerId);
  formData.append('hci', hic);
  formData.append('tpas', tpas);

  return axios.post(`/contract-management/excel/bulk-upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const contractsManagementSlice = createSlice({
  name: 'contractsManagement',
  initialState,
  reducers: {
    errorCleanUp: (state) => {
      state.isLoading = false;
      state.error = undefined;
      state.apiCallSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.fulfilled, (state, action: any) => {
        state.contracts = action.payload.data;
        state.count = action.payload.count;
        state.isLoading = false;
      })
      .addCase(getContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContracts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getContract.fulfilled, (state, action: any) => {
        state.contract = action.payload;
        state.isLoading = false;
      })
      .addCase(getContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(postContract.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(patchContract.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(patchContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteContract.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {errorCleanUp} = contractsManagementSlice.actions;

export const contractsManagementSelect = (state: RootState) => state.settings.contractManagement;
export default contractsManagementSlice.reducer;
