import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface IValues {
  [field: string]: any;
}
// export interface Values {
//   [field: string]: any;
// }

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    bodyWrapper: {
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        width: '450px',
      },
      width: 400,
    },
    emailSent: {
      color: Colors.grayLighter,
      display: 'flex',
      fontSize: '13px',
      alignItems: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
      '& img': {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    wrongEmail: {
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 10,
    },
    loadingContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 200,
    },
    buttonContainer: {
      paddingLeft: 16,
    },
    dropdownButtonContainer: {
      width: 380,
      flex: '1 1 0%',
      fontWeight: 600,
      fontSize: 14,
      color: Colors.blueGrayDark,
      display: 'flex',
      alignItems: 'center',
      marginTop: 2,

      '& button': {
        textAlign: 'left',
        border: 'none',
        background: 'none',
        paddingTop: 6,
        paddingBottom: 6,
        margin: 0,
        width: '100%',
        '&:hover': {
          backgroundColor: Colors.blueGrayLight,
          cursor: 'pointer',
        },
      },
    },
    buttonActive: {
      backgroundColor: Colors.blueGrayLight,
    },
    label: {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 'bold',
      color: Colors.grayLabel,
    },
    optionText: {whiteSpace: 'nowrap', color: Colors.blueGrayDark, fontWeight: 600},
    error: {
      color: Colors.red,
    },
    fullWidth: {
      width: 360,
    },
  };
});
