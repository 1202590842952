import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {
  KlaimInput,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimRadioSelect,
  KlaimToggle,
} from 'modules/Common/components-v2/KlaimForm';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {
  bodySiteCode,
  bodySiteSystem,
  drugSelectionReason,
  fdiOralRegionCode2,
  itemSystems,
  subSiteCode,
  subSiteSystem,
  toothSurfacedCode1,
} from 'modules/Common/constants/DropdownOptions';
import {Colors} from 'modules/Common/constants/Colors';
import ActivityCodeDropdown from 'modules/Activity/features/ActivityCodeDropdown';
import {useAppDispatch, useAppSelector} from '../../../../../../App/store';
import {activitySelect} from '../../../../../activitySlice';
import {contractsManagementSelect, getContracts} from '../../../../../../Settings/features/Contracts/ContractSlice';
import {
  branchManagementSelect,
  getBranches,
} from '../../../../../../Settings/features/BranchManagement/branchManagementSlice';
import {usePreAuthFormContext} from '../../../../../../PreAuthV2/features/PreAuthFormV2/PreAuthFormContext';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'grid',
      rowGap: '1rem',
      margin: 20,
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 40,
    },
    orContainer: {
      display: 'flex',
      alignItems: 'center',
      '& hr': {
        flex: 1,
        border: `1px solid ${Colors.grayLight}`,
        height: 0,
      },
      '& span': {
        width: '5%',
        textAlign: 'center',
        fontSize: 12,
      },
    },
    additionalInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      borderRadius: 10,
      background: theme.container.color.tertiary,
      '& h3': {
        fontSize: 14,
      },
      '& p': {
        fontSize: 12,
      },
    },
  };
});

interface ComponentProps {
  isClaim?: boolean;
  price?: any;
  discount?: any;
  onSelectActivity?: any;
  payerId?: string;
  providerId?: string;
  onBodySiteChange?: any;
  onBodySiteCodeChange?: any;
}

const ItemForm: React.FC<ComponentProps> = ({
  isClaim,
  onSelectActivity,
  payerId,
  providerId,
  onBodySiteChange,
  onBodySiteCodeChange,
}) => {
  const [isShadowBillingEnabled, setIsShadowBillingEnabled] = React.useState('Unit');
  const [unitPrice, setUnitPrice] = React.useState<number>(10);
  const [quantity, setQuantity] = React.useState<number>(0);
  const [factor, setFactor] = React.useState<number>(0);
  const [tax, setTax] = React.useState<number>(0);
  const [patientShare, setPatientShare] = React.useState<number>(0);
  const [bodySite, setBodySite] = React.useState<string>();
  const [subSite, setSubSite] = React.useState<string>();
  const [contractSearch, setContractSearch] = React.useState<string>();
  const [generic, setGeneric] = React.useState<boolean>(false);
  const [showGenericSection, setShowGenericSection] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {constants} = usePreAuthFormContext();

  const classes = useStylesFromThemeFunction();
  const {contracts, isLoading} = useAppSelector(contractsManagementSelect);

  const {
    branches: {
      data: {branches},
    },
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  const getData = () => {
    dispatch(
      getContracts({
        limit: 500,
        offset: 0,
        providerId: constants?.selectedBranch.identifier || providerId,
        hci: constants?.selectedTpa || payerId,
        search: contractSearch,
      }),
    );
  };
  useEffect(() => {
    if (branches.length > 0) getData();
  }, [branches, contractSearch]);

  return (
    <div className={classes.container}>
      <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'}}>
        {/*<KlaimFieldWrapper name={'item.something'}>*/}
        {/*  <KlaimDropdownSearch*/}
        {/*    options={[*/}
        {/*      {label: 'NPHIES', value: 'NPHIES'},*/}
        {/*      {label: 'HCP', value: 'HCP'},*/}
        {/*    ]}*/}
        {/*    label="Type"*/}
        {/*    variant="secondary"*/}
        {/*    placeholder="Choose"*/}
        {/*  />*/}
        {/*</KlaimFieldWrapper>*/}
        <KlaimFieldWrapper
          name={'item.itemCode'}
          onChange={(v) => {
            const c = contracts.find((el) => el.nphiesCode === v.nphiesCode && el.hcpCode === v.hcpCode);
            onSelectActivity(c);
            if (c && c.isPackageDrug) {
              setIsShadowBillingEnabled('Unit');
              setShowGenericSection(true);
              setUnitPrice(c.unitPrice);
            } else {
              setShowGenericSection(false);
              setUnitPrice(0);
            }
          }}
        >
          <KlaimDropdownSearch
            options={contracts.map((el) => ({
              label: `${el.hcpDescription} - ${el.nphiesCode} - ${el.hcpCode}`,
              value: el,
            }))}
            label="Search Activity Code"
            variant="secondary"
            placeholder="Choose"
            isLoading={isLoading}
            onInputChange={(v) => {
              setContractSearch(v);
            }}
          />
        </KlaimFieldWrapper>
        {showGenericSection && (
          <div style={{height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
            <KlaimToggle title="Generic" onChange={setGeneric} style={{width: '100%'}} />
          </div>
        )}
      </div>
      {generic && showGenericSection ? (
        <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'}}>
          <KlaimFieldWrapper name={'item.__'}>
            <KlaimInput label="Prescribed medication" variant="secondary" placeholder="Prescribed medication" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'item._'}>
            <KlaimDropdownSearch
              options={drugSelectionReason}
              label="Generic selection Reason"
              variant="secondary"
              placeholder="Choose"
            />
          </KlaimFieldWrapper>
        </div>
      ) : null}
      {isClaim && (
        <>
          <KlaimFieldWrapper name={'item.invoiceNo.number'}>
            <KlaimInput label="Item Invoice No." variant="secondary" placeholder="ex. I-253635" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'item.invoiceNo.system'}>
            <KlaimInput label="Item Invoice System" variant="secondary" placeholder="ex. https://www.gnp.sa/" />
          </KlaimFieldWrapper>
        </>
      )}
      {/*<KlaimFieldWrapper name={'item.itemCode'}>*/}
      {/*  <ActivityCodeDropdown />*/}
      {/*</KlaimFieldWrapper>*/}
      {showGenericSection && (
        <KlaimFieldWrapper
          name={'item.shadowBilling'}
          onChange={(value) => setIsShadowBillingEnabled(value)}
          style={{marginTop: 6}}
        >
          <KlaimRadioSelect
            value={isShadowBillingEnabled}
            selections={[
              {label: 'Unit', value: 'Unit'},
              {label: 'Package', value: 'Package'},
            ]}
          />
        </KlaimFieldWrapper>
      )}
      {isShadowBillingEnabled === 'Unit' && (
        <>
          {/*<KlaimFieldWrapper name={'item.itemCodeSfda'}>*/}
          {/*  <KlaimInput variant="secondary" label="Enter SFDA Code" placeholder="994IFDFHG90395..." />*/}
          {/*</KlaimFieldWrapper>*/}
          {/*<KlaimFieldWrapper name={'item.itemCodeSfdaDisplay'}>*/}
          {/*  <KlaimInput variant="secondary" label="Enter SFDA Description" placeholder="Description..." />*/}
          {/*</KlaimFieldWrapper>*/}
          {/*<div className={classes.orContainer}>*/}
          {/*  <hr />*/}
          {/*  <span>Or</span>*/}
          {/*  <hr />*/}
          {/*</div>*/}
          {/*<KlaimFieldWrapper name={'item.itemCodeHcp'}>*/}
          {/*  <KlaimInput variant="secondary" label="Enter HCP Code" placeholder="994IFDFHG90395..." />*/}
          {/*</KlaimFieldWrapper>*/}
          {/*<KlaimFieldWrapper name={'item.itemCodeHcpDisplay'}>*/}
          {/*  <KlaimInput variant="secondary" label="Enter HCP Description" placeholder="Description..." />*/}
          {/*</KlaimFieldWrapper>*/}
        </>
      )}
      <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'}}>
        <KlaimFieldWrapper
          name={'item.bodySite.system'}
          onChange={(value) => {
            setBodySite(value);
            onBodySiteChange(value);
          }}
        >
          <KlaimDropdownSearch
            isOptional
            options={bodySiteSystem}
            label="Body Site System"
            variant="secondary"
            placeholder="Choose"
          />
        </KlaimFieldWrapper>
        {bodySite && (
          <KlaimFieldWrapper name={'item.bodySite.code'} onChange={(value) => onBodySiteCodeChange(value)}>
            <KlaimDropdownSearch
              label="Body Site Code"
              variant="secondary"
              isOptional
              options={bodySite === '1' ? bodySiteCode : fdiOralRegionCode2}
            />
          </KlaimFieldWrapper>
        )}

        <KlaimFieldWrapper name={'item.subSite.system'} onChange={(value) => setSubSite(value)}>
          <KlaimDropdownSearch
            isOptional
            options={subSiteSystem}
            label="Sub Site System"
            variant="secondary"
            placeholder="Choose"
          />
        </KlaimFieldWrapper>
        {subSite && (
          <KlaimFieldWrapper name={'item.subSite.code'}>
            <KlaimDropdownSearch
              label="Sub Site Code"
              variant="secondary"
              isOptional
              options={subSite === '1' ? subSiteCode : toothSurfacedCode1}
            />
          </KlaimFieldWrapper>
        )}
      </div>
      <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'}}>
        <KlaimFieldWrapper type="number" name={'item.itemQty'} onChange={(value) => setQuantity(value)}>
          <KlaimInput label="Quantity" type="number" min="1" variant="secondary" placeholder="Quantity" />
        </KlaimFieldWrapper>
        <KlaimFieldWrapper type="number" name={'item.itemUnitPrice'} onChange={(value) => setUnitPrice(value)}>
          <KlaimInput label="Unit Price" value={unitPrice} type="number" variant="secondary" placeholder="Unit Price" />
        </KlaimFieldWrapper>
        <KlaimFieldWrapper type="number" name={'item.factor'} onChange={(value) => setFactor(value)}>
          <KlaimInput
            value={factor}
            label="Factor (%)"
            type="number"
            variant="secondary"
            placeholder="Factor (%)"
            max="1"
            min="0"
            step=".01"
          />
        </KlaimFieldWrapper>
        <KlaimFieldWrapper type="number" name={'item.itemTax'} onChange={(value) => setTax(value)}>
          <KlaimInput label="Item Tax" type="number" variant="secondary" placeholder="Item Tax" value={tax} />
        </KlaimFieldWrapper>
        <KlaimFieldWrapper type="number" name={'item.itemPatientShare'} onChange={(value) => setPatientShare(value)}>
          <KlaimInput
            label="Patient Share"
            type="number"
            variant="secondary"
            placeholder="Patient Share"
            value={patientShare}
          />
        </KlaimFieldWrapper>
        <KlaimInput
          label="Net Amount"
          value={((quantity || 0) * (unitPrice || 0) * (factor || 1) + tax).toString()}
          variant="secondary"
          placeholder="Net Amount"
          isReadOnly
        />
      </div>
    </div>
  );
};

export default ItemForm;
