import React from 'react';
import {KlaimScreenContext} from '../../index';

interface ComponentProps {
  name: string;
  index?: number;
}

const KlaimScreen: React.FC<ComponentProps> = ({name, index = 0, children}) => {
  const {currentScreen} = React.useContext(KlaimScreenContext);
  if (currentScreen.index !== index) return null;
  return <div>{children}</div>;
};

export default KlaimScreen;
