import {IPatientResponse} from 'interfaces/patient.interface';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';

interface MyKnownError {
  stack: {error: {message: string}; message?: ErrorMessageObject[] | string};
  statusCode: number;
  status: number;
  message: string;
}
interface ErrorMessageObject {
  property: string;
  constraints: {
    matches: string;
  };
}
interface InitialState {
  isLoading: boolean;
  isDeleted?: boolean;
  isCreated: boolean;
  isEdited: boolean;
  error?: MyKnownError;
  patient: IPatientResponse | null;
  patients: PatientsData;
}

const initialState: InitialState = {
  isDeleted: false,
  isCreated: false,
  isEdited: false,
  isLoading: false,
  patient: null,
  error: undefined,
  patients: {data: [], limit: 10, count: 0},
};

interface PatientsQuery {
  providerId: string;
  limit?: number;
  offset?: number;
  search?: string;
  sortBy?: string;
}
interface PatientsData {
  data: IPatientResponse[];
  limit?: number;
  count: number;
}

export const getPatients = createAsyncThunk<PatientsData, PatientsQuery, {rejectValue: MyKnownError}>(
  'patientManagement/getPatients',
  async (query: PatientsQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/patients`, {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

interface GetPatientAtrribute {
  id: string;
  providerId: string;
}

export const getPatient = createAsyncThunk<IPatientResponse, GetPatientAtrribute, {rejectValue: MyKnownError}>(
  'patientManagement/getPatient',
  async (data: GetPatientAtrribute, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/patients/${data.id}`, {params: {providerId: data.providerId}});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const PatientSlice = createSlice({
  name: 'patientManagement',
  initialState,
  reducers: {
    errorCleanup: (state) => {
      state.error = undefined;
    },
    patientCleanup: (state) => {
      state.patients = {data: [], limit: 10, count: 0};
      state.patient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatients.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.patients = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(getPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatient.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.patient = action.payload;
        state.isLoading = false;
        // Add user to the state array
      });
  },
});

export const {patientCleanup, errorCleanup} = PatientSlice.actions;
export const patientSelect = (state: RootState) => state.patients;
export default PatientSlice.reducer;
