import React, {FC, useEffect, useRef, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import queryString from 'query-string';
import dayjs from 'dayjs';
import editIcon from 'assets/edit_icon.svg';
import viewIcon from 'assets/view_icon.svg';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import Table from 'modules/Common/components/Table';
import * as Toast from 'modules/Common/utils/toast';
import Button from 'modules/Common/components/Button';
import {ClaimData, ClaimListDetails, ClaimStatus} from 'modules/Claims/api/types';
import ClaimStatusField from 'modules/Claims/components/ClaimStatusField';
import {selectClaimList} from 'modules/App/selectors';
import useApiRequest from 'modules/Common/hooks/useApiRequest';
import {updateClaimList} from 'modules/App/dispatchers';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {events, Mixpanel} from 'services/mixpanel';
import {useAppSelector} from '../../../../App/store';
import {Branches, headerSelect} from '../../../../Header/headerSlice';
import {NoData} from '../../../../Common/components/NoData';
import {KlaimModal} from '../../../../Common/components-v2/KlaimModalv2';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    dFlex: {
      display: 'flex',
    },
    editClaimBtn: {
      '& p': {
        color: Colors.purple,
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    paginationContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
    },
    patientListContainer: {
      background: Colors.white,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
    },
    tableWrapper: {
      flexGrow: 1,
    },
    totalAmountText: {
      marginBottom: 0,
      marginTop: 0,
    },
    viewClaimBtn: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    viewClaim: {
      fontWeight: theme.font.weightSemibold,
      marginLeft: 10,
    },
    newClaimButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 20,
      gap: 16,
    },
    w100: {
      width: '100%',
    },
  };
});

interface ComponentProps {
  theme?: KlaimTheme;
}

const DATE_FORMAT = 'DD MMM YYYY HH:MM';
const FILTER_DATE_FORMAT = 'YYYY-MM-DD';

const ClaimsList: FC<ComponentProps> = ({theme}) => {
  const classes = useStylesFromThemeFunction({theme});
  const tableDivRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const claimHistoryList: ClaimListDetails[] | null = useSelector((state: any) => selectClaimList(state));
  const [loadClaimHistoryList, claimHistoryLoading] = useApiRequest<ClaimData>(updateClaimList);
  const [totalClaimCount, setTotalClaimCount] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const {startDate, endDate, selectedStatus, searchInput, selectedBranches} = useAppSelector(headerSelect);

  const newStartDate = dayjs(startDate).format(FILTER_DATE_FORMAT);
  const newEndDate = dayjs(endDate).format(FILTER_DATE_FORMAT);
  const claimStatus = selectedStatus.map((status) => status.value).join(',');
  const claimBranches = selectedBranches.map((branch: Branches) => branch.value).join(',');

  const getClaimList = async (
    searchKeyword?: string | null,
    statusFilters?: string | null,
    dateTo?: string | null,
    dateFrom?: string | null,
    branches?: string | null,
  ) => {
    try {
      const queryParams = {
        limit,
        offset: (offset - 1) * limit,
        search: searchKeyword || '',
        status: statusFilters,
        from: dateFrom,
        to: dateTo,
        providerId: branches,
      };
      const requestUrl = queryString.stringifyUrl({
        query: queryParams,
        url: '/claims',
      });
      const {data} = await loadClaimHistoryList(requestUrl);
      setTotalClaimCount(data.count || 0);
    } catch (e: any) {
      if (typeof e === 'string') Toast.error(e);
      else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
    }
  };

  useEffect(() => {
    if (newStartDate && newEndDate && claimBranches) {
      const ClaimQuery = async () => {
        await getClaimList(searchInput, claimStatus, newEndDate, newStartDate, claimBranches);
      };
      ClaimQuery();
    }
  }, [limit, offset, claimStatus, searchInput, newStartDate, newEndDate, claimBranches]);

  // useEffect(() => {
  //   if (ifSelectedBranches() && isDateValid()) {
  //     (async () => {
  //       await getClaimList(
  //         searchInput,
  //         selectedStatus.map((status) => status.value).join(','),
  //         endDate,
  //         startDate,
  //         selectedBranches.map((branch: Branches) => branch.value).join(','),
  //       );
  //       Mixpanel.track(events.pageViews.claimListView);
  //     })();
  //   }
  // }, [limit, offset, selectedStatus, searchInput, startDate, endDate, selectedBranches]);

  const handleViewClaim = (claim: ClaimListDetails) => {
    history.push(`/claims/details?claimId=${claim.id}`);
  };

  const ifSelectedBranches = () => {
    return selectedBranches.length > 0;
  };

  const formatDateFilter = (date: string | null | undefined) => {
    if (date === null || date === undefined) return;
    return dayjs(date, 'DD/MM/YYYY').format(FILTER_DATE_FORMAT);
  };

  const isDateValid = () => {
    return dayjs(startDate, 'DD/MM/YYYY', true).isValid() && dayjs(endDate, 'DD/MM/YYYY', true).isValid();
  };

  return (
    <div className={classes.patientListContainer}>
      {ifSelectedBranches() ? (
        <>
          <div className={classes.newClaimButtonContainer}>
            <Button onClick={() => history.push('/claims/form')} title="Create New Claim" />
          </div>
          <div ref={tableDivRef} className={classes.tableWrapper}>
            <div className={classes.paginationContainer}>
              <KlaimPaginationContainer
                pageSize={limit}
                totalCount={totalClaimCount}
                currentPage={offset}
                onPageChange={setOffset}
                label="claims"
                className={classes.w100}
              >
                <Table
                  loading={claimHistoryLoading}
                  tableHeadings={[
                    <p className={classes.tableHeading}>Claim ID</p>,
                    <p className={classes.tableHeading}>Authorization ID</p>,
                    <p className={classes.tableHeading}>Member ID</p>,
                    <p className={classes.tableHeading}>Insurer</p>,
                    <p className={classes.tableHeading}>Submission Date</p>,
                    <p className={classes.tableHeading}>Status</p>,
                    <p className={classes.tableHeading}>Action</p>,
                  ]}
                  renderBody={() => (
                    <>
                      {claimHistoryList &&
                        claimHistoryList.map((row: ClaimListDetails, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{row?.claim_id}</td>
                              <td>I012-000-112411962-01</td>
                              <td>{row?.member_id}</td>
                              <td>{row?.payer_id}</td>
                              <td>{dayjs(row?.first_submission_date).format(DATE_FORMAT)}</td>
                              <td>
                                <ClaimStatusField status={row?.status} />
                              </td>
                              <td>
                                <div
                                  className={classes.viewClaimBtn}
                                  role="button"
                                  onClick={() => handleViewClaim(row)}
                                >
                                  <>
                                    <img src={viewIcon} alt="view" />
                                    <p className={classes.viewClaim}>View</p>
                                  </>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                />
              </KlaimPaginationContainer>
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default ClaimsList;
