import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    padding: 10,
    display: 'flex',
    borderTop: `1px solid ${Colors.grayLight}`,
    borderBottom: `1px solid ${Colors.grayLight}`,
    zIndex: 20,
    maxWidth: 186,
    position: 'relative',
    '&:hover': {
      background: Colors.blueGrayLight,
    },
  },
  containerClose: {
    padding: 10,
    maxWidth: 70,
    display: 'flex',
    borderTop: `1px solid ${Colors.grayLight}`,
    borderBottom: `1px solid ${Colors.grayLight}`,
    zIndex: 20,
    position: 'relative',
    marginLeft: 7,
    '&:hover': {
      background: Colors.blueGrayLight,
    },
  },
  nameContainerOpen: {
    '& h1': {
      fontSize: 18,
      fontWeight: 600,
      marginTop: 5,
      marginBottom: 5,
    },
    '& p': {
      fontSize: 13,
      color: Colors.purple,
      marginTop: 5,
      marginBottom: 5,
    },
    marginLeft: 5,
    opacity: 1,
    transition: [['all', '0.3s', 'ease']],
  },
  nameContainerClose: {
    marginLeft: 0,
    opacity: 0,
    transition: [['all', '0.3s', 'ease']],
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  contentContainer: {
    position: 'absolute',
    paddingBottom: 80,
    paddingLeft: 20,
  },
  contentContainerOpen: {
    position: 'absolute',
    bottom: 0,
    left: 200,
    paddingBottom: 80,
    paddingLeft: 20,
  },
  contentContainerClose: {
    position: 'absolute',
    bottom: 0,
    left: 80,
    paddingBottom: 80,
    paddingLeft: 20,
  },
  hoverContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
