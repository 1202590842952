import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    saveDraftButton: {
      marginRight: 18,
    },
    collapsibleSectionTxt: {
      fontWeight: 'bolder',
      fontSize: 18,
    },
    backBtnContainer: {
      marginLeft: 0,
      marginTop: 0,
    },
    backBtn: {
      cursor: 'pointer',
    },
    modal: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteUserContainer: {
      width: 472,
      height: 359,
      borderRadius: 10,
      backgroundColor: Colors.blueGrayLight,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerContainer: {
      '& h1': {
        fontSize: 18,
        fontWeight: 'normal',
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: Colors.white,
      padding: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    optionsButtonContainer: {
      display: 'flex',
      padding: 25,
      justifyContent: 'flex-end',
      gap: 10,
      backgroundColor: Colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    backButton: {
      background: Colors.white,
      color: Colors.black,
      border: `1px solid ${Colors.black}`,
    },
    messageContainer: {
      textAlign: 'center',
      color: Colors.blueGrayDark,
      '& h2': {
        fontSize: 18,
        fontWeight: 600,
      },
      '& p': {
        fontSize: 13,
      },
    },
    nameText: {
      color: Colors.purple,
      fontWeight: 600,
    },
    activityObservation: {
      color: Colors.purple,
    },
    activityObservationTitle: {
      width: '420px',
    },
    collapsedContainerClassName: {
      background: 'transparent',
      boxShadow: 'none',
      height: 48,
      position: 'relative',
      zIndex: 1,
    },
    collapsedContainerHeaderClassName: {
      position: 'sticky',
      top: 0,
    },
    collapsedContentClassName: {
      borderLeft: `2px solid ${Colors.purple}`,
      marginLeft: 45,
      paddingLeft: 15,
      paddingRight: 20,
      paddingTop: 0,
    },
    collapsibleHeaderContainer: {
      '& h5': {
        color: Colors.purpleDark,
        marginBottom: 0,
        marginTop: 0,
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: 75,
      justifyContent: 'space-between',
    },
    collapsibleHeaderTitle: {
      alignItems: 'center',
      color: Colors.purple,
      display: 'flex',
      fontWeight: 600,
    },
    containerContentClassName: {padding: 0},
    diagnosesContainer: {
      background: Colors.blueGrayLight,
      borderRadius: 20,
      marginBottom: 10,
      marginTop: 15,
      maxHeight: 400,
      overflowY: 'scroll',
    },
    divider: {
      background: Colors.pinkLight,
      height: 1,
      marginBottom: 14,
      width: '90%',
    },
    draftInfo: {
      background: Colors.blueGrayLight,
      borderRadius: '10px',
      fontSize: '13px',
      color: Colors.purpleDark,
      padding: '4px 10px 4px 10px',
    },
    drugInfo: {
      '& > div': {
        '& > div': {
          flex: 1,
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    expandedContainerClassName: {
      paddingBottom: 0,
    },
    form: {paddingBottom: 75},
    formButtonRightContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    formElementWithBottomSpacing: {
      marginBottom: 30,
      width: '100%',
    },
    formSectionElementsWrapper: {
      '@media (max-width: 776px)': {
        marginLeft: 100,
        marginRight: 100,
      },
      '@media (max-width: 530px)': {
        marginLeft: 50,
        marginRight: 50,
      },
      marginLeft: 200,
      marginRight: 200,
      paddingTop: 40,

      '& > div': {
        position: 'relative',
        '& p': {
          position: 'absolute',
          top: '65px',
          left: '15px',
        },
      },
    },
    formSectionTitleWrapper: {
      '& h2': {
        color: Colors.purpleDark,
      },
      flex: 1,
      textAlign: 'right',
    },
    itemsListed: {
      fontSize: 13,
      marginBottom: 38,
      paddingLeft: 20,
    },
    listsTitle: {
      fontSize: 14,
      fontWeight: 600,
      marginTop: 30,
      paddingLeft: 20,
    },
    ml4: {
      marginLeft: 4,
    },
    mr4: {
      marginRight: 4,
    },
    responsiveSelect: {
      width: '100%',
    },
    rowInputs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 30,
      position: 'relative',
    },
    dxContainer: {
      width: '100%',
    },
    inputDate: {
      width: 220,
    },
    rowInput: {
      width: '204px',
      '& input': {
        width: '204px',
      },
    },
    rowInputRight: {
      width: '100%',
      marginLeft: '30px',
      position: 'relative',
      '& p': {
        marginLeft: '13px !important',
        left: '0 !important',
      },
    },
    rowInputSelect: {
      '& select': {
        width: '157px',
      },
    },
    serviceDateInput: {
      minWidth: 180,
      width: '30%',
    },
    submitPreAuthButton: {
      marginRight: 20,
      width: 200,
    },
    textarea: {
      '& textarea': {
        padding: '10px 12px',
      },
    },
    w100: {
      width: '100%',
    },
    dFlex: {
      display: 'flex',
    },
    backDraftBtn: {
      marginRight: '20px',
    },
    deleteDraftBtn: {
      background: Colors.lightRed,
      marginRight: '20px',
    },
    positionRelative: {
      position: 'relative',
      margin: 6,
    },
    addActivityBtn: {
      alignSelf: 'center',
      background: Colors.purple,
      borderRadius: '7px',
      height: '24px',
      width: '24px',
      padding: '0',
      fontSize: '22px',
    },
    separatorBorder: {
      borderBottom: `1px solid ${Colors.grayLight}`,
      height: 'fit-content',
      width: '80%',
      alignSelf: 'center',
    },
    separatorBorderObservation: {
      borderBottom: `1px solid ${Colors.grayLight}`,
      height: 'fit-content',
      width: '70%',
      alignSelf: 'center',
    },
    justifyContentBetween: {
      justifyContent: 'space-between',
    },
    fileUploadBox: {
      width: '100%',
      '& > div': {
        '& p': {
          position: 'unset !important',
          marginTop: '15px',
        },
      },
    },
    removeActivityBtn: {
      background: Colors.grayLight,
      borderRadius: '7px',
      height: '24px',
      width: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    left0: {
      left: '0 !important',
    },
  };
});
