import React, {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Field} from 'formik';
import infoIcon from 'assets/info_icon.svg';
import dayjs from 'dayjs';
import ErxStatusField from 'modules/Pharmacy/components/ErxStatusField';
import {useParams, useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import KlaimDropdown from 'modules/Common/components/KlaimDropdown';
import KlaimForm from 'modules/Common/components/KlaimForm';
import HeaderContainer from 'modules/Common/components/HeaderContainer';
import Button from 'modules/Common/components/Button';
import Input from 'modules/Common/components/Input';
import {getAuthorizeId, erxDispenseSelect, authorizeErxById, dispenseErxById} from './erxDispenseSlice';
import {DiagnoseDispenseDetails, DrugDispenseDetails} from './types';
import {useStylesFromThemeFunction} from './ErxDispense';

const ErxDetails: FC = () => {
  const classes = useStylesFromThemeFunction();
  const {authorizeId}: {authorizeId: string} = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [refNumber, setReferenceNumber] = useState('');
  const {data: dispenseData} = useAppSelector(erxDispenseSelect);

  useEffect(() => {
    dispatch(getAuthorizeId({authorizeId}));
  }, [authorizeId]);

  useEffect(() => {
    function reRun() {
      dispatch(authorizeErxById({authorizeId}));
    }
    reRun();
    const interval = setInterval(() => reRun(), 20000);
    return () => {
      clearInterval(interval);
    };
  }, [authorizeId]);

  const referenceNumberValue = (referenceNumber: string) => {
    setReferenceNumber(referenceNumber);
  };

  const onSubmitRef = () => {
    history.push(`/pharmacy/prescription/${refNumber}`);
    setReferenceNumber('');
  };

  const intValue = [
    {
      label: 'eRx List',
      value: 'eRx List',
    },
    {
      label: 'eRx Request',
      value: 'eRx Request',
    },
  ];

  const checkValues = {
    activities: [],
  };

  const checkSchema = Yup.object({
    activities: Yup.array().required(),
  });

  interface Values {
    [field: string]: any;
  }

  const checkedForDispense = (value: Values) => {
    // console.log('dispense', value.activities);
    if (dispenseData) {
      dispatch(
        dispenseErxById({
          authorizeId,
          body: {
            activityIds: value.activities,
          },
        }),
      );
    }
  };

  // TODO Status icon,
  return (
    <>
      <KlaimForm initialValues={checkValues} validationSchema={checkSchema} onSubmit={checkedForDispense}>
        <HeaderContainer>
          <div className={classes.searchWrapper}>
            <KlaimDropdown expandOnHover name="eRx" options={intValue} />

            <div className={classes.inputSpacing}>
              <Input
                placeholder="eRx reference #"
                value={refNumber}
                onChange={(e) => referenceNumberValue(e.target.value)}
              />
              <button className={classes.buttonIcon} onClick={() => onSubmitRef()} />
            </div>
          </div>
          <div className={classes.mr20}>
            <button className={classes.cancelBtn} type="submit">
              Cancel
            </button>
            <button className={classes.klaimBtn} type="submit">
              Dispense
            </button>
          </div>
        </HeaderContainer>
        <div className={classes.cardSection}>
          <h2>Authorization</h2>
          <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
            <div className={classes.cardInfoSection}>
              eRx reference
              <span>{dispenseData?.reference}</span>
            </div>
            <div className={classes.cardInfoSection}>
              Status
              <ErxStatusField
                status={dispenseData?.status}
                submissionError={dispenseData?.authorization.submissionError}
              />
            </div>
            <div className={classes.cardInfoSection}>
              Auth. Obtained in
              <span>
                {'<'}
                {dayjs(dispenseData?.transaction_date_prior_authorization).diff(
                  dispenseData?.transaction_date,
                  'minutes',
                )}{' '}
                min
              </span>
            </div>
            <div className={classes.cardInfoSection}>
              Authorization ID
              <span>{dispenseData?.authorization.id}</span>
            </div>
          </div>
        </div>
        <div className={classes.dFlex}>
          <div className={`${classes.cardSection} ${classes.flex05}`}>
            <h2>Prescription Information</h2>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                Provider Name
                <span>
                  {dispenseData?.sender.name} ({dispenseData?.sender.id})
                </span>
              </div>
            </div>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                Clinician
                <span>
                  {dispenseData?.clinician.name} ({dispenseData?.clinician.id}))
                </span>
              </div>
            </div>
            <div className={`${classes.cardRow} ${classes.cardInfoSectionLast}`}>
              <div className={classes.cardInfoSection}>
                Prescription Date & Time
                <span>07 - Oct - 2020 21:59</span>
              </div>
            </div>
          </div>
          <div className={`${classes.cardSection} ${classes.ml20} ${classes.flex1}`}>
            <h2>Patient</h2>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                Emirates ID
                <span>{dispenseData?.patient.emirates_id}</span>
              </div>
              <div className={classes.cardInfoSection}>
                Emirates ID
                <span>{dispenseData?.patient.emirates_id}</span>
              </div>
            </div>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                Clinician
                <span>{dispenseData?.clinician.name}</span>
              </div>
              <div className={classes.cardInfoSection}>
                Weight
                <span>{dispenseData?.patient.weight}</span>
              </div>
            </div>
            <div className={`${classes.cardRow} ${classes.cardInfoSectionLast}`}>
              <div className={classes.cardInfoSection}>
                Insurance Plan
                <span>{dispenseData?.payer.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.cardSection}>
          <h2>Diagnosis</h2>
          <div className={`${classes.cardRow2} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
            {dispenseData?.diagnoses &&
              dispenseData.diagnoses.map((diagnose: DiagnoseDispenseDetails, index: number) => (
                <div className={classes.cardInfoSection} key={index}>
                  {diagnose.type === 'Principal' ? (
                    <div>
                      Principal
                      <span>
                        {diagnose.description.long} ({diagnose.code})
                      </span>{' '}
                    </div>
                  ) : (
                    <div>
                      Secondary
                      <span>
                        {diagnose.description.long} ({diagnose.code})
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={classes.cardSection}>
          <h2>Drugs</h2>
          <div className={classes.drugContainer}>
            {dispenseData?.drugs &&
              dispenseData.drugs.map((drug: DrugDispenseDetails, index: number) => {
                return (
                  <>
                    {drug.status === 'rejected' ? (
                      <div>disabled</div>
                    ) : (
                      <div className={classes.drugCard} key={index}>
                        <div className={classes.spaceCheckBox}>
                          <Field type="checkbox" name="activities" value={drug.activityId} />
                          Drug selection for dispensing
                        </div>
                        <div className={classes.cardRow}>
                          <div className={classes.cardInfoSection}>
                            Prescribed Drug {drug.activityId}
                            <span>
                              {drug.code} {drug.dosageFormPackage}
                            </span>
                          </div>
                        </div>
                        <div className={`${classes.cardRow} ${classes.flexUnset}`}>
                          <div className={classes.cardInfoSection}>
                            Quantity <span>{drug.quantity}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            Units
                            <span>Packet</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            Duration
                            <span>{drug.duration}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            Comment
                            <span>
                              <img src={infoIcon} alt="info" style={{marginRight: '10px'}} />
                              DHA-PROVIDER-DEMO-TPA-DEMO-20210608191432-0001
                            </span>
                          </div>
                        </div>
                        <div className={classes.cardRow}>
                          <div className={classes.cardInfoSection}>
                            Instructions
                            <span>{drug.instructions}</span>
                          </div>
                        </div>
                        <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
                          <div className={classes.cardInfoSection}>
                            Status
                            <span className={classes.statusColumn}>
                              <ErxStatusField status={drug?.status} />
                            </span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            Net
                            <span>{drug.net}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            Payment Amount
                            <span>33.09</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            Patient Share
                            <span>33.09</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </KlaimForm>
    </>
  );
};
export default ErxDetails;
