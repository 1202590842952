import React from 'react';
import {ComponentProps, useStylesFromThemeFunction} from 'modules/Common/layout/AuthV3Layout/AuthV3Layout';

const AuthV3Layout: React.FC<ComponentProps> = ({sidebar: Sidebar, body: Body, forceFullBody = false}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.container}>
      <div className={classes.sidebarContainer}>{Sidebar && Sidebar}</div>
      <div className={classes.bodyContainer} style={{marginLeft: forceFullBody ? 0 : 340}}>
        <div className={classes.bodySubContainer}>{Body && Body}</div>
      </div>
    </div>
  );
};

export default AuthV3Layout;
