import React from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';

interface ComponentsProps {
  open: boolean;
  error?: any;
  json?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const JsonModal: React.FC<ComponentsProps> = ({open, setOpen, json, error}) => {
  return (
    <KlaimModal open={open} setOpen={setOpen} maxWidth={1000}>
      <KlaimModalHeader title={'JSON'} />
      <KlaimModalBody style={{padding: 20, maxHeight: 600, overflowY: 'auto'}}>
        {Object.keys(json).length === 0 ? (
          <p>
            <pre>Not Found</pre>
          </p>
        ) : (
          <p>
            <pre>{JSON.stringify(json, null, 2)}</pre>
          </p>
        )}
      </KlaimModalBody>
      <KlaimModalFooter cancelButtonTitle={'OK'} submitLoading={false} hideSubmit />
    </KlaimModal>
  );
};

export default JsonModal;
