import React, {useState} from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import {useTranslation} from 'react-i18next';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {KlaimDropdownSearch, KlaimFileUploadV2, KlaimForm} from 'modules/Common/components-v2/KlaimForm';
import {uploadBulkClaims, uploadBulkPatients} from 'modules/Claims/api';
import * as Yup from 'yup';
import * as Toast from 'modules/Common/utils/toast';
import {useAppSelector} from '../../../App/store';
import {branchManagementSelect} from '../../../Settings/features/BranchManagement/branchManagementSlice';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    fileUploadBox: {},
  };
});

interface ComponentsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshList: any;
}
const BulkUploadModal: React.FC<ComponentsProps> = ({open, setOpen, refreshList}) => {
  const {t} = useTranslation();
  const classes = useStylesFromThemeFunction();
  const [providerId, setProviderId] = useState();
  const [patients, setPatients] = useState();
  const [uploading, setUploading] = useState(false);
  const {
    branches: {
      data: {branches},
    },
  } = useAppSelector(branchManagementSelect);

  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title="Bulk Upload" />
      <KlaimModalBody style={{padding: 20}}>
        <KlaimForm
          enableReinitialize
          validationSchema={Yup.object({})}
          initialValues={{}}
          onSubmit={() => {}}
          id="bulk-upload-form"
          style={{gap: 8, display: 'flex', flexDirection: 'column'}}
        >
          <KlaimDropdownSearch
            options={
              branches
                ? branches.map((branch) => {
                    return {
                      label: branch.name,
                      value: branch.identifier,
                    };
                  })
                : []
            }
            name="branch"
            label="Choose Branch"
            variant={'secondary'}
            onChange={(value) => {
              setProviderId(value);
            }}
          />

          <KlaimFileUploadV2
            label={t('Attach Files')}
            className={`${classes.fileUploadBox}`}
            acceptedFileTypes=".xlsx"
            multiple
            onChange={(value: any) => {
              if (value && value.length > 0) {
                setPatients(value[0].file);
              }
            }}
            dropAreaText="Drag and drop file"
            percentage={100}
          />
        </KlaimForm>
        <br />
        <a href={'/assets/patients_excel_template.xlsx'} download="claims_excel_template.csv">
          Download excel template
        </a>
      </KlaimModalBody>
      <KlaimModalFooter
        submitButtonTitle="Upload"
        onSubmit={async () => {
          setUploading(true);
          uploadBulkPatients(patients, providerId)
            .then(() => {
              Toast.success('Bulk patients uploaded', 'Success');
              refreshList();
            })
            .catch(() => Toast.error('An error occurred', 'Upload error'))
            .finally(() => {
              setOpen(false);
              setUploading(false);
            });
        }}
        cancelButtonTitle={'Cancel'}
        submitLoading={uploading}
      />
    </KlaimModal>
  );
};

export default BulkUploadModal;
