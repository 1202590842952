import React from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {Row, Col} from 'react-grid-system';
import {Field, FieldProps, FormikHelpers} from 'formik';
import {NavLink, useHistory} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {
  KlaimInput,
  KlaimForm,
  KlaimCalendarV2,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimDatePicker,
} from 'modules/Common/components-v2/KlaimForm';
import {gender, idType, maritalStatus, addressType} from 'modules/Common/constants/DropdownOptions';
import {branchManagementSelect, getBranches} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import * as Toast from 'modules/Common/utils/toast';
import countries from 'modules/Common/constants/DropdownOptions/country';
import {
  createPatient,
  preAuthV2Select,
  errorPatientCleanup,
  createPatientCleanup,
} from 'modules/PreAuthV2/PreAuthV2Slice';
import {ICreatePatientRequest} from 'interfaces/pre-auth.interface';
import {useStylesFromThemeFunction} from './patientsAdd';

export interface IValues {
  [field: string]: any;
}

const Component: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [retries, setRetries] = React.useState(5);

  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier);
  };

  const {isCreatePatientSuccess, error, isLoading} = useAppSelector(preAuthV2Select);
  const initialValues = {
    firstName: '',
    middleName: null,
    lastName: '',
    dateOfBirth: '',
    gender: '',
    maritalStatus: '',
    phone: null,
    documentIdType: '',
    documentId: '',
    country: null,
    city: null,
    addressText: null,
    addressType: null,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    dateOfBirth: Yup.string().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    maritalStatus: Yup.string().required('Marital Status is required'),
    documentIdType: Yup.string().required('ID Type is required'),
    documentId: Yup.string().required('ID Number is required'),
  });

  const onSubmit: (
    values: IValues,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: IValues, formikHelpers) => {
    const payload: ICreatePatientRequest = {
      providerId: values.providerId,
      name: `${values.firstName} ${values.middleName || ''} ${values.lastName}`,
      fullName: [values.firstName, values.middleName, values.lastName],
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      phone: values.phone,
      documentIdType: values.documentIdType,
      documentId: values.documentId,
      maritalStatus: values.maritalStatus,
    };
    if (values.addressType)
      payload.address = {
        use: values.addressType,
        text: values.addressText,
        city: values.city,
        country: values.country,
      };
    dispatch(createPatient(payload));
  };

  React.useEffect(() => {
    if (!isBranchLoading && branches.length === 0 && retries > 0) {
      dispatch(getBranches({}));
      setRetries(retries - 1);
    }
  }, [branches, isBranchLoading]);

  React.useEffect(() => {
    return () => {
      dispatch(errorPatientCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      Toast.error(error.stack ? error?.stack?.error?.message : error?.message);
    }
  }, [error]);

  React.useEffect(() => {
    if (isCreatePatientSuccess === true) {
      Toast.success('Patient is added successfully', 'Success');
      history.push('/patients');
    }
    return () => {
      dispatch(createPatientCleanup());
    };
  }, [isCreatePatientSuccess]);

  return (
    <KlaimForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      <Header
        title=""
        backlink="/patients"
        description={null}
        buttonType="submit"
        buttonTitle="Create"
        body={
          <div>
            {isLoading || isBranchLoading || branches.length === 0 ? (
              <div>
                {retries > 0 ? (
                  <LoadingSpinner />
                ) : (
                  <span>
                    Can't find any branch, Add your first branch in{' '}
                    <NavLink to="/settings/branch-management">Branch Management</NavLink>
                  </span>
                )}
              </div>
            ) : (
              <>
                {error && <div className={classes.errorMessage}>{error?.message}</div>}
                <Field name="active">
                  {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                    return (
                      <>
                        <Row>
                          <Col sm={12}>
                            <div className={classes.bodyTitle}>Beneficiary Primary Details</div>
                            <div className={classes.bodysubTitle}>Please check all details before submitting.</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Row>
                              <Col sm={12}>
                                <KlaimDropdownSearch
                                  options={
                                    branches
                                      ? branches.map((branch) => {
                                          return {
                                            label: branch.name,
                                            value: branch.identifier,
                                          };
                                        })
                                      : []
                                  }
                                  name="branch"
                                  label="Choose Branch"
                                  variant={'secondary'}
                                  onChange={(value) => {
                                    setFieldValue('providerId', value);
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row style={{padding: '30px 0 0 0'}}>
                              <Col sm={12}>
                                <div className={classes.sectionHeader}>PRIMARY ID</div>
                              </Col>
                            </Row>
                            <Row className={classes.formRow}>
                              <Col sm={12} className={classes.formGroup}>
                                <Row>
                                  <Col sm={4} className={classes.inputWrap}>
                                    <KlaimDropdownSearch
                                      label="ID Type"
                                      options={idType}
                                      onChange={(val) => {
                                        setFieldValue('documentIdType', val);
                                      }}
                                      value={values.documentIdType}
                                      isError={'documentIdType' in errors && 'documentIdType' in touched}
                                      isTouched={'documentIdType' in touched}
                                      variant={'secondary'}
                                    />
                                  </Col>
                                  <Col sm={4} className={classes.inputWrap}>
                                    <KlaimInput
                                      name="documentId"
                                      onChange={(val) => {
                                        setFieldValue('documentId', val);
                                      }}
                                      label="ID Number"
                                      value={values.documentId || ''}
                                      isError={'documentId' in errors && 'documentId' in touched}
                                      isTouched={'documentId' in touched}
                                      placeholder="ID Number"
                                    />
                                  </Col>
                                  <Col sm={4} className={classes.inputWrap}>
                                    <KlaimInput
                                      name="phone"
                                      onChange={(val) => {
                                        setFieldValue('phone', val);
                                      }}
                                      type="phone"
                                      label="Primary Number"
                                      value={values.phone || ''}
                                      isError={'phone' in errors && 'phone' in touched}
                                      isTouched={'phone' in touched}
                                      placeholder="Primary Number"
                                      defaultCountry={'SA'}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row style={{padding: '30px 0'}}>
                          <Col sm={6}>
                            <Row>
                              <Col sm={12}>
                                <div className={classes.sectionHeader}>NAME</div>
                              </Col>
                            </Row>
                            <Row className={classes.formRow}>
                              <Col sm={12} className={classes.formGroup}>
                                <Row>
                                  <Col sm={12} className={classes.inputWrap}>
                                    <KlaimInput
                                      name="firstName"
                                      onChange={(val) => {
                                        setFieldValue('firstName', val);
                                      }}
                                      value={values.firstName || ''}
                                      isError={'firstName' in errors && 'firstName' in touched}
                                      isTouched={'firstName' in touched}
                                      label="First Name"
                                      placeholder="First Name"
                                    />
                                  </Col>
                                  <Col sm={12} className={classes.inputWrap}>
                                    <KlaimInput
                                      name="middleName"
                                      onChange={(val) => {
                                        setFieldValue('middleName', val);
                                      }}
                                      value={values.middleName || ''}
                                      isError={'middleName' in errors && 'middleName' in touched}
                                      isTouched={'middleName' in touched}
                                      label="Middle Name"
                                      placeholder="Middle Name"
                                    />
                                  </Col>
                                  <Col sm={12} className={classes.inputWrap}>
                                    <KlaimInput
                                      name="lastName"
                                      onChange={(val) => {
                                        setFieldValue('lastName', val);
                                      }}
                                      value={values.lastName || ''}
                                      isError={'lastName' in errors && 'lastName' in touched}
                                      isTouched={'lastName' in touched}
                                      label="Last Name"
                                      placeholder="Last Name"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={6}>
                            <Row>
                              <Col sm={12}>
                                <div className={classes.sectionHeader}>OTHERS</div>
                              </Col>
                            </Row>
                            <Row className={classes.formRow}>
                              <Col sm={12} className={classes.formGroup}>
                                <Row>
                                  <Col sm={12} className={classes.inputWrap}>
                                    {/* <KlaimCalendarV2
                                      onChange={(val: any) => {
                                        setFieldValue('dateOfBirth', dayjs(val).format('YYYY-MM-DD'));
                                      }}
                                      variant="with-input"
                                      label="Date of Birth"
                                      isError={'dateOfBirth' in errors && 'dateOfBirth' in touched}
                                      isTouched={'dateOfBirth' in touched}
                                    /> */}
                                    <KlaimFieldWrapper name="dateOfBirth">
                                      <KlaimDatePicker
                                        label="Birth Date"
                                        name="end"
                                        variant="secondary"
                                        maxDate={new Date()}
                                      />
                                    </KlaimFieldWrapper>
                                  </Col>
                                  <Col sm={12} className={classes.inputWrap}>
                                    <KlaimDropdownSearch
                                      label="Gender"
                                      options={gender}
                                      onChange={(val) => {
                                        setFieldValue('gender', val);
                                      }}
                                      value={values.gender}
                                      isError={'gender' in errors && 'gender' in touched}
                                      isTouched={'gender' in touched}
                                      variant={'secondary'}
                                    />
                                  </Col>
                                  <Col sm={12} className={classes.inputWrap}>
                                    <KlaimDropdownSearch
                                      label="Marital Status"
                                      options={maritalStatus}
                                      onChange={(val) => {
                                        setFieldValue('maritalStatus', val);
                                      }}
                                      value={values.maritalStatus}
                                      isError={'maritalStatus' in errors && 'maritalStatus' in touched}
                                      isTouched={'maritalStatus' in touched}
                                      variant={'secondary'}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <Row>
                              <Col sm={12}>
                                <div className={classes.sectionHeader}>ADDRESS</div>
                              </Col>
                            </Row>
                            <Row className={classes.formRow}>
                              <Col sm={12} className={classes.formGroup}>
                                <Row>
                                  <Col sm={12} className={classes.inputWrap}>
                                    <KlaimFieldWrapper name={'addressType'}>
                                      <KlaimDropdownSearch
                                        variant={'secondary'}
                                        label={'Address Type'}
                                        options={addressType}
                                        placeholder={'Select Address Type'}
                                        isOptional
                                      />
                                    </KlaimFieldWrapper>
                                  </Col>
                                </Row>
                                {values.addressType && (
                                  <Row>
                                    <Col sm={4} className={classes.inputWrap}>
                                      <KlaimDropdownSearch
                                        label="Country"
                                        options={countries}
                                        onChange={(val) => {
                                          setFieldValue('country', val);
                                        }}
                                        value={values.country}
                                        isError={'country' in errors && 'country' in touched}
                                        isTouched={'country' in touched}
                                        variant={'secondary'}
                                      />
                                    </Col>
                                    <Col sm={4} className={classes.inputWrap}>
                                      <KlaimInput
                                        name="city"
                                        onChange={(val) => {
                                          setFieldValue('city', val);
                                        }}
                                        label="City"
                                        value={values.city || ''}
                                        isError={'city' in errors && 'city' in touched}
                                        isTouched={'city' in touched}
                                        placeholder="City"
                                      />
                                    </Col>
                                    <Col sm={4} className={classes.inputWrap}>
                                      <KlaimInput
                                        name="addressText"
                                        onChange={(val) => {
                                          setFieldValue('addressText', val);
                                        }}
                                        label="Address"
                                        value={values.addressText || ''}
                                        isError={'addressText' in errors && 'addressText' in touched}
                                        isTouched={'addressText' in touched}
                                        placeholder="House Number, Street"
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                </Field>
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default Component;
