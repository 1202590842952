import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'services/axios';
import {decodeJWT, TokenData} from 'services/jwt';
import {set as setStorage, remove as removeStorage} from 'local-storage';
import {RootState} from 'modules/App/store';
import {login} from 'modules/Auth/v3/features/Login/loginSlice';
import {register} from 'modules/Auth/v3/features/Register/registerSlice';
import {changePassword, forgotPassword} from 'modules/Auth/v3/features/Forgot/forgotPasswordSlice';
import {acceptInvite} from '../Invite/inviteSlice';

export interface InitialState {
  page: string;
  isLoading: boolean;
  isCheckLoading: boolean;
  isAuthenticated: boolean;
  isValidating: number;
  isRegister: boolean;
  success: boolean;
  branches: Branches[];
  hashValidated: boolean;
  loginResponse: any;
  user: TokenData;
  error?: MyKnownError;
  isClaimAllowed: boolean;
}

interface Branches {
  id: number;
  name: string;
  identifier: string;
  authority: string;
}

interface ErrorDetails {
  property: string;
  children: any;
  constraints: any;
}

interface MyKnownError {
  code: number;
  details: ErrorDetails[];
  message: string;
}

const initialState: InitialState = {
  page: 'CREATE_ACCOUNT',
  isClaimAllowed: false,
  isLoading: false,
  isCheckLoading: false,
  isAuthenticated: false,
  hashValidated: false,
  isRegister: false,
  isValidating: 0,
  success: false,
  loginResponse: {},
  branches: [{id: 0, name: '', identifier: '', authority: ''}],
  user: {
    id: 0,
    name: '',
    role: {id: 0, name: '', description: ''},
    organization: {id: 0, name: '', country: ''},
    permissions: [''],
    modules: [''],
    iat: 0,
    exp: 0,
    mongo_id: '',
    email: '',
    phone: '',
    is_active: '',
    country: 'UAE',
    klaim_super_admin: false,
  },
};
interface GetBranchAttribute {
  token: string;
}

export const getBranches = createAsyncThunk('auth/getBranches', async (data, {rejectWithValue}) => {
  try {
    const response = await axios.get('/users/current/branches');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const validate = createAsyncThunk('auth/validate', async (_, {rejectWithValue}) => {
  try {
    const response = await axios.get('/auth/refresh-token');
    return {
      user: decodeJWT(response.data.accessToken),
      accessToken: response.data.accessToken,
      isClaimAllowed: response.data.isClaimAllowed,
    };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  return true;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    registrationDone: (state) => {
      state.isAuthenticated = true;
    },
    pageChange: (state, action) => {
      state.page = action.payload;
    },
    registerCleanUp: (state) => {
      state.success = false;
    },
    errorCleanUp: (state) => {
      state.success = false;
      state.error = undefined;
    },
    accountSetupCleanUp: (state) => {
      state.page = 'CREATE_ACCOUNT';
    },
    isAuthorizedToModule: (state, action) => {
      console.log(action);
    },
    hasPermission: (state, action) => {
      console.log(action);
    },
    expiredToken: (state) => {
      state.isAuthenticated = false;
      removeStorage('accessToken');
    },
    getNewName: (state, action) => {
      state.user.name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validate.fulfilled, (state, action) => {
        const {user, accessToken, isClaimAllowed} = action.payload;
        state.isClaimAllowed = isClaimAllowed;
        if (setStorage('accessToken', accessToken)) {
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          state.user = user;
          state.isAuthenticated = true;
          state.isLoading = false;
        }
      })
      .addCase(validate.rejected, (state) => {
        removeStorage('accessToken');
        axios.defaults.headers.common.Authorization = undefined;
        state.isAuthenticated = false;
        state.isLoading = false;
      });
    builder.addCase(getBranches.fulfilled, (state, action) => {
      state.branches = action.payload;
    });
    builder
      .addCase(login.fulfilled, (state, action) => {
        const {user, accessToken} = action.payload;
        if (setStorage('accessToken', accessToken)) {
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          state.user = user;
          state.isAuthenticated = true;
          state.isLoading = false;
          const lastPathname = window.history?.state?.state?.from?.pathname;
          if (lastPathname) window.location.href = lastPathname;
        }
      })
      .addCase(login.rejected, (state) => {
        state.isAuthenticated = false;
      });
    builder.addCase(logout.fulfilled, (state) => {
      removeStorage('accessToken');
      state.isAuthenticated = false;
      state.page = 'CREATE_ACCOUNT';
      state.isValidating = 0;
      state.branches = [{id: 0, name: '', identifier: '', authority: ''}];
      state.user = {
        id: 0,
        name: '',
        role: {id: 0, name: '', description: ''},
        organization: {id: 0, name: '', country: ''},
        permissions: [''],
        modules: [''],
        iat: 0,
        exp: 0,
        mongo_id: '',
        email: '',
        phone: '',
        is_active: '',
        country: 'UAE',
        klaim_super_admin: false,
      };
      axios.defaults.headers.common.Authorization = undefined;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.success = true;
      const {user, accessToken} = action.payload;
      if (setStorage('accessToken', accessToken)) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        state.user = user;
        state.isAuthenticated = true;
        state.isLoading = false;
      }
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.success = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.success = true;
    });
    builder.addCase(acceptInvite.fulfilled, (state, action) => {
      state.success = true;
      const {user, accessToken} = action.payload;
      if (setStorage('accessToken', accessToken)) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        state.user = user;
        state.isAuthenticated = true;
        state.isLoading = false;
      }
    });
  },
});

export const {
  pageChange,
  registerCleanUp,
  errorCleanUp,
  registrationDone,
  accountSetupCleanUp,
  expiredToken,
  getNewName,
} = authSlice.actions;
export const authSelect = (state: RootState) => state.auth.auth;
export default authSlice.reducer;
