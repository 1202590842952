import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {events, Mixpanel} from 'services/mixpanel';
import {RootState} from 'modules/App/store';
import Body from 'modules/Main/Body';
import axios from 'services/axios';
import {
  ErxDetailsById,
  ErxID,
  ErxDescriptionError,
  ErxDispenseDescription,
  ErxDispenseDescriptionResponseId,
  InitialState,
  DefaultAttributes,
} from './types';

export const getErxById = createAsyncThunk<ErxDetailsById, ErxID, {rejectValue: ErxDescriptionError}>(
  'erxDescription/getErxByID',
  async (data: ErxID, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/erx/${data.erxId}`, {params: {...data}});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const refreshErxById = createAsyncThunk<DefaultAttributes, ErxID, {rejectValue: ErxDescriptionError}>(
  'erxDescription/refreshErxById',
  async (data: ErxID, {rejectWithValue}) => {
    try {
      const response = await axios.post(`/erx/${data.erxId}/refresh`, {...data});
      // console.log('refresh', response);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const dispenseErxByDescriptionId = createAsyncThunk<
  ErxDispenseDescriptionResponseId,
  ErxDispenseDescription,
  {rejectValue: ErxDescriptionError}
>('erxDispense/dispenseErxByDescriptionId', async (data: ErxDispenseDescription, {rejectWithValue}) => {
  try {
    const response = await axios.post(`/erx/${data.erxId}/dispense`, {...data});
    // console.log('dispense from description', response);
    Mixpanel.track(events.pharmacy.dispense, {success: true});
    return response.data.id;
  } catch (err: any) {
    Mixpanel.track(events.pharmacy.dispense, {success: false});
    return rejectWithValue(err);
  }
});

const initialState: InitialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  viewId: undefined,
};

const erxDescriptionSlice = createSlice({
  name: 'erxDescription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getErxById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getErxById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getErxById.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(dispenseErxByDescriptionId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dispenseErxByDescriptionId.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(dispenseErxByDescriptionId.fulfilled, (state, action) => {
        state.viewId = action.payload;
        state.isLoading = false;
      });
  },
});

export const erxDescriptionSelect = (state: RootState) => state.pharmacy.erxDescription;
export default erxDescriptionSlice.reducer;
