import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  emailPasswordInstructions: {
    background: Colors.white,
    width: 471,
    height: 547,
    borderRadius: 10,
    padding: 20,
  },
  subEmailPasswordInstructions: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    display: 'flex',
    '& h1': {
      fontsize: '24px',
    },
    '& p': {
      fontsize: '12px',
    },
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  headerContainer: {
    '& h1': {
      fontSize: 24,
    },
  },
  questionContainer: {
    '& span': {
      fontSize: 14,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 14,
  },
  message: {
    width: 300,
    color: Colors.grayLighter,
    fontWeight: 600,
    fontSize: 12,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});
