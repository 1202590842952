import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import _, {get} from 'lodash';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import ItemForm from 'modules/Activity/features/ActivitySubForm/ActivityModal/components/ItemForm';
import {IActivityForm, ICareTeam, IDiagnosis, ISupportingInfo} from 'interfaces/pre-auth.interface';
import {
  KlaimFieldWrapper,
  KlaimDropdownSearch,
  KlaimForm,
  KlaimInput,
  KlaimToggle,
  KlaimRadioSelect,
} from 'modules/Common/components-v2/KlaimForm';
import dayjs from 'dayjs';
import {createUseStyles} from 'react-jss';
import {cleanUpObject} from '../../../../../../../helpers/general-helper';
import KlaimDatePicker from '../../../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {useAppDispatch, useAppSelector} from '../../../../../../App/store';
import {contractsManagementSelect, getContracts} from '../../../../../../Settings/features/Contracts/ContractSlice';
import {
  branchManagementSelect,
  getBranches,
} from '../../../../../../Settings/features/BranchManagement/branchManagementSlice';
// import {
//   bodySiteCode,
//   bodySiteSystem,
//   drugSelectionReason,
//   fdiOralRegionCode2,
//   subSiteCode,
//   subSiteSystem,
//   toothSurfacedCode1,
// } from '../../../../../../Common/constants/DropdownOptions';
import {KlaimTheme} from '../../../../../../../interfaces/klaim-theme.interface';
import {Colors} from '../../../../../../Common/constants/Colors';
import {insurancesManagementSelect} from '../../../../../../Settings/features/Insurance/InsuranceSlice';

interface ComponentProps {
  isOpen: boolean;
  activity: any;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (value: any) => void;
  itemId: string;
  diagnosis?: IDiagnosis[];
  careTeam?: ICareTeam[];
  supportingInfo?: ISupportingInfo[];
  isClaim?: boolean;
  selectedActivityIndex?: number;
  providerId?: any;
  payerId?: any;
  editingItem?: any;
}
export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'grid',
      rowGap: '1rem',
      margin: 20,
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 40,
    },
    orContainer: {
      display: 'flex',
      alignItems: 'center',
      '& hr': {
        flex: 1,
        border: `1px solid ${Colors.grayLight}`,
        height: 0,
      },
      '& span': {
        width: '5%',
        textAlign: 'center',
        fontSize: 12,
      },
    },
    additionalInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      borderRadius: 10,
      background: theme.container.color.tertiary,
      '& h3': {
        fontSize: 14,
      },
      '& p': {
        fontSize: 12,
      },
    },
  };
});
const ActivityModal: React.FC<ComponentProps> = ({
  isOpen,
  setIsOpen,
  activity,
  onSubmit,
  diagnosis = [],
  careTeam = [],
  supportingInfo = [],
  isClaim,
  providerId,
  selectedActivityIndex,
  payerId,
  editingItem,
}) => {
  const getInitialValue = (path, defaultValue) =>
    get(activity, `[${selectedActivityIndex}].item.${path}`, defaultValue);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [isShadowBillingEnabled, setIsShadowBillingEnabled] = React.useState('Unit');
  const [unitPrice, setUnitPrice] = React.useState<number>(10);
  const [quantity, setQuantity] = React.useState<number>(0);
  const [factor, setFactor] = React.useState<number>(0);
  const [tax, setTax] = React.useState<number>(0);
  const [patientShare, setPatientShare] = React.useState<number>(0);
  const [bodySiteSystem, setBodySiteSystem] = React.useState<string>();
  const [bodySiteCode, setBodySiteCode] = React.useState<string>();
  const [subSite, setSubSite] = React.useState<string>();
  const [contractSearch, setContractSearch] = React.useState<string>();
  const [selectedContract, setSelectedContract] = React.useState<any>({});
  const [generic, setGeneric] = React.useState<boolean>(false);
  const [showGenericSection, setShowGenericSection] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    insurances: {data: insurances},
  } = useAppSelector(insurancesManagementSelect);

  // useEffect(() => {
  //   if (activity) {
  //     setPrice(getInitialValue('itemUnitPrice', 0));
  //     setStartDate(initialStartDate);
  //     setEndDate(initialEndDate);
  //     setDiscount(getInitialValue('factor', 0));
  //   }
  // }, [activity]);

  const {
    branches: {
      data: {branches},
    },
  } = useAppSelector(branchManagementSelect);
  const branch = branches.find((b) => b.identifier === providerId) || ({} as any);

  const initialValues = {
    careTeam: [],
    item: {
      itemIsPackage: getInitialValue('itemIsPackage', false),
      itemSystem: getInitialValue('itemSystem', undefined),
      itemTax: tax,
      itemPatientShare: patientShare,
      itemCode: selectedContract?.nphiesCode,
      itemQty: quantity,
      itemUnitPrice: unitPrice,
      itemCodeHcp: getInitialValue('itemCodeHcp', ''),
      itemCodeSfda: getInitialValue('itemCodeSfda', ''),
      factor,
      itemCodeSfdaDisplay: '',
      itemCodeHcpDisplay: '',
      itemCodeDisplay: '',
      invoiceNo: {
        number: undefined,
        system: undefined,
        isClaim,
      },
      subSite: {
        system: undefined,
        code: undefined,
      },
      bodySite: {
        system: undefined,
        code: undefined,
      },
      shadowBilling: 'Unit',
      informationSequence: getInitialValue('informationSequence', []),
      diagnosisSequence: getInitialValue('diagnosisSequence', []),
      itemCareTeamSeq: undefined,
      start: getInitialValue('start', new Date()),
      end: getInitialValue('end', new Date()),
    },
  };

  if (editingItem) {
    initialValues.careTeam = careTeam;
    initialValues.item.itemIsPackage = editingItem.itemIsPackage || initialValues.item.itemIsPackage;
    initialValues.item.itemSystem = editingItem.itemSystem || getInitialValue('itemSystem', undefined);
    initialValues.item.itemTax = editingItem.itemTax || tax;
    initialValues.item.itemPatientShare = editingItem.itemPatientShare || patientShare;
    initialValues.item.itemCode = editingItem.itemCode || selectedContract?.nphiesCode;
    initialValues.item.itemQty = editingItem.itemQty || quantity;
    initialValues.item.itemUnitPrice = editingItem.itemUnitPrice || unitPrice;
    initialValues.item.itemCodeHcp = editingItem.itemCodeHcp || getInitialValue('itemCodeHcp', '');
    initialValues.item.itemCodeSfda = editingItem.itemCodeSfda || getInitialValue('itemCodeSfda', '');
    initialValues.item.factor = editingItem.factor || factor;
    initialValues.item.itemCodeSfdaDisplay = editingItem.itemCodeSfdaDisplay || '';
    initialValues.item.itemCodeHcpDisplay = editingItem.itemCodeHcpDisplay || '';
    initialValues.item.itemCodeDisplay = editingItem.itemCodeDisplay || '';
    initialValues.item.invoiceNo = editingItem.invoiceNo || {
      number: undefined,
      system: undefined,
      isClaim,
    };
    initialValues.item.subSite = editingItem.subSite || {
      system: undefined,
      code: undefined,
    };
    initialValues.item.bodySite = editingItem.bodySite || {
      system: undefined,
      code: undefined,
    };
    initialValues.item.shadowBilling = editingItem.shadowBilling || 'Unit';
    initialValues.item.informationSequence =
      editingItem.informationSequence || getInitialValue('informationSequence', []);
    initialValues.item.diagnosisSequence = editingItem.diagnosisSequence || getInitialValue('diagnosisSequence', []);
    initialValues.item.itemCareTeamSeq = editingItem.itemCareTeamSeq || undefined;
    initialValues.item.start = editingItem.start || getInitialValue('start', new Date());
    initialValues.item.end = editingItem.end || getInitialValue('end', new Date());
  }

  const validationSchema = Yup.object({
    item: Yup.object({
      // invoiceNo: Yup.object().shape({
      //   system: Yup.string().when('isClaim', {
      //     is: (value) => value,
      //     then: Yup.string().required('Item Invoice System is required.'),
      //   }),
      //   number: Yup.string().when('isClaim', {
      //     is: (value) => value,
      //     then: Yup.string().required('Item Invoice Number is required.'),
      //   }),
      // }),
      // itemSystem: Yup.number().required('Activity Type is required.'),
      itemCode: Yup.string(),
      // itemPatientShare: Yup.number().required('Patient share is required.'),
      // factor: Yup.number().required('Factor is required.'),
      itemQty: Yup.number().required('Item Quantity is required.'),
      itemUnitPrice: Yup.number().required('Item unit price is required.'),
      itemCareTeamSeq: Yup.array().of(Yup.number()).required('Care Team is Required'),
      bodySite: Yup.object().shape({
        system: Yup.number(),
        code: Yup.string().when('system', {
          is: (value) => value,
          then: Yup.string().required('Body Site Code is required.'),
        }),
      }),
      subSite: Yup.object().shape({
        system: Yup.number(),
        code: Yup.string().when('system', {
          is: (value) => value,
          then: (code) => code.required('Sub Site Code is required.'),
        }),
      }),
      start: Yup.date(),
      end: Yup.date(),
    }),
  });

  const handleSubmit = (value: any) => {
    if (onSubmit) {
      const payload: IActivityForm = {...value};
      const {start, end} = value.item;
      if (start === end) {
        payload.item = {...value.item, servicedDate: dayjs(start).format('YYYY-MM-DD')};
      } else {
        const servicedPeriod = {
          start: dayjs(start).format('YYYY-MM-DD'),
          end: dayjs(end).format('YYYY-MM-DD'),
        };
        payload.item = {...value.item, servicedPeriod};
      }
      // const itemCodeDisplay = activities.filter((el) => el.activityCode === payload.item.itemCode)[0]?.shortDescription;
      // const ctr = selectedContract as any;
      payload.item = {
        ...payload.item,
        itemCode: selectedContract?.nphiesCode,
        itemCodeHcp: selectedContract?.hcpCode,
        itemCodeHcpDisplay: selectedContract?.hcpDescription,
        itemQty: +payload.item.itemQty,
        itemTax: +payload.item.itemTax,
        itemPatientShare: value.item.itemPatientShare.toString(),
        start: startDate,
        end: endDate,
        bodySite: bodySiteCode && bodySiteSystem ? {code: bodySiteCode, system: +bodySiteSystem} : undefined,
      };
      delete payload.item.start;
      delete payload.item.end;
      const cleanedUp = cleanUpObject(payload);
      onSubmit({
        ...cleanedUp,
        item: {...cleanedUp.item, itemPatientShare: +value.item.itemPatientShare, itemTax: +value.item.itemTax},
      });
      setIsOpen(false);
    }
  };

  const classes = useStylesFromThemeFunction();
  const {contracts} = useAppSelector(contractsManagementSelect);
  useEffect(() => {
    if (activity.itemCode) {
      const c = contracts.find((el) => el.nphiesCode === activity.itemCode);
      onSelectActivity(c);
      setUnitPrice(c?.unitPrice);
      if (c && c.isPackageDrug) {
        setIsShadowBillingEnabled('Unit');
        setShowGenericSection(true);
      } else {
        setShowGenericSection(false);
      }
    }
  }, [setUnitPrice, activity.itemCode]);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  useEffect(() => {
    dispatch(
      getContracts({
        limit: 500,
        offset: 0,
        providerId,
        hci: payerId,
      }),
    );
  }, [providerId, payerId]);

  const getData = () => {
    dispatch(
      getContracts({
        limit: 500,
        offset: 0,
        providerId,
        hci: payerId,
      }),
    );
  };
  useEffect(() => {
    if (branches.length > 0) getData();
  }, [branches]);

  const onSelectActivity = (a) => {
    setSelectedContract(activity);
    setUnitPrice(activity?.unitPrice);
    setFactor(activity?.discount);
  };
  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen}>
      <KlaimForm
        style={{height: '100%', display: 'flex', flexDirection: 'column'}}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <KlaimModalHeader title="Add Activity" />
        <KlaimModalBody style={{padding: 20, display: 'grid', gap: '1rem', maxHeight: 700, overflowY: 'scroll'}}>
          <div>Please Fill in the fields below to add new activity.</div>
          <ItemForm
            isClaim={isClaim}
            onSelectActivity={(a) => {
              setSelectedContract(a);
              setUnitPrice(a?.unitPrice);
              setFactor(a?.discount);
            }}
            payerId={payerId}
            providerId={providerId}
            onBodySiteChange={(value) => setBodySiteSystem(value)}
            onBodySiteCodeChange={(value) => setBodySiteCode(value)}
          />
          <KlaimFieldWrapper name={'item.itemCareTeamSeq'}>
            <KlaimDropdownSearch
              label="Select Care Team"
              isMulti
              options={careTeam.map((team, index) => {
                return {
                  label: `${team.name} - ${team.careTeamRoleCode}`,
                  value: index + 1,
                };
              })}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'item.diagnosisSequence'}>
            <KlaimDropdownSearch
              label="Select Diagnosis"
              isMulti
              isOptional
              options={diagnosis.map((diag, index) => {
                return {
                  label: `${diag.diagCode} - ${diag.diagType}`,
                  value: index + 1,
                };
              })}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'item.informationSequence'}>
            <KlaimDropdownSearch
              label="Select Additional Info"
              isMulti
              isOptional
              options={supportingInfo.map((info, index) => {
                return {
                  label: `${info.category}${info.code ? ` - (${info.code})` : ''}${
                    info.timingDate ? ` - ${info.timingDate}` : ''
                  }`,
                  value: index + 1,
                };
              })}
            />
          </KlaimFieldWrapper>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 8}}>
            {/*<KlaimFieldWrapper name={'item.start'} onChange={(date) => setStartDate(date)} style={{flexGrow: 1}}>*/}
            <div style={{flexGrow: 1}}>
              <KlaimDatePicker
                label="Start Date"
                minDate={new Date()}
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            {/*</KlaimFieldWrapper>*/}
            {/*<KlaimFieldWrapper name={'item.end'} onChange={(date) => setEndDate(date)} style={{flexGrow: 1}}>*/}
            <div style={{flexGrow: 1}}>
              <KlaimDatePicker
                label="End Date"
                minDate={new Date()}
                onChange={(date) => setEndDate(date)}
                value={endDate}
              />
            </div>
            {/*</KlaimFieldWrapper>*/}
          </div>
        </KlaimModalBody>
        <KlaimModalFooter buttonType="submit" />
      </KlaimForm>
    </KlaimModal>
  );
};

export default ActivityModal;
