import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import PlusButtonIcon from 'assets/plus_icon_with_background.svg';
import ArrowLine from 'assets/dropdown_with_line.svg';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    cardSection: {
      '& h2': {
        color: `${Colors.blueGrayDark}`,
        margin: 0,
        marginBottom: '20px',
      },
      background: `${Colors.white}`,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 25px 20px 25px',
      marginBottom: '20px',
      '& p': {color: Colors.darkGrayishBlue},
    },
    cardRow: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    cardRow2: {
      display: 'flex',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    cardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      },
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: `${Colors.CardInfo}`,
      fontWeight: 600,
      margin: 10,
    },
    cardInfoSectionLast: {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 0,
    },
    claimStatus: {
      marginRight: '10px',
      width: 12,
      height: 12,
      background: `${Colors.lightGreen}`,
      borderRadius: '100%',
    },
    activityCard: {
      display: 'flex',
    },
    flex2: {
      flex: 2,
    },
    timelineCardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      },
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      fontSize: '17px',
      letterSpacing: '0.13px',
      color: `${Colors.CardInfo}`,
      fontWeight: 600,
      marginBottom: '28px',
    },
    submissionCard: {
      padding: '0 120px 0 120px',
    },
    timelineInfo: {
      '& $claimSubmission, & $claimRemittance': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 120px 0 120px',
    },
    claimSubmission: {
      flex: 1,
    },
    verticalBorder: {
      display: 'flex',
      justifyContent: 'center',
      borderLeft: `1px solid ${Colors.pinkLight}`,
      margin: '0 50px',
      position: 'relative',
    },
    claimRemittance: {
      flex: 1,
    },
    timelineCard: {
      border: `0.5px solid ${Colors.TimelineCard}`,
      padding: '22px',
      borderRadius: '10px',
    },
    remittanceMt: {
      marginTop: '120px',
    },
    submissionMt: {
      marginTop: '-120px',
    },
    submissionIcon: {
      position: 'absolute',
      top: '25px',
    },
    remittanceIcon: {
      position: 'absolute',
      top: '150px',
    },
    dFlex: {
      display: 'flex',
    },
    mt0: {
      marginTop: '0',
    },
    flex3: {
      flex: 3,
    },
    flex1: {
      flex: 1,
    },
    mb20: {
      marginBottom: '20px',
    },
    top10: {
      top: '10px !important',
    },
    ml20: {
      marginLeft: '20px',
    },
    flexUnset: {
      '& div': {
        flex: 'unset',
      },
    },
    flex05: {
      flex: '0.5',
    },
    statusColumn: {
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    statusDot: {
      marginRight: '5px',
      width: 12,
      height: 12,
      background: `${Colors.lightGreen}`,
      borderRadius: '100%',
    },
    drugContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cardRowEnd: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    cardInfoSectionEnd: {
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: `${Colors.CardInfo}`,
      fontWeight: 600,
    },
    buttonRemove: {
      '& span': {
        fontSize: '15px',
        fontWeight: 600,
        letterSpacing: '0.13px',
        marginLeft: 20,
        color: `${Colors.purple}`,
      },
      background: 'none',
      border: '0',
      cursor: 'pointer',
    },
    Image: {
      width: 20,
      height: 20,
      paddingRight: 10,
      color: Colors.purple,
    },
    dropdownClassName: {
      paddingRight: 0,
      width: 200,
    },
    selectContainer: {
      marginTop: '3%',
      width: '100%',
      padding: '2%',
      background: `${Colors.grayLight}`,
      border: 'none',
      borderRadius: 10,
      boxShadow: 'outline-none',
    },
    optionList: {
      background: `${Colors.grayLight}`,
      margin: '5%',
    },
    titleSearch: {
      paddingLeft: '2%',
    },
    searchWrapper: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
    },
    inputSpacing: {
      marginLeft: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    buttonIcon: {
      position: 'absolute',
      width: 42,
      height: 40,
      backgroundImage: `url(${PlusButtonIcon})`,
      backgroundRepeat: 'no-repeat',
      border: 0,
      backgroundColor: 'red',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      cursor: 'pointer',
    },
    inputWrapper: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inputContainer: {
      '& input': {
        paddingLeft: 45,
      },
      marginLeft: '20px',
      width: '400px',
    },
    navIcons: {
      alignItems: 'center',
      background: '#f5f7fb',
      borderRadius: '10px',
      cursor: 'pointer',
      display: 'flex',
      padding: '10px',
      marginRight: '20px',
    },
    mr0: {
      marginRight: '0 !important',
    },
    mr20: {
      marginLeft: '20px',
    },
    klaimBtn: {
      '&:hover': {
        cursor: 'pointer',
      },
      alignItems: 'center',
      background: '#6135fb',
      border: 'none',
      borderRadius: 10,
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },

    branchContainer: {
      '& span': {
        paddingLeft: 10,
        fontWeight: 500,
      },
      width: '100%',
    },
    branchSelectContainer: {
      '& select': {
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        appearance: 'none',
        padding: 10,
      },
      width: '100%',
    },
    selectBranches: {
      border: `1px solid ${Colors.DrugCard}`,
      borderRadius: 10,
      width: '80%',
      background: `url(${ArrowLine}) no-repeat right ${Colors.erxBranchDropdown}`,
      backgroundPositionX: '99%',
    },
    optionsValue: {
      fontWeight: 400,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 10,
    },
    drugsContainer: {
      fontSize: 10,
      alignItems: 'center',
      display: 'flex',
      gap: 10,
      '& h2': {
        fontSize: 24,
        marginBottom: 0,
      },
      alignContent: 'center',
    },
    totalContainer: {
      '& h1': {
        fontSize: 17,
      },
      '& span': {
        fontSize: 15,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '8px',
      },
      display: 'flex',
      gap: 10,
    },
    errorMessage: {
      color: Colors.lightRed,
    },
    cardSectionError: {
      '& h2': {
        color: `${Colors.blueGrayDark}`,
        margin: 0,
        marginBottom: '20px',
      },
      background: `${Colors.white}`,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 25px 20px 25px',
      marginBottom: '20px',
    },
  };
});
