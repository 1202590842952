import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {NormalizedList} from 'modules/App/types/normalizr';
import {AP} from '@klaim-ai/klaim-interfaces';
import {payerListSchema} from 'modules/App/schemas';

const payersList = createSlice({
  initialState: {
    allIds: [],
    byId: {} as NormalizedList<AP.Payer.FindAll.Response> | undefined,
  },
  name: 'payers',
  reducers: {
    getPayersList: (state, {payload}: PayloadAction<AP.Payer.FindAll.Response>) => {
      const normalizedPayerList = normalize(payload, payerListSchema);
      state.allIds = normalizedPayerList.result;
      state.byId = normalizedPayerList.entities.payer;
    },
  },
});

export const {getPayersList} = payersList.actions;

export default payersList;
