import React from 'react';
import * as Yup from 'yup';
import KlaimInput from 'modules/Common/components/KlaimInput';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Register/CreateAccount/CreateAccount';
import {useAppDispatch} from 'modules/App/store';
import {errorCleanUp} from 'modules/Auth/v3/features/Auth/authSlice';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {errorCleanUp as errorCleanUpReg, registerSelect} from 'modules/Auth/v3/features/Register/registerSlice';
import KlaimForm from 'modules/Common/components/KlaimForm';

interface ComponentProps {
  next?: any;
  prev?: any;
  data?: any;
}

const CreateAccount: React.FC<ComponentProps> = ({next, data, prev}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {error} = useSelector(registerSelect);

  React.useEffect(() => {
    return () => {
      dispatch(errorCleanUp());
      dispatch(errorCleanUpReg());
    };
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
    password: Yup.string()
      .min(8, '')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/,
        'Password must contain 8 characters above and at least one uppercase, one number and one special case character, with no space',
      )
      .required('Password is required'),
  });

  const handleSubmit = (values: any) => {
    next(values);
  };

  const goToTerms = () => {
    window.open('https://register.klaim.ai/terms-of-service/');
  };

  const goToPrivacy = () => {
    window.open('https://register.klaim.ai/privacy-policy/');
  };

  const backToLogin = () => {
    history.goBack();
  };

  return (
    <KlaimForm onSubmit={handleSubmit} initialValues={data} validationSchema={validationSchema}>
      <div className={classes.headerContainer}>
        <h1>Create Account</h1>
        <p>Please use your company email address and a strong password.</p>
      </div>
      {error && <div className={classes.error}>{error.stack.error.message}</div>}
      {error && error.stack.errors.length > 0 && (
        <ul className={classes.error}>
          {error.stack.errors && error.stack.errors?.map((message, index) => <li key={index}>{message}</li>)}
        </ul>
      )}
      <KlaimInput name="email" label="Email" placeholder="your@email.com" />
      <KlaimInput name="password" label="Password" type="password" showTooltip />
      <div className={classes.questionContainer}>
        <p>
          Already have an account? <KlaimSpan onClick={() => history.push('/auth')}>Sign in</KlaimSpan>
        </p>
        <p>
          By clicking Create account, I agree that I have read and accepted the{' '}
          <KlaimSpan onClick={() => goToTerms()}> Terms of Use </KlaimSpan>
          and <KlaimSpan onClick={() => goToPrivacy()}>Privacy Policy.</KlaimSpan>
        </p>
      </div>
      <div className={classes.footerContainer}>
        <Button title="Back" onClick={() => backToLogin()} />
        <Button title="Create account" type="submit" />
      </div>
    </KlaimForm>
  );
};

export default CreateAccount;
