import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {claimDraftSchema} from 'modules/App/schemas';
import {NormalizedList} from 'modules/App/types/normalizr';
import {ClaimDraftData, ClaimDraftList} from '../../../Claims/api/types';

const claimDraftList = createSlice({
  initialState: {
    allIds: [],
    byId: {} as NormalizedList<ClaimDraftList> | undefined,
  },
  name: 'claimDraft',
  reducers: {
    getClaimDraftList: (state, {payload}: PayloadAction<ClaimDraftData>) => {
      const normalizedClaimList = normalize(payload.data.data, claimDraftSchema);
      state.allIds = normalizedClaimList.result;
      state.byId = normalizedClaimList.entities.claimDraft;
    },
  },
});

export const {getClaimDraftList} = claimDraftList.actions;

export default claimDraftList;
