import React from 'react';
import {EL} from '@klaim-ai/klaim-interfaces';

import {Colors} from 'modules/Common/constants/Colors';
import CollapsibleTableRow from './CollapsibleTableRow';

interface ComponentProps {
  coverageDetails: EL.Model.CoverageDetails[];
}

const CollapsibleTable: React.FC<ComponentProps> = ({coverageDetails}) => {
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        borderWidth: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: Colors.grayLight,
        overflow: 'hidden',
      }}
    >
      <div style={{width: '100%'}}>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
              padding: 20,
              textAlign: 'left',
              borderBottom: `1px solid ${Colors.grayLight}`,
              fontWeight: 500,
              fontSize: 14,
              color: '#222B45',
            }}
          >
            <div>Coverage Benefits</div>
            <div>Plan Name</div>
            <div>Plan Description</div>
            <div>Plan Network</div>
            <div>Unit</div>
            <div>Term</div>
          </div>
        </div>
        <div>
          <div style={{maxHeight: 400, overflowY: 'scroll'}}>
            {coverageDetails &&
              coverageDetails.map((coverageDetail: EL.Model.CoverageDetails, index) => (
                <CollapsibleTableRow coverageDetail={coverageDetail} index={index} />
              ))}
          </div>
          <div style={{padding: 8, fontSize: 12}}>
            <p>Total amount of benefits: {coverageDetails.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleTable;
