import React from 'react';
import {EL} from '@klaim-ai/klaim-interfaces';
import {createUseStyles} from 'react-jss';
import AnimateHeight from 'react-animate-height';
import sectionCollapseArrow from 'assets/section_collapse_arrow.svg';
import sectionExpandedArrow from 'assets/section_expanded_arrow.svg';
import {Colors} from 'modules/Common/constants/Colors';

interface ComponentProps {
  coverageDetail: EL.Model.CoverageDetails;
  index: number;
}

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    collapsableArrowContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
    },
    collapsableArrow: {
      marginLeft: 10,
      marginRight: 10,
    },
    title: {
      margin: 0,
      fontSize: 16,
    },
  };
});

const benifitTypeToValue = (type: string) => {
  switch (type) {
    case 'benefit':
      return 'Benefit';
    case 'deductible':
      return 'Deductible';
    case 'visit':
      return 'Visit';
    case 'room':
      return 'Room';
    case 'copay':
      return 'Copayment Per Service';
    case 'copay-percent':
      return 'Copayment Percent PerService';
    case 'copay-maximum':
      return 'Copayment Maximum Per Service';
    case 'vision-exam':
      return 'Vision Exam';
    case 'vision-glasses':
      return 'Vision Glasses';
    case 'vision-contacts':
      return 'Vision Contacts Coverage';
    case 'medical-primarycare':
      return 'Medical Primary Health Coverage';
    case 'pharmacy-dispense':
      return 'Pharmacy Dispense Coverage';
    case 'approval-limit':
      return 'Approval Limit';
    default:
      return 'N/A';
  }
};

const benefitCategoryIdToValue = (id: string) => {
  switch (id) {
    case '1':
      return 'MedicalCare';
    case '2':
      return 'Surgical';
    case '3':
      return 'Consultation';
    case '4':
      return 'DiagnosticXRay';
    case '5':
      return 'DiagnosticLab';
    case '6':
      return 'RenalSupplies';
    case '7':
      return 'DiagnosticDental ';
    case '8':
      return 'Periodontics';
    case '9':
      return 'Restorative';
    case '10':
      return 'Endodontics';
    case '11':
      return 'MaxillofacialProsthetics';
    case '12':
      return 'AdjunctiveDentalServices';
    case '13':
      return 'HealthBenefitPlanCoverage';
    case '14':
      return 'DentalCare';
    case '15':
      return 'DentalCrowns';
    case '16':
      return 'DentalAccident';
    case '17':
      return 'HospitalRoomandBoard';
    case '18':
      return 'MajorMedical';
    case '19':
      return 'MedicallyRelatedTransportation';
    case '20':
      return 'InWitroFertilization';
    case '21':
      return 'MRIScan';
    case '22':
      return 'DonorProcedures';
    case '23':
      return 'Maternity';
    case '24':
      return 'RenalDialysis';
    case '25':
      return 'MedicalCoverage';
    case '26':
      return 'DentalCoverage';
    case '27':
      return 'HearingCoverage';
    case '28':
      return 'VisionCoverage';
    default:
      return 'N/A';
  }
};

const getBenefitAllowed = (benefitAllowed: EL.Model.BenefitAllowed) => {
  if (benefitAllowed.allowedMoney) {
    return `${benefitAllowed.allowedMoney.value} ${benefitAllowed.allowedMoney.currency}
`;
  }
  if (benefitAllowed.allowedUnsignedInt) {
    return `${benefitAllowed.allowedUnsignedInt}%`;
  }

  if (benefitAllowed.allowedString) {
    return `(${benefitAllowed.allowedString})`;
  }

  return '';
};

const getBenefitUsed = (benefitAllowed: EL.Model.BenefitUsed) => {
  if (benefitAllowed.usedMoney) {
    return `${benefitAllowed.usedMoney.value} ${benefitAllowed.usedMoney.currency}
`;
  }
  if (benefitAllowed.usedUnsignedInt) {
    return `${benefitAllowed.usedUnsignedInt}%`;
  }

  if (benefitAllowed.usedString) {
    return `(${benefitAllowed.usedString})`;
  }

  return '';
};

const CollapsibleTableRow: React.FC<ComponentProps> = ({coverageDetail, index}) => {
  const classes = useStylesFromThemeFunction();
  const [height, setHeight] = React.useState('0%');
  const handleCollapse = () => {
    setHeight(height === '0%' ? 'auto' : '0%');
  };
  return (
    <div key={index}>
      <div style={{borderBottom: `1px solid ${Colors.grayLight}`, padding: 8}}>
        <div className={classes.collapsableArrowContainer} onClick={handleCollapse} role="button">
          {height === '0%' ? (
            <img src={sectionCollapseArrow} alt="collapse-arrow" className={classes.collapsableArrow} />
          ) : (
            <img src={sectionExpandedArrow} alt="expanded-arrow" className={classes.collapsableArrow} />
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
              flex: '1 1 0%',
              fontSize: 14,
              color: '#2C273C',
            }}
          >
            <td>{benefitCategoryIdToValue(coverageDetail.coverageCategory.toString())}</td>
            <td>{coverageDetail.planName}</td>
            <td>{coverageDetail.planDescription}</td>
            <td>{coverageDetail.planNetwork}</td>
            <td>{coverageDetail.planUnit}</td>
            <td>{coverageDetail.planTerm}</td>
          </div>
        </div>
      </div>
      <AnimateHeight duration={500} height={height}>
        <div
          style={{
            padding: 20,
            fontSize: 14,
            backgroundColor: '#F7F5FC',
          }}
        >
          <div
            style={{
              flex: '1 1 0%',
              border: 1,
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                fontWeight: 500,
                fontSize: 10,
                color: '#696F81CC',
              }}
            >
              <div style={{}}>Benefit Type</div>
              <div style={{}}>Allowed</div>
              <div>Used</div>
            </div>
            {coverageDetail.planBenefits.map((planBenefit, planBenefitIndex: number) => {
              return (
                <div key={planBenefitIndex}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                      padding: 2,
                      fontSize: 14,
                      color: '#222B45',
                    }}
                  >
                    <div style={{}}>
                      <div style={{padding: 2}}>{`${benifitTypeToValue(planBenefit.benefitType)}`}</div>
                    </div>
                    <div style={{}}>
                      <div style={{padding: 2}}> {`${getBenefitAllowed(planBenefit.benefitAllowed)}`}</div>
                    </div>
                    <div style={{}}>
                      <div style={{padding: 2}}>{`${getBenefitUsed(planBenefit.benefitUsed)}`}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default CollapsibleTableRow;
