export const ReportTypeKey = {
  Claim: 'claim',
  Activity: 'prior-auth',
  RemitanceActivity: 'midtable',
};

export const ReportTypeLabel = {
  Claim: 'Claim',
  Activity: 'Prior-Auth',
  RemitanceActivity: 'Midtable',
};

export const ReportTypeOptions = [
  {label: ReportTypeLabel.Claim, value: ReportTypeKey.Claim},
  {label: ReportTypeLabel.Activity, value: ReportTypeKey.Activity},
  {label: ReportTypeLabel.RemitanceActivity, value: ReportTypeKey.RemitanceActivity},
];
