import React, {FC} from 'react';
import {createUseStyles} from 'react-jss';
import Select from 'react-select';
import {Colors} from 'modules/Common/constants/Colors';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    klaimSelectLabel: {
      color: Colors.purpleDark,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 3,
      marginLeft: 10,
    },
    inputError: {
      color: 'red',
      fontSize: 12,
      marginBottom: 0,
      marginTop: 0,
    },
    w100: {
      width: '100%',
    },
  };
});

interface ComponentProps {
  name?: string;
  className?: string;
  defaultOptions?: any;
  onChange?: (e: any) => void;
  value?: any | undefined;
  placeholder?: string;
  label?: string;
  error?: any;
}

const customStyles = {
  control: (base: any, state: {isFocused: any}) => ({
    ...base,
    background: Colors.blueGrayLight,
    borderRadius: 10,
    height: 40,
    '&:hover': {borderColor: `${Colors.grayLight}`},
    border: `1px solid ${Colors.grayLight}`,
    boxShadow: 'none',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    borderRadius: 10,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 10,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: Colors.blueGrayDark,
    '&:hover': {
      color: Colors.blueGrayDark,
    },
  }),
  option: (base: any, state: {isSelected: any}) => ({
    ...base,
    backgroundColor: state.isSelected && Colors.purple,
    '&:hover': {backgroundColor: !state.isSelected && Colors.blueGrayLight},
    ':active': {
      ...base[':active'],
      backgroundColor: Colors.blueGrayLight,
    },
  }),
};

const SearchSelect: FC<ComponentProps> = ({
  className,
  defaultOptions,
  name,
  onChange,
  value,
  placeholder,
  label,
  error,
}) => {
  const classes = useStylesFromThemeFunction();

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e && e.value ? e.value : '');
    }
  };

  return (
    <div className={classes.w100} key={`${name}-${value}`}>
      {label && <div className={classes.klaimSelectLabel}>{label}</div>}
      <Select
        classNamePrefix="searchSelect"
        styles={customStyles}
        isClearable
        isSearchable
        name={name}
        options={defaultOptions}
        className={className}
        onChange={handleOnChange}
        placeholder={placeholder}
        value={defaultOptions.find((o: any) => o.value === value)}
        defaultValue={defaultOptions.find((o: any) => o.value === value)}
      />
      <p className={classes.inputError}>{error}</p>
    </div>
  );
};
export default SearchSelect;
