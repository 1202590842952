import {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';

import {BranchApi} from 'api/branch/BranchApi';
import {Branch} from 'api/branch/branchTypes';
import {reportsLoadingSelector} from 'components/Reports/context/reportingSelectors';
import {useReportingState} from 'components/Reports/context/reportingContext';
import {ReportFilteringCard, ReportCreationFields} from './ReportFilteringCard';

const createReportSchema = Yup.object({
  branches: Yup.array().min(1, 'Required'),
  type: Yup.string().required('Required'),
});

interface ComponentProps {
  onFindReports: (values: ReportCreationFields) => void;
}

export const ReportFilteringCardContainer: FC<ComponentProps> = ({onFindReports}) => {
  const state = useReportingState();
  const loading = reportsLoadingSelector(state);
  const [allBranches, setAllBranches] = useState<Branch[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const response = await BranchApi.getUserBranches();
      setAllBranches(response);
    })();
  }, []);

  return (
    <>
      <ReportFilteringCard
        initialFields={{
          branches: [],
          type: undefined,
        }}
        allBranches={allBranches || []}
        validationSchema={createReportSchema}
        onFindReports={onFindReports}
        loading={loading}
      />
    </>
  );
};

export default ReportFilteringCardContainer;
