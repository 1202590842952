import React from 'react';
import ReactModal from 'react-modal';
import {useStylesFromThemeFunction, ComponentProps} from './KlaimModal';

const KlaimModal: React.FC<ComponentProps> = ({
  isOpen,
  onAfterOpen,
  onRequestClose,
  children,
  contentClass,
  contentStyle,
}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      overlayClassName={classes.klaimModalOverlay}
      className={contentClass}
      style={{
        content: {
          ...contentStyle,
          border: '0px',
          display: 'flex',
          borderRadius: 20,
          outline: 'none',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default KlaimModal;
