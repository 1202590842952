import React, {ReactNode, useState} from 'react';
import {ErrorMessage, Field, FieldProps} from 'formik';
import selectArrow from 'assets/select_arrow.svg';
import {useStylesFromThemeFunction} from './KlaimDropdown';

interface ComponentProps {
  name: string;
  label?: string;
  renderItems?: () => ReactNode;
  options: {value: any; label: string | undefined}[];
  expandOnHover?: boolean;
  dropdownClassName?: string;
  dropdownContentClassName?: string;
  errorClassName?: string;
  placeholder?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
}

interface Option {
  label: string | undefined;
  value: string;
}

const Dropdown: React.FC<ComponentProps> = ({
  renderItems,
  options,
  expandOnHover,
  dropdownClassName,
  dropdownContentClassName,
  name,
  errorClassName,
  label,
  placeholder,
  isOptional,
  isDisabled,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStylesFromThemeFunction();
  const [selected, setSelected] = useState<Option>();

  return (
    <Field
      name={name}
      render={({form: {values, setFieldValue, ...rest}, meta: {touched, error}}: FieldProps) => {
        if (values[name]) {
          setSelected(options.find((option: Option) => option.value === values[name]));
        }
        return (
          <div onMouseLeave={() => expandOnHover && setExpanded(false)} className={classes.contentContainer}>
            <div className={classes.labelContainer}>
              <p>{label}</p>
              {isOptional && <span>(Optional)</span>}
            </div>
            <div
              className={`${isDisabled ? classes.dropdownDisabled : classes.dropdown}`}
              onMouseEnter={() => (expandOnHover ? setExpanded(true) : null)}
            >
              <div
                role="button"
                className={`${expanded ? classes.activeDropBtn : classes.dropBtn} ${dropdownClassName}`}
                onClick={() => setExpanded(true)}
              >
                {selected?.label || placeholder || <i style={{fontWeight: 400}}>Please Select...</i>}
                <div className={`${expanded ? classes.activeDropArrow : classes.dropArrow}`} />
              </div>
              {expandOnHover ? (
                <div onMouseLeave={() => expandOnHover && setExpanded(false)} className={classes.dropdownContent}>
                  {renderItems ? (
                    renderItems()
                  ) : (
                    <ul className={classes.itemsContainer}>
                      <li
                        className={classes.dropdownItem}
                        onClick={() => {
                          setExpanded(false);
                          setFieldValue(name, '');
                          setSelected({
                            value: '',
                            label: '',
                          });
                        }}
                      >
                        -- Remove Selected --
                      </li>
                      {options?.map((option: Option, index) => (
                        <li
                          className={classes.dropdownItem}
                          onClick={() => {
                            setExpanded(false);
                            setFieldValue(name, option.value);
                            setSelected(option);
                          }}
                          key={`${index}-expanded`}
                        >
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                expanded && (
                  <div
                    className={
                      dropdownContentClassName !== undefined ? dropdownContentClassName : classes.dropdownContent
                    }
                    onMouseLeave={() => (expandOnHover ? setExpanded(false) : null)}
                  >
                    {renderItems ? (
                      renderItems()
                    ) : (
                      <ul className={classes.itemsContainer}>
                        <li
                          className={classes.dropdownItem}
                          onClick={() => {
                            setExpanded(false);
                            setFieldValue(name, '');
                            setSelected({
                              value: '',
                              label: '',
                            });
                          }}
                        >
                          -- Remove Selected --
                        </li>
                        {options?.map((option: Option, index) => (
                          <li
                            key={index}
                            className={classes.dropdownItem}
                            onClick={() => {
                              setFieldValue(name, option.value);
                              setExpanded(false);
                              setSelected(option);
                            }}
                          >
                            {option.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              )}
            </div>
            <ErrorMessage name={name}>
              {(errorMessage) => <div className={classes.error}>{errorMessage}</div>}
            </ErrorMessage>
          </div>
        );
      }}
    />
  );
};

export default Dropdown;
