import {useState} from 'react';
import {useReportingState} from 'components/Reports/context/reportingContext';
import {reportsLoadingSelector, reportsSelector} from 'components/Reports/context/reportingSelectors';
import {Report} from 'api/reports/reportTypes';
import {ReportsApi} from 'api';
import ReportListCard from './ReportListCard';

const ReportListCardContainer = () => {
  const state = useReportingState();
  const reports = reportsSelector(state);
  const loading = reportsLoadingSelector(state);
  const [downloading, setDownloading] = useState<number | null>(null);

  const onReportDownload = async (report: Report | any, index: number) => {
    setDownloading(index);
    const downloadObject = await ReportsApi.downloadReport(report._id, report.tenantId);
    window.open(`data:text/csv;base64,${downloadObject.base64Content}`, '_blank');
    setDownloading(null);
  };

  return (
    <ReportListCard reports={reports} loading={loading} downloading={downloading} onReportDownload={onReportDownload} />
  );
};

export default ReportListCardContainer;
