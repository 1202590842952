import {FC} from 'react';
import {Button} from 'custom-test-antd';
import Section from './Section';
import vs from '../../../../../assets/vital-signs.jpg';
import {useStylesFromThemeFunction} from './MidTableForm';

const Attachments: FC<any> = ({data}) => {
  const classes = useStylesFromThemeFunction();

  const downloadFile = (base64Data, fileName = 'file') => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/octet-stream'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.pdf`;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Section title={'Claim Attachments'} img={vs}>
      <>
        <div
          className={classes.respTableRow}
          style={{
            backgroundColor: '#F5F7FB',
            width: 'calc(100% + 32px)',
            marginLeft: '-16px',
            padding: '9px 16px',
          }}
        >
          <div className={classes.value} style={{flex: '1 1 40%'}}>
            Title
          </div>
          <div className={classes.value} style={{flex: '1 1 40%'}}>
            HIS ID
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            Action
          </div>
        </div>
        {data?.attachments.map((attachment) => (
          <div
            className={classes.respTableRow}
            style={{
              width: 'calc(100% + 32px)',
              marginLeft: '-16px',
              padding: '10px 16px',
              borderBottom: 'solid 1px #E7ECF5',
              marginTop: 0,
            }}
          >
            <div className={classes.label} style={{flex: '1 1 40%'}}>
              {attachment.title}
            </div>
            <div className={classes.label} style={{flex: '1 1 40%'}}>
              {attachment.hisId}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              <div className={classes.buttonsRow}>
                <Button type="primary" onClick={() => downloadFile(attachment.content, attachment.title)}>
                  Download
                </Button>
              </div>
            </div>
          </div>
        ))}
      </>
    </Section>
  );
};

export default Attachments;
