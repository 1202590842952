import React from 'react';
import * as Yup from 'yup';
import {Row, Col} from 'react-grid-system';
import {useSelector} from 'react-redux';
import {Field, FieldProps, FormikHelpers} from 'formik';
import {useHistory} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import SelectOptions from 'modules/Common/components/Select';
import {useAppDispatch} from 'modules/App/store';
import {
  practitionerManagementSelect,
  createPractitioner,
  getPractitionerRoles,
  getPractitionerSpecialities,
  getBranches,
  createCleanup,
} from 'modules/Settings/features/Practitioners/PractitionersSlice';
import KlaimFormMultiSelect from 'modules/Common/components/KlaimFormMultiSelect';
import {OptionValue} from 'modules/Header/headerSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import * as Toast from 'modules/Common/utils/toast';
import {KlaimDropdownSearch, KlaimFieldWrapper} from 'modules/Common/components-v2/KlaimForm';
import {practitionerSpeciality, practitionerTypes} from 'modules/Common/constants/DropdownOptions';
import {useStylesFromThemeFunction} from './PractitionersAdd';

export interface IValues {
  [field: string]: any;
}

const Component: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    roles,
    specialities,
    error,
    branches: {
      data: {branches},
    },
    createData,
    isCreated,
    isLoading,
  } = useSelector(practitionerManagementSelect);

  const [selectedRoles, setSelectedRoles] = React.useState<Array<any>>([]);
  const [selectedBranches, setSelectedBranches] = React.useState<Array<any>>([]);

  const initialValues = {
    providerId: createData?.providerId || '',
    active: createData?.active || '',
    name: createData?.name || '',
    gender: createData?.gender || '',
    license_number: createData?.license_number || '',
    roles: createData?.roles || [],
    speciality: createData?.speciality || '',
    type: createData?.type || '',
    branches: createData?.branches || [],
    in_hospital: createData?.in_hospital,
    phone: createData?.phone || '',
    address: createData?.address || '',
  };

  const validationSchema = Yup.object({
    active: Yup.boolean().required('Status is required'),
    name: Yup.string().required('Full Name is required'),
    gender: Yup.string().required('Gender is required'),
    license_number: Yup.string().required('License Number is required'),
    speciality: Yup.string().required('Speciality is required'),
    type: Yup.string().required('Type is required'),
    roles: Yup.array(
      Yup.object().shape({
        id: Yup.number().required(),
      }),
    ).required('Roles is required'),
    branches: Yup.array(
      Yup.object().shape({
        id: Yup.number().required(),
      }),
    ).required('Branch is required'),
  });

  const onSubmit: (
    values: IValues,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: IValues, formikHelpers) => {
    dispatch(createPractitioner({values, formikHelpers}));
  };

  React.useEffect(() => {
    dispatch(getPractitionerRoles({}));
    dispatch(getPractitionerSpecialities({}));
    dispatch(getBranches({}));
  }, []);

  React.useEffect(() => {
    if (isCreated === true) {
      Toast.success('Branch is added successfully', 'Success');
      history.push('/settings/practitioners');
    }
    return () => {
      dispatch(createCleanup());
    };
  }, [isCreated]);

  return (
    <KlaimForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      <Header
        title="Add Practitioner"
        backlink="/settings/practitioners"
        description={<p>Use the fields below to create a new practitioner details.</p>}
        buttonType="submit"
        buttonTitle="Create"
        body={
          <div className={classes.bodyWrapper}>
            {isLoading ? (
              <div>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {error && (
                  <div className={classes.errorMessage}>{error.stack ? error.stack.error.message : error.message}</div>
                )}
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={12} lg={8} xl={8}>
                        <KlaimInput name="license_number" label="License Number" placeholder="34567468" />
                      </Col>
                      <Col md={12} lg={4} xl={4}>
                        <Field name="active">
                          {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                            return (
                              <SelectOptions
                                options={[
                                  {label: 'Active', value: 'active'},
                                  {label: 'Inactive', value: 'inactive'},
                                ]}
                                selectClassName={classes.select}
                                divClassName={classes.selectDiv}
                                label={'Status'}
                                error={touched.active && errors.active ? errors.active : ''}
                                onChange={(val) => {
                                  if (val === 'active') setFieldValue('active', true);
                                  else setFieldValue('active', false);
                                }}
                                name={'active'}
                                placeholder={'Select'}
                                isClearable={false}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={8} xl={8}>
                        <KlaimInput name="name" label="Full Name" placeholder="Name Surname" />
                      </Col>
                      <Col md={12} lg={4} xl={4}>
                        <Field name="gender">
                          {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                            return (
                              <SelectOptions
                                options={[
                                  {label: 'Male', value: 'male'},
                                  {label: 'Female', value: 'female'},
                                ]}
                                selectClassName={classes.select}
                                divClassName={classes.selectDiv}
                                label={'Gender'}
                                error={touched.gender && errors.gender ? errors.gender : ''}
                                onChange={(val) => {
                                  setFieldValue('gender', val);
                                }}
                                name={'gender'}
                                placeholder={'Select'}
                                isClearable={false}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Field name="roles">
                          {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                            return (
                              <KlaimFormMultiSelect
                                value={selectedRoles}
                                onChange={(val) => {
                                  setSelectedRoles(val);
                                  const ids = val.map((item: OptionValue) => ({id: parseInt(item.value, 10)}));
                                  setFieldValue('roles', ids);
                                }}
                                options={roles?.data.map((item) => {
                                  const x: OptionValue = {label: item.name || '-', value: item.id.toString() || 'test'};
                                  return x;
                                })}
                                label={'Roles'}
                                error={touched.roles && errors.roles ? errors.roles : ''}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 10}}>
                      <Col md={12}>
                        <Field name="type">
                          {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                            return (
                              <KlaimFieldWrapper name="type">
                                <KlaimDropdownSearch options={practitionerTypes} label="Type" variant="secondary" />
                              </KlaimFieldWrapper>
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 10}}>
                      <Col md={12}>
                        <Field name="speciality">
                          {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                            return (
                              <KlaimFieldWrapper name="speciality">
                                <KlaimDropdownSearch
                                  options={practitionerSpeciality}
                                  label="Specialty"
                                  variant="secondary"
                                />
                              </KlaimFieldWrapper>
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 10, paddingBottom: 50}}>
                      <Col md={12}>
                        <Field name="branches">
                          {({form: {values, setFieldValue, errors, touched}}: FieldProps) => {
                            return (
                              <KlaimFormMultiSelect
                                value={selectedBranches}
                                onChange={(val) => {
                                  setSelectedBranches(val);
                                  const ids = val.map((item: OptionValue) => ({id: parseInt(item.value, 10)}));
                                  setFieldValue('branches', ids);
                                  setFieldValue('providerId', val?.[0]?.value);
                                }}
                                options={branches.map((item) => {
                                  return {label: item.name, value: item.id.toString()};
                                })}
                                label={'Branches'}
                                error={touched.branches && errors.branches ? errors.branches : ''}
                                position="right"
                              />
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default Component;
