import React, {FC} from 'react';
import dayjs from 'dayjs';
import {get} from 'lodash';
import {useDispatch} from 'react-redux';
import {useStylesFromThemeFunction} from '../../../../MidTable/features/MidTableForm/Components/MidTableForm';
import {Card, Divider} from './styles';
import {speciality} from './data';
import {claimStatues} from '../ClaimsList/data';
import UserLogo from '../../../../../assets/user_small.svg';

const ClaimActivities: FC<any> = ({items, careTeam, response}) => {
  const classes = useStylesFromThemeFunction();

  const DATE_FORMAT = `DD MMM 'YY`;
  const formatDate = (date) => dayjs(date).format(DATE_FORMAT);
  const getResponsePath = (index) => {
    return `[0].response.itemsDetails[${
      get(
        get(response, `[0].response.itemsDetails`, []).filter((el) => index === el.itemIndex - 1),
        '[0].itemIndex',
      ) - 1
    }]`;
  };

  return (
    <Card style={{paddingTop: '0', paddingBottom: 0}}>
      <div className={classes.responsiveRow}>
        <div className={classes.value} style={{flex: '1 1 2%'}}>
          #
        </div>
        <div className={classes.value} style={{flex: '1 1 10%'}}>
          INVOICE
        </div>
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          CODE
        </div>
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          CLINICIAN
        </div>
        {/* <div className={classes.value} style={{flex: '1 1 20%'}}> */}
        {/*  BODY SITE */}
        {/* </div> */}
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          STATUS
        </div>
        <div className={classes.value} style={{flex: '1 1 5%'}}>
          QTY
        </div>
        <div className={classes.value} style={{flex: '1 1 50%'}}>
          AMOUNTS IN SAR
        </div>
        {/* <div className={classes.label} style={{flex: '1 1 20%'}} /> */}
      </div>
      <Divider />
      {/* {data?.services?.map((el: any) => ( */}
      {items.map((el: any, index: number) => (
        <div
          className={classes.responsiveRow}
          style={{
            width: 'calc(100%)',
            marginLeft: '-16px',
            padding: '0px 16px',
            borderBottom: 'solid 1px #E7ECF5',
            marginTop: 0,
          }}
        >
          <div className={classes.label} style={{flex: '1 1 2%'}}>
            {index}
          </div>
          <div className={classes.label} style={{flex: '1 1 10%'}}>
            {el.invoiceNo?.number}
            <br />
            {formatDate(el?.servicedPeriod?.start)}
          </div>
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {el.itemCodeHcp}
            <br />
            {el.itemCode}
            <br />
            {el.itemCodeDisplay}
          </div>
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {get(careTeam, `[${get(el, 'itemCareTeamSeq[0]') - 1}].practitionerCode`)}
            <br />
            <b>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                <img src={UserLogo} alt="timeLogo" />
                {get(careTeam, `[${get(el, 'itemCareTeamSeq[0]') - 1}].doctorName`)}
              </div>
            </b>
            <br />
            {get(speciality, get(careTeam, `[${get(el, 'itemCareTeamSeq[0]') - 1}].specialty`))}
          </div>
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {get(response, `[0].response.itemsDetails[${index}].itemOutcome`) && (
              <span
                className={classes.approvedStatus}
                style={{
                  backgroundColor: claimStatues[get(response, `${getResponsePath(index)}.itemOutcome`)]?.bg,
                  color: claimStatues[get(response, `${getResponsePath(index)}.itemOutcome`)]?.text,
                  padding: '2px 6px',
                  borderRadius: 5,
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                {get(response, `${getResponsePath(index)}.itemOutcome`)}
                {get(
                  get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                    (sub) => sub.category === 'benefit',
                  ),
                  '[0].reasonIfNotApproved',
                ) && (
                  <div className={classes.popup}>
                    <h1>Denial Reason</h1>
                    <p>
                      {get(
                        get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                          (sub) => sub.category === 'benefit',
                        ),
                        '[0].reasonIfNotApproved.code',
                      )}
                    </p>
                    <p>
                      {get(
                        get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                          (sub) => sub.category === 'benefit',
                        ),
                        '[0].reasonIfNotApproved.message',
                      )}
                    </p>
                  </div>
                )}
              </span>
            )}
          </div>
          <div className={classes.label} style={{flex: '1 1 5%'}}>
            {el.itemQty}
          </div>
          <div className={classes.label} style={{flex: '1 1 50%'}}>
            <div className={classes.col}>
              <div className={classes.col}>
                <div className={classes.row} style={{justifyContent: 'space-between', gap: 16}}>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>GROSS AMOUNT</div>
                    <div className={classes.label}>
                      {get(
                        get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                          (sub) => sub.category === 'submitted',
                        ),
                        '[0].amount',
                        0,
                      ) -
                        get(
                          get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                            (sub) => sub.category === 'tax',
                          ),
                          '[0].amount',
                          0,
                        )}
                    </div>
                  </div>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>VAT AMOUNT</div>
                    <div className={classes.label}>
                      {get(
                        get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                          (sub) => sub.category === 'tax',
                        ),
                        '[0].amount',
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.row} style={{justifyContent: 'space-between', gap: 16}}>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>DISCOUNT AMOUNT</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                        (sub) => sub.category === 'discount',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>APPROVED QTY</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                        (sub) => sub.category === 'approved-quantity',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.row} style={{justifyContent: 'space-between', gap: 16}}>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>PATIENT SHARE</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                        (sub) => sub.category === 'patientShare' || sub.category === 'copay',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>ELIGIBLE AMOUNT</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                        (sub) => sub.category === 'eligible',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.row} style={{justifyContent: 'space-between', gap: 16}}>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>NET AMOUNT</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                        (sub) => sub.category === 'submitted',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>APPROVED AMOUNT</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                        (sub) => sub.category === 'benefit',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
              </div>
              <div style={{height: 10}} />
            </div>
          </div>
          {/* <div className={classes.label} style={{flex: '1 1 20%'}}> */}
          {/*  <div className={classes.buttonsRow}> */}
          {/*    <button */}
          {/*      className={classes.delete} */}
          {/*      onClick={() => { */}
          {/*        dispatch(deleteService({id: el.id})); */}
          {/*      }} */}
          {/*    > */}
          {/*      Delete */}
          {/*    </button> */}
          {/*  </div> */}
          {/* </div> */}
        </div>
      ))}
    </Card>
  );
};
export default ClaimActivities;
