import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FormikHelpers} from 'formik';
import {RootState} from 'modules/App/store';
import {logout} from 'modules/Auth/v3/features/Auth/authSlice';
import axios from 'services/axios';
import * as Toast from 'modules/Common/utils/toast';

interface IPermission {
  module: string;
  name: string;
  description: string;
  identifier: string;
}

export interface IRole {
  id: number;
  name: string;
  permissions: IPermission[];
}

interface MyKnownError {
  stack: {error: {message: string}; message?: ErrorMessageObject[] | string};
  statusCode: number;
  status: number;
  message: string;
}

interface ErrorMessageObject {
  property: string;
  constraints: {
    matches: string;
  };
}

interface BranchAttributes {
  values: any;
  formikHelpers?: FormikHelpers<{
    [field: string]: any;
  }>;
}

export interface IMembers {
  name: string;
  specialty: string;
  id: number;
}

export interface BranchData {
  id: number;
  name: string;
  identifier: string;
  authority: string;
  authority_login: string;
}
export interface KSABranchData {
  success?: boolean | null;
  error?: any;
  pkiSenderId?: string;
  pkiSenderName?: string;
}

interface BranchValidateData {
  authority: string;
  authority_login: string;
  authority_password: string;
}

interface InitialState {
  isLoading: boolean;
  isDeleted?: boolean;
  isCreated: boolean;
  isEdited: boolean;
  error?: MyKnownError;
  branch?: BranchData;
  branchName: string;
  roles?: IRole[];
  validated: boolean;
  credentials: {
    authority: string;
    authority_login: string;
    authority_password: string;
    name?: string;
    license?: string;
  };
  uploadResult: KSABranchData;
  branches: BranchesData;
  userBranches: BranchData[];
  providers: ProviderData;
}

const initialState: InitialState = {
  validated: false,
  isDeleted: false,
  isCreated: false,
  isEdited: false,
  isLoading: false,
  branchName: '',
  branch: undefined,
  error: undefined,
  branches: {data: {branches: [], total: 0}, limit: 10, skip: 0},
  userBranches: [],
  providers: {data: []},
  credentials: {
    authority: 'DHA',
    authority_login: '',
    authority_password: '',
  },
  uploadResult: {},
};
export interface IBranch {
  id: number;
  authority: string;
  identifier: string;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  users: [];
}
interface BranchesQuery {
  limit?: number;
  offset?: number;
  search?: string;
  sortBy?: string;
}
interface ProviderShape {
  providerCode: string;
  name: string;
  i18n: string;
  authorityCode: string;
  cchiId: string;
  licensingAuthority: string;
  invoice_system?: string;
  episode_system?: string;
}
interface ProviderData {
  data: ProviderShape[];
}

interface BranchesData {
  data: {
    branches: IBranch[];
    total: number;
  };
  limit: number;
  skip: number;
}

export const getBranches = createAsyncThunk<BranchesData, BranchesQuery, {rejectValue: MyKnownError}>(
  'branchManagement/getBranches',
  async (query: BranchesQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/branches', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

interface GetBranchAtrribute {
  id: string;
}

export const getBranch = createAsyncThunk<BranchData, GetBranchAtrribute, {rejectValue: MyKnownError}>(
  'branchManagement/getBranch',
  async (data: GetBranchAtrribute, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/branches/${data.id}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getUserCurrentBranches = createAsyncThunk<BranchData[], BranchesQuery, {rejectValue: MyKnownError}>(
  'branchManagement/getUserCurrentBranches',
  async (data: BranchesQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/users/current/branches');
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBranch = createAsyncThunk<number, number, {rejectValue: MyKnownError}>(
  'branchManagement/deleteBranch',
  async (id: number, {rejectWithValue}) => {
    try {
      const response = await axios.delete(`/branches/${id}`);
      return id;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createBranch = createAsyncThunk<BranchData, BranchAttributes, {rejectValue: MyKnownError}>(
  'branchManagement/createBranch',
  async ({values, formikHelpers}: BranchAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.post('/branches', values);
      return {
        id: response.data.id,
        name: response.data.name,
        identifier: response.data.identifier,
        authority: response.data.authority,
        authority_login: response.data.authority_login,
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const uploadPKI = createAsyncThunk<any, any, {rejectValue: MyKnownError}>(
  'branchManagement/uploadPKI',
  async ({values, formikHelpers}: BranchAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.post('/branches/uploadPki', values);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface BranchEditAttributes {
  id: string;
  name: string;
  identifier: string;
  authority: string;
  authority_login: string;
}

export const editBranch = createAsyncThunk<BranchData, BranchEditAttributes, {rejectValue: MyKnownError}>(
  'branchManagement/editBranch',
  async (data: BranchEditAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.patch(`/branches/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const branchValidateCredentials = createAsyncThunk<
  BranchValidateData,
  BranchAttributes,
  {rejectValue: MyKnownError}
>('branchManagement/branchValidateCredentials', async ({values}: BranchAttributes, {rejectWithValue}) => {
  try {
    const response = await axios.post('/branches/validate-credentials', values);
    return {
      authority: values.authority,
      authority_login: values.authority_login,
      authority_password: values.authority_password,
      license: response.data.license,
      name: response.data.name,
    };
  } catch (error: any) {
    Toast.error('Invalid username or password');
    return rejectWithValue(error.response.data);
  }
});

export const getProviders = createAsyncThunk<ProviderData, any, {rejectValue: MyKnownError}>(
  'branchManagement/getProviders',
  async (id: any, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/provider?authorityCode=NPHIES&providerCode=${id}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const branchManagementSlice = createSlice({
  name: 'branchManagement',
  initialState,
  reducers: {
    validateCleanup: (state) => {
      state.validated = false;
      state.uploadResult = {};
    },
    deleteCleanup: (state) => {
      state.isDeleted = false;
    },
    createCleanup: (state) => {
      state.isCreated = false;
    },
    editCleanup: (state) => {
      state.isEdited = false;
    },
    errorCleanup: (state) => {
      state.error = undefined;
    },
    branchesCleanup: (state) => {
      state.branches = {data: {branches: [], total: 0}, limit: 10, skip: 0};
    },
    credentialsCleanup: (state) => {
      state.credentials.authority = '';
      state.credentials.authority_login = '';
      state.credentials.authority_password = '';
      state.credentials.license = '';
      state.credentials.name = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranches.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.branches.data = action.payload.data;
        // state.branches.limit = action.payload.limit;
        state.branches.skip = action.payload.skip;
        state.isLoading = false;
        // Add user to the state array
      });
    builder
      .addCase(getBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranch.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.branch = action.payload;
        state.isLoading = false;
        // Add user to the state array
      });
    builder
      .addCase(createBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBranch.fulfilled, (state) => {
        state.isCreated = true;
        state.isLoading = false;
      })
      .addCase(createBranch.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(uploadPKI.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadPKI.fulfilled, (state, action) => {
        if (action.payload.error)
          state.error = {
            message: action.payload.error,
            status: 400,
            statusCode: 400,
            stack: {error: action.payload.error},
          };
        else state.uploadResult = action.payload;
        state.isCreated = true;
        state.isLoading = false;
      })
      .addCase(uploadPKI.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(editBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editBranch.fulfilled, (state) => {
        state.isEdited = true;
        state.isLoading = false;
      })
      .addCase(editBranch.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(branchValidateCredentials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(branchValidateCredentials.fulfilled, (state, action) => {
        state.validated = true;
        state.credentials = action.payload;
        state.isLoading = false;
      })
      .addCase(branchValidateCredentials.rejected, (state) => {
        state.isLoading = false;
        state.error = {
          stack: {
            error: {message: 'Bad Request'},
            message: [
              {
                property: '',
                constraints: {matches: ''},
              },
            ],
          },
          statusCode: 500,
          status: 500,
          message: 'Bad Request',
        };
      });
    builder
      .addCase(deleteBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBranch.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteBranch.fulfilled, (state, action) => {
        state.branches.data.branches = state.branches.data.branches.filter((branch) => branch.id !== action.payload);
        state.isDeleted = true;
        state.isLoading = false;
      });
    builder
      .addCase(getProviders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviders.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.isLoading = false;
      });
    builder.addCase(logout.fulfilled, (state) => {
      state.validated = false;
      state.isDeleted = false;
      state.isCreated = false;
      state.isEdited = false;
      state.isLoading = false;
      state.branchName = '';
      state.branch = undefined;
      state.error = undefined;
      state.branches = {data: {branches: [], total: 0}, limit: 10, skip: 0};
      state.credentials = {
        authority: 'DHA',
        authority_login: '',
        authority_password: '',
      };
      state.userBranches = [];
    });
    builder.addCase(getUserCurrentBranches.fulfilled, (state, action) => {
      state.userBranches = action.payload;
    });
  },
});

export const {
  validateCleanup,
  credentialsCleanup,
  deleteCleanup,
  createCleanup,
  editCleanup,
  errorCleanup,
  branchesCleanup,
} = branchManagementSlice.actions;
export const branchManagementSelect = (state: RootState) => state.settings.branchManagement;
export default branchManagementSlice.reducer;
