import React, {FC, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';

const ErxViewDispense: FC = () => {
  const {viewdispenseId}: {viewdispenseId: string} = useParams();
  useEffect(() => {
    console.log('viewdispenseId', viewdispenseId);
  }, []);
  return (
    <div>
      <div>ERX VIEW DISPENSE</div>
    </div>
  );
};

export default ErxViewDispense;
