import {createUseStyles} from 'react-jss';
import {Colors} from '../../../../../Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  questionContainer: {
    '& p': {
      fontSize: 14,
    },
    '& span': {
      fontSize: 14,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 14,
  },
  headerContainer: {
    '& h1': {
      fontSize: 24,
    },
    '& p': {
      fontSize: 12,
    },
  },
  passwordContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orgNameText: {
    color: Colors.purple,
  },
});
