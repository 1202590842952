import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {useStylesFromThemeFunction} from '../styles';
import {KlaimButton, KlaimForm} from '../../../../Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from '../../../../App/store';
import {headerSelect} from '../../../../Header/headerSlice';
import {getContracts, contractsManagementSelect, getContract, deleteContract, errorCleanUp} from '../ContractSlice';
import {providerId} from '../../../../MidTable/features/MidTableList/data';
import {Card, Header, Wrapper} from '../styled';
import KlaimPaginationContainer from '../../../../Common/components/KlaimPaginationContainer';
import {contractStatues} from '../constants';
import moreIcon from '../../../../../assets/more_icon_2.svg';
import KlaimDropdownContainer from '../../../../Common/components/KlaimDropdownContainer';
import {branchManagementSelect, getBranches} from '../../BranchManagement/branchManagementSlice';
import * as Toast from '../../../../Common/utils/toast';
import {getPatients} from '../../../../Patients/patientsSlice';
import BulkUploadModal from './BulkUploadModal';

const formatBranchDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};
const DATE_FORMAT = `DD MMM 'YY`;

const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

const Component: React.FC = () => {
  const s = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(1);
  const [uploadModal, setUploadModal] = useState(false);
  const [limit] = useState(10);

  const {contracts, isLoading, error, apiCallSuccess, count} = useAppSelector(contractsManagementSelect);
  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  const getData = () => {
    dispatch(
      getContracts({
        limit,
        offset: (offset - 1) * limit,
        providerId: getProviderIds(),
      }),
    );
  };
  useEffect(() => {
    if (branches.length > 0) getData();
  }, [limit, offset, branches]);

  useEffect(() => {
    if (apiCallSuccess) {
      Toast.success('Operation succeeded');
      getData();
      dispatch(errorCleanUp());
    }
  }, [apiCallSuccess]);

  useEffect(() => {
    if (error) {
      Toast.error('There was an error while treating your request');
      dispatch(errorCleanUp());
    }
  }, [error]);
  return (
    <Wrapper>
      <Card>
        <Header>
          <h1 className={s.header} style={{marginBottom: 0}}>
            Contract Management
          </h1>
          <input className={s.searchInput} placeholder={'Search Contract'} />
          <div>
            <KlaimButton variant="primary" onClick={() => history.push('/settings/contracts-management/add')}>
              Add Contract
            </KlaimButton>
            <KlaimButton variant="primary" onClick={() => setUploadModal(true)}>
              Add Bulk Contract
            </KlaimButton>
          </div>
        </Header>
      </Card>

      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={count}
        currentPage={offset}
        onPageChange={setOffset}
        label="Contracts"
        className={s.pagination}
      >
        <div className={s.tableContainer}>
          <div className={s.tableHeaderContainer}>
            <div className={s.tableHeader}>
              <div className={s.value} style={{width: 170}}>
                BRANCH NPHIES ID
              </div>
              <div className={s.value} style={{width: 140}}>
                HIC/TPA
              </div>
              <div className={s.value} style={{width: 160}}>
                HCP
              </div>
              <div className={s.value} style={{width: 250}}>
                NPHIES CODE
              </div>
              <div className={s.value} style={{width: 250}}>
                HCP DESCRIPTION
              </div>
              <div className={s.value} style={{width: 120}}>
                PCG DRUG
              </div>
              <div className={s.value} style={{width: 120}}>
                PCG SERVICE
              </div>
              <div className={s.value} style={{width: 200}}>
                UNIT PRICE
              </div>
              <div className={s.value} style={{width: 200}}>
                DISCOUNT
              </div>
              <div className={s.value} style={{width: 50}}>
                ACTION
              </div>
            </div>
          </div>
          <KlaimForm
            enableReinitialize
            initialValues={{}}
            validationSchema={Yup.object({})}
            onSubmit={() => null}
            id="list-claim-form"
          >
            <div className={s.tableBodyContainer}>
              {contracts.map((el) => {
                const item = el as any;
                return (
                  <div className={s.tableRow} role={'link'}>
                    <div className={s.label} style={{width: 170}}>
                      <span style={{position: 'relative'}} className={s.popupContainer}>
                        {item.tenantId}
                        <div className={s.popup}>
                          <b>ID</b>
                          <p>{item.tenantId}</p>
                        </div>
                      </span>
                    </div>
                    <div className={s.label} style={{width: 140}}>
                      <span style={{position: 'relative'}} className={s.popupContainer}>
                        {el.hci?.substring(0, 6)}...
                        <div className={s.popup}>
                          <b>ID</b>
                          <p>{item.hci}</p>
                        </div>
                      </span>
                    </div>
                    <div className={s.label} style={{width: 160}}>
                      {el.hcpCode}
                    </div>
                    <div className={s.label} style={{width: 250}}>
                      {el.nphiesCode}
                    </div>
                    <div className={s.label} style={{width: 250}}>
                      {el.hcpDescription}
                    </div>
                    <div className={s.label} style={{width: 120}}>
                      <span
                        onClick={() => {}}
                        role={'button'}
                        className={s.statuse}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: contractStatues[item.isPackageDrug?.toString()]?.bg,
                          color: contractStatues[item.isPackageDrug.toString()]?.text,
                        }}
                      >
                        {contractStatues[item.isPackageDrug?.toString()]?.label}
                      </span>
                    </div>
                    <div className={s.label} style={{width: 120}}>
                      <span
                        onClick={() => {}}
                        role={'button'}
                        className={s.statuse}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: contractStatues[item.isPackageService?.toString()]?.bg,
                          color: contractStatues[item.isPackageService?.toString()]?.text,
                        }}
                      >
                        {contractStatues[item.isPackageService?.toString()]?.label}
                      </span>
                    </div>
                    <div className={s.label} style={{width: 200}}>
                      {el.unitPrice} AED
                    </div>
                    <div className={s.label} style={{width: 200}}>
                      {el.discount} %
                    </div>
                    <div className={s.label}>
                      <KlaimDropdownContainer isIconButton icon={moreIcon} position={'bottomLeft'}>
                        <div
                          role="button"
                          className={`${s.button} ${s.navLink}`}
                          onClick={() => history.push(`/settings/contracts-management/edit/${item.id}`)}
                        >
                          Edit
                        </div>
                        <div
                          role="button"
                          className={`${s.button} ${s.navLink}`}
                          onClick={() => {
                            dispatch(
                              deleteContract({
                                id: el.id,
                                providerId: getProviderIds(),
                              }),
                            );
                          }}
                        >
                          Delete
                        </div>
                      </KlaimDropdownContainer>
                    </div>
                  </div>
                );
              })}
            </div>
          </KlaimForm>
        </div>
      </KlaimPaginationContainer>
      <BulkUploadModal
        open={uploadModal}
        setOpen={(v) => setUploadModal(v)}
        refreshList={() => {
          setTimeout(() => {
            getData();
          }, 1500);
        }}
      />
    </Wrapper>
  );
};

export default Component;
