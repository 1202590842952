import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    width: '800px',
    background: 'white',
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '10px',
    margin: 'auto',
    border: `1px solid ${Colors.grayLight}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '10px',
  },
  logoContainer: {
    '& img': {
      width: '192px',
      height: '115px',
    },
    marginTop: '30px',
    marginBottom: '30px',
  },
  vectorContainer: {
    '& img': {
      width: '450px',
      height: '187px',
    },
    marginTop: '30px',
    marginBottom: '30px',
  },
  buttonContainer: {
    marginTop: '30px',
    marginBottom: '50px',
  },
});
