import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface SettingsMenuNav {
  link: string;
  title: string;
  baseLink?: string;
  activeIcon?: string;
  icon?: string;
  permission?: string;
  countries?: string[];
  isHidden?: boolean;
}

export const useStylesFromThemeFunction = createUseStyles({
  userSettingsContainer: {
    background: Colors.white,
    boxShadow: `${Colors.RMSC.shadow} 1px 5px 20px`,
    borderRadius: 10,
    zIndex: 20,
    width: 318,
    padding: 20,
  },
  userDetailsContainer: {
    display: 'flex',
    borderBottom: `1px solid ${Colors.grayLight}`,

    paddingTop: 10,
    paddingBottom: 10,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  detailsContainer: {
    marginLeft: 5,
    '& h1': {
      fontSize: 18,
      fontWeight: 700,
    },
    '& span': {
      fontSize: 13,
      fontWeight: 600,
      marginTop: 5,
    },
    '& p': {
      fontSize: 13,
      backgroundColor: Colors.grayLight,
      paddingLeft: 8,
      paddingRight: 8,
      padding: 2,
      borderRadius: 99,
      marginTop: 5,
    },
  },
  settingsContainer: {
    '& h3': {
      margin: 0,
      fontSize: 14,
      color: Colors.grayLighter,
      fontWeight: 600,
    },
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `1px solid ${Colors.grayLight}`,
  },
  navLink: {
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    '& a': {
      textDecoration: 'none',
      color: Colors.black,
    },
    '&:hover': {
      background: Colors.blueGrayLight,
    },
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    background: Colors.white,
    color: Colors.purple,
    fontSize: 14,
    fontWeight: 550,
    border: `1px solid ${Colors.purple}`,
  },
  buttonContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${Colors.grayLight}`,
  },
  policyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerContainer: {
    fontSize: 13,
    '& p': {
      marginTop: 5,
      marginBottom: 5,
    },
    paddingTop: 20,
    paddingBottom: 20,
    color: Colors.grayLighter,
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  activeLink: {
    color: Colors.purple,
  },
  privacyTerms: {
    marginTop: 5,
    marginBottom: 5,
    color: Colors.grayLighter,
    fontSize: 13,
    textDecoration: 'none',
  },
});
