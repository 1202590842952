import React, {useContext, useState} from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {IOptions} from 'interfaces/general.interfaces';
import {Branch} from 'interfaces/branches.interfaces';
import {IPatient} from 'interfaces/patient.interface';
import {Payer} from 'interfaces/payers.interfaces';
import {
  IAccident,
  IActivityForm,
  ICareTeam,
  IClaim,
  IClaimRequest,
  ICoverage,
  IEncounter,
  IEpisode,
  IPatientRel,
  Member,
} from 'interfaces/pre-auth.interface';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'modules/App/store';
import {insuranceSelect} from 'modules/Insurance/insuranceSlice';
import {claimSelect, createClaim} from 'modules/Claims/claimsSlice';
import {ClaimHistory} from 'interfaces/claim.interface';
import {RouteComponentProps} from 'react-router-dom';
import {preAuthV2Select} from 'modules/PreAuthV2/PreAuthV2Slice';
import {eligibilitySelect} from 'modules/Eligibility/eligibilitySlice';
import validationSchema from './validationSchema';

interface IContextValues {
  initialValues: IClaimRequest;
  constants?: {
    submittedClaim: ClaimHistory;
    isCreateClaimSuccess: boolean;
    error: any;
    claimDetail: ClaimHistory;
    claimStep: number;
    isNextDisabled: boolean;
    isSubmitDisabled: boolean;
    insuranceOptions: IOptions[];
    isErrorOpen: boolean;
    isSubmitting: boolean;
    coverage: ICoverage;
    claim: IClaim;
    resubmissionType: string;
    selectedPatient: IPatient;
    isCoverageFulfilled: boolean;
    selectedBranch: Branch;
    selectedInsurance: Payer;
    selectedTpa: string;
    patientRel: IPatientRel;
    eligibility: any;
    priorAuth: any;
    member: Member;
    encounter: IEncounter;
    accident: IAccident;
    episode: IEpisode;
    errorMessages: string[];
    activities: IActivityForm[];
  };
  setter?: {
    setClaimStep: React.Dispatch<React.SetStateAction<number>>;
    setIsNextDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setInsuranceOptions: React.Dispatch<React.SetStateAction<IOptions[]>>;
    setErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    setCoverage: React.Dispatch<React.SetStateAction<ICoverage>>;
    setClaim: React.Dispatch<React.SetStateAction<IClaim>>;
    setResubmissionType: React.Dispatch<React.SetStateAction<string>>;
    setSelectedPatient: React.Dispatch<React.SetStateAction<IPatient>>;
    setIsCoverageFulfilled: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedBranch: React.Dispatch<React.SetStateAction<Branch>>;
    setSelectedInsurance: React.Dispatch<React.SetStateAction<Payer>>;
    setSelectedTpa: React.Dispatch<React.SetStateAction<string>>;
    setPatientRel: React.Dispatch<React.SetStateAction<IPatientRel | undefined>>;
    setEligibility: React.Dispatch<React.SetStateAction<any>>;
    setPriorAuth: React.Dispatch<React.SetStateAction<any>>;
    setMember: React.Dispatch<React.SetStateAction<Member>>;
    setEncounter: React.Dispatch<React.SetStateAction<IEncounter>>;
    setAccident: React.Dispatch<React.SetStateAction<IAccident>>;
    setEpisode: React.Dispatch<React.SetStateAction<IEpisode>>;
    setActivities: React.Dispatch<React.SetStateAction<IActivityForm[]>>;
  };
  methods?: {
    onNext: () => void;
    onBack: () => void;
    handleCoverageChange: (value: any) => void;
    getInsuranceName: () => void;
    getInsuranceCode: () => void;
    handleInsuranceChange: (value: any) => void;
    handleEligibilitySelect: (value: any) => void;
    handlePriorAuthSelect: (value: any) => void;
    getPayerName: (tpa: string) => string;
    onClaimSubmit: () => void;
  };
}

const ClaimFormKSATemplate = React.createContext<IContextValues>({
  initialValues: {
    senderId: '',
    providerId: '',
    senderName: '',
    payerName: '',
    receiverId: '',
    payerId: '',
    type: 'claim-request',
    patientId: '',
    coverage: {
      subscriberId: '',
      coverageRel: 'self',
      // schemeName: '',
      // schemeClass: '',
      policyHolder: 'patient',
      organizationPolicyHolder: {
        identifier: '',
        name: '',
      },
      costToBeneficiary: [],
    },
    claim: {
      claimType: 'institutional',
      claimSubtype: 'ip',
      claimPriority: 'stat',
      supportingInfo: [],
      diagnosis: [],
      careTeam: [],
      episode: {
        value: '',
        system: '',
      },
    },
    accident: {
      date: '',
      type: '',
      locationAddress: '',
    },
  },
});

export const useClaimFormKSAContext = () => {
  return useContext(ClaimFormKSATemplate);
};

const ClaimFormKSAContext: React.FC<RouteComponentProps<{id: string}>> = ({
  children,
  match: {
    params: {id},
  },
}) => {
  const dispatch = useDispatch();
  const {payers} = useAppSelector(insuranceSelect);
  const {history: preAuthDetail} = useAppSelector(preAuthV2Select);
  const {claim: submittedClaim, isCreateClaimSuccess, error, history: claimDetail} = useAppSelector(claimSelect);
  const {editEligibility} = useAppSelector(eligibilitySelect);
  const [claimStep, setClaimStep] = React.useState<number>(0);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(false);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);
  const [isErrorOpen, setErrorOpen] = React.useState<boolean>(false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const initialValues: IClaimRequest = {
    senderId: '',
    providerId: '',
    senderName: '',
    payerName: '',
    receiverId: '',
    payerId: '',
    type: 'claim-request',
    patientId: '',
    coverage: {
      subscriberId: '',
      coverageRel: 'self',
      // schemeName: '',
      // schemeClass: '',
      policyHolder: 'patient',
      organizationPolicyHolder: {
        identifier: '',
        name: '',
      },
      costToBeneficiary: [],
    },
    claim: {
      episode: {
        value: '',
        system: '',
      },
      claimType: 'institutional',
      claimSubtype: 'ip',
      claimPriority: 'stat',
      supportingInfo: [],
      diagnosis: [],
      careTeam: [],
    },
    accident: {
      date: '',
      type: '',
      locationAddress: '',
    },
  };

  React.useEffect(() => {
    if (claimDetail && claimDetail.submissions.length > 0) {
      const claimSubmissions = claimDetail.submissions.filter((submission) => submission.type === 'claim-request');

      const latestSubmission = claimSubmissions[claimSubmissions.length - 1];

      if (latestSubmission) {
        const initialMember = latestSubmission.coverage?.member && {
          id: latestSubmission.coverage?.member?.id,
          system: latestSubmission.coverage?.member?.system,
        };

        const initialEpisode = latestSubmission.claim?.episode && {
          value: latestSubmission.claim?.episode?.value,
          system: latestSubmission.claim?.episode?.system,
        };

        const initialActivity: IActivityForm[] = latestSubmission.claim?.item?.map((item) => {
          return {
            item,
          };
        });

        const initialAccident = latestSubmission.claim?.accident && {
          date: latestSubmission.claim?.accident?.date,
          locationAddress: latestSubmission.claim?.accident?.locationAddress,
          type: latestSubmission.claim?.accident?.type,
        };

        if (initialMember) {
          setMember(initialMember || initialValues.member);
        }
        if (initialEpisode) {
          setEpisode(initialEpisode || initialValues.claim.episode);
        }
        if (initialActivity) {
          setActivities(initialActivity || []);
        }
        if (initialAccident) {
          setAccident(initialAccident || initialValues.accident);
        }
      }
    }
  }, [claimDetail]);

  const [coverage, setCoverage] = React.useState<ICoverage>(initialValues.coverage);
  const [claim, setClaim] = React.useState<IClaim>(initialValues.claim);
  const [activities, setActivities] = React.useState<IActivityForm[]>([]);
  const [resubmissionType, setResubmissionType] = React.useState('prior');
  const [selectedPatient, setSelectedPatient] = React.useState<IPatient>();
  const [isCoverageFulfilled, setIsCoverageFulfilled] = React.useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = React.useState<Branch>();
  const [selectedInsurance, setSelectedInsurance] = React.useState<Payer>();
  const [selectedTpa, setSelectedTpa] = React.useState<string>('');
  const [patientRel, setPatientRel] = React.useState<IPatientRel | undefined>(initialValues.patientRel);
  const [eligibility, setEligibility] = React.useState<any>(false);
  const [priorAuth, setPriorAuth] = React.useState<any>(false);
  const [member, setMember] = React.useState<Member>({
    id: '',
    system: '',
    // subscriberId: '',
  });
  const [encounter, setEncounter] = React.useState<IEncounter>({
    encounterClass: '',
    encounterStatus: '',
    encounterServiceType: '',
    encounterPriority: '',
    encounterPeriodStart: '',
    encounterPeriodEnd: '',
    hospAdmitSource: '',
    hospReAdmission: false,
    hospdischargeDisposition: '',
  });
  const [accident, setAccident] = React.useState<IAccident>(initialValues.accident);
  const [episode, setEpisode] = React.useState<IEpisode>(initialValues.claim.episode);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

  const onNext = () => {
    setClaimStep(claimStep + 1);
  };

  const onBack = () => {
    setClaimStep(claimStep - 1);
  };

  const handleCoverageChange = (value: any) => {
    setCoverage(value);
  };

  const getInsuranceName = () => {
    return selectedInsurance ? selectedInsurance?.name : '';
  };

  const getInsuranceCode = () => {
    return selectedInsurance ? selectedInsurance?.payerCode : '';
  };

  const handleInsuranceChange = (value: any) => {
    setSelectedInsurance(payers.data.find((payer) => payer.payerCode === value));
  };

  const handleEligibilitySelect = (value: any) => {
    if (value) {
      setMember({
        id: value.member.id,
        system: value.member.system,
        // subscriberId: value.subscriberId,
      });
      setCoverage({...coverage, subscriberId: value.subscriberId});
      const setInsurance = payers.data.find((payer) => payer.payerCode === value?.payerId);
      setSelectedInsurance(setInsurance);
      setEligibility(value);
    } else {
      setEligibility(undefined);
      setMember({
        id: '',
        system: '',
      });
      setCoverage({...coverage, subscriberId: ''});
    }
  };

  const handlePriorAuthSelect = (value: any) => {
    if (value) {
      setPriorAuth(value);
    } else {
      setPriorAuth(undefined);
    }
  };

  const getPayerName = (tpa: string) => {
    if (payers) {
      const payerResult = payers.data.find((payer: Payer) => payer.payerCode === tpa);
      if (payerResult) {
        return payerResult.name;
      }
    }
    return '';
  };

  const dateFormatter = (date: string) => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  const onClaimSubmit = async () => {
    const tempClaim = {
      ...claim,
      careTeam: claim.careTeam
        ? claim.careTeam.map((careTeam: ICareTeam) => {
            return {
              code: careTeam.code,
              practitionerType: careTeam.practitionerType,
              practitionerCode: careTeam.practitionerCode,
              specialty: careTeam.specialty,
              careTeamRoleCode: careTeam.careTeamRoleCode,
              onSite: careTeam.onSite,
              name: careTeam.name,
            };
          })
        : [],
      item: claim.item.map((it) => {
        const item = {
          ...it,
          bodySite:
            it.bodySite && it.bodySite.code !== undefined && it.bodySite.system !== undefined
              ? {
                  code: it.bodySite.code,
                  system: Number(it.bodySite.system),
                }
              : undefined,
          subSite:
            it.subSite && it.subSite.code !== undefined && it.subSite.system !== undefined
              ? {
                  code: it.subSite.code,
                  system: Number(it.subSite.system),
                }
              : undefined,
        };
        if (item.bodySite === undefined) {
          delete item.bodySite;
        }
        if (item.subSite === undefined) {
          delete item.subSite;
        }
        return item;
      }),
      claimBillablePeriodEnd: dateFormatter(claim.claimBillablePeriodEnd),
      claimBillablePeriodStart: dateFormatter(claim.claimBillablePeriodStart),
    };

    const getPatientId = () => {
      if (preAuthDetail?.submissions[0]?.patientId) return preAuthDetail?.submissions[0]?.patientId;
      if (preAuthDetail?.patientId) return preAuthDetail?.patientId;
      if (claimDetail?.patientId) return claimDetail?.patientId;

      return undefined;
    };

    const payload: IClaimRequest = {
      senderId: selectedBranch ? selectedBranch.identifier : '',
      providerId: selectedBranch ? selectedBranch.identifier : '',
      senderName: selectedBranch ? selectedBranch.name : '',
      payerName: _.isEmpty(selectedTpa) ? getInsuranceName() : getPayerName(selectedTpa),
      receiverId: _.isEmpty(selectedTpa) ? getInsuranceCode() : selectedTpa,
      payerId: _.isEmpty(selectedTpa) ? getInsuranceCode() : selectedTpa,
      type: 'claim-request',
      patientId: getPatientId() || selectedPatient?.id || '',
      coverage,
      member,
      claim: Object.keys(tempClaim)
        .filter((k) => !_.isEmpty(tempClaim[k as keyof IClaim]))
        .reduce((a, k) => ({...a, [k]: tempClaim[k as keyof IClaim]}), {}),
      patientRel,
    };
    if (accident.type) {
      payload.accident = {
        ...accident,
        date: dateFormatter(accident.date),
      };
    }
    if (encounter.encounterClass) {
      payload.encounter = {
        ...encounter,
        encounterPeriodEnd: dateFormatter(encounter.encounterPeriodEnd),
        encounterPeriodStart: dateFormatter(encounter.encounterPeriodStart),
      };
    }
    if (patientRel && patientRel.isValid) {
      payload.patientRel = patientRel;
    }
    if (id) {
      payload.relatedToPriorClaimRel = resubmissionType;
    }
    if (eligibility || editEligibility) {
      payload.eligibilityId = eligibility.id || editEligibility.id;
    }
    if (priorAuth || preAuthDetail) {
      payload.priorAuthId = priorAuth.id || preAuthDetail.id;
    }
    if (episode) {
      payload.claim.episode = episode;
    }

    const {errors} = await validationSchema.validate(payload, {abortEarly: false}).catch((err) => {
      return err;
    });

    if (errors && errors.length > 0) {
      setErrorMessages(errors);
    }

    dispatch(createClaim(payload));
  };

  React.useEffect(() => {
    setErrorMessages(errorMessages.concat(error));
  }, [error]);

  return (
    <ClaimFormKSATemplate.Provider
      value={{
        initialValues,
        constants: {
          claimStep,
          isNextDisabled,
          isSubmitDisabled,
          insuranceOptions,
          isErrorOpen,
          isSubmitting,
          coverage,
          claim,
          resubmissionType,
          selectedPatient,
          isCoverageFulfilled,
          selectedBranch,
          selectedInsurance,
          selectedTpa,
          patientRel,
          eligibility,
          priorAuth,
          member,
          encounter,
          accident,
          episode,
          submittedClaim,
          isCreateClaimSuccess,
          error,
          claimDetail,
          errorMessages,
          activities,
        },
        setter: {
          setClaimStep,
          setIsNextDisabled,
          setIsSubmitDisabled,
          setInsuranceOptions,
          setErrorOpen,
          setSubmitting,
          setCoverage,
          setClaim,
          setResubmissionType,
          setSelectedPatient,
          setIsCoverageFulfilled,
          setSelectedBranch,
          setSelectedInsurance,
          setSelectedTpa,
          setPatientRel,
          setEligibility,
          setPriorAuth,
          setMember,
          setEncounter,
          setAccident,
          setEpisode,
          setActivities,
        },
        methods: {
          onNext,
          onBack,
          handleCoverageChange,
          getInsuranceName,
          getInsuranceCode,
          getPayerName,
          handleInsuranceChange,
          handleEligibilitySelect,
          handlePriorAuthSelect,
          onClaimSubmit,
        },
      }}
    >
      {children}
    </ClaimFormKSATemplate.Provider>
  );
};

export default ClaimFormKSAContext;
