import React from 'react';
import {useHistory} from 'react-router';
import expiredVector from 'assets/expired_vector.svg';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/components/ExpiredLink/ExpiredLink';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';

const ExpiredLink: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  return (
    <div className={classes.expiredLink}>
      <div className={classes.subExpiredLink}>
        <div className={classes.headerContainer}>
          <h1>Oops!</h1>
          <p>Sorry this link seems to have expired or you don’t have access to view it contents.</p>
        </div>
        <div className={classes.imageContainer}>
          <img src={expiredVector} alt="expired-link" />
        </div>
        <p className={classes.message}>
          You can go back to the homepage using the button below or <KlaimSpan>Contact Support</KlaimSpan>
        </p>
        <div className={classes.footerContainer}>
          <Button title="Go Home" onClick={() => history.push('/auth')} />
        </div>
      </div>
    </div>
  );
};

export default ExpiredLink;
