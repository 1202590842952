export const speciality = {
  '01.00': 'Anesthesiology Specialty',
  '01.01': 'Ambulatory Anesthesia',
  '01.02': 'Anesthesia Cardiology',
  '01.03': 'Neuro-Anesthesia',
  '01.04': 'Obstetrics Anesthesia',
  '01.05': 'Pediatrics Anesthesia',
  '01.06': 'Pediatrics Cardiac Anesthesia',
  '01.07': 'Regional Anesthesia',
  '01.08': 'Vascular / Thoracic Anesthesia',
  '02.00': 'Community Medicine Specialty',
  '02.01': 'Community Health',
  '03.00': 'Dermatology Specialty',
  '03.01': 'Dermatology Surgery',
  '03.02': 'Hair Implant Dermatology',
  '03.03': 'Pediatrics Dermatology',
  '04.00': 'Emergency Medicine Specialty',
  '04.01': 'Adult Emergency Medicine',
  '04.02': 'Pediatrics Emergency Medicine',
  '05.00': 'Ear, Nose & Throat Specialty',
  '05.01': 'Adult ENT',
  '05.02': 'Laryngology',
  '05.03': 'Neuro - Otology & Otology',
  '05.04': 'Nose, Ear Surgery',
  '05.05': 'Oral & Maxillofacial Surgery',
  '05.06': 'Otolaryngology',
  '05.07': 'Pediatrics ENT',
  '05.08': 'Pediatrics Otolaryngology',
  '05.09': 'Rhinology',
  '05.10': 'Audiology',
  '06.00': 'Family Medicine Specialty',
  '06.01': 'Family Medicine',
  '06.02': 'Primary Care / Ophthalmology',
  '06.03': 'Primary Care / Pulmonary',
  '06.04': 'Primary Care Preventive Pediatrics',
  '06.05': 'Primary Health Care',
  '07.00': 'Forensic Medicine Specialty',
  '08.00': 'Internal Medicine Specialty',
  '08.01': 'Adolescent Medicine',
  '08.02': 'Cardiology',
  '08.03': 'Diabetics Medicine',
  '08.04': 'Endocrinology',
  '08.05': 'Gastrology/Gastroenterology',
  '08.06': 'Geriatrics',
  '08.07': 'Hematology',
  '08.08': 'Infectious Diseases',
  '08.09': 'Nephrology',
  '08.10': 'Nuclear Medicine',
  '08.11': 'Oncology',
  '08.12': 'Palliative Medicine',
  '08.13': 'Pulmonology/Chest Medicine',
  '08.14': 'Rheumatology',
  '08.15': 'Sleep Medicine',
  '08.16': 'Sport Medicine',
  '08.17': 'Hepatology',
  '08.18': 'Neurology',
  '08.19': 'Radiation Oncology',
  '08.20': 'Diabetes Foot Care',
  '08.21': 'Head & Neck Oncology',
  '08.22': 'Hematology - Stem Cell',
  '08.23': 'Congenital Heart Disease',
  '08.24': 'Bariatric Medicine',
  '08.25': 'Cardiothoracic',
  '08.26': 'General Medicine',
  '09.00': 'Microbiology Specialty',
  '10.00': 'Obstetrics & Gynecology Specialty',
  '10.01': 'Gynecology Oncology',
  '10.02': 'Infertility',
  '10.03': 'IVF',
  '10.04': 'Perinatology',
  '10.05': 'Urogynecology',
  '10.06': 'Obstetrics',
  '10.07': 'Reproductive Endocrinology & Infertility',
  '10.08': 'Gynecology',
  '10.09': 'Maternal Fetal Medicine',
  '11.00': 'Ophthalmology Specialty',
  '11.01': 'Comprehensive Ophthalmology',
  '11.02': 'Diseases & Surgery of the Retina',
  '11.03': 'Glaucoma',
  '11.04': 'Neuro - Ophthalmology',
  '11.05': 'Ocular Oncology',
  '11.06': 'Oculoplastic',
  '11.07': 'Ophthalmology',
  '11.08': 'Pediatrics Ophthalmology & Strabismus',
  '11.09': 'Primary Care / Ophthalmology',
  '11.10': 'Uveitis / Medical Retina',
  '11.11': 'Optometric',
  '11.12': 'Anterior Segment',
  '11.13': 'Anaplastology',
  '11.14': 'Macular Dystrophy',
  '11.15': 'Abyplopia',
  '11.16': 'Ophthalmic Photography',
  '12.00': 'Orthopedic Specialty',
  '12.01': 'Oncology Orthopedic',
  '12.02': 'Orthopedic Surgery',
  '12.03': 'Pediatrics Orthopedic',
  '12.04': 'Upper Limp Orthopedic',
  '13.00': 'Pathology Specialty',
  '13.01': 'Bone & Soft Tissue Pathology',
  '13.02': 'Dermatopathology',
  '13.03': 'Gast. & Hepat Pathology',
  '13.04': 'Histopathology',
  '13.05': 'Lymphoma Pathology',
  '13.06': 'Pathology Dermatology',
  '13.07': 'Renal Pathology',
  '14.00': 'Pediatric Specialty',
  '14.01': 'Fetal Medicine',
  '14.02': 'Neonatal Intensive Care (NICU)',
  '14.03': 'Pediatrics Imaging',
  '14.04': 'Pediatrics Endocrinology',
  '14.05': 'Pediatrics Gastroenterology',
  '14.06': 'Pediatrics Genetics',
  '14.07': 'Pediatrics Rheumatology',
  '14.08': 'Pediatrics Sleep Medicine',
  '14.09': 'Pediatrics Orthopedic',
  '14.10': 'Pediatrics Hematology',
  '14.11': 'Pediatrics Infectious Diseases',
  '14.12': 'Pediatrics Intensive Care',
  '14.13': 'Pediatrics Nephrology',
  '14.14': 'Pediatrics Pulmonary Diseases',
  '14.15': 'Primary Care Preventive Pediatrics',
  '14.16': 'Pediatric Neurology',
  '14.17': 'Fetal Cardiology',
  '14.18': 'Neonatology',
  '14.19': 'Pediatric Allergy',
  '14.20': 'Pediatric Cardiology',
  '15.00': 'Pediatrics Surgery Specialty',
  '15.01': 'Pediatrics Cardiology',
  '15.02': 'Pediatrics Neurosurgery',
  '15.03': 'Pediatrics Oncology',
  '15.04': 'Pediatrics Plastic Surgery',
  '15.05': 'Pediatrics General Surgery',
  '15.06': 'Pediatrics Hematology/Oncology',
  '16.00': 'Physical Medicine & Rehabilitation Specialty',
  '16.01': 'Physical Medicine & Rehabilitation',
  '16.02': 'Occupational Medicine',
  '17.00': 'Psychiatry Specialty',
  '17.01': 'Addiction Medicine',
  '17.02': 'Child / Adolescent Psychiatry',
  '17.03': 'Consultation - Liaison Psychiatry',
  '17.04': 'Forensic Psychiatry',
  '17.05': 'Geriatric Psychiatry',
  '17.06': 'Mental Health',
  '17.07': 'Mood Disorders Psychiatry',
  '17.08': 'Psychiatry',
  '17.09': 'Rehabilitation Psychiatry',
  '17.10': 'Schizophrenia',
  '17.11': 'Pediatric Behavior',
  '17.12': 'Youth Stress Reduction',
  '18.00': 'Radiology Specialty',
  '18.01': 'Body Imaging',
  '18.02': 'Breast Imaging',
  '18.03': 'Cardiac Imaging',
  '18.04': 'Chest Imaging',
  '18.05': 'Diagnostic Neuroradiology',
  '18.06': 'Diagnostic Radiology',
  '18.07': 'Emergency Radiology',
  '18.08': 'Interventional Neuroradiology',
  '18.09': 'Interventional Radiology',
  '18.10': 'Musculoskeletal Imaging',
  '18.11': 'Pediatrics Imaging',
  '18.12': 'Women"s Imaging',
  '19.00': 'Surgery Specialty',
  '19.01': 'Arthroplasty Surgery',
  '19.02': 'Bariatric Surgery',
  '19.03': 'Cosmetic Surgery',
  '19.04': 'Craniofacial Surgery',
  '19.05': 'Endocrinology Surgery',
  '19.06': 'Facioplastic',
  '19.07': 'Foot & Ankle Surgery',
  '19.08': 'General Surgery',
  '19.09': 'Hand Surgery',
  '19.10': 'Hepatobiliary & Upper GI Surgery',
  '19.11': 'Neurosurgery (Spinal Surgery)',
  '19.12': 'Neurosurgery / Oncology',
  '19.13': 'Neurosurgery Vascular',
  '19.14': 'Plastic Surgery & Reconstruction',
  '19.15': 'Skull Base Surgery',
  '19.16': 'Spine Surgery',
  '19.17': 'Thoracic Surgery/Chest Surgery',
  '19.18': 'Trauma Surgery',
  '19.19': 'Vascular Surgery',
  '19.20': 'Colorectal Surgery',
  '19.21': 'Transplant Surgery',
  '19.22': 'Liver Transplant Surgery',
  '19.23': 'Renal and Pancreas Transplant Surgery',
  '19.24': 'Breast Surgery',
  '19.25': 'Cardiothoracic Surgery',
  '19.26': 'Burns',
  '20.00': 'Urology Specialty',
  '20.01': 'Gynecology Urology',
  '20.02': 'Laparoscopic Urology',
  '20.03': 'Neuro - Urology',
  '20.04': 'Oncology Urology',
  '20.05': 'Pediatrics Urology',
  '20.06': 'Reconstruction Urology',
  '21.00': 'Critical Care',
  '21.01': 'Pediatric Critical Care (PICU)',
  '21.02': 'Intensive Care (ICU)',
  '22.00': 'Dental',
  '22.01': 'Pediatric Dental',
  '22.02': 'Prosthodontics',
  '22.03': 'Endodontics',
  '22.04': 'Periodontics',
  '22.05': 'Orthodontics',
  '22.06': 'Dental Implants',
  '22.07': 'Dental Hygiene',
  '22.08': 'Special Needs Dentistry',
  '23.00': 'Neurophysiology',
  '24.00': 'Speech/Speech Language Pathology',
  '25.00': 'Infection Control',
};
