import React from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Patient/features/CreatePatientModal/CreatePatientModal';
import {
  KlaimStepper,
  KlaimStepperHeader,
  KlaimStepperSteps,
  KlaimStepperStep,
  KlaimStepperFooter,
} from 'modules/Common/components-v2/KlaimStepper';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {gender, idType, maritalStatus, relationship, addressType} from 'modules/Common/constants/DropdownOptions';
import country from 'modules/Common/constants/DropdownOptions/country';
import {KlaimInput, KlaimDropdownSearch} from 'modules/Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {IPatientForm, ICreatePatientRequest} from 'interfaces/patient.interface';
import KlaimFieldWrapper from 'modules/Common/components-v2/KlaimForm/components/KlaimFieldWrapper';
import KlaimForm from 'modules/Common/components-v2/KlaimForm/components/KlaimForm';
import {FormikValues} from 'formik';
import {createPatient, patientSelect} from '../../patientSlice';

const CreatePatientModal: React.FC<ComponentProps> = ({isOpen, setIsOpen, branch}) => {
  const dispatch = useAppDispatch();
  const {isCreatePatientSuccess, isLoading} = useAppSelector(patientSelect);
  const [currentStep, setCurrentStep] = React.useState(0);
  const onSubmit = (values: FormikValues) => {
    const payload: ICreatePatientRequest = {
      providerId: branch,
      fullName: [values.firstName, values.middleName, values.lastName],
      name: `${values.firstName} ${values.middleName} ${values.lastName}`,
      dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      gender: values.gender,
      maritalStatus: values.maritalStatus,
      phone: values.phone,
      documentIdType: values.documentIdType,
      documentId: values.documentId,
    };

    if (values.address.use) {
      payload.address = values.address;
    }

    dispatch(createPatient(payload));
  };

  React.useEffect(() => {
    if (isCreatePatientSuccess) {
      setIsOpen(false);
    }
  }, [isCreatePatientSuccess]);

  const classes = useStylesFromThemeFunction();

  const initialValues: IPatientForm = {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    phone: '',
    documentIdType: '',
    documentId: '',
    maritalStatus: '',
    address: {
      city: '',
      country: '',
      text: '',
      use: '',
    },
  };

  const validationSchema = Yup.object({
    documentIdType: Yup.string().required('ID type is required.'),
    documentId: Yup.string().max(15, 'ID number must be at most 15 characters').required('ID number is required.'),
    firstName: Yup.string().required('Firstname is required.'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Lastname is required.'),
    dateOfBirth: Yup.string().required('Date of birth is required.'),
    gender: Yup.string().required('Gender is required.'),
    maritalStatus: Yup.string().required('Civil status is required.'),
    // mobileNumber: Yup.string().required('Mobile number is required.'),
    address: Yup.object({
      use: Yup.string(),
      text: Yup.string().when('use', {
        is: (use) => use && use.length > 0,
        then: Yup.string().required('Address is required'),
      }),
      country: Yup.string().when('use', {
        is: (use) => use && use.length > 0,
        then: Yup.string().required('Country is required'),
      }),
      city: Yup.string().when('use', {
        is: (use) => use && use.length > 0,
        then: Yup.string().required('City is required'),
      }),
    }),
  });

  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen}>
      <KlaimModalHeader title="Create Patient" />
      <KlaimModalBody>
        <KlaimForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <div>
            <div style={{margin: 20}}>
              <KlaimStepper initialStep={currentStep} setStep={setCurrentStep}>
                <KlaimStepperHeader />
                <KlaimStepperSteps>
                  <KlaimStepperStep>
                    <div className={classes.container}>
                      <KlaimFieldWrapper name="documentIdType">
                        <KlaimDropdownSearch
                          label="ID Type"
                          options={idType}
                          variant={'secondary'}
                          value={initialValues.documentIdType}
                        />
                      </KlaimFieldWrapper>
                      <KlaimFieldWrapper name="documentId">
                        <KlaimInput label={'ID Number'} variant={'secondary'} />
                      </KlaimFieldWrapper>
                      <KlaimFieldWrapper name="firstName">
                        <KlaimInput label={'First Name'} variant={'secondary'} />
                      </KlaimFieldWrapper>
                      <KlaimFieldWrapper name="middleName">
                        <KlaimInput label={'Middle Name'} variant={'secondary'} optional />
                      </KlaimFieldWrapper>
                      <KlaimFieldWrapper name="lastName">
                        <KlaimInput label={'Last Name'} variant={'secondary'} />
                      </KlaimFieldWrapper>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                          columnGap: '1rem',
                        }}
                      >
                        <KlaimFieldWrapper name="gender">
                          <KlaimDropdownSearch label="Gender" options={gender} variant={'secondary'} />
                        </KlaimFieldWrapper>
                        <KlaimFieldWrapper name="maritalStatus">
                          <KlaimDropdownSearch label="Marital Status" options={maritalStatus} variant={'secondary'} />
                        </KlaimFieldWrapper>
                      </div>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                          columnGap: '1rem',
                        }}
                      >
                        <KlaimFieldWrapper name="dateOfBirth">
                          <KlaimInput
                            type="calendar"
                            label="Date of Birth"
                            placeholder={'MM/DD/YYYY'}
                            variant="secondary"
                          />
                        </KlaimFieldWrapper>
                        <KlaimFieldWrapper name="mobileNumber">
                          <KlaimInput
                            type="phone"
                            label="Mobile Number"
                            defaultCountry="SA"
                            placeholder="+966 000 XXX XXXX"
                          />
                        </KlaimFieldWrapper>
                      </div>
                    </div>
                  </KlaimStepperStep>
                  <KlaimStepperStep>
                    <div className={classes.container}>
                      <KlaimFieldWrapper name={'address.use'}>
                        <KlaimDropdownSearch
                          variant={'secondary'}
                          label={'Address Type'}
                          options={addressType}
                          placeholder={'Select Address Type'}
                          isOptional
                        />
                      </KlaimFieldWrapper>
                      <>
                        <KlaimFieldWrapper name="address.country">
                          <KlaimDropdownSearch
                            label="Country"
                            placeholder="Country"
                            variant="secondary"
                            options={country}
                            isOptional
                          />
                        </KlaimFieldWrapper>
                        <KlaimFieldWrapper name="address.city">
                          <KlaimInput label="City" placeholder="City" variant="secondary" optional />
                        </KlaimFieldWrapper>
                        <KlaimFieldWrapper name="address.text">
                          <KlaimInput label="Address" placeholder="Address" variant="secondary" optional />
                        </KlaimFieldWrapper>
                      </>
                    </div>
                  </KlaimStepperStep>
                </KlaimStepperSteps>
                <KlaimStepperFooter onCancel={() => setIsOpen(false)} setCurrentStep={setCurrentStep} />
              </KlaimStepper>
            </div>
          </div>
        </KlaimForm>
      </KlaimModalBody>
      {/* <KlaimModalFooter onSubmit={onSubmit} /> */}
    </KlaimModal>
  );
};

export default CreatePatientModal;
