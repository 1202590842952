import React, {FC, useEffect, useRef, useState} from 'react';
import {Formik, FormikErrors, FormikValues, getIn} from 'formik';
import {Link, RouteComponentProps} from 'react-router-dom';
import closeIcon from 'assets/close_icon.svg';
import backArrow from 'assets/back-arrow.svg';
import KlaimModal from 'modules/Common/components/KlaimModal';
import CollapsibleSection from 'modules/Common/components/CollapsibleSection';
import Button from 'modules/Common/components/Button';
import {useHistory, useLocation} from 'react-router';
import queryString from 'query-string';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import * as Toast from 'modules/Common/utils/toast';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {set as setStorage} from 'local-storage';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {
  deleteClaimDraft,
  getActivityCode,
  getClaimById,
  getClaimDraftById,
  getClinicians,
  getCliniciansById,
  getDiagnosesCode,
  getLatestClaimById,
  resendClaim,
  resubmitClaim,
  saveClaimDraft,
  submitClaim,
  testClaim,
  testResendClaim,
  testResubmitClaim,
  updateClaimDraft,
} from 'modules/Claims/api';
import {
  ActivityCode,
  AutoGenerateClaimId,
  Claim,
  ClaimActivity,
  ClaimDiagnosis,
  ClaimInitialFormValue,
  ClaimStatus,
  Clinician,
  DiagnosesCodes,
  Observation,
} from 'modules/Claims/api/types';
import BranchDropdown from 'modules/Common/components/BranchDropdown';
import {selectClaimById} from 'modules/App/selectors';
import {events, Mixpanel} from 'services/mixpanel';
import {useAppSelector} from 'modules/App/store';
import {headerSelect} from 'modules/Header/headerSlice';
import ClaimInformation from 'modules/Claims/components/ClaimInformation';
import PatientInformation from 'modules/Claims/components/PatientInformation';
import Encounter from 'modules/Claims/components/Encounter';
import Diagnosis from 'modules/Claims/components/Diagnosis';
import Activity from 'modules/Claims/components/Activity';
import Resubmit from 'modules/Claims/components/Resubmit';
import {useStylesFromThemeFunction} from './ClaimForm';

dayjs.extend(customParseFormat);

export const EmiratesIdRegex = /^\d{3}-\d{4}-\d{7}-\d$/;
export const DateRegex = /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2}) ([0-1]?[0-9]|2?[0-3]):([0-5]\d)$/;

const diagnoseValidation = Yup.object().shape({
  type: Yup.string(),
  code: Yup.mixed().test('String or null', 'Code is required.', (code) => {
    return true;
  }),
  dxInfo: Yup.object().shape({
    type: Yup.string(),
    code: Yup.string(),
  }),
});

const activityObservationValidation = Yup.object().shape({
  type: Yup.string().required('Observation type is required.'),
  code: Yup.string().when('type', {
    is: (v) => v !== 'File',
    then: Yup.string().required('Observation code is required.'),
    otherwise: null,
  }),
  value: Yup.string(),
  valueType: Yup.string(),
});

const activityValidation = Yup.object().shape({
  activityId: Yup.string(),
  start: Yup.string()
    .required('Activity start is required field.')
    .test('dateRegex', 'Please enter valid date.', (value) => {
      return DateRegex.test(value as string);
    }),
  type: Yup.number().required('Type is required.'),
  code: Yup.string().required('Code is required.'),
  quantity: Yup.number().required('Quantity is required.').typeError('Quantity must be a number'),
  gross: Yup.number().required('Gross amount is required.').typeError('Gross amount must be a number'),
  net: Yup.number().required('Net amount is required.').typeError('Net amount must be a number'),
  patientShare: Yup.number(),
  clinicianId: Yup.string().required('Clinician ID is required.'),
  priorAuthorizationId: Yup.string(),
});
interface RouteParams {
  id: string;
}

const ClaimForm: FC<RouteComponentProps<RouteParams>> = (props) => {
  const {t} = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const classes = useStylesFromThemeFunction();
  const [autoGenerateClaimId, setAutoGenerateClaimId] = useState(AutoGenerateClaimId.YES);
  const {claimId, claimType, providerId} = queryString.parse(location.search || '');
  const [claimInitialValue, setClaimInitialValue] = useState<any>(ClaimInitialFormValue);
  const claimData = useRef<any>();
  const [savedDraft, setSavedDraft] = useState(false);
  const {selectedBranches} = useAppSelector(headerSelect);
  const claimRef = useRef(null);
  const [deleteDraftOpen, setDeleteDraftOpen] = React.useState<boolean>(false);
  const [checkError, setCheckError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  let checkProd = false;
  const claimStatusCheck = useSelector((state: any) =>
    selectClaimById(state, queryString.parse(location.search).claimId || null),
  );

  const getAutoGenerateClaimId = (providerID: string, receiverId: string) => {
    const date = new Date();
    const hours =
      date.getHours().toString().length === 1 ? date.getHours().toString().padStart(2, '0') : date.getHours();
    const minutes =
      date.getMinutes().toString().length === 1 ? date.getMinutes().toString().padStart(2, '0') : date.getMinutes();
    const seconds =
      date.getSeconds().toString().length === 1 ? date.getSeconds().toString().padStart(2, '0') : date.getSeconds();
    const year = date.getFullYear();
    const month =
      date.getMonth().toString().length === 1 ? date.getMonth().toString().padStart(2, '0') : date.getMonth();
    const day = date.getDate().toString().length === 1 ? date.getDate().toString().padStart(2, '0') : date.getDate();
    return `${providerID}-${receiverId}-${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  const claimAutoGenerate = () => {
    if (!claimData?.current?.values?.claimId) {
      claimData.current.values.claimId = getAutoGenerateClaimId(
        claimData?.current?.values?.providerId,
        claimData?.current?.values?.receiverId,
      );
    }
  };

  if (window.location.href.includes('production')) {
    checkProd = true;
  }

  const claimFormValidationSchema = Yup.object().shape({
    emiratesIdNumber: Yup.string()
      .required('Emirates ID is required.')
      .matches(EmiratesIdRegex, 'Please enter valid Emirates ID format.'),
    memberId: Yup.string().required('Member ID is required.'),
    claimId: Yup.string().required('Claim ID is required.'),
    payerId: Yup.string().required('Payer ID is required.'),
    receiverId: Yup.string().required('Receiver ID is required.'),
    encounter: Yup.object().shape({
      patientId: Yup.string().required('Patient ID is required.'),
      start: Yup.string()
        .required('Encounter start date is required.')
        .test('dateRegex', 'Please enter valid date.', (value) => {
          return DateRegex.test(value as string);
        }),
      type: Yup.number(),
      startType: Yup.number().nullable(),
      end: Yup.string().test('dateRegex', 'Please enter valid date.', (value) => {
        if (value) {
          return DateRegex.test(value as string);
        }
        return true;
      }),
      endType: Yup.number().nullable(),
    }),
    diagnoses: Yup.array().of(diagnoseValidation),
    activities: Yup.array().of(activityValidation),
    providerId: Yup.string().required('Branch is required.'),
    resubmission: Yup.object().shape({
      comment: Yup.string().test('isResubmit', 'Comment is required.', function (value) {
        return !(claimType === 'resubmit' && !value);
      }),
    }),
  });

  const fillYearOfOnset = (diagnoses: any) => {
    if (diagnoses) {
      diagnoses.forEach((diagnose: any) => {
        if (diagnose.type === 'Principal' && diagnose.dxInfo.code) {
          diagnose.dxInfo.type = 'Year of Onset';
        } else if ((diagnose.type === 'Secondary' || diagnose.type === 'Admitting') && diagnose.dxInfo) {
          delete diagnose.dxInfo;
        }
      });
    }
  };

  const saveDraft = async () => {
    if (claimData.current.values.diagnoses) {
      fillYearOfOnset(claimData.current.values.diagnoses);
    }
    if (
      location.search.includes('claimId') &&
      location.search.includes('claimType') &&
      location.search.includes('providerId')
    ) {
      try {
        const currentDraft = claimData.current.values;
        const claimForm = await getClaimForm(currentDraft);
        const data = await updateClaimDraft(claimId, claimForm);
        setSavedDraft(true);
        if (data.status === 200) {
          Toast.success('Claim draft updated successfully');
          setTimeout(() => {
            setSavedDraft(false);
          }, 8000);
          Mixpanel.track(events.claims.claimSaveDraft, {success: true});
          setStorage('currentDraft', currentDraft);
        }
      } catch (e) {
        setSavedDraft(false);
        Mixpanel.track(events.claims.claimSaveDraft, {success: false});
      }
    } else {
      await saveClaimAsDraft();
    }
  };

  const saveClaimAsDraft = async () => {
    const currentDraft = claimData.current.values;
    if (
      !claimData?.current?.values?.claimId &&
      (!claimData?.current?.values?.providerId || !claimData?.current?.values?.receiverId)
    ) {
      Toast.error(t('Please Select a Receiver For unique Claim ID'));
      return;
    }
    try {
      claimAutoGenerate();
      const claimForm = await getClaimForm(currentDraft);
      const data = await saveClaimDraft(claimForm);
      setSavedDraft(true);
      if (data.status === 201) {
        Toast.success('Claim is saved as draft successfully');
        setTimeout(() => {
          setSavedDraft(false);
        }, 8000);
        Mixpanel.track(events.claims.claimSaveDraft, {success: true});
        setStorage('currentDraft', currentDraft);
      }
    } catch (e) {
      setSavedDraft(false);
      Mixpanel.track(events.claims.claimSaveDraft, {success: false});
    }
  };

  useEffect(() => {
    (async () => {
      if (claimId && claimType?.toString().toLocaleLowerCase() === ClaimStatus.draft) {
        Mixpanel.track(events.pageViews.claimDraft);
        const data = await getClaimDraftById(claimId, providerId);
        const draft = {...claimInitialValue, ...data.data.draft};
        const draftForm = await getClaimForm(draft);
        setClaimInitialValue(draftForm);
        setStorage('currentDraft', claimData.current?.values);
      }
      if (claimId && claimType?.toString().toLocaleLowerCase() === 'resubmit') {
        await getLatestClaimsById(claimId);
        Mixpanel.track(events.pageViews.claimDetail);
      }
      if (
        claimId &&
        claimType?.toString().toLocaleLowerCase() !== ClaimStatus.draft &&
        claimType?.toString().toLocaleLowerCase() !== 'resubmit'
      ) {
        await getClaimsById(claimId);
        Mixpanel.track(events.pageViews.claimDetail);
      }
    })();
  }, [claimId]);

  useEffect(() => {
    if (!claimId && !claimType) {
      Mixpanel.track(events.pageViews.newClaim);
    }
  }, []);

  const deleteDraft = async () => {
    if (claimData.current.values.providerId) {
      try {
        const data = await deleteClaimDraft(claimId ? claimId.toString() : '', claimData.current.values.providerId);
        if (data.status === 200) {
          Mixpanel.track(events.claims.claimDeleteDraft, {success: true});
          Toast.success('Draft deleted successfully.');
          history.push('/claims');
        }
      } catch (e) {
        Mixpanel.track(events.claims.claimDeleteDraft, {success: false});
        Toast.error('Error deleting draft.');
      }
    }
  };

  const handleInputChangeSecond = (
    event: any,
    fieldName: any,
    field: any,
    setFieldTouched: (e: any) => void,
    handleChange: (e: any) => void,
    arrayHelpers?: any,
  ) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const claimSubmit = async (claim: Claim, {resetForm}: any) => {
    if (!claimData?.current?.values?.claimId) {
      claimAutoGenerate();
    }
    if (claimData.current.values.diagnoses) {
      fillYearOfOnset(claimData.current.values.diagnoses);
    }
    const claimFormData = await getClaimForm(claim, 'submit');
    for (let i = 0; i < claimFormData.activities.length; i++) {
      claimFormData.activities[i].observations = claimFormData.activities[i].observations.filter(
        (observation: any) => observation.type && observation.code,
      );
    }
    for (let i = 0; i < claimFormData.diagnoses.length; i++) {
      const result =
        claimFormData?.diagnoses[i]?.dxInfo &&
        !claimFormData?.diagnoses[i]?.dxInfo?.code &&
        delete claimFormData?.diagnoses[i]?.dxInfo;
    }
    let data;
    let claimEvent;
    if (claimType === 'resubmit') {
      claimEvent = events.claims.claimResubmission;
    } else {
      claimEvent = events.claims.claimSubmission;
    }
    try {
      if (claimType === 'resubmit') {
        data = await testResubmitClaim(claimFormData, claimId);
        data = await resubmitClaim(claimFormData, claimId);
      } else if (claimType === 'submitting') {
        data = await testResendClaim(claimFormData, claimId);
        data = await resendClaim(claimFormData, claimId);
      } else {
        data = await testClaim(claimFormData);
        data = await submitClaim(claimFormData);
      }
      if (data?.status === 201) {
        Mixpanel.track(claimEvent, {success: true});
        Toast.success('Claim is created successfully');
        resetForm();
        history.push('/claims');
      } else {
        Toast.error(`Error creating claim.`);
        Mixpanel.track(claimEvent, {success: false});
      }
    } catch (e) {
      if (typeof e === 'string') {
        Toast.error(e);
      }
      if (e?.response?.data?.details) {
        if (e?.response?.data?.details.includes(' ')) {
          Toast.error(
            e?.response?.data?.code === 'dhpo_error'
              ? `${e?.response?.data?.message}`
              : `${e?.response?.data?.details}`,
          );
        } else {
          Toast.error(e?.response?.data?.message);
          setCheckError(true);
          setErrorMessage(e?.response?.data?.details);
        }
      } else {
        Toast.error(e?.response?.data?.message);
      }
      Mixpanel.track(claimEvent, {success: false});
    }
  };

  const convertStringToIsoDate = (dateString?: string) => {
    if (!dateString) return undefined;
    if (dateString.length > 16) {
      return dayjs(dateString).format('DD/MM/YYYY HH:mm');
    }
    const date = dayjs(dateString, 'DD/MM/YYYY HH:mm').toDate();
    return date.toISOString();
  };

  const onObservationFileUpload = (observationValues: any) => {
    if (observationValues.type === 'File') {
      observationValues.code = '';
      observationValues.valueType = '';
    }
    return observationValues;
  };

  const formatEmiratesId = (id: string) => {
    if (id !== undefined) {
      if (!id.includes('-') && id.length === 15) {
        const src = /^(\d{3})(\d{4})(\d{7})(\d{1})$/;
        const dst = '$1-$2-$3-$4';
        return id.replace(src, dst);
      }
      return id;
    }
  };

  const getClaimForm = async (claimForm: any, type?: string | null) => {
    if (type !== 'resubmit') {
      const authority = getAuthorityCode(claimForm.providerId);
      const diagnoses = await Promise.all(
        claimForm.diagnoses
          .filter((d: any) => d.code)
          .map(async (d: any, i: number) => {
            if (d.dxInfo === undefined) {
              return {
                defaultValue:
                  (type !== 'submit' && d.code && (await getDiagnoseCode(d.code, authority))[0]) || undefined,
                code: d.code,
                type: d.type,
                diagnoseId: i.toString(),
              };
            }
            if (d.dxInfo.code === '') {
              return {
                defaultValue:
                  (type !== 'submit' && d.code && (await getDiagnoseCode(d.code, authority))[0]) || undefined,
                code: d.code,
                type: d.type,
                diagnoseId: i.toString(),
              };
            }
            return {
              defaultValue: (type !== 'submit' && d.code && (await getDiagnoseCode(d.code, authority))[0]) || undefined,
              code: d.code,
              type: d.type,
              dxInfo: {
                code: d.dxInfo.code,
                type: d.dxInfo.type,
              },
              diagnoseId: i.toString(),
            };
          }),
      );

      const activities = await Promise.all(
        claimForm.activities.map(async (activity: ClaimActivity, i: number) => {
          let clinicianValue;
          let orderingClinicianValue;
          let code;
          if (type !== 'submit') {
            clinicianValue = await getCliniciansList(activity.clinicianId, authority);
            orderingClinicianValue = await getCliniciansOrderingList(activity.clinicianId, authority);
            code = await getActivitiesCode(activity.code, activity.type, authority);
          }
          return {
            activityId: activity.activityId || i.toString(),
            activityIds: i.toString(),
            start: activity.start
              ? convertStringToIsoDate(activity.start)
              : convertStringToIsoDate(claimForm.encounter.start),
            type: +activity.type || undefined,
            code: activity.code || undefined,
            activityPerformed: (code && code.find((a: any) => a.value === activity.code)) || undefined,
            quantity: +activity.quantity || undefined,
            gross: +activity.gross || +activity.net,
            net: +activity.net || undefined,
            patientShare: +activity.patientShare || 0,
            clinicianId: activity.clinicianId || undefined,
            orderingClinicianId:
              activity.orderingClinicianId === undefined ? activity.clinicianId : activity.orderingClinicianId,
            priorAuthorizationId: activity.priorAuthorizationId || undefined,
            clinicianValue: (clinicianValue && clinicianValue.find((c: any) => c.value === activity.clinicianId)) || {
              label: activity.clinicianId,
            },
            orderingClinicianValue: (orderingClinicianValue &&
              orderingClinicianValue.find(
                (c: any) =>
                  c.value ===
                  (activity.orderingClinicianId === undefined ? activity.clinicianId : activity.orderingClinicianId),
              )) || {
              label: activity.orderingClinicianId,
            },
            observations: activity.observations
              .filter((observation: Observation, j: number) => observation.code !== '')
              .map((observation: Observation, j: number) => {
                observation = onObservationFileUpload(observation);
                return {
                  type: observation.type || '',
                  code: observation.code || '',
                  value: observation.value || '',
                  valueType: observation.valueType || '',
                  fileId: observation.fileId || '',
                  fileBase64: observation.fileBase64 || undefined,
                  observationId: j.toString(),
                };
              }),
          };
        }),
      );

      if (type !== 'submit') {
        diagnoses.push({type: diagnoses.length > 0 ? 'Secondary' : 'Principal', code: ''});
      }

      return {
        idType: emiratesIdTypeOnChange(claimForm.emiratesIdNumber),
        providerId: claimForm.providerId,
        claimId: claimForm.claimId,
        memberId: claimForm.memberId || undefined,
        payerId: claimForm.payerId || undefined,
        receiverId: claimForm.receiverId || undefined,
        emiratesIdNumber: formatEmiratesId(claimForm.emiratesIdNumber) || undefined,
        idPayer: claimForm.idPayer,
        encounter: {
          type: +claimForm.encounter.type || undefined,
          patientId: claimForm.encounter.patientId || undefined,
          start: convertStringToIsoDate(claimForm.encounter.start) as string,
          end: convertStringToIsoDate(claimForm.encounter.end) as string,
          startType: claimForm.encounter.startType ? +claimForm.encounter.startType : undefined,
          endType: claimForm.encounter.end_type ? claimForm.encounter.end_type : 1,
        },
        diagnoses,
        activities,
        resubmission:
          claimType === 'resubmit'
            ? {
                type: claimForm.resubmission.type.toLowerCase(),
                comment: claimForm.resubmission.comment,
                attachment: claimForm.resubmission.attachment,
              }
            : undefined,
      };
    }
    claimForm.resubmission = {type: 'correction', comment: '', attachment: undefined};
    claimForm.diagnoses.push({type: claimForm.diagnoses.length > 0 ? 'Secondary' : 'Principal', code: ''});
    claimForm.activities.map((a: any) => a.observations.push({type: '', code: '', value: '', valueType: ''}));

    return {
      idType: emiratesIdTypeOnChange(claimForm.emirates_id),
      providerId: claimForm.sender_id,
      claimId: claimForm.claim_id,
      memberId: claimForm.member_id || undefined,
      payerId: claimForm.payer_id || undefined,
      receiverId: claimForm.receiver_id || undefined,
      idPayer: claimForm.id_payer || '',
      emiratesIdNumber: formatEmiratesId(claimForm.emirates_id) || undefined,
      encounter: {
        type: claimForm.encounter.type || undefined,
        patientId: claimForm.encounter.patient_id || undefined,
        start: convertStringToIsoDate(claimForm.encounter.start_date) as string,
        end: convertStringToIsoDate(claimForm.encounter.end_date) as string,
        startType: claimForm.encounter.start_type ? claimForm.encounter.start_type : undefined,
        endType: claimForm.encounter.end_type ? claimForm.encounter.end_type : 1,
      },
      diagnoses: claimForm.diagnoses.map((d: any, i: number) => {
        if (d.dx_info === undefined) {
          return {
            defaultValue: d.code && {label: `${d.code} - ${d.short_description || ''}`, value: d.code},
            code: d.code,
            type: d.type,
            diagnoseId: i.toString(),
          };
        }
        if (d.dx_info.code === '') {
          return {
            defaultValue: d.code && {label: `${d.code} - ${d.short_description || ''}`, value: d.code},
            code: d.code,
            type: d.type,
            diagnoseId: i.toString(),
          };
        }
        return {
          defaultValue: d.code && {label: `${d.code} - ${d.short_description || ''}`, value: d.code},
          code: d.code,
          type: d.type,
          dxInfo: {
            code: d.dx_info.code,
            type: 'Year of Onset',
          },
          diagnoseId: i.toString(),
        };
      }),
      activities: claimForm.activities.map((activity: any, index: number) => ({
        activityIds: index.toString(),
        activityId: activity.active_activity_id,
        start: convertStringToIsoDate(activity.start_date),
        type: activity.activity_performed.type || undefined,
        code: activity.activity_performed.code || undefined,
        quantity: +activity.quantity,
        gross: +activity.gross || +activity.net,
        net: +activity.net,
        patientShare: +activity.patient_share || 0,
        clinicianId: activity.clinician.id || undefined,
        orderingClinicianId:
          activity.ordering_clinician.id === undefined ? activity.clinician.id : activity.ordering_clinician.id,
        priorAuthorizationId: activity.prior_authorization_provider_id || undefined,
        clinicianValue: {
          label: `${activity.clinician.id} - ${activity.clinician.name}`,
          value: activity.clinician.id,
        },
        orderingClinicianValue: {
          label: `${activity.ordering_clinician.id} - ${activity.clinician.name}`,
          value: activity.ordering_clinician.id,
        },
        observations: activity.observations.map((observation: Observation, j: number) => {
          return {
            type: observation.type || '',
            code: observation.code || undefined,
            value: observation.value || undefined,
            valueType: observation.valueType || undefined,
            fileId: observation.fileId || undefined,
            fileBase64: observation.fileBase64 || undefined,
            observationId: j.toString(),
          };
        }),
        activityPerformed: {
          label: `${activity.activity_performed.code} - ${activity.activity_performed.short_description}`,
          code: activity.activity_performed.code,
        },
      })),
      resubmission: claimType === 'resubmit' ? claimForm.resubmission : undefined,
    };
  };

  const getClaimsById = async (claimResubmitId?: string[] | string) => {
    try {
      if (selectedBranches.length > 0) {
        const {data} = await getClaimById(
          claimResubmitId,
          selectedBranches.map((provider) => provider.value).join(','),
        );
        const resubmitData = await getClaimForm(data, 'resubmit');
        for (let i = 0; i < resubmitData.activities.length; i++) {
          if (resubmitData.activities[i].observations.length !== 1) {
            for (let j = 0; j < resubmitData.activities[i].observations.length; j++) {
              if (!resubmitData.activities[i].observations[j].type) {
                resubmitData.activities[i].observations.pop();
              }
            }
          }
        }
        setClaimInitialValue(resubmitData);
        setStorage('currentDraft', claimData.current.values);
      }
    } catch (e) {
      if (typeof e === 'string') Toast.error(e);
      else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
    }
  };

  const getLatestClaimsById = async (claimResubmitId?: string[] | string) => {
    try {
      if (selectedBranches.length > 0) {
        // const {data} = await getLatestClaimById(claimResubmitId, providerId);
        const {data} = await getClaimById(
          claimResubmitId,
          selectedBranches.map((provider) => provider.value).join(','),
        );
        const resubmitData = await getClaimForm(data, 'resubmit');
        for (let i = 0; i < resubmitData.activities.length; i++) {
          if (resubmitData.activities[i].observations.length !== 1) {
            for (let j = 0; j < resubmitData.activities[i].observations.length; j++) {
              if (!resubmitData.activities[i].observations[j].type) {
                resubmitData.activities[i].observations.pop();
              }
            }
          }
        }
        setClaimInitialValue(resubmitData);
        setStorage('currentDraft', claimData.current.values);
      }
    } catch (e) {
      if (typeof e === 'string') Toast.error(e);
      else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
    }
  };
  const emiratesIdTypeOnChange = (emiratesId: string) => {
    switch (emiratesId) {
      case '000-0000-0000000-0':
        return 'national';
      case '111-1111-1111111-1':
        return 'expatriate';
      case '222-2222-2222222-2':
        return 'other';
      case '999-9999-9999999-9':
        return 'unknown';
      default:
        return 'manual';
    }
  };

  const getDiagnoseCode = async (search: string, authority: string) => {
    if (search.length > 0) {
      try {
        const {data} = await getDiagnosesCode(search, authority);
        return data.data
          .map((code: DiagnosesCodes) => {
            return {label: `${code.diagnosisCode} - ${code.shortDescription}`, value: code.diagnosisCode};
          })
          .sort((a: DiagnosesCodes, b: DiagnosesCodes) => (a.diagnosisCode > b.diagnosisCode ? 1 : -1));
      } catch (e) {
        if (typeof e === 'string') Toast.error(e);
        else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
      }
    }
  };

  const getCliniciansList = async (clinicianId: string, authority: string) => {
    if (clinicianId && clinicianId.length > 0) {
      try {
        const {data} = await getCliniciansById(clinicianId, authority);
        return data.data
          .map((code: Clinician) => {
            if (!code.name)
              return {
                label: `${code.clinicianId}`,
                value: code.clinicianId,
              };
            return {label: `${code.clinicianId} - ${code.name}`, value: code.clinicianId};
          })
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      } catch (e) {
        if (typeof e === 'string') Toast.error(e);
        else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
      }
    }
  };

  const getCliniciansOrderingList = async (clinicianId: string, authority: string) => {
    if (clinicianId && clinicianId.length > 0) {
      try {
        const {data} = await getCliniciansById(clinicianId, authority);
        return data.data
          .map((code: Clinician) => {
            if (!code.name)
              return {
                label: `${code.orderingClinicianId}`,
                value: code.orderingClinicianId,
              };
            if (code.orderingClinicianId === undefined) {
              return {label: `${code.clinicianId} - ${code.name}`, value: code.clinicianId};
            }
            return {label: `${code.orderingClinicianId} - ${code.name}`, value: code.orderingClinicianId};
          })
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      } catch (e) {
        if (typeof e === 'string') Toast.error(e);
        else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
      }
    }
  };

  const getActivitiesCode = async (searchString: string, activityType: string, authority: string) => {
    if (searchString && searchString.length > 0) {
      try {
        const {data} = await getActivityCode(searchString, activityType, authority);
        return data.data
          .map((code: ActivityCode) => {
            if (code.drug)
              return {
                label: `${code.drug.name} – ${code.drug.strength}, ${code.drug.dosage_form_package}, ${code.drug.manufacturer}`,
                value: code.activityCode,
              };
            return {label: code.shortDescription, value: code.activityCode};
          })
          .sort((a: ActivityCode, b: ActivityCode) => (a.activityCode > b.activityCode ? 1 : -1));
      } catch (e) {
        if (typeof e === 'string') Toast.error(e);
        else Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
      }
    }
  };

  const getAuthorityCode = (provider: string) => {
    const branches = JSON.parse(localStorage.getItem('userBranches') as string);
    return branches.find((b: {value: ''}) => b.value === provider).authority;
  };
  // Commented code changes of issues 248, As they are creating issue of resubmit
  // const [userBranches, setBranches] = useState<Array<any>>([]);

  // useEffect(() => {
  //   getUserBranches();
  // }, []);

  // const getUserBranches = () => {
  //   const branches = localStorage.getItem('userBranches');
  //   if (branches != null) {
  //     setBranches(JSON.parse(branches));
  //   }
  // };

  // const defaultSingleValue = (values: any) => {
  //   if (userBranches.length <= 1) {
  //     const defValue = userBranches.map((branch) => ({
  //       ...branch,
  //       values: branch[0].value,
  //     }));
  //     return defValue;
  //   }
  //   return values.providerId;
  // };

  const BranchId = ({handleChange, values, errors, touched, setFieldTouched}: any) => {
    return (
      <div className={classes.formSectionElementsWrapper}>
        <BranchDropdown
          className={classes.formElementWithBottomSpacing}
          value={values.providerId}
          onChange={(e) => handleInputChangeSecond(e, values, 'providerId', setFieldTouched, handleChange)}
          name="providerId"
          error={errors.providerId && touched.providerId ? errors.providerId : null}
          disabled={values.providerId && (claimType === 'resubmit' || claimType === 'draft')}
        />
      </div>
    );
  };

  const patientInformationVisible = (errors: FormikErrors<any>, values: FormikValues) => {
    return (
      (!errors.memberId &&
        !errors.payerId &&
        !errors.receiverId &&
        (autoGenerateClaimId === 'YES' || values.claimId)) ||
      values.encounter?.patientId
    );
  };

  const encounterVisible = (errors: FormikErrors<any>, values: FormikValues) => {
    return !getIn(errors, 'encounter.patientId') || values.encounter?.start;
  };

  const diagnosisVisible = (errors: FormikErrors<any>) => {
    return !getIn(errors, 'encounter.start');
  };

  const activityVisible = (values: FormikValues) => {
    return values.diagnoses && values.diagnoses.some((d: ClaimDiagnosis) => d.code);
  };

  const branchVisible = (values: FormikValues) => {
    return values.providerId;
  };

  const downloadErrorMessage = () => {
    const dhpoError = errorMessage;
    window.location.href = `data:application/zip;base64,${dhpoError.replaceAll('\n', '')}`;
  };
  return (
    <>
      <KlaimModal contentClass={classes.modal} isOpen={deleteDraftOpen}>
        <div className={classes.deleteUserContainer}>
          <div className={classes.headerContainer}>
            <h1>Delete Draft</h1>
            <img onClick={() => setDeleteDraftOpen(false)} src={closeIcon} alt="Close-icon" />
          </div>
          <div className={classes.messageContainer}>
            <div>
              <h2>Are you sure you want to delete draft?</h2>
            </div>
          </div>
          <div className={classes.optionsButtonContainer}>
            <Button
              title="No, Go back"
              onClick={() => setDeleteDraftOpen(false)}
              buttonClassName={classes.backButton}
            />
            <Button
              title="Delete Draft"
              onClick={() => {
                deleteDraft();
              }}
            />
          </div>
        </div>
      </KlaimModal>
      <>
        <Formik
          initialValues={claimInitialValue}
          validationSchema={claimFormValidationSchema}
          validateOnMount
          validateOnChange
          validateOnBlur
          enableReinitialize
          onSubmit={async (values: Claim, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            await claimSubmit(values, {resetForm});
            setSubmitting(false);
          }}
          innerRef={claimData}
        >
          {({
            handleChange,
            values,
            errors,
            handleSubmit,
            touched,
            setFieldTouched,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => (
            <>
              <CollapsibleSection
                expandedContainerClassName={classes.expandedContainerClassName}
                contentClassName={classes.containerContentClassName}
                collapsedContainerClassName={classes.collapsedContainerHeaderClassName}
                renderHeader={() => (
                  <>
                    <div className={classes.collapsibleHeaderContainer}>
                      <div className={classes.backBtnContainer}>
                        {claimType === ClaimStatus.draft ? (
                          <Link to={'/claims/draft'}>
                            <img src={backArrow} alt="back-arrow" className={classes.backBtn} />
                          </Link>
                        ) : (
                          <Link to={'/claims'}>
                            <img src={backArrow} alt="back-arrow" className={classes.backBtn} />
                          </Link>
                        )}
                      </div>
                      <div className={classes.collapsibleSectionTxt}>
                        <h5>{claimType === 'resubmit' ? t('Resubmit Claim') : t('Submit New Claim')}</h5>
                      </div>

                      <div className={classes.dFlex}>
                        {claimId && claimType === ClaimStatus.draft && (
                          <Button
                            title={t('Delete Draft')}
                            type="button"
                            buttonClassName={classes.deleteDraftBtn}
                            onClick={() => {
                              setDeleteDraftOpen(true);
                            }}
                            disabled={isSubmitting}
                          />
                        )}
                        {checkError && (
                          <Button
                            title={t('Download Error')}
                            type="button"
                            buttonClassName={classes.deleteDraftBtn}
                            onClick={() => {
                              downloadErrorMessage();
                            }}
                            disabled={isSubmitting}
                          />
                        )}

                        {claimType !== 'resubmit' && claimType !== 'submitting' && (
                          <Button
                            title={t('Save as Draft')}
                            type="button"
                            buttonClassName={classes.saveDraftButton}
                            onClick={saveDraft}
                          />
                        )}
                        {claimType !== 'resubmit' ? (
                          <Button
                            title={checkProd === true ? t('Submit') : 'Test Submit'}
                            type="button"
                            onClick={handleSubmit}
                            buttonClassName={classes.submitPreAuthButton}
                            disabled={isSubmitting}
                          />
                        ) : (
                          <Button
                            title={t('Resubmit')}
                            type="button"
                            onClick={handleSubmit}
                            buttonClassName={classes.submitPreAuthButton}
                            disabled={isSubmitting}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                renderContent={() => (
                  <form className={classes.form} ref={claimRef}>
                    {claimType === 'resubmit' && (
                      <Resubmit
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        classes={classes}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    <BranchId
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                    />
                    {branchVisible(values) && (
                      <ClaimInformation
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        claimData={claimData}
                        setFieldTouched={setFieldTouched}
                      />
                    )}
                    {patientInformationVisible(errors, values) && (
                      <PatientInformation
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        classes={classes}
                      />
                    )}
                    {encounterVisible(errors, values) && (
                      <Encounter
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        classes={classes}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                      />
                    )}
                    {diagnosisVisible(errors) && (
                      <Diagnosis
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        classes={classes}
                      />
                    )}
                    {activityVisible(values) && (
                      <Activity
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        classes={classes}
                        handleBlur={handleBlur}
                      />
                    )}
                  </form>
                )}
              />
            </>
          )}
        </Formik>
      </>
    </>
  );
};

export default ClaimForm;
