import React from 'react';
import {useHistory} from 'react-router';

import klaimLogo from 'assets/klaim_logo.svg';
import statusCode from 'assets/404_vector.svg';

import Button from 'modules/Common/components/Button';

import {useStylesFromThemeFunction} from './NotFound';

const NotFound: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={klaimLogo} alt="klaimLogo" />
      </div>
      <div className={classes.vectorContainer}>
        <img src={statusCode} alt="statusCode" />
        <p>Page not found...</p>
      </div>
      <div className={classes.buttonContainer}>
        <Button title="Go Back" onClick={() => history.push('/')} />
      </div>
    </div>
  );
};

export default NotFound;
