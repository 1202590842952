import React, {FC} from 'react';
import {createUseStyles} from 'react-jss';
import Select from 'react-select';
import {Colors} from 'modules/Common/constants/Colors';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    klaimSelectLabel: {
      color: '#222b45',
      fontSize: 13,
      fontWeight: 'bold',
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 4,
    },
    inputError: {
      color: `${Colors.red} !important`,
      fontSize: 12,
      marginBottom: `0 !important`,
      marginTop: 0,
      marginLeft: 5,
    },
  };
});

const customStyles = {
  control: (base: any, state: {isFocused: any}) => ({
    ...base,
    background: Colors.blueGrayLight,
    borderRadius: 10,
    height: 40,
    '&:hover': {borderColor: `${Colors.grayLight}`},
    border: `1px solid ${Colors.grayLight}`,
    boxShadow: 'none',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    borderRadius: 10,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 10,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: Colors.blueGrayDark,
    '&:hover': {
      color: Colors.blueGrayDark,
    },
  }),
  option: (base: any, state: {isSelected: any}) => ({
    ...base,
    backgroundColor: state.isSelected && Colors.purple,
    '&:hover': {backgroundColor: !state.isSelected && Colors.blueGrayLight},
    ':active': {
      ...base[':active'],
      backgroundColor: Colors.blueGrayLight,
    },
  }),
};

interface ComponentProps {
  name?: string;
  label?: string;
  options: {value: any; label: string | undefined}[];
  value?: any;
  onChange?: (value: any, label: string) => void;
  divClassName?: string;
  selectClassName?: string;
  error?: any;
  configuration?: any;
  onChangeValue?: (value: any) => void;
  placeholder?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const SelectOptions: FC<ComponentProps> = ({
  label,
  options,
  value,
  onChange,
  name,
  divClassName,
  error,
  selectClassName,
  configuration,
  onChangeValue,
  placeholder,
  isLoading,
  isClearable = true,
  disabled = false,
}) => {
  const classes = useStylesFromThemeFunction();

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e && e.value ? e.value : null, e.label);
    }
  };

  return (
    <div className={divClassName}>
      {label && <div className={classes.klaimSelectLabel}>{label}</div>}
      <Select
        className={selectClassName}
        styles={customStyles}
        isClearable={isClearable}
        name={name}
        options={options}
        onChange={handleOnChange}
        value={options.find((o: any) => o.value === value)}
        defaultValue={options.find((o: any) => o.value === value)}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={disabled}
      />
      {error ? <p className={classes.inputError}>{error}</p> : null}
    </div>
  );
};

export default SelectOptions;
