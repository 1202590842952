import React from 'react';
import KlaimSearch from 'modules/Common/components/KlaimSearch';
import Button from 'modules/Common/components/Button';
import IsAuthorized from 'modules/Auth/v3/features/IsAuthorized';
import {useStylesFromThemeFunction, ComponentProps} from 'modules/Settings/components/TopMenu/TopMenu';

const TopMenu: React.FC<ComponentProps> = ({title, description, buttonTitle, requiredPermission, onButtonClick}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.topMenuWrapper}>
      <div className={classes.containerTextButton}>
        <div className={classes.topMenuContent}>
          <h2>{title}</h2>
          {description}
        </div>
        <div className={classes.menuButton}>
          <IsAuthorized requiredPermission={requiredPermission}>
            <Button title={buttonTitle} onClick={onButtonClick} />
          </IsAuthorized>
        </div>
      </div>
      <KlaimSearch placeholder="Search" />
    </div>
  );
};

export default TopMenu;
