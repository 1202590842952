import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Common/components-v2/KlaimModalv2/components/KlaimModalHeader/KlaimModalHeader';
import CloseIcon from 'assets/component/CloseIcon';
import {ModalContext} from '../KlaimModal/index';

interface ComponentProps {
  title: string;
  onClose?: () => void;
}

const KlaimModalHeader: React.FC<ComponentProps> = ({children, title, onClose}) => {
  const classes = useStylesFromThemeFunction();
  const {open, setOpen} = React.useContext(ModalContext);

  return (
    <div className={classes.container}>
      {!children && (
        <div className={classes.defaultContainer}>
          <h3>{title}</h3>
          <button
            onClick={() => {
              if (onClose) {
                onClose();
              }
              setOpen(!open);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      {children && children}
    </div>
  );
};

export default KlaimModalHeader;
