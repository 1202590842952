import React, {FC} from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {useDispatch} from 'react-redux';
import Section from './Section';
import vs from '../../../../../assets/vital-signs.jpg';
import {
  KlaimButton,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
} from '../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from './MidTableForm';
import {deleteService} from '../../../midTableSlice';

const ClaimActivities: FC<any> = ({data}) => {
  const classes = useStylesFromThemeFunction();
  const initialValues = {};
  const dispatch = useDispatch();

  const DATE_FORMAT = `DD MMM 'YY`;
  const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

  return (
    <Section title={'Claim Activities'} img={vs}>
      <KlaimForm
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({})}
        onSubmit={() => {}}
        id="midtable-form"
        customClass={classes.form}
      >
        <div
          className={classes.responsiveRow}
          style={{
            backgroundColor: '#F5F7FB',
            width: 'calc(100% + 32px)',
            marginLeft: '-16px',
            padding: '9px 16px',
            marginTop: -8,
          }}
        >
          <div className={classes.value} style={{flex: '1 1 10%'}}>
            INVOICE
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            CODE
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            CLINICIAN
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            STATUS
          </div>
          <div className={classes.value} style={{flex: '1 1 5%'}}>
            QTY
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            Service Desc
          </div>
          <div className={classes.value} style={{flex: '1 1 50%'}}>
            AMOUNTS IN SAR
          </div>
          {/* <div className={classes.label} style={{flex: '1 1 20%'}} /> */}
        </div>

        {data?.services?.map((el: any) => (
          <div
            className={classes.responsiveRow}
            style={{
              width: 'calc(100%)',
              marginLeft: '-16px',
              padding: '0px 16px',
              borderBottom: 'solid 1px #E7ECF5',
              marginTop: 0,
            }}
          >
            <div className={classes.label} style={{flex: '1 1 10%'}}>
              {el.invoiceNumber}
              <br />
              {formatDate(el.invoiceDate)}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              {el.serviceCode}
              <br />
              {el.serviceType}
              <br />
              {el.serviceTypeDescription}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%', fontWeight: 600}}>
              {data.doctorName}
              <br />
              <span style={{fontWeight: 400}}>{data.speciality?.name}</span>
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              <span className={classes.approvedStatus}>---</span>
            </div>
            <div className={classes.label} style={{flex: '1 1 5%'}}>
              {el.quantity}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              {el.serviceDescription}
            </div>
            <div className={classes.label} style={{flex: '1 1 50%'}}>
              <div className={classes.col}>
                <div className={classes.row}>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>Gross</div>
                    <div className={classes.label}>{el.grossAmount}</div>
                  </div>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>NET</div>
                    <div className={classes.label}>{el.netAmount}</div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>DISCOUNT</div>
                    <div className={classes.label}>{el.discountAmount}</div>
                  </div>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>VAT</div>
                    <div className={classes.label}>{el.vatAmount}</div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>COPAY</div>
                    <div className={classes.label}>{el.copaymentAmount}</div>
                  </div>
                  <div className={classes.spacedRow}>
                    <div className={classes.value} />
                    <div className={classes.label} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={classes.label} style={{flex: '1 1 20%'}}> */}
            {/*  <div className={classes.buttonsRow}> */}
            {/*    <button */}
            {/*      className={classes.delete} */}
            {/*      onClick={() => { */}
            {/*        dispatch(deleteService({id: el.id})); */}
            {/*      }} */}
            {/*    > */}
            {/*      Delete */}
            {/*    </button> */}
            {/*  </div> */}
            {/* </div> */}
          </div>
        ))}
      </KlaimForm>
    </Section>
  );
};
export default ClaimActivities;
