import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import {Row, Col} from 'react-grid-system';
import {useSelector} from 'react-redux';
import {Field, FieldProps, FormikHelpers} from 'formik';
import {useHistory, useParams} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import {useAppDispatch} from 'modules/App/store';
import {
  insuranceCompanyManagementSelect,
  createInsurance,
  getBranches,
  getTPAs,
  getInsurers,
  Payer,
  createCleanup,
  getInsuranceById,
  editInsurance,
} from 'modules/Settings/features/InsuranceCompanies/insuranceManagementSlice';
import {KlaimForm, KlaimFieldWrapper, KlaimInput, KlaimMultiSelect} from 'modules/Common/components-v2/KlaimForm';
import {OptionValue} from 'modules/Header/headerSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import * as Toast from 'modules/Common/utils/toast';
import InsuranceDropdown from 'modules/Insurance/features/InsuranceDropdown';
import {useStylesFromThemeFunction} from './insuranceCompaniesEdit';

export interface IValues {
  [field: string]: any;
}

const Component: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    error,
    insuranceData,
    isCreated,
    isLoading,
    branches: {
      data: {branches},
    },
    insurers,
    tpas,
  } = useSelector(insuranceCompanyManagementSelect);

  const {id}: {id: string} = useParams();

  const [selectedBranches, setSelectedBranches] = React.useState<Array<any>>([]);
  const [selectedInsurers, setSelectedInsurers] = React.useState<any>({});
  const [selectedTPAs, setSelectedTPAs] = React.useState<Array<any>>([]);

  const newTpa = insuranceData.insurances[0].tpas.map((tpa) => {
    return {
      label: tpa,
      value: tpa,
    };
  });

  const initialValues = {
    active: insuranceData.active || true,
    name: insuranceData.name || '',
    tpas: newTpa,
    insurances: {label: insuranceData.insurances[0].name, value: insuranceData.insurances[0].code} || [],
    branches: [{label: insuranceData.branch?.name, value: insuranceData.branch?.identifier}] || [],
  };

  const validationSchema = Yup.object({
    // active: Yup.boolean().required(),
    // name: Yup.string().required(),
    // tpas: Yup.array(Yup.string()),
    // insurances: Yup.array(Yup.string()),
    // branches: Yup.array(Yup.number()).required('Branch is required'),
  });

  const insuranceResult = insurers.find((ins) => ins.payerCode === selectedInsurers);
  const newTpas = selectedTPAs.map((tpa) => {
    return tpa.value;
  });

  const onSubmit: (
    values: IValues,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: IValues, formikHelpers) => {
    const newBranches = values.branches.map((branch: any) => {
      return parseInt(branch.value, 10);
    });

    const payload = {
      active: true,
      // specialityCode: '12312311111',
      branches: values.branches ? newBranches : [insuranceData.branch?.id],
      name: values.name,
      insurances: [
        {
          code: insuranceResult?.payerCode ? insuranceResult?.payerCode : insuranceData.insurances[0].code,
          name: insuranceResult?.name ? insuranceResult?.name : insuranceData.insurances[0].name,
          tpas: newTpas,
        },
      ],
      id,
    };

    dispatch(editInsurance({values, formikHelpers}));

    // dispatch(createInsurance({values, formikHelpers}));
  };

  React.useEffect(() => {
    dispatch(getBranches({}));
    // dispatch(getInsurers({}));
    dispatch(getInsuranceById({values: {id}}));
    dispatch(getTPAs({}));
  }, []);

  React.useEffect(() => {
    if (isCreated === true) {
      Toast.success('Insurance is added successfully', 'Success');
      history.push('/settings/insurance-companies');
    }
    return () => {
      dispatch(createCleanup());
    };
  }, [isCreated]);

  return (
    <KlaimForm
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
    >
      <Header
        title="Edit Insurance"
        backlink="/settings/insurance-companies"
        description={<p>Add additional insurance companies that have not been listed</p>}
        buttonType="submit"
        buttonTitle="Update"
        body={
          <div className={classes.bodyWrapper}>
            {isLoading ? (
              <div>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {error && (
                  <div className={classes.errorMessage}>{error.stack ? error.stack.error.message : error.message}</div>
                )}
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={12}>
                        <KlaimFieldWrapper name="name">
                          <KlaimInput label="Insurance Name" placeholder="34567468" />
                        </KlaimFieldWrapper>
                      </Col>
                      <Col md={12}>
                        <KlaimFieldWrapper name={'branches'}>
                          <KlaimMultiSelect
                            options={
                              branches
                                ? branches.map((branch) => {
                                    return {
                                      label: branch.name,
                                      value: branch.identifier,
                                    };
                                  })
                                : []
                            }
                            placeholder={'Select Branch'}
                            label="Choose Branch"
                          />
                        </KlaimFieldWrapper>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <KlaimFieldWrapper name="tpas" onChange={(e) => setSelectedTPAs(e)}>
                          <KlaimMultiSelect
                            optional
                            label="Select TPA/Receiver"
                            options={tpas.map((item: Payer) => {
                              const x: OptionValue = {label: item.name || '-', value: item.payerCode || '-'};
                              return x;
                            })}
                          />
                        </KlaimFieldWrapper>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <KlaimFieldWrapper name={'insurance'} onChange={(e) => setSelectedInsurers(e)}>
                          <InsuranceDropdown />
                        </KlaimFieldWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default Component;
