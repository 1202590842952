import React from 'react';
import {useHistory} from 'react-router-dom';

import klaimLogo from 'assets/klaim_logo.svg';
import forbidden from 'assets/forbidden_vector.svg';

import Button from 'modules/Common/components/Button';

import {useStylesFromThemeFunction} from './NotAuthorized';

const NotAuthorized: React.FC = () => {
  const history = useHistory();
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={klaimLogo} alt="klaimLogo" />
      </div>
      <div className={classes.vectorContainer}>
        <img src={forbidden} alt="statusCode" />
        <p>You don't have access to this page. Please contact your admin.</p>
      </div>
      <div className={classes.buttonContainer}>
        <Button title="Go Back" onClick={() => history.push('/')} />
      </div>
    </div>
  );
};

export default NotAuthorized;
