import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {IRoute} from 'interfaces/route.interface';
import PublicRoute from 'modules/Route/PublicRoute';
import PrivateRoute from 'modules/Route/PrivateRoute';
import {useAppSelector} from '../../App/store';
import {authSelect} from '../../Auth/v3/features/Auth/authSlice';

const RouteWithSubRoutes: React.FC<IRoute> = ({
  path,
  exact,
  component: Component,
  routes,
  routeType,
  requiredModule,
  requiredPermission,
  routeKey,
  redirect,
  ...rest
}) => {
  const {user} = useAppSelector(authSelect);
  if (redirect && user.organization.country !== 'KSA') {
    return <Redirect to={redirect} />;
  }
  if (routeType === 'public') {
    return (
      <PublicRoute
        path={path}
        exact={exact}
        component={Component}
        routes={routes}
        requiredModule={requiredModule}
        requiredPermission={requiredPermission}
        routeKey={routeKey}
        {...rest}
      />
    );
  }
  if (routeType === 'private') {
    return (
      <PrivateRoute
        path={path}
        exact={exact}
        component={Component}
        routes={routes}
        requiredModule={requiredModule}
        requiredPermission={requiredPermission}
        routeKey={routeKey}
        {...rest}
      />
    );
  }
  return (
    <Route
      path={path}
      exact={exact}
      key={routeKey}
      render={(props) => {
        return <Component {...props} routes={routes} />;
      }}
    />
  );
};
export default RouteWithSubRoutes;
