export interface PaymentDetailsID {
  paymentId: string | any;
  providerId: string;
}

export interface InitialState {
  data?: PaymentDetailsById;
  isLoading: boolean;
  error?: PaymentDetailsError;
}

export interface PaymentDetailsError {
  statusCode: number;
  status: number;
  message: string;
}

export interface DefaultAttributes {
  attribute?: any;
}

export interface PaymentDetailsById {
  id: string;
  firstReconciliationDate: string;
  status: string;
  payerId: string;
  branch: string;
  type: string;
  reference: string;
  billingPeriod: string;
  amount: string;
  response: any;
  claim: string;
}

export interface PaymentDetails {
  id: string;
  firstReconciliationDate: string;
  status: string;
  payerId: string;
  branch: string;
  type: string;
  reference: string;
  billingPeriod: string;
  amount: string;
  response: any;
  claim: string;
}

export const PaymentNoticeInitialValues = {paymentAmount: null, paymentStatus: null, paymentDate: null};
