import Button from 'modules/Common/components/Button';
import {FC} from 'react';
import backArrow from 'assets/back-arrow.svg';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {Link} from 'react-router-dom';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    backBtn: {
      cursor: 'pointer',
      marginRight: 26,
    },
    bodyWrapper: {
      borderTop: `1px solid ${Colors.grayLight}`,
      padding: '40px 90px',
    },
    menuButton: {
      '& button': {
        height: '42px',
        marginBottom: 10,
      },
    },
    titleWrapper: {
      display: 'flex',
    },
    topMenuContent: {
      '& h2': {
        color: Colors.blueGrayDark,
        fontSize: '22px',
        fontWeight: 'bold',
        letterSpacing: '0.22px',
        marginTop: 0,
      },
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        marginBottom: '28px',
        width: '450px',
      },
      '& span': {
        color: Colors.purple,
        cursor: 'pointer',
      },
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 36px',
      width: '100%',
    },
    topMenuWrapper: {
      background: `${Colors.white} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      boxShadow: `0px 0.5px 2px ${Colors.RMSC.shadow}`,
      display: 'flex',
      flex: '1 1',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: '30px',
    },
    klaimBtn: {
      '&:hover': {
        cursor: 'pointer',
      },
      textDecoration: 'none',
      alignItems: 'center',
      background: '#6135fb',
      border: 'none',
      borderRadius: 10,
      color: 'white',
      display: 'flex',
      fontWeight: 400,
      justifyContent: 'center',
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
      marginBottom: 10,
    },
  };
});

interface ComponentProps {
  title: string;
  description: any;
  buttonTitle?: string;
  buttonType?: 'button' | 'submit' | 'reset' | 'link' | undefined;
  body?: any;
  backlink: string;
  proceedlink?: string;
  bodyClass?: string;
  isLoading?: boolean;
}

const SubHeader: FC<ComponentProps> = ({
  title,
  description,
  buttonTitle,
  buttonType = 'button',
  body,
  backlink,
  proceedlink,
  bodyClass,
  isLoading,
}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.topMenuWrapper}>
      <div className={classes.topMenuContent}>
        <div className={classes.titleWrapper}>
          <div className={classes.backBtn}>
            <Link to={backlink}>
              <img src={backArrow} alt="back-arrow" />
            </Link>
          </div>
          <div>
            <h2>{title}</h2>
            {description}
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className={classes.menuButton}>
            {proceedlink && buttonType === 'link' ? (
              <Link className={classes.klaimBtn} to={proceedlink || '/'}>
                {buttonTitle || 'Submit'}
              </Link>
            ) : (
              <Button title={buttonTitle || 'Submit'} type="submit" />
            )}
          </div>
        )}
      </div>
      <div className={bodyClass || classes.bodyWrapper}>{body}</div>
    </div>
  );
};

export default SubHeader;
