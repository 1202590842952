import React from 'react';

export interface IStepper {
  step: number;
  setStep: (index: number) => void;
  totalSteps: number;
  setTotalStep: (index: number) => void;
}

interface ComponentProps {
  initialStep: number;
  setStep: (index: number) => void;
}

export const StepperContext = React.createContext<IStepper>({
  step: 0,
  totalSteps: 0,
  setStep: () => {},
  setTotalStep: () => {},
});

const KlaimStepper: React.FC<ComponentProps> = ({initialStep = 0, setStep, children}) => {
  const [totalSteps, setTotalStep] = React.useState(0);
  React.useEffect(() => {
    return () => {
      setStep(0);
    };
  }, []);
  return (
    <StepperContext.Provider value={{step: initialStep, setStep, totalSteps, setTotalStep}}>
      {children}
    </StepperContext.Provider>
  );
};

export default KlaimStepper;
