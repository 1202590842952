import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {remove as removeStorage, set as setStorage} from 'local-storage';
import {RootState} from 'modules/App/store/index';
import {ClaimsBreakdownDateTypes, DateTypesToUse} from 'modules/Dashboard/api/strings';
import {NavigationMenuItem} from 'interfaces/header.interfaces';
import {login} from 'modules/Auth/v3/features/Login/loginSlice';
import {logout} from 'modules/Auth/v3/features/Auth/authSlice';
import {statusOptions} from 'modules/Common/constants/DropdownOptions/index';

export interface Branches {
  value: string;
  label: string;
}

export interface Status {
  value: string;
  label: string;
}

export interface OptionValue {
  label: string;
  value: string;
  leftIcon?: string;
  rightIcon?: string;
}

interface InitialState {
  selectedBranches: OptionValue[];
  selectedDateType: OptionValue;
  rejectionSelectedDateType: OptionValue;
  rejectionDateOptions: OptionValue[];
  startDate: string;
  endDate: string;
  visitStartDate: string;
  visitEndDate: string;
  dateTypeOptions: OptionValue[];
  statusOptions: OptionValue[];
  selectedStatus: OptionValue[];
  selectedPayer: OptionValue[];
  selectedReceiver: OptionValue[];
  selectedTPA: OptionValue[];
  selectedType: OptionValue[];
  selectedSpecialties: OptionValue[];
  selectedVisits: OptionValue[];
  selectedSynced: OptionValue[];
  dateTypeFilter: boolean;
  rejectionDateTypeFilter: boolean;
  dateTypesToUse: DateTypesToUse;
  staticDateType: string;
  statusFilter: boolean;
  payersFilter: boolean;
  receiversFilter: boolean;
  TPAFilter: boolean;
  typesFilter: boolean;
  specialtiesFilter: boolean;
  visitFilter: boolean;
  syncedFilter: boolean;
  dateRangeFilter: boolean;
  filterByDate: boolean;
  optionsFilter: boolean;
  navOptions: NavigationMenuItem[];
  search: boolean;
  branchesFilter: boolean;
  navigation: boolean;
  searchInput: string;
  dateRangeFilterMonth: boolean;
  searchRef: boolean;
  searchRefValue: string;
  searchErxList: boolean;
  searchErx: string;
  searchEpisode: boolean;
  searchEpisodeQuery: string;
  erxStatusSelect: boolean;
  erxStatusOptions: OptionValue[];
  selectedErxStatus: OptionValue[];
  requestAuthorizationUI: boolean;
  dispenseAuthorzationUI: boolean;
  insurersFilterUI: boolean;
  selectedErxInsurer: OptionValue[];
  dateRangeFilterDays: boolean;
  visitDateFilter: boolean;
  eligibilityButtons: boolean;
  searchParams: boolean;
  preAuthButtons: boolean;
  claimButtons: boolean;
  paymentButton: boolean;
  submitButton: boolean;
  submitAllButton: boolean;
  submitFormButton: boolean;
  paymentModal: boolean;
  submitOnClick: boolean;
  submitAllOnClick: boolean;
  isNavigationButtonHidden: boolean;
}

const DATE_RANGE = 'dateRange';
const SELECTED_BRANCHES = 'selectedBranches';
const SELECTED_DATETYPE = 'dateType';
const USER_BRANCHES = 'userBranches';
const CLAIM_DRAFT = 'currentDraft';

let dateTypeOptions = [
  {label: ClaimsBreakdownDateTypes.EncounterDateLabel, value: ClaimsBreakdownDateTypes.EncounterDate},
  {label: ClaimsBreakdownDateTypes.SubmissionDateLabel, value: ClaimsBreakdownDateTypes.SubmissionDate},
];

const rejectionDateOptions = [
  {label: ClaimsBreakdownDateTypes.EncounterDateLabel, value: ClaimsBreakdownDateTypes.EncounterDate},
  {label: ClaimsBreakdownDateTypes.SubmissionDateLabel, value: ClaimsBreakdownDateTypes.SubmissionDate},
  {label: ClaimsBreakdownDateTypes.RemittanceDateLabel, value: ClaimsBreakdownDateTypes.RemittanceDate},
];

const ageingDateTypeOptions = [
  {label: ClaimsBreakdownDateTypes.SubmissionDateLabel, value: ClaimsBreakdownDateTypes.SubmissionDate},
  {label: ClaimsBreakdownDateTypes.LastSubmissionDateLabel, value: ClaimsBreakdownDateTypes.LastSubmissionDate},
];

const erxStatusOptions = [
  {
    label: 'Requesting_Authorization',
    value: 'requesting_authorization',
  },
  {
    label: 'Authorized',
    value: 'authorized',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Dispensed',
    value: 'dispensed',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
];

const initialState: InitialState = {
  navOptions: [],
  dateTypeOptions,
  statusOptions,
  selectedBranches: [],
  selectedDateType: dateTypeOptions[0],
  rejectionSelectedDateType: rejectionDateOptions[0],
  rejectionDateOptions,
  startDate: dayjs().subtract(11, 'months').format('M/D/YYYY'),
  endDate: dayjs().format('M/D/YYYY'),
  visitStartDate: dayjs().subtract(11, 'months').format('M/D/YYYY'),
  visitEndDate: dayjs().format('M/D/YYYY'),
  selectedStatus: [],
  selectedPayer: [],
  selectedReceiver: [],
  selectedTPA: [],
  selectedType: [],
  selectedSpecialties: [],
  selectedSynced: [],
  selectedVisits: [],
  dateTypeFilter: false,
  dateTypesToUse: DateTypesToUse.Default,
  staticDateType: '',
  statusFilter: false,
  payersFilter: false,
  receiversFilter: false,
  TPAFilter: false,
  typesFilter: false,
  specialtiesFilter: false,
  visitFilter: false,
  syncedFilter: false,
  dateRangeFilter: false,
  filterByDate: false,
  optionsFilter: false,
  branchesFilter: false,
  search: false,
  navigation: false,
  searchInput: '',
  dateRangeFilterMonth: false,
  searchRef: false,
  searchRefValue: '',
  searchErxList: false,
  searchErx: '',
  searchEpisode: false,
  searchEpisodeQuery: '',
  erxStatusSelect: false,
  erxStatusOptions,
  selectedErxStatus: [],
  requestAuthorizationUI: false,
  dispenseAuthorzationUI: false,
  insurersFilterUI: false,
  selectedErxInsurer: [],
  dateRangeFilterDays: false,
  visitDateFilter: false,
  rejectionDateTypeFilter: false,
  eligibilityButtons: false,
  searchParams: false,
  preAuthButtons: false,
  claimButtons: false,
  paymentButton: false,
  paymentModal: false,
  isNavigationButtonHidden: false,
  submitButton: false,
  submitAllButton: false,
  submitFormButton: false,
  submitOnClick: false,
  submitAllOnClick: false,
};
const headerSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSelectedBranches: (state, action) => {
      setStorage(SELECTED_BRANCHES, JSON.stringify(action.payload));
      state.selectedBranches = action.payload;
    },
    setSelectedDateType: (state, action) => {
      setStorage(SELECTED_DATETYPE, JSON.stringify(action.payload));
      state.selectedDateType = action.payload;
    },
    setRejectionSelectedDateType: (state, action) => {
      setStorage(SELECTED_DATETYPE, JSON.stringify(action.payload));
      state.rejectionSelectedDateType = action.payload;
    },
    setDateRange: (state, action) => {
      state.startDate = action.payload.start;
      state.endDate = action.payload.end;
    },
    setVisitDateRange: (state, action) => {
      state.visitStartDate = action.payload.start;
      state.visitEndDate = action.payload.end;
    },
    setClinicianDateRange: (state, action) => {
      state.startDate = action.payload.start;
      state.endDate = action.payload.end;
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
    setSelectedPayer: (state, action) => {
      state.selectedPayer = action.payload;
    },
    setSelectedReceiver: (state, action) => {
      state.selectedReceiver = action.payload;
    },
    setSelectedTPA: (state, action) => {
      state.selectedTPA = action.payload;
    },
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },
    setSelectedSpecialties: (state, action) => {
      state.selectedSpecialties = action.payload;
    },
    setSelectedVisit: (state, action) => {
      state.selectedVisits = action.payload;
    },
    setSelectedSynced: (state, action) => {
      state.selectedSynced = action.payload;
    },
    setRequiredFilters: (state, action) => {
      const {
        dateTypeFilter,
        dateTypesToUse,
        staticDateType,
        statusFilter,
        payersFilter,
        receiversFilter,
        TPAFilter,
        typesFilter,
        specialtiesFilter,
        visitFilter,
        syncedFilter,
        dateRangeFilter,
        filterByDate,
        optionsFilter,
        branchesFilter,
        search,
        navOptions,
        dateRangeFilterMonth,
        searchByRef,
        searchPharmacyList,
        erxStatusFilter,
        requestAuthorizationButton,
        dispenseDrugButton,
        insurersFilter,
        dateRangeFilterDays,
        visitDateFilter,
        rejectionDateTypeFilter,
        eligibilityButtons,
        searchParams,
        preAuthButtons,
        claimButtons,
        paymentButton,
        submitButton,
        submitAllButton,
        submitFormButton,
        paymentModal,
        searchEpisodeQuery,
        searchEpisode,
      } = action.payload;
      if (dateTypesToUse === DateTypesToUse.Ageing) {
        dateTypeOptions = ageingDateTypeOptions;
      }
      const [firstOption] = dateTypeOptions;
      state.selectedDateType = firstOption;
      state.dateTypeOptions = dateTypeOptions;
      state.dateTypeFilter = dateTypeFilter || false;
      state.rejectionDateTypeFilter = rejectionDateTypeFilter || false;
      state.dateTypesToUse = dateTypesToUse || DateTypesToUse.Default;
      state.staticDateType = staticDateType || '';
      state.statusFilter = statusFilter || false;
      state.payersFilter = payersFilter || false;
      state.receiversFilter = receiversFilter || false;
      state.TPAFilter = TPAFilter || false;
      state.typesFilter = typesFilter || false;
      state.specialtiesFilter = specialtiesFilter || false;
      state.visitFilter = visitFilter || false;
      state.syncedFilter = syncedFilter || false;
      state.dateRangeFilter = dateRangeFilter || false;
      state.filterByDate = filterByDate || false;
      state.optionsFilter = optionsFilter || false;
      state.branchesFilter = branchesFilter || false;
      state.search = search || false;
      state.dateRangeFilterMonth = dateRangeFilterMonth || false;
      state.searchRef = searchByRef || false;
      state.searchErxList = searchPharmacyList || false;
      state.erxStatusSelect = erxStatusFilter || false;
      state.requestAuthorizationUI = requestAuthorizationButton || false;
      state.dispenseAuthorzationUI = dispenseDrugButton || false;
      state.insurersFilterUI = insurersFilter || false;
      state.dateRangeFilterDays = dateRangeFilterDays || false;
      state.visitDateFilter = visitDateFilter || false;
      state.eligibilityButtons = eligibilityButtons || false;
      state.searchParams = searchParams || false;
      state.preAuthButtons = preAuthButtons || false;
      state.claimButtons = claimButtons || false;
      state.paymentButton = paymentButton || false;
      state.submitButton = submitButton || false;
      state.submitAllButton = submitAllButton || false;
      state.submitFormButton = submitFormButton || false;
      state.paymentModal = paymentModal || false;
      state.searchEpisode = searchEpisode || false;
      state.searchEpisodeQuery = searchEpisodeQuery || false;
    },
    setNavOptions: (state, action) => {
      const {navOptions} = action.payload;
      state.navOptions = navOptions || [];
    },
    setNavButton: (state, action) => {
      state.isNavigationButtonHidden = action.payload;
    },
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
    searchByRefNumber: (state, action) => {
      state.searchRefValue = action.payload;
    },
    searchErxByKeyword: (state, action) => {
      state.searchErx = action.payload;
    },
    searchEpisodeByKeyword: (state, action) => {
      state.searchEpisodeQuery = action.payload;
    },
    setSelectedErxStatus: (state, action) => {
      state.selectedErxStatus = action.payload;
    },
    setSelectedErxInsurers: (state, action) => {
      state.selectedErxInsurer = action.payload;
    },
    setModalVisibility: (state, action) => {
      state.paymentModal = action.payload;
    },
    setSubmitButtonOnClick: (state, action) => {
      state.submitOnClick = action.payload;
    },
    setSubmitAllButtonOnClick: (state, action) => {
      state.submitAllOnClick = action.payload;
    },
    selectedStatusCleanup: (state) => {
      state.selectedStatus = [];
    },
    selectedPayerCleanup: (state) => {
      state.selectedPayer = [];
    },
    selectedReceiverCleanup: (state) => {
      state.selectedReceiver = [];
    },
    selectedTPACleanup: (state) => {
      state.selectedTPA = [];
    },
    selectedTypeCleanup: (state) => {
      state.selectedType = [];
    },
    selectedSpecialtiesCleanup: (state) => {
      state.selectedSpecialties = [];
    },
    selectedVisitCleanup: (state) => {
      state.selectedVisits = [];
    },
    selectedSyncedCleanup: (state) => {
      state.selectedVisits = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state) => {
      // state = initialState;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.navOptions = [];
      state.statusOptions = [];
      state.selectedBranches = [];
      state.startDate = dayjs().subtract(11, 'months').format('M/D/YYYY');
      state.endDate = dayjs().format('M/D/YYYY');
      state.selectedStatus = [];
      state.selectedTPA = [];
      state.selectedPayer = [];
      state.selectedReceiver = [];
      state.selectedType = [];
      state.selectedSpecialties = [];
      state.selectedVisits = [];
      state.selectedSynced = [];
      state.dateTypeFilter = false;
      state.dateTypesToUse = DateTypesToUse.Default;
      state.staticDateType = '';
      state.payersFilter = false;
      state.receiversFilter = false;
      state.TPAFilter = false;
      state.typesFilter = false;
      state.specialtiesFilter = false;
      state.syncedFilter = false;
      state.statusFilter = false;
      state.dateRangeFilter = false;
      state.filterByDate = false;
      state.optionsFilter = false;
      state.branchesFilter = false;
      state.search = false;
      state.navigation = false;
      state.searchInput = '';
      state.dateRangeFilterMonth = false;
      state.searchRef = false;
      state.searchRefValue = '';
      state.searchErxList = false;
      state.searchErx = '';
      state.searchEpisode = false;
      state.searchEpisodeQuery = '';
      state.erxStatusSelect = false;
      state.selectedErxStatus = [];
      state.requestAuthorizationUI = false;
      state.dispenseAuthorzationUI = false;
      state.insurersFilterUI = false;
      state.selectedErxInsurer = [];
      state.dateRangeFilterDays = false;
      state.visitDateFilter = false;
      state.rejectionDateTypeFilter = false;
      state.eligibilityButtons = false;
      state.searchParams = false;
      state.paymentModal = false;
      removeStorage(DATE_RANGE);
      removeStorage(SELECTED_BRANCHES);
      removeStorage(SELECTED_DATETYPE);
      removeStorage(USER_BRANCHES);
      removeStorage(CLAIM_DRAFT);
    });
  },
});

export const {
  setSelectedBranches,
  setRequiredFilters,
  setSelectedStatus,
  setSelectedPayer,
  setSelectedReceiver,
  setSelectedTPA,
  setSelectedType,
  setSelectedSpecialties,
  setSelectedVisit,
  setSelectedSynced,
  setSelectedDateType,
  setDateRange,
  setVisitDateRange,
  setNavOptions,
  setSearchInput,
  searchByRefNumber,
  searchErxByKeyword,
  searchEpisodeByKeyword,
  setSelectedErxStatus,
  setSelectedErxInsurers,
  setClinicianDateRange,
  setRejectionSelectedDateType,
  setModalVisibility,
  setSubmitButtonOnClick,
  setSubmitAllButtonOnClick,
  setNavButton,
  selectedStatusCleanup,
} = headerSlice.actions;
export const headerSelect = (state: RootState) => state.header;

export default headerSlice.reducer;
