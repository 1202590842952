import React from 'react';
import {EL} from '@klaim-ai/klaim-interfaces';

import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import {KlaimTab, KlaimTabList, KlaimTabPanel, KlaimTabPanels, KlaimTabs} from 'modules/Common/components/KlaimTabs';
import dayjs from 'dayjs';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import {get} from 'lodash';
import CoveragePlan from './CoveragePlan';
import {useAppDispatch, useAppSelector} from '../../../../../App/store';
import {getInsurance, insuranceSelect} from '../../../../../Insurance/insuranceSlice';

const DATE_FORMAT = `DD MMM YYYY`;
const formatDate = (date) => (date ? dayjs(date).format(DATE_FORMAT) : null);
interface ComponentProps {
  eligibility?: EL.Model.Eligibility;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      padding: 20,
      marginTop: 20,
    },
    title: {
      marginBottom: 20,
      fontSize: 22,
      fontWeight: 'bold',
      color: '#2C273C',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      '& h1': {
        fontSize: 15,
      },
      '& button': {
        fontWeight: 500,
        letterSpacing: 1.2,
      },
    },
  };
});

const Insurance: React.FC<ComponentProps> = ({eligibility}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {payer} = useAppSelector(insuranceSelect);
  React.useEffect(() => {
    if (eligibility?.payerId) {
      dispatch(
        getInsurance({
          id: eligibility?.payerId,
        }),
      );
    }
  }, [eligibility?.payerId]);

  const getInsuranceName = () => {
    if (payer) {
      if (payer.data.length > 0) {
        return payer.data[0].name;
      }
    }
    return '';
  };
  const el = eligibility as any;
  return (
    <KlaimContainer>
      <KlaimContainer>
        <KlaimContainer>
          <h2 className={classes.title}>Insurance</h2>
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', gap: '1rem'}}>
            <KlaimValue direction="column" label="Insurance Name" value={getInsuranceName() || ''} />
            <KlaimValue direction="column" label="Eligibility Response" value={eligibility?.status || ''} />
            <KlaimValue direction="column" label="Site Eligibility" value={el?.siteEligibility || ''} />
            <KlaimValue direction="column" label="Response identifier" value={el?.responseIdentifier?.value} />
          </div>
          <br />
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', gap: '1rem'}}>
            <KlaimValue direction="column" label="Message" value={el?.message || ''} />
            <KlaimValue
              direction="column"
              label="Policy holder"
              value={get(el, 'coverages[0].policyHolderDetails.policyHolderName')}
            />
            <KlaimValue
              direction="column"
              label="Date of Birth"
              value={eligibility?.patient?.dateOfBirth ? formatDate(eligibility?.patient?.dateOfBirth) : ''}
            />
          </div>
          {eligibility && eligibility.coverages.length > 1 && (
            <div style={{backgroundColor: Colors.grayBackground, padding: 10, borderRadius: 10}}>
              <p style={{fontSize: 13}}>
                <span style={{fontWeight: 700}}>Note:</span> This user has multiple coverage plans. You can navigate
                them by clicking the tabs below.
              </p>
            </div>
          )}
        </KlaimContainer>
      </KlaimContainer>
      <KlaimContainer>
        <KlaimTabs>
          <KlaimTabList>
            {eligibility &&
              eligibility.coverages.map((coverage: EL.Model.EligibilityCoverage, index: number) => (
                <KlaimTab key={index}>{`Coverage Plan ${index + 1}`}</KlaimTab>
              ))}
          </KlaimTabList>
          <KlaimTabPanels>
            {eligibility &&
              eligibility.coverages.map((coverage: EL.Model.EligibilityCoverage, index: number) => (
                <KlaimTabPanel key={index}>
                  <CoveragePlan coverage={coverage} />
                </KlaimTabPanel>
              ))}
          </KlaimTabPanels>
        </KlaimTabs>
      </KlaimContainer>
    </KlaimContainer>
  );
};

export default Insurance;
