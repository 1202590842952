import React from 'react';
import {FieldArray, FieldArrayRenderProps, useFormikContext} from 'formik';
import {Col, Row} from 'react-grid-system';
import {
  KlaimCollapsible,
  KlaimCollapsibleHeader,
  KlaimCollapsibleBody,
} from 'modules/Common/components-v2/KlaimCollapsible';
import {
  KlaimIconButton,
  KlaimButton,
  KlaimDropdownSearch,
  KlaimRadioSelect,
  KlaimInput,
} from 'modules/Common/components-v2/KlaimForm';
import RemovePractitionIcon from 'assets/component/RemovePractitionIcon';
import {Colors} from 'modules/Common/constants/Colors';
import {itemSystems} from 'modules/Common/constants/DropdownOptions';
import {IActivityForm, IItem} from 'interfaces/pre-auth.interface';
import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import ActivityCodeDropdown from 'modules/Activity/features/ActivityCodeDropdown';
import RemoveIcon from 'assets/component/RemoveIcon';
import {useStylesFromThemeFunction} from './SubActivity';

const SubActivity: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const {values} = useFormikContext<IActivityForm>();
  const [isShadowBillingEnabled, setIsShadowBillingEnabled] = React.useState('No');
  const activityTypeName = (value: any) => {
    const res = itemSystems.find((item: any) => item.value === value);
    if (res) {
      return res;
    }
    return {
      label: '',
    };
  };

  const [itemSystem, setItemSystem] = React.useState({
    value: '',
    error: '',
  });
  const [itemCode, setItemCode] = React.useState({
    value: '',
    error: '',
  });
  const [itemCodeSfda, setItemCodeSfda] = React.useState({
    value: '',
    error: '',
  });
  const [itemCodeHcp, setItemCodeHcp] = React.useState({
    value: '',
    error: '',
  });
  const [itemQty, setItemQty] = React.useState({
    value: 0,
    error: '',
  });
  const [itemUnitPrice, setItemUnitPrice] = React.useState({
    value: 0,
    error: '',
  });
  const [itemTax, setItemTax] = React.useState({
    value: 0,
    error: '',
  });

  return (
    <FieldArray
      name={'item.detail'}
      render={(arrayHelpers: FieldArrayRenderProps) => (
        <div className={classes.container}>
          {itemSystem.error && <span>{itemSystem.error}</span>}
          <ActivityCodeDropdown
            initialValue={itemCode.value}
            onChange={(value) => setItemCode({...itemCode, value, error: ''})}
          />
          {itemCode.error && <span>{itemCode.error}</span>}
          <KlaimRadioSelect
            label="Enable Shadow Billing?"
            selections={[
              {label: 'Yes', value: 'Yes'},
              {label: 'No', value: 'No'},
            ]}
            value={isShadowBillingEnabled}
            onChange={(value) => setIsShadowBillingEnabled(value)}
          />
          {isShadowBillingEnabled === 'Yes' && (
            <div>
              <KlaimDropdownSearch
                variant="secondary"
                options={[]}
                label="Select SFDA Code"
                placeholder="994IFDFHG90395..."
                value={itemCodeSfda.value}
                onChange={(value) => setItemCodeSfda({...itemCodeSfda, value, error: ''})}
              />
              <div className={classes.orContainer}>
                <hr />
                <span>Or</span>
                <hr />
              </div>
              <KlaimInput
                variant="secondary"
                label="Enter HCP Code"
                placeholder="994IFDFHG90395..."
                value={itemCodeHcp.value}
                onChange={(value) => setItemCodeHcp({...itemCodeHcp, value, error: ''})}
              />
            </div>
          )}
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem'}}>
            <KlaimInput
              type="number"
              variant="secondary"
              placeholder="Quantity"
              value={itemQty.value}
              onChange={(value) => setItemQty({...itemQty, value, error: ''})}
            />
            <KlaimInput
              type="number"
              variant="secondary"
              placeholder="Unit Price"
              value={itemUnitPrice.value}
              onChange={(value) => setItemUnitPrice({...itemUnitPrice, value, error: ''})}
            />
            <KlaimInput
              type="number"
              variant="secondary"
              placeholder="Item Tax"
              value={itemTax.value}
              onChange={(value) => setItemTax({...itemTax, value, error: ''})}
            />
          </div>
          <KlaimButton
            type={'button'}
            style={{width: '20%'}}
            variant="primary-outline"
            onClick={() => {
              // if (itemSystem.value === '') {
              //   setItemSystem({
              //     ...itemSystem,
              //     error: 'Activity Type is Required',
              //   });
              // }
              if (itemCode.value === '') {
                setItemCode({
                  ...itemCode,
                  error: 'Activity Code is Required',
                });
              }
              if (itemSystem.value === '' || itemSystem.value === '') {
                return;
              }
              arrayHelpers.push({
                itemSystem: itemSystem.value,
                itemCode: itemCode.value,
                itemCodeSfda: itemCodeSfda.value,
                itemTax: itemTax.value,
                itemQty: itemQty.value,
                itemUnitPrice: itemUnitPrice.value,
              });
            }}
          >
            Add
          </KlaimButton>
          <div className={classes.addedContainer}>
            {values.item.detail &&
              values.item.detail.map((act: IItem, index: number) => (
                <KlaimCollapsible
                  style={{border: `1px solid ${Colors.grayLight}`, marginTop: 10}}
                  variant="secondary"
                  key={index}
                >
                  <KlaimCollapsibleHeader>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div
                        style={{
                          color: '#6135F9',
                          fontSize: 15,
                          fontWeight: 600,
                          textTransform: 'capitalize',
                        }}
                      >
                        {`${activityTypeName(act.itemSystem).label}`}
                      </div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: 4, fontSize: 12}}>Remove</span>
                        <KlaimIconButton
                          style={{
                            backgroundColor: '#000000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 2,
                          }}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <RemoveIcon fill={'white'} />
                        </KlaimIconButton>
                      </div>
                    </div>
                  </KlaimCollapsibleHeader>
                  <KlaimCollapsibleBody>
                    <div>
                      <Row nogutter style={{marginTop: 10}}>
                        <Col xs={6} md={2}>
                          <KlaimValue label={'ACTIVITY CODE'} value={act.itemCode || ''} direction={'column'} />
                        </Col>
                      </Row>
                      <Row nogutter style={{marginTop: 10}}>
                        <Col xs={6} md={2}>
                          <KlaimValue
                            label={'SHADOW BILLING'}
                            value={act.shadowBilling === 'Yes' ? 'Enabled' : 'Disabled'}
                            direction={'column'}
                          />
                        </Col>
                      </Row>
                      <Row nogutter style={{marginTop: 10}}>
                        {act.bodySite?.code && (
                          <Col xs={6} md={2}>
                            <KlaimValue label={'BODY SITE'} value={act.bodySite?.code || ''} direction={'column'} />
                          </Col>
                        )}

                        {act.subSite?.code && (
                          <Col xs={6} md={2}>
                            <KlaimValue label={'SUB SITE'} value={act.subSite?.code || ''} direction={'column'} />
                          </Col>
                        )}
                      </Row>

                      <Row nogutter style={{marginTop: 10}}>
                        <Col xs={6} md={2}>
                          <KlaimValue label={'CODE'} value={act.itemCode || ''} direction={'column'} />
                        </Col>
                        {act.itemQty && (
                          <Col xs={6} md={2}>
                            <KlaimValue label={'QUANTITY'} value={act.itemQty.toString()} direction={'column'} />
                          </Col>
                        )}
                        {act.itemUnitPrice && (
                          <Col xs={6} md={2}>
                            <KlaimValue
                              label={'UNIT PRICE'}
                              value={act.itemUnitPrice.toString()}
                              direction={'column'}
                            />
                          </Col>
                        )}
                        {act.itemTax && (
                          <Col xs={6} md={2}>
                            <KlaimValue label={'ITEM TAX'} value={act.itemTax.toString()} direction={'column'} />
                          </Col>
                        )}
                        {act.itemPatientShare && (
                          <Col xs={6} md={2}>
                            <KlaimValue
                              label={'PATIENT SHARE'}
                              value={act.itemPatientShare.toString()}
                              direction={'column'}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </KlaimCollapsibleBody>
                </KlaimCollapsible>
              ))}
          </div>
        </div>
      )}
    />
  );
};

export default SubActivity;
