import React from 'react';
import {Field, FieldProps} from 'formik';
import {useStylesFromThemeFunction} from 'modules/Common/components/KlaimCheckbox/KlaimCheckbox';

interface ComponentProps {
  name: string;
  label: string;
}

const KlaimCheckbox: React.FC<ComponentProps> = ({name, label}) => {
  const classes = useStylesFromThemeFunction();
  const [clicked, setClicked] = React.useState(false);
  return (
    <Field name={name}>
      {({form: {values, setFieldValue}}: FieldProps) => {
        return (
          <div className={classes.container}>
            <input
              type="checkbox"
              onClick={() => {
                setClicked(!clicked);
                setFieldValue(name, !clicked);
              }}
            />
            <label>{label}</label>
          </div>
        );
      }}
    </Field>
  );
};

export default KlaimCheckbox;
