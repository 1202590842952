import React from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import TopMenu from 'modules/Settings/components/TopMenu';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  insuranceCompanyManagementSelect,
  getInsurances,
} from 'modules/Settings/features/InsuranceCompanies/insuranceManagementSlice';
import ListItem from 'modules/Settings/features/InsuranceCompanies/InsuranceCompaniesList/ListItem';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {useStylesFromThemeFunction} from '../InsuranceCompanies';

const CompaniesList: React.FC<RouteComponentProps> = ({history}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {
    companies: {data, total},
    isLoading,
    isDeleted,
  } = useAppSelector(insuranceCompanyManagementSelect);

  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [sortBy] = React.useState<string>('');
  const limit = 10;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const searchParam = query.get('search');

  React.useEffect(() => {
    dispatch(
      getInsurances({
        limit,
        offset: (offset - 1) * limit,
        search,
        sortBy,
      }),
    );
  }, [limit, offset, search, isDeleted]);

  React.useEffect(() => {
    setSearch(searchParam || '');
  }, [searchParam]);

  return (
    <div className={classes.cardWrapper}>
      <TopMenu
        title="Insurance Companies"
        description={<p>Look up, add and modify the insurances for your organization and facilities.</p>}
        buttonTitle="Add Insurance"
        inputPlaceholder="Search insurance"
        onButtonClick={() => history.push('/settings/insurance-companies/add')}
        // Todo: Apply permission
        // requiredPermission={permissionsConstant.add_branches.identifier}
      />
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={total}
        currentPage={offset}
        onPageChange={setOffset}
        label="insurance-companies"
      >
        <p className={classes.tableName}>Insurance List</p>
        <div className={classes.container}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <ul className={classes.table}>
              {data.length > 0 ? (
                data.map((insurance: any, index: number) => (
                  <ListItem key={insurance.id} insurance={insurance} index={index} offset={offset} limit={limit} />
                ))
              ) : (
                <div style={{paddingLeft: 30}}>
                  Add your first insurance company by clicking "Add Insurance Company"
                </div>
              )}
            </ul>
          )}
        </div>
      </KlaimPaginationContainer>
    </div>
  );
};

export default CompaniesList;
