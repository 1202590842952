import * as Yup from 'yup';

const patientSchema = Yup.object({
  name: Yup.string(),
  fullName: Yup.array().of(Yup.string()),
  dateOfBirth: Yup.string(),
  gender: Yup.string(),
  phone: Yup.string(),
  phoneNumber: Yup.string(),
  documentIdType: Yup.string(),
  documentId: Yup.string(),
  maritalStatus: Yup.string(),
  country: Yup.string().nullable(true),
  address: Yup.object({
    city: Yup.string(),
    country: Yup.string(),
    text: Yup.string(),
    use: Yup.string(),
  }).nullable(true),
  idNumber: Yup.string(),
});

const patientRelSchema = Yup.object({
  name: Yup.string(),
  dateOfBirth: Yup.string(),
  gender: Yup.string(),
  phone: Yup.string(),
  phoneNumber: Yup.string(),
  documentIdType: Yup.string(),
  documentId: Yup.string(),
  maritalStatus: Yup.string(),
  isValid: Yup.boolean(),
});

const lensSpecSchema = Yup.object({
  code: Yup.string(),
  eye: Yup.string(),
  cylinder: Yup.number(),
  sphere: Yup.number(),
  axis: Yup.number(),
  add: Yup.number(),
  power: Yup.number(),
  backCurve: Yup.number(),
  diameter: Yup.number(),
  duration: Yup.object({
    value: Yup.number(),
    code: Yup.string(),
  }),
  color: Yup.string(),
  brand: Yup.string(),
  note: Yup.array().of(Yup.object({text: Yup.string()})),
  prism: Yup.array().of(
    Yup.object({
      amount: Yup.number(),
      base: Yup.string(),
    }),
  ),
});

const itemSchema = Yup.array().of(
  Yup.object({
    itemId: Yup.string(),
    informationSequence: Yup.array().of(Yup.number()),
    diagnosisSequence: Yup.array().of(Yup.number()),
    itemCareTeamSeq: Yup.array().of(Yup.number()).required('ItemCareTeamSeq should not be empty'),
    itemIsPackage: Yup.boolean(),
    itemSystem: Yup.number(),
    itemTax: Yup.number(),
    itemPatientShare: Yup.number(),
    itemCode: Yup.string(),
    servicedDate: Yup.string().nullable(),
    servicedPeriod: Yup.object({
      start: Yup.string(),
      end: Yup.string(),
    }).nullable(),
    itemQty: Yup.number(),
    itemUnitPrice: Yup.number(),
    itemCodeHcp: Yup.string(),
    bodySite: Yup.object({
      system: Yup.number().typeError('Body site System must be a number').nullable(),
      code: Yup.string().typeError('Body site Code must be a string').nullable(),
    }),
    subSite: Yup.object({
      system: Yup.number().nullable(),
      code: Yup.string().nullable(),
    }),
    itemCodeSfda: Yup.string(),
    factor: Yup.number(),
    shadowBilling: Yup.string(),
  }),
);

const supportingInfoSchema = Yup.array().of(
  Yup.object({
    itemId: Yup.string(),
    category: Yup.string(),
    codeSystem: Yup.number(),
    code: Yup.string(),
    timingPeriod: Yup.object({
      start: Yup.string(),
      end: Yup.string(),
    }),
    timingDate: Yup.string(),
    reason: Yup.string(),
    reasonSystem: Yup.number(),
    valueBoolean: Yup.boolean(),
    valueString: Yup.string(),
    valueQuantity: Yup.number(),
    valueAttachment: Yup.object({
      title: Yup.string(),
      content: Yup.string(),
    }),
    valueUcumUnit: Yup.string(),
  }),
);

const careTeamSchema = Yup.object({
  code: Yup.string(),
  practitionerType: Yup.string(),
  practitionerCode: Yup.string(),
  specialty: Yup.string(),
  careTeamRoleCode: Yup.string(),
  practitioner: Yup.object({
    active: Yup.string(),
    address: Yup.string(),
    gender: Yup.string(),
    id: Yup.number(),
    identifier: Yup.string(),
    in_hospital: Yup.boolean(),
    license_number: Yup.string(),
    name: Yup.string(),
    phone: Yup.string(),
    providerId: Yup.string(),
    speciality: Yup.string(),
    roles: Yup.array().of(
      Yup.object({
        active: Yup.string(),
        code: Yup.string(),
        id: Yup.number(),
        name: Yup.string(),
        speciality: Yup.string(),
      }),
    ),
  }),
  onSite: Yup.boolean(),
  name: Yup.string(),
});

const claimSchema = Yup.object({
  relatedToPriorClaim: Yup.string(),
  relatedToPriorClaimRel: Yup.string(),
  claimType: Yup.string(),
  claimSubtype: Yup.string(),
  claimPriority: Yup.string(),
  visionPresciption: Yup.object({
    date: Yup.string(),
    prescriberIndex: Yup.number(),
    lensSpec: Yup.array().of(lensSpecSchema),
  }).nullable(true),
  supportingInfo: supportingInfoSchema,
  careTeam: Yup.array().of(careTeamSchema),
  diagnosis: Yup.array().of(
    Yup.object({
      diagCode: Yup.string(),
      diagType: Yup.string(),
      diagOnAdmission: Yup.string(),
    }),
  ),
  item: itemSchema,
  claimBillablePeriodStart: Yup.string(),
  claimBillablePeriodEnd: Yup.string(),
}).required('Claim is Required');

const coverageSchema = Yup.object({
  policyHolder: Yup.string(),
  organizationPolicyHolder: Yup.object({
    identifier: Yup.string(),
    name: Yup.string(),
  }).nullable(true),
  subscriberId: Yup.string().required('Subscriber is Required'),
  coverageRel: Yup.string().required('Coverage Rel is Required'),
  // schemeName: Yup.string(),
  // schemeClass: Yup.string(),
  member: Yup.object({
    id: Yup.string(),
    system: Yup.string(),
  }),
  costToBeneficiary: Yup.array().of(
    Yup.object({
      title: Yup.string(),
      type: Yup.string(),
      valueMoney: Yup.object({
        amount: Yup.number(),
        currency: Yup.string(),
      }),
      exception: Yup.array().of(
        Yup.object({
          type: Yup.string(),
          period: Yup.object({
            amount: Yup.number(),
            currency: Yup.string(),
          }),
        }),
      ),
      valueQuantity: Yup.string(),
    }),
  ),
});

const accidentSchema = Yup.object({
  date: Yup.date(),
  type: Yup.string(),
  locationAddress: Yup.string(),
});

const encounterSchema = Yup.object({
  encounterClass: Yup.string(),
  encounterStatus: Yup.string(),
  encounterServiceType: Yup.string(),
  encounterPriority: Yup.string(),
  encounterPeriodStart: Yup.date(),
  encounterPeriodEnd: Yup.date(),
  hospAdmitSource: Yup.string(),
  hospReAdmission: Yup.boolean(),
  hospdischargeDisposition: Yup.string(),
});

const episodeSchema = Yup.object({
  value: Yup.string(),
  system: Yup.string(),
});

const validationSchema = Yup.object({
  patient: patientSchema,
  patientRel: patientRelSchema,
  claim: claimSchema,
  patientId: Yup.string(),
  senderId: Yup.string(),
  providerId: Yup.string(),
  senderName: Yup.string(),
  receiverId: Yup.string(),
  payerId: Yup.string(),
  payerName: Yup.string(),
  type: Yup.string(),
  member: Yup.object({
    id: Yup.string(),
    system: Yup.string(),
    subsciberId: Yup.string(),
  }).nullable(true),
  coverage: coverageSchema,
  accident: accidentSchema,
  encounter: encounterSchema,
  episode: episodeSchema,
});

export default validationSchema;
