import styled, {css} from 'styled-components';
import redMark from 'assets/red_mark.svg';

export const Container = styled.div`
  margin-top: 44px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 89vh;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 0.5px 2px #60617029;
  border-radius: 10px;
  opacity: 1;
  padding: 24px;
  flex-grow: 1;
  position: relative;
  &.table-card {
    padding: 8px 24px;
  }
  .button {
    position: absolute;
    right: 16px;
    top: 26px;
    opacity: 0.7;
    cursor: pointer;
  }
  tbody {
    background-color: red;
  }
`;

export const Title = styled.div`
  font: normal normal medium 20px/24px Poppins;
  letter-spacing: 0.22px;
  color: #2c273c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  svg {
    transition: ease 0.3s;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  &.hor {
    flex-direction: row;
    align-items: center;
    .label {
      width: 150px;
      margin-bottom: 0px;
    }
  }
`;
export const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  &.index {
    flex-grow: 0.04;
  }
  &.item {
    flex-grow: 0.13;
  }
  &.grow {
    flex-grow: 0.83;
  }
  &.expand {
    flex-grow: 0.93;
  }
  &.label {
    font: normal normal medium 10px/10px Poppins;
    font-size: 10px;
    letter-spacing: 1px;
    color: #696f81;
  }
  &.value {
    font: normal normal normal 10px/10px Poppins;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: #3f465d;
  }
  &.bold {
    font: normal normal medium 10px/10px Poppins;
    font-size: 14px;
    letter-spacing: 1px;
    color: #222b45;
  }
  &.id {
    flex-grow: 0.08;
  }
  &.one {
    flex-grow: 0.1;
  }
  &.small {
    flex-grow: 0.04;
  }
  &.medium {
    flex-grow: 0.06;
  }
  &.index {
    flex-grow: 0.05;
  }
  &.large {
    flex-grow: 0.43;
  }
  &.ml {
    padding-left: 16px;
  }
  &.center {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding-left: 8px;
  }
  :empty {
    //border: solid 1px red;
    :after {
      content: '---';
    }
  }
  ${({width}) =>
    width &&
    css`
      width: ${width}%;
      min-width: ${width}%;
      max-width: ${width}%;
    `}};
`;

export const Status = styled.span`
  padding: 6px 4px;
  border-radius: 5px;
  font: normal normal medium 13px/10px Poppins;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 80px;
  align-items: center;
  justify-content: center;

  &.approved {
    color: #33e5be;
    background-color: #cff5ed;
  }

  &.neutral {
    color: #6135fb;
    background-color: #beb2f6;
  }

  &.rejected {
    background-color: #ffa7a7;
    color: #e64444;

    ::before {
      content: ' ';
      width: 16px;
      height: 16px;
      display: block;
      background-image: url(${redMark});
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeeafb;
  margin: 16px 0;
  &.small {
    margin: 16px 0;
    width: 100vw;
    margin-left: -32px;
  }
  &.header {
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: -32px;
    width: 100vw;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.justify-left {
    justify-content: left;
  }
  &.mt-38 {
    margin-top: 38px;
  }
  &.mt-25 {
    margin-top: 25px;
  }
`;

export const Label = styled.span`
  font: normal normal 600 10px/10px Poppins;
  letter-spacing: 1.5px;
  color: #696f81cc;
  text-transform: uppercase;
  margin-bottom: 8px;
`;
export const LabelValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  padding: 24px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat;
  box-shadow: 0px 0.5px 2px #c7c5c9;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 12px 95px;
`;

export const Value = styled.span`
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0.42px;
  color: #222b45;
  :empty {
    //border: solid 1px red;
    :after {
      content: '---';
    }
  }
  min-height: 10px;
  &.name {
    font: normal normal normal 18px/24px Poppins;
    letter-spacing: 0.13px;
    font-size: 18px;
    line-height: 24px;
  }
  &.small {
    color: #696f81;
    font-size: 14px;
    line-height: 20px;
  }
  &.link {
    cursor: pointer;
    color: #6135fb;
  }
`;

export const TimeLine = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 180px;
  position: relative;
`;
export const TimeLineItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 22px;
  border-radius: 8px;
  box-shadow: 0 2px 8px #d0d4db76;
  flex-grow: 1;
  margin-bottom: 22px;
`;

export const TimeLineColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
  &.left {
    width: 50%;
    z-index: 2;
  }
  &.center {
    width: 38px;
    position: absolute;
    left: calc(50% - 38px);
    top: 0;
    opacity: 0.5;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  &.right {
    width: 50%;
    z-index: 2;
  }
  .icon {
    max-width: 38px;
    max-height: 38px;
    border-radius: 19px;
    background-color: #64dbf7;
    box-shadow: 0px 0.5px 2px #e7ecf5;
    margin-bottom: 14%;
    padding: 9px;
    margin-left: 9px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const TimeLineCardTitle = styled.div`
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0.5px;
  color: #222b45;
  margin-bottom: 28px;
  text-transform: capitalize;
  p {
    font: normal normal normal 12px/34px Poppins;
    letter-spacing: 0.36px;
    color: #3f465d;
    opacity: 0.87;
  }
`;

export const Image = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #e7ecf5;
  border-radius: 10px;
  margin-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeLineDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #eff2f7;
  box-shadow: 0 0.5px 2px #e7ecf5;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;
`;
