import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    position: 'absolute',
    zIndex: 10,
  },
  contentContainer: {
    width: 200,
    background: Colors.white,
    marginLeft: 200,
    marginTop: '-10px',
    '& > div': {
      marginBottom: 10,
    },
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: `${Colors.RMSC.shadow} 5px 5px 10px`,
  },
  optionsContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
  },
  iconContainer: {
    width: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoverButton: {
    background: Colors.blueGrayLight,
    color: Colors.purple,
  },
  defaultButton: {
    background: Colors.white,
    color: Colors.black,
  },
});
