import React from 'react';
import {Form, Formik} from 'formik';
import {ComponentProps, useStylesFromThemeFunction} from './KlaimForm';

const KlaimForm: React.FC<ComponentProps> = ({
  children,
  initialValues,
  validationSchema,
  onSubmit,
  enableReinitialize = false,
  validateOnChange = true,
  validateOnBlur = false,
  customClass,
  style,
  id,
  onChange,
}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
    >
      {(props: any) => {
        const {handleSubmit} = props;
        const childrenWithProps = React.Children.map(children, (child, i) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {index: i, ...props});
          }
          return child;
        });
        return (
          <Form
            id={id}
            style={style}
            onSubmit={handleSubmit}
            className={customClass !== undefined ? customClass : classes.formContainer}
            onChange={onChange}
          >
            {childrenWithProps}
          </Form>
        );
      }}
    </Formik>
  );
};

export default KlaimForm;
