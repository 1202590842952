import React from 'react';
import {FormikHelpers} from 'formik';
import {useHistory, RouteComponentProps} from 'react-router-dom';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Invite/Invite';
import Loading from 'modules/Loading';
import {verifyEmailRequest} from 'modules/Auth/v3/features/Verify/verifySlice';

import * as Toast from 'modules/Common/utils/toast';
import InvalidVerificationLink from '../../components/InvalidVerificationLink';

interface RouteParams {
  hash: string;
}

const VerifyEmail: React.FC<RouteComponentProps<RouteParams>> = ({match}) => {
  const {
    params: {hash},
  } = match;
  const classes = useStylesFromThemeFunction();

  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (hash && !isLoading) {
      setIsLoading(true);
      verifyEmailRequest({hash}).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          Toast.success('Your email has been verified', 'Verified!');
          const to = setTimeout(() => {
            clearTimeout(to);
            history.push('/auth/login');
          }, 1000);
        }
      });
    }
  }, [hash]);

  if (isLoading) {
    return <Loading customContainer={classes.loadingContainer} />;
  }
  return <InvalidVerificationLink />;
};

export default VerifyEmail;
