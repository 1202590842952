import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import CollapsiblePreAuthDetails from 'modules/PreAuth/components/CollapsiblePreAuthDetails';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    activityContainer: {
      background: '#F5F7FB',
      borderRadius: 20,
      marginBottom: 10,
      marginTop: 15,
      maxHeight: 720,
      overflowY: 'scroll',
    },
    activityStatus: {
      alignItems: 'center',
      display: 'flex',
    },
    approved: {
      background: '#41D8B6',
      borderRadius: 100,
      height: 15,
      margin: '0 12px',
      width: 15,
    },
    diagnosesContainer: {
      background: '#F5F7FB',
      borderRadius: 20,
      marginBottom: 10,
      marginTop: 15,
      maxHeight: 200,
      overflowY: 'scroll',
    },
    divider: {
      background: '#e8e9f2',
      height: 1,
      marginBottom: 30,
      marginTop: 30,
    },
    drugInfo: {
      '& > div': {
        flex: 1,
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemsListed: {
      fontSize: 13,
      marginBottom: 38,
      paddingLeft: 20,
    },
    listsTitle: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 30,
      paddingLeft: 20,
    },
    patientInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    patientInfoSection: {
      '& > $div': {
        flex: 1,
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: 80,
      marginTop: 30,
    },
    patientInfoSection2: {
      '& > $div': {
        flex: 1,
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: 80,
    },
    patientInfoSubtitle: {
      fontSize: 12,
      marginBottom: '0',
    },
    patientInfoText: {
      marginTop: 5,
    },
    patientInfoTitle: {
      color: '#222b45',
      marginTop: '0 !important',
    },
    patientInfoWrapper: {
      background: 'white',
      borderRadius: 10,
      marginBottom: 45,
      // marginTop: 45,
      paddingBottom: 40,
      paddingLeft: 35,
      paddingRight: 35,
      paddingTop: 40,
    },
    rejected: {
      background: '#D90B0B',
      borderRadius: 100,
      height: 15,
      margin: '0 12px',
      width: 15,
    },
  };
});

const PreAuthResponse: FC = () => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.patientInfoContainer}>
      <div className={classes.patientInfoWrapper}>
        <h3 className={classes.patientInfoTitle}>Pre-Auth Details</h3>
        <div className={classes.patientInfoSection}>
          <div>
            <p className={classes.patientInfoSubtitle}>Pre-Auth. Status</p>
            <p className={classes.patientInfoText}>Partially</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Submissions</p>
            <p className={classes.patientInfoText}>1</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Activities</p>
            <p className={classes.patientInfoText}>3</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Submission</p>
            <p className={classes.patientInfoText}>24 Oct 2020 19:09</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Remittance Advice</p>
            <p className={classes.patientInfoText}>24 Oct 2020 19:09</p>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.patientInfoSection}>
          <div>
            <p className={classes.patientInfoSubtitle}>Pre-Auth. ID</p>
            <p className={classes.patientInfoText}>PA-16356-P</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Type</p>
            <p className={classes.patientInfoText}>Professional</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Type</p>
            <p className={classes.patientInfoText}>InPatient</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Priority</p>
            <p className={classes.patientInfoText}>Immediate</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Service Date</p>
            <p className={classes.patientInfoText}>25 Oct 2020 20:58</p>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.patientInfoSection}>
          <div>
            <p className={classes.patientInfoSubtitle}>Eligible Amount:</p>
            <p className={classes.patientInfoText}>123 SAR</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Tax Amount:</p>
            <p className={classes.patientInfoText}>103 SAR</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Benefit Amount:</p>
            <p className={classes.patientInfoText}>103 SAR</p>
          </div>
          <div>
            <p className={classes.patientInfoSubtitle}>Rejected Amount:</p>
            <p className={classes.patientInfoText}>103 SAR</p>
          </div>
          <div />
        </div>
        <div className={classes.listsTitle}>Diagnoses List</div>
        <div className={classes.diagnosesContainer}>
          <CollapsiblePreAuthDetails
            headerText="Principal"
            renderContent={() => (
              <>
                <b>Diagnosis #1</b>
                <p>
                  (R83-R89) Abnormal findings on examination of other body fluids, substances and tissues, without
                  diagnosis
                </p>
                <p>On Admission: Yes</p>
              </>
            )}
          />
          <CollapsiblePreAuthDetails
            headerText="Secondary"
            renderContent={() => (
              <>
                <b>Diagnosis #1</b>
                <p>
                  (R83-R89) Abnormal findings on examination of other body fluids, substances and tissues, without
                  diagnosis
                </p>
                <p>On Admission: Yes</p>
              </>
            )}
          />
        </div>
        <div className={classes.itemsListed}>Diagnoses Listed: 6</div>
        <div className={classes.listsTitle}>Activity List</div>
        <div className={classes.activityContainer}>
          <CollapsiblePreAuthDetails
            headerText="Pharmaceuticals"
            subHeaderText="Inshurance resopnse #1 - 25 Oct 2020 20:58"
            statusText="Rejected"
            renderContent={() => (
              <>
                <b>PRIMARY DOCTOR:</b>
                <p>Radwan Badawi - Pharmacist</p>
                <b>DRUG #1:</b>
                <p>
                  100501-1171 21ST CENTURY B COMPLEX WITH C (VITAMIN B COMPLEX : N/A) (ASCORBIC ACID (VITAMIN C) : N/A)
                  TABLETS”,”VITAMIN B COMPLEX/ASCORBIC ACID (VITAMIN C) [N/A|N/A]”,”TABLETS (100’S, BOTTLE)”,”21ST
                  CENTURY HEALTH CARE, USA”
                </p>
                <div className={classes.drugInfo}>
                  <div>
                    <b>IS IT A PACKAGE?</b>
                    <p>True</p>
                  </div>
                  <div>
                    <b>DURATION:</b>
                    <p>5 days</p>
                  </div>
                  <div>
                    <b>QUANTITY:</b>
                    <p>5 Tablets</p>
                  </div>
                  <div>
                    <b>UNIT PRICE:</b>
                    <p>32 SAR</p>
                  </div>
                  <div>
                    <b>ITEM TAX:</b>
                    <p>30 SAR</p>
                  </div>
                  <div>
                    <b>PATIENT SHARE:</b>
                    <p>2 SAR</p>
                  </div>
                </div>
                <b>NOTES:</b>
                <p>
                  The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced
                  by fox whelps.
                </p>
                <b>ATTACHMENTS:</b>
                <p>File name1.pdf; File name2.pdf</p>
              </>
            )}
            renderSubContent={() => (
              <>
                <b>ACTIVITY STATUS:</b>
                <p className={classes.activityStatus}>
                  <div className={classes.rejected} />
                  Rejected
                </p>
                <b>RESON FOR NOT APPROVAL:</b>
                <p>TKBK-001 Correction take-back</p>
                <b>NOTES:</b>
                <p>
                  The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced
                  by fox whelps.
                </p>
                <div className={classes.drugInfo}>
                  <div>
                    <b>ELIGIBLE AMOUNT:</b>
                    <p>0 SAR</p>
                  </div>
                  <div>
                    <b>BENEFIT AMOUNT:</b>
                    <p>0 SAR</p>
                  </div>
                </div>
              </>
            )}
          />
          <CollapsiblePreAuthDetails
            headerText="Laboratory Test"
            subHeaderText="Inshurance resopnse #1 - 25 Oct 2020 20:58"
            statusText="Approved"
            renderContent={() => (
              <>
                <b>ASSISTING DOCTOR:</b>
                <p>Dr. Mohamad - Neurology</p>
                <b>CODE #1:</b>
                <p>12 Biopsy of brain or meninges</p>
                <div className={classes.drugInfo}>
                  <div>
                    <b>IS IT A PACKAGE?</b>
                    <p>True</p>
                  </div>
                  <div>
                    <b>DURATION:</b>
                    <p>5 days</p>
                  </div>
                  <div>
                    <b>QUANTITY:</b>
                    <p>5 Tablets</p>
                  </div>
                  <div>
                    <b>UNIT PRICE:</b>
                    <p>32 SAR</p>
                  </div>
                  <div>
                    <b>ITEM TAX:</b>
                    <p>30 SAR</p>
                  </div>
                  <div>
                    <b>PATIENT SHARE:</b>
                    <p>2 SAR</p>
                  </div>
                </div>
                <b>NOTES:</b>
                <p>
                  The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced
                  by fox whelps.
                </p>
                <b>ATTACHMENTS:</b>
                <p>File name1.pdf; File name2.pdf</p>
              </>
            )}
            renderSubContent={() => (
              <>
                <b>ACTIVITY STATUS:</b>
                <p className={classes.activityStatus}>
                  <div className={classes.approved} />
                  Approved
                </p>
                <b>NOTES:</b>
                <p>
                  The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced
                  by fox whelps.
                </p>
                <div className={classes.drugInfo}>
                  <div>
                    <b>ELIGIBLE AMOUNT:</b>
                    <p>100 SAR</p>
                  </div>
                  <div>
                    <b>COPAY AMOUNT:</b>
                    <p>0 SAR</p>
                  </div>
                  <div>
                    <b>BENEFIT AMOUNT:</b>
                    <p>100 SAR</p>
                  </div>
                </div>
              </>
            )}
          />
          <CollapsiblePreAuthDetails
            headerText="Radiology Test"
            subHeaderText="Inshurance resopnse #1 - 25 Oct 2020 20:58"
            statusText="Approved"
            renderContent={() => (
              <>
                <b>ASSISTING DOCTOR:</b>
                <p>Dr. Mohamad - Neurology</p>
                <b>CODE #1:</b>
                <p>12 Biopsy of brain or meninges</p>
                <div className={classes.drugInfo}>
                  <div>
                    <b>IS IT A PACKAGE?</b>
                    <p>True</p>
                  </div>
                  <div>
                    <b>DURATION:</b>
                    <p>5 days</p>
                  </div>
                  <div>
                    <b>QUANTITY:</b>
                    <p>5 Tablets</p>
                  </div>
                  <div>
                    <b>UNIT PRICE:</b>
                    <p>32 SAR</p>
                  </div>
                  <div>
                    <b>ITEM TAX:</b>
                    <p>30 SAR</p>
                  </div>
                  <div>
                    <b>PATIENT SHARE:</b>
                    <p>2 SAR</p>
                  </div>
                </div>
                <b>NOTES:</b>
                <p>
                  The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced
                  by fox whelps.
                </p>
                <b>ATTACHMENTS:</b>
                <p>File name1.pdf; File name2.pdf</p>
              </>
            )}
            renderSubContent={() => (
              <>
                <b>ACTIVITY STATUS:</b>
                <p className={classes.activityStatus}>
                  <div className={classes.approved} />
                  Approved
                </p>
                <b>NOTES:</b>
                <p>
                  The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced
                  by fox whelps.
                </p>
                <div className={classes.drugInfo}>
                  <div>
                    <b>ELIGIBLE AMOUNT:</b>
                    <p>100 SAR</p>
                  </div>
                  <div>
                    <b>COPAY AMOUNT:</b>
                    <p>0 SAR</p>
                  </div>
                  <div>
                    <b>BENEFIT AMOUNT:</b>
                    <p>100 SAR</p>
                  </div>
                </div>
              </>
            )}
          />
        </div>
        <div className={classes.itemsListed}>Activity/Responses Listed: 6</div>
      </div>
    </div>
  );
};

export default PreAuthResponse;
