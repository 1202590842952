import {RootState} from 'modules/App/store';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'services/axios';
import {decodeJWT, TokenData} from 'services/jwt';
import {FormikHelpers} from 'formik';

interface MyKnownError {
  code: number;
  details?: ErrorDetails[];
  message: string;
  stack: {
    error: {
      message: string;
    };
    errors: string[];
  };
}
interface ErrorDetails {
  property: string;
  children: any;
  constraints: any;
}

interface RegisterAttributes {
  values: any;
  formikHelpers: FormikHelpers<{
    [field: string]: any;
  }>;
}

interface EmailValidateAtrributes {
  hash: string;
}

interface EmailInviteData {
  options?: any;
}

interface EmailInviteKnownError {
  error: any;
}

export const emailInviteValidate = createAsyncThunk<
  any,
  EmailValidateAtrributes,
  {
    rejectValue: EmailInviteKnownError;
  }
>('invite/emailInviteValidate', async ({hash}: EmailValidateAtrributes, {rejectWithValue}) => {
  try {
    const response = await axios.get(`/auth/verify-invitation/${hash}`);
    return response.data;
  } catch (err) {
    return rejectWithValue({error: err});
  }
});

interface AcceptInviteAtrributes {
  hash: string;
  values: {email: string; password: string; name: string; phone: string};
}

export const acceptInvite = createAsyncThunk<any, AcceptInviteAtrributes, {rejectValue: MyKnownError}>(
  'invite/acceptInvite',
  async ({hash, values}: AcceptInviteAtrributes, {rejectWithValue}) => {
    try {
      const response = await axios.post(`/auth/update-invitation/${hash}`, {...values});
      return {
        user: decodeJWT(response.data.accessToken),
        accessToken: response.data.accessToken,
      };
    } catch (error: any) {
      let reduce = [];
      if (error.response.data.stack) {
        if (error.response.data.stack.message) {
          if (error.response.data.stack.message.length > 0) {
            reduce = error.response.data.stack.message.reduce((acc: any, errorx: any) => {
              return [...acc, errorx.constraints.matches];
              // return {
              //   ...acc,
              //   [errorx.property]: errorx.constraints.matches,
              // };
            }, []);
            // formikHelpers.setErrors(reduce);
          }
        }
      }
      if (error.response.data.stack) {
        if (error.response.data.stack.error) {
          return rejectWithValue({
            code: error.response.data.statusCode,
            message: error.response.data.stack.error,
            stack: {
              error: {
                message: reduce.length > 0 ? 'Invalid Field(s)' : error.response.data.stack.error.message,
              },
              errors: reduce,
            },
          });
        }
      }
      return rejectWithValue(error.response.data);
    }
  },
);

interface InviteUser {
  email: string;
  orgnanizationName: string;
}

export interface InitialState {
  isLoading: boolean;
  isValidHash: boolean;
  isHashValidated: boolean;
  error?: MyKnownError;
  user?: InviteUser;
}

const initialState: InitialState = {
  isLoading: false,
  isValidHash: false,
  isHashValidated: false,
  error: undefined,
  user: undefined,
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    inviteCleanUp: (state) => {
      state.isLoading = false;
      state.isValidHash = false;
      state.isHashValidated = false;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(emailInviteValidate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailInviteValidate.fulfilled, (state, action) => {
        state.isValidHash = true;
        state.isHashValidated = true;
        state.user = {
          email: action.payload.invited_user.email,
          orgnanizationName: action.payload.invited_user.organization.name || 'organization',
        };
        state.isLoading = false;
      })
      .addCase(emailInviteValidate.rejected, (state) => {
        state.isValidHash = false;
        state.isHashValidated = true;
        state.isLoading = false;
      })
      .addCase(acceptInvite.rejected, (state, action) => {
        state.isHashValidated = true;
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const {inviteCleanUp} = inviteSlice.actions;
export const inviteSelect = (state: RootState) => state.auth.invite;

export default inviteSlice.reducer;
