import React from 'react';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {useHistory, RouteComponentProps} from 'react-router-dom';
import {Field, FieldProps} from 'formik';
import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import {IValues} from 'modules/Settings/features/AccountSettings/EditInfo/EditInfo';
import {useAppDispatch} from 'modules/App/store';

import PermissionBox from 'modules/Settings/features/AccessManagement/PermissionBox';
import {
  accessManagementSelect,
  updateRoleCleanUp,
  getRole,
  getPermissions,
  updateRole,
  errorCleanUp,
  permissionCleanup,
} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import {useStylesFromThemeFunction} from 'modules/Settings/features/AccessManagement/RoleEdit/RoleEdit';
import * as Toast from 'modules/Common/utils/toast';

export interface IRouteParams {
  id: string;
}

const RoleEdit: React.FC<RouteComponentProps<IRouteParams>> = ({match}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    isUpdateSuccess,
    permissions,
    role,
    errors: {message},
    error: {message: errorMessage},
  } = useSelector(accessManagementSelect);

  React.useEffect(() => {
    dispatch(getRole(match.params.id));
    dispatch(getPermissions());
  }, [match.params.id]);

  React.useEffect(() => {
    if (isUpdateSuccess) {
      Toast.success('Role is updated successfully', 'Success');
      history.push('/settings/access-management');
    }
    return () => {
      dispatch(updateRoleCleanUp());
      dispatch(errorCleanUp());
      dispatch(permissionCleanup());
    };
  }, [isUpdateSuccess]);

  const initialValues = {
    name: role.name,
    description: role.description,
    permissions: role.permissions,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    permissions: Yup.array().required(),
  });

  const onSubmit = (values: IValues) => {
    dispatch(
      updateRole({
        id: role.id,
        name: values.name,
        description: values.description,
        permissions: values.permissions,
      }),
    );
  };
  return (
    <KlaimForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      enableReinitialize
    >
      <Header
        title="Edit Role"
        backlink="/settings/access-management"
        description={<p>Edit role by adding or modifying access rights.</p>}
        buttonType="submit"
        buttonTitle="Update Role"
        body={
          <div className={classes.bodyWrapper}>
            {errorMessage && (
              <div className={classes.errorContainer}>
                <span>&#9679;</span>
                {errorMessage}
              </div>
            )}
            {message && (
              <div className={classes.errorContainer}>
                {message.map((mess: any, index: number) => (
                  <div key={index}>
                    <span>&#9679;</span>
                    {mess}
                  </div>
                ))}
              </div>
            )}
            <div className={classes.inputContainer}>
              <div className={classes.input}>
                <KlaimInput name="name" label="Role Title" placeholder="ex. Administrator" value={role.name} />
              </div>
              <div className={classes.input}>
                <KlaimInput
                  name="description"
                  label="Description"
                  placeholder="ex. Organization Admin"
                  optional
                  value={role.description}
                />
              </div>
            </div>

            <Field name="permissions">
              {({form: {values, setFieldValue}}: FieldProps) => {
                return (
                  <PermissionBox
                    setFieldValues={setFieldValue}
                    permissions={permissions}
                    rolePermissions={role.permissions}
                  />
                );
              }}
            </Field>
          </div>
        }
      />
    </KlaimForm>
  );
};

export default RoleEdit;
