import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IRoute} from 'interfaces/route.interface';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {useAppSelector} from 'modules/App/store';

const PrivateRoute: React.FC<IRoute> = ({
  component: Component,
  requiredPermission,
  requiredModule,
  countries,
  routeKey,
  ...rest
}) => {
  const {
    isAuthenticated,
    isClaimAllowed,
    user: {modules, permissions, klaim_super_admin: isAdmin, organization},
  } = useAppSelector(authSelect);

  if (!isClaimAllowed && routeKey === 'CLAIMS') {
    return <Redirect to={{pathname: '/not-authorized'}} />;
  }

  if (isAdmin === false) {
    if (requiredModule) {
      if (!modules.includes(requiredModule)) {
        return <Redirect to={{pathname: '/not-authorized'}} />;
      }
    }
    if (countries) {
      if (!countries.includes(organization.country)) {
        return <Redirect to={{pathname: '/not-authorized'}} />;
      }
    }

    if (requiredPermission) {
      if (!permissions.includes(requiredPermission)) {
        return <Redirect to={{pathname: '/not-authorized'}} />;
      }
    }
  }

  return (
    <Route
      key={routeKey}
      render={(props) => {
        return isAuthenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{pathname: '/auth', state: {from: props.location}, search: props.location.search}} />
        );
      }}
    />
  );
};

export default PrivateRoute;
