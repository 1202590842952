import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FormikHelpers} from 'formik';
import axios from 'services/axios';
import {decodeJWT, TokenData} from 'services/jwt';

export interface AuthState {
  success: boolean;
  error?: MyKnownError;
}

interface AuthData {
  user: TokenData;
  accessToken: string;
  status: number;
}

interface MyKnownError {
  code: number;
  details: ErrorDetails[];
  message: string;
  status: number;
}

interface ErrorDetails {
  property: string;
  children: any;
  constraints: any;
}

interface RegisterAttributes {
  values: any;
  formikHelpers: FormikHelpers<{
    [field: string]: any;
  }>;
}

interface ChangePasswordAttributes {
  hash: string;
  values: any;
  formikHelpers: FormikHelpers<{
    [field: string]: any;
  }>;
}

const initialState: AuthState = {
  success: false,
  error: undefined,
};

export const forgotPassword = createAsyncThunk<
  AuthData,
  RegisterAttributes,
  {
    rejectValue: MyKnownError;
  }
>('auth/forgotPassword', async ({values, formikHelpers}: RegisterAttributes, {rejectWithValue}) => {
  try {
    const response = await axios.post('/auth/forgot-password', values);
    return {
      user: decodeJWT(response.data.accessToken),
      accessToken: response.data.accessToken,
      status: response.status,
    };
  } catch (error: any) {
    if (error.response.data.details) {
      if (error.response.data.details.length > 0) {
        const reduce = error.response.data.details.reduce(
          (acc: any, errorx: any) => ({
            ...acc,
            [errorx.property]: errorx.constraints.matches,
          }),
          {},
        );
        formikHelpers.setErrors(reduce);
      }
    }
    return rejectWithValue(error.response.data);
  }
});

export const changePasswordRequest = async (values: any, hash: string) => {
  try {
    const response = await axios.post(`/auth/change-password/${hash}`, values);
    return response;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
    return err;
  }
};

// TODO: Reimplement this
export const changePassword = createAsyncThunk<
  AuthData,
  ChangePasswordAttributes,
  {
    rejectValue: MyKnownError;
  }
>('auth/changePassword', async ({values, hash, formikHelpers}: ChangePasswordAttributes, {rejectWithValue}) => {
  try {
    const response = await axios.post(`/auth/change-password/${hash}`, values);
    return {
      user: decodeJWT(response.data.accessToken),
      accessToken: response.data.accessToken,
      status: response.status,
    };
  } catch (error: any) {
    if (error.response.data.details) {
      if (error.response.data.details.length > 0) {
        const reduce = error.response.data.details.reduce(
          (acc: any, errorx: any) => ({
            ...acc,
            [errorx.property]: errorx.constraints.matches,
          }),
          {},
        );
        formikHelpers.setErrors(reduce);
      }
    }
    return rejectWithValue(error.response.data);
  }
});

export const forgotPasswordValidate = createAsyncThunk('auth/forgotPasswordValidate', async () => {
  return true;
});

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(forgotPasswordValidate.rejected, (state, action) => {
      // state.error = action.payload;
    });
  },
});

export default forgotPasswordSlice.reducer;
