import React, {FC, useState} from 'react';
import dayjs from 'dayjs';
import {get} from 'lodash';
import {useDispatch} from 'react-redux';
import {v4 as uuid} from 'uuid';
import {useStylesFromThemeFunction} from '../../../../../MidTable/features/MidTableForm/Components/MidTableForm';
import {speciality} from '../../ClaimDetailsV3/data';
import {claimStatues} from '../../ClaimsList/data';
import KlaimDropdownContainer from '../../../../../Common/components/KlaimDropdownContainer';
import moreIcon from '../../../../../../assets/more_icon_2.svg';
import addIcon from '../../../../../../assets/add_icon_2.svg';
import EditForm from './EditActivityFields';
import {useAppSelector} from '../../../../../App/store';

import {
  getPractitioners,
  practitionerManagementSelect,
  PractitionersQuery,
} from '../../../../../Settings/features/Practitioners/PractitionersSlice';
import {
  branchManagementSelect,
  getBranches,
} from '../../../../../Settings/features/BranchManagement/branchManagementSlice';
import {KlaimInput} from '../../../../../Common/components-v2/KlaimForm';
import {IActivityForm, IItem} from '../../../../../../interfaces/pre-auth.interface';
import ActivityModal from './ActivityModal';
import {checkErroredFields} from '..';

const ClaimActivities: FC<any> = ({
  items,
  careTeam,
  supportingInfo,
  diagnosis,
  response,
  submission,
  providerId,
  setSubmission,
  errors,
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useDispatch();
  const DATE_FORMAT = `DD MMM 'YY`;
  const formatDate = (date) => dayjs(date).format(DATE_FORMAT);
  const {
    practitioners: {data},
  } = useAppSelector(practitionerManagementSelect);
  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier);
  };

  React.useEffect(() => {
    if (!isBranchLoading && branches.length === 0) dispatch(getBranches({}));
  }, [branches, isBranchLoading]);

  React.useEffect(() => {
    if (branches.length > 0 && !isBranchLoading) {
      const queryPayload: PractitionersQuery = {
        providerId: getProviderIds().join(','),
        limit: 100,
      };
      dispatch(getPractitioners(queryPayload));
    }
  }, [branches]);
  const [itemIndex, setItemIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const branch = branches.find((b) => b.identifier === providerId) || ({} as any);

  const [isOpen, setIsOpen] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [selectedActivityIndex, setSelectedActivityIndex] = useState(0);
  const [activity, setActivity] = useState([]);
  const itemId = uuid();

  const handleRemoveActivity = (value: IItem, activityIndex: number) => {
    setActivity(activity.filter((_, i: number) => i !== activityIndex));
  };
  const handleEditActivity = (activityIndex: number) => {
    setSelectedActivityIndex(activityIndex);
    setIsOpen(true);
  };
  const handleActivityChange = (value: IActivityForm) => {
    if (selectedActivityIndex === undefined) {
      setActivity([...activity, value]);
      const obj = JSON.parse(JSON.stringify(submission));
      const it = obj.claim.item;
      it.push(value);
      setSubmission({
        ...obj,
        claim: {
          ...obj.claim,
          item: it,
        },
      });
    } else {
      const newActivityArray = [...activity];
      newActivityArray[selectedActivityIndex] = value;
      setActivity(newActivityArray);
      setSubmission({
        ...submission,
        claim: {
          ...submission.claim,
          item: submission.claim.item.map((it, index) => {
            if (index === selectedActivityIndex) {
              return {...it, ...value.item};
            }
            return it;
          }),
        },
      });
    }
    setIsOpen(false);
  };

  const handleActivityAdd = (value: IActivityForm) => {
    setActivity([...activity, value]);
    const obj = JSON.parse(JSON.stringify(submission));
    const it = obj.claim.item;
    it.push(value.item);
    setSubmission({
      ...obj,
      claim: {
        ...obj.claim,
        item: it,
      },
    });
  };

  return (
    <div>
      <EditForm
        setSubmission={setSubmission}
        submission={submission}
        editingItem={editingItem}
        providerId={providerId}
        errors={errors}
      />
      <div
        style={{
          position: 'relative',
          backgroundColor: '#F5F7FB',
          width: 'calc(100%)',
          padding: '16px 16px',
          display: 'flex',
          flexDirection: 'row',
          gap: 26,
          marginTop: -25,
          marginBottom: -25,
          borderLeft: checkErroredFields(errors, 'item') ? '1px solid red' : '',
          borderRight: checkErroredFields(errors, 'item') ? '1px solid red' : '',
        }}
      >
        <div
          className={classes.absoluteButton}
          style={{paddingBottom: 18, marginRight: 16}}
          role="button"
          onClick={() => {
            setShowAddActivityModal(true);
          }}
        >
          <img src={addIcon} alt="" />
        </div>
        <div className={classes.value} style={{flex: '1 1 2%'}}>
          #
        </div>
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          INVOICE
        </div>
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          CODE
        </div>
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          CLINICIAN
        </div>
        {/* <div className={classes.value} style={{flex: '1 1 20%'}}> */}
        {/*  BODY SITE */}
        {/* </div> */}
        <div className={classes.value} style={{flex: '1 1 20%'}}>
          STATUS
        </div>
        <div className={classes.value} style={{flex: '1 1 5%'}}>
          QTY
        </div>
        <div className={classes.value} style={{flex: '1 1 50%'}}>
          AMOUNTS IN SAR
        </div>
        {/* <div className={classes.label} style={{flex: '1 1 20%'}} /> */}
      </div>
      {items.map((el: any, index: number) => (
        <div
          key={`${index}-item-claim-x`}
          className={classes.responsiveRow}
          style={{
            backgroundColor: 'white',
            width: 'calc(100%)',
            // marginLeft: '-16px',
            padding: '0px 16px',
            borderBottom: 'solid 1px #E7ECF5',
            paddingTop: 8,
            borderLeft: checkErroredFields(errors, 'item') ? '1px solid red' : '',
            borderRight: checkErroredFields(errors, 'item') ? '1px solid red' : '',
          }}
        >
          <div className={classes.label} style={{flex: '1 1 2%'}}>
            {index}
          </div>
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {el?.invoiceNo?.number}
            <br />
            {formatDate(el?.servicedDate)}
            <KlaimInput
              label="Invoice No"
              variant="secondary"
              placeholder="Invoice No"
              value={el?.invoiceNo?.number}
              onChange={(e) => {
                const sub = JSON.parse(JSON.stringify(submission));
                sub.claim.item[index].invoiceNo = {
                  number: e,
                  system: branch.invoice_system ? branch.invoice_system : sub.claim.item[index].invoiceNo?.system,
                };
                setSubmission(sub);
              }}
            />
            <br />
          </div>
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {el?.itemCodeHcp}
            <br />
            {el?.itemCode}
            <br />
            {el?.itemCodeDisplay}
          </div>
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {get(careTeam, `[${get(el, 'itemCareTeamSeq[0]') - 1}].practitionerCode`)}
            <br />
            {get(speciality, get(careTeam, `[${get(el, 'itemCareTeamSeq[0]') - 1}].specialty`))}
          </div>
          {/* <div className={classes.label} style={{flex: '1 1 20%', color: '#302E3848'}}> */}
          {/*  {el?.bodySite} */}
          {/* </div> */}
          <div className={classes.label} style={{flex: '1 1 20%'}}>
            {/* <span className={classes.approvedStatus}> */}
            {get(response, `[0].response.itemsDetails[${index}].itemOutcome`) && (
              <span
                className={classes.approvedStatus}
                style={{
                  backgroundColor: claimStatues[get(response, `[0].response.itemsDetails[${index}].itemOutcome`)]?.bg,
                  color: claimStatues[get(response, `[0].response.itemsDetails[${index}].itemOutcome`)]?.text,
                  padding: '2px 6px',
                  borderRadius: 5,
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                {get(response, `[0].response.itemsDetails[${index}].itemOutcome`)}
                {get(
                  get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                    (sub) => sub.category === 'benefit',
                  ),
                  '[0].reasonIfNotApproved.code',
                ) && (
                  <div className={classes.popup}>
                    <h1>Denial Reason</h1>
                    <p>
                      {get(
                        get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                          (sub) => sub.category === 'benefit',
                        ),
                        '[0].reasonIfNotApproved.code',
                      )}
                    </p>
                    <p>
                      {get(
                        get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                          (sub) => sub.category === 'benefit',
                        ),
                        '[0].reasonIfNotApproved.message',
                      )}
                    </p>
                  </div>
                )}
              </span>
            )}
          </div>
          <div className={classes.label} style={{flex: '1 1 5%'}}>
            {el?.itemQty}
          </div>
          <div className={classes.label} style={{flex: '1 1 50%'}}>
            <div className={classes.col}>
              <div className={classes.col}>
                <div className={classes.row}>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>GROSS</div>
                    <div className={classes.label}>
                      {get(
                        get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                          (sub) => sub.category === 'submitted',
                        ),
                        '[0].amount',
                        0,
                      ) -
                        get(
                          get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                            (sub) => sub.category === 'tax',
                          ),
                          '[0].amount',
                          0,
                        )}
                    </div>
                  </div>
                  <div className={classes.spacedRow}>
                    <div className={classes.value}>NET VAT</div>
                    <div className={classes.label}>
                      {get(
                        get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                          (sub) => sub.category === 'tax',
                        ),
                        '[0].amount',
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>DISCOUNT</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                        (sub) => sub.category === 'discount',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>APRD QTY</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                        (sub) => sub.category === 'approved-quantity',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>PT SHARE</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                        (sub) => sub.category === 'patientShare',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>ELIGIBLE</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                        (sub) => sub.category === 'eligible',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>NET</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                        (sub) => sub.category === 'submitted',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
                <div className={classes.spacedRow}>
                  <div className={classes.value}>APPROVED</div>
                  <div className={classes.label}>
                    {get(
                      get(response, `[0].response.itemsDetails[${index}].adjudication`, []).filter(
                        (sub) => sub.category === 'benefit',
                      ),
                      '[0].amount',
                    )}
                  </div>
                </div>
              </div>
              <div style={{height: 10}} />
            </div>
          </div>
          <KlaimDropdownContainer isIconButton icon={moreIcon} position={'center'}>
            <div
              role="button"
              onClick={() => {
                setEditingItem(items[index]);
                setItemIndex(index);
                setIsOpen(true);
                setSelectedActivityIndex(index);
              }}
              className={`${classes.button} ${classes.navLink}`}
            >
              Edit
            </div>
            <div
              role="button"
              onClick={() => {
                setSubmission({
                  ...submission,
                  claim: {
                    ...submission.claim,
                    item: items.filter((item, filterIndex) => filterIndex !== index),
                  },
                });
              }}
              className={`${classes.button} ${classes.navLink}`}
            >
              Delete
            </div>
          </KlaimDropdownContainer>
        </div>
      ))}
      {items[selectedActivityIndex] && (
        <ActivityModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={handleActivityChange}
          itemId={itemId}
          selectedActivityIndex={selectedActivityIndex}
          activity={items[selectedActivityIndex]}
          editingItem={editingItem}
          careTeam={careTeam}
          diagnosis={diagnosis}
          supportingInfo={supportingInfo}
          providerId={providerId}
          isClaim
        />
      )}
      <ActivityModal
        isOpen={showAddActivityModal}
        setIsOpen={setShowAddActivityModal}
        onSubmit={handleActivityAdd}
        itemId={itemId}
        selectedActivityIndex={undefined}
        activity={items}
        careTeam={careTeam}
        diagnosis={diagnosis}
        supportingInfo={supportingInfo}
        providerId={providerId}
        payerId={submission?.payerId}
        isClaim
      />
    </div>
  );
};
export default ClaimActivities;
