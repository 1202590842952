import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'grid',
      rowGap: '1rem',
      margin: 20,
    },
    practitionerRoleContainer: {
      display: 'grid',
      gridTemplateColumns: '60% 40%',
      columnGap: '1rem',
      marginRight: 15,
      alignItems: 'end',
    },
    practitionerListContainer: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'grid',
      rowGap: '0.5rem',
      margin: 20,
    },
    practitionerListItem: {
      display: 'grid',
      gridTemplateColumns: '45% 27.5% 27.5%',
      background: Colors.blueGrayLight,
      padding: 10,
      borderRadius: 10,
      alignItems: 'center',
      border: `1px solid ${Colors.grayLight}`,
      '& .roleField': {
        fontWeight: 300,
      },
      '& .removeField': {
        display: 'flex',
        WebkitJustifyContent: 'flex-end',
        alignItems: 'center',
        gap: 10,
        fontWeight: 300,
        fontSize: 12,
        color: Colors.grayInputPlaceholder,
        border: 'none',
        background: 'none',
      },
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 40,
    },
    orContainer: {
      display: 'flex',
      alignItems: 'center',
      '& hr': {
        flex: 1,
        border: `1px solid ${Colors.grayLight}`,
        height: 0,
      },
      '& span': {
        width: '5%',
        textAlign: 'center',
        fontSize: 12,
      },
    },
    additionalInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      borderRadius: 10,
      background: theme.container.color.tertiary,
      '& h3': {
        fontSize: 14,
      },
      '& p': {
        fontSize: 12,
      },
    },
  };
});
