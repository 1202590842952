import React, {useState} from 'react';
import {get} from 'lodash';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import {KlaimFieldWrapper, KlaimForm, KlaimInput} from '../../../../Common/components-v2/KlaimForm';
import KlaimDatePicker from '../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {useStylesFromThemeFunction} from '../../../../MidTable/features/MidTableForm/Components/MidTableForm';
import {resubmitClaim, sendCommRequest} from '../../../claimsSlice';

interface ComponentsProps {
  open: boolean;
  comm?: any;
  submission?: any;
  id?: any;
  providerId?: any;
  response?: any;
  status?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DATE_FORMAT = `YYYY-MM-DD`;
const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

const Component: React.FC<ComponentsProps> = ({open, setOpen, comm, submission, status, id, providerId, response}) => {
  const classes = useStylesFromThemeFunction();
  const [title, setTitle] = useState(get(comm, 'communication.payload[0].contentTitle'));
  const [date, setDate] = useState(get(comm, 'communication.sent'));
  const [note, setNote] = useState(get(comm, 'communication.payload[0].contentString'));
  const dispatch = useDispatch();

  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title={status === 'missing-information' ? 'Communication-response' : 'Communication'} />
      <KlaimModalBody style={{padding: 20, maxHeight: 600, overflowY: 'auto'}}>
        <KlaimForm
          enableReinitialize
          initialValues={{
            title,
            date,
            note,
          }}
          validationSchema={Yup.object({})}
          onSubmit={() => {}}
          id="patient-form"
        >
          <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', marginTop: 16}}>
            <KlaimFieldWrapper name={'title'} onChange={(value) => setTitle(value)}>
              <KlaimInput label="Title" variant="secondary" placeholder="Title" value={title} />
            </KlaimFieldWrapper>
            <div className={classes.respFieldWrapper}>
              <KlaimDatePicker
                onChange={(e) => {
                  setDate(e);
                }}
                value={date}
                label="Date"
                // name="item.servicedDate"
                variant="secondary"
                maxDate={new Date()}
              />
            </div>
          </div>
          <br />
          <KlaimFieldWrapper name={'note'} onChange={(value) => setNote(value)}>
            <KlaimInput label="Note" variant="secondary" placeholder="Note" value={note} />
          </KlaimFieldWrapper>
        </KlaimForm>
      </KlaimModalBody>
      <KlaimModalFooter
        cancelButtonTitle={'Cancel'}
        submitLoading={false}
        onSubmit={() => {
          //from communication request in timeline too
          const {patient, senderId, receiverId, payerId, payerName, senderName} = submission;
          if (status === 'missing-information')
            dispatch(
              sendCommRequest({
                patient,
                senderId,
                receiverId,
                payerId,
                id,
                claimId: id,
                providerId,
                payerName,
                senderName,
                type: 'communication-response',
                claimSystem: 'http://klaim.ai/fhir/Claim',
                communication: {
                  ...submission?.communication,
                  basedOnCommReqId: get(response, 'response.comunicationIdentifier'),
                  basedOnCommReqSystem: get(response, 'response.comunicationSystem'),
                  sent: formatDate(date),
                  payload: [{contentTitle: title, contentString: note}],
                },
              }),
            );
          if (status !== 'missing-information')
            dispatch(
              sendCommRequest({
                patient,
                senderId,
                receiverId,
                payerId,
                id,
                providerId,
                payerName,
                senderName,
                type: 'communication',
                claimSystem: 'http://klaim.ai/fhir/Claim',
                claimId: id,
                communication: {
                  ...submission?.communication,
                  sent: formatDate(date),
                  payload: [{contentTitle: title, contentString: note}],
                },
              }),
            );
          setOpen(false);
        }}
        submitButtonTitle={'Send'}
      />
    </KlaimModal>
  );
};

export default Component;
