import {ClaimListDetails} from '../../Claims/api/types';

const selectClaimList = (state: any) => {
  if (state.claim.claimList.byId) return Object.values(state.claim.claimList.byId) as ClaimListDetails[];
  return [];
};

const selectClaimById = (state: any, id: any) => {
  if (id && state.claim.claimList.byId) return state.claim.claimList.byId[id];
  return null;
};

export {selectClaimList, selectClaimById};
