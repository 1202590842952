import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export interface ComponentProps {
  status: string;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    viewClaimBtn: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',

      '& p': {
        fontWeight: theme.font.weightSemibold,
        marginLeft: 10,
      },
    },
  };
});
