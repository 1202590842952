import {notification} from 'custom-test-antd';

export const showDefaultError = () => {
  notification.error({
    message: 'Internal error was occurred',
    description: 'Please contact technical support',
  });
};

export const showNetworkError = () => {
  notification.error({
    message: 'Network error was occurred',
    description: 'Please check your connection',
  });
};

export const showError = (message: string, description: string) => {
  // console.log(message, description);
  notification.error({message, description});
};
