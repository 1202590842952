import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  title: string;
  description: any;
  buttonTitle: string;
  inputPlaceholder: string;
  onButtonClick?: () => void;
  requiredPermission?: string;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    menuButton: {
      '& button': {
        height: '42px',
        marginBottom: '28px',
      },
    },
    topMenuContent: {
      '& h2': {
        color: Colors.blueGrayDark,
        fontSize: '22px',
        fontWeight: 'bold',
        letterSpacing: '0.22px',
      },
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        marginBottom: '28px',
      },
      '& span': {
        color: Colors.purple,
        cursor: 'pointer',
      },
    },
    topMenuWrapper: {
      background: `${Colors.white} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      boxShadow: `0px 0.5px 2px ${Colors.RMSC.shadow}`,
      display: 'flex',
      padding: '20px 30px',
      flexDirection: 'column',
    },
    containerTextButton: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
});
