import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import ArrowLine from 'assets/dropdown_with_line.svg';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    dropdownContainer: {
      position: 'relative',
    },
    searchBox: {
      position: 'absolute',
      background: 'white',
      border: '1px solid black',
      padding: '2%',
      width: '96%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: Colors.blueGrayLight,
      height: '110px',
      overflow: 'hidden',
      overflowY: 'scroll',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginTop: -5,
      boxShadow: `0 4px 8px 0 ${Colors.grayWithOpacity}, 0 6px 20px 0 ${Colors.grayWithOpacity}`,
      zIndex: 99,
    },
    imageIcon: {
      width: 20,
      marginRight: '2%',
    },
    inputWrapper: {
      width: '100%',
      paddingBottom: 5,
    },
    inputContainer: {
      '& input': {
        paddingLeft: 45,
        width: '100%',
      },
      width: '100%',
    },
    searchListContainer: {
      '&:hover': {
        backgroundColor: Colors.grayWithOpacity,
        cursor: 'pointer',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    searchListSpacing: {
      padding: '2%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    hrCustom: {
      borderColor: Colors.grayWithOpacity,
      width: '100%',
      marginBottom: 0,
    },
    selectBranches: {
      border: `1px solid ${Colors.DrugCard}`,
      borderRadius: 10,
      padding: 10,
      cursor: 'pointer',
      width: '100%',
      background: `url(${ArrowLine}) no-repeat right ${Colors.erxBranchDropdown}`,
      backgroundPositionX: '98%',
      textAlign: 'left',
    },
  };
});
