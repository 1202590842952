import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../../Common/components/Input';
import SearchSelect from '../../../Common/components/SearchSelect';
import SelectOptions from '../../../Common/components/Select';
import {Clinician, ID_TYPE, PayerInsurerTypes} from '../../../Claims/api/types';
import AsyncSearchSelect from '../../../Common/components/AsyncSearchSelect';
import {useStylesFromThemeFunction} from '../../features/PriorRequest/PriorRequest';
import {getAllPayersReceivers, getClinicians, getPayerInsurer} from '../../../Claims/api';
import * as Toast from '../../../Common/utils/toast';
import {formatPayerReceiverData} from '../../../Claims/components/ClaimInformation';
import {getAutoFormattedValue} from '../../../Claims/components/PatientInformation';

interface ComponentProps {
  handleChange: (e: any) => void;
  values: any;
  setFieldTouched: (e: any) => void;
  setFieldValue: any;
  errors: any;
  touched: any;
}

const Information: FC<ComponentProps> = ({handleChange, values, setFieldTouched, setFieldValue, errors, touched}) => {
  const classes = useStylesFromThemeFunction();
  const [sPayers, setSPayers] = useState<Array<any>>([]);
  const [sReceivers, setSPReceivers] = useState<Array<any>>([]);
  const branches = JSON.parse(localStorage.getItem('userBranches') as string);
  const {t} = useTranslation();
  const limit = 200;

  const handleInputChange = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const getAllPayersList = async () => {
    try {
      const {data} = await getAllPayersReceivers(PayerInsurerTypes.PAYERS, values.authorityCode, limit);
      const payers = formatPayerReceiverData(data.data);
      setSPayers(payers);
    } catch (e) {
      Toast.error(e);
    }
  };

  const getAllReceiversList = async () => {
    try {
      const {data} = await getAllPayersReceivers(PayerInsurerTypes.RECEIVERS, values.authorityCode, limit);
      const receivers = formatPayerReceiverData(data.data);
      setSPReceivers(receivers);
    } catch (e) {
      Toast.error(e);
    }
  };

  const getPayerReceiverPrediction = async (memberId: any) => {
    if (memberId && memberId.length > 3) {
      const data = await getPayerInsurer(memberId, values.providerId);

      const payerData: any = data.data.payers.map((payer: {name: string; id: string}) => {
        return {label: `${payer.name} (${payer.id})`, value: payer.id};
      });
      const receiverData: any = data.data.receivers.map((receiver: {name: string; id: string}) => {
        return {label: `${receiver.name} (${receiver.id})`, value: receiver.id};
      });
      const pData = filterPayerReceiverData(payerData, 'payers');
      const rData = filterPayerReceiverData(receiverData, 'receivers');
      setFieldValue('payerId', [...pData][0].value);
      setFieldValue('receiverId', [...rData][0].value);
      setSPayers([...pData]);
      setSPReceivers([...rData]);
    }
  };

  const filterPayerReceiverData = (payerData: Array<{label: ''; value: ''}>, type?: 'payers' | 'receivers') => {
    payerData = payerData.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
    let data: any[];
    const dataType = type === 'payers' ? sPayers : sReceivers;
    let payerReceiverData = [];
    for (let i = 0; i < dataType.length; i++) {
      if (dataType[i].options) {
        payerReceiverData = dataType[i].options;
      } else {
        payerReceiverData = dataType;
      }
    }
    if (type === 'payers') {
      data = [...payerData, {label: '---ALL---', options: [...payerReceiverData]}];
    } else {
      data = [...payerData, {label: '---ALL---', options: [...payerReceiverData]}];
    }
    return Array.from(new Set(data.map((a) => a.value))).map((v) => {
      return data.find((a) => a.value === v);
    });
  };

  const onEmiratesIdChange = (before: string, after: string) => {
    const pattern: [number, string][] = [
      [3, '-'],
      [8, '-'],
      [16, '-'],
    ];
    const newValue = getAutoFormattedValue(after, before, pattern);
    if (!newValue) return;
    setFieldValue('emiratesIdNumber', newValue);
  };

  const onDateInputChange = (before: string, after: string, field: string) => {
    const pattern: [number, string][] = [
      [2, '/'],
      [5, '/'],
      [10, ' '],
      [13, ':'],
    ];
    const newValue = getAutoFormattedValue(after, before, pattern);
    if (!newValue) return;
    setFieldValue(field, newValue);
  };

  const setEmiratesIdNumber = (e: string) => {
    switch (e) {
      case 'national':
        values.emiratesIdNumber = '000-0000-0000000-0';
        break;
      case 'expatriate':
        values.emiratesIdNumber = '111-1111-1111111-1';
        break;
      case 'other':
        values.emiratesIdNumber = '222-2222-2222222-2';
        break;
      case 'unknown':
        values.emiratesIdNumber = '999-9999-9999999-9';
        break;
      case 'manual':
        values.emiratesIdNumber = '';
        break;
      default:
        break;
    }
  };

  const getCliniciansList = async (clinicianId: string) => {
    if (clinicianId && clinicianId.length > 0) {
      try {
        const {data} = await getClinicians(clinicianId, values.authorityCode);
        return data.data
          .map((code: Clinician) => {
            if (!code.name)
              return {
                label: `${code.clinicianId}`,
                value: code.clinicianId,
              };
            return {label: `${code.clinicianId} - ${code.name}`, value: code.clinicianId};
          })
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      } catch (e) {
        Toast.error(e);
      }
    }
  };

  useEffect(() => {
    if (branches != null && values.providerId) {
      const authority = branches.find((b: any) => b.value === values.providerId);
      values.authorityCode = authority.authority;
    }
  }, [values.providerId]);

  useEffect(() => {
    (async () => {
      if (values.authorityCode) {
        await getAllReceiversList();
        await getAllPayersList();
      }
    })();
  }, [values.authorityCode]);

  useEffect(() => {
    (async () => {
      await getPayerReceiverPrediction(values.memberId);
    })();
  }, [values.memberId]);

  return (
    <div className={classes.formSectionElementsWrapper}>
      <h2>Information</h2>
      <Input
        name="memberId"
        label="Member ID"
        placeholder="INSUR-MEMBER"
        inputContainerClassName={classes.formElementWithBottomSpacing}
        value={values.memberId}
        onChange={(e) => handleInputChange(e, values, 'memberId')}
        error={errors.memberId && touched.memberId ? errors.memberId : null}
      />
      <SearchSelect
        className={classes.formElementWithBottomSpacing}
        defaultOptions={sPayers}
        onChange={(e) => handleInputChange(e, values, 'payerId')}
        value={values.payerId}
        name="payerId"
        placeholder="Payers"
        label="Payers"
        error={errors.payerId && touched.payerId ? errors.payerId : null}
      />
      <SearchSelect
        className={classes.formElementWithBottomSpacing}
        defaultOptions={sReceivers}
        onChange={(e) => handleInputChange(e, values, 'receiverId')}
        value={values.receiverId}
        name="receiverId"
        placeholder="Receivers"
        label="Receivers"
        error={errors.receiverId && touched.receiverId ? errors.receiverId : null}
      />
      <SelectOptions
        options={ID_TYPE}
        selectClassName={classes.formElementWithBottomSpacing}
        label={t('ID Type')}
        value={values.idType}
        onChange={(e) => {
          handleInputChange(e, values, 'idType');
          setEmiratesIdNumber(e);
        }}
      />
      {values.idType === 'manual' && (
        <Input
          name="emiratesIdNumber"
          label="Emirates ID"
          value={values.emiratesIdNumber}
          placeholder="123-1234-1234567-1"
          inputContainerClassName={classes.formElementWithBottomSpacing}
          onChange={(e) => handleInputChange(e, values, 'emiratesIdNumber')}
          onValueChange={(e) => onEmiratesIdChange(values.emiratesIdNumber, e)}
          maxLength={18}
          error={errors.emiratesIdNumber && touched.emiratesIdNumber ? errors.emiratesIdNumber : null}
        />
      )}
      <div className={classes.rowInputs}>
        <Input
          label="Start Date"
          className={classes.rowInput}
          placeholder="DD/MM/YYYY HH:MM"
          name="dateOrdered"
          value={values.dateOrdered}
          onChange={(e) => handleInputChange(e, values, 'dateOrdered')}
          onValueChange={(e) => onDateInputChange(values.dateOrdered, e, 'dateOrdered')}
          maxLength={16}
          error={errors.dateOrdered && touched.dateOrdered ? errors.dateOrdered : null}
        />
        <AsyncSearchSelect
          name="clinicianId"
          className={classes.rowInputRight}
          placeholder="Clinician"
          loadOptions={getCliniciansList}
          label="Clinician"
          value={values.clinicianId}
          onChange={(e) => handleInputChange(e, values, 'clinicianId')}
          error={errors.clinicianId && touched.clinicianId ? errors.clinicianId : null}
        />
      </div>
    </div>
  );
};

export default Information;
