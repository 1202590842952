import React from 'react';
import {KlaimDropdownSearch} from 'modules/Common/components-v2/KlaimForm';
import {createUseStyles} from 'react-jss';
import {Payer} from 'interfaces/payers.interfaces';
import {error as errorToast} from 'modules/Common/utils/toast';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {getInsurances, insuranceSelect, payerCleanUp} from 'modules/Insurance/insuranceSlice';
import {Link} from 'react-router-dom';
import {authorityCodes} from 'modules/Common/constants/Country';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {Colors} from 'modules/Common/constants/Colors';

interface ComponentProps {
  onChange?: (e: any) => void;
  isError?: boolean;
  isTouched?: boolean;
  variant?: 'secondary' | 'primary';
  branchId?: string;
}

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    errorMessage: {
      color: theme.formElement.color.error,
    },
  };
});

const InsuranceDropdown = ({onChange, branchId, ...props}: ComponentProps) => {
  const dispatch = useAppDispatch();
  const {payers, error} = useAppSelector(insuranceSelect);
  const [payersList, setPayersList] = React.useState<
    {
      value: any;
      label: string;
    }[]
  >([]);
  const [limit, setLimit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const [sortBy] = React.useState<string>('');
  const {user} = useAppSelector(authSelect);
  const classes = useStylesFromThemeFunction();
  React.useEffect(() => {
    dispatch(
      getInsurances({
        limit,
        search,
        offset: (offset - 1) * limit,
        authorityCode: authorityCodes.find((authority: any) => authority.country === user.organization.country)?.value,
        branchId,
        classification: 'Insurance',
      }),
    );
  }, [search, branchId]);

  React.useEffect(() => {
    if (error) {
      errorToast(error.message);
    }
  }, [error]);

  React.useEffect(() => {
    return () => {
      dispatch(payerCleanUp());
      dispatch(payerCleanUp());
    };
  }, []);

  React.useEffect(() => {
    if (payers) {
      setPayersList(
        payers.data.length > 0
          ? payers.data.map((payer: any) => {
              if (branchId) {
                return {
                  value: payer.code,
                  label: payer.name,
                };
              }
              return {
                value: payer.payerCode,
                label: payer.name,
              };
              // return {
              //   value: payer.resource.identifier[0].value,
              //   label: payer.resource.type[0].text,
              // };
            })
          : [],
      );
    }
  }, [payers]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const handleInputChange = (newValue: any) => {
    setSearchTyping(newValue);
  };

  const handleOnChange = (e: any) => {
    if (onChange) {
      const payerResult = payers.data.find((payer: any) => {
        if (payer.code) {
          return payer.code === e;
        }
        return payer.payerCode === e;
      });

      onChange(payerResult);
    }
  };

  return (
    <div>
      {payers.data.length > 0 ? (
        <KlaimDropdownSearch
          {...props}
          label={'Insurance'}
          options={payersList}
          onChange={handleOnChange}
          onInputChange={handleInputChange}
        />
      ) : (
        <span style={{fontSize: 14, textAlign: 'center'}}>
          The selected branch does not have insurance. Please add{' '}
          <Link to="/settings/insurance-companies">
            <span style={{color: Colors.purple}}>here</span>
          </Link>
        </span>
      )}
    </div>
  );
};

export default InsuranceDropdown;
