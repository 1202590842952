import styled, {css} from 'styled-components';

export const OfflineContainer = styled.div`
  background: #f5f7fb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
`;
export const Title = styled.div`
  text-align: left;
  font: normal normal 600 14px/30px Poppins;
  letter-spacing: 0.42px;
  color: #222b45;
`;

export const Desc = styled.div`
  font: normal normal normal 12px/16px Poppins;
  letter-spacing: 0.36px;
  color: #696f81;
`;
