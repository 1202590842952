import React from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import ErrorExclamationIcon from 'assets/component/ErrorExclamaitonIcon';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.gray,
    padding: 4,
    minHeight: 60,
    backgroundColor: Colors.errorBackgroundRed,
  },
  title: {
    color: Colors.errorTextRed,
    fontSize: 18,
    fontWeight: '600',
  },
  message: {
    color: Colors.gray,
    fontSize: 14,
    fontWeight: '500',
  },
  messageContainer: {
    flex: 1,
    padding: 4,
  },
  messageIconContainer: {
    display: 'flex',
    padding: 4,
  },
  icon: {
    padding: 4,
  },
});

interface ComponentProps {
  title: string;
  message: string;
  messages?: string[];
}

const KlaimErrorMessage: React.FC<ComponentProps> = ({title, message, messages}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={classes.container}>
      <div className={classes.messageIconContainer}>
        <div className={classes.icon}>
          <ErrorExclamationIcon fill={Colors.red} />
        </div>
        <div className={classes.messageContainer}>
          <div className={classes.title}>{title}</div>
          <div className={classes.message}>{message}</div>
        </div>
      </div>

      {messages && (
        <ul>
          {messages.map((mess) => (
            <li>{mess}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default KlaimErrorMessage;
