export interface ClaimData {
  skip: number;
  limit: number;
  data: ClaimList;
}

export interface ClaimList {
  count?: number;
  data?: Array<ClaimListDetails>;
}

export interface ClaimListDetails {
  id: string;
  claim_id: string;
  status: ClaimStatus;
  submission_error: boolean;
  receiver_id: string;
  payer_id: string;
  first_submission_date: string;
  pending_submission: boolean;
  claim: {
    gross_amount: number;
    patient_share: number;
    net_amount: number;
    resubmission_count: number;
  };
  remittance: any;
  member_id: string;
  payer_name: string;
  receiver_name: string;
}

export enum ClaimStatus {
  submitting = 'submitting',
  submitted = 'submitted',
  paid = 'paid',
  partially_paid = 'partially_paid',
  rejected = 'rejected',
  draft = 'draft',
  error = 'error',
}

export interface ClaimDetailsInterface {
  id: string;
  claim_id: string;
  status: ClaimStatus;
  submission_error: boolean;
  receiver_id: string;
  receiver_name: string;
  payer_id: string;
  payer_name: string;
  first_submission_date: string;
  pending_submission: boolean;
  member_id: string;
  file_ids: Array<any>;
  sender_id: string;
  submissions: Array<Submission>;
  activities: Array<Activity>;
  encounter: PatientEncounter;
  diagnoses: Array<Diagnosis>;
  claim: {
    gross_amount: number;
    patient_share: number;
    net_amount: number;
    resubmission_count: number;
  };
  remittance: Remittance;
  history?: Array<any>;
}

export interface Submission {
  id: string;
  file_id: string;
  status: 'submitting' | 'submitted' | 'error';
  submission_error?: {
    code: ClaimSubmissionError;
    details: string;
  };
  submission_date: string;
  id_payer?: string;
  gross_amount: number;
  patient_share: number;
  net_amount: number;
  activity_ids: Array<string>;
  resubmission_type?: string;
  resubmission_comment?: string;
  resubmission_attachment_id?: string;
}

export type ClaimSubmissionError = 'invalid_credentials' | 'unexpected_error' | 'dhpo_error';

export interface ActivityHistory {
  claimSubmission?: Submission;
  activityRemittanceAdvice?: Remittance;
  date: string;
}

export interface PatientEncounter {
  facility_id: string;
  patient_id: string;
  type: number;
  start_date: string;
  end_date: string;
  start_type: number;
  end_type: number;
}

export interface Diagnosis {
  type: 'Principal' | 'Secondary';
  code: string;
  short_description: string;
  long_description: string;
}

export interface Activity {
  id: string;
  activity_ids: Array<string>;
  active_activity_id: string;
  first_submission_date: string;
  start_date: string;
  activity_performed: {
    type: number;
    code: string;
    short_description: string;
    long_description: string;
    full_description: string;
  };
  quantity: number;
  gross: number;
  patient_share: number;
  net: number;
  clinician: {
    id: string;
    name: string;
  };
  prior_authorization_provider_id: string;
  remittance_advices: Array<any>;
  observations: [
    {
      type: string;
      code: string;
      value: string;
      value_type: string;
    },
  ];
  payment_amount: number;
  history: Array<any>;
}

export interface Remittance {
  payment_amount: number;
  denied_amount: number;
  latest_payment_reference: string;
  latest_settlement_date: string;
  payment_ref: string;
  settlement_date: string;
  denial?: {
    code: string;
    description?: string;
  };
}

export interface ClaimDraftList {
  count?: number;
  data?: Array<ClaimDraft>;
}

export interface ClaimDraftData {
  limit: number;
  skip: number;
  data: ClaimDraftList;
}

export interface ClaimDraft {
  id: string;
  creationDate: Date;
  draft: Draft;
  memberId: string;
  percentCompleted: string;
  receiver: {
    id: string;
    name: string;
  };
}

export interface Draft {
  encounter: Encounter;
  diagnoses: ClaimDraftDiagnosis[];
  activities: ClaimDraftActivity[];
  providerId: string;
}

export interface ClaimDraftActivity {
  activityId: string;
  quantity: number;
  gross: number;
  net: number;
  patientShare: number;
  observations: any[];
}

export interface ClaimDraftDiagnosis {
  type: string;
  code: string;
}

export interface Encounter {
  type: number;
  startType: number;
}

export interface PayerReceiver {
  payers: Array<{
    id: string;
    name: string;
  }>;
  receivers: Array<{
    id: string;
    name: string;
  }>;
}

export interface ClaimEncounter {
  patientId: string;
  start: string;
  startType: any;
  end: any;
  endType: any;
  type: number;
}

export interface ClaimDiagnosis {
  type: string;
  code: string;
  defaultValue?: string;
  diagnoseId: string;
  dxInfo: {
    code: string;
    type: string;
  };
}

export interface Observation {
  fileBase64?: undefined;
  fileId?: undefined;
  type: string;
  code: string;
  value: string;
  valueType: string;
  observationId: string;
}

export interface ClaimActivity {
  clinicianValue?: any;
  activityPerformed?: any;
  unit?: string;
  orderingClinicianId?: string;
  activityId: string;
  start: string;
  type: any;
  code: string;
  quantity: number;
  gross: number;
  net: number;
  patientShare: number;
  clinicianId: string;
  priorAuthorizationId: string;
  observations: Observation[];
  activityIds: string;
}

export interface Claim {
  status?: string;
  memberId: string;
  claimId?: string;
  payerId: string;
  receiverId: string;
  emiratesIdNumber?: string;
  encounter: ClaimEncounter;
  diagnoses: ClaimDiagnosis[];
  activities: ClaimActivity[];
}

export enum PayerInsurerTypes {
  RECEIVERS = 'TPA',
  PAYERS = 'Insurance',
}

export const ClaimInitialFormValue = {
  authorityCode: '',
  providerId: '',
  idType: 'manual',
  emiratesIdNumber: '',
  memberId: '',
  claimId: '',
  payerId: '',
  receiverId: '',
  encounter: {
    patientId: '',
    start: '',
    startType: 1,
    end: '',
    endType: 1,
    type: 1,
  },
  diagnoses: [
    {
      type: 'Principal',
      code: '',
      diagnoseId: '0',
      dxInfo: {
        code: '',
        type: 'Year of Onset',
      },
    },
  ],
  activities: [
    {
      activityIds: '0',
      activityId: '',
      start: '',
      type: 3,
      code: '',
      quantity: 0,
      gross: 0,
      net: 0,
      patientShare: 0,
      clinicianId: '',
      priorAuthorizationId: '',
      observations: [
        {
          type: 'LOINC',
          code: '',
          value: '',
          valueType: '',
          observationId: '0',
        },
      ],
    },
  ],
  resubmission: {type: '', comment: ''},
};

export interface DiagnosesCodes {
  authorityCode: string;
  createdAt: string;
  diagnosisCode: string;
  longDescription: string;
  shortDescription: string;
  updatedAt: string;
  versionCode: string;
}

export interface Clinician {
  name: string;
  authorityCode: string;
  clinicianId: string;
  orderingClinicianId: string;
  licensingAuthority: string;
  createdAt: string;
  updatedAt: string;
}

export interface ActivityCode {
  versionCode: string;
  authorityCode: string;
  activityType: string;
  activityCode: string;
  shortDescription: string;
  longDescription: string;
  createdAt: string;
  updatedAt: string;
  drug?: any;
}

export const ID_TYPE = [
  {
    label: 'Manual Input',
    value: 'manual',
  },
  {
    label: 'National without a card',
    value: 'national',
  },
  {
    label: 'Expatriate without a card',
    value: 'expatriate',
  },
  {
    label: 'Non-national/non-expatriate without a card',
    value: 'other',
  },
  {
    label: 'Unknown status, without a card',
    value: 'unknown',
  },
];

export const DIAGNOSIS_TYPE = [
  {label: 'Principal', value: 'Principal'},
  {
    label: 'Secondary',
    value: 'Secondary',
  },
  {label: 'Admitting', value: 'Admitting'},
];
export const ACTIVITY_TYPE = [
  {label: 'CPT', value: 3},
  {label: 'HCPCS', value: 4},
  {label: 'Drug', value: 5},
  {label: 'Dental', value: 6},
  {
    label: 'Service code',
    value: 8,
  },
  {label: 'DRG', value: 9},
  {label: 'Scientific code', value: 10},
];

export const OBSERVATION_TYPE = [
  {label: 'LOINC', value: 'LOINC'},
  {label: 'Text', value: 'Text'},
  {label: 'File', value: 'File'},
  {label: 'Universal Dental', value: 'Universal Dental'},
  {label: 'Financial', value: 'Financial'},
  {label: 'Grouping', value: 'Grouping'},
  {label: 'ERX', value: 'ERX'},
  {label: 'Result', value: 'Result'},
];

export const ENCOUNTER_START_TYPE = [
  {label: 'Elective', value: 1},
  {label: 'Emergency', value: 2},
  {label: 'Transfer', value: 3},
  {label: 'Live Birth', value: 4},
  {label: 'Still birth', value: 5},
  {label: 'Dead on Arrival', value: 6},
  {label: 'Continuing Encounter', value: 7},
];

export const ENCOUNTER_END_TYPE = [
  {label: 'Discharged with approval', value: 1},
  {label: 'Discharged against advice', value: 2},
  {label: 'Discharged absent without leave', value: 3},
  {label: 'Discharged transfer to acute care', value: 4},
  {label: 'Deceased', value: 5},
  {label: 'Not Discharged', value: 6},
  {label: 'Discharge transfer to not acute care', value: 7},
  {label: 'None', value: 8},
];

export const AutoGenerateClaimId = {
  YES: 'YES',
  NO: 'NO',
};

export const ResubmissionType = {
  CORRECTION: 'Correction',
  INTERNAL_COMPLAINT: 'internal complaint',
};
