import React from 'react';
import {idType, gender, maritalStatus} from 'modules/Common/constants/DropdownOptions';
import {KlaimDropdownSearch, KlaimInput} from 'modules/Common/components-v2/KlaimForm';
import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import {IPatientRel} from 'interfaces/pre-auth.interface';

interface ComponentProps {
  onChange?: (values: IPatientRel) => void;
  initialValue?: IPatientRel;
}

const PatientRelationship: React.FC<ComponentProps> = ({onChange, initialValue}) => {
  const [relationshipToPatient, setRelationshipToPatient] = React.useState<IPatientRel>({
    name: '',
    dateOfBirth: '',
    gender: '',
    phone: '',
    documentIdType: '',
    documentId: '',
    maritalStatus: '',
    isValid: false,
  });

  React.useEffect(() => {
    let isValid = false;
    if (
      relationshipToPatient.name !== '' &&
      relationshipToPatient.dateOfBirth !== '' &&
      relationshipToPatient.gender !== '' &&
      relationshipToPatient.documentIdType !== '' &&
      relationshipToPatient.documentId !== '' &&
      relationshipToPatient.maritalStatus !== ''
    ) {
      isValid = true;
    } else {
      isValid = false;
    }
    if (onChange)
      onChange({
        ...relationshipToPatient,
        isValid,
      });
  }, [relationshipToPatient]);

  return (
    <KlaimContainer
      variant="secondary"
      style={{
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'repeat(2, minmax(0, 2fr))',
        marginBottom: 20,
      }}
    >
      <h2 style={{gridColumn: 'span 2 / span 2'}}>Relationship to Patient</h2>
      <KlaimDropdownSearch
        label={'ID Type'}
        options={idType}
        placeholder={'Select ID Type'}
        value={relationshipToPatient.documentIdType}
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            documentIdType: value,
          })
        }
      />
      <KlaimInput
        placeholder="13 - 31 - 00 - 00 - 03"
        label="ID Number"
        value={relationshipToPatient.documentId}
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            documentId: value,
          })
        }
      />

      <KlaimInput
        value={relationshipToPatient.name}
        label="Full Name"
        placeholder="Name"
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            name: value,
          })
        }
      />
      <KlaimInput
        value={relationshipToPatient.dateOfBirth}
        type="calendar"
        label="Date of Birth"
        placeholder={'MM/DD/YYYY'}
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            dateOfBirth: value,
          })
        }
      />
      <KlaimDropdownSearch
        value={relationshipToPatient.gender}
        label={'Gender'}
        options={gender}
        placeholder={'Select Gender'}
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            gender: value,
          })
        }
      />
      <KlaimDropdownSearch
        value={relationshipToPatient.maritalStatus}
        label={'Marital Status'}
        options={maritalStatus}
        placeholder={'Select Marital Status'}
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            maritalStatus: value,
          })
        }
      />
      <KlaimInput
        value={relationshipToPatient.phoneNumber}
        optional
        type="phone"
        label="Mobile Number"
        defaultCountry="SA"
        placeholder="+966 000 XXX XXXX"
        onChange={(value: any) =>
          setRelationshipToPatient({
            ...relationshipToPatient,
            phoneNumber: value,
          })
        }
      />
    </KlaimContainer>
  );
};

export default PatientRelationship;
