import React from 'react';
import {StepperContext} from '../KlaimStepper/index';

function KlaimStepperHeader() {
  const {totalSteps, step} = React.useContext(StepperContext);

  return (
    <div style={{display: 'grid', gridTemplateColumns: `repeat(${totalSteps}, minmax(0, 1fr))`, columnGap: 4}}>
      {[...Array(totalSteps)].map((currentStep: number, index: number) => {
        return (
          <div
            style={{
              border: `1px solid #6135FB`,
              backgroundColor: `${index < step + 1 ? '#6135FB' : 'white'}`,
              height: 10,
              borderRadius: 5,
            }}
            key={index}
          />
        );
      })}
    </div>
  );
}

export default KlaimStepperHeader;
