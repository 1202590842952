import {FC, useCallback, useState} from 'react';
import {createUseStyles} from 'react-jss';
import uploadFileCloudIcon from 'assets/upload_file_cloud_icon.svg';
import {useDropzone} from 'react-dropzone';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    dragAndDropText: {
      color: 'rgba(48, 46, 56, 0.6)',
      '& span': {
        fontWeight: 'bold',
      },
    },
    label: {
      color: '#222b45',
      fontSize: 12,
      fontWeight: theme.font.weightSemibold,
      marginBottom: 3,
      marginLeft: 10,
    },
    uploadBox: {
      alignItems: 'center',
      background: '#F5F7FB',
      borderColor: '#E7ECF5',
      borderRadius: 10,
      borderStyle: 'dotted',
      borderWidth: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 150,
    },
    uploadBoxActive: {
      borderColor: '#6135FB',
    },
    uploadBoxRow: {
      flexDirection: 'row',
      minHeight: '36px !important',
      alignItems: 'center',
      '& p': {
        marginTop: 'unset !important',
        marginLeft: '10px',
      },
    },
  };
});

interface ComponentProps {
  label?: string;
  className?: string;
  theme?: KlaimTheme;
  acceptedFileTypes?: string;
  setFieldValue?: any;
  fieldValue?: string;
  type?: string;
}

enum LayoutType {
  Column = 'column',
  Row = 'row',
}

const FileUpload: FC<ComponentProps> = ({
  label,
  className,
  theme,
  acceptedFileTypes,
  setFieldValue,
  fieldValue,
  type = LayoutType.Column,
}) => {
  const classes = useStylesFromThemeFunction({theme});
  const onDrop = useCallback(async (acceptedFiles) => {
    await handleFileUpload(acceptedFiles[0]);
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxSize: 5 * 1024 * 1024,
    maxFiles: 1,
  });
  const [fileName, setFileName] = useState('');

  const handleFileUpload = async (file: File) => {
    if (file) {
      const fileBase64 = await getBase64FromFile(file);
      setFieldValue(fieldValue, fileBase64);
      setFileName(file.name);
    }
  };

  const getBase64FromFile = async (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    await new Promise<void>((resolve, reject) => {
      reader.onload = () => resolve();
      reader.onerror = () => reject();
    });
    return btoa(reader.result as string);
  };

  return (
    <div className={className}>
      {label && <div className={classes.label}>{label}</div>}
      <div
        className={`${classes.uploadBox} ${isDragActive ? classes.uploadBoxActive : ''} ${
          type === LayoutType.Row && classes.uploadBoxRow
        }`}
        {...getRootProps()}
      >
        <img src={uploadFileCloudIcon} alt="upload-file" />
        <p className={classes.dragAndDropText}>
          {!fileName && (isDragActive ? 'Drop your file here' : 'You can drag-and-drop or click to select')}
          <span>{fileName}</span>
        </p>
        <input
          {...getInputProps()}
          onChange={async (e) => {
            if (e) {
              const file = e.target.files;
              if (file) {
                await handleFileUpload(file[0]);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default FileUpload;
