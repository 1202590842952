import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Register/CompletedRegistration/CompletedRegistration';
import sentEmail from 'assets/email_sent_v3.svg';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';
import {useHistory} from 'react-router-dom';
import {emailStore, registerSelect} from 'modules/Auth/v3/features/Register/registerSlice';
import {useAppDispatch, useAppSelector} from 'modules/App/store';

export interface Values {
  [field: string]: any;
}

interface ComponentProps {
  prev?: any;
  data?: any;
}

const CompletedRegistration: React.FC<ComponentProps> = ({prev, data}) => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {email} = useAppSelector(registerSelect);
  return (
    <div className={classes.completedRegistration}>
      <div className={classes.subCompletedRegistration}>
        <div className={classes.headerContainer}>
          <h1>Registration complete</h1>
          <p className={classes.message}>Please check {email} for a confirmation link to continue.</p>
        </div>
        <div className={classes.imageContainer}>
          <img src={sentEmail} alt="email_sent" />
        </div>
        <div className={classes.questionContainer}>
          <p className={classes.message}>
            You will receive an email within ~1 minute. Be sure to check your spam folder, too.
          </p>
          <p>
            No email? <KlaimSpan onClick={() => prev()}>Correct Email</KlaimSpan>
          </p>
        </div>
        <div className={classes.footerContainer}>
          <Button
            title="Done"
            onClick={() => {
              history.push('/auth');
              dispatch(emailStore(''));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompletedRegistration;
