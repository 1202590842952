import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Common/components/KlaimPaginationContainer/KlaimPaginationContainer';
import ArrowLeft from 'assets/arrow_left_icon.svg';
import ArrowRight from 'assets/arrow_right_icon.svg';
import ArrowDown from 'assets/arrow_down_icon.svg';

interface ComponentProps {
  currentPage: number;
  totalCount: number;
  siblingCount?: number;
  pageSize: number;
  onPageChange: React.Dispatch<React.SetStateAction<number>>;
  label?: string;
  className?: string;
}

const KlaimPaginationContainer: React.FC<ComponentProps> = ({
  children,
  totalCount,
  siblingCount = 1,
  pageSize,
  currentPage,
  onPageChange,
  label = 'Custom label',
  className,
}) => {
  const node = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const classes = useStylesFromThemeFunction();
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setOpen(false);
  };
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const dropDownPage = () => {
    const elements = [];
    for (let i = 1; i <= Math.ceil(totalCount / pageSize); i++) {
      elements.push(
        <button
          className={`${classes.numberDropdownButton} ${currentPage === i && classes.currentPage}`}
          onClick={() => {
            onPageChange(i);
            setOpen(false);
          }}
        >
          {i}
        </button>,
      );
    }
    return elements;
  };

  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 0;
  return (
    <div className={className}>
      <div className={classes.tableContainer}>{children}</div>
      {currentPage === 0 || paginationRange.length < 2 ? null : (
        <div className={classes.paginationContainer}>
          <span className={classes.pageLabel}>
            Total number of {label}: {totalCount}
          </span>
          <ul className={classes.pageContainer}>
            {/* <li className={classes.dropdownContainer}>
              <div className={classes.dropDownButtonContainer} ref={node}>
                <button
                  className={classes.dropDownButton}
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <img src={ArrowDown} alt="down" />
                </button>
                <span className={classes.dropdownLabel}>{currentPage}</span>
              </div>
              {open && (
                <div className={classes.dropdownContentContainer}>
                  <div className={classes.numberDropdownContainer}>{dropDownPage()}</div>
                </div>
              )}
            </li> */}
            {currentPage > 1 && (
              <li className={`${classes.liReset} ${classes.iconContainer}`} onClick={onPrevious}>
                <img className={classes.imgIcon} src={ArrowLeft} alt="left" />
              </li>
            )}
            <div className={classes.numberContainer}>
              {paginationRange &&
                paginationRange.map((pageNumber) => {
                  if (pageNumber === DOTS) {
                    return (
                      <li className={`${classes.liReset}`} key={pageNumber}>
                        <div className={classes.pageDot}>
                          <span>&#8230;</span>
                        </div>
                      </li>
                    );
                  }
                  const numLength = pageNumber.toString().length;
                  let numStyle = {width: 32};
                  if (numLength > 2) numStyle = {width: 32 + numLength * 8};
                  return (
                    <li className={`${classes.liReset}`} onClick={() => onPageChange(pageNumber)} key={pageNumber}>
                      <div
                        className={`${classes.pageNumber} ${
                          parseInt(pageNumber, 10) === currentPage && classes.activePageNumber
                        }`}
                        style={numStyle}
                      >
                        <span>{pageNumber}</span>
                      </div>
                    </li>
                  );
                })}
            </div>
            {currentPage <= totalCount / pageSize && (
              <li className={`${classes.liReset} ${classes.iconContainer}`} onClick={onNext}>
                <img className={classes.imgIcon} src={ArrowRight} alt="right" />
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default KlaimPaginationContainer;
export const DOTS = '...';

const range = (start: any, end: any) => {
  const length = end - start + 1;
  return Array.from({length}, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: {
  totalCount: number;
  pageSize: number;
  siblingCount: number;
  currentPage: number;
}) => {
  const paginationRange = React.useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
