import React from 'react';
import {IRoute} from 'interfaces/route.interface';
import Sidebar from 'modules/Auth/v3/components/Sidebar';
import Body from 'modules/Auth/v3/components/Body';
import AuthV3Layout from 'modules/Common/layout/AuthV3Layout';

const Auth: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  return <AuthV3Layout sidebar={<Sidebar />} body={<Body routes={routes} />} forceFullBody />;
};

export default Auth;
