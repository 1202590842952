import React, {useEffect, useRef, useState} from 'react';
import {FormikValues} from 'formik';
import {useTranslation} from 'react-i18next';
import {getAllPayersReceivers, getPayerInsurer} from '../../api';
import {AutoGenerateClaimId, PayerInsurerTypes} from '../../api/types';
import {useStylesFromThemeFunction} from '../../containers/ClaimForm/ClaimForm';
import SearchSelect from '../../../Common/components/SearchSelect';
import Input from '../../../Common/components/Input';
import * as Toast from '../../../Common/utils/toast';
import TypeSelect from '../../../Common/components/TypeSelect';

interface ComponentProps {
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  claimData: any;
  setFieldTouched: any;
}

export const formatPayerReceiverData = (data: Array<any>) => {
  return data
    .map((d) => {
      return {label: `${d.payerCode} - ${d.name}`, value: d.payerCode};
    })
    .sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
};

const ClaimInformation: React.FC<ComponentProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  claimData,
  setFieldTouched,
}) => {
  const classes = useStylesFromThemeFunction();
  const {t} = useTranslation();
  const [autoGenerateClaimId, setAutoGenerateClaimId] = useState(AutoGenerateClaimId.NO);
  const [sPayers, setSPayers] = useState<Array<any>>([]);
  const [sReceivers, setSPReceivers] = useState<Array<any>>([]);
  const branches = JSON.parse(localStorage.getItem('userBranches') as string);
  const [payerLoaded, setPayerLoaded] = useState(false);
  const [receiverLoaded, setReceiverLoaded] = useState(false);
  const limit = 300;

  const getAutoGenerateClaimId = (providerID: string, receiverId: string) => {
    const date = new Date();
    const hours =
      date.getHours().toString().length === 1 ? date.getHours().toString().padStart(2, '0') : date.getHours();
    const minutes =
      date.getMinutes().toString().length === 1 ? date.getMinutes().toString().padStart(2, '0') : date.getMinutes();
    const seconds =
      date.getSeconds().toString().length === 1 ? date.getSeconds().toString().padStart(2, '0') : date.getSeconds();
    const year = date.getFullYear();
    const month =
      date.getMonth().toString().length === 1 ? date.getMonth().toString().padStart(2, '0') : date.getMonth();
    const day = date.getDate().toString().length === 1 ? date.getDate().toString().padStart(2, '0') : date.getDate();
    return `${providerID}-${receiverId}-${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  const claimAutoGenerate = (e: any, formValues: FormikValues) => {
    setAutoGenerateClaimId(e);
    if (e === AutoGenerateClaimId.YES) {
      claimData.current.values.claimId = getAutoGenerateClaimId(
        claimData?.current?.values?.providerId,
        formValues?.receiverId || claimData?.current?.values?.receiverId,
      );
    }
  };

  const handleInputChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const handleInputIDChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event.target.value !== undefined ? event.target.value : '';
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const getAllPayersList = async () => {
    try {
      const {data} = await getAllPayersReceivers(PayerInsurerTypes.PAYERS, values.authorityCode, limit);
      const payers = formatPayerReceiverData(data.data);
      setSPayers(payers);
      setPayerLoaded(true);
    } catch (e) {
      Toast.error(t(e.message));
    }
  };

  const getAllReceiversList = async () => {
    try {
      const {data} = await getAllPayersReceivers(PayerInsurerTypes.RECEIVERS, values.authorityCode, limit);
      const receivers = formatPayerReceiverData(data.data);
      setSPReceivers(receivers);
      setReceiverLoaded(true);
    } catch (e) {
      Toast.error(t(e.message));
    }
  };

  useEffect(() => {
    if (branches != null) {
      const authority = branches.find((b: any) => b.value === values.providerId);
      values.authorityCode = authority.authority;
    }
  }, [values.providerId]);

  useEffect(() => {
    (async () => {
      if (values.authorityCode) {
        await getAllReceiversList();
        await getAllPayersList();
      }
    })();
  }, [values.authorityCode]);

  useEffect(() => {
    (async () => {
      if (payerLoaded || receiverLoaded || values.memberId) {
        await getPayerReceiverPrediction(values.memberId);
      }
    })();
  }, [payerLoaded, receiverLoaded, values.memberId]);

  const getPayerReceiverPrediction = async (memberId: any) => {
    if (memberId && memberId.length > 3) {
      const payersList: any = await getAllPayersReceivers(PayerInsurerTypes.PAYERS, values.authorityCode, limit);
      const receiversList: any = await getAllPayersReceivers(PayerInsurerTypes.RECEIVERS, values.authorityCode, limit);
      const payerData: any = payersList.data.data.map((payer: {name: string; payerCode: string}) => {
        return {label: `${payer.payerCode} - ${payer.name}`, value: payer.payerCode};
      });
      const receiverData: any = receiversList.data.data.map((receiver: {name: string; payerCode: string}) => {
        return {label: `${receiver.payerCode} - ${receiver.name}`, value: receiver.payerCode};
      });
      const pData = filterPayerReceiverData(payerData, 'payers');
      const rData = filterPayerReceiverData(receiverData, 'receivers');
      const currentDraftVal = JSON.parse(localStorage.getItem('currentDraft') as string);
      setFieldValue('payerId', currentDraftVal?.payerId);
      setFieldValue('receiverId', currentDraftVal?.receiverId);
      setSPayers([...pData]);
      setSPReceivers([...rData]);
    }
  };

  const filterPayerReceiverData = (payerData: Array<{label: ''; value: ''}>, type?: 'payers' | 'receivers') => {
    payerData = payerData.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
    let data: any[];
    const dataType = type === 'payers' ? sPayers : sReceivers;
    let payerReceiverData = [];
    for (let i = 0; i < dataType.length; i++) {
      if (dataType[i].options) {
        payerReceiverData = dataType[i].options;
      } else {
        payerReceiverData = dataType;
      }
    }
    if (type === 'payers') {
      data = [...payerData, {label: '---ALL---', options: [...payerReceiverData]}];
    } else {
      data = [...payerData, {label: '---ALL---', options: [...payerReceiverData]}];
    }
    return Array.from(new Set(data.map((a) => a.value))).map((v) => {
      return data.find((a) => a.value === v);
    });
  };

  return (
    <div className={classes.formSectionElementsWrapper}>
      <h2>Claim Information</h2>
      <TypeSelect
        className={classes.formElementWithBottomSpacing}
        label={t('Auto-Generate Claim ID')}
        values={[
          {title: t('Yes'), value: AutoGenerateClaimId.YES},
          {title: t('No'), value: AutoGenerateClaimId.NO},
        ]}
        selectedValue={autoGenerateClaimId}
        setSelectedValue={(e) => claimAutoGenerate(e, values)}
      />
      {autoGenerateClaimId === AutoGenerateClaimId.NO && (
        <Input
          name="claimId"
          label="Claim ID"
          placeholder="KLAIM-XXXX1234"
          inputContainerClassName={classes.formElementWithBottomSpacing}
          onChange={handleChange}
          value={values.claimId}
          error={errors.claimId && touched.claimId ? errors.claimId : null}
        />
      )}
      <Input
        name="memberId"
        label="Member ID"
        placeholder={t('INSUR-MEMBER')}
        inputContainerClassName={classes.formElementWithBottomSpacing}
        value={typeof values.memberId === undefined ? '' : values.memberId}
        onChange={(e) => handleInputIDChangeSecond(e, values, 'memberId')}
        error={errors.memberId && touched.memberId ? errors.memberId : null}
      />
      <SearchSelect
        className={classes.formElementWithBottomSpacing}
        defaultOptions={sPayers}
        onChange={(e) => handleInputChangeSecond(e, values, 'payerId')}
        value={values.payerId}
        name="payerId"
        placeholder={t('Choose Payer/Insurer')}
        label="Payer"
        error={errors.payerId && touched.payerId ? errors.payerId : null}
      />
      <SearchSelect
        defaultOptions={sReceivers}
        onChange={(e) => handleInputChangeSecond(e, values, 'receiverId')}
        value={values.receiverId}
        name="receiverId"
        placeholder={t('Choose Receiver')}
        label="Receiver"
        error={errors.receiverId && touched.receiverId ? errors.receiverId : null}
      />
    </div>
  );
};

export default ClaimInformation;
