import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {useAppSelector} from 'modules/App/store';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {IRoute} from 'interfaces/route.interface';
import {stateType} from './PublicRoute';

const PublicRoute: React.FC<IRoute> = ({component: Component, routeKey, ...rest}) => {
  const {isAuthenticated} = useAppSelector(authSelect);
  const {state} = useLocation<stateType>();
  return (
    <Route
      key={routeKey}
      render={(props) => {
        return isAuthenticated ? (
          <Redirect
            to={{
              pathname: state ? state.from.pathname : '/',
              state: {from: props.location},
              search: props.location.search,
            }}
          />
        ) : (
          <Component {...props} {...rest} />
        );
      }}
    />
  );
};

export default PublicRoute;
