import React from 'react';

import KlaimLogo from 'assets/login_logo.svg';

import {useStylesFromThemeFunction} from './Loading';

interface ComponentProps {
  customContainer?: string;
}

const Loading: React.FC<ComponentProps> = ({customContainer}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={`${customContainer}  ${classes.container}`}>
      <div>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={KlaimLogo} alt="KlaimLogo" />
        </div>
        <div className={classes.loadingBar} />
      </div>
    </div>
  );
};

export default Loading;
