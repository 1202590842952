import {createUseStyles} from 'react-jss';
import {Colors} from '../../../Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0.5px 2px #60617029',
      borderRadius: 10,
      marginBottom: 38,
    },
    header: {
      textAlign: 'left',
      font: 'normal normal bold 22px/34px Poppins',
      letterSpacing: 0.22,
      color: '#2C273C',
      marginBottom: 38,
    },
    description: {
      textAlign: 'left',
      font: 'normal normal normal 15px/34px Poppins',
      letterSpacing: 0.45,
      color: '#2C273C',
      marginBottom: 38,
    },
    spacedRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 28,
    },
    searchInput: {
      flex: 1,
      padding: '10px 16px',
      backgroundColor: Colors.blueGrayLight,
      fontSize: 17,
      border: '1px solid #E7ECF5',
      borderRadius: 10,
      minWidth: 300,
      maxWidth: 300,
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 12,
    },
    label: {
      font: 'normal normal 600 13px/30px Poppins',
      letterSpacing: '0.39px',
      color: '#302E38',
      opacity: 1,
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '6px 12px',
    },
    value: {
      font: 'normal normal 500 13px/30px Poppins',
      letterSpacing: '0.39px',
      color: '#48444f',
      opacity: 1,
    },
    button: {
      width: 75,
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: '5px 15px',
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    navLink: {
      textDecoration: 'none',
    },
    br: {
      height: 50,
      width: 1,
    },
    delete: {
      backgroundColor: 'red',
      border: 'none',
    },
    statuse: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#E64444',
      fontSize: 12,
      padding: '2px 6px',
      backgroundColor: '#FFA7A7',
      borderRadius: 5,
      display: 'inline-block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };
});
