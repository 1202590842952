import React, {FC} from 'react';
import {Colors} from 'modules/Common/constants/Colors';
import {useStylesFromThemeFunction} from './ErxStatus';
import {ErxStatus} from './types';

interface ComponentProps {
  status?: ErxStatus;
  submissionError?: boolean;
}

const StatusDotColor = {
  rejected: `${Colors.red}`,
  dispensed: `${Colors.lightGreen}`,
  authorized: `${Colors.turquoiseBlue}`,
  canceled: `${Colors.grayWithOpacity}`,
  request_authorization: `${Colors.purple}`,
};

const getStatusName = (status: ErxStatus | undefined) => {
  switch (status) {
    case ErxStatus.requesting_authorization:
      return 'Requesting Auth.';
    case ErxStatus.authorized:
      return 'Authorized';
    case ErxStatus.rejected:
      return 'Rejected';
    case ErxStatus.canceled:
      return 'Canceled';
    case ErxStatus.dispensed:
      return 'Dispensed';
    default:
      return '–';
  }
};

const ErxStatusField: FC<ComponentProps> = ({status, submissionError}) => {
  const classes = useStylesFromThemeFunction();
  const loader = false;

  const setErxStatus = (erxStatus: ErxStatus | undefined) => {
    switch (erxStatus) {
      case ErxStatus.dispensed:
        return StatusDotColor.dispensed;
      case ErxStatus.authorized:
        return StatusDotColor.authorized;
      case !submissionError && ErxStatus.requesting_authorization:
        return StatusDotColor.request_authorization;
      case submissionError || ErxStatus.rejected:
        return StatusDotColor.rejected;
      case ErxStatus.canceled:
        return StatusDotColor.canceled;
      default:
        return '#ffffff';
    }
  };

  // TODO LOADER ON REQUEST AUTHORIZATION

  return (
    <div className={classes.statusColumn}>
      {submissionError || status === ErxStatus.requesting_authorization ? (
        <div className={classes.loader} />
      ) : (
        <div className={classes.statusDot} style={{background: setErxStatus(status)}} />
      )}
      {getStatusName(status)}
    </div>
  );
};
export default ErxStatusField;
