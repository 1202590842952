import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FormikHelpers} from 'formik';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {Branch} from 'interfaces/branches.interfaces';

interface MyKnownError {
  stack: {error: {message: string}; message?: ErrorMessageObject[] | string};
  statusCode: number;
  status: number;
  message: string;
}

interface ErrorMessageObject {
  property: string;
  constraints: {
    matches: string;
  };
}

interface PractitionerAttributes {
  values: any;
  formikHelpers?: FormikHelpers<{
    [field: string]: any;
  }>;
}
interface InitialState {
  isLoading: boolean;
  isDeleted?: boolean;
  isCreated: boolean;
  isEdited: boolean;
  error?: MyKnownError;
  practitioner: Practitioner | null;
  practitioners: PractitionersData;
  roles: PractitionersRolesData;
  specialities: PractitionersSpecialitiesData;
  branches: BranchesData;
  createData: {
    providerId: string;
    active: boolean | null;
    name: string;
    gender: string;
    type: string;
    license_number: string;
    in_hospital: boolean;
    phone?: string;
    address?: string;
    speciality: string;
    roles: PractitionerRole[];
    branches: PractitionerBranch[];
  };
}

const initialState: InitialState = {
  isLoading: false,
  isDeleted: false,
  isCreated: false,
  isEdited: false,
  error: undefined,
  createData: {
    providerId: '',
    active: null,
    name: '',
    gender: '',
    type: '',
    license_number: '',
    in_hospital: true,
    phone: '',
    // address: '',
    speciality: '',
    roles: [],
    branches: [],
  },
  practitioner: null,
  practitioners: {
    data: [],
    total: 0,
  },
  roles: {
    data: [],
    total: 0,
  },
  specialities: {
    data: [],
  },
  branches: {
    data: {
      branches: [],
    },
  },
};

export interface PractitionerRole {
  id: number;
  name?: string;
  code?: string;
  speciality?: string;
}
export interface PractitionerBranch {
  id: number;
  name?: string;
}
export interface Speciality {
  _id: string;
  name: string;
  code: string;
}

export interface Practitioner {
  id: number;
  in_hospital: boolean;
  active: boolean;
  providerId: string;
  identifier: string;
  phone: string;
  address: string;
  name: string;
  license_number: string;
  gender: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  speciality: string;
  roles: PractitionerRole[];
  branches: PractitionerBranch[];
}
export interface PractitionersQuery {
  providerId: string;
  offset?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  active?: boolean;
}
interface OnePractitionerQuery {
  id: number;
}
interface BranchesQuery {
  offset?: number;
  limit?: number;
}

interface PractitionersData {
  data: Practitioner[];
  total: number;
}

interface PractitionersRolesData {
  data: PractitionerRole[];
  total: number;
}

interface PractitionersSpecialitiesData {
  data: Speciality[];
}

interface BranchesData {
  data: {
    branches: Branch[];
  };
}

export const getPractitionerRoles = createAsyncThunk<PractitionersRolesData, any, {rejectValue: MyKnownError}>(
  'practitionersManagement/getPractitionersRoles',
  async (_, {rejectWithValue}) => {
    try {
      const response = await axios.get('/practitioners/roles');
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getPractitionerSpecialities = createAsyncThunk<
  PractitionersSpecialitiesData,
  any,
  {rejectValue: MyKnownError}
>('practitionersManagement/getPractitionerSpecialities', async (_, {rejectWithValue}) => {
  try {
    const response = await axios.get('/speciality');
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getPractitioners = createAsyncThunk<PractitionersData, PractitionersQuery, {rejectValue: MyKnownError}>(
  'practitionersManagement/getPractitioners',
  async (query: PractitionersQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/practitioners', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getOnePractitioner = createAsyncThunk<Practitioner, OnePractitionerQuery, {rejectValue: MyKnownError}>(
  'practitionersManagement/getOnePractitioner',
  async (query: OnePractitionerQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/practitioners/${query.id}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getBranches = createAsyncThunk<BranchesData, BranchesQuery, {rejectValue: MyKnownError}>(
  'practitionersManagement/getBranches',
  async (query: PractitionersQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/branches', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createPractitioner = createAsyncThunk<
  PractitionersData,
  PractitionerAttributes,
  {rejectValue: MyKnownError}
>(
  'practitionersManagement/createPractitioner',
  async ({values, formikHelpers}: PractitionerAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.post('/practitioners', values);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updatePractitioner = createAsyncThunk<Practitioner, PractitionerAttributes, {rejectValue: MyKnownError}>(
  'practitionersManagement/updatePractitioner',
  async ({values, formikHelpers}: PractitionerAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.put('/practitioners', values);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const practitionerManagementSlice = createSlice({
  name: 'practitionerManagement',
  initialState,
  reducers: {
    errorCleanup: (state) => {
      state.error = undefined;
    },
    createCleanup: (state) => {
      state.isCreated = false;
    },
    editCleanup: (state) => {
      state.isEdited = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPractitioners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPractitioners.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPractitioners.fulfilled, (state, action) => {
        state.practitioners.data = action.payload.data;
        state.practitioners.total = action.payload.total;
        state.isLoading = false;
      });
    builder
      .addCase(getPractitionerRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPractitionerRoles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPractitionerRoles.fulfilled, (state, action) => {
        state.roles.data = action.payload.data;
        state.roles.total = action.payload.total;
        state.isLoading = false;
      });
    builder
      .addCase(getPractitionerSpecialities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPractitionerSpecialities.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPractitionerSpecialities.fulfilled, (state, action) => {
        state.specialities.data = action.payload.data;
        state.isLoading = false;
      });
    builder
      .addCase(getOnePractitioner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePractitioner.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getOnePractitioner.fulfilled, (state, action) => {
        state.practitioner = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(createPractitioner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPractitioner.fulfilled, (state) => {
        state.isCreated = true;
        state.isLoading = false;
      })
      .addCase(createPractitioner.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(updatePractitioner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePractitioner.fulfilled, (state) => {
        state.isEdited = true;
        state.isLoading = false;
      })
      .addCase(updatePractitioner.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(getBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranches.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.branches.data.branches = action.payload.data.branches;
        state.isLoading = false;
      });
  },
});

export const {errorCleanup, createCleanup, editCleanup} = practitionerManagementSlice.actions;
export const practitionerManagementSelect = (state: RootState) => state.settings.practitionerManagement;
export default practitionerManagementSlice.reducer;
