import React, {useState, useEffect, useRef} from 'react';
import io from 'socket.io-client';
import {get as getStorage} from 'local-storage';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  getReportProgressStatus,
  getReportErrorStatus,
  reportProgressSelect,
  getDownloadProgressID,
} from 'modules/Reports/store/reportProgressStatus/reportUploadProgress';
import {decodeJWT} from 'services/jwt';
import {KlaimToast} from 'modules/Common/components/KlaimToast';
import closeIcon from 'assets/notification_close.svg';
import socket from 'services/socket';
import {ComponentProps, useStylesFromThemeFunction} from './MainLayout';
import {ProgressBarStatus} from '../../../Reports/api/strings';

const MainLayout: React.FC<ComponentProps> = ({sidebar: Sidebar, body: Body}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const [progressCount, setProgressCount] = useState<number>(1);
  const [isDisplayProgress, setIsDisplayProgress] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<string>('');
  const {downloadProgressID} = useAppSelector(reportProgressSelect);
  const progressID = useRef('');

  useEffect(() => {
    /* Get Socket Instance */
    const socketInstance = socket.getInstance();
    if (socketInstance) {
      /* Get Email from Access Token */
      const {email} = decodeJWT(getStorage('accessToken'));
      /* Socket connected Check */
      socketInstance.on('connect', () => {});

      socketInstance.on('disconnect', () => {
        socketInstance.connect();
      });

      socketInstance.on('connect_error', (err: any) => {
        console.log(`connect_error due to :: ${err.message}`);
        socketInstance.connect();
      });

      /* Progress Bar Start */
      socketInstance.on('uploadStart', (data: any) => {
        const {userEmail, status, downloadID} = data;
        if (userEmail === email) {
          const downloadProgressIDVal = getDownloadProgressIdValue();
          if (status === ProgressBarStatus.Start && downloadID === downloadProgressIDVal) {
            dispatch(getReportProgressStatus(true));
            setIsDisplayProgress(true);
            setShowErrorToast(false);
          }
        }
      });

      /* Progress Bar Update and Completed */
      socketInstance.on('notify', (data: any) => {
        const {userEmail, status, progress, url, downloadID} = data;
        const downloadProgressIDVal = getDownloadProgressIdValue();
        if (userEmail === email && downloadID === downloadProgressIDVal) {
          if (status === ProgressBarStatus.InProgress) {
            dispatch(getReportProgressStatus(true));
            setIsDisplayProgress(true);
            setShowErrorToast(false);
          }
          setProgressCount(+progress);
          if (status === ProgressBarStatus.Completed) {
            socketInstance.emit('cancelDownload', {downloadID: downloadProgressIDVal});
            dispatch(getDownloadProgressID(''));
            /* Download Uploaded URL File */
            window.open(url, '_blank');
            dispatch(getReportProgressStatus(false));
            setIsDisplayProgress(false);
            setProgressCount(1);
          }
        }
      });

      /* Progress Bar Update and Completed */
      socketInstance.on('error', (data: any) => {
        const {userEmail, status, downloadID} = data;
        const downloadProgressIDVal = getDownloadProgressIdValue();
        if (userEmail === email && downloadID === downloadProgressIDVal) {
          socketInstance.emit('cancelDownload', {downloadID: downloadProgressIDVal});
          dispatch(getDownloadProgressID(''));
          dispatch(getReportErrorStatus(true));
          setShowErrorToast(true);
          setIsDisplayProgress(false);
          setErrorStatus(status);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 2000);
        }
      });
    }
  }, [downloadProgressID]);

  useEffect(() => {
    progressID.current = downloadProgressID;
  }, [downloadProgressID]);

  function getDownloadProgressIdValue() {
    if (progressID && progressID.current && progressID.current !== '') {
      return progressID.current;
    }
    return '';
  }

  /* Cancel Progress */
  const cancelProgress = () => {
    const socketInstance = socket.getInstance();
    if (socketInstance) {
      socketInstance.emit('cancelDownload', {downloadID: downloadProgressID});
      dispatch(getDownloadProgressID(''));
      dispatch(getReportProgressStatus(false));
      setIsDisplayProgress(false);
      setProgressCount(1);
    }
  };

  return (
    <div id="main" className={classes.container}>
      {/* Error Toast */}
      {showErrorToast && (
        <div className={classes.toastMsg}>
          <div className={classes.innerToastMsgError}>
            <span>&nbsp;</span>
            {errorStatus === ProgressBarStatus.NotFound && (
              <>
                <h2>There is no data for that selection.</h2>
                <p>Please try with another filter selection.</p>
              </>
            )}
            {errorStatus === ProgressBarStatus.Error && <h2>Something went wrong. Try again.</h2>}
          </div>
        </div>
      )}
      {isDisplayProgress && (
        <div className={classes.toastMsg}>
          <div role="button" className={classes.xBox} onClick={cancelProgress}>
            <img width="30" alt="close" src={closeIcon} />
          </div>
          <div className={classes.innerToastMsg}>
            <h2>Processing…</h2>
            <p>Please wait, we are processing your data.</p>
            <p>Your download will start soon ...</p>
            <div className={classes.progressBar}>
              <div className={classes.label} data-count={progressCount}>
                {progressCount}%
              </div>
              <div className={classes.line}>
                <span style={{width: `${progressCount}%`}} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="main-side-bar" className={classes.sidebarContainer}>
        {Sidebar && Sidebar}
      </div>
      <div id="main-body" className={classes.bodyContainer}>
        {Body && Body}
      </div>
    </div>
  );
};

export default MainLayout;
