import {FC} from 'react';

import {Branch} from 'api/branch/branchTypes';
import {Button} from 'custom-test-antd';
import {SelectField} from 'utils/form-helpers/antd-formik';
import {ReportTypeOptions} from 'components/Reports/common/constants';
import type {Moment} from 'moment/moment';

import './ReportFilteringCard.pcss';
import {Formik, FormikHelpers} from 'formik';
import ReportCard from '../ReportCard/ReportCard';

export type RangeValue = [Moment | null, Moment | null] | null;

export type ReportCreationFields = {
  branches: string[];
  type: 'claim' | 'prior-auth' | 'midtable';
};

type Props = {
  allBranches: Branch[] | undefined;
  initialFields: ReportCreationFields;
  validationSchema: any;
  onFindReports: (fields: ReportCreationFields, formikHelpers: FormikHelpers<ReportCreationFields>) => void;
  loading: boolean;
};

export const ReportFilteringCard: FC<Props> = ({
  initialFields,
  allBranches,
  validationSchema,
  onFindReports,
  loading,
}) => (
  <ReportCard className="ReportFilteringCard shadowed" title="Report Filtering">
    <Formik initialValues={initialFields} validationSchema={validationSchema} onSubmit={onFindReports}>
      {({handleSubmit, errors, touched}) => (
        <form>
          <div className="ReportFilteringCard-cardContentWrap">
            <div className="ReportFilteringCard-filterList">
              <div>
                <SelectField
                  name="branches"
                  className="ReportFilteringCard-filter"
                  loading={allBranches === undefined}
                  size="large"
                  gray
                  maxTagCount={1}
                  mode="multiple"
                  placeholder="Branch"
                  dropdownMatchSelectWidth={false}
                  optionFilterProp="label"
                  optionLabelProp="value"
                  status={errors.branches && touched.branches ? 'error' : undefined}
                >
                  {allBranches &&
                    allBranches.map((branch) => (
                      <SelectField.Option
                        key={branch.identifier}
                        value={branch.identifier}
                        label={`${branch.identifier} ${branch.name}`}
                      >
                        {`[${branch.identifier}] ${branch.name}`}
                      </SelectField.Option>
                    ))}
                </SelectField>
                {errors.branches && touched.branches && (
                  <div className="ReportFilteringCard-errorMsg">{errors.branches}</div>
                )}
              </div>
              <div>
                <SelectField
                  name="type"
                  className="ReportFilteringCard-filter type"
                  placeholder="Report Type"
                  gray
                  size="large"
                  options={ReportTypeOptions}
                />
                {errors.type && touched.type && <div className="ReportFilteringCard-errorMsg">{errors.type}</div>}
              </div>
            </div>
            <div>
              <Button type="primary" onClick={() => handleSubmit()} loading={loading}>
                Find Reports
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  </ReportCard>
);

export default ReportFilteringCard;
