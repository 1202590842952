import React from 'react';
import {KlaimFileUploadV2} from 'modules/Common/components-v2/KlaimForm';

interface ComponentProps {
  onChange?: (value: any) => void;
}

const Attachment: React.FC<ComponentProps> = ({onChange}) => {
  const handleChange = (value: any) => {
    if (onChange) {
      if (value && value.length > 0) {
        onChange(value[0].base64StringFile);
      }
    }
  };
  return <KlaimFileUploadV2 dropAreaText="Drag and drop file" percentage={100} onChange={handleChange} />;
};

export default Attachment;
