import React from 'react';
import {Prompt} from 'react-router-dom';
import usePreventReload from '../../helpers/usePreventReload';

interface ComponentProps {
  isDirty?: boolean;
  message?: string;
}

const EligiblityFormPrompt: React.FC<ComponentProps> = ({isDirty = false, message}) => {
  usePreventReload(isDirty);
  return <Prompt when={isDirty} message="TEST GLOBAL" />;
};

export default EligiblityFormPrompt;
