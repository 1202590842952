import React, {useEffect} from 'react';
import AnimateHeight from 'react-animate-height';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {Colors} from 'modules/Common/constants/Colors';
import {KlaimButton, KlaimDropdownSearch, KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {DocumentIdType, MaritalStatus, IAddress} from 'interfaces/general.interfaces';
import {IPatient, IPatientResponse} from 'interfaces/patient.interface';
import PatientSelectRow from './PatientSelectRow';
import {ComponentProps, useStylesFromThemeFunction} from './PatientSelect';
import CreatePatientModal from '../CreatePatientModal';
import {patientSelect, getPatients} from '../../patientSlice';
import {cleanUpHipd, getHipd, hipdSelect} from '../../../Hipd/hipdSlice';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {subSiteSystem} from '../../../Common/constants/DropdownOptions';

interface IPatientList {
  name: string;
  idNumber: string;
  phoneNumber: string;
  birthday: string;
  gender: string;
}

const PatientSelect: React.FC<ComponentProps> = ({
  label,
  placeholder,
  containerClassName,
  errorClassName,
  optional = false,
  className,
  name = 'default',
  branch,
  onChange,
  initialValue,
  patientId,
  isEligiblity = false,
  isHidden,
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {patients} = useAppSelector(patientSelect);
  const isValidated = (error: boolean, touched: any) => {
    return error && touched ? classes.error : classes.validated;
  };

  const [limit] = React.useState<number>(10);
  const [offset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const node = React.useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [errorMessage] = React.useState('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [patientModal, setPatientModal] = React.useState(false);
  const [patientList, setPatientList] = React.useState<IPatientList[]>([]);
  const [HIPDInsuranceList, setHIPDInsuranceList] = React.useState<any[]>([]);
  const [selectedPatient, setSelectetPatient] = React.useState<IPatient | undefined>(initialValue);
  const {hipd, isLoading, error} = useAppSelector(hipdSelect);

  // useEffect(() => {
  //   if (patients.data.length === 1) populatePatient(patients.data[0]);
  // }, [patients.data.length]);

  const [height, setHeight] = React.useState(open ? 'auto' : '0%');
  React.useEffect(() => {
    setHeight(open ? 'auto' : '0%');
  }, [open]);
  const getPatientName = (patient: IPatientResponse | undefined) => {
    if (patient?.resource.name) {
      return patient?.resource.name[0].text;
    }
    return '';
  };
  React.useEffect(() => {
    if (patientId !== '' && patientList && patientList.length > 0) {
      handleChange(patientList.find((patient) => patient.idNumber === patientId));
    }
  }, [patientId, patientList]);

  React.useEffect(() => {
    const newPatients = patients.data.map((patient) => {
      let patientName = '';
      let patientPhoneNumber = '';

      if (patient.resource.name) {
        const findPatientName = getPatientName(patient);
        if (findPatientName) {
          patientName = findPatientName;
        }
      }

      if (patient.resource.telecom) {
        const findPatientPhoneNumber = patient.resource.telecom.find((p) => p.system === 'phone')?.value;
        if (findPatientPhoneNumber) {
          patientPhoneNumber = findPatientPhoneNumber;
        }
      }
      return {
        name: patientName,
        idNumber: patient.id,
        phoneNumber: patientPhoneNumber,
        birthday: patient.resource.birthDate,
        gender: patient.resource.gender,
        address: patient.resource.address,
        maritalStatus: patient.resource.maritalStatus,
        newBorn: patient.newBorn,
      };
    });

    setPatientList(newPatients);
  }, [patients]);

  React.useEffect(() => {
    const s = {search: search || patientId};
    dispatch(getPatients({limit, ...s, offset: (offset - 1) * limit, providerId: branch}));
    setSelectetPatient(null);
    dispatch(cleanUpHipd());
  }, [branch]);

  React.useEffect(() => {
    const s = {search: search || patientId};
    dispatch(getPatients({limit, ...s, offset: (offset - 1) * limit, providerId: branch}));
    dispatch(cleanUpHipd());
  }, [search, patientId]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (isEligiblity) {
        dispatch(cleanUpHipd());
      }
    };
  }, []);

  const handleChange = (value: any) => {
    if (!value) return;
    const patientResult = patients.data.find((patient) => patient.id === value.idNumber);
    populatePatient(patientResult);
  };
  const populatePatient = (patientResult: any) => {
    const getFullName = () => {
      if (patientResult?.resource.name) {
        return patientResult?.resource.name[0]?.given;
      }
      return [];
    };

    const getDocumentId = () => {
      if (patientResult?.resource.identifier) {
        return patientResult?.resource.identifier[0]?.value;
      }
      return '';
    };

    const getDocumentType = (): DocumentIdType => {
      if (patientResult?.resource.identifier) {
        if (patientResult?.resource.identifier[0].type.coding) {
          return patientResult?.resource.identifier[0].type.coding[0].code;
        }
        return '';
      }
      return '';
    };

    const getMaritalStatus = (): MaritalStatus => {
      if (patientResult?.resource.maritalStatus.coding) {
        return patientResult?.resource.maritalStatus.coding[0].code;
      }
      return '';
    };

    const getCountry = () => {
      if (patientResult?.resource.telecom) {
        return patientResult?.resource.telecom[0].value;
      }
      return '';
    };

    const getAddress = (): IAddress => {
      if (patientResult?.resource.address) {
        return patientResult?.resource.address[0];
      }
      return {};
    };

    const newValue: IPatient = {
      name: getPatientName(patientResult),
      fullName: getFullName(),
      dateOfBirth: patientResult?.resource.birthDate || '',
      gender: patientResult?.resource.gender || '',
      documentId: getDocumentId(),
      documentIdType: getDocumentType(),
      id: patientResult?.id || '',
      maritalStatus: getMaritalStatus(),
      phoneNumber: getCountry(),
      country: getAddress().country,
      address: getAddress(),
    };

    setSelectetPatient(newValue);
  };

  React.useEffect(() => {
    if (onChange) onChange(selectedPatient);
    setInputValue(selectedPatient ? selectedPatient.name : '');
    setOpen(false);
  }, [selectedPatient]);

  React.useEffect(() => {
    if (hipd && !error) {
      // const patient: IPatient = {
      //   name: hipd.response.insurance[0].beneficiaryName,
      //   gender: hipd.response.insurance[0].gender === 'Male' ? 'male' : 'female',
      //   documentId: hipd.response.insurance[0].identityNumber,
      // };
      // if (onChange) onChange(patient);
      setHIPDInsuranceList(hipd.response.insurance);
      // setInputValue(patient ? patient.name : '');
      setOpen(false);
    }
  }, [hipd]);

  const handleClickOutside = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setOpen(false);
  };

  const handleSearchHipd = () => {
    dispatch(
      getHipd({
        providerId: branch,
        documentId: search,
      }),
    );
  };

  if (isLoading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <LoadingSpinner />
      </div>
    );
  }
  if (isHidden) return null;

  return (
    <div className={`${className} ${classes.contentContainer} ${containerClassName}`}>
      {label && (
        <label className={classes.label} htmlFor={name}>
          {label}
          {optional && <span>(Optional)</span>}
        </label>
      )}
      <div
        ref={node}
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            borderWidth: 1,
            borderColor: Colors.grayLight,
            borderStyle: 'solid',
            borderRadius: 8,
            overflow: 'hidden',
            backgroundColor: '#F5F7FB',
            height: 42,
          }}
        >
          <input
            style={{fontSize: 14, height: 42, font: 'inherit', color: '#363C46'}}
            placeholder={placeholder || 'Select Patient'}
            onBlur={(e) => {
              if (node.current !== null) {
                if (node.current?.contains(e.target)) {
                  return;
                }
              }
              setOpen(false);
            }}
            onFocus={() => setOpen(true)}
            className={classes.input}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
              setSearchTyping(e.currentTarget.value);
            }}
          />
          <div style={{borderRight: '1px solid', borderColor: Colors.grayLight, margin: 4}} />

          <KlaimIconButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div style={{width: 32, height: 20}}>
              <ChevronArrowDown />
            </div>
          </KlaimIconButton>
        </div>

        <div
          style={{
            boxShadow: '0px 5px 5px -1px rgb(96 97 112 / 16%)',
            borderRadius: 8,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 2,
            top: 50,
            left: 0,
            right: 0,
            backgroundColor: Colors.white,
          }}
        >
          <AnimateHeight duration={500} height={height}>
            <div style={{padding: 8}}>
              {patientList.length > 0 && (
                <div style={{marginBottom: 8}}>
                  <p style={{margin: 0, fontSize: 12, marginBottom: 8, color: Colors.grayLighter}}>Search Results</p>
                  {patientList.map((patient, index) => (
                    <PatientSelectRow patient={patient} key={index} onClick={handleChange} />
                  ))}
                </div>
              )}
              {search && patientList.length < 1 && (
                <div>
                  <span
                    style={{
                      margin: 0,
                      fontSize: 12,
                      marginBottom: 8,
                      color: Colors.grayLighter,
                    }}
                  >
                    Action
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: Colors.purpleLight,
                      padding: 20,
                      borderRadius: 8,
                      alignItems: 'center',
                    }}
                  >
                    <div style={{alignSelf: 'start'}}>
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: 99999,
                          backgroundColor: 'gray',
                          marginRight: 4,
                        }}
                      />
                    </div>
                    {error ? (
                      <div style={{flex: 1, marginLeft: 10, marginRight: 10}}>
                        <span
                          style={{
                            margin: 0,
                            fontSize: 14,
                            marginBottom: 8,
                            fontWeight: 'bold',
                            color: Colors.purple,
                          }}
                        >
                          {search} doesn’t exist in CCHI!
                        </span>
                        <p style={{fontSize: 12, color: Colors.grayLighter}}>
                          It seems that data your are searching does not exist on CCHI database.
                        </p>
                      </div>
                    ) : (
                      <div style={{flex: 1, marginLeft: 10, marginRight: 10}}>
                        <span
                          style={{
                            margin: 0,
                            fontSize: 14,
                            marginBottom: 8,
                            fontWeight: 'bold',
                            color: Colors.purple,
                          }}
                        >
                          {search} doesn’t exist!
                        </span>
                        <p style={{fontSize: 12, color: Colors.grayLighter}}>
                          It seems that you don’t have anyone with that entry in patient management.
                        </p>
                      </div>
                    )}
                    <div>
                      {isEligiblity ? (
                        <KlaimButton onClick={() => handleSearchHipd()}>Search Patient in CCHI</KlaimButton>
                      ) : (
                        <KlaimButton onClick={() => setPatientModal(true)}>Create Patient</KlaimButton>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </AnimateHeight>
        </div>
        <CreatePatientModal isOpen={patientModal} setIsOpen={setPatientModal} branch={branch} />
      </div>

      {HIPDInsuranceList.length > 0 && [
        <br />,
        <KlaimDropdownSearch
          options={HIPDInsuranceList.map((el) => ({label: el.insuranceCompanyName, value: el.policyNumber}))}
          label="Select insurance"
          variant="secondary"
          placeholder="Select insurance"
          onChange={(value) => {
            const insurance = HIPDInsuranceList.find((el) => el.policyNumber === value);
            const patient: IPatient = {
              name: insurance.beneficiaryName,
              gender: insurance.gender === 'Male' ? 'male' : 'female',
              documentId: insurance.identityNumber,
            };
            if (onChange) onChange(patient, insurance);
            setInputValue(patient ? patient.name : '');
          }}
        />,
      ]}

      {errorMessage && (
        <div className={errorClassName !== undefined ? errorClassName : classes.error}>{errorMessage}</div>
      )}
    </div>
  );
};

export default PatientSelect;
