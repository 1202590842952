import {createUseStyles} from 'react-jss';
import selectArrow from 'assets/select_arrow.svg';
import selectArrowUp from 'assets/select_arrow_up.svg';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  value?: {value: any; label: string | undefined};
  setValue: (arg0: {value: any; label: string | undefined}) => void;
  renderItems?: () => React.ReactNode;
  options: {value: any; label: string | undefined}[];
  expandOnHover?: boolean;
  dropdownClassName?: string;
}

export const useStylesFromThemeFunction = createUseStyles({
  contentContainer: {
    padding: '10px',
    '& span': {
      paddingLeft: '10px',
    },
  },
  dropArrow: {
    backgroundImage: `url(${selectArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 40,
    position: 'absolute',
    right: -1,
    width: 40,
  },
  activeDropArrow: {
    backgroundImage: `url(${selectArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 40,
    position: 'absolute',
    right: -1,
    width: 40,
  },
  dropBtn: {
    alignItems: 'center',
    background: '#f5f7fb',
    border: '1px solid #e7ecf5',
    borderRadius: 10,
    boxSizing: 'border-box',
    color: '#222b45',
    display: 'flex',
    fontSize: 12,
    fontWeight: 500,
    height: 40,
    MozAppearance: 'none',
    outline: 'none',
    paddingLeft: 15,
    paddingRight: 95,
    position: 'relative',
    textAlign: 'left',
    width: '100%',
    zIndex: 2,
  },
  activeDropBtn: {
    alignItems: 'center',
    borderRadius: 10,
    boxSizing: 'border-box',
    display: 'flex',
    fontSize: 12,
    fontWeight: 500,
    height: 40,
    MozAppearance: 'none',
    outline: 'none',
    paddingLeft: 15,
    paddingRight: 95,
    position: 'relative',
    textAlign: 'left',
    width: '100%',
    zIndex: 2,
  },
  dropdown: {
    '&:hover': {
      '& $dropdownContent': {display: 'block'},
      cursor: 'pointer',
    },
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 12,
    borderColor: Colors.grayLight,
  },
  dropdownDisabled: {
    '&:hover': {
      '& $dropdownContent': {display: 'block'},
      cursor: 'pointer',
    },
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    pointerEvents: 'none',
    opacity: 0.4,
  },
  dropdownContent: {
    backgroundColor: Colors.white,
    boxShadow: '0px 16px 22px 3px rgba(96, 97, 112, 0.16)',
    boxSizing: 'border-box',
    display: 'none',
    fontSize: 14,
    MozBoxShadow: '0px 16px 22px 3px rgba(96, 97, 112, 0.16)',
    MozBoxSizing: 'border-box',
    paddingBottom: 10,
    padding: 5,
    position: 'absolute',
    top: 35,
    WebkitBoxShadow: '0px 16px 22px 3px rgba(96, 97, 112, 0.16)',
    WebkitBoxSizing: 'border-box',
    width: '100%',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    isolation: 'isolate',
    zIndex: 10,
  },
  dropdownItem: {
    '&:hover': {
      background: '#f5f7fb',
    },
    cursor: 'pointer',
    fontWeight: 600,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 10,
    paddingTop: 10,
  },
  itemsContainer: {listStyleType: 'none', padding: 0, margin: 0},
  error: {
    color: Colors.red,
  },
  labelContainer: {
    '& span': {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      fontWeight: 'normal',
      fontSize: 12,
      color: Colors.black,
    },
    '& p': {
      flex: 1,
      margin: 0,
      fontWeight: 'bold',
    },
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
  },
});
