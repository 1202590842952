import React from 'react';
import {useParams} from 'react-router-dom';
import {Row, Col} from 'react-grid-system';
import dayjs from 'dayjs';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import Header from 'modules/Settings/components/SubHeader';
import {branchManagementSelect, getBranches} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {patientSelect, getPatient, patientCleanup, errorCleanup} from 'modules/Patients/patientsSlice';
import {useStylesFromThemeFunction} from './patientView';

export interface IValues {
  [field: string]: any;
}

interface KeyValProps {
  title: any;
  val: any;
}

const KeyValue: React.FC<KeyValProps> = ({title, val}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={`keyValContainer ${classes.keyValContainer}`}>
      <div className={'title'}>{title}</div>
      <div className={'value'}>{val || '-'}</div>
    </div>
  );
};

const Component: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {id}: {id: string} = useParams();

  const {patient} = useAppSelector(patientSelect);

  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier);
  };

  React.useEffect(() => {
    dispatch(patientCleanup());
    dispatch(errorCleanup());
  }, []);

  React.useEffect(() => {
    if (!isBranchLoading && branches.length === 0) {
      dispatch(getPatient({id, providerId: getProviderIds().join(',')}));
      dispatch(getBranches({}));
    }
    if (branches.length > 0) {
      dispatch(getPatient({id, providerId: getProviderIds().join(',')}));
    }
  }, [branches, isBranchLoading]);

  console.log('patient-x', patient);

  return (
    <Header
      title=""
      backlink="/patients"
      proceedlink={`/patients/edit/${patient?.id}`}
      description={null}
      buttonType="link"
      buttonTitle="Edit Details"
      body={
        patient !== null && patient ? (
          <>
            <Row>
              <Col sm={12}>
                <div className={classes.bodyTitle}>Beneficiary Primary Details</div>
                <div className={classes.bodysubTitle}>
                  Updated on {dayjs(patient?.updatedAt).format('ddd DD MMM - hh:mm a')}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className={classes.sectionHeader}>PRIMARY ID</div>
              </Col>
            </Row>
            <Row style={{paddingTop: 30}}>
              <Col sm={4}>
                <KeyValue
                  title={`ID Type`}
                  val={patient?.resource?.identifier ? patient?.resource?.identifier[0].type.coding[0]?.code : '-'}
                />
              </Col>
              <Col sm={4}>
                <KeyValue
                  title={`ID Number`}
                  val={patient?.resource?.identifier ? patient?.resource?.identifier[0]?.value : '-'}
                />
              </Col>
              <Col sm={4}>
                <KeyValue
                  title={`Primary Number`}
                  val={patient?.resource?.telecom ? patient?.resource?.telecom[0]?.value : '-'}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className={classes.sectionHeader}>NAME</div>
              </Col>
              <Col sm={6}>
                <div className={classes.sectionHeader}>OTHERS</div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Row style={{paddingTop: 30}}>
                  <Col sm={12}>
                    <KeyValue title={`Name`} val={patient ? patient?.resource?.name[0]?.text : ''} />
                  </Col>
                  {/* <Col sm={12}>
                    <KeyValue title={`First Name`} val={patient ? patient?.resource?.name[0]?.given[0] : '-'} />
                  </Col>
                  <Col sm={12}>
                    <KeyValue title={`Middle Name`} val={patient ? patient?.resource?.name[0]?.given[1] : '-'} />
                  </Col>
                  <Col sm={12}>
                    <KeyValue title={`Last Name`} val={patient ? patient?.resource?.name[0]?.given[2] : '-'} />
                  </Col> */}
                </Row>
              </Col>
              <Col sm={6}>
                <Row style={{paddingTop: 30}}>
                  <Col sm={12}>
                    <KeyValue title={`Date of Birth`} val={patient ? patient?.resource?.birthDate : '-'} />
                  </Col>
                  <Col sm={12}>
                    <KeyValue
                      title={`Gender`}
                      val={
                        patient ? patient?.resource?._gender.extension[0]?.valueCodeableConcept?.coding[0]?.code : '-'
                      }
                    />
                  </Col>
                  <Col sm={12}>
                    <KeyValue
                      title={`Marital Status`}
                      val={patient ? patient?.resource?.maritalStatus?.coding[0]?.code : '-'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className={classes.sectionHeader}>ADDRESS</div>
              </Col>
            </Row>
            <Row style={{paddingTop: 30}}>
              <Col sm={4}>
                <KeyValue
                  title={`Country`}
                  val={patient?.resource?.address ? patient?.resource?.address[0]?.country : '-'}
                />
              </Col>
              <Col sm={4}>
                <KeyValue title={`City`} val={patient?.resource?.address ? patient?.resource?.address[0]?.city : '-'} />
              </Col>
              <Col sm={4}>
                <KeyValue
                  title={`Street`}
                  val={patient?.resource?.address ? patient?.resource?.address[0]?.text : '-'}
                />
              </Col>
            </Row>
          </>
        ) : (
          <div>
            <LoadingSpinner />
          </div>
        )
      }
    />
  );
};

export default Component;
