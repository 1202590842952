import React from 'react';
import {StepperContext} from '../KlaimStepper/index';

interface ComponentProps {
  style?: React.CSSProperties;
}

const KlaimStepperSteps: React.FC<ComponentProps> = ({children, style}) => {
  const {setTotalStep} = React.useContext(StepperContext);
  React.useEffect(() => {
    setTotalStep(React.Children.count(children));
  }, []);
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {step: i});
    }
    return child;
  });

  return <div style={style}>{childrenWithProps}</div>;
};

export default KlaimStepperSteps;
