import {getCurrencyToDisplay} from 'modules/Common/utils/localization';

export enum ClaimsBreakdownStatus {
  Accepted = 'Accepted',
  Denied = 'Denied',
  Pending = 'Pending',
  PartiallyAccepted = 'Partially Accepted',
  Rejected = 'Rejected',
  Breakdown = 'Breakdown',
}

export enum ClaimsBreakdownDateRangeFormat {
  dateFormat = 'MM/DD/YYYY',
}

export enum TotalClaimsLabels {
  Claims = 'Claims',
  Claimed = 'Claimed',
  Total = 'Total',
}

export enum KPIsLabels {
  AverageAPC = 'Average Amount Per Claim',
  EncounterToClaim = 'Encounter To Claim Submission',
  SubmissionTPayment = 'Claim Submission To Payment',
  KPIs = 'KPIs',
}

export enum ClaimsBreakdownSeries {
  Accepted = 'Accepted',
  Denied = 'Denied',
  Pending = 'Pending',
  PartiallyAccepted = 'Partially Accepted',
}

export enum ClaimsBreakdownDateTypes {
  SubmissionDate = 'submission',
  SubmissionDateLabel = 'First Submission Date',
  LastSubmissionDate = 'lastSubmission',
  LastSubmissionDateLabel = 'Last Submission Date',
  EncounterDate = 'encounter',
  EncounterDateLabel = 'Encounter Date',
  RemittanceDate = 'remittance',
  RemittanceDateLabel = 'Last Remittance Advice Date Range',
}

export enum ClaimsBreakdownFilters {
  Count = 'Count',
  Amount = 'Amount',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum TotalClaimsVSAmountKeys {
  Count = 'totalClaims',
  Amount = 'claimAmount',
}

export enum TotalClaimsVSAmountDateFormats {
  Monthly = 'MMM',
  Yearly = 'YYYY',
  MonthWithYear = 'MMM YYYY',
  CompleteDate = 'MMM D, YYYY',
}

export enum DashFilterDateFormat {
  dateFormat = 'MM/dd/yyyy',
}

export enum CollectionPerMonthFilters {
  Insurer = 'Insurer',
  TPA = 'TPA',
}

export enum PayerType {
  Insurer = 'insurer',
  TPA = 'tpa',
}

export enum PayersClassifications {
  Insurance = 'Insurance',
  TPA = 'TPA',
  Broker = 'Broker',
}

export enum CollectionsPerMonthSeries {
  Collected = 'Collected',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export const AgeingOrderBy = [
  '<= 15 days',
  '15 - 30 days',
  '30 - 45 days',
  '45 - 60 days',
  '60 - 90 days',
  '90 - 120 days',
  '120 - 150 days',
  '150 - 180 days',
  '180 - 360 days',
  '> 360 days',
];

export const AgeingOrderByNames = [
  '<= 15 D',
  '15 - 30 D',
  '30 - 45 D',
  '45 - 60 D',
  '60 - 90 D',
  '90 - 120 D',
  '120 - 150 D',
  '150 - 180 D',
  '180 - 360 D',
  '> 360 D',
];

export const ClinicianTableNames = [
  'Clinician',
  '# Claims',
  `Net Claimed (${getCurrencyToDisplay()})`,
  `Paid (${getCurrencyToDisplay()})`,
  `Pending (${getCurrencyToDisplay()})`,
  `Rejected (${getCurrencyToDisplay()})`,
  'Rejection (%)',
];

export const CollectionPerSourceNames = [
  'Payer',
  'Payer ID',
  `Claimed ${getCurrencyToDisplay()}`,
  `Collected ${getCurrencyToDisplay()}`,
  'Collected (%)',
  `Rejected ${getCurrencyToDisplay()}`,
  'Rejected (%)',
  `Pending ${getCurrencyToDisplay()}`,
  'Pending (%)',
];

export const AgeingCsvTableNames = [
  'Receiver/TPA',
  '<= 15 days',
  '15 - 30 days',
  '30 - 45 days',
  '45 - 60 days',
  '60 - 90 days',
  '120 - 150 days',
  '150 - 180 days',
  '180 - 360 days',
  '> 360 days',
  'Total',
];

export enum AgeingTableToggle {
  Insurer = 'Insurer',
  TPA = 'TPA',
}

export const TopTenRejectionReasonKeys = {
  Count: 'Count',
  Amount: `${getCurrencyToDisplay()} Amount`,
};

export enum TopTenRejectionReasonCodeNameKeys {
  Medical = 'Medical Reasons',
  Technical = 'Technical Reasons',
}

export enum TopTenRejectionKeys {
  Insurer = 'Insurer',
  TPA = 'TPA',
  Procedure = 'Procedure Code',
  Clinician = 'Clinician',
}

export const TopTenClinicianKeys = {
  Count: 'Count',
  Amount: `${getCurrencyToDisplay()} Amount`,
};

export enum CliniciansTopTenSeries {
  Paid = 'Paid',
  Rejected = 'Rejected',
  Pending = 'Pending',
  PartiallyPaid = 'Partially Paid',
}

export const DashForbiddenMessage = 'You are not allowed to see the requested branches data';

export const TopTenRejectionReasonCsv = {
  MedicalValue: 'medicalReasons',
  TechnicalValue: 'technicalReasons',
  MedicalLabel: 'Medical Reasons',
};

export enum ClaimsBreakdownCsv {
  Monthly = 'Month',
  Yearly = 'Year',
}

export const PaymentStatisticsCsv = {
  AvgPerWeek: `${getCurrencyToDisplay()}  Avg. per week`,
  AvgPerMonth: `${getCurrencyToDisplay()}  Avg. per month`,
  DaysSubmiision: 'Days Submission to payment',
  DaysEncounter: 'Encounter to Payment (Days)',
  PaymentStatistics: 'Payment Statistics',
};

export const CumulativePaymentCsv = {
  Month: 'Month',
  Base: 'Base',
  Paid: 'Paid',
};

export const TopPerProcedureCsv = {
  ProcedureCode: 'Procedure Code',
  AEDAmount: 'AED Amount',
};

export const TopTenClinicianCsv = {
  Submitted: 'submitted',
  Paid: 'paid',
  Rejected: 'rejected',
  Partial: 'partially_paid',
  CliniciansLabel: 'Clinicians',
  PaidLabel: 'Paid',
  RejectedLabel: 'Rejected',
  PendingLabel: 'Pending',
  PartialLabel: 'Partially Paid',
};

export enum CollectionPerMonthCsv {
  Month = 'Month',
}

export const AgeingCsv = {
  PendingClaim: 'Pending Claims',
  FourtyFiveDays: `> 45 Days`,
  PendingClaimCurrency: `${getCurrencyToDisplay()} Pending Claims`,
  FourtyFiveDaysCurrency: `${getCurrencyToDisplay()} > 45 Days`,
  DayRange: 'Day Range',
  AgeingAmount: `Ageing ${getCurrencyToDisplay()} Amount`,
  Summary: 'Summary',
};

export enum PaymentPerTPACsv {
  ReceiverName = 'Receiver Name',
  ReceiverCode = 'Receiver Code',
}

export enum PaymentPerInsurerCsv {
  ReceiverName = 'Receiver Name',
  ReceiverCode = 'Receiver Code',
}

export enum DateTypesToUse {
  Default = 'default',
  Ageing = 'ageing',
}
