import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {InitialState, PreAuthError, PreAuthHistoryData, PreAuthHistoryQuery} from './types';

export const getPreAuthList = createAsyncThunk<PreAuthHistoryData, PreAuthHistoryQuery, {rejectValue: PreAuthError}>(
  'preAuthList',
  async (query: PreAuthHistoryQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/prior-authorization`, {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState: InitialState = {
  data: {
    count: 0,
    data: [],
  },
  isLoading: false,
  error: undefined,
};

const preAuthListSlice = createSlice({
  name: 'preAuthList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPreAuthList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPreAuthList.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getPreAuthList.fulfilled, (state, action) => {
        state.data.data = action.payload.data;
        state.data.count = action.payload.count;
        state.isLoading = false;
      });
  },
});

export const preAuthListSelect = (state: RootState) => state.preAuth.preAuthList;
export default preAuthListSlice.reducer;
