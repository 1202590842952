import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    gap: 5,
  },
});
