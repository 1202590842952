import React, {FC} from 'react';
import {get, sum} from 'lodash';
import {useStylesFromThemeFunction} from '../../../../MidTable/features/MidTableForm/Components/MidTableForm';
import {Card, Divider, Header, Label, LabelValue, Value} from './styles';

const SummaryHeader: FC<any> = ({items, response}) => {
  const classes = useStylesFromThemeFunction();

  const getResponsePath = (index) => {
    return `[0].response.itemsDetails[${
      get(
        get(response, `[0].response.itemsDetails`, []).filter((el) => index === el.itemIndex - 1),
        '[0].itemIndex',
      ) - 1
    }]`;
  };
  const labelValue = (label: string, value: any) => {
    return (
      <LabelValue className={'labelValue'}>
        <Label className={'value'}>{label}</Label>
        <Value className="label">{value}</Value>
      </LabelValue>
    );
  };
  // {labelValue(
  //     'NET VAT',
  //     sum(
  //         items.map((_, index) =>
  //
  //         ),
  //     ),
  // )}
  return (
    <Header className={'header'}>
      {labelValue(
        'GROSS AMOUNT',
        sum(
          items.map(
            (_, index) =>
              get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                  (sub) => sub.category === 'submitted',
                ),
                '[0].amount',
                0,
              ) -
              get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter((sub) => sub.category === 'tax'),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}
      {labelValue(
        'VAT AMOUNT',
        sum(
          items.map((_, index) => {
            return +get(
              get(response, `${getResponsePath(index)}.adjudication`, []).filter((sub) => sub.category === 'tax'),
              '[0].amount',
              0,
            );
          }),
        ).toFixed(2),
      )}
      {labelValue(
        'DISCOUNT AMOUNT',
        sum(
          items.map(
            (_, index) =>
              +get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                  (sub) => sub.category === 'discount',
                ),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}
      {labelValue(
        'APPROVED QTY',
        sum(
          items.map(
            (_, index) =>
              +get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                  (sub) => sub.category === 'approved-quantity',
                ),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}

      {labelValue(
        'PATIENT SHARE',
        sum(
          items.map(
            (_, index) =>
              +get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                  (sub) => sub.category === 'patientShare' || sub.category === 'copay',
                ),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}
      {labelValue(
        'ELIGIBLE AMOUNT',
        sum(
          items.map(
            (_, index) =>
              +get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                  (sub) => sub.category === 'eligible',
                ),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}
      {labelValue(
        'NET AMOUNT',
        sum(
          items.map(
            (_, index) =>
              +get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter(
                  (sub) => sub.category === 'submitted',
                ),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}
      {labelValue(
        'APPROVED AMOUNT',
        sum(
          items.map(
            (_, index) =>
              +get(
                get(response, `${getResponsePath(index)}.adjudication`, []).filter((sub) => sub.category === 'benefit'),
                '[0].amount',
                0,
              ),
          ),
        ).toFixed(2),
      )}
    </Header>
  );
};
export default SummaryHeader;
