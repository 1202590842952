import React from 'react';
import {IRoute} from 'interfaces/route.interface';

import MainLayout from 'modules/Common/layout/MainLayout';
import Sidebar from './Sidebar';
import Body from './Body';

const Main: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const [sidebarIsOpen, setSidebarIsOpen] = React.useState<boolean>(false);

  const handleResize = () => {
    if (window.innerWidth > 1023) {
      setSidebarIsOpen(true);
    } else {
      setSidebarIsOpen(false);
    }
  };

  React.useEffect(() => {
    handleResize();
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [window]);

  return (
    <MainLayout
      sidebar={
        <Sidebar handleResize={handleResize} sidebarIsOpen={sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen} />
      }
      body={<Body routes={routes} handleResize={handleResize} sidebarIsOpen={sidebarIsOpen} />}
    />
  );
};

export default Main;
