import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getIn} from 'formik';
import {ClaimActivity, ENCOUNTER_END_TYPE, ENCOUNTER_START_TYPE} from '../../api/types';
import SelectOptions from '../../../Common/components/Select';
import {getEncounters} from '../../api';
import KlaimDateTimePicker from '../../../Common/components-v2/KlaimForm/components/KlaimDateTimePicker';

interface ComponentProps {
  values: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  classes: any;
  handleChange: any;
  setFieldValue: any;
  handleBlur?: any;
}

const Encounter: React.FC<ComponentProps> = ({
  values = {},
  errors,
  touched,
  setFieldTouched,
  classes,
  handleChange,
  setFieldValue,
  handleBlur,
}) => {
  const {t} = useTranslation();
  const [encounterType, setEncounterType] = useState<any>([]);

  const handleInputChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const handleInputDateChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    event = {target: {value: typeof event === 'string' ? event : event.format('DD/MM/YYYY HH:MM')}};
    handleChange(event);
    fieldName[field] = event.target.value !== undefined ? event.target.value : '';
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const getEncounterTypes = async () => {
    const {data} = await getEncounters(values.authorityCode);
    const encounters = data.data.map((encounter: any) => {
      return {label: encounter.name, value: +encounter.code};
    });
    setEncounterType(encounters);
  };

  const onDateInputChange = (before: string, after: string, field: string) => {
    const pattern: [number, string][] = [
      [2, '/'],
      [5, '/'],
      [10, ' '],
      [13, ':'],
    ];
    const newValue = getAutoFormattedValue(after, before, pattern);
    if (!newValue) return;
    setFieldValue(field, newValue);
  };

  const getAutoFormattedValue = (after: string, before: string, pattern: [number, string][]) => {
    const isDeleting = !after || !before || after.length <= before.length;
    if (isDeleting) return undefined;

    if (before.length > 0) {
      const lastCharBefore = before[before.length - 1];
      const newChar = after[after.length - 1];
      const newCharShouldBeIgnored = ['/', '', ':'].includes(lastCharBefore) && ['/', '', ':'].includes(newChar);
      if (newCharShouldBeIgnored) return before;
    }

    const autoFormattingNewChar = pattern.find(([position]) => position === after.length);
    if (autoFormattingNewChar) return after + autoFormattingNewChar[1];

    const autoFormattingMissingChar = pattern.find(
      ([position, char]) => position === before.length && after[after.length - 1] !== char,
    );
    if (autoFormattingMissingChar)
      return after.slice(0, after.length - 1) + autoFormattingMissingChar[1] + after[after.length - 1];
  };

  React.useEffect(() => {
    (async () => {
      await getEncounterTypes();
    })();
  }, []);

  useEffect(() => {
    if (values.encounter?.start) {
      values.activities.forEach((a: ClaimActivity) => {
        a.start = values.encounter.start;
      });
    }
  }, [values.encounter?.start]);

  return (
    <div className={classes.formSectionElementsWrapper}>
      <h2>{t('Encounter')}</h2>
      <SelectOptions
        name="encounter.type"
        options={encounterType}
        selectClassName={classes.formElementWithBottomSpacing}
        label={t('Encounter')}
        value={values.encounter?.type}
        onChange={(e) => setFieldValue('encounter.type', e)}
        placeholder={t('Select Encounter Type')}
        isClearable={false}
      />
      <div className={`${classes.rowInputs}`}>
        <KlaimDateTimePicker
          label="Start Date"
          name="encounter.start"
          onChange={(e) => {
            onDateInputChange(values.encounter.start, e, 'encounter.start');
            handleInputDateChangeSecond(e, values.encounter, 'start');
          }}
          value={values.encounter?.start}
          placeholder="DD/MM/YYYY HH:MM"
          errors={
            getIn(errors, 'encounter.start') && getIn(touched, 'encounter.start')
              ? getIn(errors, 'encounter.start')
              : null
          }
        />
        <SelectOptions
          name="encounter.startType"
          options={ENCOUNTER_START_TYPE}
          label={t('Start Type')}
          value={values.encounter?.startType}
          onChange={(e) => setFieldValue('encounter.startType', e)}
          divClassName={classes.rowInputRight}
          selectClassName={classes.w100}
          placeholder={t('Select Encounter Start Type')}
          isClearable={false}
        />
      </div>
      <div className={`${classes.rowInputs}`}>
        <KlaimDateTimePicker
          label="End Date"
          name="encounter.end"
          onChange={(e) => {
            onDateInputChange(values.encounter.end, e, 'encounter.end');
            handleInputDateChangeSecond(e, values.encounter, 'end');
          }}
          value={values.encounter?.end}
          placeholder="DD/MM/YYYY HH:MM"
          errors={
            getIn(errors, 'encounter.end') && getIn(touched, 'encounter.end') ? getIn(errors, 'encounter.end') : null
          }
        />
        <SelectOptions
          name="encounter.endType"
          options={ENCOUNTER_END_TYPE}
          label={t('End Type')}
          value={values.encounter?.endType}
          onChange={(e) => setFieldValue('encounter.endType', e)}
          divClassName={classes.rowInputRight}
          selectClassName={classes.w100}
          placeholder={t('Select Encounter End Type')}
        />
      </div>
    </div>
  );
};

export default Encounter;
