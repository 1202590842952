import axios, {CancelToken} from 'services/axios';
import {BranchData} from 'modules/Pharmacy/features/PriorRequest/types';

let cToken: any;

export const searchErxDrug = (drug: string, authorityCode: 'DHA' | 'HAAD') => {
  return axios.get(`/drug?search=${drug}&authorityCode=${authorityCode}`);
};

export const getBranches = () => {
  return axios.get<BranchData>('/branches?offset=0&sortBy=createdAt');
};

export const submitPriorRequest = (priorRequestForm: any) => {
  return axios.post('/erx/prior-request', priorRequestForm);
};
