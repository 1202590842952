import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    formContainer: {
      display: 'grid',
      gap: 10,
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  };
});
