import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  children: React.ReactNode;
  contentStyle?: any;
  contentClass?: string;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    klaimModalOverlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
      bottom: 0,
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: 10,
    },
  };
});
