import React from 'react';
import {createUseStyles} from 'react-jss';
import {Switch} from 'react-router-dom';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import {IRoute} from 'interfaces/route.interface';
import {Colors} from 'modules/Common/constants/Colors';
import Header from 'modules/Header';
import {useAppDispatch} from 'modules/App/store';
import {setNavOptions, setRequiredFilters} from 'modules/Header/headerSlice';
import {useLocation} from 'react-router';
import {remove as removeStorage} from 'local-storage';
import {useSelector} from 'react-redux';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {NavigationMenuItem} from 'interfaces/header.interfaces';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
      overflowY: 'auto',
      position: 'relative',
    },
    contentContainer: {
      borderRadius: '10px',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
      padding: 20,
    },
    mr0: {
      marginRight: '0 !important',
    },
    navIcons: {
      alignItems: 'center',
      background: Colors.blueGrayLight,
      borderRadius: '10px',
      cursor: 'pointer',
      display: 'flex',
      padding: '10px',
      marginRight: '20px',
      position: 'relative',
      justifyContent: 'space-around',
      '& $span': {
        fontWeight: '600 !important',
      },
    },
    newClaimButton: {
      marginLeft: 20,
    },
    filtersOptions: {
      '& $label': {
        textTransform: 'capitalize',
      },
      boxShadow: `0px 23px 20px ${Colors.RMSC.shadow}`,
      borderRadius: '0 0 5px 5px',
      position: 'absolute',
      top: '45px',
      background: Colors.white,
      padding: '20px',
      zIndex: '1',
      right: '0px',
      width: '130px',
      marginBottom: '12px',
      '& > :last-child': {
        marginBottom: '0',
      },
    },
    optionFilters: {
      '& $label': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '12px',
        paddingLeft: '7px',
        cursor: 'pointer',
      },
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'space-between',
      fontSize: '14px',
      alignItems: 'end',
    },
    dateFilterOptions: {
      padding: '10px',
      width: '150px',
    },
    dateFilter: {
      '& $span': {
        background: Colors.black,
        height: '6px',
        width: '6px',
        borderRadius: '25px',
        display: 'flex',
        alignSelf: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    disabled: {
      pointerEvents: 'none',
      opacity: '0.7',
    },
  };
});

const claimDetailsScreenOption = {title: 'Claim Details', link: '/claims/details'};

const Claims: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {user} = useSelector(authSelect);

  const screenOptions: NavigationMenuItem[] = [
    {title: 'New Claim', link: '/claims/form', isHidden: true},
    {title: 'Claims List', link: '/claims'},
    {title: 'Claim Drafts', link: '/claims/draft'},
    // {title: 'Pre-Auth History', link: '/claims/pre-auth-history'},
  ];
  const screenOptions2: NavigationMenuItem[] = [
    {title: 'Claims List', link: '/claims'},
    {title: 'Claim Drafts', link: '/claims/draft'},
  ];

  const handlePreAuthLink = () => {
    if (user.organization.country === 'KSA') {
      return screenOptions;
    }
    return screenOptions2;
  };

  const checkIfClaimsDraft = () => {
    return location.pathname.includes('claims/draft');
  };

  const checkIfNewClaim = () => {
    return location.pathname.includes('form');
  };

  const checkIfClaimDetails = () => {
    return location.pathname.includes('details');
  };

  const getNavigationOptions = () => {
    if (checkIfClaimDetails()) {
      return [claimDetailsScreenOption].concat(handlePreAuthLink());
    }
    return handlePreAuthLink();
  };

  const setHeaderFilters = () => {
    return !checkIfNewClaim() && !checkIfClaimDetails();
  };

  const userBranches = () => {
    const branches = localStorage.getItem('userBranches');
    return branches != null;
  };

  React.useEffect(() => {
    removeStorage('currentDraft');
    dispatch(
      setRequiredFilters({
        navigation: true,
        search: setHeaderFilters(),
        statusFilter: setHeaderFilters(),
        TPAFilter: false, //setHeaderFilters(),
        payersFilter: setHeaderFilters(),
        receiversFilter: setHeaderFilters(),
        typesFilter: setHeaderFilters(),
        visitFilter: setHeaderFilters(),
        dateRangeFilterDays: false, //setHeaderFilters(),
        branchesFilter: setHeaderFilters(),
        searchEpisode: setHeaderFilters(),
        visitDateFilter: true,
        // claimButtons: setHeaderFilters(),
      }),
    );

    dispatch(
      setNavOptions({
        navOptions: getNavigationOptions(),
      }),
    );
  }, [screenOptions, location.pathname]);

  return (
    <div className={classes.container}>
      <div>
        <Header />
      </div>
      <div className={classes.contentContainer}>
        <Switch>
          {routes &&
            routes.map(({routeKey, ...route}) => {
              return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
            })}
        </Switch>
      </div>
    </div>
  );
};

export default Claims;
