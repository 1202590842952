import React from 'react';
import * as Yup from 'yup';

import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import KlaimTypeSelect from 'modules/Common/components/KlaimTypeSelect';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  branchManagementSelect,
  branchValidateCredentials,
  validateCleanup,
  errorCleanup,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import * as Toast from 'modules/Common/utils/toast';
import {useStylesFromThemeFunction} from './BranchVerify';
import LoadingSpinner from '../../../../Common/components/LoadingSpinner';

export interface IValues {
  [field: string]: any;
}

const UAEForm: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();

  const {error, isLoading} = useAppSelector(branchManagementSelect);

  React.useEffect(() => {
    return () => {
      dispatch(errorCleanup());
    };
  }, []);

  const initialValues = {
    authority_login: '',
    authority_password: '',
    authority: 'DHA',
  };
  const validationSchema = Yup.object({
    authority_login: Yup.string().required('Username is required'),
    authority_password: Yup.string().required('Password is required'),
    authority: Yup.string().required(),
  });

  const onSubmit = (values: IValues) => {
    dispatch(branchValidateCredentials({values}));
  };

  const errorToast = () => {
    Toast.error('Invalid username or password');
  };

  return (
    <KlaimForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      <Header
        isLoading={isLoading}
        title="Verify Credentials"
        backlink="/settings/branch-management"
        description={
          <div className={classes.uploadNote}>
            First we need to identify the entity by providing credentials for new branch <br /> so that you can proceed
            to the next step <span>Learn more</span>
          </div>
        }
        buttonType="submit"
        buttonTitle="Next"
        body={
          <div className={classes.bodyWrapper}>
            {error && errorToast()}
            {isLoading ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={classes.formContainer}>
                <KlaimTypeSelect
                  name="authority"
                  values={[
                    {title: 'eClaimLink', value: 'DHA'},
                    {title: 'Shafafiya', value: 'HAAD'},
                  ]}
                />
                <KlaimInput name="authority_login" label="Username" />
                <KlaimInput name="authority_password" type="password" label="Password" showTooltip />
              </div>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default UAEForm;
