import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    permissionBoxContainer: {
      '& h1': {
        fontSize: '20px',
        fontWeight: 'semibold',
      },
      '& p': {
        margin: '0px',
        width: '100%',
      },
    },
    permissionsLabelView: {
      fontWeight: 'bold',
      textDecorationLine: 'underline',
      marginLeft: 20,
    },
    permissionsLabel: {
      fontWeight: 'bold',
      textDecorationLine: 'underline',
      marginLeft: 20,
      marginTop: 15,
    },
    permissionsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '10px',
      gap: '10px',
      margin: '1px',
    },
    rolePermissionContainer: {
      display: 'flex',
      minHeight: '30px',
      flexWrap: 'wrap',
      padding: '10px',
      marginLeft: '10px',
      gap: '10px',
      border: `1px solid ${Colors.grayLight}`,
      background: Colors.blueGrayLight,
      borderRadius: '10px',
    },
    rolePermissionContainerWithError: {
      display: 'flex',
      minHeight: '30px',
      flexWrap: 'wrap',
      padding: '10px',
      marginLeft: '10px',
      gap: '10px',
      border: `1px solid ${Colors.red}`,
      background: Colors.blueGrayLight,
      borderRadius: '10px',
    },
    labelText: {
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 700,
    },
    paragraphText: {
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 700,
    },
    errorText: {
      color: Colors.red,
      fontSize: 12,
      marginLeft: 15,
      paddingTop: 0,
      paddingBottom: 0,
      '& input': {
        color: 'black',
        borderColor: Colors.red,
        borderWidth: '1px',
      },
    },
  };
});
