import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FormikHelpers} from 'formik';
import {RootState} from 'modules/App/store';
import {authorityCodes} from 'modules/Common/constants/Country';
import axios from 'services/axios';

interface MyKnownError {
  stack: {error: {message: string}; message?: ErrorMessageObject[] | string};
  statusCode: number;
  status: number;
  message: string;
}

interface ErrorMessageObject {
  property: string;
  constraints: {
    matches: string;
  };
}

interface InsuranceAttributes {
  values: any;
  formikHelpers?: FormikHelpers<{
    [field: string]: any;
  }>;
}
export interface PractitionerBranch {
  id: number;
  name?: string;
}

interface IInsuranceData {
  id: number;
  active: boolean;
  name: string;
  insurances: {
    code: string;
    name: string;
    tpas: string[];
  }[];
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  branch?: {
    id: number;
    name: string;
    authority: string;
    identifier: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
  };
}
interface InitialState {
  isLoading: boolean;
  isDeleted?: boolean;
  isCreated: boolean;
  isEdited: boolean;
  error?: MyKnownError;
  tempError?: CustomError;
  companies: InsurancesData;
  insurers: Payer[];
  tpas: Payer[];
  insuranceData: IInsuranceData;
  branches: BranchesData;
}

const initialState: InitialState = {
  isLoading: false,
  isDeleted: false,
  isCreated: false,
  isEdited: false,
  error: undefined,
  insurers: [],
  tpas: [],
  insuranceData: {
    id: 0,
    active: true,
    name: '',
    insurances: [{code: '', name: '', tpas: ['']}],
    created_at: '',
    updated_at: '',
  },
  companies: {
    data: [],
    total: 0,
    result: 0,
  },
  branches: {
    data: {
      branches: [],
    },
  },
};

interface Tpa {
  id: number;
  name: string;
  active: boolean;
  identifier: string;
  created_at: string;
}
export interface Branch {
  id: number;
  name: string;
  authority: string;
  identifier: string;
}
export interface Insurance {
  id: number;
  active: boolean;
  name: string;
  tpas: string[];
  insurances: string[];
  branches: Branch[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
interface InsurancesQuery {
  offset?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  active?: boolean;
  codes?: string;
}

interface InsurancesData {
  data: Insurance[];
  total: number;
  result: number;
}
interface BranchesData {
  data: {
    branches: Branch[];
  };
}
interface BranchesQuery {
  offset?: number;
  limit?: number;
}
interface PractitionersQuery {
  offset?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  active?: boolean;
}

export interface Payer {
  _id: string;
  name: string;
  authorityCode: string;
  payerCode?: string;
  code?: string;
}
interface PayerData {
  data: Payer[];
}
interface DeleteQuery {
  id: number;
}

interface CustomError {
  message: string;
  statusCode: number;
}

export const getInsurances = createAsyncThunk<InsurancesData, InsurancesQuery, {rejectValue: MyKnownError}>(
  'companiesManagement/getInsurances',
  async (query: InsurancesQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/insurance/companies', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createInsurance = createAsyncThunk<Insurance[], InsuranceAttributes, {rejectValue: CustomError}>(
  'companiesManagement/createInsurance',
  async ({values, formikHelpers}: InsuranceAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.post('/insurance/companies', values);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteInsurance = createAsyncThunk<InsurancesData, DeleteQuery, {rejectValue: MyKnownError}>(
  'companiesManagement/deleteInsurance',
  async (query: DeleteQuery, {rejectWithValue}) => {
    try {
      const response = await axios.delete('/insurance/companies', {params: {id: query.id}});

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const editInsurance = createAsyncThunk<Insurance[], InsuranceAttributes, {rejectValue: MyKnownError}>(
  'companiesManagement/editInsurance',
  async ({values, formikHelpers}: InsuranceAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.put(`/insurance/companies/${values.id}`, values);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getBranches = createAsyncThunk<BranchesData, BranchesQuery, {rejectValue: MyKnownError}>(
  'companiesManagement/getBranches',
  async (query: PractitionersQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/branches', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getTPAs = createAsyncThunk<PayerData, any, {rejectValue: MyKnownError}>(
  'companiesManagement/getTPAs',
  async (query: PractitionersQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/payers', {
        params: {
          classification: 'TPA',
          authorityCode: 'NPHIES',
        },
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getInsuranceByCodes = createAsyncThunk<any, InsurancesQuery, {rejectValue: MyKnownError}>(
  'companiesManagement/getInsuranceByCode',
  async (query: InsurancesQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get('/payers-by-codes', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getInsurers = createAsyncThunk<
  PayerData,
  {classification?: string; authorityCode?: string},
  {rejectValue: MyKnownError}
>('companiesManagement/getInsurers', async (query, {rejectWithValue}) => {
  try {
    const response = await axios.get('/payers', {
      params: query,
    });
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getInsuranceById = createAsyncThunk<IInsuranceData, InsuranceAttributes, {rejectValue: MyKnownError}>(
  'companiesManagement/getInsuranceById',
  async ({values}: InsuranceAttributes, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/insurance/companies/${values.id}`, values);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const insuranceCompanyManagementSlice = createSlice({
  name: 'insuranceCompanyManagement',
  initialState,
  reducers: {
    errorCleanup: (state) => {
      state.error = undefined;
      state.tempError = undefined;
    },
    createCleanup: (state) => {
      state.isCreated = false;
    },
    editCleanup: (state) => {
      state.isEdited = false;
    },
    deleteCleanup: (state) => {
      state.isDeleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsurances.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurances.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInsurances.fulfilled, (state, action) => {
        state.companies.data = action.payload.data;
        state.companies.total = action.payload.total;
        state.isLoading = false;
      });
    builder
      .addCase(createInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInsurance.fulfilled, (state) => {
        state.isCreated = true;
        state.isLoading = false;
      })
      .addCase(createInsurance.rejected, (state, action) => {
        state.tempError = {message: 'Something went Wrong', statusCode: 500};
        state.isLoading = false;
      });
    builder
      .addCase(getBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranches.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.branches.data.branches = action.payload.data.branches;
        state.isLoading = false;
      });
    builder
      .addCase(getTPAs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTPAs.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTPAs.fulfilled, (state, action) => {
        state.tpas = action.payload.data;
        state.isLoading = false;
      });
    builder
      .addCase(getInsurers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInsurers.fulfilled, (state, action) => {
        state.insurers = action.payload.data;
        state.isLoading = false;
      });
    builder
      .addCase(deleteInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInsurance.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteInsurance.fulfilled, (state, action) => {
        state.isDeleted = true;
        state.isLoading = false;
      });
    builder
      .addCase(getInsuranceById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getInsuranceById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getInsuranceById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.insuranceData = action.payload;
      });
  },
});

export const {errorCleanup, createCleanup, editCleanup, deleteCleanup} = insuranceCompanyManagementSlice.actions;
export const insuranceCompanyManagementSelect = (state: RootState) => state.settings.insuranceCompanyManagement;
export default insuranceCompanyManagementSlice.reducer;
