import React from 'react';
import {NavLink} from 'react-router-dom';
import {IPatientResponse} from 'interfaces/patient.interface';
import {useStylesFromThemeFunction} from 'modules/Patients/PatientsList/CollapsibleRow/CollapsibleRow';
import dayjs from 'dayjs';

interface ComponentProps {
  patient: IPatientResponse;
  index: number;
  offset: number;
  limit: number;
}
const CollapsibleRow: React.FC<ComponentProps> = ({
  patient: {
    id,
    resource: {identifier, name, telecom},
    createdAt,
  },
  index,
  offset,
  limit,
}) => {
  const classes = useStylesFromThemeFunction();
  const [rowsOpen, setRowsOpen] = React.useState<boolean>(false);

  const officialName = name && name.length > 0 ? name.filter((_name) => _name.use === 'official')[0].text : '-';
  const phoneNumber = telecom && telecom.length > 0 ? telecom.filter((_tel) => _tel.system === 'phone')[0].value : '-';

  return (
    <NavLink to={`/patients/${id}`} style={{textDecoration: 'none'}}>
      <li className={classes.treeViewList} key={id}>
        <div className={`${classes.tableRow} ${classes.topNone}`} role="button" onClick={() => setRowsOpen(!rowsOpen)}>
          <div className={classes.listCountWrapper}>
            <div className={classes.listCount}>
              <div>{(offset - 1) * limit + index + 1}</div>
            </div>
          </div>
          <div className={classes.rowData}>{officialName}</div>
          <div className={classes.rowData}>{identifier[0].value}</div>
          <div className={classes.rowData}>{phoneNumber}</div>
          <div className={classes.rowData}>{dayjs(createdAt).format('D MMM YYYY hh:mm')}</div>
        </div>
      </li>
    </NavLink>
  );
};
export default CollapsibleRow;
