import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  label?: string;
  name: string;
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  type?: 'text' | 'email' | 'number' | 'password' | 'checkbox';
  labelClassName?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  errorClassName?: string;
  containerClassName?: string;
  optional?: boolean;
  value?: string;
  showTooltip?: boolean;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    contentContainer: {
      paddingTop: 10,
      paddingBottom: 10,
      '& span': {
        paddingLeft: '10px',
      },
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 'bold',
      color: Colors.grayLabel,
    },
    input: {
      fontSize: '15px',
      '&:focus': {
        outline: '0px',
        borderRadius: '10px',
        backgroundColor: Colors.white,
      },
      border: 'solid',
      borderWidth: '1px',
      borderColor: Colors.grayLight,
      borderRadius: '10px',
      padding: '10px',
      backgroundColor: Colors.blueGrayLight,
      color: Colors.grayLabel,
    },
    error: {
      color: Colors.red,
      fontSize: 12,
      marginLeft: 5,
      paddingTop: 0,
      paddingBottom: 0,
      '& input': {
        color: 'black',
        borderColor: Colors.red,
        borderWidth: '1px',
      },
    },
    disabled: {
      opacity: '50%',
    },
    validated: {
      color: Colors.lightGreen,
      '& input': {
        borderWidth: '1px',
      },
    },
    passwordContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      width: '100%',
    },
    passwordSubContainer: {
      border: 'solid',
      borderWidth: '1px',
      borderRadius: '10px',
      borderColor: Colors.grayLight,
      backgroundColor: Colors.blueGrayLight,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    passwordError: {
      borderColor: Colors.red,
      borderWidth: '1px',
    },
    passwordValid: {
      borderColor: Colors.lightGreen,
    },
    passwordInput: {
      border: 0,
      height: '100%',
      width: '100%',
      borderRadius: '10px 0px 0px 10px',
      backgroundColor: Colors.blueGrayLight,
      padding: '12px',
      fontSize: '15px',
      '&:focus': {
        outline: '0px',
        borderRadius: '10px',
        backgroundColor: Colors.blueGrayLight,
      },
    },
    passwordIcon: {
      border: 0,
      paddingTop: 2,
      paddingBottom: 2,
      backgroundColor: Colors.blueGrayLight,
    },
  };
});
