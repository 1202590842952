import {createUseStyles} from 'react-jss';
import {Colors} from '../../../Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0.5px 2px #60617029',
      borderRadius: 10,
      marginBottom: 38,
    },
    header: {
      textAlign: 'left',
      font: 'normal normal bold 22px/34px Poppins',
      letterSpacing: 0.22,
      fontSize: 22,
      color: '#2C273C',
      marginBottom: 38,
    },
    description: {
      textAlign: 'left',
      font: 'normal normal normal 15px/34px Poppins',
      letterSpacing: 0.45,
      color: '#2C273C',
      marginBottom: 38,
    },
    spacedRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 28,
    },
    searchInput: {
      flex: 1,
      padding: '10px 16px',
      backgroundColor: Colors.blueGrayLight,
      fontSize: 17,
      border: '1px solid #E7ECF5',
      borderRadius: 10,
      minWidth: 400,
      maxWidth: 400,
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 12,
    },
    label: {
      font: 'normal normal 600 13px/30px Poppins',
      letterSpacing: '0.39px',
      color: '#302E38',
      opacity: 1,
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '6px 12px',
    },
    value: {
      font: 'normal normal 500 13px/30px Poppins',
      letterSpacing: '0.39px',
      color: '#48444f',
      opacity: 1,
    },
    button: {
      width: 75,
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: '5px 15px',
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    navLink: {
      textDecoration: 'none',
    },
    br: {
      height: 50,
      width: 1,
    },
    delete: {
      backgroundColor: 'red',
      border: 'none',
    },
    loading: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    statusCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexGap: 10,
    },
    status: {
      height: 12,
      width: 12,
      borderRadius: 12,
      backgroundColor: Colors.successTextGreen,
      '&.submitting, &.submitted, &.resubmitting, &.acknowledged': {backgroundColor: Colors.purple},
      '&.cancelling, &.cancelled': {backgroundColor: Colors.grayMediumLight},
      '&.error': {backgroundColor: Colors.red},
      '&.approved': {backgroundColor: Colors.lightGreen},
      '&.acknowledgement': {backgroundColor: Colors.lightDimGreen},
    },
    statusText: {
      marginLeft: 10,
      textTransform: 'capitalize',
    },
    labelValue: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    checkBox: {
      marginTop: 10,
    },
    approvedStatus: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#33E5BE',
      fontSize: 14,
      padding: '2px 2px',
      backgroundColor: '#CFF5ED',
      borderRadius: 5,
      width: 75,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    statuse: {
      font: 'normal normal medium 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#E64444',
      fontSize: 12,
      padding: '2px 6px',
      backgroundColor: '#FFA7A7',
      borderRadius: 5,
      display: 'inline-block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    date: {
      font: 'normal normal normal 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#3F465D',
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 4,
    },
    tableContainer: {
      backgroundColor: 'white',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      marginTop: 26,
      position: 'relative',
      overflowX: 'auto',
      width: '100%',
    },
    tableBodyContainer: {
      width: '100%',
    },
    tableHeaderContainer: {},
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
    },
    absolute: {
      position: 'absolute',
      right: 0,
      top: 8,
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'red',
    },
    pagination: {
      // minWidth: '1200px',
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
    },
    update: {
      padding: '6px 16px',
      backgroundColor: 'lightblue',
      color: 'white',
      borderRadius: 4,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
    popupContainer: {
      '&:hover': {
        '& $popup': {display: 'block', zIndex: 10},
      },
    },
    popup: {
      fontSize: 14,
      padding: '4px 7px',
      backgroundColor: 'white',
      borderRadius: 5,
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'none',
      boxShadow: '0px 0.5px 2px #C7C5C9',
    },
  };
});
