import React from 'react';
import {Classes} from 'jss';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

interface ComponentProps {
  size?: 's' | 'm' | 'l' | 'xl';
  weight?: 'normal' | 'bold';
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    base: {
      color: Colors.purple,
      cursor: 'pointer',
    },
    bold: {
      fontWeight: 'bold',
    },
    small: {
      fontSize: '12px',
    },
    medium: {
      fontSize: '16px',
    },
    large: {
      fontSize: '20px',
    },
    xlarge: {fontSize: '24px'},
  };
});

const fontSizeStyle = (classes: Classes<any>, size: string) => {
  switch (size) {
    case 's':
      return classes.small;
    case 'm':
      return classes.medium;
    case 'l':
      return classes.large;
    case 'xl':
      return classes.xlarge;
    default:
      return '';
  }
};

const KlaimSpan: React.FC<ComponentProps> = ({size = 's', weight, children, onClick}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <span
      onClick={onClick}
      role="button"
      className={`${classes.base} ${weight === 'bold' && classes.bold} ${fontSizeStyle(classes, size)} `}
    >
      {children}
    </span>
  );
};

export default KlaimSpan;
