import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import authReducers from 'modules/Auth/v3/authV3Reducer';
import reportProgressStatusReducers from 'modules/Reports/store/reportProgressStatus';
import settingsReducer from 'modules/Settings/settingsReducer';
import headerReducer from 'modules/Header/headerSlice';
import toastReducer from 'modules/Common/components/KlaimToast/toastSlice';
import preAuthReducer from 'modules/Claims/containers/PreAuthHistory/preAuthReducer';
import eligibilityReducers from 'modules/Eligibility/eligibilitySlice';
import preAuthV2Reducer from 'modules/PreAuthV2/PreAuthV2Slice';
import patientsReducer from 'modules/Patients/patientsSlice';
import insuranceReducer from 'modules/Insurance/insuranceSlice';
import patientReducer from 'modules/Patient/patientSlice';
import pratitionerReducer from 'modules/Practitioner/practitionerSlice';
import activityReducer from 'modules/Activity/activitySlice';
import diagnosisReducer from 'modules/Diagnosis/diagnosisSlice';
import claimKsaReducer from 'modules/Claims/claimsSlice';
import midTableReducer from 'modules/MidTable/midTableSlice';
import paymentsReducer from 'modules/Payments/PaymentsReducer';
import insuranceManagementReducer from 'modules/Settings/features/Insurance/InsuranceSlice';
import hipdReducer from 'modules/Hipd/hipdSlice';
import payerReducers from './payers';
import claimReducers from './claim';
import claimDraftReducers from './claimDraft';
import pharmacyReducer from '../../Pharmacy/pharmacyReducer';

const store = configureStore({
  reducer: {
    activity: activityReducer,
    eligibility: eligibilityReducers,
    payers: payerReducers,
    claimDraft: claimDraftReducers,
    auth: authReducers,
    reportProgressStatus: reportProgressStatusReducers,
    claim: claimReducers,
    claimKsa: claimKsaReducer,
    header: headerReducer,
    settings: settingsReducer,
    pharmacy: pharmacyReducer,
    payments: paymentsReducer,
    toast: toastReducer,
    preAuth: preAuthReducer,
    preAuthV2: preAuthV2Reducer,
    insurance: insuranceReducer,
    patients: patientsReducer,
    patient: patientReducer,
    practitioner: pratitionerReducer,
    diagnosis: diagnosisReducer,
    hipd: hipdReducer,
    midtable: midTableReducer,
    insuranceManagement: insuranceManagementReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
