import React from 'react';
import EllipsisText from 'react-ellipsis-text';
import {
  ComponentProps,
  OptionValue,
  useStylesFromThemeFunction,
} from 'modules/Common/components/KlaimMultiSelect/KlaimMultiSelect';
import checkIcon from 'assets/check_icon.svg';
import deselectIcon from 'assets/deselect_icon.svg';
import searchIcon from 'assets/search_icon.svg';

const KlaimMultiSelect: React.FC<ComponentProps> = ({
  options,
  setValue,
  selectAllTitle,
  disableSearch,
  disableSelectAll,
  onChange,
  value,
  className,
}) => {
  const classes = useStylesFromThemeFunction();
  const [selected, setSelected] = React.useState<OptionValue[]>(value !== undefined ? value : []);
  const [filter, setFilter] = React.useState<OptionValue[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [typing, setTyping] = React.useState<boolean>(false);

  React.useEffect(() => {
    setFilter(
      options.filter(
        (option) =>
          (option.label && option.label.toLowerCase().match(search.toLowerCase())) ||
          (option.value && option.value.toLowerCase().match(search.toLowerCase())),
      ),
    );
  }, [search]);

  React.useEffect(() => {
    setFilter(options);
  }, [options]);

  const searchFieldValidate = (e: any) => {
    const stringToValidate = e.target.value;
    const validatedString = stringToValidate.replace(/[^a-zA-Z0-9]/g, '');
    setSearch(validatedString);
  };

  const isSelected = (option: OptionValue) => {
    return selected.some((select) => select.value === option.value);
  };

  const selectAll = () => {
    if (options.length === selected.length) {
      setSelected([]);
      if (setValue) setValue([]);
      if (onChange) onChange([]);
    } else {
      const newValue = [
        ...selected,
        ...options.filter((option) => {
          if (selected.some((select) => select.value === option.value)) {
            return false;
          }
          return true;
        }),
      ];
      setSelected(newValue);
      if (setValue) setValue(newValue);
      if (onChange) onChange(newValue);
    }
  };

  const onSelect = (option: OptionValue) => {
    if (selected.some((select) => select.value === option.value)) {
      const newValue = [...selected.filter((select) => select.value !== option.value)];
      setSelected(newValue);
      if (setValue) setValue(newValue);
      if (onChange) onChange(newValue);
    } else {
      const newValue = [...selected, option];
      setSelected(newValue);
      if (setValue) setValue(newValue);
      if (onChange) onChange(newValue);
    }
  };

  return (
    <div className={`${classes.container} ${className}`}>
      {!disableSearch && (
        <div className={classes.searchContainer}>
          <div className={classes.subSearchContainer}>
            <input
              className={classes.searchInput}
              onChange={(e) => searchFieldValidate(e)}
              value={search}
              onFocus={() => setTyping(true)}
              onBlur={() => setTyping(false)}
            />
            <img src={searchIcon} alt="Search Icon" />
          </div>
          {/* <div className={classes.hiddenDiv} /> */}
        </div>
      )}
      {!disableSelectAll && (
        //

        <div className={`${classes.buttonContainer} ${classes.checkAllContainer}`}>
          <button type="button" onClick={() => selectAll()}>
            {options.length === selected.length ? (
              <div className={classes.check}>
                <img className={classes.deselectImg} src={deselectIcon} alt="deselect" />
              </div>
            ) : (
              <div className={classes.uncheck} />
            )}
          </button>
          <span>{selectAllTitle === undefined ? 'Select All' : selectAllTitle}</span>
        </div>

        //
      )}
      {filter.map((option: OptionValue, index: number) => {
        return (
          <div className={classes.buttonContainer} key={`${option.value}_${index}`}>
            <button type="button" onClick={() => onSelect(option)}>
              {isSelected(option) ? (
                <div className={classes.check}>
                  <img src={checkIcon} alt="check" />
                </div>
              ) : (
                <div className={classes.uncheck} />
              )}
            </button>

            {option.leftIcon && <img src={option.leftIcon} alt="left" />}
            <span className={classes.optionText}>
              <EllipsisText text={option.label} length={'50'} />
            </span>
            {option.rightIcon && <img src={option.rightIcon} alt="right" />}
          </div>
        );
      })}
    </div>
  );
};

export default KlaimMultiSelect;
