import {RootState} from 'modules/App/store';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'services/axios';
import {decodeJWT, TokenData} from 'services/jwt';
import {FormikHelpers} from 'formik';

interface EmailValidateAtrributes {
  hash: string;
}
interface ResendLinkAtrributes {
  email: string;
}

export const verifyEmailRequest = async ({hash}: EmailValidateAtrributes) => {
  try {
    const response = await axios.post(`/auth/verify-email/${hash}`);
    return response;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
    return err;
  }
};

export const resendVerificationLink = async ({email}: ResendLinkAtrributes) => {
  try {
    const response = await axios.post(`/auth/resend-verification`, {email});
    return response.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
    return err;
  }
};
