import React, {FC, ReactNode, useEffect, useState} from 'react';
import sectionCollapseArrow from 'assets/section_collapse_arrow.svg';
import sectionExpandedArrow from 'assets/section_expanded_arrow.svg';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    collapsableArrow: {
      marginLeft: 20,
      marginRight: 20,
    },
    collapsableArrowContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
    },
    collapsedSectionHeader: {
      flex: 1,
    },
    collapsedSectionBody: {
      overflow: 'auto',
      background: 'white',
      transition: 'all .5s ease-in-out',
    },
    collapsedSectionContainer: {
      alignItems: 'center',
      background: 'white',
      borderRadius: 10,
      boxShadow: '0px 5px 5px -1px rgb(96 97 112 / 16%)',
      display: 'flex',
      flexDirection: 'row',
      minHeight: 50,
      left: '0',
      right: '0',
      zIndex: 19,
    },
    expandedContainer: {
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    contentContainer: {
      minHeight: 100,
    },
  };
});

interface ComponentProps {
  collapsed?: boolean;
  renderContent: () => ReactNode;
  renderHeader?: () => ReactNode;
  theme?: KlaimTheme;
  collapsedContainerClassName?: string;
  contentClassName?: string;
  expandedContainerClassName?: string;
}

const CollapsibleSection: FC<ComponentProps> = ({
  collapsed: defaultValue,
  renderContent,
  renderHeader,
  theme,
  collapsedContainerClassName,
  contentClassName,
  expandedContainerClassName,
}) => {
  const classes = useStylesFromThemeFunction();
  const [height, setHeight] = useState(defaultValue ? '0%' : '100');
  const [collapsed, setCollapsed] = React.useState(defaultValue);

  useEffect(() => {
    setHeight(defaultValue ? '0%' : '100');
  }, [defaultValue]);

  const handleCollapse = () => {
    setHeight(height === '0' ? '100' : '0%');
    setCollapsed(!collapsed);
  };

  return (
    <div className={`${classes.expandedContainer}  ${expandedContainerClassName}`}>
      <div className={`${classes.collapsedSectionContainer} ${collapsedContainerClassName}`}>
        <div className={classes.collapsableArrowContainer} onClick={handleCollapse} role="button">
          {collapsed ? (
            <img src={sectionCollapseArrow} alt="collapse-arrow" className={classes.collapsableArrow} />
          ) : (
            <img src={sectionExpandedArrow} alt="expanded-arrow" className={classes.collapsableArrow} />
          )}
        </div>
        <div className={classes.collapsedSectionHeader}>{renderHeader && renderHeader()}</div>
      </div>
      <div
        className={classes.collapsedSectionBody}
        style={{flexGrow: collapsed ? 1 : 0, height: collapsed ? '100px' : '0%'}}
      >
        {renderContent()}
      </div>
    </div>
  );
};

export default CollapsibleSection;
