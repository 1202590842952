import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      minWidth: 200,
    },
    titleContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 6,
      paddingBottom: 6,
      backgroundColor: Colors.blueGrayLight,
      borderRadius: 14,
      borderStyle: 'solid',
      borderColor: Colors.grayLight,
      borderWidth: 2,
      display: 'flex',
      alignItems: 'center',
    },
    titleText: {
      fontSize: 14,
      fontWeight: 700,
      color: Colors.blueGrayDark,
      marginLeft: 10,
      marginRight: 10,
    },
    titleIcon: {
      height: 18,
    },

    buttonContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 4,
      paddingBottom: 4,
      flex: '1 1 0%',
      fontWeight: 600,
      fontSize: 14,
      color: Colors.blueGrayDark,
      display: 'flex',
      alignItems: 'center',
      marginTop: 2,
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
    },
    button: {
      backgroundColor: Colors.purple,
      border: 'none',
      paddingLeft: 16,
      paddingRight: 16,
    },
    optionText: {whiteSpace: 'nowrap', color: Colors.blueGrayDark, fontWeight: 600, fontSize: 14},
    searchContainer: {
      paddingLeft: 6,
      paddingRight: 6,
      overflow: 'hidden',
      backgroundColor: Colors.blueGrayLight,
      borderRadius: 14,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: Colors.grayLight,
      '& input:focus': {
        outline: 'none',
      },
    },
    searchInput: {
      height: '100%',
      padding: 0,
      borderStyle: 'none',
      backgroundColor: Colors.blueGrayLight,
    },
    optionButton: {
      outline: 'none',
      border: 'none',
      width: '100%',
      background: 'none',
      textAlign: 'left',
      padding: 0,
    },
    activeText: {
      '& span': {
        color: Colors.purple,
      },
    },
  };
});

export interface OptionValue {
  label: string;
  value: string;
  leftIcon?: string;
  rightIcon?: string;
}

export interface ComponentProps {
  options: OptionValue[];
  setValue?: React.Dispatch<React.SetStateAction<OptionValue>>;
  onChange?: (values: OptionValue) => void;
  value?: OptionValue;
  filter?: boolean;
}
