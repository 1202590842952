import React from 'react';
import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import {createUseStyles} from 'react-jss';

import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import {IPatient} from '../../../../interfaces/patient.interface';
import {
  KlaimCollapsible,
  KlaimCollapsibleBody,
  KlaimCollapsibleHeader,
} from '../../../Common/components-v2/KlaimCollapsible';

interface ComponentProps {
  patient?: IPatient;
}

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    row: {
      borderBottom: '1px solid',
    },
    column: {
      marginBottom: 10,
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold',
      color: '#2C273C',
    },
    line: {
      borderBottom: '1px solid #EEEAFB',
      padding: '0 !important',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  };
});

const PatientDetail: React.FC<ComponentProps> = ({patient, children}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <KlaimCollapsible style={{boxShadow: '0px 5px 5px -1px rgb(96 97 112 / 16%)', marginBottom: 10, flexGrow: 0}}>
      <KlaimCollapsibleHeader style={{padding: 10}} buttonFill="#6135FB">
        <h2 className={classes.title}>
          Patient <span style={{fontWeight: 400}}>({patient?.name || '-'})</span>
        </h2>
      </KlaimCollapsibleHeader>
      <KlaimCollapsibleBody style={{padding: 20}}>
        <div className={classes.container}>
          <KlaimValue direction="column" label={`Full Name`} value={patient?.name || '-'} />
          <KlaimValue direction="column" label={`Date of Birth`} value={patient?.dateOfBirth || '-'} />
          <KlaimValue direction="column" label={`Gender`} value={patient?.gender || '-'} />
          <KlaimValue direction="column" label={`Civil Status`} value={patient?.maritalStatus || '-'} />
          <div className={classes.line} style={{gridColumn: 'span 4 / span 4', width: '100%'}} />
          <KlaimValue direction="column" label={`Mobile Number`} value={patient?.phoneNumber || '-'} />
          {children}
        </div>
      </KlaimCollapsibleBody>
    </KlaimCollapsible>
  );
};

export default PatientDetail;
