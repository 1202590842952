import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import edit from 'assets/edit.svg';
import {Link} from 'react-router-dom';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    userInfoContent: {
      color: '#302E38',
      fontSize: '18px',
      marginTop: '15px',
    },
    userInfoHeader: {
      '& a': {
        color: '#6135FB',
        textDecoration: 'none',
      },
      '& span': {
        color: '#222B45',
        fontSize: '13px',
        fontWeight: '600',
      },
      alignItems: 'center',
      borderBottom: '1px solid #60617029',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '10px',
    },
    userInfoIcon: {
      '& a': {
        alignItems: 'center',
        display: 'flex',
      },
      '& img': {
        marginRight: '10px',
      },
      '& span': {
        color: '#6135FB',
        fontSize: '16px',
        fontWeight: '600',
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
  };
});

interface ComponentProps {
  title: string;
  content: string;
  editLink?: any;
}

const UserInfo: FC<ComponentProps> = ({title, content, editLink}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div className={classes.userInfo}>
      <div className={classes.userInfoHeader}>
        <span>{title}</span>
        <div className={classes.userInfoIcon}>
          {editLink && (
            <Link to={editLink}>
              <img src={edit} alt="edit-icon" />
              Edit
            </Link>
          )}
        </div>
      </div>
      <span className={classes.userInfoContent}>{content}</span>
    </div>
  );
};

export default UserInfo;
