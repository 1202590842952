import store from 'modules/App/store';
import {setNewToast, removeToast} from 'modules/Common/components/KlaimToast/toastSlice';

// TODO: Remove all magic strings.

export const dispatchNotif = (message: string, title?: string, variant?: string) => {
  const id = Math.random().toString(36).substr(2, 9);
  let varaintDefault = 'Notification';
  if (variant === 'success') varaintDefault = 'Successful';
  if (variant === 'error') varaintDefault = 'Error';
  if (variant === 'warning') varaintDefault = 'Warning';
  if (variant === 'download') varaintDefault = 'Downloading...';
  const setTo = setTimeout(() => {
    store.dispatch(
      setNewToast({
        id,
        title: title || varaintDefault,
        message,
        variant,
      }),
    );
    clearTimeout(setTo);
  }, 100);
  const delTo = setTimeout(() => {
    store.dispatch(removeToast(id));
    clearTimeout(delTo);
  }, 3000);
};

export const error = (msg: string, title?: string) => {
  dispatchNotif(msg, title, 'error');
};
export const info = (msg: string, title?: string) => {
  dispatchNotif(msg, title, 'info');
};
export const warning = (msg: string, title?: string) => {
  dispatchNotif(msg, title, 'warning');
};
export const success = (msg: string, title?: string) => {
  dispatchNotif(msg, title, 'success');
};
export const download = (msg: string, title?: string) => {
  dispatchNotif(msg, title, 'download');
};
