import React from 'react';
import {createUseStyles} from 'react-jss';
import EnterIcon from 'assets/component/EnterIcon';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';

interface ComponentProps {
  patient: any;
  onClick: (value: any) => void;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    gridRow: {
      gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
      display: 'grid',
      padding: 8,
      borderRadius: 8,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.container.background.secondary,
      },
    },
    value: {
      fontSize: 14,
      color: theme.formElement.color.tertiary,
    },
  };
});
const PatientSelectRow: React.FC<ComponentProps> = ({onClick, patient}) => {
  const classes = useStylesFromThemeFunction();
  const handleSelect = () => {
    onClick(patient);
  };

  function chunk(str: string, n: number) {
    const ret = [];
    let i;
    let len;

    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }

    return ret;
  }

  const convertedNumber = () => {
    const patientNumber = patient.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);
    if (patientNumber) {
      return `+1 (${patientNumber[1]}) ${patientNumber[2]} - ${patientNumber![3]}`;
    }
    return '';
  };

  return (
    <KlaimIconButton onClick={handleSelect} style={{width: '100%'}}>
      <div className={classes.gridRow}>
        <div style={{display: 'flex', alignItems: 'center', gridColumn: 'span 2 / span 2'}}>
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: 99999,
              backgroundColor: 'gray',
              marginRight: 4,
            }}
          />
          <span className={classes.value}>{patient.name}</span>
        </div>
        {/* <div>
          <span className={classes.value}>{chunk(patient.idNumber, 4).join('-')}</span>
        </div> */}
        {/* <div>
          <span className={classes.value}>{convertedNumber()}</span>
        </div> */}
        <div>
          <span className={classes.value}>{patient.birthday}</span>
        </div>
        <div>
          <span className={classes.value}>{patient.gender}</span>
        </div>
        <div style={{display: 'flex', WebkitJustifyContent: 'flex-end', alignItems: 'center'}}>
          <span style={{marginRight: 10, fontSize: 12}}>Select</span>
          <div style={{width: 15}}>
            <EnterIcon />
          </div>
        </div>
      </div>
    </KlaimIconButton>
  );
};

export default PatientSelectRow;
