import React from 'react';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import {StepperContext} from '../KlaimStepper/index';

interface ComponentProps {
  submitText?: string;
  cancelText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  setCurrentStep?: (s: number) => void;
  isNextDisabled?: boolean;
}

const KlaimStepperFooter: React.FC<ComponentProps> = ({
  submitText,
  cancelText,
  onSubmit,
  onCancel,
  setCurrentStep,
  isNextDisabled,
}) => {
  const {totalSteps, step, setStep} = React.useContext(StepperContext);

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleStepChange = (s: number) => {
    setStep(s);
    if (setCurrentStep) setCurrentStep(s);
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <div>
        {onCancel && step === 0 && (
          <KlaimButton variant={'primary-outline'} onClick={() => handleCancel()}>
            {cancelText || 'Cancel'}
          </KlaimButton>
        )}
        {step > 0 && (
          <KlaimButton variant={'primary-outline'} onClick={() => handleStepChange(step - 1)}>
            Previous
          </KlaimButton>
        )}
        {step + 1 < totalSteps && (
          <KlaimButton disabled={isNextDisabled} onClick={() => handleStepChange(step + 1)}>
            Next
          </KlaimButton>
        )}
        {step + 1 === totalSteps && (
          <KlaimButton type={'submit'} onClick={handleSubmit}>
            {submitText || 'Submit'}
          </KlaimButton>
        )}
      </div>
    </div>
  );
};

export default KlaimStepperFooter;
