export interface ErxDescriptionError {
  statusCode: number;
  status: number;
  message: string;
}

export enum ErxStatus {
  requesting_authorization = 'requesting_authorization',
  authorized = 'authorized',
  rejected = 'rejected',
  canceled = 'canceled',
  dispensed = 'dispensed',
}

export interface ErxID {
  erxId: string;
  providerId: string;
}

export interface DefaultAttributes {
  attribute?: any;
}

export interface DiagnoseDetails {
  type: string;
  code: string;
  description: {
    short: string;
    long: string;
  };
}

export interface SenderDetails {
  id: string;
  name: string;
}

export interface ReceiverDetails {
  id: string;
  name: string;
}

export interface PayerDetails {
  id: string;
  name: string;
}

export interface ClinicianDetails {
  id: string;
  name: string;
}

export interface PatientDetails {
  member_id: string;
  emirates_id: string;
  date_of_birth: string;
  weight: number;
}

export interface EncounterDetails {
  facility: FacilityDetails;
  type: number;
}

export interface FacilityDetails {
  id: string;
  name: string;
}

export interface AuthorizationDetails {
  id: string;
  submissionError: boolean;
}

export interface DrugDetails {
  activityId: string;
  erxRef: string;
  code: string;
  name: string;
  strength: string;
  dosageFormPackage: string;
  manufacturer: string;
  packagePrice: string;
  unitPerPackage: string;
  unitPrice?: number;
  quantity: number;
  duration: number;
  instructions: string;
  net: number;
  netOriginal: number;
  list: number;
  patientShare: number;
  paymentAmount: number;
  status: ErxStatus;
}

export interface ErxDetailsById {
  id: string;
  reference: string;
  sender: SenderDetails;
  receiver: ReceiverDetails;
  payer: PayerDetails;
  transaction_date: string;
  transaction_date_prior_authorization: string;
  clinician: ClinicianDetails;
  patient: PatientDetails;
  encounter: EncounterDetails;
  diagnoses: DiagnoseDetails[];
  drugs: DrugDetails[];
  authorization: AuthorizationDetails;
  status: ErxStatus;
  comments?: string;
  limit: number;
}

export interface ErxDispenseDescription {
  erxId: string;
  providerId: string;
  activityIds: [];
}

export interface ErxDispenseDescriptionResponseId {
  id: string;
}

export interface InitialState {
  data?: ErxDetailsById;
  isLoading: boolean;
  error?: ErxDescriptionError;
  viewId?: ErxDispenseDescriptionResponseId;
}
