import React from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Register/AccountSetup/AccountSetup';
import KlaimInput from 'modules/Common/components/KlaimInput';
import Button from 'modules/Common/components/Button';

export interface Values {
  [field: string]: any;
}

interface ComponentProps {
  next?: any;
  prev?: any;
  data?: any;
}
const validationSchema = Yup.object({
  name: Yup.string().required('Full Name is required'),
  phone: Yup.string().required('Phone is required'),
});

const AccountSetup: React.FC<ComponentProps> = ({next, prev, data}) => {
  const classes = useStylesFromThemeFunction();

  const handleSubmit = (
    values: any,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    next(values, true, formikHelpers);
  };

  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={data} validationSchema={validationSchema} enableReinitialize>
        {({values}) => (
          <Form>
            <div className={classes.headerContainer}>
              <h1>Account Setup</h1>
              <p>Finish setting up your account by filling the following details.</p>
            </div>
            <div>
              <KlaimInput name="name" label="Full Name" placeholder="Name Surname" />
              <KlaimInput name="phone" label="Phone" placeholder="+971 00 000 0000" />
            </div>
            <div className={classes.footerContainer}>
              <Button
                title="Prev"
                type="button"
                onClick={() => {
                  prev(values);
                }}
              />
              <Button title="Finish" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AccountSetup;
