import React from 'react';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {FormikHelpers} from 'formik';
import {useHistory} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import {useAppDispatch} from 'modules/App/store';
import {
  branchManagementSelect,
  createBranch,
  createCleanup,
  validateCleanup,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import BranchVerify from 'modules/Settings/features/BranchManagement/BranchVerify';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import * as Toast from 'modules/Common/utils/toast';
import {useStylesFromThemeFunction} from './BranchAdd';

export interface IValues {
  [field: string]: any;
}

const BranchDepartmentAdd: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {error, credentials, validated, isCreated, isLoading} = useSelector(branchManagementSelect);
  const initialValues = {
    name: credentials?.name || '',
    identifier: credentials?.license || '',
    authority: credentials?.authority || '',
    authority_login: credentials?.authority_login || '',
    authority_password: credentials?.authority_password || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    identifier: Yup.string().required(),
    authority: Yup.string().required(),
    authority_login: Yup.string().required(),
    authority_password: Yup.string().required(),
  });

  const onSubmit: (
    values: IValues,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: IValues, formikHelpers) => {
    dispatch(createBranch({values, formikHelpers}));
  };

  React.useEffect(() => {
    if (validated === true && isCreated === true) {
      Toast.success('Branch is added successfully', 'Success');
      history.push('/settings/branch-management');
    }
    return () => {
      dispatch(validateCleanup());
      dispatch(createCleanup());
    };
  }, [isCreated]);

  if (!validated) {
    return <BranchVerify />;
  }

  return (
    <KlaimForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      <Header
        title="Add Branch"
        backlink="/settings/branch-management"
        description={
          <p>
            Please check and confirm the details of the facility. Click ‘Done’ to add this facility to your
            organization..
          </p>
        }
        buttonType="submit"
        buttonTitle="Done"
        body={
          <div className={classes.bodyWrapper}>
            {isLoading ? (
              <div>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {error && (
                  <div className={classes.errorMessage}>{error.stack ? error.stack.error.message : error.message}</div>
                )}
                <KlaimInput name="name" label="Facility Name" placeholder="CLINIC XYZ" value={credentials.name} />
                <KlaimInput
                  name="identifier"
                  label="Provider ID"
                  placeholder="XX-XX-100-10"
                  value={credentials.license}
                  isReadOnly
                />
                <KlaimInput
                  name="authority"
                  label="Authority"
                  placeholder="DHA"
                  value={credentials.authority}
                  isReadOnly
                />
                <KlaimInput
                  name="authority_login"
                  label="Authority Login"
                  placeholder="email@email.com"
                  value={credentials.authority_login}
                  isReadOnly
                />
                <KlaimInput
                  name="authority_password"
                  label="Authority Password"
                  type="password"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  isReadOnly
                />
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default BranchDepartmentAdd;
