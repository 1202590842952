import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  label?: string;
  name: string;
  placeholder?: string;
  errorClassName?: string;
  containerClassName?: string;
  optional?: boolean;
  defaultOptions: any;
  className?: any;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    contentContainer: {
      // padding: '10px',
    },
    label: {
      paddingLeft: '5px',
      paddingRight: '10px',
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 'bold',
      color: Colors.grayLabel,
    },
    error: {
      color: Colors.red,
      '& .searchSelect__control': {
        border: `1px solid ${Colors.red} !important`,
      },
      '& .searchSelect__menu': {
        color: Colors.black,
      },
      fontSize: 12,
      marginLeft: 5,
      paddingBottom: 5,
    },
    validated: {
      '& .searchSelect__control': {
        border: `1px solid ${Colors.lightGreen} !important`,
      },
    },
  };
});

export const customStyles = {
  control: (base: any, state: {isFocused: any}) => ({
    ...base,
    background: Colors.blueGrayLight,
    borderRadius: 10,
    height: 40,
    '&:hover': {borderColor: `${Colors.grayLight}`},
    border: `1px solid ${Colors.grayLight}`,
    boxShadow: 'none',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    borderRadius: 10,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 10,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: Colors.blueGrayDark,
    '&:hover': {
      color: Colors.blueGrayDark,
    },
  }),
  option: (base: any, state: {isSelected: any}) => ({
    ...base,
    backgroundColor: state.isSelected && Colors.purple,
    '&:hover': {backgroundColor: !state.isSelected && Colors.blueGrayLight},
    ':active': {
      ...base[':active'],
      backgroundColor: Colors.blueGrayLight,
    },
  }),
  singleValue: (base: any) => ({
    fontSize: '15px',
    marginLeft: '1px',
  }),
};
