import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Common/components/KlaimTooltip/KlaimTooltip';

interface Requirements {
  message: string;
}

interface ComponentProps {
  icon?: string;
  title?: string;
  requirements?: Requirements[];
  tooltipClass?: string;
  tooltipArrowClass?: string;
  contentClass?: string;
  value?: string;
  description?: string;
}

const KlaimTooltip: React.FC<ComponentProps> = ({
  icon,
  requirements,
  title = 'Requirements',
  tooltipClass,
  tooltipArrowClass,
  contentClass,
  value,
  description,
}) => {
  const classes = useStylesFromThemeFunction();
  const [hover, setHover] = React.useState(false);
  return (
    <div
      className={tooltipClass || classes.tooltipContainer}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {value ? <p>{value}</p> : <img src={icon} alt="tooltip icon" />}
      {hover && (
        <>
          <div className={`${contentClass} ${classes.contentContainer}`}>
            <p>{description}</p>
            <h3>{title ? `${title}:` : ''}</h3>
            <ul>
              {requirements &&
                requirements.map((req, index) => (
                  <li key={index} style={{padding: 4}}>
                    {req.message}
                  </li>
                ))}
            </ul>
          </div>
          <div className={`${tooltipArrowClass} ${classes.tooltipArrow}`} />
        </>
      )}
    </div>
  );
};

export default KlaimTooltip;
