import React from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import KlaimInput from 'modules/Common/components/KlaimInput';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Invite/CreateAccount/CreateAccount';
import {useAppSelector} from 'modules/App/store';
import KlaimErrorMessage from 'modules/Common/components/KlaimErrorMessage';
import {inviteSelect} from '../inviteSlice';

interface ComponentProps {
  next?: any;
  prev?: any;
  data?: any;
}

const CreateAccount: React.FC<ComponentProps> = ({next, data, prev}) => {
  const classes = useStylesFromThemeFunction();

  const {user, error} = useAppSelector(inviteSelect);

  const handleSubmit = (
    values: any,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    next(values);
  };
  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const goToTerms = () => {
    window.open('https://register.klaim.ai/terms-of-service/');
  };

  const goToPrivacy = () => {
    window.open('https://register.klaim.ai/privacy-policy/');
  };
  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={data} validationSchema={validationSchema} enableReinitialize>
        {({values}) => (
          <Form>
            <div className={classes.headerContainer}>
              <h1>Create Account</h1>
              <p>
                You have been invited by <span className={classes.orgNameText}>{user?.orgnanizationName}</span>. Create
                your Klaim account to continue.
              </p>
            </div>

            {error && (
              <KlaimErrorMessage title="Error" message={error.stack.error.message} messages={error.stack.errors} />
            )}
            <KlaimInput isReadOnly name="email" label="Email" placeholder="your@email.com" />
            <KlaimInput
              name="password"
              label="Password"
              type="password"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              showTooltip
            />
            <div className={classes.questionContainer}>
              <p>
                Already have an account? <KlaimSpan>Sign in</KlaimSpan>
              </p>
              <p>
                By clicking Create account, I agree that I have read and accepted the{' '}
                <KlaimSpan onClick={() => goToTerms()}> Terms of Use </KlaimSpan>
                and <KlaimSpan onClick={() => goToPrivacy()}>Privacy Policy.</KlaimSpan>
              </p>
            </div>
            <div className={classes.footerContainer}>
              <Button title="Create account" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateAccount;
