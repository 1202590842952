export const data = {
    gross_amount: {label: 'GROSS AMOUNT', value: '---'},
    // key0: {label: 'DEDUCTIBLE AMOUNT', value: '---'},
    total_discount_amount: {label: 'DISCOUNT AMOUNT', value: '---'},
    total_net_amount: {label: 'NET AMOUNT', value: '---'},
    total_vat_amount: {label: 'VAT AMOUNT', value: '---'},
    // key1: {label: 'TOTAL NET WITH VAT', value: '---'},
    total_claim: {label: 'TOTAL CLAIMS', value: '---'},
    // key2: {label: 'READY TO GO', value: '---'},
    // key3: {label: 'PENDING', value: '---'},
    // key4: {label: 'ACCEPTED', value: '---'},
    // key5: {label: 'WITHDRAWN', value: '---'},
    // key6: {label: 'INVALID', value: '---'},
    // key7: {label: 'REJECTED', value: '---'},
    // key8: {label: 'RECEIVED', value: '---'},
    total_copayment_amount: {label: 'COPAYMENT AMOUNT', value: '---'},
  };
  
  export const visitTypeData = {
    ip: 'In patient',
    op: 'Out patient',
  };
  
  export const getClaimType = (visitType, speciality) => {
    if (visitType?.mirthCode === 'op' && speciality?.toLowerCase() === 'dental') return 'oral';
    if (visitType?.mirthCode === 'op' && speciality?.toLowerCase() === 'ophthalmology') return 'vision';
    if (visitType?.mirthCode === 'ip') return 'institutional';
    if (visitType?.mirthCode === 'op') return 'professional';
  };
  
  export const providerId = 10000000131597;
  export const claimsData = {
    items: [
      {
        id: 1,
        tenantId: '10000000131597',
        visitDate: '2022-08-01T00:00:00.000Z',
        patientNo: '1',
        patientName: 'xxx',
        memberId: '1233455',
        preAuthNo: 'Value',
        doctorCode: '1',
        doctorLicense: '1',
        doctorName: 'ALI',
        clinicCode: '1',
        clinicName: 'ENT',
        payerCode: 'Bupa',
        policyNumber: '1234567',
        class: 'Value',
        return: 'O',
        visitType: 'ip',
        hasEmr: null,
        isReferral: null,
        totalGrossAmount: null,
        totalCopaymentAmount: null,
        totalDiscountAmount: null,
        totalNetAmount: 82657,
        totalVatAmount: null,
        batchDate: '2022-08-01T00:00:00.000Z',
        batchStartDate: '2022-08-01T00:00:00.000Z',
        batchEndDate: '2022-08-31T00:00:00.000Z',
        batchNo: '202008',
        eligibilityRefNo: 'Value',
        patientId: '1111111111',
        patientDob: '1990-12-14',
        patientAge: null,
        patientNationality: 'Value',
        admissionNo: '117539390000',
        admissionDate: '2022-08-08T00:00:00.000Z',
        admissionSpeciality: '2',
        admissionType: null,
        roomNumber: null,
        bedNumber: null,
        dischargeDate: '2022-08-08T00:00:00.000Z',
        dischargeSpeciality: null,
        lengthOfStay: 1,
        dischargeMode: null,
        created_at: '2022-09-27T07:52:30.820Z',
        updated_at: '2022-10-09T13:46:32.542Z',
        deleted_at: null,
        patientGender: {
          id: 2,
          label: 'Female',
          hisCode: 'F',
          mirthCode: 'female',
          tenantId: '10000000131597',
          created_at: '2022-10-08T15:12:13.836Z',
          updated_at: '2022-10-08T15:12:13.836Z',
          deleted_at: null,
        },
        patientMaritalStatus: {
          id: 1,
          label: 'married',
          hisCode: 'Married',
          mirthCode: 'M',
          tenantId: '10000000131597',
          created_at: '2022-10-08T14:24:55.407Z',
          updated_at: '2022-10-08T14:24:55.407Z',
          deleted_at: null,
        },
      },
    ],
    meta: {
      totalItems: 1,
      itemCount: 1,
      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1,
    },
  };