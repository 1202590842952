import React from 'react';
import {useTranslation} from 'react-i18next';
import {getIn} from 'formik';
import RemoveIcon from '../../../../assets/component/RemoveIcon';
import SelectOptions from '../../../Common/components/Select';
import {Observation, OBSERVATION_TYPE} from '../../api/types';
import FileUpload from '../../../Common/components/FileUpload';
import Input from '../../../Common/components/Input';
import Button from '../../../Common/components/Button';

interface ComponentProps {
  handleChange: any;
  observation: any;
  activityIndex: any;
  observationIndex: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  observationHelper: any;
  activities: any;
  setFieldValue: any;
  values: any;
  classes: any;
}

const ActivityObservation: React.FC<ComponentProps> = ({
  handleChange,
  observation,
  activityIndex,
  observationIndex,
  errors,
  touched,
  setFieldTouched,
  observationHelper,
  activities,
  setFieldValue,
  values,
  classes,
}) => {
  const {t} = useTranslation();

  const [showObservation, setShowObservation] = React.useState(false);
  const [deleteObservation, setDeleteObservation] = React.useState(false);

  const getLastObservationId = (obs: Array<Observation>) => {
    return obs[obs.length - 1]?.observationId ?? '0';
  };

  const handleInputChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const handleInputValueChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event.target.value !== undefined ? event.target.value : '';
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  return (
    <>
      {showObservation && (
        <div className={classes.formSectionElementsWrapper} key={observation.observationId}>
          <h2 className={`${classes.dFlex} ${classes.justifyContentBetween}`}>
            <div>
              <span className={classes.activityObservation}>{`Activity #${activityIndex + 1}`}</span> -
              {` Observation #${observationIndex + 1}`}
            </div>
            {values.activities[activityIndex].observations.length !== 1 && (
              <>
                <div className={classes.separatorBorderObservation} />
                <div
                  role="button"
                  className={classes.removeActivityBtn}
                  onClick={() => {
                    setDeleteObservation(true);
                    setShowObservation(true);
                    observationHelper.remove(
                      values.activities[activityIndex].observations.indexOf(
                        values.activities[activityIndex].observations.find((a: any) => {
                          return a.observationId === observation.observationId;
                        }),
                      ),
                    );
                  }}
                >
                  <RemoveIcon />
                </div>
              </>
            )}
          </h2>
          <div className={`${classes.rowInputs} ${classes.rowInputSelect}`}>
            <SelectOptions
              name={`activities[${activityIndex}].observations[${observationIndex}].type`}
              options={OBSERVATION_TYPE}
              label={t('Type')}
              value={values.activities[activityIndex].observations[observationIndex].type}
              onChange={(e) =>
                handleInputChangeSecond(e, values.activities[activityIndex].observations[observationIndex], 'type')
              }
              error={
                errors.activities &&
                errors.activities[activityIndex] &&
                errors.activities[activityIndex].observations &&
                errors.activities[activityIndex].observations[observationIndex] &&
                getIn(touched, `activities[${activityIndex}].observations[${observationIndex}].type`)
                  ? errors.activities[activityIndex].observations[observationIndex].type
                  : null
              }
              selectClassName={classes.rowInput}
              isClearable={false}
            />
            {observation.type === 'File' ? (
              <FileUpload
                label={t('Attach Files')}
                className={`${classes.fileUploadBox} ${classes.rowInputRight}`}
                acceptedFileTypes="application/pdf"
                setFieldValue={setFieldValue}
                fieldValue={`activities[${activityIndex}].observations[${observationIndex}].fileBase64`}
                type="row"
              />
            ) : (
              <Input
                name={`activities[${activityIndex}].observations[${observationIndex}].value`}
                value={observation.value}
                onChange={(e) =>
                  handleInputValueChangeSecond(
                    e,
                    values.activities[activityIndex].observations[observationIndex],
                    'value',
                  )
                }
                label="Value"
                placeholder={t('Enter Value')}
                className={classes.rowInputRight}
              />
            )}
          </div>
          {observation.type !== 'File' && (
            <div className={classes.rowInputs}>
              <Input
                name={`activities[${activityIndex}].observations[${observationIndex}].code`}
                value={observation.code}
                onChange={(e) =>
                  handleInputValueChangeSecond(
                    e,
                    values.activities[activityIndex].observations[observationIndex],
                    'code',
                  )
                }
                label="Code"
                placeholder={t('Enter Code')}
                className={classes.rowInput}
                error={
                  errors.activities &&
                  errors.activities[activityIndex] &&
                  errors.activities[activityIndex].observations &&
                  errors.activities[activityIndex].observations[observationIndex] &&
                  getIn(touched, `activities[${activityIndex}].observations[${observationIndex}].code`)
                    ? errors.activities[activityIndex].observations[observationIndex].code
                    : null
                }
              />
              <Input
                name={`activities[${activityIndex}].observations[${observationIndex}].valueType`}
                value={observation.valueType}
                onChange={(e) =>
                  handleInputChangeSecond(
                    e,
                    values.activities[activityIndex].observations[observationIndex],
                    'valueType',
                  )
                }
                label="Value Type"
                placeholder={t('Enter Value Type')}
                className={classes.rowInputRight}
              />
            </div>
          )}
        </div>
      )}
      {observationIndex + 1 === activities[activityIndex].observations.length && (
        <div className={classes.formSectionElementsWrapper} key={`addObservation-${observationIndex}`}>
          <div className={`${classes.dFlex} ${classes.justifyContentBetween}`}>
            <h2>
              <span className={classes.activityObservation}>{` Observation #${
                observationIndex + (deleteObservation ? 2 : 1)
              }`}</span>
            </h2>
            <div className={classes.separatorBorder} />
            <Button
              title="+"
              type="button"
              buttonClassName={classes.addActivityBtn}
              onClick={() => {
                setShowObservation(true);
                setDeleteObservation(false);
                observationHelper.push({
                  type: '',
                  value: '',
                  code: '',
                  valueType: '',
                  observationId: (+getLastObservationId(values.activities[activityIndex].observations) + 1).toString(),
                });
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ActivityObservation;
