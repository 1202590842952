import React from 'react';
import {createUseStyles} from 'react-jss';
import {Link} from 'react-router-dom';
import {Colors} from '../../constants/Colors';

interface ComponentProps {
  path: string;
  text?: string;
  containerStyle?: string;
  textStyle?: string;
}

export const useStylesFromThemeFunction = createUseStyles({
  linkContainer: {
    paddingTop: 4,
    paddingBottom: 4,
    '& a': {
      textDecoration: 'none',
    },
  },
  linkText: {
    color: Colors.purple,
    fontWeight: 500,
    fontSize: 14,
  },
});

const KlaimLink: React.FC<ComponentProps> = ({path, text, children, containerStyle, textStyle}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={containerStyle === undefined ? classes.linkContainer : containerStyle}>
      <Link to={path}>
        {text && <span className={textStyle === undefined ? classes.linkText : textStyle}>{text}</span>}
        {children}
      </Link>
    </div>
  );
};

export default KlaimLink;
