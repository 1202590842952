import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {get} from 'lodash';

import {
  KlaimButton,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
  KlaimToggle,
} from '../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from '../styles';
import {insurancesManagementSelect, errorCleanUp, patchInsurance, postInsurance, getInsurance} from '../InsuranceSlice';
import * as Toast from '../../../../Common/utils/toast';
import {useAppSelector} from '../../../../App/store';
import {Card, Header, Row, Wrapper} from '../styled';
import {
  branchManagementSelect,
  getBranches,
  getUserCurrentBranches,
} from '../../BranchManagement/branchManagementSlice';
import {Branch} from '../../../../../interfaces/branches.interfaces';
import {IOptions} from '../../../../../interfaces/general.interfaces';
import {getInsurances, insuranceSelect} from '../../../../Insurance/insuranceSlice';
import {Payer} from '../../../../../interfaces/payers.interfaces';

const Component: React.FC = () => {
  const s = useStylesFromThemeFunction();
  const history = useHistory();
  const [insurance, setInsurance] = React.useState<any>({});
  const [selectedBranch, setSelectedBranch] = React.useState<Branch>();
  const [branchOptions, setBranchOptions] = React.useState<IOptions[]>([]);
  const [hasTpa, setHasTpa] = React.useState(false);
  const [hcp, setHcp] = React.useState(null);

  const {error, apiCallSuccess, insurance: ins} = useAppSelector(insurancesManagementSelect);
  const {id}: {id: string} = useParams();

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (values.tpas?.length > 1) { // not supported
      Toast.error('Tpas can not have more than 1 element');
      return;
    }
    const insr = payers.data.find((el) => el.payerCode === hcp);
    dispatch(
      patchInsurance({
        ...values,
        id,
        hasTpa,
        name: insr?.shortName || insr?.name?.split(' ')[0],
        longName: insr?.name,
        tpas: hasTpa
          ? values.tpas.map((el) => {
              const tpa = payers.data.find((payer) => payer.payerCode === el) as any;
              return {
                hcpCode: tpa?.payerCode,
                name: tpa?.shortName || tpa?.name?.split(' ')[0],
                longName: tpa?.name,
                memberSystem: values.memberSystem,
              };
            })
          : [],
      }),
    );
  };
  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };

  useEffect(() => {
    if (branches.length > 0 && id)
      dispatch(
        getInsurance({
          id,
          providerId: getProviderIds(),
        }),
      );
  }, [branches, id]);

  useEffect(() => {
    setInsurance(ins as any);
    setHasTpa(get(ins, 'hasTpa'));
    setHcp(get(ins, 'hcpCode'))
  }, [ins]);

  useEffect(() => {
    if (error) {
      Toast.error('There was an error while treating your request');
      dispatch(errorCleanUp());
    }
  }, [error]);

  useEffect(() => {
    if (apiCallSuccess) {
      Toast.success('Operation succeeded');
      setTimeout(() => {
        dispatch(errorCleanUp());
        history.push('/settings/insurances-management');
      }, 1000);
    }
  }, [apiCallSuccess]);
  const {userBranches} = useAppSelector(branchManagementSelect);

  React.useEffect(() => {
    dispatch(getUserCurrentBranches({}));
    dispatch(getInsurances({authorityCode: 'NPHIES', limit: 300}));
  }, []);

  React.useEffect(() => {
    if (userBranches) {
      setBranchOptions(
        userBranches.map((branch) => {
          return {
            label: branch.name,
            value: branch.identifier,
          };
        }),
      );
    }
  }, [userBranches]);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);
  const {payers} = useAppSelector(insuranceSelect);

  React.useEffect(() => {
    if (payers && payers.data.length > 0) {
      setInsuranceOptions(
        payers.data.map((payer: Payer) => {
          return {
            label: payer.name,
            value: payer.payerCode,
          };
        }),
      );
    }
  }, [payers]);
  return (
    <Wrapper>
      <Card>
        <Header>
          <h1 className={s.header} style={{marginBottom: 0}}>
            Insurance Management
          </h1>
          <div />
        </Header>
      </Card>
      <br />
      <Card style={{padding: 12, paddingLeft: 32, minHeight: '85vh'}}>
        <KlaimForm
          enableReinitialize
          initialValues={{
            providerId: insurance?.tenantId,
            hcpCode: insurance?.hcpCode,
            hasTpa: insurance?.hasTpa,
            tpas: [insurance?.tpas?.[0]?.hcpCode],
            memberSystem: insurance?.memberSystem,
          }}
          validationSchema={Yup.object({
            providerId: Yup.string().required(),
            hcpCode: Yup.string().required(),
            hasTpa: Yup.boolean().required(),
            tpas: Yup.string(),
            memberSystem: Yup.string().required(),
          })}
          onSubmit={handleSubmit}
          style={{maxWidth: 500, gap: 16, display: 'flex', flexDirection: 'column'}}
          id="list-claim-form"
        >
          <b>Manual Entry</b> Add the HIC/TPA and the Member System URL
          {userBranches.length > 0 && (
            <KlaimFieldWrapper
              name={'providerId'}
              onChange={(value: any) => {
                setSelectedBranch(userBranches.find((branch) => value === branch.identifier));
              }}
            >
              <KlaimDropdownSearch
                options={branchOptions}
                placeholder={'Select Branch'}
                name="patient"
                label="Choose Branch"
                variant={'secondary'}
              />
            </KlaimFieldWrapper>
          )}
          <KlaimFieldWrapper name="hcpCode" onChange={setHcp}>
            <KlaimDropdownSearch variant="secondary" label="HIC Code" options={insuranceOptions} />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="memberSystem">
            <KlaimInput variant="secondary" placeholder="Member System URL label" label="Member System URL" />
          </KlaimFieldWrapper>
          <Row style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
            <KlaimDropdownSearch
              label="Has TPA"
              value={hasTpa}
              options={[
                {label: 'YES', value: true},
                {label: 'NO', value: false},
              ]}
              name="hasTpa"
              style={{width: '49%', marginTop: 0, paddingTop: 0}}
              onChange={(el) => setHasTpa(el)}
            />
            {hasTpa && (
              <KlaimFieldWrapper name="tpas" style={{flexGrow: 1}}>
                <KlaimDropdownSearch variant="secondary" label="TPAs" options={insuranceOptions} isMulti />
              </KlaimFieldWrapper>
            )}
          </Row>
          <div>
            <KlaimButton variant="primary" type={'submit'}>
              Save Insurance
            </KlaimButton>
          </div>
        </KlaimForm>
      </Card>
    </Wrapper>
  );
};

export default Component;
