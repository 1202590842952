import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {PaymentDetailsID, PaymentDetailsError, DefaultAttributes} from './types';

export const getPaymentDetailsByID = createAsyncThunk<
  DefaultAttributes,
  PaymentDetailsID,
  {rejectValue: PaymentDetailsError}
>('paymentDetails/getPaymentDetails', async (data: PaymentDetailsID, {rejectWithValue}) => {
  try {
    const response = await axios.get(`/payments/${data.paymentId}?providerId=${data.providerId}`);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const sendPaymentNotice = (query: any, id: string) => {
  return axios.post(`/payments/notice/${id}`, query);
};

const initialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
};

const paymentDetailsSlice = createSlice({
  name: 'paymentsDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentDetailsByID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentDetailsByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getPaymentDetailsByID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const paymentDetailsSelect = (state: RootState) => state.payments.paymentsDetails;
export default paymentDetailsSlice.reducer;
