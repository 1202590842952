import React, {FC, useEffect, useRef, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import Table from 'modules/Common/components/Table';
import editIcon from 'assets/edit_icon.svg';
import dayjs from 'dayjs';
import queryString from 'query-string';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {updateDraftList} from 'modules/App/dispatchers/claimDraft';
import {ClaimDraft, ClaimDraftData} from 'modules/Claims/api/types';
import useApiRequest from 'modules/Common/hooks/useApiRequest';
import {selectClaimDraft} from 'modules/App/selectors';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {useAppSelector} from '../../../App/store';
import {headerSelect} from '../../../Header/headerSlice';
import {NoData} from '../../../Common/components/NoData';
import ListItem from '../../features/ksa/ClaimsList/ClaimListItem';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    dFlex: {
      display: 'flex',
    },
    editClaimBtn: {
      '& p': {
        color: Colors.purple,
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    paginationContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
    },
    claimDraftListContainer: {
      background: Colors.white,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
    },
    tableWrapper: {
      flexGrow: 1,
    },
    tableRow: {},
    totalAmountText: {
      marginBottom: 0,
      marginTop: 0,
    },
    viewClaimBtn: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    viewClaim: {
      fontWeight: theme.font.weightSemibold,
      marginLeft: 10,
    },
    statusColumn: {
      alignItems: 'center',
      display: 'flex',
    },
    statusDotDispensed: {
      marginRight: '5px',
      width: 12,
      height: 12,
      background: Colors.lightGreen,
      borderRadius: '100%',
    },
    w100: {
      width: '100%',
    },
  };
});

interface ComponentProps {
  theme?: KlaimTheme;
}

const DATE_FORMAT = 'DD MMM YYYY HH:MM';

const ClaimsDraftList: FC<ComponentProps> = ({theme}) => {
  const classes = useStylesFromThemeFunction({theme});
  const tableDivRef = useRef<HTMLDivElement>(null);
  const claimDraftList: ClaimDraft[] = useSelector((state: any) => selectClaimDraft(state));
  const [loadClaimDraftList, claimDraftListLoading] = useApiRequest<ClaimDraftData>(updateDraftList);
  const [totalClaimCount, setTotalClaimCount] = useState(0);
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const {startDate, endDate, searchInput, selectedBranches} = useAppSelector(headerSelect);

  const getClaimDraftsList = async (searchKeyword: string, dateTo?: string | null, dateFrom?: string | null) => {
    const queryParams = {
      limit,
      offset: (offset - 1) * limit,
      search: searchKeyword || '',
      from: dateFrom,
      to: dateTo,
      providerId: selectedBranches.map((b) => b.value).join(','),
    };
    const requestUrl = queryString.stringifyUrl({
      query: queryParams,
      url: '/claims?isDraftClaim=true',
    });
    const {data} = await loadClaimDraftList(requestUrl);
    setTotalClaimCount(data.count || 0);
  };

  useEffect(() => {
    (async () => {
      await getClaimDraftsList(searchInput, startDate, endDate);
    })();
  }, [limit, offset, searchInput, startDate, endDate, selectedBranches]);

  const handleDraftView = (claimId: string, providerId: string) => {
    history.push(`/claims/form?claimId=${claimId}&claimType=draft&providerId=${providerId}`);
  };

  return (
    <div className={classes.claimDraftListContainer}>
      <div ref={tableDivRef} className={classes.tableWrapper}>
        <div className={classes.paginationContainer}>
          {selectedBranches.length ? (
            <KlaimPaginationContainer
              pageSize={limit}
              totalCount={totalClaimCount}
              currentPage={offset}
              onPageChange={setOffset}
              label="drafts"
              className={classes.w100}
            >
              <Table
                loading={claimDraftListLoading}
                tableHeadings={[
                  <p className={classes.tableHeading}>Patient info</p>,
                  <p className={classes.tableHeading}>Auth ID</p>,
                  <p className={classes.tableHeading}>Payer/TPA ID</p>,
                  <p className={classes.tableHeading}># Activity</p>,
                  <p className={classes.tableHeading}>Status</p>,
                  <p className={classes.tableHeading}>Service Date</p>,
                  <p className={classes.tableHeading}>Submission Date</p>,
                  <p className={classes.tableHeading}>Action</p>,
                ]}
                renderBody={() => (
                  <>
                    {claimDraftList && claimDraftList.length > 0
                      ? claimDraftList.map((item: any, index: number) => (
                          <ListItem
                            key={item.id}
                            data={item}
                            handleView={handleDraftView}
                            handleResubmit={() => {}}
                            handleViewEligibilityDetails={() => {}}
                            openModal={() => {}}
                            setItem={() => {}}
                            setModalContent={() => {}}
                          />
                        ))
                      : null}
                  </>
                )}
              />
            </KlaimPaginationContainer>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimsDraftList;
