import React, {FC, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {headerSelect} from 'modules/Header/headerSlice';
import {Modal, Pagination, PaginationProps, Table, Typography} from 'custom-test-antd';
import {events, Mixpanel} from 'services/mixpanel';
import dayjs from 'dayjs';
import timeLogo from 'assets/time.svg';
import UserLogo from 'assets/user_small.svg';
import moreIcon from 'assets/more_icon_2.svg';
import {ColumnsType} from 'antd/lib/table';
import {get} from 'lodash';
import queryString from 'query-string';
import {useAppDispatch, useAppSelector} from '../../../../App/store';
import {claimStatues, data, visitTypeData} from './data';
import {useStylesFromThemeFunction} from './ClaimsList';
import {
  getClaimsHistory as getClaims,
  getClaimsHistoryTotal,
  claimSelect,
  communicationRequestCleanup,
  errorClaimCleanup,
  getHistory,
} from '../../../claimsSlice';
import * as Toast from '../../../../Common/utils/toast';
import KlaimDropdownContainer from '../../../../Common/components/KlaimDropdownContainer';
import ErrorsModal from '../ClaimDetailsV3/ErrorsModal';
import CommRequestModal from './CommRequestModal';
import CommCancelModal from './CommCancelModal';
import Button from '../../../../Common/components/Button';
import axiosInstance from '../../../../../services/axios';
import BulkUploadModal from '../../ksa/ClaimsList/modals/BulkUploadModal';
import {createPreAuthCleanup, errorPreAuthCleanup, preAuthV2Select} from '../../../../PreAuthV2/PreAuthV2Slice';

const DATE_FORMAT = `DD MMM 'YY`;

const formatBranchDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};
const Component: FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {search} = queryString.parse(location.search);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [openErrors, setOpenErrors] = useState(false);
  const [openCommRequest, setOpenCommRequest] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [errors, setErrors] = useState([]);
  const [comm, setComm] = useState<any>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [generatingReport, setGeneratingReport] = useState(false);

  const {selectedBranches} = useAppSelector(headerSelect);
  const {
    histories: {data: dataList, count: dataCount},
    totals,
    error,
    isCancellationSuccess,
    history: claim,
  } = useAppSelector(claimSelect);
  const {
    startDate,
    endDate,
    visitStartDate,
    visitEndDate,
    selectedTPA,
    selectedReceiver,
    selectedPayer,
    selectedStatus,
    selectedType,
    selectedVisits,
    searchEpisodeQuery,
  } = useAppSelector(headerSelect);

  // useEffect(() => {
  //   const clm = claim as any;
  //   if (clm) dispatch(createPreAuth({providerId: clm.tenantId, ...clm.submissions[0]}));
  // }, [claim]);

  const {preAuth, error: perror} = useAppSelector(preAuthV2Select);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const newDataSource = dataList.map((dataEl) => ({
      ...dataEl,
      key: dataEl.id,
    }));
    setDataSource(newDataSource);
  }, [dataList]);

  useEffect(() => {
    if (preAuth)
      setTimeout(() => {
        // history.push(
        //   `/pre-auth/details/${preAuth.id}?providerId=${selectedBranches.map((provider) => provider.value).join(',')}`,
        // );
        dispatch(createPreAuthCleanup());
        dispatch(errorPreAuthCleanup());
      }, 1000);
    else {
      dispatch(createPreAuthCleanup());
      dispatch(errorPreAuthCleanup());
    }
  }, [preAuth]);

  useEffect(() => {
    if (perror) {
      Toast.error('error while creating the PA');
      dispatch(createPreAuthCleanup());
      dispatch(errorPreAuthCleanup());
    }
  }, [perror]);

  const getAdditionalRequestParams = () => {
    const prId =
      selectedBranches.length > 0 ? {providerId: selectedBranches.map((provider) => provider.value).join(',')} : {};
    const pId = selectedTPA.length > 0 ? {payerId: selectedTPA.map((el: any) => el.value).join(',')} : {};
    const rId = selectedReceiver.length > 0 ? {receiverId: selectedReceiver.map((el: any) => el.value).join(',')} : {};
    const payId = selectedPayer.length > 0 ? {payerId: selectedPayer.map((el: any) => el.value).join(',')} : {};
    const vts = selectedVisits.length > 0 ? {claimSubType: selectedVisits.map((el: any) => el.value).join(',')} : {};
    const tps = selectedType.length > 0 ? {claimType: selectedType.map((el: any) => el.value).join(',')} : {};
    const sts = selectedStatus.length > 0 ? {status: selectedStatus.map((el: any) => el.value).join(',')} : {};
    const ep = searchEpisodeQuery && searchEpisodeQuery?.length > 0 ? {episode: searchEpisodeQuery} : {};

    return {
      ...pId,
      ...prId,
      ...vts,
      ...tps,
      ...sts,
      ...ep,
      ...payId,
      ...rId,
    };
  };

  const refresh = () => {
    setTimeout(() => {
      dispatch(
        getClaims({
          limit,
          offset: (offset - 1) * limit,
          startDate: formatBranchDate(startDate),
          endDate: formatBranchDate(endDate),
          ...getAdditionalRequestParams(),
          search: search as string,
        }),
      );
    }, 1000);
    dispatch(errorClaimCleanup());
  };

  const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

  useEffect(() => {
    if (error) {
      // Toast.error('There was an error while treating your request');
      dispatch(errorClaimCleanup());
    }
  }, [error]);

  useEffect(() => {
    if (isCancellationSuccess) {
      setSelectedIds([]);
      Toast.success('Operation succeeded');
      refresh();
      setTimeout(() => dispatch(communicationRequestCleanup()), 500);
    }
  }, [isCancellationSuccess]);

  useEffect(() => {
    dispatch(
      getClaimsHistoryTotal({
        limit,
        offset: (offset - 1) * limit,
        // startDate: formatBranchDate(startDate),
        // endDate: formatBranchDate(endDate),
        billablePeriodStart: formatBranchDate(visitStartDate),
        billablePeriodEnd: formatBranchDate(visitEndDate),
        ...getAdditionalRequestParams(),
        search: search as string,
      }),
    );
    dispatch(
      getClaims({
        limit,
        offset: (offset - 1) * limit,
        // startDate: formatBranchDate(startDate),
        // endDate: formatBranchDate(endDate),
        billablePeriodStart: formatBranchDate(visitStartDate),
        billablePeriodEnd: formatBranchDate(visitEndDate),
        ...getAdditionalRequestParams(),
        search: search as string,
      }),
    );
  }, [
    limit,
    offset,
    startDate,
    selectedStatus,
    endDate,
    visitStartDate,
    visitEndDate,
    selectedType,
    selectedBranches,
    selectedTPA,
    selectedPayer,
    selectedReceiver,
    search,
    selectedVisits,
    searchEpisodeQuery,
  ]);

  useEffect(() => {
    Mixpanel.track(events.pageViews.midTableListView);
  }, []);

  const labelValue = (label: string, value: string) => {
    return (
      <div className={classes.labelValue}>
        <div className={classes.value}>{label}</div>
        <div className={classes.label}>{value}</div>
      </div>
    );
  };

  const getHeader = () => {
    const arr = Object.keys(data).map((key) => ({label: data[key].label, value: totals[key] || data[key].value}));
    arr.push({
      label: 'TOTAL NET WITH VAT',
      value: `${Number(parseFloat(totals.total_net_amount || 0) + parseFloat(totals.total_vat_amount || 0)).toFixed(
        2,
      )}`,
    });
    return arr;
  };

  const paginationProps: PaginationProps = {
    current: offset,
    onChange: (page) => setOffset(page),
    total: dataCount,
    showSizeChanger: true,
    onShowSizeChange: (current, size) => setLimit(size),
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getControls = (item) => (
    <KlaimDropdownContainer isIconButton icon={moreIcon} position={'center'}>
      <div
        role="button"
        onClick={
          () =>
            history.push(
              `claims/details/${item.id}?providerId=${selectedBranches.map((provider) => provider.value).join(',')}`,
            )
          // history.push(`/midtable/form/${item.id}/${item.tenantId}`)
        }
        className={`${classes.button} ${classes.navLink}`}
      >
        View Claim
      </div>
      <div
        role="button"
        onClick={
          () => history.push(`/claims/form?id=${item.id}&providerId=${item.tenantId}`)
          // history.push(`/midtable/form/${item.id}/${item.tenantId}`)
        }
        className={`${classes.button} ${classes.navLink}`}
      >
        Edit Claim
      </div>
      {item.status !== 'error' && item.status !== 'submitting' && (
        <div
          role="button"
          onClick={() => {
            setComm(item);
            setOpenCommRequest(true);
          }}
          className={`${classes.button} ${classes.navLink}`}
        >
          Communication
        </div>
      )}
      {item.status !== 'error' && item.status !== 'submitting' && (
        <div
          role="button"
          onClick={() => {
            setComm(item);
            setOpenCancel(true);
          }}
          className={`${classes.button} ${classes.navLink}`}
        >
          Cancel request
        </div>
      )}
      {item.status === 'rejected' && (
        <div
          role="button"
          onClick={() => {
            dispatch(
              getHistory({
                id: item.id,
                providerId: selectedBranches.map((provider) => provider.value).join(','),
              }),
            );
          }}
          className={`${classes.button} ${classes.navLink}`}
        >
          Generate PA
        </div>
      )}
    </KlaimDropdownContainer>
  );

  const columns: ColumnsType<any> = [
    {
      title: 'CLAIM DETAILS',
      width: 170,
      render: (_, item) => (
        <div className={classes.col}>
          <span style={{position: 'relative'}} className={classes.popupContainer}>
            {item.id && item.id.substring(0, 5)}...
            <div className={classes.popup}>
              <b>ID</b>
              <p>{item.id}</p>
            </div>
          </span>
          <span className={classes.date}>
            <img src={timeLogo} alt="timeLogo" />
            {formatDate(item.createdAt)}
          </span>
          <span className={classes.date}>
            <b>
              {get(item, 'submissions[0].claim.claimType')}
              {/* {claimType(
                              get(item, 'submissions[0].claim.claimSubtype'),
                              item.midtableSupportingData?.specialityName,
                            )} */}
            </b>
          </span>
        </div>
      ),
    },
    {
      title: 'PAYER / RECEIVER',
      width: 140,
      render: (_, item) => (
        <div className={classes.label}>
          {get(item, 'submissions[0].payerName')}
          <br />
          {get(item, 'submissions[0].receiverName')}
        </div>
      ),
    },
    {
      title: 'PATIENT',
      width: 160,
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <span>{get(item, 'submissions[0].patient.name')}</span>
            <span className={classes.date}>
              <img src={UserLogo} alt="timeLogo" />
              <span style={{position: 'relative'}} className={classes.popupContainer}>
                {get(item, 'submissions[0].patient.documentId') &&
                  get(item, 'submissions[0].patient.documentId').substring(0, 5)}
                ...
                <div className={classes.popup}>
                  <b>ID</b>
                  <p>{get(item, 'submissions[0].patient.documentId')}</p>
                </div>
              </span>
            </span>
            <span className={classes.date}>
              <b>{item.patientNo}</b>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'ADMISSION / DISCHARGE',
      width: 250,
      render: (_, item) => (
        <div className={classes.label}>
          {/* claimBillablePeriodEnd */}
          {/* claimBillablePeriodStart */}
          {`${
            get(item, 'submissions[0].claim.claimBillablePeriodStart')
              ? formatDate(get(item, 'submissions[0].claim.claimBillablePeriodStart'))
              : 'No Date'
          } - ${
            get(item, 'submissions[0].claim.claimBillablePeriodStart')
              ? formatDate(get(item, 'submissions[0].claim.claimBillablePeriodEnd'))
              : 'No Date'
          }`}
        </div>
      ),
    },
    {
      title: 'STATUS',
      width: 120,
      render: (_, item) => (
        <div className={classes.label}>
          <span
            onClick={() => {
              setErrors(get(item, 'responses[0].errors', null));
              if (get(item, 'responses[0].errors', []).length > 0) setOpenErrors(true);
            }}
            role={'button'}
            className={classes.statuse}
            style={{
              cursor: 'pointer',
              backgroundColor: claimStatues[item.status]?.bg,
              color: claimStatues[item.status]?.text,
            }}
          >
            {claimStatues[item.status]?.label}
          </span>
        </div>
      ),
    },
    {
      title: 'CLINIC',
      width: 200,
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <span className={classes.date}>{item.clinicName}</span>
            <span className={classes.date}>
              <img src={UserLogo} alt="timeLogo" />
              {item?.midtableSupportingData?.doctorName}
            </span>
            <span className={classes.date}>
              <b>{visitTypeData[item.visitType]}</b>
            </span>
            <span className={classes.date}>{get(item, 'submissions[0].senderName')}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'TOTAL AMOUNTS IN SAR',
      width: 400,
      render: (_, item) => (
        <div className={classes.label}>
          <div className={classes.col}>
            <div className={classes.row}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>Gross</div>
                <div className={classes.label}>{item.financialData?.totalGrossAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>NET</div>
                <div className={classes.label}>{item.financialData?.totalNetAmount}</div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>DISCOUNT</div>
                <div className={classes.label}>{item.financialData?.totalDiscountAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>VAT</div>
                <div className={classes.label}>{item.financialData?.totalVatAmount}</div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>COPAY</div>
                <div className={classes.label}>{item.financialData?.totalCopaymentAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value} />
                <div className={classes.label} />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'ACTION',
      render: (_, item) => <div className={classes.label}>{getControls(item)}</div>,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.header}>{getHeader().map((el) => labelValue(el.label, el.value))}</div>
      <Table
        whiteHeader
        firstColumnPadding={false}
        className={classes.tableContainer}
        columns={columns}
        dataSource={dataSource}
        // scroll={{x: 1490}}
        footer={() => (
          <div className={classes.footerContent}>
            <Typography.Title level={5} className={classes.totalAmountText}>
              TOTAL AMOUNT OF ENTRIES: {dataCount}
            </Typography.Title>
            <Pagination {...paginationProps} />
          </div>
        )}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            disabled: record.isSynchronized,
          }),
        }}
      />
      <ErrorsModal open={openErrors} setOpen={setOpenErrors} errors={errors} />
      <CommRequestModal
        open={openCommRequest}
        setOpen={setOpenCommRequest}
        comm={comm.communication}
        status={comm.status}
        id={comm.id}
        submission={get(comm, 'submissions[0]', {})}
        providerId={comm.tenantId}
        response={get(comm, 'responses[0]', {})}
      />
      <CommCancelModal
        open={openCancel}
        setOpen={setOpenCancel}
        comm={comm.communication}
        status={comm.status}
        id={comm.id}
        submission={get(comm, 'submissions[0]', {})}
        providerId={comm.tenantId}
      />
      <div className={classes.flexEndRow}>
        <Button
          buttonClassName={classes.button}
          onClick={async () => {
            setGeneratingReport(true);
            await axiosInstance.get('/reports/generate/claim', {
              params: {
                providerId: selectedBranches.map((provider) => provider.value).join(','),
                // startDate: formatBranchDate(startDate),
                // endDate: formatBranchDate(endDate),
                billablePeriodStart: formatBranchDate(visitStartDate),
                billablePeriodEnd: formatBranchDate(visitEndDate),
                ...getAdditionalRequestParams(),
                search: search as string,
              },
            });
            Modal.info({
              title: 'Generate Report',
              content:
                'We\'ve received your request for generating a report. The report will be available for download in the "Reporting" section',
              okText: 'Close',
              centered: true,
            });
            setGeneratingReport(false);
          }}
          title="Generate Report"
          loading={generatingReport}
        />
        <Button buttonClassName={classes.button} onClick={() => setOpen(true)} title="Bulk Upload" />
      </div>
      <BulkUploadModal
        open={open}
        setOpen={(v) => setOpen(v)}
        providerId={selectedBranches.map((provider) => provider.value).join(',')}
      />
    </div>
  );
};

export default Component;
