import React from 'react';
import {createUseStyles} from 'react-jss';
import hospitalIcon from 'assets/hospital_icon.svg';
import KlaimMultiSelect from 'modules/Common/components/KlaimMultiSelect';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {Branches, headerSelect, setSelectedBranches} from 'modules/Header/headerSlice';
import {
  branchManagementSelect,
  getBranches,
  getUserCurrentBranches,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    branchesWidth: {
      width: 550,
    },
  };
});

interface ComponentProps {
  showDescription: boolean;
  className?: string;
}

const BranchesFilter: React.FC<ComponentProps> = ({showDescription, className}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const [branchesValue, setBranchesValue] = React.useState<string>('');
  const {selectedBranches} = useAppSelector(headerSelect);
  const {userBranches} = useAppSelector(branchManagementSelect);
  const {isAuthenticated} = useAppSelector(authSelect);

  React.useEffect(() => {
    if (isAuthenticated) dispatch(getUserCurrentBranches({}));
  }, [isAuthenticated]);

  React.useEffect(() => {
    setBranchesValue(
      `${selectedBranches[0] !== undefined ? selectedBranches[0].value : 'Filter by Branches'}${
        selectedBranches && selectedBranches.length > 1 ? `, +${selectedBranches.length - 1} more` : ''
      }`,
    );
    return () => {
      setSelectedBranches([]);
    };
  }, [userBranches, selectedBranches]);

  const branchChange = (selBranches: Branches[]) => {
    dispatch(setSelectedBranches(selBranches));
  };

  return (
    <KlaimDropdownContainer
      leftIcon={hospitalIcon}
      value={branchesValue}
      showDescription={showDescription}
      className={className}
    >
      <KlaimMultiSelect
        className={classes.branchesWidth}
        onChange={branchChange}
        options={userBranches.map((branch: any) => {
          return {
            label: `[${branch.identifier}] ${branch.name}`,
            value: branch.identifier,
          };
        })}
        selectAllTitle="ALL BRANCHES"
        value={selectedBranches}
      />
    </KlaimDropdownContainer>
  );
};

export default BranchesFilter;
