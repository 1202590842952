import React, {useEffect} from 'react';
import AnimateHeight from 'react-animate-height';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {Colors} from 'modules/Common/constants/Colors';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import dayjs from 'dayjs';
import CloseIcon from 'assets/component/CloseIcon';
import EligibilityDropdownRow from './EligibilityDropdownRow';
import {ComponentProps, useStylesFromThemeFunction} from './EligibilityDropdown';
import {eligibilitySelect, getEligibilities} from '../../eligibilitySlice';

const EligibilityDropdown: React.FC<ComponentProps> = ({
  label,
  placeholder,
  containerClassName,
  errorClassName,
  optional = false,
  className,
  name = 'default',
  documentId,
  branch,
  onChange,
  initialValue,
  patientId,
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {eligibilities} = useAppSelector(eligibilitySelect);
  const isValidated = (error: boolean, touched: any) => {
    return error && touched ? classes.error : classes.validated;
  };

  const [limit] = React.useState<number>(10);
  const [offset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const node = React.useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [errorMessage] = React.useState('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedEligibility, setSelectedEligibility] = React.useState<any>();
  useEffect(() => {
    if (eligibilities && initialValue) {
      const eli = eligibilities.data.filter((el) => el.id === initialValue);
      if (eli.length > 0) setSelectedEligibility(eli[0]);
    }
  }, [initialValue, eligibilities]);

  const [height, setHeight] = React.useState(open ? 'auto' : '0%');
  React.useEffect(() => {
    setHeight(open ? 'auto' : '0%');
  }, [open]);

  React.useEffect(() => {
    dispatch(getEligibilities({limit, offset: (offset - 1) * limit, search: documentId, providerId: branch}));
  }, [documentId]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChange = (value: any) => {
    setSelectedEligibility(value);
  };

  React.useEffect(() => {
    if (onChange) onChange(selectedEligibility);
    setInputValue(
      selectedEligibility
        ? `${dayjs(selectedEligibility.date).format('MMMM DD YYYY - HH:MM')} - ${selectedEligibility.status}`
        : '',
    );
    setOpen(false);
  }, [selectedEligibility]);

  const handleClickOutside = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setOpen(false);
  };

  return (
    <div
      className={`${className} ${classes.contentContainer} ${containerClassName} 
            `}
    >
      {label && (
        <label className={classes.label} htmlFor={name}>
          {label}
          {optional && <span>(Optional)</span>}
        </label>
      )}
      <div
        ref={node}
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            borderWidth: 1,
            borderColor: Colors.grayLight,
            borderStyle: 'solid',
            borderRadius: 8,
            overflow: 'hidden',
            backgroundColor: '#F5F7FB',
            height: 42,
          }}
        >
          <input
            style={{fontSize: 15, height: 42, font: 'inherit'}}
            placeholder={placeholder || 'Select Eligibility'}
            onBlur={(e) => {
              if (node.current !== null) {
                if (node.current?.contains(e.target)) {
                  return;
                }
              }
              setOpen(false);
            }}
            onFocus={() => setOpen(true)}
            className={classes.input}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
              setSearchTyping(e.currentTarget.value);
            }}
          />
          <KlaimIconButton style={{marginRight: 4}} type="button" onClick={() => setSelectedEligibility(undefined)}>
            <CloseIcon />
          </KlaimIconButton>
          <div style={{borderRight: '1px solid', borderColor: Colors.grayLight, margin: 4}} />

          <KlaimIconButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div style={{width: 32, height: 20}}>
              <ChevronArrowDown />
            </div>
          </KlaimIconButton>
        </div>

        <div
          style={{
            boxShadow: '0px 5px 5px -1px rgb(96 97 112 / 16%)',
            borderRadius: 8,

            overflow: 'hidden',
            position: 'absolute',
            zIndex: 2,
            top: 50,
            left: 0,
            right: 0,
            backgroundColor: Colors.white,
          }}
        >
          <AnimateHeight duration={500} height={height}>
            <div style={{padding: 8}}>
              {eligibilities.data.length > 0 && (
                <div style={{marginBottom: 8}}>
                  <p style={{margin: 0, fontSize: 12, marginBottom: 8, color: Colors.grayLighter}}>Search Results</p>
                  {eligibilities.data
                    .filter((eligibility) => eligibility.status === 'inForce')
                    .map((eligibility, index) => (
                      <EligibilityDropdownRow eligibility={eligibility} key={index} onClick={handleChange} />
                    ))}
                </div>
              )}
            </div>
          </AnimateHeight>
        </div>
      </div>

      {errorMessage && (
        <div className={errorClassName !== undefined ? errorClassName : classes.error}>{errorMessage}</div>
      )}
    </div>
  );
};

export default EligibilityDropdown;
