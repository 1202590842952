import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {
  ErxDispenseDetailsById,
  ErxDispenseID,
  ErxDispenseError,
  ErxDispense,
  ErxDispenseResponseId,
  InitialState,
  DefaultAttributes,
} from './types';

export const getAuthorizeId = createAsyncThunk<ErxDispenseDetailsById, ErxDispenseID, {rejectValue: ErxDispenseError}>(
  'erxDispense/getAuthorizeId',
  async (data: ErxDispenseID, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/erx/${data.authorizeId}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const authorizeErxById = createAsyncThunk<DefaultAttributes, ErxDispenseID, {rejectValue: ErxDispenseError}>(
  'erxDispense/authorizeErxById',
  async (data: ErxDispenseID, {rejectWithValue}) => {
    try {
      const response = await axios.post(`/erx/${data.authorizeId}/refresh`);
      console.log('refresh', response);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const dispenseErxById = createAsyncThunk<ErxDispenseResponseId, ErxDispense, {rejectValue: ErxDispenseError}>(
  'erxDispense/dispenseErxById',
  async (data: ErxDispense, {rejectWithValue}) => {
    try {
      const response = await axios.post(`/erx/${data.authorizeId}/dispense`, data.body);
      console.log('dispense', response);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: InitialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
};

const erxDispenseSlice = createSlice({
  name: 'erxDispense',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthorizeId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthorizeId.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAuthorizeId.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(authorizeErxById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authorizeErxById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(authorizeErxById.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const erxDispenseSelect = (state: RootState) => state.pharmacy.erxDispense;
export default erxDispenseSlice.reducer;
