import React from 'react';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {useAppSelector} from 'modules/App/store';
import {RouteComponentProps} from 'react-router-dom';
import UAEClaimsList from 'modules/Claims/features/uae/ClaimsList';
import KSAClaimsList from 'modules/Claims/features/v3/ClaimsList';

const ClaimsListing: React.FC<RouteComponentProps> = (props) => {
  const {
    user: {organization},
  } = useAppSelector(authSelect);
  let component;
  switch (organization.country) {
    case 'KSA':
      component = <KSAClaimsList {...props} />;
      break;
    default:
      component = <UAEClaimsList {...props} />;
  }

  return component;
};

export default ClaimsListing;
