import React from 'react';
import {NavLink, withRouter, RouteComponentProps} from 'react-router-dom';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {createUseStyles} from 'react-jss';
import {Colors} from '../../constants/Colors';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    iconNavLink: {
      height: 26,
      marginRight: 30,
      width: 26,
    },
    iconNavLinkClose: {
      marginRight: 0,
    },
    navLink: {
      font: 'normal normal medium 20px/24px Poppins',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0,
      color: '#222B45',
      display: 'flex',
      textDecoration: 'none',
      flex: '1 1 0%',
      '&:hover': {
        color: Colors.purple,
      },
    },
    sideMenuItemActive: {
      color: Colors.purple,
    },
    sideMenuSubWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
      paddingLeft: '40px',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      marginBottom: 25,
      cursor: 'pointer',
      '&:hover': {
        background: Colors.blueGrayLight,
        borderRadius: 3,
      },
      '& a': {
        display: 'flex',
      },
    },
    containerClose: {
      alignItems: 'center',
      marginBottom: 25,
      cursor: 'pointer',
      padding: 5,
      '& a': {
        justifyContent: 'center',
      },
      '&:hover': {
        background: Colors.blueGrayLight,
        borderRadius: 3,
      },
    },
  };
});

interface ComponentProps extends RouteComponentProps {
  title: string;
  link: string;
  activeIcon?: string;
  icon?: string;
  theme?: KlaimTheme;
  sidebarIsOpen?: boolean;
}

const SideMenuLink: React.FC<ComponentProps> = ({
  title,
  link,
  activeIcon,
  icon,
  theme,
  children,
  location,
  sidebarIsOpen,
}) => {
  const classes = useStylesFromThemeFunction({theme});
  const [activePath, setActivePath] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (location.pathname) {
      if (location.pathname.split('/')[1].toLocaleLowerCase().match(link.split('/')[1].toLocaleLowerCase())) {
        setActivePath(true);
      } else {
        setActivePath(false);
      }
    }
  }, [location.pathname]);
  return (
    <div
      className={sidebarIsOpen ? classes.container : classes.containerClose}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <NavLink
        exact
        to={link}
        className={classes.navLink}
        activeClassName={classes.sideMenuItemActive}
        isActive={() => activePath}
      >
        {icon && (
          <img
            src={activePath || active ? activeIcon : icon}
            className={sidebarIsOpen ? classes.iconNavLink : classes.iconNavLinkClose}
            alt={title}
          />
        )}
        {sidebarIsOpen && <div style={{display: 'flex', alignItems: 'center'}}>{title}</div>}
      </NavLink>
      {children && <div className={classes.sideMenuSubWrapper}>{children}</div>}
    </div>
  );
};

export default withRouter(SideMenuLink);
