import React, {FC, useState} from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {get} from 'lodash';
import Section from './Section';
import vs from '../../../../../../assets/vital-signs.jpg';
import {KlaimButton, KlaimDropdownSearch, KlaimForm, KlaimInput} from '../../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from './ClaimForm';
// import {deleteDiagnosis, patchDiagnosis, postDiagnosis} from '../../../../midTableSlice';
import DiagnosisCodeDropdown from '../../../../../Diagnosis/features/DiagnosisCodeDropdown';
import KlaimDropdownContainer from '../../../../../Common/components/KlaimDropdownContainer';
import moreIcon from '../../../../../../assets/more_icon_2.svg';
import {checkErroredFields} from '..';

const ClaimDiagnosis: FC<any> = ({data, submission, setSubmission, errors}) => {
  const classes = useStylesFromThemeFunction();

  const [diagOnAdmission, setDiagnosisOnAdmission] = useState(null);
  const [diagType, setDiagnosisType] = useState(null);
  const [diagCode, setDiagnosisCode] = useState(null);
  const [diagnosisId, setDiagnosisId] = useState(null);
  const [index, setIndex] = useState(null);

  return (
    <Section
      title={'Claim Diagnosis'}
      img={vs}
      style={{border: checkErroredFields(errors, 'diagnosis') ? '1px solid red' : ''}}
    >
      <KlaimForm
        enableReinitialize
        initialValues={{}}
        validationSchema={Yup.object({
          diagOnAdmission: Yup.string().required('Required'),
        })}
        onSubmit={() => {
          console.log('submit');
        }}
        id="diagnoses-formss"
        customClass={classes.form}
      >
        <div className={classes.respRow}>
          <KlaimDropdownSearch
            style={{flex: '1 1 10%'}}
            variant="secondary"
            label={'ONA'}
            options={[
              {
                label: 'Yes',
                value: 'y',
              },
              {
                label: 'No',
                value: 'n',
              },
            ]}
            placeholder={'ONA'}
            value={diagOnAdmission}
            onChange={setDiagnosisOnAdmission}
            name="diagOnAdmission"
          />
          <KlaimDropdownSearch
            style={{flex: '1 1 20%'}}
            variant="secondary"
            label={'Type'}
            options={
              data?.some((el) => el.diagType === 'principal')
                ? [
                    {
                      label: 'Secondary',
                      value: 'secondary',
                    },
                  ]
                : [
                    {
                      label: 'Principal',
                      value: 'principal',
                    },
                    {
                      label: 'Secondary',
                      value: 'secondary',
                    },
                  ]
            }
            placeholder={'Select type'}
            value={diagType}
            onChange={setDiagnosisType}
          />
          <div style={{flex: '1 1 60%'}}>
            <DiagnosisCodeDropdown
              label="ICD10 Diagnosis"
              authorityCode="NPHIES"
              value={diagCode}
              onChange={(v) => {
                setDiagnosisCode(v.diagnosisCode);
              }}
            />
          </div>

          <div style={{flex: '1 1 10%', marginTop: 24}}>
            <KlaimButton
              onClick={() => {
                if (diagType && diagOnAdmission && diagCode) {
                  const obj = JSON.parse(JSON.stringify(submission));
                  if (index !== undefined && index !== null) {
                    setSubmission({
                      ...obj,
                      claim: {
                        ...obj.claim,
                        diagnosis: [
                          ...obj.claim.diagnosis.filter((el, i) => i !== index),
                          {diagType, diagOnAdmission, diagCode},
                        ],
                      },
                    });
                  } else if (!obj?.claim?.diagnosis) {
                    setSubmission({
                      ...obj,
                      claim: {
                        ...obj.claim,
                        diagnosis: [{diagType, diagOnAdmission, diagCode}],
                      },
                    });
                  } else {
                    setSubmission({
                      ...obj,
                      claim: {
                        ...obj.claim,
                        diagnosis: [...obj.claim.diagnosis, {diagType, diagOnAdmission, diagCode}],
                      },
                    });
                  }
                }
              }}
              variant="primary"
            >
              {index !== undefined && index !== null ? 'EDIT' : 'ADD'}
            </KlaimButton>
          </div>
        </div>
        <div
          className={classes.respTableRow}
          style={{
            backgroundColor: '#F5F7FB',
            width: 'calc(100% + 32px)',
            marginLeft: '-16px',
            padding: '9px 16px',
          }}
        >
          <div className={classes.value} style={{flex: '1 1 10%'}}>
            Type
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}}>
            ONA
          </div>
          <div className={classes.value} style={{flex: '1 1 80%'}}>
            ACTIVITY
          </div>
          <div className={classes.value} style={{flex: '1 1 20%'}} />
        </div>
        {data?.map((el: any, i: number) => (
          <div
            className={classes.respTableRow}
            style={{
              width: 'calc(100% + 32px)',
              marginLeft: '-16px',
              padding: '0px 16px',
              borderBottom: 'solid 1px #E7ECF5',
              marginTop: 0,
            }}
          >
            <div className={classes.label} style={{flex: '1 1 10%'}}>
              {el.diagType}
            </div>
            <div className={classes.label} style={{flex: '1 1 20%'}}>
              {el.diagOnAdmission}
            </div>
            <div className={classes.label} style={{flex: '1 1 80%'}}>
              {el.diagCode}
            </div>
            <div className={classes.label} style={{width: 50}}>
              <KlaimDropdownContainer isIconButton icon={moreIcon} position={'left'}>
                {/* <div
                  role="button"
                  onClick={() => {
                    setDiagnosisType(el.diagType);
                    setDiagnosisOnAdmission(el.diagOnAdmission);
                    setDiagnosisCode(el.diagCode);
                    setDiagnosisId(el.id);
                    setIndex(i);
                  }}
                  className={`${classes.button} ${classes.navLink}`}
                >
                  Edit
                </div> */}
                <div
                  role="button"
                  onClick={() => {
                    const obj = JSON.parse(JSON.stringify(submission));
                    setSubmission({
                      ...obj,
                      claim: {
                        ...obj.claim,
                        diagnosis: [...obj.claim.diagnosis.filter((ell, ii) => ii !== i)],
                      },
                    });
                  }}
                  className={`${classes.button} ${classes.navLink}`}
                >
                  Delete
                </div>
              </KlaimDropdownContainer>
            </div>
          </div>
        ))}
      </KlaimForm>
    </Section>
  );
};

export default ClaimDiagnosis;
