import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import searchIcon from 'assets/search_icon.svg';
import {useStylesFromThemeFunction, ComponentProps} from './KlaimSearch';

const KlaimSearch: React.FC<ComponentProps> = ({placeholder, setSearch}) => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const useQueryRoute = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQueryRoute();
  const search = query.get('search');
  const [searchTyping, setSearchTyping] = React.useState<string>(search || '');

  React.useEffect(() => {
    const delayFn = setTimeout(() => {
      if (searchTyping) {
        query.delete('search');
        query.append('search', searchTyping || '');
      } else {
        query.delete('search');
      }
      history.push({search: query.toString()});
    }, 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTyping(e.currentTarget.value);
  };
  return (
    <div className={classes.container}>
      <img className={classes.imageIcon} src={searchIcon} alt="search" />
      <input value={searchTyping} placeholder={placeholder} className={classes.input} onChange={(e) => onChange(e)} />
    </div>
  );
};

export default KlaimSearch;
