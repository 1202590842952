import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  onSubmit?: () => void;
  buttonType?: 'button' | 'submit';
  showCancelButton?: boolean;
  submitLoading?: boolean;
  hideSubmit?: boolean;
}

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    borderTop: `1px ${Colors.purpleLight} solid`,
    padding: '20px 30px',
  },
  defaultContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
  },
  cancelButton: {
    background: 'none',
    color: Colors.purple,
    border: `1px ${Colors.purple} solid`,
  },
});
