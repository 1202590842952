import React, {FC, useState} from 'react';
import {createUseStyles} from 'react-jss';
import AsyncSelect from 'react-select/async';
import {Colors} from 'modules/Common/constants/Colors';
import ClearIcon from 'assets/component/ClearIcon';
import {components} from 'react-select';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    klaimSelectLabel: {
      color: Colors.purpleDark,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 3,
      marginLeft: 10,
    },
    inputError: {
      color: 'red',
      fontSize: 12,
      marginBottom: 0,
      marginTop: 0,
      marginLeft: '27px',
    },
    w100: {
      width: '100%',
    },
  };
});

interface ComponentProps {
  name?: string;
  className?: string;
  onChange?: (e: any) => void;
  onValueChange?: (e: any) => void;
  placeholder?: string;
  loadOptions?: any;
  value?: any | undefined;
  label?: string;
  error?: string;
  defaultValue?: any;
  valueContainerOption?: (e: any) => void;
  isClearable?: boolean;
}

const AsyncSearchSelect: FC<ComponentProps> = ({
  className,
  name,
  onChange,
  placeholder,
  loadOptions,
  value,
  label,
  error,
  onValueChange,
  defaultValue,
  valueContainerOption,
  isClearable = true,
}) => {
  const classes = useStylesFromThemeFunction();
  const [defaultLabel, setDefaultLabel] = useState<{label: ''}>();

  const customStyles = {
    control: (base: any, state: {isFocused: any}) => ({
      ...base,
      background: Colors.blueGrayLight,
      borderRadius: 10,
      height: 40,
      '&:hover': {borderColor: `${Colors.grayLight}`},
      border: `1px solid ${Colors.grayLight}`,
      boxShadow: 'none',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      borderRadius: 10,
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: 10,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: Colors.blueGrayDark,
      '&:hover': {
        color: Colors.blueGrayDark,
      },
    }),
    option: (base: any, state: {isSelected: any}) => ({
      ...base,
      backgroundColor: state.isSelected && Colors.purple,
      '&:hover': {backgroundColor: !state.isSelected && Colors.blueGrayLight},
      ':active': {
        ...base[':active'],
        backgroundColor: Colors.blueGrayLight,
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginRight: 8,
      '& .asyncSearchSelect__single-value': {
        paddingRight: !isClearable && '35px !important',
      },
    }),
  };

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e && e.value ? e.value : '');
      if (e && e.label) {
        setDefaultLabel({label: e.label});
      }
    }
  };

  const ValueContainer: FC<any> = ({children, ...props}) => {
    const handleChange = (e: any) => {
      if (valueContainerOption) {
        valueContainerOption(e);
      }
    };

    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {children}
          {!!children && <ClearIcon onClick={handleChange} />}
        </components.ValueContainer>
      )
    );
  };

  return (
    <div className={className} key={`${name}-${label}`}>
      {label && <div className={classes.klaimSelectLabel}>{label}</div>}
      <AsyncSelect
        classNamePrefix="asyncSearchSelect"
        styles={customStyles}
        name={name}
        isClearable={isClearable}
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={handleOnChange}
        onInputChange={(e) => !onValueChange || onValueChange(e) || null}
        defaultValue={defaultLabel || defaultValue}
        components={!isClearable && value ? {ValueContainer} : undefined}
      />
      <p className={classes.inputError}>{error}</p>
    </div>
  );
};
export default AsyncSearchSelect;
