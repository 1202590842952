import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 12px 0 12px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 0.5px 2px #60617029;
  border-radius: 10px;
  opacity: 1;
  &.table-card {
    padding: 8px 24px;
  }
`;

export const Title = styled.div`
  font: normal normal bold 22px/34px Poppins;
  letter-spacing: 0.22px;
  color: #2c273c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  svg {
    transition: ease 0.3s;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;
