/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import {
  DatePicker as _DatePicker,
  DatePickerProps as _DatePickerProps,
  MonthPickerProps as _MonthPickerProps,
  RangePickerProps as _RangePickerProps,
  WeekPickerProps as _WeekPickerProps,
} from 'custom-test-antd';
import moment from 'moment';
import {FieldProps} from 'formik';

import Field from './Field';
import {FormikFieldProps} from './FieldProps';
import {makeField} from './makeField';

type DatePickerOwnProps = _DatePickerProps & {useDateString?: boolean};
type DatePickerProps = FieldProps & DatePickerOwnProps;
type DatePickerFieldProps = FormikFieldProps & DatePickerOwnProps;

type WeekPickerOwnProps = _WeekPickerProps & {useDateString?: boolean};
type WeekPickerProps = FieldProps & WeekPickerOwnProps;
type WeekPickerFieldProps = FormikFieldProps & _WeekPickerProps;

type RangePickerOwnProps = _RangePickerProps & {useDateString?: boolean};
type RangePickerProps = FieldProps & RangePickerOwnProps;
type RangePickerFieldProps = FormikFieldProps & _RangePickerProps;

type MonthPickerOwnProps = _MonthPickerProps & {useDateString?: boolean};
type MonthPickerProps = FieldProps & MonthPickerOwnProps;
type MonthPickerFieldProps = FormikFieldProps & _MonthPickerProps;

export type {DatePickerOwnProps as DatePickerProps};
export type {WeekPickerOwnProps as WeekPickerProps};
export type {RangePickerOwnProps as RangePickerProps};
export type {MonthPickerOwnProps as MonthPickerProps};

const {MonthPicker: _MonthPicker, RangePicker: _RangePicker, WeekPicker: _WeekPicker} = _DatePicker;

const DatePickerInternal = React.forwardRef(
  ({field, form, meta, onChange, useDateString = false, ...restProps}: DatePickerProps, ref: React.Ref<any>) => {
    const {value, name} = field;
    const {setFieldValue, setFieldTouched} = form;
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_DatePicker
        value={value ? moment(value) : undefined}
        onChange={(date: any, dateString: string) => {
          const dateValue = useDateString ? dateString : date;
          setFieldValue(name, dateValue || null);
          setFieldTouched(name, true, false);
          if (onChange) {
            onChange(date, dateString);
          }
        }}
        ref={ref}
        {...restProps}
      />
    );
  },
);

const DatePickerFieldInternal = React.forwardRef(
  ({name, validate, onChange, fast, ...restProps}: DatePickerFieldProps, ref: React.Ref<any>) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => <DatePickerInternal ref={ref} {...fieldProps} {...restProps} />}
    </Field>
  ),
);

const MonthPicker = React.forwardRef(
  ({field, form, meta, onChange, useDateString = false, ...restProps}: MonthPickerProps, ref: React.Ref<any>) => {
    const {value, name} = field;
    const {setFieldValue, setFieldTouched} = form;
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_MonthPicker
        ref={ref}
        value={value ? moment(value) : undefined}
        onChange={(date, dateString) => {
          const dateValue = useDateString ? dateString : date;
          setFieldValue(name, dateValue);
          setFieldTouched(name, true, false);
          if (onChange) {
            onChange(date, dateString);
          }
        }}
        {...restProps}
      />
    );
  },
);

const MonthPickerField = React.forwardRef(
  ({name, validate, ...restProps}: MonthPickerFieldProps, ref: React.Ref<any>) => (
    <Field name={name} validate={validate}>
      {(fieldProps: any) => <MonthPicker ref={ref} {...fieldProps} {...restProps} />}
    </Field>
  ),
);

const RangePicker = React.forwardRef(
  (
    {
      field: {value, name},
      form: {setFieldValue, setFieldTouched},
      meta,
      onChange,
      useDateString = false,
      ...restProps
    }: RangePickerProps,
    ref: React.Ref<any>,
  ) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <_RangePicker
      ref={ref}
      name={name}
      value={value}
      onChange={(dates, dateStrings) => {
        const dateValue = useDateString ? dateStrings : dates;
        setFieldValue(name, dateValue);
        setFieldTouched(name, true, false);
        if (onChange) {
          onChange(dates, dateStrings);
        }
      }}
      {...restProps}
    />
  ),
);

const RangePickerField = React.forwardRef(
  ({name, validate, onChange, ...restProps}: RangePickerFieldProps, ref: React.Ref<any>) => (
    <Field name={name} validate={validate}>
      {(fieldProps: any) => <RangePicker ref={ref} {...fieldProps} {...restProps} />}
    </Field>
  ),
);

const WeekPicker = React.forwardRef(
  (
    {
      onChange,
      field: {value, name},
      form: {setFieldValue, setFieldTouched},
      meta,
      useDateString = false,
      ...restProps
    }: WeekPickerProps,
    ref: React.Ref<any>,
  ) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <_WeekPicker
      ref={ref}
      name={name}
      value={value}
      onChange={(date, dateString) => {
        const dateValue = useDateString ? dateString : date;
        setFieldValue(name, dateValue);
        setFieldTouched(name, true, false);
        if (onChange) {
          onChange(date, dateString);
        }
      }}
      {...restProps}
    />
  ),
);

const WeekPickerField = React.forwardRef(
  ({name, validate, onChange, ...restProps}: WeekPickerFieldProps, ref: React.Ref<any>) => (
    <Field name={name} validate={validate}>
      {(fieldProps: any) => <WeekPicker ref={ref} {...fieldProps} {...restProps} />}
    </Field>
  ),
);

export interface DatePickerComponent
  extends React.ForwardRefExoticComponent<DatePickerProps & React.RefAttributes<any>> {
  WeekPicker: typeof WeekPicker;
  MonthPicker: typeof MonthPicker;
  RangePicker: typeof RangePicker;
}

export const DatePicker = DatePickerInternal as DatePickerComponent;
DatePicker.WeekPicker = WeekPicker;
DatePicker.MonthPicker = MonthPicker;
DatePicker.RangePicker = RangePicker;

export const DatePickerWrapper = makeField<DatePickerOwnProps>(DatePicker);
export const WeekPickerWrapper = makeField<_WeekPickerProps>(DatePicker.WeekPicker);
export const RangePickerWrapper = makeField<RangePickerOwnProps>(DatePicker.RangePicker);
export const MonthWrapper = makeField<_MonthPickerProps>(DatePicker.MonthPicker);

export interface DatePickerFieldComponent
  extends React.ForwardRefExoticComponent<DatePickerFieldProps & React.RefAttributes<any>> {
  WeekPicker: typeof WeekPickerField;
  MonthPicker: typeof MonthPickerField;
  RangePicker: typeof RangePickerField;
}

export const DatePickerField = DatePickerFieldInternal as DatePickerFieldComponent;
DatePickerField.WeekPicker = WeekPickerField;
DatePickerField.MonthPicker = MonthPickerField;
DatePickerField.RangePicker = RangePickerField;
