import React, {FC} from 'react';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    iconLeftPadding15: {
      paddingLeft: 15,
    },
    iconLeftPadding50: {
      paddingLeft: 50,
    },
    iconLeftWrapper: {
      display: 'flex',
      position: 'absolute',
      left: '0.5rem',
      right: '0.5rem',
      top: 8,
      bottom: 8,
      zIndex: 1,
      // width: 50,
      width: 10,
    },
    input: {
      '&:focus': {
        outline: 'none',
      },
      background: '#f5f7fb',
      border: '1px solid #e7ecf5',
      borderRadius: 10,
      boxSizing: 'border-box',
      height: 42,
      MozBoxSizing: 'border-box',
      paddingLeft: 15,
      paddingRight: 10,
      WebkitBoxSizing: 'border-box',
      width: '100%',
    },
    inputError: {
      color: 'red',
      fontSize: 12,
      marginBottom: 0,
      marginTop: 0,
    },
    inputLabel: {
      color: '#222b45',
      fontSize: 12,
      fontWeight: theme.font.weightSemibold,
      marginBottom: 3,
      marginLeft: 10,
    },
    inputWrapper: {
      position: 'relative',
    },
  };
});

interface ComponentProps {
  label?: string;
  className?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  value?: string | number | readonly string[] | undefined;
  error?: string;
  onChange?: (e: any) => void;
  onValueChange?: (value: string) => void;
  onKeyUp?: (e: any) => void;
  renderInput?: () => void;
  placeholder?: string;
  iconLeft?: any;
  name?: string;
  theme?: KlaimTheme;
  inputProps?: any;
  maxLength?: number;
  onBlur?: any;
}

const Input: FC<ComponentProps> = ({
  label,
  className,
  inputClassName,
  inputContainerClassName,
  value,
  onChange,
  onValueChange,
  onKeyUp,
  renderInput,
  placeholder,
  iconLeft,
  error,
  name,
  theme,
  inputProps,
  maxLength,
  onBlur,
}) => {
  const classes = useStylesFromThemeFunction({theme});
  return (
    <div className={className}>
      {label && <div className={classes.inputLabel}>{label}</div>}
      {renderInput ? (
        renderInput()
      ) : (
        <div className={`${classes.inputWrapper} ${inputContainerClassName}`}>
          <div className={classes.iconLeftWrapper}>{iconLeft && <img src={iconLeft} alt="icon-left" />}</div>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            name={name}
            className={`${classes.input} ${inputClassName} ${
              iconLeft ? classes.iconLeftPadding50 : classes.iconLeftPadding15
            }`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              if (onValueChange) {
                onValueChange(e.target.value);
              }
            }}
            onKeyUp={onKeyUp}
            maxLength={maxLength}
            onBlur={onBlur}
          />
        </div>
      )}
      <p className={classes.inputError}>{error}</p>
    </div>
  );
};

export default Input;
