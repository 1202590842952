import React from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';

interface ComponentsProps {
  open: boolean;
  errors?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ErrorsModal: React.FC<ComponentsProps> = ({open, setOpen, errors}) => {
  return (
    <KlaimModal open={open} setOpen={setOpen} maxWidth={1000}>
      <KlaimModalHeader title="Claim Errors" />
      <KlaimModalBody style={{padding: 20, maxHeight: 600, overflowY: 'auto'}}>
        <table>
          <thead>
            <tr>
              <th style={{width: '20%', textAlign: 'left'}}>Code</th>
              <th style={{width: '80%', textAlign: 'left'}}>Error</th>
            </tr>
          </thead>
          <tbody>
            {errors?.map((el) => (
              <tr>
                <td style={{width: '20%', fontWeight: 'bold', fontSize: 13}}>{el.code}</td>
                <td style={{width: '80%'}}>
                  <p>{el.display || ''}</p>
                  <p>{el.message || ''}</p>
                </td>
                <br />
              </tr>
            ))}
          </tbody>
        </table>
      </KlaimModalBody>
      <KlaimModalFooter cancelButtonTitle={'OK'} submitLoading={false} hideSubmit />
    </KlaimModal>
  );
};

export default ErrorsModal;
