import React from 'react';
import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  maxWidth?: number;
  width?: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ref?: React.RefObject<HTMLDivElement>;
  onClose?: () => void;
}

export const useStylesFromThemeFunction = createUseStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
  container: {
    background: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  '@media (max-height: 800px)': {
    container: {
      height: 'calc(100% - 100px)',
    },
  },
});
