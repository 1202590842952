import React from 'react';
import KlaimModal from 'modules/Common/components/KlaimModal';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';
import {useAppDispatch} from 'modules/App/store';
import closeIcon from 'assets/close_icon.svg';
import {deleteRoleCleanup} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import {ComponentProps, useStylesFromThemeFunction} from './DeleteModal';

const DeleteModal: React.FC<ComponentProps> = ({
  isConfirmDisableOpen,
  setConfirmDisableOpen,
  onRequestClose,
  index,
  isActive,
  setOpen,
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(deleteRoleCleanup());
    };
  }, []);

  return (
    <KlaimModal contentClass={classes.modal} isOpen={isConfirmDisableOpen} onRequestClose={onRequestClose}>
      <div className={classes.confirmDisableContainer}>
        <div className={classes.headerContainer}>
          <h1>Disable Role</h1>
          <img onClick={() => setConfirmDisableOpen(false)} src={closeIcon} alt="Close-icon" />
        </div>
        <p className={classes.descriptionContainer}>
          By disabling a role, staff members assigned to the role won’t be able to access the system. Do you want to
          disable this role?
        </p>
        <div className={classes.usersAssignedContainer}>
          <h3>
            Users assigned to <KlaimSpan>Administrator</KlaimSpan>
          </h3>
          <p>Jennifer Johnson</p>
          <p>David Willams</p>
          <p>Elizabeth Brown</p>
          <p>Mary Jones</p>
        </div>
        <div className={classes.optionsButtonContainer}>
          <div className={classes.optionsButtonSubContainer}>
            <Button title="No, Go back" buttonClassName={classes.backButton} />
            <Button
              title="Disable"
              onClick={() => {
                setConfirmDisableOpen(false);
                setOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </KlaimModal>
  );
};

export default DeleteModal;
