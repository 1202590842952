import React from 'react';
import {Formik, FormikErrors, FormikValues} from 'formik';
import CollapsibleSection from 'modules/Common/components/CollapsibleSection';
import Button from 'modules/Common/components/Button';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import * as Toast from 'modules/Common/utils/toast';
import {useHistory} from 'react-router';
import * as Yup from 'yup';
import Diagnosis from 'modules/Claims/components/Diagnosis';
import {submitPriorRequest} from 'modules/Pharmacy/api';
import BranchDropdown from 'modules/Common/components/BranchDropdown';
import {events, Mixpanel} from 'services/mixpanel';
import {ClaimDiagnosis} from 'modules/Claims/api/types';
import {DateRegex, EmiratesIdRegex} from 'modules/Claims/features/uae/ClaimForm';
import {ActivityType, AuthorizationType, EncounterType, ObservationType, PriorRequestInitialForm} from './types';
import {useStylesFromThemeFunction} from './PriorRequest';
import Information from '../../components/Information';
import Drugs from '../../components/Drugs';

const diagnoseValidation = Yup.object().shape({
  type: Yup.string(),
  code: Yup.mixed().test('String or null', 'Diagnosis Code is required.', (code) => {
    return true;
  }),
});

const activityValidation = Yup.object().shape({
  activityId: Yup.string(),
  code: Yup.string().required('Item Code is required.'),
  quantity: Yup.number().typeError('Quantity must be a number'),
  duration: Yup.number().typeError('Duration must be a number'),
});

const priorRequestValidationSchema = Yup.object().shape({
  emiratesIdNumber: Yup.string()
    .required('Emirates ID is required.')
    .matches(EmiratesIdRegex, 'Please enter valid Emirates ID format.'),
  memberId: Yup.string().required('Member ID is required.'),
  payerId: Yup.string().required('Payer ID is required.'),
  receiverId: Yup.string().required('Receiver ID is required.'),
  activities: Yup.array().of(activityValidation),
  providerId: Yup.string().required('Branch is required.'),
  diagnoses: Yup.array().of(diagnoseValidation),
  dateOrdered: Yup.string()
    .required('Start date is required.')
    .test('dateRegex', 'Please enter valid date.', (value) => {
      return DateRegex.test(value as string);
    }),
  clinicianId: Yup.string().required('Clinician is required.'),
});

const PriorRequest = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const classes = useStylesFromThemeFunction();

  const handleInputChange = (
    event: any,
    fieldName: any,
    field: any,
    setFieldTouched: (e: any) => void,
    handleChange: (e: any) => void,
    arrayHelpers?: any,
  ) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const priorRequestSubmit = async (prForm: any) => {
    const form = await getPriorRequestForm(prForm);
    try {
      const data = await submitPriorRequest(form);
      if (data.status === 201) {
        Toast.success('Prior request submitted.');
        Mixpanel.track(events.pharmacy.submitPr, {success: true, fromErx: false});
        history.push('/pharmacy');
      } else {
        Toast.error('Error submitting prior request.');
        Mixpanel.track(events.pharmacy.submitPr, {success: false, fromErx: false});
      }
    } catch (e) {
      Mixpanel.track(events.pharmacy.submitPr, {success: false, fromErx: false});
      Toast.error(e);
    }
  };

  const convertStringToIsoDate = (dateString?: string) => {
    if (!dateString) return undefined;
    if (dateString.length > 16) {
      return dayjs(dateString).format('DD/MM/YYYY HH:mm');
    }
    const date = dayjs(dateString, 'DD/MM/YYYY HH:mm').toDate();
    return date.toISOString();
  };

  const getPriorRequestForm = async (priorRequestForm: any) => {
    const activities = priorRequestForm.activities.map((activity: any, index: number) => {
      return {
        activityId: index.toString(),
        start: dayjs().toISOString(),
        type: ActivityType.Drug,
        code: activity.code,
        quantity: +activity.quantity,
        unit: activity.unit,
        net: +activity.net,
        clinicianId: priorRequestForm.clinicianId,
        observations: [
          {
            type: ObservationType.Text,
            code: 'Duration',
            value: activity.duration,
            valueType: 'Days',
          },
        ],
      };
    });
    return {
      priorRequest: {
        type: AuthorizationType.AUTHORIZATION,
        payerId: priorRequestForm.payerId,
        receiverId: priorRequestForm.receiverId,
        dateOrdered: convertStringToIsoDate(priorRequestForm.dateOrdered),
        memberId: priorRequestForm.memberId,
        emiratesIdNumber: priorRequestForm.emiratesIdNumber,
        encounter: {
          type: EncounterType.NoBedNoEmergencyRoom,
        },
        diagnoses: priorRequestForm.diagnoses.filter((d: {code: ''}) => d.code),
        activities,
      },
      providerId: priorRequestForm.providerId || '',
    };
  };

  const informationVisible = (values: FormikValues) => {
    return values.providerId;
  };

  const diagnosisVisible = (errors: FormikErrors<any>, values: FormikValues) => {
    return (
      values.memberId &&
      values.payerId &&
      values.receiverId &&
      values.emiratesIdNumber &&
      !errors.clinicianId &&
      !errors.dateOrdered
    );
  };

  const drugVisible = (values: FormikValues) => {
    return values.diagnoses.some((d: ClaimDiagnosis) => d.code);
  };

  const BranchId = ({handleChange, values, setFieldTouched, errors, touched}: any) => {
    return (
      <div className={classes.formSectionElementsWrapper}>
        <BranchDropdown
          className={classes.formElementWithBottomSpacing}
          value={values.providerId}
          onChange={(e) => handleInputChange(e, values, 'providerId', setFieldTouched, handleChange)}
          name="providerId"
          error={errors.providerId && touched.providerId ? errors.providerId : null}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <Formik
          validationSchema={priorRequestValidationSchema}
          initialValues={PriorRequestInitialForm}
          validateOnChange
          validateOnMount
          onSubmit={async (values: any, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            await priorRequestSubmit(values);
            resetForm();
            setSubmitting(false);
          }}
        >
          {({handleChange, values, handleSubmit, setFieldTouched, isSubmitting, setFieldValue, errors, touched}) => (
            <>
              <CollapsibleSection
                expandedContainerClassName={classes.expandedContainerClassName}
                contentClassName={classes.containerContentClassName}
                collapsedContainerClassName={classes.collapsedContainerHeaderClassName}
                renderHeader={() => (
                  <div className={classes.collapsibleHeaderContainer}>
                    <h5>Submit Prior Request</h5>
                    <div className={classes.dFlex}>
                      <Button
                        title={t('Submit')}
                        type="button"
                        onClick={handleSubmit}
                        buttonClassName={classes.submitPreAuthButton}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
                renderContent={() => (
                  <form className={classes.form}>
                    <BranchId
                      handleChange={handleChange}
                      values={values}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                    />
                    {informationVisible(values) && (
                      <Information
                        handleChange={handleChange}
                        values={values}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {diagnosisVisible(errors, values) && (
                      <Diagnosis
                        handleChange={handleChange}
                        values={values}
                        setFieldTouched={setFieldTouched}
                        classes={classes}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {drugVisible(values) && (
                      <Drugs
                        handleChange={handleChange}
                        values={values}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        classes={classes}
                      />
                    )}
                  </form>
                )}
              />
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
export default PriorRequest;
