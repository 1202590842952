import React from 'react';
import {createUseStyles} from 'react-jss';
import dayjs from 'dayjs';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import Table from 'modules/Common/components/Table';
import warningIcon from 'assets/warning.svg';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  preAuthV2Select,
  getHistories,
  cancelPA,
  communicationRequest,
  errorPreAuthCleanup,
  cancelPreAuthCleanup,
  communicationRequestCleanup,
  downloadExcel,
  getHistoryTotal,
} from 'modules/PreAuthV2/PreAuthV2Slice';
import {Colors} from 'modules/Common/constants/Colors';
import {KlaimModal, KlaimModalBody, KlaimModalHeader} from 'modules/Common/components-v2/KlaimModalv2';
import Sort from 'assets/sort.png';
import {Modal} from 'custom-test-antd';
import KlaimStepper from 'modules/Common/components-v2/KlaimStepper/components/KlaimStepper';
import KlaimStepperFooter from 'modules/Common/components-v2/KlaimStepper/components/KlaimStepperFooter';
import KlaimStepperHeader from 'modules/Common/components-v2/KlaimStepper/components/KlaimStepperHeader';
import KlaimStepperStep from 'modules/Common/components-v2/KlaimStepper/components/KlaimStepperStep';
import KlaimStepperSteps from 'modules/Common/components-v2/KlaimStepper/components/KlaimStepperSteps';
import KlaimScreenList from 'modules/Common/components-v2/KlaimScreens/components/KlaimScreenList';
import KlaimTextArea from 'modules/Common/components-v2/KlaimForm/components/KlaimTextArea';
import {KlaimDropdownSearch, KlaimFileUploadV2} from 'modules/Common/components-v2/KlaimForm';
import {branchesCleanup} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import {PreAuthHistory} from 'interfaces/pre-auth.interface';
import * as Toast from 'modules/Common/utils/toast';
import {get} from 'lodash';
import ListItem from './Listing';
import KlaimPaginationContainer from '../../../Common/components/KlaimPaginationContainer';
import {headerSelect, OptionValue, selectedStatusCleanup, setRequiredFilters} from '../../../Header/headerSlice';
import Button from '../../../Common/components/Button';
import axiosInstance from '../../../../services/axios';
import {ClaimStatdata} from '../../../Claims/features/v3/ClaimsList/data';

const DATE_FORMAT = `DD MMM 'YY`;

const formatBranchDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};
const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    paginationContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
    },
    listContainer: {
      background: theme.container.color.primary,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowX: 'auto',
    },
    value: {
      letterSpacing: '1.5px',
      color: '#696F81',
      textTransform: 'uppercase',
      font: 'normal normal medium 10px/16px Poppins',
      fontSize: 10,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
    },
    tableWrapper: {
      flexGrow: 1,
    },
    confirmPage: {
      background: Colors.blueGrayLight,
      padding: '20px 0',
      marginBottom: 20,
      textAlign: 'center',
      '& > *': {
        display: 'inline-block',
        width: '100%',
        textAlign: 'center',
      },
    },
    icon: {
      height: 50,
      width: 50,
      borderRadius: '100%',
      background: Colors.white,
      '& img': {width: 25, marginTop: 11},
      marginBottom: 20,
    },
    t1: {fontSize: 14},
    t2: {fontSize: 12},
    communicationWrap: {
      margin: 20,
    },
    uploaderCont: {
      margin: '30px auto',
      width: '100%',
    },
    flexEndRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      justifyContent: 'flex-end',
      paddingTop: 12,
      paddingBottom: 12,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '90vh',
      position: 'relative',
      paddingTop: 0,
      width: '100%',
    },
    header: {
      padding: 24,
      background: '#FFFFFF 0% 0% no-repeat',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto',
      gap: '12px 95px',
      // minWidth: '1200px',
    },
    labelValue: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      font: 'normal normal normal 14px/34px Poppins',
      letterSpacing: '0.42px',
      color: '#222B45',
      fontSize: 14,
    },

    tableContainer: {
      backgroundColor: 'white',
      boxShadow: '0px 0.5px 2px #C7C5C9',
      borderRadius: 10,
      marginTop: 26,
      position: 'relative',
      overflowX: 'auto',
      width: '100%',
    },
    tableBodyContainer: {
      width: '100%',
    },
    tableHeaderContainer: {},
    tableHeader: {
      borderBottom: '1px solid #E7ECF5',
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'row',
    },
  };
});

const PreAuthHistoryV2: React.FC<RouteComponentProps> = ({history, match}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const searchParam = query.get('search');
  const {
    selectedBranches,
    startDate,
    endDate,
    selectedTPA,
    selectedStatus,
    selectedType,
    selectedVisits,
    selectedReceiver,
    // searchEpisodeQuery,
  } = useAppSelector(headerSelect);

  const [offset, setOffset] = React.useState<number>(1);
  const [limit] = React.useState<number>(10);
  const [search, setSearch] = React.useState<string>('');
  const [modalContent, setModalContent] = React.useState<string>('communication');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [reason, setReason] = React.useState(null);
  const [pa, setPa] = React.useState<PreAuthHistory | null>(null);
  const [note, setNote] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [comErrors, setComErrors] = React.useState<{note: any}>({note: null});
  const [generatingReport, setGeneratingReport] = React.useState(false);

  const {
    histories: {data, count},
    totals,
    isCancellationSuccess,
    isCommunicationRequestSuccess,
    error,
  } = useAppSelector(preAuthV2Select);

  const getAdditionalRequestParams = () => {
    const prId =
      selectedBranches.length > 0 ? {providerId: selectedBranches.map((provider) => provider.value).join(',')} : {};
    const pId = selectedTPA.length > 0 ? {payerId: selectedTPA.map((el: any) => el.value).join(',')} : {};
    const rId = selectedReceiver.length > 0 ? {receiverId: selectedReceiver.map((el: any) => el.value).join(',')} : {};
    const vts = selectedVisits.length > 0 ? {claimSubType: selectedVisits.map((el: any) => el.value).join(',')} : {};
    const tps = selectedType.length > 0 ? {claimType: selectedType.map((el: any) => el.value).join(',')} : {};
    const sts = selectedStatus.length > 0 ? {status: selectedStatus.map((el: any) => el.value).join(',')} : {};
    // const ep = searchEpisodeQuery && searchEpisodeQuery?.length > 0 ? {episode: searchEpisodeQuery} : {};
    const srch = {sortBy: 'createdAt', sortOrder};

    return {
      ...pId,
      ...prId,
      ...vts,
      ...tps,
      ...sts,
      ...srch,
      ...rId,
      // ...ep,
    };
  };

  React.useEffect(() => {
    if (selectedBranches.length > 0) {
      dispatch(
        getHistories({
          limit,
          offset: (offset - 1) * limit,
          startDate: formatBranchDate(startDate),
          endDate: formatBranchDate(endDate),
          ...getAdditionalRequestParams(),
          search: search as string,
        }),
      );
      dispatch(
        getHistoryTotal({
          limit,
          offset: (offset - 1) * limit,
          startDate: formatBranchDate(startDate),
          endDate: formatBranchDate(endDate),
          ...getAdditionalRequestParams(),
          search: search as string,
        }),
      );
    }
  }, [
    limit,
    offset,
    startDate,
    selectedStatus,
    endDate,
    selectedType,
    selectedBranches,
    selectedTPA,
    search,
    selectedVisits,
    selectedReceiver,
    sortOrder,
    // searchEpisodeQuery,
  ]);

  React.useEffect(() => {
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
  }, [searchParam]);

  React.useEffect(() => {
    dispatch(errorPreAuthCleanup());
    dispatch(branchesCleanup());
  }, []);

  React.useEffect(() => {
    if (error) Toast.error('Something went wrong while processing your request.', 'Request Failed');
  }, [error]);

  React.useEffect(() => {
    if (isCancellationSuccess === true) {
      dispatch(cancelPreAuthCleanup());
      setIsOpen(false);
      setPa(null);
      Toast.success('Cancellation Request is succesfully sent!', 'Success');
    }
    if (isCommunicationRequestSuccess === true) {
      dispatch(communicationRequestCleanup());
      setIsOpen(false);
      setPa(null);
      Toast.success('Communication Request is succesfully sent!', 'Success');
    }
  }, [isCancellationSuccess, isCommunicationRequestSuccess]);

  React.useEffect(() => {
    dispatch(
      setRequiredFilters({
        navigation: true,
        search: true,
        statusFilter: true,
        TPAFilter: true,
        typesFilter: true,
        visitFilter: true,
        dateRangeFilterDays: true,
        branchesFilter: true,
        searchEpisode: false,
        preAuthButtons: true,
        receiversFilter: true,
      }),
    );
    return () => {
      dispatch(
        setRequiredFilters({
          navigation: false,
          search: false,
          statusFilter: false,
          TPAFilter: false,
          typesFilter: false,
          visitFilter: false,
          dateRangeFilterDays: false,
          branchesFilter: false,
          searchEpisode: false,
          preAuthButtons: false,
          receiversFilter: true,
        }),
      );
      dispatch(selectedStatusCleanup());
    };
  }, []);

  const handleView = (id: any, providerId: string) => {
    history.push(`${match.url}/details/${id}?providerId=${providerId}`);
  };

  const handleResubmit = (id: any, providerId: string) => {
    history.push(`${match.url}/form/${id}/resubmit?providerId=${providerId}`);
  };

  const handleProceedToClaim = (id: any, providerId: string) => {
    history.push(`/claims/form?providerId=${providerId}&preAuthId=${id}`);
  };

  const handleViewEligibilityDetails = (id: any, providerId: string) => {
    history.push(`/eligibility/details/${id}?providerId=${providerId}`);
  };

  const submitCancelRequest = () => {
    if (pa) {
      const payload = {
        providerId: pa.tenantId,
        type: 'cancel-request',
        payerId: pa?.payerId || '',
        receiverId: pa?.receiverId || '',
        claimSystem: 'http://klaim.ai/fhir/authorization', //get(pa, 'submissions[0].coverage.member.system', ''),
        cancelRequest: {
          reason: reason || '',
        },
        claimId: pa?.id,
      };
      dispatch(
        cancelPA({
          payload,
        }),
      );
    }
  };

  const submitCommRequest = () => {
    if (note.length === 0) {
      setComErrors({note: 'This field is required.'});
      return Toast.error('Add a note at least.', 'Error');
    }
    if (pa) {
      const payload: any = {
        providerId: pa.tenantId,
        type: 'communication',
        payerId: pa?.payerId || '',
        receiverId: pa?.receiverId || '',
        // responses[0].response.requestedClaim.system
        // claimSystem: pa?.id || '',
        claimSystem: 'http://klaim.ai/fhir/authorization', //get(pa, 'submissions[0].coverage.member.system', ''),
        patientId: pa?.patientId || '',
        payerName: get(pa, 'submissions[0].payerName'),
        senderName: get(pa, 'submissions[0].senderName'),
        claimId: get(pa, 'submissions[0].claimId'),
      };

      const payloadArr = [];
      payloadArr.push({contentString: note});
      files.map(({base64StringFile, file}: any) => {
        payloadArr.push({
          contentType: file?.type,
          contentTitle: file?.name,
          contentData: base64StringFile,
        });
        return file;
      });

      payload.communication = {
        payload: payloadArr,
      };

      if (pa?.status === 'missing-information') {
        payload.type = 'communication-response';
        payload.communication.basedOnCommReqId = pa?.responses?.[0]?.response?.comunicationIdentifier;
        payload.communication.basedOnCommReqSystem = pa?.responses?.[0]?.response?.comunicationSystem;
      }

      dispatch(
        communicationRequest({
          id: pa?.id,
          payload,
        }),
      );
    }
  };

  let renderModal = (
    <>
      <KlaimModalHeader title="PA CANCELLATION REQUEST" />
      <KlaimModalBody>
        <KlaimScreenList>
          <KlaimStepper initialStep={currentStep} setStep={setCurrentStep}>
            <div style={{margin: 20}}>
              <KlaimStepperHeader />
            </div>
            <KlaimStepperSteps>
              <KlaimStepperStep>
                <div style={{margin: 20}}>
                  <KlaimDropdownSearch
                    label="Reason"
                    options={[
                      {label: 'Wrong information', value: 'WI'},
                      {label: 'Service not performed', value: 'NP'},
                      {label: 'Transaction already submitted', value: 'TAS'},
                    ]}
                    onChange={(val) => {
                      setReason(val);
                    }}
                    variant={'secondary'}
                  />
                </div>
              </KlaimStepperStep>
              <KlaimStepperStep>
                <div className={classes.confirmPage}>
                  <div className={classes.icon}>
                    <img src={warningIcon} alt="cancelation" />
                  </div>
                  <p className={classes.t1}>You are about to send a cancellation request</p>
                  <p className={classes.t2}>Are you sure you want to proceed?</p>
                </div>
              </KlaimStepperStep>
            </KlaimStepperSteps>
            <div style={{margin: 20}}>
              <KlaimStepperFooter onSubmit={submitCancelRequest} submitText="Yes, Send" />
            </div>
          </KlaimStepper>
        </KlaimScreenList>
      </KlaimModalBody>
    </>
  );

  if (modalContent === 'communication')
    renderModal = (
      <>
        <KlaimModalHeader title="Communication Request" />
        <KlaimModalBody>
          <KlaimScreenList>
            <KlaimStepper initialStep={currentStep} setStep={setCurrentStep}>
              <div style={{margin: 20}}>
                <KlaimStepperHeader />
              </div>
              <KlaimStepperSteps>
                <KlaimStepperStep>
                  <div className={classes.communicationWrap}>
                    <p className={classes.t1}>You are about to send a communication request to NPHIES</p>
                    <p className={classes.t1}>Please leave a note below.</p>
                    <div className={classes.uploaderCont}>
                      <KlaimFileUploadV2
                        multiple
                        onChange={(val: any) => {
                          setFiles(val);
                        }}
                        dropAreaText="Drag and drop file"
                        percentage={100}
                      />
                    </div>
                    <KlaimTextArea
                      placeholder="Leave a note"
                      resizable={false}
                      label="Note"
                      onChange={(e) => setNote(e)}
                      isError={comErrors.note !== null}
                      isTouched={comErrors.note !== null}
                    />
                  </div>
                </KlaimStepperStep>
              </KlaimStepperSteps>
              <div style={{margin: 20}}>
                <KlaimStepperFooter onSubmit={submitCommRequest} submitText="Send" />
              </div>
            </KlaimStepper>
          </KlaimScreenList>
        </KlaimModalBody>
      </>
    );

  const labelValue = (label: string, value: string) => {
    return (
      <div className={classes.labelValue}>
        <div className={classes.value}>{label}</div>
        <div className={classes.label}>{value}</div>
      </div>
    );
  };
  const getHeader = () => {
    const arr = Object.keys(ClaimStatdata).map((key) => ({
      label: ClaimStatdata[key].label,
      value: totals[key] || ClaimStatdata[key].value,
    }));
    arr.push({
      label: 'TOTAL NET WITH VAT',
      value: `${Number(parseFloat(totals.total_net_amount || 0) + parseFloat(totals.total_vat_amount || 0)).toFixed(
        2,
      )}`,
    });
    return arr;
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>{getHeader().map((el) => labelValue(el.label, el.value))}</div>
      <br />
      <div style={{height: '90vh', overflowY: 'auto', paddingBottom: 16}}>
        <KlaimPaginationContainer
          pageSize={limit}
          totalCount={count}
          currentPage={offset}
          onPageChange={setOffset}
          label="Pre-Authorizations"
        >
          <KlaimModal
            maxWidth={600}
            open={isOpen}
            setOpen={() => {
              setIsOpen(false);
              setComErrors({note: null});
            }}
          >
            {renderModal}
          </KlaimModal>

          <div className={classes.tableContainer}>
            <div className={classes.tableHeaderContainer}>
              <div className={classes.tableHeader}>
                <div
                  className={classes.value}
                  style={{width: 170, cursor: 'pointer'}}
                  onClick={() => {
                    if (sortOrder === 'asc') setSortOrder('desc');
                    else setSortOrder('asc');
                  }}
                  role={'button'}
                >
                  CLAIM DETAILS &nbsp;
                  <img src={Sort} alt="genderIcon" style={{opacity: 0.4, width: 18}} />
                </div>
                <div className={classes.value} style={{width: 140}}>
                  PAYERS / RECEIVER
                </div>
                <div className={classes.value} style={{width: 160}}>
                  PATIENT
                </div>
                <div className={classes.value} style={{width: 250}}>
                  ADMISSION / DISCHARGE
                </div>
                <div className={classes.value} style={{width: 120}}>
                  STATUS
                </div>
                <div className={classes.value} style={{width: 200}}>
                  CLINIC
                </div>
                <div className={classes.value} style={{width: 400}}>
                  TOTAL AMOUNTS IN SAR
                </div>
                <div className={classes.value} style={{width: 50}}>
                  ACTION
                </div>
              </div>
            </div>
            <div className={classes.tableBodyContainer}>
              {data && data.length > 0
                ? data.map((item: any) => {
                    return (
                      <ListItem
                        key={item.id}
                        data={item}
                        handleView={handleView}
                        handleResubmit={handleResubmit}
                        handleProceedToClaim={handleProceedToClaim}
                        handleViewEligibilityDetails={handleViewEligibilityDetails}
                        openModal={setIsOpen}
                        setItem={setPa}
                        setModalContent={setModalContent}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        </KlaimPaginationContainer>
        <div className={classes.flexEndRow}>
          <Button
            loading={generatingReport}
            onClick={async () => {
              setGeneratingReport(true);
              await axiosInstance.get('/reports/generate/prior-auth', {
                params: {
                  providerId: selectedBranches.map((provider) => provider.value).join(','),
                  startDate: formatBranchDate(startDate),
                  endDate: formatBranchDate(endDate),
                  ...getAdditionalRequestParams(),
                  search: search as string,
                },
              });
              Modal.info({
                title: 'Generate Report',
                content:
                  'We\'ve received your request for generating a report. The report will be available for download in the "Reporting" section',
                okText: 'Close',
                centered: true,
              });
              setGeneratingReport(false);
            }}
            title="Download Excel"
          />
        </div>
      </div>
    </div>
  );
};

export default PreAuthHistoryV2;
