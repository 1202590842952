import React, {useState} from 'react';
import {get} from 'lodash';
import dayjs from 'dayjs';
import {Card, Item, Label, Row, Title, Value} from './styles';
import {TimeLine, TimeLineCardTitle, TimeLineColumn, TimeLineDivider, TimeLineItem} from './stylesOld';
import {ReactComponent as TimeLineIcon} from '../../../../../assets/timeline_icon.svg';
import {claimStatues} from '../ClaimsList/data';
import CommRequestModal from '../ClaimsList/CommRequestModal';

const DATE_FORMAT = `DD MMM YYYY HH:mm`;
const formatDate = (date) => (date ? dayjs(date).format(DATE_FORMAT) : null);

const getTimeLineItemLeft = ({submission, response, status}) => {
  return (
    <Row>
      {submission &&
        response && [
          <TimeLineItem>
            <TimeLineCardTitle>
              {submission?.type?.replace('-', ' ')} <p>{formatDate(response?.date)}</p>
            </TimeLineCardTitle>
            {['communication', 'communication-response'].indexOf(submission.type) !== -1 ? (
              <Row>
                <Item>
                  <Label>Info</Label>
                  <Value>{get(submission, 'communication.payload[0].contentString')}</Value>
                </Item>
              </Row>
            ) : (
              <Row>
                <Item>
                  <Label>Num of services</Label>
                  <Value>{submission?.claim?.item?.length}</Value>
                </Item>
                <Item>
                  <Label>QUEUED</Label>
                  <Value>{response?.queued}</Value>
                </Item>
                <Item>
                  <Label>Total Submitted</Label>
                  <Value>{response?.submittedTotal}</Value>
                </Item>
              </Row>
            )}
          </TimeLineItem>,
          <TimeLineIcon className={'icon'} />,
        ]}
    </Row>
  );
};
const getTimeLineItemRight = ({submission, response, status, setOpen}) => {
  return (
    <Row>
      {submission &&
        response && [
          // ['claim-response', 'priorauth-response'].indexOf(response.type) !== -1 && [
          [
            <TimeLineIcon className={'icon right'} />,
            <TimeLineItem>
              <TimeLineCardTitle>
                {response?.type?.replace('-', ' ')} <p>{formatDate(response?.date)}</p>
              </TimeLineCardTitle>
              <Row>
                <Item>
                  <Label>Status</Label>
                  {response?.response?.queued && <Value>NPHIES QUEUED</Value>}
                  <div>
                    {response?.errors?.length > 0 ? (
                      <Value
                        style={{
                          backgroundColor: claimStatues.error?.bg,
                          color: claimStatues.error?.text,
                          padding: '2px 6px',
                          borderRadius: 5,
                        }}
                      >
                        {claimStatues.error?.label}
                      </Value>
                    ) : (
                      <Value
                        style={{
                          backgroundColor: claimStatues[get(response, 'response.adjOutcome[0].code')]?.bg,
                          color: claimStatues[get(response, 'response.adjOutcome[0].code')]?.text,
                          padding: '2px 6px',
                          borderRadius: 5,
                        }}
                      >
                        {claimStatues[get(response, 'response.adjOutcome[0].code')]?.label}
                      </Value>
                    )}
                  </div>
                </Item>
              </Row>
              <br />
              {!response?.response?.queued && [
                <Row>
                  <Item>
                    <Label>Num of services</Label>
                    <Value>{submission?.claim?.item?.length}</Value>
                  </Item>
                  <Item>
                    <Label>Total Submitted</Label>
                    <Value>
                      {get(
                        get(response, `response.claimTotal`, []).filter((sub) => sub.category === 'submitted'),
                        '[0].amount',
                      )}
                    </Value>
                  </Item>
                </Row>,
                <div>
                  {response?.errors?.length === 0 && [
                    <br />,
                    <Row>
                      <Item>
                        <Label>TOTAL COPAY</Label>
                        <Value>
                          {get(
                            get(response, `response.claimTotal`, []).filter((sub) => sub.category === 'copay'),
                            '[0].amount',
                          )}
                        </Value>
                      </Item>
                      <Item>
                        <Label>ELIGIBLE</Label>
                        <Value>
                          {get(
                            get(response, `response.claimTotal`, []).filter((sub) => sub.category === 'eligible'),
                            '[0].amount',
                          )}
                        </Value>
                      </Item>
                      <Item>
                        <Label>TAX</Label>
                        <Value>
                          {get(
                            get(response, `response.claimTotal`, []).filter((sub) => sub.category === 'tax'),
                            '[0].amount',
                          )}
                        </Value>
                      </Item>
                      <Item>
                        <Label>BENEFIT</Label>
                        <Value>
                          {get(
                            get(response, `response.claimTotal`, []).filter((sub) => sub.category === 'benefit'),
                            '[0].amount',
                          )}
                        </Value>
                      </Item>
                      <Item>
                        <Label>PT SHARE</Label>
                        <Value>
                          {get(
                            get(response, `response.claimTotal`, []).filter(
                              (sub) => sub.category === 'patientShare' || sub.category === 'copay',
                            ),
                            '[0].amount',
                          )}
                        </Value>
                      </Item>
                      <Item>
                        <Label> </Label>
                        <Value> </Value>
                      </Item>
                    </Row>,
                  ]}
                </div>,
              ]}
            </TimeLineItem>,
          ],
          ['communication-request'].indexOf(response.type) !== -1 && [
            <TimeLineIcon className={'icon right'} />,
            <TimeLineItem>
              <TimeLineCardTitle>
                {response?.type?.replace('-', ' ')} <p>{formatDate(response?.date)}</p>
              </TimeLineCardTitle>
              <Row>
                <Item>
                  <Label>INFO</Label>
                  <Value>{get(response, 'response.commPayload[0].contentString')}</Value>
                </Item>
              </Row>
              <br />
              <Row>
                <Item>
                  <Label>Attachment</Label>
                  <Value>{get(response, 'response.commPayload[0].contentAttachment')}</Value>
                </Item>
              </Row>
              <br />
              <Row>
                <Item>
                  <Label>Attachment title</Label>
                  <Value>{get(response, 'response.commPayload[0].title')}</Value>
                </Item>
              </Row>
              <hr />
              <Row>
                <Item>
                  <Label />
                  <Value
                    className={'link'}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Respond to request
                  </Value>
                </Item>
              </Row>
            </TimeLineItem>,
          ],
          ['communication-response'].indexOf(response.type) !== -1 && [
            <TimeLineIcon className={'icon right'} />,
            <TimeLineItem>
              <TimeLineCardTitle>
                {response?.type?.replace('-', ' ')} <p>{formatDate(response?.date)}</p>
              </TimeLineCardTitle>
              <Row>
                <Item>
                  <Label>STATUS</Label>
                  <div>
                    {response?.errors?.length > 0 ? (
                      <Value
                        style={{
                          backgroundColor: claimStatues.error?.bg,
                          color: claimStatues.error?.text,
                          padding: '2px 6px',
                          borderRadius: 5,
                        }}
                      >
                        {claimStatues.error?.label}
                      </Value>
                    ) : (
                      <Value
                        style={{
                          backgroundColor: claimStatues[get(response, 'response.adjOutcome[0].code')]?.bg,
                          color: claimStatues[get(response, 'response.adjOutcome[0].code')]?.text,
                          padding: '2px 6px',
                          borderRadius: 5,
                        }}
                      >
                        {claimStatues[get(response, 'response.adjOutcome[0].code')]?.label}
                      </Value>
                    )}
                  </div>
                </Item>
              </Row>
              {response?.errors?.length > 0 && [
                <br />,
                <Row>
                  <Item>
                    <Label>Code</Label>
                    <Value>{get(response, 'response.errors[0].code')}</Value>
                  </Item>
                </Row>,
                <br />,
                <Row>
                  <Item>
                    <Label>Message</Label>
                    <Value>{get(response, 'response.errors[0].message')}</Value>
                  </Item>
                </Row>,
              ]}
              <br />
            </TimeLineItem>,
          ],
          ['cancel-response'].indexOf(response.type) !== -1 && [
            <TimeLineIcon className={'icon right'} />,
            <TimeLineItem>
              <TimeLineCardTitle>
                {response?.type?.replace('-', ' ')} <p>{formatDate(response?.date)}</p>
              </TimeLineCardTitle>
              <Row>
                <Item>
                  <Label>STATUS</Label>
                  <div>
                    {response?.errors?.length > 0 ? (
                      <Value
                        style={{
                          backgroundColor: claimStatues.error?.bg,
                          color: claimStatues.error?.text,
                          padding: '2px 6px',
                          borderRadius: 5,
                        }}
                      >
                        {claimStatues.error?.label}
                      </Value>
                    ) : (
                      <Value
                        style={{
                          backgroundColor: claimStatues[get(response, 'response.adjOutcome[0].code')]?.bg,
                          color: claimStatues[get(response, 'response.adjOutcome[0].code')]?.text,
                          padding: '2px 6px',
                          borderRadius: 5,
                        }}
                      >
                        {claimStatues[get(response, 'response.adjOutcome[0].code')]?.label}
                      </Value>
                    )}
                  </div>
                </Item>
              </Row>
              <br />
              <Row>
                <Item>
                  <Label>Code</Label>
                  <Value>{get(response, 'response.output[0].typeDetails.output.code')}</Value>
                </Item>
              </Row>
              <br />
              <Row>
                <Item>
                  <Label>Message</Label>
                  <Value>{get(response, 'response.output[0].typeDetails.output.display')}</Value>
                </Item>
              </Row>
              <br />
            </TimeLineItem>,
          ],
        ]}
    </Row>
  );
};
const Component: React.FC<any> = ({status, submissions, responses, claim}) => {
  const [open, setOpen] = useState(false);
  return (
    <Card>
      <Title>Timeline</Title>
      <TimeLine>
        <TimeLineColumn className="left">
          {submissions?.map((submission: any, index: any) => {
            return getTimeLineItemLeft({submission, response: get(responses, `[${index}]`, {}), status});
          })}
        </TimeLineColumn>
        <TimeLineColumn className="center">
          <TimeLineDivider />
        </TimeLineColumn>
        <TimeLineColumn className="right">
          {responses?.map((response: any) => {
            return getTimeLineItemRight({submission: submissions[submissions.length - 1], response, status, setOpen});
          })}
        </TimeLineColumn>
      </TimeLine>
      <CommRequestModal
        open={open}
        setOpen={setOpen}
        comm={claim?.communication}
        status={claim?.status}
        id={claim?.id}
        submission={get(claim, 'submissions[0]', {})}
        providerId={claim?.tenantId}
        response={get(claim, 'responses[0]', {})}
      />
    </Card>
  );
};

export default Component;
