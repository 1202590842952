import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    calendarWrapper: {
      '& .react-datepicker__header:not(.react-datepicker__header--has-time-select)': {
        background: Colors.white,
        border: 0,
        '& button': {
          background: 'none',
          border: 'none',
        },
        '& h1': {
          color: Colors.purple,
          fontWeight: 500,
          fontSize: 28,
        },
        '& p': {
          color: Colors.grayLabel,
          fontWeight: 600,
          fontSize: 14,
        },
        '& .react-datepicker__day-names': {
          background: Colors.blueGrayLight,
          borderTop: `1px solid ${Colors.grayLight}`,
          borderBottom: `1px solid ${Colors.grayLight}`,
          height: 20,
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          marginLeft: 'auto',
        },
        '& .react-datepicker__day-name': {
          height: 20,
          fontSize: 12,
          margin: 0,
          lineHeight: 2,
        },
      },
      '& .react-datepicker__month-container': {
        width: '100%',
        '& .react-datepicker__month-wrapper': {
          display: 'flex',
          flexWrap: 'wrap',
          gap: 5,
          justifyContent: 'center',
          '& .react-datepicker__month-text': {
            width: '25%',
            padding: 3,
            '&.react-datepicker__month--in-range': {
              background: Colors.purple,
              '&.react-datepicker__month-text--keyboard-selected': {
                // background: Colors.white,
                // color: Colors.black,
                background: `${Colors.purple} !important`,
                color: `${Colors.white} !important`,
              },
            },
            '&.react-datepicker__month-text--keyboard-selected': {
              background: Colors.purple,
            },
            '& .react-datepicker__day': {
              background: Colors.purple,
            },
            '& .react-datepicker__day--in-range ': {
              background: Colors.purple,
            },
            '& .react-datepicker__month--range-end': {
              background: Colors.purple,
            },
          },
        },
        '& .react-datepicker__month': {
          '& .react-datepicker__week': {
            display: 'flex',
            justifyContent: 'center',
            gap: 5,
            marginTop: 2,
            marginBottom: 2,
            '& .react-datepicker__day': {
              borderRadius: 5,
            },
            '& .react-datepicker__day--in-range': {
              background: Colors.purple,
            },
            '& .react-datepicker__day--in-selecting-range': {
              background: Colors.purpleLight,
              color: Colors.purple,
            },
            '& .react-datepicker__day--selected': {
              '& :hover': {
                background: Colors.purple,
              },
              background: Colors.purple,
              color: Colors.white,
            },
            '& .react-datepicker__day--outside-month': {
              background: Colors.grayWithOpacity,
            },
          },
        },
      },
      background: 'cf0',
      border: 0,
      width: 250,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});
