import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from 'services/axios';
import {RootState} from 'modules/App/store';
import {
  HistoriesData,
  HistoriesQuery,
  HistoryQuery,
  CancelQuery,
  PreAuthHistory,
  IPreAuthRequest,
  ICreatePatientRequest,
  CommunicationRequestQuery,
} from 'interfaces/pre-auth.interface';
import {MyKnownError} from 'interfaces/error-interface';
import {ClaimHistoriesData} from '../../interfaces/claim.interface';
import {get} from 'lodash';

interface InitialState {
  histories: {
    data: PreAuthHistory[];
    count: number;
  };
  totals: any;

  history?: PreAuthHistory;
  isLoading: boolean;
  error: any;
  isCreatePatientSuccess: boolean;
  isUpdatePatientSuccess: boolean;
  isCreatePreAuthSuccess: boolean;
  isCancellationSuccess: boolean;
  isCommunicationRequestSuccess?: any;
  cancelledPA: any;
  communicationRequest: any;
  preAuth?: PreAuthHistory;
}

const initialState: InitialState = {
  histories: {
    data: [],
    count: 0,
  },
  totals: {},
  history: undefined,
  preAuth: undefined,
  isLoading: false,
  error: undefined,
  isCreatePatientSuccess: false,
  isUpdatePatientSuccess: false,
  isCreatePreAuthSuccess: false,
  isCancellationSuccess: false,
  isCommunicationRequestSuccess: false,
  cancelledPA: {},
  communicationRequest: {},
};

export const getHistories = createAsyncThunk<HistoriesData, HistoriesQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/getHistoriesV2',
  async (query: HistoriesQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/prior-auth', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getHistory = createAsyncThunk<PreAuthHistory, HistoryQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/getHistoryV2',
  async ({id, ...params}: HistoryQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/prior-auth/${id}`, {params});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getHistoryTotal = createAsyncThunk<
  Record<string, number>,
  Record<string, unknown>,
  {rejectValue: MyKnownError}
>('getHistoryTotal', async (query: any, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.get(`/prior-auth/total/amount`, {params: {...query}});
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const downloadExcel = createAsyncThunk<ClaimHistoriesData, HistoriesQuery, {rejectValue: MyKnownError}>(
  'downloadExcel',
  async (query: HistoryQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/prior-auth//report', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const cancelPA = createAsyncThunk<PreAuthHistory, CancelQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/cancelPA',
  async (query: CancelQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/prior-auth/cancel`, query.payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const communicationRequest = createAsyncThunk<
  PreAuthHistory,
  CommunicationRequestQuery,
  {rejectValue: MyKnownError}
>('preAuthV2/communicationRequest', async (query: CommunicationRequestQuery, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.post(`/prior-auth/${query.id}/communication-request`, query.payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createPatient = createAsyncThunk<null, ICreatePatientRequest, {rejectValue: MyKnownError}>(
  'preAuthV2/createPatient',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post('/patients', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const updatePatient = createAsyncThunk<
  null,
  {id: string; payload: ICreatePatientRequest},
  {rejectValue: MyKnownError}
>('preAuthV2/updatePatient', async (data, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.patch(`/patients/${data.id}`, data.payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createPreAuth = createAsyncThunk<PreAuthHistory, any, {rejectValue: MyKnownError}>(
  'preAuthV2/createPreauth',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post('/prior-auth', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resubmitPreAuth = createAsyncThunk<PreAuthHistory, IPreAuthRequest, {rejectValue: MyKnownError}>(
  'preAuthV2/resubmitPreAuth',
  async ({id, ...data}, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/prior-auth/${id}/resubmit`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const preAuthV2Slice = createSlice({
  name: 'preAuthV2',
  initialState,
  reducers: {
    errorPatientCleanup: (state) => {
      state.error = undefined;
    },
    errorPreAuthCleanup: (state) => {
      state.error = undefined;
    },
    createPatientCleanup: (state) => {
      state.isCreatePatientSuccess = false;
    },
    updatePatientCleanup: (state) => {
      state.isUpdatePatientSuccess = false;
    },
    createPreAuthCleanup: (state) => {
      state.isCreatePreAuthSuccess = false;
      state.preAuth = undefined;
    },
    getPreAuthCleanup: (state) => {
      state.history = undefined;
    },
    cancelPreAuthCleanup: (state) => {
      state.isCancellationSuccess = false;
    },
    communicationRequestCleanup: (state) => {
      state.isCommunicationRequestSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistories.fulfilled, (state, action) => {
        state.histories = action.payload;
        state.isLoading = false;
      })
      .addCase(getHistories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(downloadExcel.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(downloadExcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadExcel.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getHistory.fulfilled, (state, action) => {
        state.history = action.payload;
        state.isLoading = false;
      })
      .addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(cancelPA.fulfilled, (state, action) => {
        state.cancelledPA = action.payload;
        state.isCancellationSuccess = true;
        state.isLoading = false;
      })
      .addCase(cancelPA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelPA.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(communicationRequest.fulfilled, (state, action) => {
        state.communicationRequest = action.payload;
        state.isCommunicationRequestSuccess = true;
        state.isLoading = false;
      })
      .addCase(communicationRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(communicationRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(updatePatient.fulfilled, (state) => {
        state.isUpdatePatientSuccess = true;
        state.isLoading = false;
      })
      .addCase(updatePatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdatePatientSuccess = false;
        state.error = action.payload;
      });
    builder
      .addCase(createPatient.fulfilled, (state) => {
        state.isCreatePatientSuccess = true;
        state.isLoading = false;
      })
      .addCase(createPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreatePatientSuccess = false;
        state.error = action.payload;
      });
    builder
      .addCase(createPreAuth.fulfilled, (state, action) => {
        state.preAuth = action.payload;
        state.isCreatePreAuthSuccess = true;
        state.isLoading = false;
      })
      .addCase(createPreAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPreAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreatePreAuthSuccess = false;
        state.error = action.payload.stack.message;
      });
    builder
      .addCase(resubmitPreAuth.fulfilled, (state, action) => {
        state.preAuth = action.payload;
        state.isCreatePreAuthSuccess = true;
        state.isLoading = false;
      })
      .addCase(resubmitPreAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resubmitPreAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreatePreAuthSuccess = false;
        state.error = get(action, 'payload.response.data.stack.message');
      })
      .addCase(getHistoryTotal.fulfilled, (state, action) => {
        state.totals = action.payload;
        state.isLoading = false;
      })
      .addCase(getHistoryTotal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistoryTotal.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  errorPatientCleanup,
  createPatientCleanup,
  updatePatientCleanup,
  createPreAuthCleanup,
  getPreAuthCleanup,
  cancelPreAuthCleanup,
  communicationRequestCleanup,
  errorPreAuthCleanup,
} = preAuthV2Slice.actions;
export const preAuthV2Select = (state: RootState) => state.preAuthV2;
export default preAuthV2Slice.reducer;
