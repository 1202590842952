import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export interface ComponentProps {
  value?: any;
  name?: string;
  label?: string;
  variant?: 'primary' | 'secondary';
  onChange?: (value: any | undefined) => void;
  isError?: boolean;
  isTouched?: boolean;
  minDate?: Date;
  maxDate?: Date;
  hasError?: boolean;
}

export const useStylesFromThemeFunction = (variant: string) => {
  return createUseStyles((theme: KlaimTheme) => {
    return {
      calendar: {
        width: '100%',
        fontSize: '14px !important',
        '& .react-date-picker__wrapper': {
          backgroundColor: `${
            variant === 'primary' ? theme.container.background.primary : theme.container.background.secondary
          } !important`,
          padding: '2px 8px',
          height: 42,
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '10px',
          borderColor: theme.formElement.border.primary,
          '& .react-date-picker__inputGroup': {
            '& .react-date-picker__inputGroup__divider': {
              padding: 0,
            },
            '& .react-date-picker__inputGroup__input': {
              '&:focus': {
                border: 'none',
                background: 'none',
              },
            },
            '& .react-date-picker__inputGroup__day': {
              '&:focus': {
                border: 'none',
                background: 'none',
              },
            },
            '& .react-date-picker__inputGroup__month': {
              '&:focus': {
                border: 'none',
                background: 'none',
              },
            },
            '& .react-date-picker__inputGroup__year': {
              '&:focus': {
                border: 'none',
                background: 'none',
              },
            },
          },
          '& .react-date-picker__clear-button': {},
          '& .react-date-picker__calendar-button': {
            '& svg': {
              '& rect': {},
              '& line': {},
            },
          },
          '& .react-date-picker__button': {},
        },
        '& span': {
          '& .react-date-picker__calendar ': {
            backgroundColor: 'red',
          },
          '& .react-date-picker__calendar--open': {
            // backgroundColor: 'blue',
            '& .react-calendar': {
              width: 400,
              maxWidth: 'none',
              border: 'none',
              borderRadius: '0.375rem',
              '--tw-shadow': '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
              'box-shadow':
                'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
              '& .react-calendar__navigation': {
                '& .react-calendar__navigation__next-button': {
                  backgroundColor: theme.formButton.background.primary,
                  color: theme.formButton.color.tertiary,
                  fontSize: 20,
                },
                '& .react-calendar__navigation__prev-button': {
                  backgroundColor: theme.formButton.background.primary,
                  color: theme.formButton.color.tertiary,
                  fontSize: 20,
                },
                '& .react-calendar__navigation__next2-button': {
                  display: 'none',
                },
                '& .react-calendar__navigation__prev2-button': {
                  display: 'none',
                },
                '& .react-calendar__navigation__label': {
                  backgroundColor: theme.formButton.background.primary,
                  '& .react-calendar__navigation__label__labelText': {
                    fontSize: 28,
                    fontWeight: 500,
                    color: theme.formButton.color.tertiary,
                  },
                  '& .react-calendar__navigation__label__labelText--from': {},
                },
              },
              '& .react-calendar__viewContainer': {
                '& .react-calendar__month-view': {
                  '& div': {
                    '& div': {
                      '& .react-calendar__month-view__days__day:disabled': {
                        opacity: 0.5,
                      },
                      '& .react-calendar__month-view__weekdays': {},
                      '& .react-calendar__tile--now': {
                        borderRadius: '0.375rem',
                      },
                      '& .react-calendar__month-view__days': {
                        '& .react-calendar__month-view__days__day--neighboringMonth': {
                          opacity: 0.75,
                        },
                        '& .react-calendar__month-view__days__day--weekend': {
                          color: `${theme.formButton.color.error} !important`,
                        },
                        '& .react-calendar__month-view__days__day': {
                          color: theme.formButton.color.default,
                        },
                        '& .react-calendar__tile--active, .react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd, .react-calendar__tile--rangeBothEnds': {
                          backgroundColor: theme.formButton.background.secondary,
                          color: theme.formButton.color.primary,
                          borderRadius: '0.375rem',
                        },
                      },
                    },
                  },
                },
                '& .react-calendar__year-view, .react-calendar__decade-view, .react-calendar__century-view': {
                  '& div': {
                    display: 'grid !important',
                    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                    gap: ' 1.5rem',
                    padding: '1rem',
                    '& .react-calendar__tile ': {
                      backgroundColor: theme.formButton.background.tertiary,
                      color: theme.formButton.color.default,
                      borderRadius: '0.375rem',
                      padding: '0.25rem',
                      maxWidth: '100% !important',
                    },
                    '&  .react-calendar__tile--hasActive ': {
                      backgroundColor: theme.formButton.background.secondary,
                      color: theme.formButton.color.secondary,
                      '& abbr': {},
                    },
                    '& ..react-calendar__tile react-calendar__tile--now': {},
                    '& .react-calendar__year-view__months__month': {
                      '& abbr': {},
                      '& button': {},
                    },
                  },
                },
              },
            },
          },
          padding: 10,
        },
      },
      inputContainerDefault: {
        '& .react-date-picker__wrapper': {
          backgroundColor: theme.formElement.background.primary,
          borderColor: theme.formElement.border.primary,
        },
      },
      inputContainerValidated: {
        '& .react-date-picker__wrapper': {
          borderColor: theme.formElement.border.validated,
        },
      },
      inputContainerError: {
        '& .react-date-picker__wrapper': {
          borderColor: theme.formElement.border.error,
        },
      },
      labelError: {
        color: theme.formElement.color.error,
      },
      labelValidated: {
        color: theme.formElement.color.validated,
      },
    };
  })();
};
