import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Eligibility/components/OptionTabs/OptionTabs';
import ViewMemberIcon from 'assets/component/ViewMemberIcon';
import {Colors} from 'modules/Common/constants/Colors';
import DeleteMemberIcon from 'assets/component/DeleteMemberIcon';
import ClaimIcon from 'assets/component/ClaimIcon';
import EditMemberIcon from 'assets/component/EditMemberIcon';
import SuspendIcon from 'assets/component/SuspendIcon';
import PaIcon from 'assets/component/PaIcon';
import {RouteComponentProps} from 'react-router-dom';

interface Options {
  iconLeft: JSX.Element;
  title: string;
}

interface ComponentProps {
  positionX: number;
  positionY: number;
  onViewClick?: (id: string) => void;
  id?: string;
}

const OptionTabs: React.FC<ComponentProps> = ({positionX, positionY, onViewClick, id}) => {
  const classes = useStylesFromThemeFunction();
  const [hover, setHover] = React.useState<Options | undefined>(undefined);

  const options: Options[] = [
    {
      iconLeft: <ViewMemberIcon fill={hover?.title === 'View Eligibility' ? Colors.purple : undefined} />,
      title: 'View Eligibility',
    },
    {
      iconLeft: <PaIcon fill={hover?.title === 'Continue to PA' ? Colors.purple : undefined} />,
      title: 'Continue to PA',
    },
    {
      iconLeft: <ClaimIcon fill={hover?.title === 'Create Claim' ? Colors.purple : undefined} />,
      title: 'Create Claim',
    },
    {
      iconLeft: <EditMemberIcon fill={hover?.title === 'Edit Patient' ? Colors.purple : undefined} />,
      title: 'Edit Patient',
    },
    {
      iconLeft: <SuspendIcon fill={hover?.title === 'Suspend' ? Colors.purple : undefined} />,
      title: 'Suspend',
    },
    {
      iconLeft: <DeleteMemberIcon fill={hover?.title === 'Delete' ? Colors.purple : undefined} />,
      title: 'Delete',
    },
  ];

  return (
    <div className={classes.container} style={{left: positionX - 435, top: positionY - 115}}>
      <div className={classes.contentContainer}>
        {options &&
          options.map((option: Options, i: number) => (
            <div
              onMouseEnter={() => setHover(option)}
              onMouseLeave={() => setHover(undefined)}
              className={`${hover?.title === option.title ? classes.hoverButton : classes.defaultButton} ${
                classes.optionsContainer
              }`}
              onClick={() => {
                if (option.title === 'View Eligibility' && onViewClick) onViewClick(id || '');
              }}
              role="button"
            >
              <div className={classes.iconContainer}>{option.iconLeft}</div>
              <p>{option.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OptionTabs;
