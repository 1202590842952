import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from 'services/axios';
import {RootState} from 'modules/App/store';
import {MyKnownError} from 'interfaces/error-interface';
import {Insurance, InsuranceData, InsuranceQuery} from 'interfaces/insurance.interface';
import {providerId} from '../../../MidTable/features/MidTableList/data';

interface InitialState {
  insurances: {
    data: Insurance[];
    count: number;
    result: number;
  };
  totals: any;
  insurance?: Insurance;
  isLoading: boolean;
  error: any;
  apiCallSuccess: boolean;
}

const initialState: InitialState = {
  insurances: {
    data: [],
    count: 0,
    result: 0,
  },
  totals: {},
  insurance: undefined,
  isLoading: false,
  error: undefined,
  apiCallSuccess: false,
};

export const getInsurances = createAsyncThunk<InsuranceData, InsuranceQuery, {rejectValue: MyKnownError}>(
  'insurances',
  async (query: InsuranceQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/insurances', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const getInsurance = createAsyncThunk<InsuranceData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'insurance',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/insurances/${query.id}`, {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const postInsurance = createAsyncThunk<Insurance, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'postInsurance',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/insurances?providerId=${data.providerId}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const patchInsurance = createAsyncThunk<Insurance, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'patchInsurance',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/insurances/${data.id}?providerId=${data.providerId}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const deleteInsurance = createAsyncThunk<Insurance, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteInsurance',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/insurances/${data.id}?providerId=${data.providerId}`, {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const insurancesManagementSlice = createSlice({
  name: 'insurancesManagementt',
  initialState,
  reducers: {
    errorCleanUp: (state) => {
      state.isLoading = false;
      state.error = undefined;
      state.apiCallSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsurances.fulfilled, (state, action: any) => {
        state.insurances = {
          data: action.payload.data,
          count: action.payload.results,
          result: action.payload.result,
        };
        state.isLoading = false;
      })
      .addCase(getInsurances.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurances.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getInsurance.fulfilled, (state, action: any) => {
        state.insurance = action.payload;
        state.isLoading = false;
      })
      .addCase(getInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurance.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(postInsurance.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postInsurance.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(patchInsurance.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(patchInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchInsurance.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteInsurance.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInsurance.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {errorCleanUp} = insurancesManagementSlice.actions;

export const insurancesManagementSelect = (state: RootState) => state.settings.insuranceManagement;
export default insurancesManagementSlice.reducer;
