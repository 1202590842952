import React from 'react';
import * as Yup from 'yup';
import {FormikHelpers} from 'formik';
import {useHistory} from 'react-router';
import invalidLinkBanner from 'assets/invalid_link_banner.svg';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/components/InvalidVerificationLink/styles';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import KlaimForm from 'modules/Common/components/KlaimForm';
import Button from 'modules/Common/components/Button';
import KlaimInput from 'modules/Common/components/KlaimInput';
import {resendVerificationLink} from 'modules/Auth/v3/features/Verify/verifySlice';
import * as Toast from 'modules/Common/utils/toast';

interface Values {
  [field: string]: any;
}

const InvalidVerificationLink: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
  });

  const onSubmit = (values: Values) => {
    resendVerificationLink({email: values.email}).then((res) => {
      if (res.success) {
        Toast.success(res.message, 'Success');
        const to = setTimeout(() => {
          clearTimeout(to);
          history.push('/auth');
        }, 3000);
      } else {
        Toast.error(res.message, 'Email Not Found!');
      }
    });
  };

  return (
    <div className={classes.expiredLink}>
      <div className={classes.subExpiredLink}>
        <div className={classes.headerContainer}>
          <h1>Invalid Verification Link</h1>
          <p>Sorry this link seems to be invalid or expired.</p>
        </div>
        <div className={classes.imageContainer}>
          <img src={invalidLinkBanner} alt="invalid-link" />
        </div>
        <p className={classes.message}>
          Please enter your email below to receive a new link or <KlaimSpan>Contact Support</KlaimSpan>
        </p>
        <div className={classes.footerContainer}>
          <KlaimForm validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
            <KlaimInput
              name="email"
              label="Email"
              inputContainerClassName={classes.emailInput}
              placeholder="mail@example.com"
            />
            <Button title="Resend Link" type="submit" buttonClassName="resendButton" />
          </KlaimForm>
        </div>
      </div>
    </div>
  );
};

export default InvalidVerificationLink;
