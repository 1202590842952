import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    rowGap: '1rem',
    margin: 20,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      fontSize: 14,
      color: Colors.purple,
    },
  },
  removeContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 10,
  },
  descriptionContainer: {
    fontSize: 11,
  },
  addedContainer: {
    display: 'grid',
    rowGap: '0.5rem',
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    '& hr': {
      flex: 1,
      border: `1px solid ${Colors.grayLight}`,
      height: 0,
    },
    '& span': {
      width: '5%',
      textAlign: 'center',
      fontSize: 12,
    },
  },
  subActivityDetailsContainer: {
    display: 'grid',
    rowGap: '1rem',
    fontSize: 12,
    margin: 20,
    '& h3': {fontSize: 12},
  },
  flexContainer: {
    display: 'flex',
    '& div': {
      flex: 1,
    },
  },
});
