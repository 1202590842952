import React from 'react';
import Select from 'react-select';
import {
  ComponentProps,
  customStyles,
  useStylesFromThemeFunction,
} from 'modules/Common/components-v2/KlaimForm/components/KlaimDropdownSearch/KlaimDropdownSearch';

const KlaimDropdownSearch: React.FC<ComponentProps> = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  isLoading,
  isClearable = true,
  disabled = false,
  isOptional = false,
  variant = 'primary',
  isError = false,
  isTouched = false,
  onInputChange,
  style,
  initialValue,
  isMulti = false,
}) => {
  const classes = useStylesFromThemeFunction();

  const handleOnChange = (e: any) => {
    if (onChange) {
      if (Array.isArray(e)) {
        onChange(
          e.map((data) => {
            return data.value;
          }),
        );
      } else {
        onChange(e && e.value ? e.value : undefined);
      }
    }
  };

  const handleInputChange = (newValue: string) => {
    if (onInputChange) onInputChange(newValue);
  };

  const [isFocus, setIsFocus] = React.useState(false);
  return (
    <div className={classes.container} style={style}>
      {label && (
        <div className={classes.labelContainer}>
          <div
            className={`${classes.klaimSelectLabel} ${isError && classes.labelError} ${
              value && isTouched && !isError && classes.labelSuccess
            }`}
          >
            {label}
            {isMulti && (
              <span style={{fontWeight: 'normal', fontSize: 11, marginLeft: 4}}>(You can select multiple)</span>
            )}
          </div>
          {isOptional && <span style={{fontWeight: 'normal', fontSize: '13px'}}>(Optional)</span>}
        </div>
      )}
      <Select
        onInputChange={handleInputChange}
        styles={customStyles({variant, isFocus, isError, isTouched})}
        isClearable={isClearable}
        options={options}
        onChange={handleOnChange}
        value={options.find((o: any) => o.value == value)}
        defaultValue={initialValue}
        placeholder={placeholder}
        isLoading={isLoading}
        isDisabled={disabled}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          setIsFocus(false);
        }}
        isMulti={isMulti}
      />
    </div>
  );
};

export default KlaimDropdownSearch;
