import styled from 'styled-components';
import redMark from 'assets/red_mark.svg';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 0.5px 2px #60617029;
  border-radius: 10px;
  opacity: 1;
  padding: 38px;
  &.table-card {
    padding: 8px 24px;
  }
`;

export const Title = styled.div`
  font: normal normal bold 22px/34px Poppins;
  letter-spacing: 0.22px;
  color: #2c273c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  svg {
    transition: ease 0.3s;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;
export const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  &.index {
    flex-grow: 0.04;
  }
  &.item {
    flex-grow: 0.13;
  }
  &.grow {
    flex-grow: 0.83;
  }
  &.expand {
    flex-grow: 0.93;
  }
  &.label {
    font: normal normal medium 10px/10px Poppins;
    font-size: 10px;
    letter-spacing: 1px;
    color: #696f81;
  }
  &.value {
    font: normal normal normal 10px/10px Poppins;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #3f465d;
  }
  &.bold {
    font: normal normal medium 10px/10px Poppins;
    font-size: 14px;
    letter-spacing: 1px;
    color: #222b45;
  }
  &.id {
    flex-grow: 0.08;
  }
  &.one {
    flex-grow: 0.1;
  }
  &.small {
    flex-grow: 0.04;
  }
  &.medium {
    flex-grow: 0.06;
  }
  &.index {
    flex-grow: 0.05;
  }
  &.large {
    flex-grow: 0.43;
  }
  &.ml {
    padding-left: 16px;
  }
  &.center {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding-left: 8px;
  }
  :empty {
    :after {
      content: '---';
    }
  }
`;

export const Status = styled.span`
  padding: 6px 4px;
  border-radius: 5px;
  font: normal normal medium 13px/10px Poppins;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 80px;
  align-items: center;
  justify-content: center;

  &.approved {
    color: #33e5be;
    background-color: #cff5ed;
  }

  &.neutral {
    color: #6135fb;
    background-color: #beb2f6;
  }

  &.rejected {
    background-color: #ffa7a7;
    color: #e64444;

    ::before {
      content: ' ';
      width: 16px;
      height: 16px;
      display: block;
      background-image: url(${redMark});
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeeafb;
  margin: 28px 0;
  &.small {
    margin: 16px 0;
    width: 100vw;
    margin-left: -32px;
  }
  &.header {
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: -32px;
    width: 100vw;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.justify-left {
    justify-content: left;
  }
  &.mt-38 {
    margin-top: 38px;
  }
`;

export const Label = styled.span`
  font: normal normal 600 10px/10px Poppins;
  letter-spacing: 1.5px;
  color: #696f81cc;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const Value = styled.span`
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0.42px;
  color: #222b45;
  :empty {
    :after {
      content: '---';
    }
  }
  min-height: 10px;
`;

export const TimeLine = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 16px;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`;
export const TimeLineItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 22px;
  border-radius: 8px;
  box-shadow: 0 2px 8px #d0d4db76;
  flex-grow: 1;
  margin-bottom: 22px;
  di
`;

export const TimeLineColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
  max-width: 500px;
  align-items: flex-end;
  &.left {
    width: 46%;
    z-index: 2;
    margin-right: -2%;
  }
  &.center {
    width: 4%;
    height: 100%;
    opacity: 0.5;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  &.right {
    width: 46%;
    z-index: 2;
    padding-top: 50px;
    margin-left: -2%;
  }
  .icon {
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
    border-radius: 100px;
    background-color: #64dbf7;
    &.right {
      background-color: #fbce5d;
      transform: rotate(180deg);
    }
    box-shadow: 0px 0.5px 2px #e7ecf5;
    margin-bottom: 14%;
    margin-left: 9px;
    margin-right: 9px;
    padding: 8px;
  }
`;

export const TimeLineCardTitle = styled.div`
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0.5px;
  color: #222b45;
  margin-bottom: 28px;
  text-transform: capitalize;
  p {
    font: normal normal normal 12px/34px Poppins;
    letter-spacing: 0.36px;
    color: #3f465d;
    opacity: 0.87;
  }
`;

export const TimeLineDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #eff2f7;
  box-shadow: 0 0.5px 2px #e7ecf5;
  margin-top: -50px;
`;
