import React from 'react';
import {Switch} from 'react-router';
import {IRoute} from 'interfaces/route.interface';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import Header from 'modules/Header';
import {useAppDispatch} from 'modules/App/store';
import {setNavOptions} from 'modules/Header/headerSlice';
import {NavigationMenuItem} from 'interfaces/header.interfaces';
import {createUseStyles} from 'react-jss';
import PreAuthFormContext from './features/PreAuthFormV2/PreAuthFormContext';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    bodyContainer: {
      width: '100%',
      borderRadius: 10,
      padding: 20,
      overflow: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  };
});

const PreAuthV2: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();

  const navigations: NavigationMenuItem[] = [
    {link: '/pre-auth', title: 'Pre-Auth History'},
    {link: '/pre-auth/form', title: 'Pre-Auth Form', isHidden: true},
  ];

  React.useEffect(() => {
    dispatch(
      setNavOptions({
        navOptions: navigations,
      }),
    );
  }, []);
  return (
    <div className={classes.container} id="pre-auth-container">
      <Header />
      <div className={classes.bodyContainer} id="pre-auth-body-container">
        <PreAuthFormContext>
          <Switch>
            {routes &&
              routes.map(({routeKey, ...route}) => {
                return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
              })}
          </Switch>
        </PreAuthFormContext>
      </div>
    </div>
  );
};

export default PreAuthV2;
