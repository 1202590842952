import {Colors} from 'modules/Common/constants/Colors';
import {klaimTheme as theme} from 'modules/App/App';

export const styles: {[key: string]: React.CSSProperties} = {
  headerContainer: {
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: `1px ${Colors.purpleLight} solid`,
    fontFamily: 'Poppins',
  },
  headerSubContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerButtonContainer: {
    background: 'none',
    border: 'none',
    padding: '10px 0 10px 0',
    cursor: 'pointer',
  },
  headerTextStyle: {
    fontWeight: 500,
    fontSize: 18,
    marginRight: 20,
  },
  footerContainer: {
    borderTop: `1px ${Colors.purpleLight} solid`,
    padding: '20px 30px',
  },
  footerSubContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
  },
  buttonDefault: {
    minWidth: 152,
    minHeight: 42,
    borderRadius: 10,
    fontSize: 14,
    background: 'none',
    border: 'none',
    fontWeight: 500,
    marginLeft: 4,
    marginRight: 4,
    cursor: 'pointer',
  },
  primary: {
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: Colors.red,
    borderColor: Colors.red,
    color: theme.formButton.color.primary,
  },
  primaryOutline: {
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: theme.formButton.background.primary,
    borderColor: Colors.gray,
    color: Colors.gray,
  },
  bodyContainer: {
    flex: 1,
    padding: 11,
    background: Colors.blueGrayLight,
  },
  paragraph: {
    fontSize: 13,
    color: Colors.grayLabel,
  },
};
