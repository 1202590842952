import mixpanel, {Dict} from 'mixpanel-browser';
import {TokenData} from 'services/jwt';

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

const mixpanelActions = {
  init: () => {
    if (mixpanelToken) mixpanel.init(mixpanelToken);
  },
  identify: (user: TokenData) => {
    if (mixpanelToken && user) {
      mixpanel.identify(user.email);
      mixpanel.people.set(
        {
          $name: user.name,
          $email: user.email,
        },
        () => {
          mixpanel.track('User Login', {login: 'User logged in'});
        },
      );
    }
  },
  track: (name: string, action?: Dict | undefined) => {
    if (mixpanelToken) mixpanel.track(name, action);
  },
};

export const events = {
  claims: {
    claimView: 'Claim View',
    claimSubmission: 'Claim Submission',
    claimSaveDraft: 'Claim Save Draft',
    claimDeleteDraft: 'Claim Delete Draft',
    downloadPdf: 'Download PDF',
    downloadXml: 'Download XML',
    claimResubmission: 'Claim Resubmission',
  },
  pageViews: {
    midTableListView: 'midTable List View',
    claimListView: 'Claim List View',
    paymentListView: 'Payment List View',
    reports: 'Report Page view',
    dashboardView: 'Dashboard Overview',
    dashboardRejection: 'Dashboard Rejection',
    dashboardAgeing: 'Dashboard Aging',
    dashboardPayments: 'Dashboard Payments',
    dashboardClinician: 'Dashboard Clinician',
    dashboardCollectionMonth: 'Dashboard Collection-month',
    dashboardCollectionInsurer: 'Dashboard Collection-insurer',

    newClaim: 'New Claim View',
    claimDraft: 'Draft Claims',
    claimDetail: 'Claim Details',
    login: 'Login View',
    erxList: 'Erx List',
    erxDetails: 'Erx Details',
  },
  pharmacy: {
    retrieveErx: 'Retrieve Erx',
    submitPr: 'Submit Pr',
    retrievePa: 'Retrieve Pa',
    cancelPr: 'Cancel Pr',
    dispense: 'Dispense Erx',
  },
  payments: {
    paymentView: 'Payment View',
  },
  downloadReport: 'Report Download',
};

export const actions = {
  claims: {
    claimView: {action: 'Claim viewed'},
    downloadPdf: {action: 'Download claim as PDF'},
    downloadXml: {action: 'Download claim as XML'},
  },
  pharmacy: {
    retrievePa: {action: 'Retrieve Pa'},
  },
  payments: {
    paymentView: {action: 'Payment View'},
  },
};

export const Mixpanel = mixpanelActions;
