import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import PlusButtonIcon from 'assets/plus_icon_with_background.svg';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    cardRow: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    cardRow2: {
      display: 'flex',
      justifyContent: 'flex-end',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    cardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      },
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: `${Colors.CardInfo}`,
      fontWeight: 600,
    },
    cardInfoSection2: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      },
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: `${Colors.CardInfo}`,
      fontWeight: 600,
      marginRight: '60px',
    },
    cardInfoSectionLast: {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 0,
    },
    flexUnset: {
      '& div': {
        flex: 'unset',
      },
    },
    statusColumn: {
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    drugCard: {
      width: '100%',
      border: `1px solid ${Colors.DrugCard}`,
      borderRadius: '10px',
      padding: '28px',
      margin: 10,
    },
    cardRowEnd: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    cardInfoSectionEnd: {
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: `${Colors.CardInfo}`,
      fontWeight: 600,
    },
    buttonRemove: {
      '& span': {
        fontSize: '15px',
        fontWeight: 600,
        letterSpacing: '0.13px',
        marginLeft: 20,
        color: `${Colors.purple}`,
      },
      background: 'none',
      border: '0',
      cursor: 'pointer',
    },
    Image: {
      width: 20,
      height: 20,
      paddingRight: 10,
    },
    titleSearch: {
      paddingLeft: '2%',
    },
    spaceCheckBox: {
      paddingBottom: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    imgSpacing: {
      marginRight: '10px',
    },
    inputSpacing: {
      width: '80%',
      marginRight: '10%',
    },
    genericPrescription: {
      paddingTop: 10,
    },
    prescription: {
      paddingTop: 10,
    },
    drugValue: {
      paddingTop: 10,
    },
    spaceCheckBoxField: {
      paddingLeft: 10,
    },
  };
});
