import React from 'react';
import {FieldArray, getIn} from 'formik';
import {useTranslation} from 'react-i18next';
import {getDiagnosesCode} from 'modules/Claims/api';
import {ClaimDiagnosis, DiagnosesCodes, DIAGNOSIS_TYPE} from 'modules/Claims/api/types';
import SelectOptions from 'modules/Common/components/Select';
import AsyncSearchSelect from 'modules/Common/components/AsyncSearchSelect';
// import * as Toast from '../../../Common/utils/toast';
import Input from '../../../Common/components/Input';

interface ComponentProps {
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  classes: any;
}

const Diagnosis: React.FC<ComponentProps> = ({handleChange, values, errors, touched, setFieldTouched, classes}) => {
  const {t} = useTranslation();
  const newValues = values.diagnoses.map((diagnoseValue: any, index: number) => {
    if (diagnoseValue.dxInfo === undefined) {
      return {...diagnoseValue, dxInfo: {code: '', type: 'Year of Onset'}};
    }
    return diagnoseValue;
  });

  const handleInputChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const getDiagnoseCode = async (search: string) => {
    if (search.length > 0) {
      try {
        const {data} = await getDiagnosesCode(search, values.authorityCode);
        return data.data
          .map((code: DiagnosesCodes) => {
            return {label: `${code.diagnosisCode} - ${code.shortDescription}`, value: code.diagnosisCode};
          })
          .sort((a: DiagnosesCodes, b: DiagnosesCodes) => (a.diagnosisCode > b.diagnosisCode ? 1 : -1));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getLastDiagnoseId = (diagnoses: Array<ClaimDiagnosis>) => {
    return diagnoses[diagnoses.length - 1].diagnoseId;
  };

  return (
    <div className={classes.formSectionElementsWrapper}>
      <h2>{t('Diagnosis')}</h2>
      <FieldArray
        name="diagnoses"
        render={(diagnosesHelper) => (
          <>
            {newValues &&
              newValues &&
              newValues.map((diagnose: ClaimDiagnosis, index: number) => {
                return (
                  <div key={diagnose.diagnoseId}>
                    <div className={`${classes.rowInputs} ${classes.rowInputSelect}`}>
                      <SelectOptions
                        name={`diagnoses[${index}].type`}
                        options={DIAGNOSIS_TYPE}
                        label={t('Type')}
                        onChange={(e) => handleInputChangeSecond(e, values.diagnoses[index], 'type')}
                        value={diagnose.type}
                        selectClassName={classes.rowInput}
                        isClearable={false}
                      />
                      <AsyncSearchSelect
                        label="Code"
                        name={`diagnoses[${index}].code`}
                        className={classes.rowInputRight}
                        placeholder={t('Enter Code')}
                        loadOptions={getDiagnoseCode}
                        value={diagnose.code}
                        defaultValue={diagnose.defaultValue}
                        onChange={(e) =>
                          handleInputChangeSecond(
                            e,
                            values.diagnoses[index],
                            'code',
                            index + 1 === values.diagnoses.length
                              ? diagnosesHelper.push({
                                  type: 'Secondary',
                                  code: '',
                                  diagnoseId: (+getLastDiagnoseId(values.diagnoses) + 1).toString(),
                                })
                              : null,
                          )
                        }
                        error={
                          errors.diagnoses &&
                          errors.diagnoses[index] &&
                          errors.diagnoses[index].code &&
                          getIn(touched, `diagnoses[${index}].code`)
                            ? errors.diagnoses[index].code
                            : null
                        }
                        valueContainerOption={() => {
                          diagnosesHelper.remove(values.diagnoses.indexOf(diagnose));
                        }}
                        isClearable={false}
                      />
                    </div>
                    {diagnose.type === 'Principal' && (
                      <div className={classes.dxContainer}>
                        <Input
                          name={`diagnoses[${index}].dxInfo.code`}
                          label="Year of Onset"
                          value={diagnose.dxInfo.code}
                          placeholder={t('Enter Code')}
                          inputContainerClassName={classes.formElementWithBottomSpacing}
                          onChange={(e) => handleInputChangeSecond(e, newValues[index].dxInfo, 'code')}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </>
        )}
      />
    </div>
  );
};

export default Diagnosis;
