import React from 'react';
import closeIcon from 'assets/close_icon.svg';
import KlaimModal from 'modules/Common/components/KlaimModal';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Settings/features/UserManagement/DeleteUserModal/DeleteUserModal';
import Button from 'modules/Common/components/Button';
import {useAppDispatch} from 'modules/App/store';
import {deleteUser, deleteUserCleanup} from '../userManagementSlice';

const DeleteUserModal: React.FC<ComponentProps> = ({isDeleteUserOpen, setDeleteUserOpen, userId, email}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(deleteUserCleanup());
    };
  });
  return (
    <KlaimModal contentClass={classes.modal} isOpen={isDeleteUserOpen}>
      <div className={classes.deleteUserContainer}>
        <div className={classes.headerContainer}>
          <h1>Delete User</h1>
          <img onClick={() => setDeleteUserOpen(false)} src={closeIcon} alt="Close-icon" />
        </div>
        <div className={classes.messageContainer}>
          <div>
            <h2>Are you sure you want to delete?</h2>
            <p className={classes.nameText}>{email}</p>
          </div>
          <hr />
          <p>User won’t be able to access the organization on the system.</p>
        </div>
        <div className={classes.optionsButtonContainer}>
          <Button title="No, Go back" onClick={() => setDeleteUserOpen(false)} buttonClassName={classes.backButton} />
          <Button
            title="Delete User"
            onClick={() => {
              setDeleteUserOpen(false);
              dispatch(deleteUser({id: userId}));
            }}
          />
        </div>
      </div>
    </KlaimModal>
  );
};

export default DeleteUserModal;
