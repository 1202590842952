import {FC} from 'react';
import ReportFilteringCardContainer from './components/ReportFilteringCard/ReportFilteringCardContainer';

import './Reports.pcss';
import {ReportCreationFields} from './components/ReportFilteringCard/ReportFilteringCard';
import ReportListCardContainer from './components/ReportListCard/ReportListCardContainer';

interface ComponentProps {
  onFindReports: (values: ReportCreationFields) => void;
}

export const Reports: FC<ComponentProps> = ({onFindReports}) => (
  <div className="Reports">
    <ReportFilteringCardContainer onFindReports={onFindReports} />
    <ReportListCardContainer />
  </div>
);

export default Reports;
