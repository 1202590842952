export const Colors = {
  white: '#ffffff',
  black: '#000000',
  blackLight: '#3F3B3B',
  gray: '#999999',
  grayLabel: '#302E38',
  grayInputPlaceholder: '#75757d',
  grayLighter: '#222B457A',
  grayWithOpacity: '#60617029',
  grayLight: '#E7ECF5',
  grayMediumLight: '#D2DDEF',
  graySeparator: '#CCCCCC',
  blueGrayLight: '#F5F7FB',
  blueGrayLightDim: '#EAEEF7',
  blueGrayDarkLight: '#E8F0FE',
  blueGrayDark: '#222b45',
  purple: '#6135FB',
  purpleLight: '#E5DEFF',
  purpleDark: '#222B45',
  red: '#D90B0B',
  teal: '#55F0EA',
  lightGreen: '#41D8B6',
  yellow: '#FCF059',
  timberwolf: '#dcd8d8',
  turquoiseBlue: '#64DBF7',
  grayBackground: '#f9fafc',
  darkGrayishBlue: '#807f86',
  RMSC: {
    bg: '#fff',
    border: '#f4f7fb',
    shadow: '#60617009',
    toastShadow: '#989898',
    downloadBtnShadow: '#60617080',
    hrBorder: '#ccc',
    gray: '#f4f7fb',
    hover: '#f1f3f5',
    selected: '#f4f7fb',
  },
  TimelineCard: '#E7ECF58D',
  DrugCard: '#E8E9F2',
  CardInfo: '#2C273C',
  pinkLight: '#EEEAFB',
  pinkDark: '#2C273C',
  yellowBright: '#F7F764',
  lightRed: '#ff3d71',
  errorBackgroundRed: '#FCEAEB',
  errorTextRed: '#D90B15',
  successBackgroundGreen: '#CFF5ED',
  successTextGreen: '#27C6A2',
  notificationBody: '#83E8F1',
  erxBranchDropdown: '#F5F7FB',
  lighterRed: '#F9EBEB',
  lightDimGreen: '#41AAD2',
  darkViolet: '#1C1342',
};
