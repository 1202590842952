import queryString, {StringifiableRecord} from 'query-string';
import {DASH} from 'modules/Dashboard/api';
import {restAPIRequest} from 'modules/Common/utils/restRequestsService';

export const getPayersDataService = async (queryData: StringifiableRecord) => {
  const request = restAPIRequest<DASH.Payers.Response>();
  const requestUrl = queryString.stringifyUrl({
    query: queryData,
    url: '/payers',
  });
  const data = await request(requestUrl);
  return {data};
};
