import React from 'react';
import {createUseStyles} from 'react-jss';
import {Col, Row} from 'react-grid-system';
import {IActivityForm, ISupportingInfo, ICareTeam, IDiagnosis, IItem} from 'interfaces/pre-auth.interface';
import {
  KlaimCollapsible,
  KlaimCollapsibleHeader,
  KlaimCollapsibleBody,
} from 'modules/Common/components-v2/KlaimCollapsible';
import {KlaimIconButton, KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import RemoveIcon from 'assets/component/RemoveIcon';
import {v4 as uuid} from 'uuid';
import {Colors} from 'modules/Common/constants/Colors';
import {itemSystems} from 'modules/Common/constants/DropdownOptions';
import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import PackageModal from './PackageModal';
import ActivityModal from './ActivityModal';
import KlaimContainer from '../../../Common/components-v2/KlaimContainer';

const useStylesFromThemeFunction = createUseStyles({
  container: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    rowGap: '1rem',
    margin: 20,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      fontSize: 14,
      color: Colors.purple,
    },
  },
  removeContainer: {
    backgroundColor: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
  },
  descriptionContainer: {
    fontSize: 11,
  },
  addedContainer: {
    display: 'grid',
    rowGap: '0.5rem',
  },
});

interface ComponentProps {
  supportingInfos?: ISupportingInfo[];
  activity: IActivityForm[];
  setActivity: React.Dispatch<React.SetStateAction<IActivityForm[]>>;
  handleRemoveActivity?: (value: any, index: number) => void;
  diagnosis?: IDiagnosis[];
  careTeam?: ICareTeam[];
  supportingInfo?: ISupportingInfo[];
  isClaim?: boolean;
  selectedActivityIndex?: number;
  setSelectedActivityIndex?: (index: number) => void;
}

const ActivitySubForm: React.FC<ComponentProps> = ({
  activity,
  setActivity,
  selectedActivityIndex,
  setSelectedActivityIndex,
  ...rest
}) => {
  const classes = useStylesFromThemeFunction();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [packageModal, setPackageModal] = React.useState<boolean>(false);
  const itemId = uuid();

  const handleRemoveActivity = (value: IItem, activityIndex: number) => {
    setActivity(activity.filter((_, i: number) => i !== activityIndex));
  };
  const handleEditActivity = (activityIndex: number) => {
    setSelectedActivityIndex(activityIndex);
    setIsOpen(true);
  };

  const handleActivityChange = (value: IActivityForm) => {
    if (selectedActivityIndex === undefined) {
      setActivity([...activity, value]);
    } else {
      setActivity((state) => {
        const arr = [...state];
        arr[selectedActivityIndex] = {...value};
        return [...arr];
      });
    }
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (!isOpen) if (setSelectedActivityIndex) setSelectedActivityIndex(undefined);
  }, [isOpen]);

  const activityTypeName = (value: any) => {
    const res = itemSystems.find((item: any) => item.value === value);
    if (res) {
      return res;
    }
    return {
      label: '',
    };
  };

  const capitalizeFirstLetter = (value: string) => {
    if (value) return value.charAt(0).toUpperCase() + value.slice(1);
    return '';
  };

  return (
    <div style={{marginTop: 20}}>
      <KlaimContainer variant={'secondary'} style={{padding: 20}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <div>
            <h3 style={{fontSize: 14, fontWeight: 600, color: '#222B45', marginBottom: 8}}>
              Activities{activity.length > 0 && ` (${activity.length})`}
            </h3>
            <p style={{maxWidth: 500, fontSize: 12, color: '#696F81'}}>Add Activities</p>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <KlaimButton variant="link" onClick={() => setIsOpen(true)}>
              Add Activity
            </KlaimButton>
            {/*<KlaimButton variant="link" onClick={() => setPackageModal(true)}>*/}
            {/*  Add Package*/}
            {/*</KlaimButton>*/}
          </div>
        </div>
        <div style={{padding: 10}}>
          {activity
            .map((act) => {
              return {
                ...act,
                item: {
                  ...act.item,
                  informationSequence: rest.supportingInfos
                    ?.map((supportingInfoData, index) => {
                      return {
                        ...supportingInfoData,
                        tempIndex: index,
                      };
                    })
                    .filter((filterData) => filterData.itemId === act.item.itemId)
                    .map((tempData) => tempData.tempIndex),
                },
              };
            })
            .map((act: IActivityForm, index: number) => {
              return (
                <KlaimCollapsible key={index} style={{marginBottom: 10}}>
                  <KlaimCollapsibleHeader>
                    <div className={classes.headerContainer}>
                      <h2>
                        <span>{`${act.item.itemIsPackage ? 'Package' : 'Activity'}`}</span>
                        <span style={{marginLeft: 10, marginRight: 10}}>•</span>
                        <span>{`${activityTypeName(act.item.itemSystem).label}`}</span>
                      </h2>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <KlaimButton
                          style={{
                            minWidth: 'initial',
                            minHeight: 'initial',
                            backgroundColor: '#00000000',
                            color: '#6135FB',
                            borderRadius: 2,
                            border: 0,
                            padding: 6,
                          }}
                          onClick={() => {
                            handleEditActivity(index);
                          }}
                        >
                          Edit
                        </KlaimButton>
                        <KlaimButton
                          style={{
                            minWidth: 'initial',
                            minHeight: 'initial',
                            backgroundColor: '#00000000',
                            color: '#F00',
                            borderRadius: 2,
                            border: 0,
                            padding: 6,
                          }}
                          onClick={() => {
                            handleRemoveActivity(act.item, index);
                          }}
                        >
                          Remove
                        </KlaimButton>
                      </div>
                    </div>
                  </KlaimCollapsibleHeader>
                  <KlaimCollapsibleBody>
                    <div
                      style={{
                        padding: '1rem',
                        marginLeft: '2rem',
                        borderLeft: `2px solid ${Colors.blueGrayDark}`,
                      }}
                    >
                      {act.careTeam &&
                        act.careTeam?.map((team: ICareTeam, teamIndex: number) => {
                          return (
                            <div
                              key={teamIndex}
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                                gap: '1rem',
                              }}
                            >
                              <KlaimValue
                                label={'PRIMARY DOCTOR'}
                                value={`${team.practitioner?.name} ${team.practitioner?.speciality}`}
                                direction={'column'}
                              />
                              <KlaimValue
                                label={'DOCTOR ROLE'}
                                value={`${capitalizeFirstLetter(team.careTeamRoleCode)} ${
                                  team.practitioner?.speciality
                                }`}
                                direction={'column'}
                              />
                              <KlaimValue label={'LICENSE NUMBER'} value={team.practitionerCode} direction={'column'} />
                            </div>
                          );
                        })}
                      {act.item && (
                        <div>
                          <Row nogutter style={{marginTop: 10}}>
                            <Col xs={6} md={2}>
                              <KlaimValue
                                label={'ACTIVITY CODE'}
                                value={act.item.itemCode || ''}
                                direction={'column'}
                              />
                            </Col>
                          </Row>
                          <Row nogutter style={{marginTop: 10}}>
                            <Col xs={6} md={2}>
                              <KlaimValue
                                label={'SHADOW BILLING'}
                                value={act.item.shadowBilling === 'Yes' ? 'Enabled' : 'Disabled'}
                                direction={'column'}
                              />
                            </Col>
                          </Row>
                          <Row nogutter style={{marginTop: 10}}>
                            {act.item.bodySite?.code && (
                              <Col xs={6} md={2}>
                                <KlaimValue
                                  label={'BODY SITE'}
                                  value={act.item.bodySite?.code || ''}
                                  direction={'column'}
                                />
                              </Col>
                            )}

                            {act.item.subSite?.code && (
                              <Col xs={6} md={2}>
                                <KlaimValue
                                  label={'SUB SITE'}
                                  value={act.item.subSite?.code || ''}
                                  direction={'column'}
                                />
                              </Col>
                            )}
                          </Row>

                          <Row nogutter style={{marginTop: 10}}>
                            <Col xs={6} md={2}>
                              <KlaimValue label={'CODE'} value={act.item.itemCode || ''} direction={'column'} />
                            </Col>
                            {act.item.itemQty && (
                              <Col xs={6} md={2}>
                                <KlaimValue
                                  label={'QUANTITY'}
                                  value={act.item.itemQty.toString()}
                                  direction={'column'}
                                />
                              </Col>
                            )}
                            {act.item.itemUnitPrice && (
                              <Col xs={6} md={2}>
                                <KlaimValue
                                  label={'UNIT PRICE'}
                                  value={act.item.itemUnitPrice.toString()}
                                  direction={'column'}
                                />
                              </Col>
                            )}
                            {act.item.itemTax && (
                              <Col xs={6} md={2}>
                                <KlaimValue
                                  label={'ITEM TAX'}
                                  value={act.item.itemTax.toString()}
                                  direction={'column'}
                                />
                              </Col>
                            )}
                            {act.item.itemPatientShare && (
                              <Col xs={6} md={2}>
                                <KlaimValue
                                  label={'PATIENT SHARE'}
                                  value={act.item.itemPatientShare.toString()}
                                  direction={'column'}
                                />
                              </Col>
                            )}
                          </Row>
                        </div>
                      )}
                    </div>
                  </KlaimCollapsibleBody>
                </KlaimCollapsible>
              );
            })}
        </div>
        <ActivityModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={handleActivityChange}
          itemId={itemId}
          selectedActivityIndex={selectedActivityIndex}
          {...rest}
        />
        <PackageModal isOpen={packageModal} setIsOpen={setPackageModal} onSubmit={handleActivityChange} {...rest} />
      </KlaimContainer>
    </div>
  );
};

export default ActivitySubForm;
