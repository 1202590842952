import _ from 'lodash';
import {IOptions} from 'interfaces/general.interfaces';
import {supportingInfoCategory} from '../modules/Common/constants/DropdownOptions/index';

export const getOptionLabel = (value: string, options: IOptions[]): string => {
  const res = options.find((item: IOptions) => item.value === value);
  if (res) {
    return res.label;
  }
  return '';
};

export const removeEmptyProperty = (object: any) => {
  return Object.keys(object)
    .filter((k) => {
      if (typeof object[k] === 'number') {
        return true;
      }
      return !_.isEmpty(object[k]);
    })
    .reduce((a, k) => ({...a, [k]: object[k]}), {});
};

export const getSupportInfoTitle = (value: string) => {
  const suppInfo = supportingInfoCategory.find((supp) => supp.value === value);
  if (suppInfo) {
    return suppInfo.label;
  }
  return '';
};

export const cleanUpObject = (object) => {
  if (Object.entries(object) && Object.entries(object).length && Object.entries(object).length > 0)
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        cleanUpObject(v);
      }
      if (
        (v && typeof v === 'object' && !Object.keys(v).length) ||
        v === null ||
        v === undefined ||
        v === '' ||
        v === 0
      ) {
        if (Array.isArray(object)) {
          object.splice(parseFloat(k), 1);
        } else {
          delete object[k];
        }
      }
    });
  return object;
};
