import React from 'react';
import * as Yup from 'yup';
import {Row, Col} from 'react-grid-system';
import {useSelector} from 'react-redux';
import {FormikHelpers} from 'formik';
import {useHistory} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {
  insuranceCompanyManagementSelect,
  createInsurance,
  getTPAs,
  getInsurers,
  Payer,
  createCleanup,
  errorCleanup,
} from 'modules/Settings/features/InsuranceCompanies/insuranceManagementSlice';
import {KlaimForm, KlaimFieldWrapper, KlaimInput, KlaimMultiSelect} from 'modules/Common/components-v2/KlaimForm';
import {OptionValue} from 'modules/Header/headerSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import * as Toast from 'modules/Common/utils/toast';
import InsuranceDropdown from 'modules/Insurance/features/InsuranceDropdown';
import {useStylesFromThemeFunction} from './insuranceCompaniesAdd';
import {branchManagementSelect, getBranches} from '../../BranchManagement/branchManagementSlice';

export interface IValues {
  [field: string]: any;
}

interface InsuranceValues {
  active?: boolean;
  name: string;
  tpas: string[];
  branches: {label: string; value: string}[];
  insurance: {label: string; value: string}[];
}

const Component: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {error, insuranceData, isCreated, isLoading, insurers, tpas, tempError} = useSelector(
    insuranceCompanyManagementSelect,
  );

  const [selectedInsurers, setSelectedInsurers] = React.useState<any>({});
  const [selectedTPAs, setSelectedTPAs] = React.useState<Array<any>>([]);
  const {branches} = useAppSelector(branchManagementSelect);
  const initialValues = {
    active: true,
    name: '',
    tpas: [],
    insurance: {},
    branches: [],
  };

  const validationSchema = Yup.object({
    active: Yup.boolean(),
    name: Yup.string().required('Name is required'),
    tpas: Yup.array(Yup.string()),
    insurance: Yup.object().nullable().required('Insurance is required'),
    branches: Yup.array().required('Branch is required'),
  });

  const onSubmit: (
    values: IValues,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: IValues, formikHelpers) => {
    const newTpas = selectedTPAs.map((tpa) => tpa.value);

    const payload = {
      active: true,
      // specialityCode: '12312311111',
      branches: values.branches.map((branch: any) => {
        return {id: parseInt(branch.value, 10)};
      }),
      name: values.name,
      insurances: [
        {
          code: selectedInsurers.payerCode,
          name: selectedInsurers.name,
          tpas: newTpas,
        },
      ],
    };

    dispatch(createInsurance({values: payload, formikHelpers}));
  };

  const handleInsuranceChange = (e: any) => {
    setSelectedInsurers(e);
  };

  React.useEffect(() => {
    dispatch(getBranches({}));
    dispatch(getInsurers({classification: 'Insurance'}));
    dispatch(getTPAs({}));
    return () => {
      dispatch(errorCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (tempError) {
      Toast.error(tempError.message);
    }
  }, [tempError]);

  React.useEffect(() => {
    if (isCreated === true) {
      Toast.success('Insurance is added successfully', 'Success');
      history.push('/settings/insurance-companies');
    }
    return () => {
      dispatch(createCleanup());
    };
  }, [isCreated]);

  return (
    <KlaimForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      <Header
        title="Add Insurance"
        backlink="/settings/insurance-companies"
        description={<p>Add additional insurance companies that have not been listed</p>}
        buttonType="submit"
        buttonTitle="Create"
        body={
          <div className={classes.bodyWrapper}>
            {isLoading ? (
              <div>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {/* {error && (
                  <div className={classes.errorMessage}>{error.stack ? error.stack.error.message : error.message}</div>
                )} */}
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={12}>
                        <KlaimFieldWrapper name="name">
                          <KlaimInput variant="secondary" label="Insurance Name" placeholder="34567468" />
                        </KlaimFieldWrapper>
                      </Col>
                      <Col md={12}>
                        <KlaimFieldWrapper name={'branches'}>
                          <KlaimMultiSelect
                            options={
                              branches.data.branches
                                ? branches.data.branches.map((branch: any) => {
                                    return {
                                      label: branch.name,
                                      value: branch.id.toString(),
                                    };
                                  })
                                : []
                            }
                            placeholder={'Select Branch'}
                            label="Choose Branch"
                          />
                        </KlaimFieldWrapper>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <KlaimFieldWrapper name="tpas" onChange={(e) => setSelectedTPAs(e)}>
                          <KlaimMultiSelect
                            optional
                            label="Select TPA/Receiver"
                            options={tpas.map((item: Payer) => {
                              const x: OptionValue = {label: item.name || '-', value: item.payerCode || '-'};
                              return x;
                            })}
                          />
                        </KlaimFieldWrapper>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <KlaimFieldWrapper name={'insurance'} onChange={handleInsuranceChange}>
                          <InsuranceDropdown variant="secondary" />
                        </KlaimFieldWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default Component;
