import React, {FC} from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import vs from 'assets/vital-signs.jpg';
import {get} from 'lodash';
import Section from './Section';
import {
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
  KlaimTextArea,
  KlaimDatePicker,
} from '../../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from './ClaimForm';
// import {deleteVitalSigns, patchVitalSigns, postVitalSigns} from '../../../../midTableSlice';

const VitalSigns: FC<any> = ({data, providerId, setSubmission, submission}) => {
  const classes = useStylesFromThemeFunction();
  const init = data;
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    // if (data?.vitalSigns[0]?.id) {
    //   dispatch(
    //     patchVitalSigns({
    //       payload: {...values, providerId: `${providerId}`},
    //       claimId: data.id,
    //       id: data.vitalSigns[0].id,
    //     }),
    //   );
    // } else {
    //   dispatch(postVitalSigns({payload: {...values, providerId: `${providerId}`}, id: data.id}));
    // }
  };

  const DATE_FORMAT = `DD MMM 'YY`;
  const formatDate = (date) => (date ? dayjs(date).format(DATE_FORMAT) : null);

  const extractSupportingInfo = (supportingInfo, category) =>
    get(
      supportingInfo?.filter((el) => el.category === category),
      '[0]',
      {},
    );

  const updateSubmission = (field, valueType, value) => {
    if (submission && submission.claim && submission.claim.supportingInfo) {
      let supportingInfo = [...submission.claim.supportingInfo];
      supportingInfo = supportingInfo.filter((si) => si.category !== field);
      supportingInfo.push({category: field, [valueType]: value});
      setSubmission({
        ...submission,
        claim: {...submission.claim, supportingInfo},
      });
    }
  };
  return (
    <Section
      title={'Vital Signs'}
      img={vs}
      // headerRight={
      //   <div className={classes.buttonsRow}>
      //     <button
      //       className={classes.delete}
      //       onClick={() => {
      //         // dispatch(deleteVitalSigns({id: data.id}));
      //       }}
      //     >
      //       Delete
      //     </button>
      //     {data?.vitalSigns?.id ? (
      //       <button className={classes.update} form={'vital-form'}>
      //         Update
      //       </button>
      //     ) : (
      //       <button className={classes.save} form={'vital-form'}>
      //         Save
      //       </button>
      //     )}
      //   </div>
      // }
    >
      <KlaimForm
        enableReinitialize
        initialValues={{
          significantSign: extractSupportingInfo(init, 'info').valueString,
          chiefComplaint: extractSupportingInfo(init, 'chief-complaint').text,
          internalNotes: extractSupportingInfo(init, 'info').valueString,
          temperature: extractSupportingInfo(init, 'temperature').valueQuantity,
          respiratoryRate: extractSupportingInfo(init, 'respiratory-rate').valueQuantity,
          bloodPressureDiastolic: extractSupportingInfo(init, 'vital-sign-diastolic').valueQuantity,
          bloodPressureSystolic: extractSupportingInfo(init, 'vital-sign-systolic').valueQuantity,
          height: extractSupportingInfo(init, 'vital-sign-height').valueQuantity,
          weight: extractSupportingInfo(init, 'vital-sign-weight').valueQuantity,
          pulse: extractSupportingInfo(init, 'pulse').valueQuantity,
          // durationOfIllness: init.durationOfIllness,
          lmp: new Date(extractSupportingInfo(init, 'last-menstrual-period').valueQuantity || null),
        }}
        validationSchema={Yup.object({
          significantSign: Yup.string(),
          internalNotes: Yup.string(),
          temperature: Yup.number(),
          respiratoryRate: Yup.string(),
          bloodPressure: Yup.string(),
          chiefComplaint: Yup.string(),
          height: Yup.string(),
          weight: Yup.string(),
          pulse: Yup.string(),
          // durationOfIllness: Yup.string(),
          lmp: Yup.string(),
        })}
        onSubmit={handleSubmit}
        id="vital-form"
        customClass={classes.form}
      >
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            onChange={(e) => updateSubmission('info', 'valueString', `Duration of Stay: ${e}`)}
            className={classes.respFieldWrapper}
            name={'durationOfIllness'}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Duration of Stay" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="temperature"
            onChange={(e) => updateSubmission('temperature', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
            type={'number'}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="TEMP" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="bloodPressureDiastolic"
            onChange={(e) => updateSubmission('vital-sign-diastolic', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
          >
            <KlaimInput variant="secondary" placeholder="" label="Blood Pressure Diastolic" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="bloodPressureSystolic"
            onChange={(e) => updateSubmission('vital-sign-systolic', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
          >
            <KlaimInput variant="secondary" placeholder="" label="Blood Pressure Systolic" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="weight"
            onChange={(e) => updateSubmission('vital-sign-weight', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Weight" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="height"
            onChange={(e) => updateSubmission('vital-sign-height', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Height" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="pulse"
            onChange={(e) => updateSubmission('pulse', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Pulse" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="respiratoryRate"
            onChange={(e) => updateSubmission('respiratory-rate', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
          >
            <KlaimInput variant="secondary" placeholder="" label="Resp Rate" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="lmp"
            onChange={(e) => updateSubmission('last-menstrual-period', 'valueQuantity', e)}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimDatePicker label="LMP" variant="secondary" name="lmp" maxDate={new Date()} />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            name="chiefComplaint"
            onChange={(e) => updateSubmission('chief-complaint', 'text', e)}
            className={classes.respFieldWrapper}
          >
            <KlaimTextArea label="Chief Complaint & Main Symptoms" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="significantSign"
            onChange={(e) => updateSubmission('info', 'valueString', e)}
            className={classes.respFieldWrapper}
          >
            <KlaimTextArea label="Significant Signs" />
          </KlaimFieldWrapper>
        </div>
      </KlaimForm>
    </Section>
  );
};

export default VitalSigns;
