import React, {useEffect, useState} from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import {useTranslation} from 'react-i18next';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimFileUploadV2,
  KlaimForm,
} from 'modules/Common/components-v2/KlaimForm';
import * as Yup from 'yup';
import * as Toast from 'modules/Common/utils/toast';
import {useAppSelector} from '../../../../../App/store';
import {branchManagementSelect} from '../../../BranchManagement/branchManagementSlice';
import {uploadBulk} from '../../ContractSlice';
import {IOptions} from '../../../../../../interfaces/general.interfaces';
import {getInsurances, insurancesManagementSelect} from '../../../Insurance/InsuranceSlice';
import {useDispatch} from 'react-redux';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    fileUploadBox: {},
  };
});

interface ComponentsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshList?: any;
}
const BulkUploadModal: React.FC<ComponentsProps> = ({open, setOpen, refreshList}) => {
  const {t} = useTranslation();
  const classes = useStylesFromThemeFunction();
  const [providerId, setProviderId] = useState();
  const [services, setServices] = useState();
  const [uploading, setUploading] = useState(false);
  const [selectedTPA, setSelectedTPA] = React.useState(null);
  const [hic, setHic] = React.useState(null);
  const [hicMongoId, setHicMongoId] = React.useState(null);

  const {
    branches: {
      data: {branches},
    },
  } = useAppSelector(branchManagementSelect);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);

  const {
    insurances: {data: insurances, count: dataCount},
  } = useAppSelector(insurancesManagementSelect);
  const dispatch = useDispatch();

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };
  useEffect(() => {
    if (providerId) {
      dispatch(
        getInsurances({
          limit: 100,
          offset: 0,
          providerId,
        }),
      );
    }
  }, [providerId]);

  React.useEffect(() => {
    if (insurances && insurances.length > 0) {
      setInsuranceOptions(
        insurances.map((payer: any) => {
          return {
            label: `${payer.longName} ${payer.hasTpa ? `(${payer.tpas[0].name})` : ''}`,
            value: `${payer.hcpCode}::${payer._id}`,
          };
        }),
      );
    }
  }, [insurances]);
  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title="Bulk Upload" />
      <KlaimModalBody style={{padding: 20}}>
        <KlaimForm
          enableReinitialize
          validationSchema={Yup.object({})}
          initialValues={{}}
          onSubmit={() => {}}
          id="bulk-upload-form"
          style={{gap: 8, display: 'flex', flexDirection: 'column'}}
        >
          <KlaimDropdownSearch
            options={
              branches
                ? branches.map((branch) => {
                    return {
                      label: branch.name,
                      value: branch.identifier,
                    };
                  })
                : []
            }
            name="branch"
            label="Choose Branch"
            variant={'secondary'}
            onChange={(value) => {
              setProviderId(value);
            }}
          />
          <KlaimFieldWrapper
            name={'hic'}
            onChange={(value) => {
              setHic(value.split('::')[0]);
              setHicMongoId(value.split('::')[1]);
            }}
          >
            <KlaimDropdownSearch variant="secondary" label="HIC/TPA" options={insuranceOptions} />
          </KlaimFieldWrapper>
          <KlaimFileUploadV2
            label={t('Attach Files')}
            className={`${classes.fileUploadBox}`}
            acceptedFileTypes=".xlsx"
            multiple
            onChange={(value: any) => {
              if (value && value.length > 0) {
                setServices(value[0].file);
              }
            }}
            dropAreaText="Drag and drop file"
            percentage={100}
          />
        </KlaimForm>
        <br />
      </KlaimModalBody>
      <KlaimModalFooter
        submitButtonTitle="Upload"
        onSubmit={async () => {
          const insurance = insurances.find((el: any) => el.hcpCode === hic) as any;
          setUploading(true);

          uploadBulk(services, providerId, hicMongoId, insurance.tpas)
            .then(() => {
              Toast.success('Bulk services uploaded', 'Success');
              refreshList();
            })
            .catch(() => Toast.error('An error occurred', 'Upload error'))
            .finally(() => {
              setOpen(false);
              setUploading(false);
            });
        }}
        cancelButtonTitle={'Cancel'}
        submitLoading={uploading}
      />
    </KlaimModal>
  );
};

export default BulkUploadModal;
