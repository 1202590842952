import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  isDeleteUserOpen: boolean;
  setDeleteUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  email: string;
}

export const useStylesFromThemeFunction = createUseStyles({
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteUserContainer: {
    width: 472,
    height: 359,
    borderRadius: 10,
    backgroundColor: Colors.blueGrayLight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerContainer: {
    '& h1': {
      fontSize: 18,
      fontWeight: 'normal',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: Colors.white,
    padding: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  optionsButtonContainer: {
    display: 'flex',
    padding: 25,
    justifyContent: 'flex-end',
    gap: 10,
    backgroundColor: Colors.white,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  backButton: {
    background: Colors.white,
    color: Colors.black,
    border: `1px solid ${Colors.black}`,
  },
  messageContainer: {
    textAlign: 'center',
    color: Colors.blueGrayDark,
    '& h2': {
      fontSize: 18,
      fontWeight: 600,
    },
    '& p': {
      fontSize: 13,
    },
  },
  nameText: {
    color: Colors.purple,
    fontWeight: 600,
  },
});
