import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {},
    scrollContainer: {
      width: '100%',
    },
    containerBody: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '100vh',
      overflow: 'auto',
    },
  };
});
