import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import noDataIMG from 'assets/no_content.svg';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    nodataContainer: {
      width: '100%',
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 30,
    },
  };
});

interface ComponentProps {
  overrideContent?: any | undefined;
}

export const NoData: FC<ComponentProps> = ({overrideContent}) => {
  const classes = useStylesFromThemeFunction();
  const defaultContent = (
    <div className={classes.nodataContainer}>
      <img alt="No Data" src={noDataIMG} />
      <h3>No Data</h3>
      <p>Please check back later or try other filter options</p>
    </div>
  );

  return <>{overrideContent || defaultContent}</>;
};
