import {Field, FieldProps} from 'formik';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {FC} from 'react';
import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    activeBtn: {
      background: '#EAEEF7',
      border: '1px solid #6135FB !important',
      color: '#6135FB !important',
      fontWeight: '500',
    },
    buttonWrapper: {
      alignItems: 'center',
      border: '1px solid #E7ECF5',
      color: '#302E3898',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '15px',
      fontWeight: 600,
      height: '40px',
      justifyContent: 'center',
      width: '100%',
    },
    label: {
      color: '#222b45',
      fontSize: 12,
      fontWeight: theme.font.weightSemibold,
      marginBottom: 3,
      marginLeft: 10,
    },
    typeWrapper: {
      '& div:first-child': {
        borderRadius: '10px 0 0 10px',
      },
      '& div:last-child': {
        borderRadius: '0px 10px 10px 0px',
      },
      background: '#f5f7fb',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  };
});

interface ComponentProps {
  values: Array<TypeOptions>;
  selectedValue?: string;
  setSelectedValue?: (value: string) => void;
  label?: string;
  theme?: KlaimTheme;
  className?: string;
  name: string;
}

interface TypeOptions {
  title: string;
  value: string;
}

const KlaimTypeSelect: FC<ComponentProps> = ({
  values,
  selectedValue,
  setSelectedValue,
  label,
  theme,
  className,
  name,
}) => {
  const classes = useStylesFromThemeFunction({theme});

  const onClick = (value: string) => {
    if (setSelectedValue) setSelectedValue(value);
  };

  return (
    <Field name={name}>
      {({form: {setFieldValue}, field: {value}}: FieldProps) => {
        return (
          <div className={className}>
            {label && <div className={classes.label}>{label}</div>}
            <div className={`${classes.typeWrapper}`}>
              {values?.map((v, index) => {
                return (
                  <div
                    key={index}
                    className={`${selectedValue === v.value || value === v.value ? classes.activeBtn : ''} ${
                      classes.buttonWrapper
                    }`}
                    role="button"
                    onClick={() => {
                      setFieldValue(name, v.value);
                      onClick(v.value);
                    }}
                  >
                    {v.title}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </Field>
  );
};
export default KlaimTypeSelect;
