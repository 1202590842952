import React from 'react';
import DatePicker from 'react-date-picker';
import CalendarIcon from 'assets/calendar_icon.svg';
import {useStylesFromThemeFunction, ComponentProps} from './KlaimDatePicker';
import KlaimLabel from '../KlaimLabel';

const KlaimDatePicker: React.FC<ComponentProps> = (props) => {
  const {
    value = new Date(),
    onChange,
    isError,
    isTouched,
    name,
    label,
    variant = 'primary',
    minDate,
    maxDate,
    hasError,
  } = props;
  const classes = useStylesFromThemeFunction(variant);
  const validateInput = (touched?: boolean, error?: boolean) => {
    if ((touched && error) || hasError) {
      return classes.inputContainerError;
    }
    if (touched) {
      return classes.inputContainerValidated;
    }
    return classes.inputContainerDefault;
  };
  const validateLabel = (touched?: boolean, error?: boolean) => {
    if ((touched && error) || hasError) {
      return classes.labelError;
    }
    if (touched) {
      return classes.labelValidated;
    }
    // return classes.labelDefault;
  };
  return (
    <div>
      {label && <KlaimLabel name={name} label={label} {...props} className={`${validateLabel(isTouched, isError)}`} />}
      <DatePicker
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        className={`${classes.calendar} ${validateInput(isTouched, isError)}`}
        onChange={(date) => onChange(date)}
        value={value}
        calendarIcon={<img src={CalendarIcon} alt="calendar icon" />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default KlaimDatePicker;
