import React from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    buttonReset: {
      display: 'inline-block',
      border: 'none',
      margin: 0,
      textDecoration: 'none',
      backgroundColor: Colors.white,
      fontSize: 14,
      fontWeight: 600,
      cursor: 'pointer',
      textAlign: 'center',
      transition: 'background 250ms ease-in-out transform 150ms ease',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      padding: 6,
      '&:hover': {
        fontSize: 15,
        color: Colors.purple,
        borderBottom: `1px solid ${Colors.purple}`,
      },
    },
    buttonDefault: {
      color: Colors.gray,
      fontSize: 14,
      fontWeight: 600,
    },
    buttonActive: {
      color: Colors.purple,
      borderBottom: `1px solid ${Colors.purple}`,
      fontSize: 14,
      fontWeight: 600,
    },
  };
});

interface IContext {
  index: number;
  setIndex: (index: number) => void;
}

export const TabContext = React.createContext<IContext>({
  index: 0,
  setIndex: () => {},
});
export const KlaimTabs: React.FC = ({children}) => {
  const [index, setIndex] = React.useState(0);

  return <TabContext.Provider value={{index, setIndex}}>{children}</TabContext.Provider>;
};

interface IKlaimTabList {
  grid?: number;
  id?: string;
}
export const KlaimTabList: React.FC<IKlaimTabList> = ({children, grid = 5, id}) => {
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {index: i});
    }
    return child;
  });

  return (
    <div id={id} style={{display: 'grid', gridTemplateColumns: `repeat(${grid}, minmax(0, 1fr))`}}>
      {childrenWithProps}
    </div>
  );
};

interface IKlaimTab {
  index?: number;
  span?: number;
  style?: React.CSSProperties;
}
export const KlaimTab: React.FC<IKlaimTab> = ({index: childIndex = 0, span = 1, children, style}) => {
  const {index, setIndex} = React.useContext(TabContext);
  const classes = useStylesFromThemeFunction();
  return (
    <button
      className={`${classes.buttonReset} ${index === childIndex ? classes.buttonActive : classes.buttonDefault}`}
      style={{gridColumn: `span ${span} / span ${span}`, ...style}}
      onClick={() => {
        setIndex(childIndex);
      }}
    >
      {children || 'Link'}
    </button>
  );
};

interface ComponentProps {
  style?: React.CSSProperties;
  id?: string;
}

export const KlaimTabPanels: React.FC<ComponentProps> = ({children, style, id}) => {
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {index: i});
    }
    return child;
  });

  return (
    <div id={id} style={style}>
      {childrenWithProps}
    </div>
  );
};
export const KlaimTabPanel: React.FC<IKlaimTab> = ({index: childIndex = 0, children, span, style}) => {
  const {index} = React.useContext(TabContext);
  return index === childIndex ? <div style={style}>{children}</div> : null;
};
