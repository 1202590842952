import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {PaymentsData, PaymentsSearchQuery, PaymentsError, InitialState} from './types';

export const getPaymentsList = createAsyncThunk<PaymentsData, PaymentsSearchQuery, {rejectValue: PaymentsError}>(
  'paymentsList/gePaymentsList',
  async (query: PaymentsSearchQuery, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/payments`, {
        params: {
          providerId: query.providerId,
          ...query,
        },
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState: InitialState = {
  data: {
    count: 0,
    data: [],
  },
  isLoading: false,
  error: undefined,
};

const paymentsListSlice = createSlice({
  name: 'paymentsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentsList.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getPaymentsList.fulfilled, (state, action) => {
        state.data.data = action.payload.data;
        state.data.count = action.payload.count;
        state.isLoading = false;
      });
  },
});

export const PaymentsListSelect = (state: RootState) => state.payments.paymentsList;
export default paymentsListSlice.reducer;
