import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      backgroundColor: '#FFFFFF',
      width: '70%',
    },
    imgContainer: {
      display: 'flex',
      padding: 69,
    },
    logoContainer: {
      paddingRight: 69,
      borderRight: `${Colors.grayLight} 2px solid`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '20px',
      height: 250,
    },
    firstDiv: {
      paddingLeft: '5px',
      display: 'flex',
      gap: '10px',
    },
    secondDiv: {
      fontSize: '0.75rem',
      paddingLeft: '45px',
      '& hr': {
        height: 5,
        border: 'none',
        background: 'linear-gradient(90deg, rgba(97, 53, 251, 1) 50%, rgba(65, 216, 182, 1) 50%)',
      },
      '& h1': {
        fontSize: 25,
        color: Colors.grayLabel,
        marginBottom: '0px',
      },
      '& h2': {
        color: Colors.lightDimGreen,
        fontWeight: 'normal',
        marginTop: 0,
      },
    },
    thirdDiv: {
      display: 'flex',
      gap: '20px',
      flexBasis: '50%',
      marginTop: '25px',
      alignItems: 'center',
    },
    forthDiv: {
      display: 'flex',
      gap: '20px',
      marginTop: '20px',
      alignItems: 'center',
    },
    contactsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: 16,
      color: Colors.grayLighter,
    },
    fifthDiv: {
      fontSize: '18px',
      padding: 10,
      color: Colors.white,
      textAlign: 'center',
      fontWeight: 500,
      background: 'linear-gradient(0deg, rgba(85, 190, 202, 1) 5%, rgba(87, 66, 239, 1) 85%)',
    },
    sixDiv: {
      padding: '40px 100px 40px 40px',
      fontSize: '10px',
    },
    informationColor: {
      color: Colors.darkViolet,
    },
  };
});
