export const permissions = {
  download_remittance_report: {
    module: 'Reports',
    name: 'Download Remittance Report',
    description: 'Allows the user download the Remittance Report',
    identifier: 'download_remittance_report',
  },
  download_settlement_report: {
    module: 'Reports',
    name: 'Download Settlement Report',
    description: 'Allows the user to download the Settlement Report',
    identifier: 'download_settlement_report',
  },
  download_rejection_report: {
    module: 'Reports',
    name: 'Download Rejection Report',
    description: 'Allows the user to download the Rejection Report',
    identifier: 'download_rejection_report',
  },
  download_clinicians_report: {
    module: 'Reports',
    name: 'Download Clinicians Report',
    description: 'Allows the user to download the Clinicians Report',
    identifier: 'download_clinicians_report',
  },
  download_payments_report: {
    module: 'Reports',
    name: 'Download Payments Report',
    description: 'Allows the user to download the Payments Report',
    identifier: 'download_payments_report',
  },
  download_ageing_report: {
    module: 'Reports',
    name: 'Download Ageing Report',
    description: 'Allows the user to download the Ageing Report',
    identifier: 'download_ageing_report',
  },
  view_overview_dashboard: {
    module: 'Dashboards',
    name: 'View Overview Dashboard',
    description: 'Allows the user to view the Overview Dashboard',
    identifier: 'view_overview_dashboard',
  },
  view_rejections_dashboard: {
    module: 'Dashboards',
    name: 'View Rejections Dashboard',
    description: 'Allows the user to view the Rejections Dashboard',
    identifier: 'view_rejections_dashboard',
  },
  view_aging_dashboard: {
    module: 'Dashboards',
    name: 'View Ageing Dashboard',
    description: 'Allows the user to view the Ageing Dashboard',
    identifier: 'view_aging_dashboard',
  },
  view_clinicians_dashboard: {
    module: 'Dashboards',
    name: 'View Clinicians Dashboard',
    description: 'Allows the user to view the Clinicians Dashboard',
    identifier: 'view_clinicians_dashboard',
  },
  view_payments_dashboard: {
    module: 'Dashboards',
    name: 'View Payments Dashboard',
    description: 'Allows the user to view the Payments Dashboard',
    identifier: 'view_payments_dashboard',
  },
  view_collections_dashboard: {
    module: 'Dashboards',
    name: 'View Collections Dashboard',
    description: 'Allows the user to view the Collections Dashboard',
    identifier: 'view_collections_dashboard',
  },
  list_claims: {
    module: 'Claims',
    name: 'List Claims',
    description: 'Allows the user to view a list of all claims',
    identifier: 'list_claims',
  },
  view_claim_details: {
    module: 'Claims',
    name: 'View Claim Details',
    description: 'Allows the user to view the details of a claim',
    identifier: 'view_claim_details',
  },
  create_claims: {
    module: 'Claims',
    name: 'Create Claim',
    description: 'Allows the user to create a new claim, save the claim as a draft, or edit draft claims',
    identifier: 'create_claims',
  },
  submit_claim: {
    module: 'Claims',
    name: 'Submit Claim',
    description: 'Allows the user to submit a new claim',
    identifier: 'submit_claim',
  },
  delete_claim_draft: {
    module: 'Claims',
    name: 'Delete Claim Drafts',
    description: 'Allows the user to delete draft claims',
    identifier: 'delete_claim_draft',
  },
  resubmit_claim: {
    module: 'Claims',
    name: 'Resubmit Claim',
    description: 'Allows the user to resubmit a claim that has been partially or completely rejected',
    identifier: 'resubmit_claim',
  },
  download_claim_xml_file: {
    module: 'Claims',
    name: 'Download Claim as XML',
    description: "Allows the user to download the claim's source XML file",
    identifier: 'download_claim_xml_file',
  },
  download_claim_as_pdf: {
    module: 'Claims',
    name: 'Download Claim as PDF',
    description: 'Allows the user to download a claim as a PDF',
    identifier: 'download_claim_as_pdf',
  },
  load_erx: {
    module: 'Pharmacy',
    name: 'Load eRX',
    description: 'Allows the user to load and retrieve an eRx from DHPO',
    identifier: 'load_erx',
  },
  submit_erx_prior_request: {
    module: 'Pharmacy',
    name: 'Submit eRx Prior Request',
    description: 'Allows the user to submit a Prior Request for an eRx',
    identifier: 'submit_erx_prior_request',
  },
  resubmit_erx_priort_request: {
    module: 'Pharmacy',
    name: 'Resubmit eRx Prior Request',
    description:
      'Allows the user to resubmit a Prior Request for an eRx that has been partially or completely rejected',
    identifier: 'resubmit_erx_priort_request',
  },
  search_erx: {
    module: 'Pharmacy',
    name: 'Cancel eRx Prior Request',
    description: 'Allows the user to cancel the Prior Request for an eRx and to release the eRx back to DHPO',
    identifier: 'search_erx',
  },
  dispense_erx: {
    module: 'Pharmacy',
    name: 'Dispense eRx',
    description:
      'Allows the user to dispense an eRx that has received Prior Authorization, and automatically create and submit the claim for it',
    identifier: 'dispense_erx',
  },
  list_eligibility_requests: {
    module: 'Eligibility',
    name: 'List Eligibility Requests',
    description: 'Allows the user to view a list of all Eligibility Requests',
    identifier: 'list_eligibility_requests',
  },
  view_eligibility_request_details: {
    module: 'Eligibility',
    name: 'View Eligibility Request Details',
    description: 'Allows the user to to view the details of an Eligibility Request',
    identifier: 'view_eligibility_request_details',
  },
  create_eligibility_request: {
    module: 'Eligibility',
    name: 'Create Eligibility Request',
    description: 'Allows the user to create a new request to check eligibility',
    identifier: 'create_eligibility_request',
  },
  list_pre_auth_history: {
    module: 'Prior Auth',
    name: 'List Pre-Auth History',
    description: 'Allows the user to list the history of Prior Authorization Requests',
    identifier: 'list_pre_auth_history',
  },
  view_pre_auth_details: {
    module: 'Prior Auth',
    name: 'View Pre-Auth Details',
    description: 'Allows the user to view the details of a Prior Authorization Request',
    identifier: 'view_pre_auth_details',
  },
  create_pre_auth_request: {
    module: 'Prior Auth',
    name: 'Create Pre-Auth Request',
    description: 'Allows the user to create a new Prior Authorization Request',
    identifier: 'create_pre_auth_request',
  },
  resubmit_pre_auth_request: {
    module: 'Prior Auth',
    name: 'Resubmit Pre-Auth Request',
    description: 'Allows the user to resubmit a Prior Authorization Request',
    identifier: 'resubmit_pre_auth_request',
  },
  cancel_pre_auth_request: {
    module: 'Prior Auth',
    name: 'Cancel Pre-Auth Request',
    description: 'Allows the user to cancel a Prior Authorization Request',
    identifier: 'cancel_pre_auth_request',
  },
  view_branches: {
    module: 'Settings',
    name: 'View Branches',
    description: 'Allows the user to view a list of branches within the organization',
    identifier: 'view_branches',
  },
  add_branches: {
    module: 'Settings',
    name: 'Add Branches',
    description: 'Allows the user to add a new branch for the organization',
    identifier: 'add_branches',
  },
  edit_branches: {
    module: 'Settings',
    name: 'Edit Branches',
    description:
      'Allows the user to edit branch details, and update government portal login credentials for the branch',
    identifier: 'edit_branches',
  },
  remove_branches: {
    module: 'Settings',
    name: 'Remove Branches',
    description: 'Allows the user to remove a branch from the organization',
    identifier: 'remove_branches',
  },
  view_users: {
    module: 'Settings',
    name: 'View Users',
    description: 'Allows the user to view a list of users for the organization',
    identifier: 'view_users',
  },
  add_users: {
    module: 'Settings',
    name: 'Add Users',
    description: 'Allows the user to add a new user to the organization',
    identifier: 'add_users',
  },
  edit_users: {
    module: 'Settings',
    name: 'Edit Users',
    description: "Allows the user to edit a user's details",
    identifier: 'edit_users',
  },
  view_user_roles: {
    module: 'Settings',
    name: 'View User Roles',
    description: 'Allows the user to view a list of user roles',
    identifier: 'view_user_roles',
  },
  add_user_roles: {
    module: 'Settings',
    name: 'Add User Roles',
    description: 'Allows the user to create and define a new role and its permissions',
    identifier: 'add_user_roles',
  },
  edit_user_roles: {
    module: 'Settings',
    name: 'Edit User Roles',
    description: 'Allows the user to edit a role and update its permissions',
    identifier: 'edit_user_roles',
  },
  delete_user_roles: {
    module: 'Settings',
    name: 'Delete User Roles',
    description: 'Allows the user to remove a user role',
    identifier: 'delete_user_roles',
  },
  list_midtable: {
    module: 'MidTable',
    name: 'List MidTable',
    description: 'Allows the user to view a list of midTable',
    identifier: 'list_midtable',
  },
  list_payments: {
    module: 'Payments',
    name: 'List Payments',
    description: 'Allows the user to view a list of all payments',
    identifier: 'list_payments',
  },
  view_payment_details: {
    module: 'Payments',
    name: 'View Payment Details',
    description: 'Allows the user to view the details of a payment',
    identifier: 'view_payment_details',
  },
  send_payment_notice: {
    module: 'Payments',
    name: 'Send Payment Notice',
    description: 'Allows the user to send payment notice',
    identifier: 'send_payment_notice',
  },
};

export const modules = {
  reports: 'Reports',
  dashboard: 'Dashboards',
  claims: 'Claims',
  pharmacy: 'Pharmacy',
  priorAuth: 'Prior Auth',
  settings: 'Settings',
  payments: 'Payments',
  midtable: 'MidTable',
};
