import React from 'react';
import AnimateHeight from 'react-animate-height';
import {createUseStyles} from 'react-jss';
import {EL} from '@klaim-ai/klaim-interfaces';

import sectionCollapseArrow from 'assets/section_collapse_arrow.svg';
import sectionExpandedArrow from 'assets/section_expanded_arrow.svg';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimLabelValueGrid from 'modules/Eligibility/components/LabelValueGrid';
import dayjs from 'dayjs';
import KlaimValue from '../../../../../../../Common/components-v2/KlaimValue';

interface ComponentProps {
  collapsed?: boolean;
  costToBenificiaries: EL.Model.costToBeneficiaryDetails[];
}

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    headerContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
    },
    collapsableArrowContainer: {
      width: 20,
    },
    collapsableArrow: {
      marginLeft: 2,
      marginRight: 2,
    },
    title: {
      margin: 0,
      fontSize: 14,
      fontWeight: '400 !important',
    },
  };
});

const getBeneficiaryDetails = (details: any) => {
  if (details.valueMoney !== null) {
    return `${details.valueMoney.amount} ${details.valueMoney.curreny}`;
  }
  return `${details.valueQuantity.value} ${details.valueQuantity.code}`;
};

const CollapsibleContainer: React.FC<ComponentProps> = ({collapsed, costToBenificiaries}) => {
  const classes = useStylesFromThemeFunction();
  const [height, setHeight] = React.useState(collapsed ? '0%' : 'auto');
  const handleCollapse = () => {
    setHeight(height === '0%' ? 'auto' : '0%');
  };

  return (
    <div style={{borderRadius: '0.375rem', backgroundColor: '#F7F5FC', padding: 10}}>
      <div className={classes.headerContainer}>
        <div className={classes.collapsableArrowContainer} onClick={handleCollapse} role="button">
          {height === '0%' ? (
            <img src={sectionCollapseArrow} alt="collapse-arrow" className={classes.collapsableArrow} />
          ) : (
            <img src={sectionExpandedArrow} alt="expanded-arrow" className={classes.collapsableArrow} />
          )}
        </div>
        <h2 className={classes.title}>Global Benefits and Exceptions</h2>
      </div>
      <AnimateHeight duration={500} height={height}>
        {costToBenificiaries.map((costToBenificiary: EL.Model.costToBeneficiaryDetails) => (
          <div>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(5, minmax(0, 1fr))'}}>
              <KlaimValue
                label="Type"
                value={costToBenificiary.type}
                style={{gridColumn: 'span 1 / span 1', marginLeft: 'auto'}}
              />
              <KlaimValue
                label="Amount"
                value={getBeneficiaryDetails(costToBenificiary.details)}
                style={{gridColumn: 'span 2 / span 2', marginLeft: 'auto'}}
              />
            </div>
            {costToBenificiary.exceptions.map((exception) => (
              <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', rowGap: 2}}>
                <KlaimValue
                  label="Exceptions Type"
                  value={exception.type}
                  style={{gridColumn: 'span 1 / span 1', marginLeft: 'auto'}}
                />
                <KlaimValue
                  label="Exceptions Start & End Period"
                  value={`${dayjs(exception.periodStart).format('DD MMM YYYY')} - ${dayjs(exception.periodEnd).format(
                    'DD MMM YYYY',
                  )}`}
                  style={{gridColumn: 'span 2 / span 2', marginLeft: 'auto'}}
                />
              </div>
            ))}
          </div>
        ))}
      </AnimateHeight>
    </div>
  );
};

export default CollapsibleContainer;
