import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  staffManagementTableRow: {
    alignItems: 'center',
    display: 'flex',
    height: '80px',
    justifyContent: 'space-evenly',
    width: '100%',
    '& p': {
      color: Colors.blueGrayDark,
      flex: '1',
      fontSize: '14px',
      letterSpacing: '0px',
    },
  },
  hospitalInfo: {
    '& img': {
      marginRight: '26px',
    },
    alignItems: 'center',
    color: Colors.blueGrayDark,
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0px',
    flex: 1,
  },
  branchesLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    background: 'transparent',
    position: 'relative',
    cursor: 'default',
    '& .arrow': {
      background: Colors.white,
      zIndex: 20,
      bottom: 25,
      borderBottom: `1px solid ${Colors.grayWithOpacity}`,
      borderRight: `1px solid ${Colors.grayWithOpacity}`,
    },
    '& .tooltipContent': {
      background: Colors.white,
      color: Colors.grayLabel,
      boxShadow: `7px 7px 8px ${Colors.grayWithOpacity}`,
      zIndex: 10,
      bottom: 35,
    },
  },

  labelContainer: {
    flex: 1,
  },
  moreContainer: {
    position: 'relative',
  },
  avatar: {
    marginRight: 10,
  },
  navLink: {
    textDecoration: 'none',
  },
  button: {
    marginTop: 4,
    outline: 'none',
    backgroundColor: Colors.white,
    border: 'none',
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.blueGrayLight,
    },
    padding: 6,
    borderRadius: 4,
    whiteSpace: 'nowrap',
    color: Colors.blueGrayDark,
  },
  buttonIcon: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    height: 14,
  },
});
