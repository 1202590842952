import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import {find, get} from 'lodash';
import dayjs from 'dayjs';
import {ReactComponent as Error} from 'assets/error_outline_red.svg';
import {
  Card,
  Container,
  Divider,
  TableItem,
  Item,
  Label,
  Row,
  Value,
  Title,
  Col,
  Image,
  SpacedRow,
  LabelValue,
  Header,
} from './styles';
import Activities from './ClaimActivities';
import {ReactComponent as UserIcon} from '../../../../../assets/pt_claim_ksa.svg';
import {useAppDispatch, useAppSelector} from '../../../../App/store';
import {
  claimSelect,
  downloadExcel,
  errorClaimCleanup,
  getClaimCleanup,
  getHistory,
  getJsonResponse,
} from '../../../claimsSlice';
import ErrorsModal from './ErrorsModal';
import {claimStatues, detailsData} from '../ClaimsList/data';
import TimeLine from './TimeLine';
import Button from '../../../../Common/components/Button';
import {useStylesFromThemeFunction} from '../ClaimsList/ClaimsList';
import JsonModal from './JsonModal';
import SummaryHeader from './SummaryHeader';
import {specialtiesMapping} from 'modules/Common/constants/DropdownOptions';

const DATE_FORMAT = `DD MMM YYYY`;
const formatDate = (date) => (date ? dayjs(date).format(DATE_FORMAT) : null);

const editButton = ({fields}) => null;

const getPatient = ({patient, claim, id}) => (
  <Card>
    <Row>
      <Image>
        <UserIcon className={'userIcon'} />
      </Image>
      <Item>
        <Value className={'name'}>{patient?.name}</Value>
        <Value className={'small'}>
          {patient?.maritalStatus} • {patient?.gender}
        </Value>
        <Value>{formatDate(patient?.dateOfBirth)}</Value>
      </Item>
      <Item>
        <Label>CONTACT</Label>
        <Value>{patient?.phoneNumber}</Value>
        <Value> </Value>
      </Item>
      <Item>
        <Label>MEMBER / NID</Label>
        <Value>{patient?.documentId}</Value>
        <Value> </Value>
      </Item>
      <Item>
        <Label>FILE ID / CLAIM ID</Label>
        <Value>{id}</Value>
        <Value>{claim?.claimId}</Value>
      </Item>
    </Row>
  </Card>
);
const getClaim = ({errors, submissions, status, open, setOpen}) => {
  const claim = get(submissions, '[0].claim', {});

  const doctorSpecialty = specialtiesMapping.find((s) => s.value === claim.careTeam[0]?.specialty);

  return (
    <Card style={{maxWidth: '43%'}}>
      {errors && errors.length > 0 && <Error className={'button'} onClick={() => setOpen(true)} />}
      <Title>Claim Details</Title>
      <Row className={'mt-25'}>
        <Col>
          <Item className={'hor'}>
            <Label className={'label'}>CLAIM STATUS:</Label>
            <Value
              style={{
                backgroundColor: claimStatues[status].bg,
                color: claimStatues[status].text,
                padding: '2px 6px',
                borderRadius: 5,
              }}
            >
              {claimStatues[status].label}
            </Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>SUBMISSIONS:</Label>
            <Value>{submissions.length}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>ORDERED:</Label>
            <Value>{formatDate(submissions.submissionDate)}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>CLAIM TYPE:</Label>
            <Value>{claim.claimType}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>ENCOUNTER START:</Label>
            <Value>{formatDate(claim.claimBillablePeriodStart)}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>ENCOUNTER END:</Label>
            <Value>{formatDate(claim.claimBillablePeriodEnd)}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>ELIGIBILITY:</Label>
            <Value className={'link'}>{claim.eligibilityRefNo}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>PRE-AUTHORISATION:</Label>
            <Value className={'link'}>{claim.preAuthNo}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>EPISODE:</Label>
            <Value>{claim.episode?.value}</Value>
          </Item>
          <Item className={'hor'}>
            <Label className={'label'}>DOCTOR SPECIALTY:</Label>
            <Value>{doctorSpecialty.label}</Value>
          </Item>
        </Col>
      </Row>
      <ErrorsModal open={open} setOpen={setOpen} errors={errors} />
    </Card>
  );
};
const getInsurance = ({insurance, submissions}) => (
  <Card>
    {editButton({fields: []})}
    <Title>Insurance</Title>
    <Row className={'mt-25'}>
      <Col>
        <Item className={'hor'}>
          <Label className={'label'}>INSURANCE NAME:</Label>
          <Value>{submissions.payerName}</Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>TPA:</Label>
          <Value>
            {submissions.receiverId === submissions.payerId ? '' : submissions.receiverName || submissions.receiverId}
          </Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>MEMBER ID:</Label>
          <Value>{insurance?.member?.id}</Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>NETWORK:</Label>
          <Value>{insurance?.member?.network}</Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>POLICY NAME:</Label>
          <Value>{insurance.someValue}</Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>POLICY #:</Label>
          <Value>{insurance.policyNo}</Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>CLASS:</Label>
          <Value>{insurance.schemeClass}</Value>
        </Item>
        <Item className={'hor'}>
          <Label className={'label'}>SUBSCRIBER ID:</Label>
          <Value>{insurance.subscriberId}</Value>
        </Item>
      </Col>
    </Row>
  </Card>
);

const extractSupportingInfo = (supportingInfo, category) =>
  get(
    supportingInfo?.filter((el) => el.category === category),
    '[0]',
    {},
  );

const getVitals = ({supportingInfo}) => (
  <Card className={'table-card'}>
    <Row>
      <TableItem className={'label'} width={20}>
        CHIEF COMPLAINT & MAIN SYMPTOMS
      </TableItem>
      <TableItem className={'label'} width={20}>
        SIGNIFICANT SIGNS
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        LMP
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        DURATION
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        TEMP
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        BLOOD PRESSURE
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        WEIGHT
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        HEIGHT
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        PULSE
      </TableItem>
      <TableItem className={'label'} width={7.5}>
        RESP RATE
      </TableItem>
    </Row>
    <Divider />
    <Row>
      <TableItem className={'value'} width={20}>
        {extractSupportingInfo(supportingInfo, 'chief-complaint').text}
      </TableItem>
      <TableItem className={'value'} width={20}>
        {extractSupportingInfo(supportingInfo, 'info').valueString}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'last-menstrual-period').valueQuantity}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'info').smthing}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'temperature').valueQuantity}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'vital-sign-diastolic').valueQuantity} /{' '}
        {extractSupportingInfo(supportingInfo, 'vital-sign-systolic').valueQuantity}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'vital-sign-weight').valueQuantity}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'vital-sign-height').valueQuantity}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'pulse').valueQuantity}
      </TableItem>
      <TableItem className={'value'} width={7.5}>
        {extractSupportingInfo(supportingInfo, 'respiratory-rate').valueQuantity}
      </TableItem>
    </Row>
    <Divider />
  </Card>
);
const getMessage = ({response}) => (
  <Card className={'table-card'}>
    <Row style={{marginBottom: 8}}>
      <TableItem className={'value'} width={10}>
        Message
      </TableItem>
      <TableItem className={'label'} width={90}>
        {get(response, '[0].response.message')}
      </TableItem>
    </Row>
    <Row style={{marginBottom: 8}}>
      <TableItem className={'value'} width={10}>
        Type
      </TableItem>
      <TableItem className={'label'} width={90}>
        {get(response, '[0].response.processNote[0].type', '')}
      </TableItem>
    </Row>
    <Row style={{marginBottom: 8}}>
      <TableItem className={'value'} width={10}>
        ID
      </TableItem>
      <TableItem className={'label'} width={90}>
        {get(response, '[0].response.processNote[0].number', '')}
      </TableItem>
    </Row>
    <Row>
      <TableItem className={'value'} width={10}>
        Note
      </TableItem>
      <TableItem className={'label'} width={90}>
        {get(response, '[0].response.processNote[0].text', '')}
      </TableItem>
    </Row>
  </Card>
);

const getDiagnosis = ({diagnosis}) => (
  <Card className={'table-card'}>
    <Row className={'justify-left'}>
      <TableItem className={'label'} width={3}>
        #
      </TableItem>
      <TableItem className={'label'} width={20}>
        DIAGNOSIS TYPE
      </TableItem>
      <TableItem className={'label'} width={20}>
        ON ADMISSION
      </TableItem>
      <TableItem className={'label'} width={20}>
        DIAGNOSIS
      </TableItem>
      <TableItem className={'value'} width={37}>
        DESCRIPTION
      </TableItem>
    </Row>
    <Divider />
    {diagnosis.map((item: any, index: number) => [
      <Row className={'justify-left'} key={`${index}-diagnosis-row`}>
        <TableItem className={'value'} width={3}>
          {index}
        </TableItem>
        <TableItem className={'value'} width={20}>
          {item.diagType}
        </TableItem>
        <TableItem className={'value'} width={20}>
          {item.diagOnAdmission === 'y' ? 'YES' : 'NO'}
        </TableItem>
        <TableItem className={'value'} width={20}>
          {item.diagCode}
        </TableItem>
        <TableItem className={'value'} width={37}>
          {item.diagDescription}
        </TableItem>
      </Row>,
      <Divider />,
    ])}
  </Card>
);

const getAttachment = (submission) => (
  <Card className={'table-card'}>
    <Row className={'justify-left'}>
      <TableItem className={'label'} width={10}>
        ATTACHMENTS
      </TableItem>
      <TableItem className={'label'} width={10}>
        LAB TEST NAME
      </TableItem>
      <TableItem className={'label'}>ACTION</TableItem>
    </Row>
    <Divider />
    {[0, 1].map((diagnosis: any, index: number) => [
      <Row className={'justify-left'} key={`${index}-diagnosis-row`}>
        <TableItem className={'value'} width={10}>
          {diagnosis.smth}
        </TableItem>
        <TableItem className={'value'} width={10}>
          {diagnosis.smth}
        </TableItem>
        <TableItem className={'value'} width={80}>
          {diagnosis.smth}
        </TableItem>
      </Row>,
      <Divider />,
    ])}
  </Card>
);

interface RouteParams {
  id: string;
}

const useQuery = () => {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const Component: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: {id},
  },
}) => {
  const query = useQuery();
  const {history, jsonResponse, error} = useAppSelector(claimSelect);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [openJson, setOpenJson] = useState(false);
  const classes = useStylesFromThemeFunction();
  useEffect(() => {
    if (error) {
      setOpenJson(true);
      dispatch(errorClaimCleanup());
    }
  }, [error]);

  React.useEffect(() => {
    // dispatch(createPreAuthCleanup());
    if (id) dispatch(getHistory({id, providerId: query.get('providerId') || ''}));
  }, [id]);

  useEffect(() => {
    if (Object.keys(jsonResponse).length > 0) setOpenJson(true);
  }, [jsonResponse]);

  React.useEffect(() => {
    return () => {
      dispatch(getClaimCleanup());
    };
  }, []);
  if (!history) return null;

  const labelValue = (label: string, value: string) => {
    return (
      <LabelValue className={'labelValue'}>
        <Label className={'value'}>{label}</Label>
        <Value className="label">{value}</Value>
      </LabelValue>
    );
  };

  const getHeader = (financialData) => {
    const arr = Object.keys(detailsData).map((key) => ({
      label: detailsData[key]?.label,
      value: financialData[key] || detailsData[key]?.value,
    }));
    arr.push({
      label: 'ELIGIBLE',
      value: get(
        get(history, `responses[0].response.itemsDetails[0].adjudication`, []).filter(
          (sub) => sub.category === 'eligible',
        ),
        '[0].amount',
      ),
    });
    arr.push({
      label: 'APPROVED',
      value: get(
        get(history, 'responses[0].response.claimTotal', []).find((el) => el?.category === 'approved-quantity'),
        'amount',
        '-',
      ),
    });
    arr.push({label: ' ', value: ' '});
    return arr;
  };

  const getTotals = ({financialData}) => (
    <Header className={'header'}>{getHeader(financialData).map((el) => labelValue(el.label, el.value))}</Header>
  );
  return (
    <Container>
      <SummaryHeader items={get(history, 'submissions[0].claim.item', [])} response={get(history, 'responses', [])} />
      {/*{getTotals({financialData: get(history, 'financialData', {})})}*/}
      {getPatient({
        patient: get(history, 'submissions[0].patient', {}),
        claim: get(history, 'submissions[0]', {}),
        id: get(history, 'hisId'),
      })}
      <SpacedRow>
        {getClaim({
          errors: get(history, 'responses[0].errors', null),
          submissions: get(history, 'submissions', {}),
          status: history.status,
          open,
          setOpen,
        })}
        {getInsurance({
          insurance: get(history, 'submissions[0].coverage', {}),
          submissions: get(history, 'submissions[0]', {}),
        })}
      </SpacedRow>
      {getMessage({response: get(history, 'responses', [])})}
      {getVitals({supportingInfo: get(history, 'submissions[0].claim.supportingInfo', [])})}
      {getDiagnosis({diagnosis: get(history, 'submissions[0].claim.diagnosis', [])})}
      <Activities
        items={get(history, 'submissions[0].claim.item', [])}
        response={get(history, 'responses', [])}
        careTeam={get(history, 'submissions[0].claim.careTeam', [])}
      />
      {/*{getAttachment({submission: {}})}*/}
      <TimeLine
        status={get(history, 'status')}
        submissions={get(history, 'submissions')}
        responses={get(history, 'responses', [])}
        claim={history}
      />
      <div className={classes.flexEndRow}>
        <Button
          buttonClassName={classes.button}
          onClick={() => {
            dispatch(
              getJsonResponse({
                id: get(history, 'responses[0].response.bundleIds.request', ''),
                providerId: query.get('providerId'),
              }),
            );
          }}
          title="Display JSON request"
        />
        &nbsp;
        <Button
          buttonClassName={classes.button}
          onClick={() => {
            dispatch(
              getJsonResponse({
                id: get(history, 'responses[0].response.bundleIds.response', ''),
                providerId: query.get('providerId'),
              }),
            );
          }}
          title="Display JSON response"
        />
      </div>
      <JsonModal open={openJson} setOpen={setOpenJson} json={jsonResponse} error={error} />
    </Container>
  );
};

export default Component;
