import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axiosInstance from 'services/axios';
import {IActivityCodeData, IActivityCodeParams, IActivityCode} from 'interfaces/pre-auth.interface';
import {MyKnownError} from 'interfaces/error-interface';

interface InitialState {
  activities: IActivityCode[];
  isLoading: boolean;
  error?: MyKnownError;
}

export const getActivityCode = createAsyncThunk<IActivityCodeData, IActivityCodeParams, {rejectValue: MyKnownError}>(
  'preAuthV2/getActivityCode',
  async (query, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/activities', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const initialState: InitialState = {
  activities: [],
  isLoading: false,
  error: undefined,
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityCode.fulfilled, (state, action) => {
        state.activities = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getActivityCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActivityCode.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const activitySelect = (state: RootState) => state.activity;
export default activitySlice.reducer;
