import React, {useEffect} from 'react';
import {get as getStorage, set as setStorage} from 'local-storage';
import {isEmpty} from 'lodash';
import * as Yup from 'yup';
import {useHistory, useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import actionButtonIcon from 'assets/action_button_icon.svg';
import infoIcon from 'assets/info_icon.svg';
import Bookmark from 'assets/bookmark.svg';
import Umbrella from 'assets/umbrella.svg';
import downloadPdfIcon from 'assets/download_pdf_icon.svg';
import arrowUpIcon from 'assets/arrow_up_icon.svg';
import bulkIcon from 'assets/bulk_icon.svg';
import cancelIcon from 'assets/cancel_icon.svg';
import searchIcon from 'assets/search_icon.svg';
import dateTypeIcon from 'assets/datetype_icon.svg';
import filterIcons from 'assets/active_settings_icon.svg';
import AddIcon from 'assets/component/AddIcon';
import KlaimSelect from 'modules/Common/components/KlaimSelect';
import KlaimMultiSelect from 'modules/Common/components/KlaimMultiSelect';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {authSelect, getBranches} from 'modules/Auth/v3/features/Auth/authSlice';
import {
  headerSelect,
  setSelectedDateType,
  setSelectedStatus,
  setSelectedBranches,
  Status,
  setSearchInput,
  searchByRefNumber,
  searchEpisodeByKeyword,
  searchErxByKeyword,
  setSelectedErxStatus,
  setSelectedErxInsurers,
  setRejectionSelectedDateType,
  setModalVisibility,
  setSelectedPayer,
  setSelectedTPA,
  OptionValue,
  setSubmitButtonOnClick,
  setSelectedType,
  setSelectedSpecialties,
  setSelectedVisit,
  setSelectedSynced,
  setSubmitAllButtonOnClick,
  setSelectedReceiver,
} from 'modules/Header/headerSlice';
import queryString from 'query-string';
import {loadingSelect} from 'modules/Auth/v3/features/Loading/loadingSlice';
import {useStylesFromThemeFunction} from 'modules/Header/Header';
import Input from 'modules/Common/components/Input';
import Navigation from 'modules/Header/features/Navigation';
import KlaimForm from 'modules/Common/components/KlaimForm';
import {erxDescriptionSelect} from 'modules/Pharmacy/features/ErxDescription/erxDescriptionSlice';
import {ErxStatus} from 'modules/Pharmacy/features/ErxDescription/types';
import {erxListSelect} from 'modules/Pharmacy/features/ErxList/erxListSlice';
import Button from 'modules/Common/components/Button';
import {selectClaimById} from 'modules/App/selectors';
import {ClaimStatus} from 'modules/Claims/api/types';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';
import {Colors} from 'modules/Common/constants/Colors';
import {decodeJWT} from 'services/jwt';
import DateRange from './features/DateRange';
import SearchQuery from './features/SearchQuery';
import BranchesFilter from './features/BranchesFilter';
import {getInsurances, insuranceSelect} from '../Insurance/insuranceSlice';
import {claimType, visitType, syncedType, specialtiesMapping} from '../Common/constants/DropdownOptions';
import {getInsurances as getTPA, insurancesManagementSelect} from '../Settings/features/Insurance/InsuranceSlice';
import VisitDateRange from './features/VisitDateRange';

export const Filter: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const {branches, isAuthenticated} = useAppSelector(authSelect);
  const {isBranchesFulfilled, isCheckLoading, isLoading} = useAppSelector(loadingSelect);
  const {
    selectedBranches,
    statusOptions,
    selectedStatus,
    selectedPayer,
    selectedReceiver,
    selectedTPA,
    selectedType,
    selectedSpecialties,
    selectedVisits,
    selectedSynced,
    selectedDateType,
    dateTypeOptions,
    rejectionDateOptions,
    startDate,
    endDate,
    searchRefValue,
    erxStatusOptions,
    selectedErxStatus,
    selectedErxInsurer,
    rejectionSelectedDateType,
    eligibilityButtons,
    searchParams,
    preAuthButtons,
    claimButtons,
    paymentButton,
    submitButton,
    submitAllButton,
    submitFormButton,
    isNavigationButtonHidden,
  } = useAppSelector(headerSelect);
  const {data: erxDesc} = useAppSelector(erxDescriptionSelect);
  const {
    dataInsurers: {data: erxInsurers},
  } = useAppSelector(erxListSelect);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getInsurances({authorityCode: 'NPHIES', limit: 100}));
    if (branches && branches.length > 0) {
      const branchIds = branches.map((b: any) => b.identifier).join(',');
      if (branchIds) {
        dispatch(
          getTPA({
            limit: 100,
            providerId: branchIds,
          }),
        );
      }
    }
  }, [branches]);
  const {payers} = useAppSelector(insuranceSelect);

  const {
    insurances: {data: tpas},
  } = useAppSelector(insurancesManagementSelect);

  useEffect(() => {
    if (!isEmpty(payers)) {
      setPayersOptions(payers.data.map((el) => ({label: el.name, value: el.payerCode})));
      const receivers = payers.data.filter((receiver) => receiver.classification === 'TPA');
      setReceiversOptions(receivers.map((el) => ({label: el.name, value: el.payerCode})));
    }
  }, [payers]);

  useEffect(() => {
    if (!isEmpty(tpas)) {
      const mappedTpas = tpas.map((el) => {
        const obj = el as any;
        return {label: obj.longName, value: obj.hcpCode};
      });
      setTpaOptions(mappedTpas.filter((el, i, a) => a.findIndex((obj) => obj.value === el.value) === i));
    }
  }, [tpas]);

  const [showDescription, setShowDescription] = React.useState<boolean>(true);
  const [statusValue, setStatusValue] = React.useState<string>('');
  const [payerValue, setPayerValue] = React.useState<string>('');
  const [receiverValue, setReceiverValue] = React.useState<string>('');
  const [tpaValue, setTPAValue] = React.useState<string>('');
  const [typeValue, setTypeValue] = React.useState<string>('');
  const [visitValue, setVisitValue] = React.useState<string>('');
  const [syncedValue, setSyncedValue] = React.useState<string>('');
  const [dateTypeValue, setDateTypeValue] = React.useState<string>('');
  const [rejectDateTypeValue, setRejectDateTypeValue] = React.useState<string>('');
  const [searchClaimKeyword, setSearchClaimKeyword] = React.useState<string>('');
  const [dateRangeValue, setDateRangeValue] = React.useState<string>('');
  const [erxStatusValue, setErxStatusValue] = React.useState<string>('');
  const [erxInsurerValue, setErxInsurerValue] = React.useState<string>('');
  const [eRxInsurerData, setErxInsurerData] = React.useState([] as any);
  const [payersOptions, setPayersOptions] = React.useState([] as any);
  const [receiversOptions, setReceiversOptions] = React.useState([] as any);
  const [tpaOptions, setTpaOptions] = React.useState([] as any);
  const claim = useSelector((state: any) => selectClaimById(state, queryString.parse(location.search).claimId || null));
  const searchEl = React.useRef(null);
  const [buttonHover, setButtonHover] = React.useState<boolean>(false);
  const [firstExecution, setFirstExecution] = React.useState<boolean>(true);
  const [expended, setExpended] = React.useState<boolean>(false);
  const [submitAllDisabled, setSubmitAllDisabled] = React.useState<boolean>(false);
  // eslint-disable-next-line
  const format = /[`!@#$%^&*()_+\=\[\]{};'"\\|,.<>\/?~]/;

  const handleResize = () => {
    let width = 1100;
    if (searchEl) width = 1400;
    if (window.innerWidth < width) {
      setShowDescription(false);
    } else {
      setShowDescription(true);
    }
  };

  React.useEffect(() => {
    handleResize();
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [window]);

  React.useEffect(() => {
    if (isBranchesFulfilled) {
      const localSelectedBranches = JSON.parse(getStorage('selectedBranches') || '[]');
      if (decodeJWT(getStorage('accessToken')).role.name === 'Admin') {
        if (localSelectedBranches.length < 1 && !firstExecution) {
          const fetchedBranches = branches.map((branch) => {
            return {label: branch.name, value: branch.identifier};
          });
          dispatch(setSelectedBranches(fetchedBranches));
        } else if (localSelectedBranches.length < 1 && firstExecution) {
          const fetchedBranches = branches.map((branch) => {
            return {label: branch.name, value: branch.identifier};
          });
          dispatch(setSelectedBranches(fetchedBranches.length <= 2 ? fetchedBranches : fetchedBranches.splice(0, 2)));
          setFirstExecution(false);
        } else {
          dispatch(setSelectedBranches(localSelectedBranches));
        }
      } else if (decodeJWT(getStorage('accessToken')).role.name !== 'Admin') {
        if (localSelectedBranches.length < 1) {
          const fetchedBranches = branches.map((branch) => {
            return {label: branch.name, value: branch.identifier};
          });
          dispatch(setSelectedBranches(fetchedBranches));
        } else {
          dispatch(setSelectedBranches(localSelectedBranches));
        }
      }
      setStorage(
        'userBranches',
        branches.map((branch) => {
          return {label: branch.name, value: branch.identifier, authority: branch.authority};
        }),
      );
    }
  }, [branches, isBranchesFulfilled]);

  React.useEffect(() => {
    dispatch(getBranches());
  }, []);

  const dateTypeChange = (value: any) => {
    dispatch(setSelectedDateType(value));
  };

  const rejectionDateTypeChange = (value: any) => {
    dispatch(setRejectionSelectedDateType(value));
  };

  React.useEffect(() => {
    const localDatetype = JSON.parse(getStorage('dateType'));
    if (localDatetype) {
      dateTypeChange(localDatetype);
    } else {
      dateTypeChange(dateTypeOptions[1]);
    }
  }, []);

  const statusChange = (selStatus: Status[]) => {
    dispatch(setSelectedStatus(selStatus));
    const joinedStatus = selStatus.map((status: Status) => status.label).join(',');
    setStatusValue(joinedStatus);
  };
  const payersChange = (payer: any[]) => {
    dispatch(setSelectedPayer(payer));
    setPayerValue(`${payer.length} Payers`);
  };
  const receiversChange = (receiver: any[]) => {
    dispatch(setSelectedReceiver(receiver));
    setReceiverValue(`${receiver.length} Receivers`);
  };
  const tpaChange = (payer: any[]) => {
    dispatch(setSelectedTPA(payer));
    setTPAValue(`${payer.length} Payers`);
  };
  const typesChange = (types: any[]) => {
    dispatch(setSelectedType(types));
    setTypeValue(`${types.length} Type`);
  };
  const specialtiesChange = (specialties: any[]) => {
    dispatch(setSelectedSpecialties(specialties));
    setTypeValue(`${specialties.length} Type`);
  };
  const vistChange = (types: any[]) => {
    dispatch(setSelectedVisit(types));
    setVisitValue(`${types.length} Visits`);
  };
  const syncedChange = (types: any[]) => {
    dispatch(setSelectedSynced(types));
    setSyncedValue(types.length > 0 ? `${types.length} Options` : 'is Synced');
  };

  React.useEffect(() => {
    setButtonHover(false);
    setSubmitAllDisabled(false);
  }, [history.location.pathname]);

  React.useEffect(() => {
    dispatch(setSearchInput(searchClaimKeyword));
  }, [searchClaimKeyword]);

  React.useEffect(() => {
    setDateTypeValue(selectedDateType.label);
  }, [selectedDateType]);

  React.useEffect(() => {
    setRejectDateTypeValue(rejectionSelectedDateType.label);
  }, [rejectionSelectedDateType]);

  React.useEffect(() => {
    const newInsurersData = erxInsurers.map((data: any, index: number) => ({
      label: data.name,
      value: data.payerCode,
    }));
    setErxInsurerData(newInsurersData);
  }, [erxInsurers]);

  useEffect(() => {
    setSubmitAllDisabled(false);
  }, [startDate, selectedStatus, endDate, selectedType, selectedBranches, selectedTPA, selectedVisits]);

  const setReferenceNumber = (referenceNumber: string) => {
    dispatch(searchByRefNumber(referenceNumber));
  };

  const onSubmitRef = () => {
    if (searchRefValue !== '') {
      history.push(`/pharmacy/prescription/${searchRefValue}`);
      setReferenceNumber('');
    }
    return <h1>This field is required!</h1>;
  };

  const setSearchFilter = (searchKeyword: string) => {
    dispatch(searchErxByKeyword(searchKeyword));
  };
  const setSearchEpisode = (searchKeyword: string) => {
    dispatch(searchEpisodeByKeyword(searchKeyword));
  };
  const setPaymentModalVisibility = (state: boolean) => {
    dispatch(setModalVisibility(state));
  };

  const setSubmitButtonClick = (state: boolean) => {
    dispatch(setSubmitButtonOnClick(state));
  };

  const setSubmitAllButtonClick = (state: boolean) => {
    dispatch(setSubmitAllButtonOnClick(state));
  };

  const intValue = [{}];

  const validationSchema = Yup.object({});

  const submitEnter = (e: any) => {};

  const onSubmit = () => {};

  const statusTitleValue = () => {
    return selectedStatus.length === statusOptions.length ? 'All Statuses' : statusValue;
  };
  const payersTitleValue = () => {
    return selectedPayer.length === payersOptions.length ? 'All Payers' : payerValue;
  };
  const receiversTitleValue = () => {
    return selectedReceiver.length === receiversOptions.length ? 'All Receivers' : receiverValue;
  };
  const tpaTitleValue = () => {
    return selectedTPA.length === tpaOptions.length ? 'All Payers' : tpaValue;
  };
  const typesTitleValue = () => {
    return selectedType.length === claimType.length ? 'All Payers' : typeValue;
  };
  const visiteTitleValue = () => {
    return selectedVisits.length === visitType.length ? 'All visits' : visitValue;
  };

  const syncedTitleValue = () => {
    return selectedSynced?.length === syncedType.length ? 'All Options' : syncedValue;
  };

  const statusErxChange = (erxStatus: Status[]) => {
    dispatch(setSelectedErxStatus(erxStatus));
    const joinedErxStatus = erxStatus.map((status: Status) => status.value).join(',');
    setErxStatusValue(joinedErxStatus);
  };

  const erxStatuses = () => {
    return selectedErxStatus.length === erxStatusOptions.length ? 'All Statuses' : erxStatusValue;
  };

  const insurerStatuses = () => {
    return selectedErxInsurer.length === eRxInsurerData.length ? 'All Insurers' : erxInsurerValue;
  };

  const insurerErxChange = (insurerStatus: Status[]) => {
    dispatch(setSelectedErxInsurers(insurerStatus));
    const joinedInsurerStatus = insurerStatus.map((insuranceStatus: Status) => insuranceStatus.value).join(',');
    setErxInsurerValue(joinedInsurerStatus);
  };

  const {
    staticDateType,
    dateTypeFilter,
    statusFilter,
    payersFilter,
    receiversFilter,
    TPAFilter,
    typesFilter,
    visitFilter,
    syncedFilter,
    specialtiesFilter,
    dateRangeFilter,
    filterByDate,
    optionsFilter,
    navOptions,
    branchesFilter,
    search,
    dateRangeFilterMonth,
    searchRef,
    searchErxList,
    searchEpisode,
    erxStatusSelect,
    requestAuthorizationUI,
    dispenseAuthorzationUI,
    insurersFilterUI,
    dateRangeFilterDays,
    visitDateFilter,
    rejectionDateTypeFilter,
    submitAllOnClick,
  } = useAppSelector(headerSelect);

  const handleEditClaim = (claimId: string | undefined, providerID: string) => {
    history.push(`/claims/form?claimId=${claimId}&claimType=resubmit&providerId=${providerID}`);
  };

  const handleSubmittingEditClaim = (claimId: string | undefined, providerID: string) => {
    history.push(`/claims/form?claimId=${claimId}&claimType=submitting&providerId=${providerID}`);
    // history.push(`/claims/form?claimId=${claimId}&claimType=draft&providerId=${providerId}`);
  };

  const HeaderButtons = () => {
    if (erxDesc?.status === ErxStatus.authorized) {
      return (
        <div className={classes.mr20}>
          <button className={classes.cancelBtn} type="submit">
            Cancel
          </button>
          <button className={classes.klaimBtn} type="submit">
            Dispense
          </button>
        </div>
      );
    }
    if (erxDesc?.status === ErxStatus.rejected) {
      return (
        <div>
          <button className={classes.klaimBtn} type="submit">
            Resubmit Authorization
          </button>
        </div>
      );
    }
    if (erxDesc?.status === ErxStatus.canceled) {
      return (
        <div>
          <button className={classes.cancelBtn} type="submit">
            Canceled
          </button>
        </div>
      );
    }
    if (erxDesc?.status === ErxStatus.dispensed) {
      return (
        <div>
          <button className={classes.klaimBtn} type="submit">
            View Claim
          </button>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div
      className={
        location.pathname === '/pharmacy' || location.pathname === '/pharmacy/'
          ? `${classes.headerContainer2}`
          : `${classes.headerContainer}`
      }
    >
      <div className={classes.firstRow}>
        <div className={classes.searchWrapper}>
          {navOptions.length > 0 && <Navigation isButtonHidden={isNavigationButtonHidden} />}

          {/* {search && (
          <div className={classes.inputWrapper} ref={searchEl} id="search-el">
            <Input
              inputContainerClassName={classes.inputContainer}
              placeholder="Search"
              iconLeft={searchIcon}
              value={searchClaimKeyword}
              onChange={(e) =>
                format.test(e.target.value) ? setSearchClaimKeyword('') : setSearchClaimKeyword(e.target.value)
              }
            />
          </div>
        )} */}
          {searchRef && (
            <KlaimForm initialValues={intValue} validationSchema={validationSchema} onSubmit={onSubmit}>
              <div className={classes.inputSpacing} id="searchRef">
                <input
                  className={classes.inputStyle}
                  placeholder="eRx reference #"
                  value={searchRefValue}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
                <button
                  className={classes.buttonIcon}
                  onClick={() => onSubmitRef()}
                  onKeyPress={(e) => submitEnter(e)}
                />
              </div>
            </KlaimForm>
          )}
          {searchErxList && (
            <div className={classes.inputWrapper} id="search-erx-list">
              <Input
                inputContainerClassName={classes.inputContainer}
                placeholder="Search"
                iconLeft={searchIcon}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </div>
          )}
          {(searchParams || search) && <SearchQuery />}
        </div>
        {/* {selectedScreen.value === 'Claims List' && (
        <Button
          buttonClassName={classes.newClaimButton}
          onClick={() => history.push('/claims/form')}
          title="Create New Claim"
        />
      )} */}
        {preAuthButtons && (
          <>
            <KlaimIconButton
              onClick={() => history.push('/pre-auth/form')}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
              className={classes.buttonContainer}
            >
              {/* {buttonHover && <p>New PA</p>} */}
              <p>New PA</p>
              {/* <AddIcon fill={buttonHover ? Colors.white : undefined} /> */}
              <AddIcon fill={Colors.white} />
            </KlaimIconButton>
            {/* <KlaimIconButton className={classes.buttonContainer}>
              <MoreIcon />
            </KlaimIconButton> */}
          </>
        )}
        {claimButtons && (
          <>
            <KlaimIconButton
              onClick={() => history.push('/claims/form')}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
              className={classes.buttonContainer}
            >
              {/* {buttonHover && <p>New Claim</p>} */}
              <p>New Claim</p>
              {/* <AddIcon fill={buttonHover ? Colors.white : undefined} /> */}
              <AddIcon fill={Colors.white} />
            </KlaimIconButton>
            {/* <KlaimIconButton className={classes.buttonContainer}>
              <MoreIcon />
            </KlaimIconButton> */}
          </>
        )}
        {eligibilityButtons && (
          <>
            <KlaimIconButton
              onClick={() => history.push('/eligibility/form')}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
              className={classes.buttonContainer}
            >
              {/* {buttonHover && <p>New Check</p>} */}
              <p>New Check</p>
              <AddIcon fill={Colors.white} />
              {/* <AddIcon fill={buttonHover ? Colors.white : undefined} /> */}
            </KlaimIconButton>
            {/* <KlaimIconButton className={classes.buttonContainer}>
              <MoreIcon />
            </KlaimIconButton> */}
          </>
        )}
        {location.pathname.includes('details') &&
          claim &&
          (claim.status === ClaimStatus.partially_paid || claim.status === ClaimStatus.rejected) && (
            <>
              <Button
                buttonClassName={classes.editClaimBtn}
                title="Edit"
                onClick={() => handleEditClaim(claim.id, claim.branch.identifier)}
              />
            </>
          )}
        {location.pathname.includes('details') && claim && claim.status === ClaimStatus.submitting && (
          <>
            <Button
              buttonClassName={classes.editClaimBtn}
              title="Edit"
              onClick={() => handleSubmittingEditClaim(claim.id, claim.branch.identifier)}
            />
          </>
        )}
        {paymentButton && (
          <>
            <Button
              buttonClassName={classes.editClaimBtn}
              title="Send Payment Notice"
              onClick={() => {
                setPaymentModalVisibility(true);
              }}
            />
          </>
        )}
        {submitButton && (
          <Button
            buttonClassName={classes.editClaimBtn}
            title="Submit Selected"
            onClick={() => {
              setSubmitButtonClick(true);
            }}
          />
        )}
        {submitAllButton && (
          <Button
            buttonClassName={classes.submitAllBtn}
            title="Submit All"
            loading={submitAllOnClick}
            disabled={submitAllDisabled}
            onClick={() => {
              setSubmitAllDisabled(true);
              setSubmitAllButtonClick(true);
            }}
          />
        )}
        {submitFormButton && (
          <Button
            buttonClassName={classes.editClaimBtn}
            title="Submit"
            onClick={() => {
              setSubmitButtonClick(true);
            }}
          />
        )}
        <Button
          buttonClassName={classes.collapsibleTrigger}
          onClick={() => setExpended((state) => !state)}
          iconLeft={filterIcons}
        />
      </div>
      {expended && (
        <div className={`${classes.collapsibleRow} ${expended && classes.collapsibleRowExpended}`}>
          <div className={classes.collapsibleHeader}>
            <div className={classes.filterContainer}>
              {staticDateType && staticDateType.length > 0 && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title={staticDateType || ''}
                  leftIcon={dateTypeIcon}
                  value={staticDateType || ''}
                  className={classes.filterItem}
                />
              )}

              {dateTypeFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Filter by Date type"
                  leftIcon={dateTypeIcon}
                  value={dateTypeValue}
                  className={classes.filterItem}
                >
                  <KlaimSelect options={dateTypeOptions} onChange={dateTypeChange} value={selectedDateType} />
                </KlaimDropdownContainer>
              )}

              {rejectionDateTypeFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Filter by Date type"
                  leftIcon={dateTypeIcon}
                  value={rejectDateTypeValue}
                >
                  <KlaimSelect
                    options={rejectionDateOptions}
                    onChange={rejectionDateTypeChange}
                    value={rejectionSelectedDateType}
                  />
                </KlaimDropdownContainer>
              )}

              {statusFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Status"
                  leftIcon={infoIcon}
                  value={statusTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={statusChange} value={selectedStatus} options={statusOptions} />
                </KlaimDropdownContainer>
              )}

              {typesFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Types"
                  leftIcon={infoIcon}
                  value={typesTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={typesChange} value={selectedType} options={claimType} />
                </KlaimDropdownContainer>
              )}
              {specialtiesFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Specialties"
                  leftIcon={infoIcon}
                  value={typesTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect
                    onChange={specialtiesChange}
                    value={selectedSpecialties}
                    options={specialtiesMapping}
                  />
                </KlaimDropdownContainer>
              )}
              {visitFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Visits"
                  leftIcon={infoIcon}
                  value={visiteTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={vistChange} value={selectedVisits} options={visitType} />
                </KlaimDropdownContainer>
              )}
              {syncedFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="is Synced"
                  leftIcon={infoIcon}
                  value={syncedTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={syncedChange} value={selectedSynced} options={syncedType} />
                </KlaimDropdownContainer>
              )}

              {dateRangeFilter && <DateRange dateRangePicker showDescription={showDescription} />}

              {erxStatusSelect && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Status"
                  leftIcon={Bookmark}
                  value={erxStatuses()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={statusErxChange} value={selectedErxStatus} options={erxStatusOptions} />
                </KlaimDropdownContainer>
              )}

              {dateRangeFilterMonth && (
                <DateRange
                  maxDate={new Date()}
                  showMonthYearPicker
                  dateRangePicker
                  showDescription={showDescription}
                  className={classes.filterItem}
                />
              )}

              {dateRangeFilterDays && (
                <DateRange
                  dateRangePicker
                  showDescription={showDescription}
                  className={classes.filterItem}
                  title="S:"
                />
              )}

              {visitDateFilter && (
                <VisitDateRange
                  dateRangePicker
                  showDescription={showDescription}
                  className={classes.filterItem}
                  title="V:"
                />
              )}

              {filterByDate && (
                <DateRange
                  maxDate={new Date()}
                  showMonthYearPicker
                  dateRangePicker
                  showDescription={showDescription}
                  className={classes.filterItem}
                />
              )}

              {optionsFilter && (
                <KlaimDropdownContainer
                  title="Options"
                  leftIcon={actionButtonIcon}
                  showDescription={showDescription}
                  className={classes.filterItem}
                >
                  <div className={classes.filtersOptions}>
                    <div className={classes.optionFilters}>
                      <img src={downloadPdfIcon} alt="download-pdf" />
                      <label>Download PDF</label>
                    </div>
                    <div className={classes.optionFilters}>
                      <img src={arrowUpIcon} alt="select-completed" />
                      <label>Completed</label>
                    </div>
                    <div className={classes.optionFilters}>
                      <img src={bulkIcon} alt="bulk-submit" />
                      <label>Bulk Submit</label>
                    </div>
                    <div className={classes.optionFilters}>
                      <img style={{transform: 'rotate(45deg)'}} src={cancelIcon} alt="cancel-icon" />
                      <label>Cancel</label>
                    </div>
                  </div>
                </KlaimDropdownContainer>
              )}

              {branchesFilter && <BranchesFilter showDescription={showDescription} className={classes.filterItem} />}
              {payersFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Payers"
                  leftIcon={infoIcon}
                  value={payersTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={payersChange} value={selectedPayer} options={payersOptions} />
                </KlaimDropdownContainer>
              )}
              {receiversFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Receivers"
                  leftIcon={infoIcon}
                  value={receiversTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={receiversChange} value={selectedReceiver} options={receiversOptions} />
                </KlaimDropdownContainer>
              )}
              {TPAFilter && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Payers"
                  leftIcon={infoIcon}
                  value={tpaTitleValue()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={tpaChange} value={selectedTPA} options={tpaOptions} />
                </KlaimDropdownContainer>
              )}

              {insurersFilterUI && (
                <KlaimDropdownContainer
                  showDescription={showDescription}
                  title="Insurers"
                  leftIcon={Umbrella}
                  value={insurerStatuses()}
                  className={classes.filterItem}
                >
                  <KlaimMultiSelect onChange={insurerErxChange} value={selectedErxInsurer} options={eRxInsurerData} />
                </KlaimDropdownContainer>
              )}

              {requestAuthorizationUI && (
                <div className={`${classes.mr20} ${classes.filterItem}`}>
                  <button className={classes.klaimBtn} type="submit">
                    Request Authorization
                  </button>
                </div>
              )}

              {searchEpisode && (
                <div className={classes.inputWrapper} id="search-erx-list">
                  <Input
                    inputContainerClassName={classes.inputContainer}
                    placeholder="Search episode"
                    iconLeft={searchIcon}
                    onChange={(e) => setSearchEpisode(e.target.value)}
                  />
                </div>
              )}

              {dispenseAuthorzationUI && <div className={classes.filterItem}>{HeaderButtons()}</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
