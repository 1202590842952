import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const GENDER_SELECT = [
  {label: 'Male', value: 'Male'},
  {label: 'Female', value: 'Female'},
];

export const JOB_POSITION = [
  {label: 'CEO', value: 'CEO'},
  {label: 'CTO', value: 'CTO'},
  {label: 'Team Leader', value: 'Team Leader'},
  {label: 'Manager', value: 'Manager'},
  {label: 'Executive', value: 'Executive'},
];

export interface IRouteParams {
  path: string;
}

export interface IValues {
  [field: string]: any;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    editBodyInput: {
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        width: '450px',
        marginTop: 10,
      },
      '& select': {
        width: '450px',
      },
      '& span': {
        cursor: 'pointer',
      },
      width: '420px',
    },
    mb40: {
      marginBottom: '40px',
    },
    editEmailInput: {
      '& p': {
        color: Colors.grayLighter,
        fontSize: '13px',
        letterSpacing: '0px',
        opacity: '1',
      },
    },
    emailVerificationStatus: {
      '& p': {
        marginTop: '0 !important',
      },
      display: 'flex',
    },
    verificationStatus: {
      background: Colors.yellow,
      borderRadius: '25px',
      height: '14px',
      width: '14px',
    },
  };
});
