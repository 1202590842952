import React from 'react';
import * as Yup from 'yup';
import {Field, FieldProps} from 'formik';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import {IValues} from 'modules/Settings/features/AccountSettings/EditInfo/EditInfo';
import {useAppDispatch} from 'modules/App/store';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import * as Toast from 'modules/Common/utils/toast';

import PermissionBox from 'modules/Settings/features/AccessManagement/PermissionBox';
import {
  accessManagementSelect,
  addRoles,
  addRolesCleanUp,
  getPermissions,
  permissionCleanup,
} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import {useStylesFromThemeFunction} from 'modules/Settings/features/AccessManagement/RoleAdd/RoleAdd';

const RoleAdd: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    isCreateSuccess,
    permissions,
    role,
    errors: {message},
    error: {message: errorMessage},
  } = useSelector(accessManagementSelect);

  const initialValues = {
    name: '',
    description: '',
    permissions: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Title is required'),
    permissions: Yup.array().required('Permissions are required'),
  });

  const onSubmit = (values: IValues) => {
    dispatch(
      addRoles({
        name: values.name,
        description: values.description,
        permissions: values.permissions,
      }),
    );
  };

  // React.useEffect(() => {
  //   setNewPermissions(())
  // }, [selected])

  React.useEffect(() => {
    dispatch(getPermissions());
  }, []);

  React.useEffect(() => {
    if (isCreateSuccess) {
      history.push('/settings/access-management');
      Toast.success('Role created successfully', 'Success');
    }
    return () => {
      dispatch(addRolesCleanUp());
      dispatch(permissionCleanup());
    };
  }, [isCreateSuccess]);

  return (
    <KlaimForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange>
      <Header
        title="Create New Role"
        backlink="/settings/access-management"
        description={<p>Create new roles by adding or modifying access rights.</p>}
        buttonType="submit"
        buttonTitle="Create Role"
        body={
          <div className={classes.bodyWrapper}>
            {errorMessage && (
              <div className={classes.errorContainer}>
                <span>&#9679;</span>
                {errorMessage}
              </div>
            )}
            {message && (
              <div className={classes.errorContainer}>
                {message.map((mess: any, index: number) => (
                  <div key={index}>
                    <span>&#9679;</span>
                    {mess}
                  </div>
                ))}
              </div>
            )}
            <div className={classes.inputContainer}>
              <div className={classes.input}>
                <KlaimInput name="name" label="Role Title" placeholder="ex. Administrator" />
              </div>
              <div className={classes.input}>
                <KlaimInput name="description" label="Description" placeholder="ex. Organization Admin" optional />
              </div>
            </div>

            <Field name="permissions">
              {({form: {values, setFieldValue}, meta, field}: FieldProps) => {
                return (
                  <PermissionBox
                    setFieldValues={setFieldValue}
                    permissions={permissions}
                    rolePermissions={role.permissions}
                  />
                );
              }}
            </Field>
          </div>
        }
      />
    </KlaimForm>
  );
};

export default RoleAdd;
