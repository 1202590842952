import React, {FC, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {headerSelect, setRequiredFilters, setSubmitButtonOnClick} from 'modules/Header/headerSlice';
import {useStylesFromThemeFunction} from './Components/MidTableForm';
import Patient from './Components/Patient';
import VitalSigns from './Components/VitalSigns';
import ClaimDiagnosis from './Components/Diagnosis';
import ClaimActivities from './Components/ClaimActivities';
import {useAppDispatch, useAppSelector} from '../../../App/store';
import {errorCleanUp, getClaim, getNationalities, midtableSelect, postClaimsBulk} from '../../midTableSlice';
import * as Toast from '../../../Common/utils/toast';
import Attachments from './Components/Attachments';

const Component: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const {midtable, apiCallSuccess, error, nationalities} = useAppSelector(midtableSelect);
  const {submitOnClick} = useAppSelector(headerSelect);

  const {id, providerId}: {id: string; providerId: string} = useParams();
  useEffect(() => {
    dispatch(
      setRequiredFilters({
        submitButton: false,
        submitAllButton: false,
        submitFormButton: true,
      }),
    );
    dispatch(
      getClaim({
        id,
        providerId,
      }),
    );
    // dispatch(getNationalities({providerId}));
  }, [location.pathname]);
  const [preAuth, setPreAuth] = useState();

  useEffect(() => {
    if (submitOnClick) {
      dispatch(
        postClaimsBulk({
          providerId,
          hisIds: [midtable.hisId],
        }),
      );
      dispatch(setSubmitButtonOnClick(false));
      history.push('/midtable');
    }
  }, [submitOnClick]);

  useEffect(() => {
    if (error) {
      Toast.error('There was an error while treating your request');
      dispatch(errorCleanUp());
    }
  }, [error]);

  useEffect(() => {
    if (apiCallSuccess) {
      Toast.success('Operation succeeded');
      dispatch(errorCleanUp());
      dispatch(
        getClaim({
          id,
          providerId,
        }),
      );
    }
  }, [apiCallSuccess]);

  return (
    <div className={classes.container} style={{padding: 16, marginTop: 32}}>
      <Patient data={midtable} providerId={providerId} nationalities={nationalities} onPreAuthChange={setPreAuth} />
      <VitalSigns data={midtable} providerId={providerId} />
      <ClaimDiagnosis data={midtable} providerId={providerId} />
      <Attachments data={midtable} />
      <ClaimActivities data={midtable} />
      {/* <ClaimLab data={midtable} /> */}
      {/* <ClaimRadiology data={midtable} /> */}
    </div>
  );
};

export default Component;
