import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FieldArray, getIn} from 'formik';
import {ClaimActivity} from 'modules/Claims/api/types';
import AsyncSearchSelect from '../../../Common/components/AsyncSearchSelect';
import Input from '../../../Common/components/Input';
import SelectOptions from '../../../Common/components/Select';
import {ActivityType, Drug, DrugPackage, ObservationType} from '../../features/PriorRequest/types';
import Button from '../../../Common/components/Button';
import {searchErxDrug} from '../../api';
import RemoveIcon from '../../../../assets/component/RemoveIcon';

interface ComponentProps {
  handleChange: (e: any) => void;
  values: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  classes: any;
}

const Drugs: React.FC<ComponentProps> = ({values, handleChange, setFieldTouched, errors, touched, classes}) => {
  const {t} = useTranslation();
  const [selDrugs, setSelDrugs] = useState<Array<Drug>>([]);

  const handleInputChange = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const getErxDrug = async (drugSearch: string) => {
    try {
      const {data} = await searchErxDrug(drugSearch, values.authorityCode);
      if (data) {
        const drugData = data.data.map((drug: Drug) => {
          return {
            generic: false,
            name: `${drug.name} – ${drug.strength}, ${drug.dosageFormPackage}, ${drug.manufacturer}`,
            packagePrice: drug.packagePrice,
            unitPerPackage: drug.unitPerPackage,
            unitPrice: drug.unitPrice,
            label: `${drug.name} – ${drug.strength}, ${drug.dosageFormPackage}, ${drug.manufacturer}`,
            value: drug.drugCode,
          };
        });
        setSelDrugs([...drugData]);
        return drugData;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPackagePrice = (code: string) => {
    if (!selDrugs) return undefined;
    const drug = selDrugs.find((d: any) => d.value === code);
    return drug ? +drug.packagePrice : undefined;
  };

  const getUnitPrice = (code: string) => {
    if (!selDrugs) return undefined;
    const drug = selDrugs.find((d: any) => d.value === code);
    if (!drug) return;
    if (drug.unitPrice) return drug.unitPrice;
    if (drug.packagePrice && drug.unitPerPackage) return (+drug.packagePrice / +drug.unitPerPackage).toFixed(2);
  };

  const getNetPrice = (activity: any) => {
    const {quantity} = activity;
    if (!quantity || quantity.isNaN) return;

    const unit = activity.unit as 'pack' | 'unit';
    if (unit === 'pack') {
      const packagePrice = getPackagePrice(activity.code);
      if (!packagePrice) return;
      activity.net = (packagePrice * quantity).toFixed(2);
      return (packagePrice * quantity).toFixed(2);
    }

    const unitPrice = getUnitPrice(activity.code);
    if (!unitPrice) return;
    activity.net = (+unitPrice * quantity).toFixed(2);
    return (+unitPrice * quantity).toFixed(2);
  };
  const scrollToElementId = (elementId: string) => {
    setTimeout(() => {
      const itemToScrollTo = document.getElementById(elementId);
      if (itemToScrollTo) itemToScrollTo.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, 0);
  };

  const scrollToDrug = (drugs: any) => {
    const drugId = drugs.length + 1;
    scrollToElementId(`drug-${drugId}`);
  };

  const getLastDrugId = (activity: Array<ClaimActivity>) => {
    return activity[activity.length - 1].activityId;
  };

  return (
    <FieldArray
      name="activities"
      render={(drugsHelper) => (
        <>
          {values.activities &&
            values.activities.length &&
            values.activities.map((activity: any, index: number) => (
              <div key={activity.activityId}>
                <div className={classes.formSectionElementsWrapper} id={`drug-${index + 1}`}>
                  <h2 className={`${classes.dFlex} ${classes.justifyContentBetween}`}>
                    {t(`Drug #${index + 1}`)}
                    {values.activities.length !== 1 && (
                      <>
                        <div className={classes.separatorBorder} />
                        <div
                          role="button"
                          className={classes.removeDrugBtn}
                          onClick={() => {
                            drugsHelper.remove(values.activities.indexOf(activity));
                          }}
                        >
                          <RemoveIcon />
                        </div>
                      </>
                    )}
                  </h2>
                  <div className={classes.rowInputs}>
                    <AsyncSearchSelect
                      name={`activities[${index}].code`}
                      className={`${classes.w100} ${classes.pm0}`}
                      placeholder="Code"
                      loadOptions={getErxDrug}
                      value={values.activities[index].code}
                      onChange={(e) => {
                        handleInputChange(e, values.activities[index], 'code');
                      }}
                      label="Code"
                      error={
                        errors.activities &&
                        errors.activities[index] &&
                        errors.activities[index].code &&
                        getIn(touched, `activities[${index}].code`)
                          ? errors.activities[index].code
                          : null
                      }
                    />
                  </div>
                  <div className={classes.rowInputs}>
                    <Input
                      name={`activities[${index}].quantity`}
                      value={activity.quantity}
                      onChange={handleChange}
                      label="Quantity"
                      placeholder="6 tablets"
                      className={classes.positionRelative}
                      error={
                        errors.activities &&
                        errors.activities[index] &&
                        errors.activities[index].quantity &&
                        getIn(touched, `activities[${index}].quantity`)
                          ? errors.activities[index].quantity
                          : null
                      }
                    />
                    <SelectOptions
                      name={`activities[${index}].unit`}
                      options={DrugPackage}
                      label={t('Unit')}
                      value={activity.unit}
                      onChange={(e) => handleInputChange(e, values.activities[index], 'unit')}
                      selectClassName={classes.rowInput}
                    />
                    <Input
                      name={`activities[${index}].duration`}
                      value={activity.duration}
                      onChange={(e) => handleInputChange(e, values.activities[index], 'duration')}
                      placeholder="Days"
                      label="Duration"
                      className={classes.positionRelative}
                      error={
                        errors.activities &&
                        errors.activities[index] &&
                        errors.activities[index].duration &&
                        getIn(touched, `activities[${index}].duration`)
                          ? errors.activities[index].duration
                          : null
                      }
                    />
                    <div className={classes.cardInfoSection}>
                      Package (SAR)
                      <span>{getPackagePrice(activity.code)}</span>
                    </div>
                    <div className={classes.cardInfoSection}>
                      Unit (SAR)
                      <span>{getUnitPrice(activity.code)}</span>
                    </div>
                    <div className={classes.cardInfoSection}>
                      Net (SAR)
                      <span>{getNetPrice(activity)}</span>
                    </div>
                  </div>
                </div>
                {index + 1 === values.activities.length && (
                  <div className={classes.formSectionElementsWrapper} key={`addDrug-${index}`}>
                    <div className={`${classes.dFlex} ${classes.justifyContentBetween}`}>
                      <h2>{t(`Drug #${index + 2}`)}</h2>
                      <div className={classes.separatorBorder} />
                      <Button
                        title="+"
                        type="button"
                        buttonClassName={classes.addActivityBtn}
                        onClick={() => {
                          drugsHelper.push({
                            activityId: (+getLastDrugId(values.activities) + 1).toString(),
                            start: '',
                            type: ActivityType.Drug,
                            code: '',
                            quantity: '',
                            unit: '',
                            net: '',
                            duration: '',
                            observations: [
                              {
                                type: ObservationType.Text,
                                code: 'Duration',
                                value: '',
                                valueType: 'Days',
                              },
                            ],
                          });
                          scrollToDrug(values.activities);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </>
      )}
    />
  );
};

export default Drugs;
