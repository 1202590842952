import React, {FC, useCallback, useState} from 'react';
import EllipsisText from 'react-ellipsis-text';
import {createUseStyles} from 'react-jss';
import uploadFileCloudIcon from 'assets/upload_file_cloud_icon.svg';
import {useDropzone} from 'react-dropzone';
import dayjs from 'dayjs';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import RemoveIcon from 'assets/component/RemoveIcon';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimIconButton from '../KlaimIconButton';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    dragAndDropText: {
      cursor: 'pointer',
      padding: 10,
      margin: '0 !important',
      color: 'rgba(48, 46, 56, 0.6)',
      width: '100%',
      textAlign: 'center',
      '& p': {
        margin: 0,
      },
      '& span': {
        color: Colors.purple,
      },
    },
    label: {
      color: '#222b45',
      fontSize: 12,
      fontWeight: theme.font.weightSemibold,
      marginLeft: 10,
    },
    uploadBox: {
      alignItems: 'center',
      background: '#F5F7FB',
      borderColor: '#E7ECF5',
      borderRadius: 10,
      borderStyle: 'dotted',
      borderWidth: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      // minHeight: 150,
    },
    uploadBoxActive: {
      borderColor: '#6135FB',
    },
    uploadBoxRow: {
      flexDirection: 'row',
      minHeight: '36px !important',
      alignItems: 'center',
      '& p': {
        marginTop: 'unset !important',
        marginLeft: '10px',
      },
    },
    uploadingCont: {
      margin: '0 auto',
      width: '90%',
      '& .imgCont': {
        verticalAlign: 'top',
        display: 'inline-block',
        width: 40,
        height: 40,
        borderRadius: 100,
        background: Colors.white,
        textAlign: 'center',
        '& img': {
          width: '60%',
          marginTop: 10,
        },
      },
      '& .filesList': {
        verticalAlign: 'top',
        display: 'inline-block',
        width: 'calc(100% - 60px)',
        textAlign: 'left',
        marginLeft: 10,
        '& .title': {
          color: Colors.black,
          fontSize: 14,
          fontWeight: '600',
        },
        '& .list': {
          marginTop: 5,
          '& span': {
            color: Colors.blueGrayDark,
          },
        },
        '& .progressCont': {
          width: '100%',
          marginTop: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .progress': {
            display: 'inline-block',
            width: '85%',
            height: 10,
            borderRadius: 5,
            background: Colors.grayLight,
            '& .bar': {
              height: 10,
              borderRadius: 5,
              backgroundImage: `linear-gradient(to right, ${Colors.purple} , ${Colors.turquoiseBlue})`,
            },
          },
          '& .percent': {
            display: 'inline',
            width: 30,
            marginLeft: 10,
          },
        },
      },
    },
  };
});

export interface IFileUploadResponse {
  file: any;
  fileName: string;
  base64StringFile: string;
}

interface ComponentProps {
  label?: string;
  className?: string;
  theme?: KlaimTheme;
  acceptedFileTypes?: string;
  fieldValue?: string;
  type?: string;
  onChange?: (e: IFileUploadResponse | IFileUploadResponse[]) => void;
  onSelected?: (e: any) => void;
  multiple?: boolean;
  dropAreaText?: string;
  percentage?: number;
}

enum LayoutType {
  Column = 'column',
  Row = 'row',
}

const FileUpload: FC<ComponentProps> = ({
  label,
  className,
  theme,
  acceptedFileTypes,
  type = LayoutType.Column,
  onChange,
  onSelected,
  multiple,
  dropAreaText,
  percentage,
}) => {
  const classes = useStylesFromThemeFunction({theme});

  const onDrop = useCallback(async (acceptedFiles) => {
    await handleFileUpload(acceptedFiles);
  }, []);

  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxSize: 5 * 1024 * 1024,
    maxFiles: 10,
    multiple,
  });

  const handleFileUpload = async (files: File[]) => {
    // if (files.length === 1) {
    //   const fileBase64 = await getBase64FromFile(files[0]);
    //   if (onChange)
    //     onChange({
    //       file: files[0],
    //       fileName: files[0].name,
    //       base64StringFile: fileBase64,
    //     });
    // } else if (files.length > 1) {
    const promises = files.map(async (file) => {
      const result = await getBase64FromFile(file);
      return {
        file,
        fileName: file.name,
        base64StringFile: result,
      };
    });
    Promise.all(promises).then((_result) => {
      if (onChange) onChange(_result);
    });
    // }
  };

  const files = acceptedFiles.map((file: File, i) => (
    <div
      key={file.name}
      style={{
        display: 'flex',
        textDecoration: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <EllipsisText text={`${file.name} - ${dayjs(file.lastModified).format('MM/DD/YYYY')}`} length={'30'} />
      <KlaimIconButton
        style={{
          border: `1px solid ${Colors.gray}`,
          borderRadius: 5,
          display: 'flex',
        }}
        onClick={() => remove(i)}
      >
        <RemoveIcon fill={Colors.grayLighter} />
      </KlaimIconButton>
    </div>
  ));

  const remove = (file: number) => {
    const newFiles = [...files];
    acceptedFiles.splice(file, 1);
  };

  const getBase64FromFile = async (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    await new Promise<void>((resolve, reject) => {
      reader.onload = () => resolve();
      reader.onerror = () => reject();
    });
    return btoa(reader.result as string);
  };

  return (
    <div>
      {label && <div className={classes.label}>{label}</div>}
      <div
        className={`${classes.uploadBox} ${isDragActive ? classes.uploadBoxActive : ''} ${
          type === LayoutType.Row && classes.uploadBoxRow
        }`}
        {...getRootProps()}
      >
        <input onChange={onSelected} {...getInputProps()} />
        <p id="upload-text" className={classes.dragAndDropText}>
          {acceptedFiles.length <= 0 && (
            <>
              {dropAreaText} or <span>click here</span>
            </>
          )}
          {acceptedFiles.length > 0 && (
            <div className={classes.uploadingCont}>
              <div className="imgCont">
                <img src={uploadFileCloudIcon} alt="upload-file" />
              </div>
              <div className="filesList">
                <div className="title">Uploading File</div>
                <div className="list">{files}</div>
                <div className="progressCont">
                  <div className="progress">
                    <div className="bar" style={{width: `${percentage}%`}} />
                  </div>
                  <div className="percent">{percentage}%</div>
                </div>
              </div>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default FileUpload;
