import React from 'react';
import {
  KlaimCollapsible,
  KlaimCollapsibleHeader,
  KlaimCollapsibleBody,
} from 'modules/Common/components-v2/KlaimCollapsible';
import {KlaimIconButton, KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import RemoveIcon from 'assets/component/RemoveIcon';
import {ICareTeam} from 'interfaces/pre-auth.interface';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import CareTeamModal from './CareTeamModal';
import KlaimContainer from '../../../Common/components-v2/KlaimContainer';

interface ComponentProps {
  onChange: (diagnosisList: ICareTeam[]) => void;
  initialValues: any[];
  removeRelatedActivity: (index: number) => void;
}

const CareTeamSubForm: React.FC<ComponentProps> = ({onChange, initialValues, removeRelatedActivity}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isRemoveOpen, setIsRemoveOpen] = React.useState<boolean>(false);
  const [careTeam, setCareTeam] = React.useState<any>();
  const [careTeams, setCareTeams] = React.useState<any[]>(initialValues);
  const [selectedCareTeamIndex, setSelectedCareTeamIndex] = React.useState<any>(undefined);
  const handleSubmit = (value: any) => {
    // setCareTeams([...careTeams, value]);
    if (selectedCareTeamIndex === undefined) {
      setCareTeams([...careTeams, {...value}]);
    } else {
      setCareTeams((state) => {
        const arr = [...state];
        arr[selectedCareTeamIndex] = {...value};
        return [...arr];
      });
    }
    setIsOpen(false);
  };

  const handleEdit = (index: number) => {
    setSelectedCareTeamIndex(index);
    setIsOpen(true);
  };

  const handleRemove = (index?: number) => {
    if (index !== undefined) {
      setCareTeams(careTeams.filter((_, i: number) => i !== index));
      removeRelatedActivity(index + 1);
    } else {
      setCareTeams(careTeams.filter((_, i: number) => i !== careTeam.index));
      removeRelatedActivity(careTeam.index + 1);
    }
    setIsRemoveOpen(false);
  };
  React.useEffect(() => {
    if (!isOpen) setSelectedCareTeamIndex(undefined);
  }, [isOpen]);

  React.useEffect(() => {
    if (onChange) onChange(careTeams);
  }, [careTeams]);

  return (
    <div style={{marginTop: 20}}>
      <KlaimContainer variant="secondary" style={{padding: 20}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <div>
            <h3 style={{fontSize: 14, fontWeight: 600, color: '#222B45', marginBottom: 8}}>
              Care Team{careTeams.length > 0 && ` (${careTeams.length})`}
            </h3>
            <p style={{maxWidth: 500, fontSize: 12, color: '#696F81'}}>Add care team.</p>
          </div>
          <KlaimButton variant="link" onClick={() => setIsOpen(true)}>
            Add Care Team
          </KlaimButton>
        </div>
        {careTeams.map((careteam, index) => (
          <KlaimCollapsible variant={'primary-outline'} key={index} style={{marginBottom: 10}}>
            <KlaimCollapsibleHeader>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div
                  style={{
                    color: '#6135F9',
                    fontSize: 15,
                    fontWeight: 600,
                    textTransform: 'capitalize',
                  }}
                >
                  {`${careteam.practitioner ? careteam.practitioner.name : ''} - ${careteam.careTeamRoleCode}`}
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <KlaimButton
                    style={{
                      minWidth: 'initial',
                      minHeight: 'initial',
                      backgroundColor: '#00000000',
                      color: '#6135FB',
                      borderRadius: 2,
                      border: 0,
                      padding: 6,
                    }}
                    onClick={() => handleEdit(index)}
                  >
                    Edit
                  </KlaimButton>
                  <KlaimButton
                    style={{
                      minWidth: 'initial',
                      minHeight: 'initial',
                      backgroundColor: '#00000000',
                      color: '#F00',
                      borderRadius: 2,
                      border: 0,
                      padding: 6,
                    }}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </KlaimButton>
                </div>
                {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 4, fontSize: 12}}>Remove</span>
                    <KlaimIconButton
                      style={{
                        backgroundColor: '#000000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 2,
                      }}
                      onClick={() => {
                        setIsRemoveOpen(true);
                        setCareTeam({...careteam, index});
                      }}
                    >
                      <RemoveIcon fill={'white'} />
                    </KlaimIconButton>
                  </div> */}
              </div>
            </KlaimCollapsibleHeader>
            <KlaimCollapsibleBody>
              <div style={{padding: '0px 40px 0px 40px'}}>
                <div
                  style={{
                    textTransform: 'capitalize',
                    fontSize: 15,
                  }}
                >
                  <span style={{marginRight: 4}}>{`License Number: ${careteam.practitionerCode}`}</span>
                </div>
                <div style={{marginTop: 10}}>
                  <span style={{fontSize: 13, fontWeight: 600}}>On Site:</span>
                  <span style={{fontSize: 14}}>{careteam.onSite ? 'Yes' : 'No'}</span>
                </div>
              </div>
            </KlaimCollapsibleBody>
          </KlaimCollapsible>
        ))}
      </KlaimContainer>
      <CareTeamModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={handleSubmit}
        selectedCareTeamIndex={selectedCareTeamIndex}
        careTeams={careTeams}
      />
      <KlaimModal open={isRemoveOpen} setOpen={setIsRemoveOpen}>
        <KlaimModalHeader title="Remove Care Team" />
        <KlaimModalBody style={{padding: 20}}>
          If you remove a care team, related Activity will also be removed. Are you sure you want to proceed?
        </KlaimModalBody>
        <KlaimModalFooter submitButtonTitle="Yes" onSubmit={handleRemove} />
      </KlaimModal>
    </div>
  );
};

export default CareTeamSubForm;
