import React, {FC, useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {Link} from 'react-router-dom';
import Button from 'modules/Common/components/Button';
import {Row} from 'react-grid-system';
import queryString from 'query-string';
import {useHistory, useLocation} from 'react-router';
import dayjs from 'dayjs';
import * as Toast from 'modules/Common/utils/toast';
import {getClaimById} from 'modules/Claims/api';
import {Activity, ClaimDetailsInterface, Diagnosis} from 'modules/Claims/api/types';
import ClaimStatusField from 'modules/Claims/components/ClaimStatusField';
import {Colors} from 'modules/Common/constants/Colors';
import Timeline from 'modules/Claims/components/Timeline';
import backArrow from 'assets/back-arrow.svg';
import {actions, events, Mixpanel} from 'services/mixpanel';
import {useAppSelector} from 'modules/App/store';
import {headerSelect} from 'modules/Header/headerSlice';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    headingTwo: {
      position: 'relative',
      top: 35,
      right: 15,
      marginBottom: 30,
    },
    backArrow: {
      position: 'relative',
      bottom: 15,
      left: 10,
    },
    cardSection: {
      '& h2': {
        color: Colors.purpleDark,
        margin: 0,
        marginBottom: '20px',
      },
      background: 'white',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 25px 20px 25px',
      marginBottom: '20px',
      '& $cardRow:last-child': {
        borderBottom: 'unset !important',
        marginBottom: 'unset !important',
        paddingBottom: 'unset !important',
      },
    },
    cardRow: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    backBtn: {
      position: 'relative',
      left: 800,
      bottom: 10,
    },
    cardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
      },
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: Colors.pinkDark,
      fontWeight: 600,
      borderBottom: 'none !important',
    },
    claimStatus: {
      marginRight: '10px',
      width: 12,
      height: 12,
      background: Colors.lightGreen,
      borderRadius: '100%',
    },
    activityCard: {
      display: 'flex',
      position: 'relative',
      minHeight: '330px',
      overflow: 'hidden',
      width: '100%',
      height: 'auto',
    },
    verticalBorder: {
      display: 'flex',
      justifyContent: 'center',
      borderLeft: `1px solid ${Colors.pinkLight}`,
      margin: '0 50px',
      position: 'relative',
    },
    flex3: {
      flex: 3,
    },
    mr10: {
      marginRight: '10px',
    },
    drugCard: {
      width: '50%',
      height: 'max-content',
    },
    '@media screen and (max-width: 1440px)': {
      activityCard: {
        flexDirection: 'column',
      },
      drugCard: {
        width: '100%',
      },
      verticalBorder: {
        borderTop: `1px solid ${Colors.pinkLight}`,
        borderLeft: 'unset',
        margin: '20px 0',
      },
    },
    '@media screen and (max-width: 1024px)': {
      cardRow: {
        flexWrap: 'wrap',
      },
      cardInfoSection: {
        flex: '1 1 auto',
        marginBottom: '5px',
      },
    },
  };
});

const encounterStartType = (type?: number) => {
  switch (type) {
    case 1:
      return 'Elective';
    case 2:
      return 'Emergency';
    case 3:
      return 'Transfer';
    case 4:
      return 'Live birth';
    case 5:
      return 'Still birth';
    case 6:
      return 'Dead On Arrival';
    case 7:
      return 'Continuing encounter';
    default:
      return type || '-';
  }
};

const encounterType = (type?: number) => {
  switch (type) {
    case 1:
      return 'No bed, no emergency room';
    case 2:
      return 'No bed, emergency room';
    case 3:
      return 'Inpatient bed, no emergency room';
    case 4:
      return 'Inpatient bed, emergency room';
    case 5:
      return 'Daycase bed, no emergency room';
    case 6:
      return 'Daycase bed, emergency room';
    case 7:
      return 'Nationals screening';
    case 8:
      return 'New visa screening';
    case 9:
      return 'Renewal visa screening';
    case 12:
      return 'Home';
    case 13:
      return 'Assisted living facility';
    case 15:
      return 'Mobile unit';
    case 41:
      return 'Ambulance land';
    case 42:
      return 'Ambulance air and/or water';
    default:
      return type || '-';
  }
};

const encounterEndType = (type?: number) => {
  switch (type) {
    case 1:
      return 'Discharged with approval';
    case 2:
      return 'Discharged against advice';
    case 3:
      return 'Discharged absent without leave';
    case 4:
      return 'Discharge transfer to acute care';
    case 5:
      return 'Deceased';
    case 6:
      return 'Not discharged';
    case 7:
      return 'Discharge transfer to non acute care';
    default:
      return type || '-';
  }
};

const activityPerformedType = (type?: number) => {
  switch (type) {
    case 3:
      return 'CPT';
    case 4:
      return 'HCPCS';
    case 5:
      return 'Drug';
    case 6:
      return 'Dental';
    case 8:
      return 'Service Code';
    case 9:
      return 'DRG';
    case 10:
      return 'Scientific Code';
    default:
      return type || '-';
  }
};

const ClaimDetails: FC = () => {
  const classes = useStylesFromThemeFunction();
  const [claimData, setClaimData] = useState<ClaimDetailsInterface | null>();
  const location = useLocation();
  const {claimId} = queryString.parse(location.search);
  const DATE_FORMAT = 'DD MMM YYYY HH:MM';
  const history = useHistory();
  const {selectedBranches} = useAppSelector(headerSelect);

  const getClaimDetailsById = async (claimDetailsId?: string) => {
    try {
      const data = await getClaimById(
        claimDetailsId || claimId,
        selectedBranches.length
          ? selectedBranches.map((provider) => provider.value).join(',')
          : localStorage.getItem('_selectedBranches'),
      );
      localStorage.setItem('_selectedBranches', selectedBranches.map((provider) => provider.value).join(','));
      if (data.data) {
        const claim = data.data as ClaimDetailsInterface;
        const timelineHistory = computeTimelineHistory(claim);
        claim.activities.forEach((a) => {
          a.history = activityTimeline(claim, a);
        });
        claim.history = timelineHistory;
        setClaimData(data.data);
      }
    } catch (e) {
      Toast.error(`${e?.response?.data?.details ?? e?.response?.data?.message}`);
      history.push('/claims');
    }
  };

  const computeTimelineHistory = (claim: ClaimDetailsInterface) => {
    const timelineHistory = [];
    const activityIds = claim.activities.map((a) => a.activity_ids);
    const remittanceAdvices = claim.activities.map((a) => a.remittance_advices).flat(1);
    timelineHistory.push(
      ...claim.submissions
        .filter((submission) =>
          submission.activity_ids.some((activityId: any) => activityIds.filter((a) => a === activityId)),
        )
        .filter((submission) => !submission.submission_error)
        .map((submission) => ({
          claimSubmission: submission,
          date: new Date(submission.submission_date),
        })),
    );
    timelineHistory.push(
      ...remittanceAdvices
        .filter((ra: any) => activityIds.filter((a) => a === ra))
        .map((ra: any) => ({
          activityRemittanceAdvice: ra,
          date: new Date(ra.remittance_date),
        })),
    );
    return timelineHistory.sort((item1: any, item2: any) => item1.date.getTime() - item2.date.getTime());
  };

  const activityTimeline = (claim: ClaimDetailsInterface, activity: Activity) => {
    const activityHistory = [];
    const activityIds = activity.activity_ids;
    const remittanceAdvices = activity.remittance_advices.flat(1);
    activityHistory.push(
      ...claim.submissions
        .filter((submission) =>
          submission.activity_ids.some((activityId: any) => activityIds.filter((a) => a === activityId)),
        )
        .filter((submission) => !submission.submission_error)
        .map((submission) => ({
          claimSubmission: submission,
          date: new Date(submission.submission_date),
        })),
    );
    activityHistory.push(
      ...remittanceAdvices
        .filter((ra: any) => activityIds.filter((a) => a === ra))
        .map((ra: any) => ({
          activityRemittanceAdvice: ra,
          date: new Date(ra.remittance_date),
        })),
    );
    return activityHistory.sort((item1: any, item2: any) => item1.date.getTime() - item2.date.getTime());
  };

  useEffect(() => {
    if (claimId) {
      getClaimDetailsById();
      Mixpanel.track(events.claims.claimView, actions.claims.claimView);
    } else {
      history.push('/claims');
    }
  }, [claimId]);

  const ClaimDetail = () => {
    return (
      <div className={classes.cardSection}>
        <Row>
          <div className={classes.backArrow}>
            <Link to={'/claims'}>
              <img src={backArrow} alt="back-arrow" />
            </Link>
          </div>
          <div className={classes.headingTwo}>
            <h2>Claim Details</h2>
          </div>
        </Row>

        <div className={classes.cardRow}>
          <div className={classes.cardInfoSection}>
            Claim ID
            <span>{claimData?.claim_id ? claimData.claim_id : ''}</span>
          </div>
          <div className={classes.cardInfoSection}>
            Claim Status
            <span>
              <ClaimStatusField status={claimData?.status} />
            </span>
          </div>

          <div className={classes.cardInfoSection}>
            # Activities
            <span>{claimData?.activities.length}</span>
          </div>
          {claimData && claimData.payer_id && (
            <div className={classes.cardInfoSection}>
              Payer ID
              <span>{claimData?.payer_id ? claimData?.payer_id : '-'}</span>
            </div>
          )}
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{width: '50%'}}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                Submission:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{dayjs(claimData?.first_submission_date).format(DATE_FORMAT)}</span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '10px',
              }}
            >
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                Remittance Advice:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>-</span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '10px',
                paddingBottom: '30px',
              }}
            >
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                ReSubmissions:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{claimData?.submissions.length}</span>
              </div>
            </div>
          </div>
          <div style={{width: '50%'}}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                Receiver / TPA:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>
                  {claimData?.receiver_id} {claimData?.receiver_name ? ` – ${claimData?.receiver_name}` : ''}
                </span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '10px',
              }}
            >
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                Payer / Network:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>
                  {claimData?.payer_id} {claimData?.payer_name ? ` – ${claimData.payer_name}` : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.cardRow}`}>
          <div className={classes.cardInfoSection}>
            Claimed Amount
            <span>{claimData?.claim.net_amount ? `${claimData.claim.net_amount.toFixed(2)} SAR` : '-'}</span>
          </div>
          <div className={classes.cardInfoSection}>
            Payment Amount
            <span>
              {claimData?.remittance && claimData.remittance.payment_amount
                ? `${claimData.remittance.payment_amount.toFixed(2)} SAR`
                : '-'}
            </span>
          </div>
          {claimData && claimData.remittance && claimData.remittance.denied_amount ? (
            <div className={classes.cardInfoSection}>
              Denied Amount
              <span>
                {claimData?.remittance && claimData.remittance.denied_amount
                  ? `${claimData.remittance.denied_amount.toFixed(2)} SAR`
                  : '-'}
              </span>
            </div>
          ) : (
            ''
          )}
          <div className={classes.cardInfoSection} />
          <div className={classes.cardInfoSection} />
          <div className={classes.cardInfoSection} />
        </div>
      </div>
    );
  };

  const ClaimTimeline = () => {
    return (
      <div className={classes.cardSection}>
        <h2>Timeline</h2>
        <Timeline timeline={claimData?.history || undefined} layout="double" />
      </div>
    );
  };

  const PatientEncounter = () => {
    return (
      <div className={classes.cardSection}>
        <h2>Patient & Encounter</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{width: '50%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: '50%', fontWeight: 600, fontSize: '14px', color: Colors.pinkDark}}>Member ID:</div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{claimData?.member_id ? claimData?.member_id : '-'}</span>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px'}}>
              <div style={{width: '50%', fontWeight: 600, fontSize: '14px', color: Colors.pinkDark}}>
                Encounter Type:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{encounterType(claimData?.encounter.type)}</span>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px'}}>
              <div style={{width: '50%', fontWeight: 600, fontSize: '14px', color: Colors.pinkDark}}>Start Date:</div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{dayjs(claimData?.encounter.start_date).format(DATE_FORMAT)}</span>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px'}}>
              <div style={{width: '50%', fontWeight: 600, fontSize: '14px', color: Colors.pinkDark}}>End Date:</div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{dayjs(claimData?.encounter.end_date).format(DATE_FORMAT)}</span>
              </div>
            </div>
          </div>
          <div style={{width: '50%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                Patient ID:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{claimData?.encounter.patient_id ? claimData?.encounter.patient_id : '-'}</span>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px'}}>
              <div
                style={{
                  width: '50%',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: Colors.pinkDark,
                }}
              >
                Facility ID:
              </div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{claimData?.encounter.facility_id ? claimData?.encounter.facility_id : '-'}</span>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px'}}>
              <div style={{width: '50%', fontWeight: 600, fontSize: '14px', color: Colors.pinkDark}}>Start Type</div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{encounterStartType(claimData?.encounter.type)}</span>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px'}}>
              <div style={{width: '50%', fontWeight: 600, fontSize: '14px', color: Colors.pinkDark}}>End Type</div>
              <div style={{display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '15px'}}>
                <span>{encounterEndType(claimData?.encounter.end_type)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Diagnose = () => {
    return (
      <div className={classes.cardSection}>
        <h2>Diagnosis</h2>
        <div className={`${classes.cardRow}`}>
          {claimData &&
            claimData?.diagnoses.length &&
            claimData.diagnoses.map((diagnose: Diagnosis, index: number) => {
              return (
                <div className={classes.cardInfoSection}>
                  {diagnose.type} <span>{`${diagnose.short_description} - (${diagnose.code})`}</span>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const Activities = () => {
    return (
      <>
        {claimData &&
          claimData?.activities.length &&
          claimData?.activities.map((activity: Activity, index: number) => {
            return (
              <div className={classes.cardSection} key={index}>
                <h2>Activity {index + 1}</h2>
                <div className={classes.activityCard}>
                  <div className={classes.drugCard}>
                    <div className={classes.cardRow}>
                      <div className={classes.cardInfoSection}>
                        Claim Status
                        <span>
                          <div className={classes.claimStatus} />
                          Paid
                        </span>
                      </div>
                      <div className={classes.cardInfoSection}>
                        Activity ID
                        <span>{activity?.active_activity_id ? activity?.active_activity_id : ''}</span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{width: '52%'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div
                            style={{
                              width: '40%',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: Colors.pinkDark,
                            }}
                          >
                            Start Date
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '60%',
                              justifyContent: 'flex-start',
                              fontSize: '15px',
                            }}
                          >
                            <span>{dayjs(activity?.start_date).format(DATE_FORMAT)}</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: '20px',
                          }}
                        >
                          <div
                            style={{
                              width: '40%',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: Colors.pinkDark,
                            }}
                          >
                            Clinician:
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '60%',
                              justifyContent: 'flex-start',
                              fontSize: '15px',
                            }}
                          >
                            <span>{`${activity?.clinician.name} (${activity?.clinician.id})`}</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: '20px',
                          }}
                        >
                          <div
                            style={{
                              width: '40%',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: Colors.pinkDark,
                            }}
                          >
                            Prior Auth ID
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '60%',
                              justifyContent: 'flex-start',
                              fontSize: '15px',
                            }}
                          >
                            <span>
                              {activity?.prior_authorization_provider_id
                                ? activity?.prior_authorization_provider_id
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{width: '48%'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div
                            style={{
                              width: '50%',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: Colors.pinkDark,
                            }}
                          >
                            Code Type:
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              justifyContent: 'flex-start',
                              fontSize: '15px',
                            }}
                          >
                            <span>{activityPerformedType(activity.activity_performed.type)}</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: '20px',
                          }}
                        >
                          <div
                            style={{
                              width: '50%',
                              fontWeight: 600,
                              fontSize: '14px',
                              color: Colors.pinkDark,
                            }}
                          >
                            Drug Code
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              justifyContent: 'flex-start',
                              fontSize: '15px',
                            }}
                          >
                            {!activity?.activity_performed.short_description && (
                              <span>{activity?.activity_performed.code}</span>
                            )}
                            {activity?.activity_performed.short_description && (
                              <span>{`${activity.activity_performed.long_description} (${activity.activity_performed.code})`}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${classes.cardRow}`} style={{marginTop: '70px'}}>
                      <div className={classes.cardInfoSection}>
                        Net Amount <span>{activity.net ? `${activity.net.toFixed(2)} SAR` : '-'}</span>
                      </div>
                      <div className={classes.cardInfoSection}>
                        Payment Amount <span>{activity.payment_amount ? activity.payment_amount : '0'}</span>
                      </div>
                      <div className={classes.cardInfoSection}>
                        Quantity <span>{activity.quantity ? activity.quantity : '-'}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`${classes.verticalBorder} ${classes.mr10}`} />
                  <Timeline timeline={activity.history || undefined} layout="single" />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <ClaimDetail />
      <ClaimTimeline />
      <PatientEncounter />
      <Diagnose />
      <Activities />
    </>
  );
};

export default ClaimDetails;
