import React from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import TopMenu from 'modules/Settings/components/TopMenu';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  branchManagementSelect,
  errorCleanup,
  getBranches,
  IBranch,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import BranchCollapsibleRow from 'modules/Settings/features/BranchManagement/Branches/BranchCollapsibleRow';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {permissions as permissionsConstant} from 'modules/Common/constants/Permissions';
import {useStylesFromThemeFunction} from './Branches';

const Branch: React.FC<RouteComponentProps> = ({history}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {
    branches: {limit, data},
    isLoading,
  } = useAppSelector(branchManagementSelect);

  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [sortBy] = React.useState<string>('');

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const searchParam = query.get('search');
  React.useEffect(() => {
    dispatch(
      getBranches({
        limit,
        offset: (offset - 1) * limit,
        search,
        sortBy,
      }),
    );
  }, [limit, offset, search]);

  React.useEffect(() => {
    return () => {
      dispatch(errorCleanup());
    };
  }, []);

  React.useEffect(() => {
    const searchAction = setTimeout(() => {
      setSearch(query.get('search') || '');
    }, 500);
    return () => clearTimeout(searchAction);
  }, [query.get('search')]);

  return (
    <div className={classes.branchManagementWrapper}>
      <TopMenu
        title="Branch Management"
        description={<p>Look up, add and modify your branches and who has access to each branch.</p>}
        buttonTitle="Add Branch"
        inputPlaceholder="Search branches"
        onButtonClick={() => history.push('/settings/branch-management/add')}
        requiredPermission={permissionsConstant.add_branches.identifier}
      />
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={data.total}
        currentPage={offset}
        onPageChange={setOffset}
        label="branches"
      >
        <p className={classes.tableName}>Branches</p>
        <div className={classes.branchManagement}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <ul className={classes.branchManagementTable}>
              {data.branches.length > 0 ? (
                data.branches.map((branch: IBranch, index: number) => (
                  <BranchCollapsibleRow key={branch.id} branch={branch} index={index} offset={offset} limit={limit} />
                ))
              ) : (
                <div style={{paddingLeft: 30}}>
                  Add your first branch/department by clicking "Add Branch / Department"
                </div>
              )}
            </ul>
          )}
        </div>
      </KlaimPaginationContainer>
    </div>
  );
};

export default Branch;
