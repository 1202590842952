import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    dropdownListContainer: {display: 'flex', flexDirection: 'column', width: 230, position: 'relative'},
    dropdownButtonContainer: {
      display: 'flex',
      overflow: 'hidden',
      borderWidth: 1,
      borderRadius: 10,
      borderColor: Colors.grayLight,
      borderStyle: 'solid',
      height: 40,
    },
    buttonTextContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 4,
      paddingBottom: 4,
      flex: '1 1 0%',
      fontWeight: 700,
      fontSize: 14,
      color: Colors.blueGrayDark,
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      backgroundColor: Colors.purple,
      border: 'none',
      paddingLeft: 16,
      paddingRight: 16,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropdownListChildContainer: {
      width: '80%',
      borderWidth: 1,
      borderRadius: 10,
      borderColor: Colors.gray,
      top: 50,
      position: 'absolute',
      boxShadow: '0px 8px 16px 2px rgb(96 97 112 / 16%)',
      MozBoxShadow: '0px 8px 16px 2px rgba(96, 97, 112, 0.16)',
      WebkitBoxShadow: '0px 8px 16px 2px rgb(96 97 112 / 16%)',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 20,
      backgroundColor: Colors.white,
      padding: 8,
    },
    dropdownLink: {
      paddingTop: 6,
      paddingBottom: 6,
      fontSize: 14,
      '& a': {
        textDecoration: 'none',
        color: Colors.black,
      },
    },
    activeLink: {
      color: Colors.purple,
      fontWeight: 700,
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 5,
      paddingTop: 2,
      paddingBottom: 2,
    },
    hoverLink: {
      background: Colors.blueGrayLight,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 5,
      paddingTop: 2,
      paddingBottom: 2,
    },
    leftIconContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 8,
    },
  };
});

export interface MenuItem {
  link: string;
  title: string;
  leftIcon?: React.FC<any>;
}
