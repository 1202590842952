import React from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import TopMenu from 'modules/Settings/components/TopMenu';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  practitionerManagementSelect,
  errorCleanup,
  getPractitioners,
  Practitioner,
  PractitionersQuery,
} from 'modules/Settings/features/Practitioners/PractitionersSlice';
import ListItem from 'modules/Settings/features/Practitioners/PractitionersList/ListItem';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {
  branchManagementSelect,
  branchesCleanup,
  getBranches,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import {useStylesFromThemeFunction} from '../Practitioners';
import PractitionerModalView from '../PractitionerView';
import {PractitionerQuery} from '../../../../../interfaces/pre-auth.interface';

const CompaniesList: React.FC<RouteComponentProps> = ({history}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {
    practitioners: {total, data},
    isLoading,
  } = useAppSelector(practitionerManagementSelect);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>({});
  const [sortBy] = React.useState<string>('');
  const limit = 10;
  const query = useQuery();
  const searchParam = query.get('search');

  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier);
  };

  React.useEffect(() => {
    if (!isBranchLoading && branches.length === 0) dispatch(getBranches({}));
  }, [branches, isBranchLoading]);

  React.useEffect(() => {
    if (branches.length > 0 && !isBranchLoading) {
      const queryPayload: PractitionersQuery = {
        providerId: getProviderIds().join(','),
        limit,
        offset: (offset - 1) * limit,
      };
      if (search) queryPayload.search = search;
      dispatch(getPractitioners(queryPayload));
    }
  }, [limit, offset, search, sortBy, branches]);

  React.useEffect(() => {
    dispatch(errorCleanup());
    dispatch(branchesCleanup());
  }, []);

  React.useEffect(() => {
    setSearch(searchParam || '');
  }, [searchParam]);

  return (
    <div className={classes.cardWrapper}>
      <PractitionerModalView
        history={history}
        data={selected}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <TopMenu
        title="Practitioners"
        description={<p>Look up, add and modify your staff members. Every invitation sent will also be listed here.</p>}
        buttonTitle="Add Practitioner"
        inputPlaceholder="Search practitioner"
        onButtonClick={() => history.push('/settings/practitioners/add')}
        // Todo: Apply permission
        // requiredPermission={permissionsConstant.add_branches.identifier}
      />
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={total}
        currentPage={offset}
        onPageChange={setOffset}
        label="practitioners"
      >
        <p className={classes.tableName}>Practitioners List</p>
        <div className={classes.container}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <ul className={classes.table}>
              {data.length > 0 ? (
                data.map((practitioner: Practitioner, index: number) => (
                  <ListItem
                    key={index}
                    practitioner={practitioner}
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelected(practitioner);
                    }}
                  />
                ))
              ) : (
                <div style={{paddingLeft: 30}}>Add your first practitioner by clicking "Add Practitioner"</div>
              )}
            </ul>
          )}
        </div>
      </KlaimPaginationContainer>
    </div>
  );
};

export default CompaniesList;
