import {showDefaultError, showError, showNetworkError} from 'utils/form-helpers/errorHandling';
// import {container} from 'ioc-container';
// import Service from 'ioc-container/service-keys';
// import {AuthEventType} from 'services/ComponentNotificationService';
import produce from 'immer';
import axios from 'axios';
// eslint-disable-next-line no-undef
export const commonNonAuthFetch = async (input: RequestInfo, init: RequestInit = {}, showErrors = true) => {
  try {
    let token = localStorage.getItem('accessToken');
    token = token.replace('"', ''); // TODO: Remove this after complete refactor
    token = token.replace('"', ''); // TODO: Remove this after complete refactor
    const fetchInit = produce(init, (draft) => {
      if (token) {
        if (draft.headers) {
          // eslint-disable-next-line no-param-reassign
          (draft.headers as any).Authorization = `Bearer ${token}`;
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.headers = {Authorization: `Bearer ${token}`};
        }
      }
      // eslint-disable-next-line no-param-reassign
      // draft.credentials = 'include';
      return draft;
    });
    // await fetch('https://google.com');
    const result = await fetch(input, fetchInit);
    if (result.status === 500 && showErrors) {
      try {
        const response = await result.json();
        if (response.message) {
          showError('Error', response.message);
        } else {
          showDefaultError();
        }
      } catch (error) {
        showDefaultError();
      }
    }
    return result;
  } catch (error) {
    if (error.name === 'AbortError') {
      throw error;
    }
    // eslint-disable-next-line no-console
    console.error(error);
    if (showErrors) {
      showNetworkError();
    }
    throw error;
  }
};

// eslint-disable-next-line no-undef
export const commonFetch = async (input: RequestInfo, init: RequestInit, showErrors = true) => {
  const result = await commonNonAuthFetch(input, init, showErrors);
  if (result.status === 401) {
    // container.resolve(Service.componentNotification).emit(AuthEventType.UnauthorizedRequest);
  }
  return result;
};

const encodeParam = (key: string, value: any) => {
  if (Array.isArray(value)) {
    return value.map((valueItem) => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(valueItem)}`).join('&');
  }
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

export const encodeQueryFilter = (filter: Record<string, any>) => {
  const filterKeys = Object.keys(filter);
  if (!filterKeys.length) {
    return '';
  }

  return `?${filterKeys.map((key) => encodeParam(key, filter[key])).join('&')}`;
};

type FetchResponse = Awaited<ReturnType<typeof fetch>>;

export class ApiError extends Error {
  private _response: FetchResponse;

  constructor(response: FetchResponse) {
    super('Api error');
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
    this._response = response;
  }

  public get response() {
    return this._response;
  }
}
