import React from 'react';
import * as Yup from 'yup';
import {useHistory, Redirect, RouteComponentProps} from 'react-router-dom';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import Header from 'modules/Settings/components/SubHeader';
import {IRouteParams, IValues, useStylesFromThemeFunction} from './EditInfo';
import {accountSelect, saveAccount, redirectCleanUp} from '../accountSettingsSlice';
import ChangePassword from '../ChangePassword';
import Input from '../../../../Common/components/Input';
import * as Toast from '../../../../Common/utils/toast';

const EditInfo: React.FC<RouteComponentProps<IRouteParams>> = ({match: {params}}) => {
  const {user, redirectTo} = useAppSelector(accountSelect);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStylesFromThemeFunction();
  React.useEffect(() => {
    if (redirectTo !== '') {
      history.push(redirectTo);
      Toast.success('Information is updated successfully');
    }
    return () => {
      dispatch(redirectCleanUp());
    };
  }, [redirectTo]);

  const onSubmit = ({name, email, phone}: IValues) => {
    const emptyCheck = name === null || name.match(/^ *$/) !== null || phone === null || phone.match(/^ *$/) !== null;
    if (emptyCheck === true) {
      if (name === null || (name.match(/^ *$/) !== null) === true) {
        Toast.error('Full Name cannot be empty');
      } else {
        Toast.error('Phone cannot be empty');
      }
    } else {
      dispatch(
        saveAccount({
          name,
          email,
          phone,
        }),
      );
    }
  };

  const initialValues = {
    name: user.name,
    email: user.email || '',
    phone: user.phone || '',
    // episodeUrl: user.episodeUrl || '',
    // invoiceUrl: user.invoiceUrl || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Full name is required').max(25, 'Maximum of 25 characters or less'),
    phone: Yup.string().required('Phone is required').max(17, 'Maximum of 12 digits or less'),
    // episodeUrl: Yup.string().required('episode url is required'),
    // invoiceUrl: Yup.string().required('invoice url is required'),
  });
  const formEdits = [
    {
      path: 'name',
      title: 'Update Name',
      description: <p>Changes to your name and surname will be reflected across your Klaim account.</p>,
      buttonTitle: 'Save Changes',
      body: (
        <div className={classes.editBodyInput}>
          <KlaimInput placeholder="Full Name" label="Full Name" name="name" value={user.name} />
        </div>
      ),
    },
    // {
    //   path: 'episodeUrl',
    //   title: 'Update Episode system url',
    //   description: <p>Change the episode system url</p>,
    //   buttonTitle: 'Save Changes',
    //   body: (
    //     <div className={classes.editBodyInput}>
    //       <KlaimInput placeholder="Episode url" label="Episode url" name="episodeUrl" value={user.episodeUrl} />
    //     </div>
    //   ),
    // },
    // {
    //   path: 'invoiceUrl',
    //   title: 'Update invoice system url',
    //   description: <p>Change the invoice system url</p>,
    //   buttonTitle: 'Save Changes',
    //   body: (
    //     <div className={classes.editBodyInput}>
    //       <KlaimInput placeholder="Invoice url" label="Invoice url" name="invoiceUrl" value={user.invoiceUrl} />
    //     </div>
    //   ),
    // },

    {
      path: 'email',
      title: 'Update Email',
      description: (
        <p>
          This email address is used to identify your Klaim account to you and others. You can’t change this address.
        </p>
      ),
      buttonTitle: 'Save Changes',
      body: (
        <div className={classes.editBodyInput}>
          <div className={classes.editEmailInput}>
            <KlaimInput placeholder="example@email.com" label="Email" name="email" />
          </div>
          <p>This info is private. Only you can see it.</p>
        </div>
      ),
    },
    {
      path: 'phone',
      title: 'Update Phone',
      description: <p>This number can be used to deliver important notifications, help you sign in and more.</p>,
      buttonTitle: 'Save Changes',
      body: (
        <div className={classes.editBodyInput}>
          <div className={classes.editEmailInput}>
            <KlaimInput placeholder="+(000) 00 0000000" label="Phone" name="phone" type="number" value={user.phone} />
          </div>
          <p>This info is private. Only you can see it.</p>
        </div>
      ),
    },
  ];
  const indexForm = formEdits.findIndex((formedit) => formedit.path === params.path);
  if (params.path === 'change-password') {
    return <ChangePassword />;
  }
  if (indexForm !== -1) {
    return (
      <KlaimForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
      >
        <Header {...formEdits[indexForm]} backlink="/settings/account" />
      </KlaimForm>
    );
  }
  return <Redirect to="/settings/account" />;
};

export default EditInfo;
