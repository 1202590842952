import React from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';
import {KlaimScreenContext} from '../../index';
import ChevronArrowDown from '../../../../../../assets/component/ChevronArrowDown';
import {KlaimTheme} from '../../../../../../interfaces/klaim-theme.interface';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    buttonText: {
      color: theme.formElement.color.secondary,
      marginLeft: 4,
    },
  };
});

const KlaimScreenHeader = () => {
  const classes = useStylesFromThemeFunction();
  const {screens, currentScreen, setCurrentScreen} = React.useContext(KlaimScreenContext);
  if (currentScreen.index === 0) return null;
  return (
    <div style={{display: 'flex'}}>
      <KlaimIconButton onClick={() => setCurrentScreen(screens[0])}>
        <div style={{transform: 'rotate(90deg)'}}>
          <ChevronArrowDown />
        </div>
      </KlaimIconButton>
      <div className={classes.buttonText}>Back to {screens.length > 0 && screens[0].name}</div>
    </div>
  );
};

export default KlaimScreenHeader;
