import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import {Payer} from 'interfaces/payers.interfaces';
import {axiosInstance} from 'services/axios';

interface ErrorMessageObject {
  property: string;
  constraints: {
    matches: string;
  };
}

interface GetInsuranceQuery {
  limit?: number;
  offset?: number;
  search?: string;
  sortBy?: string;
  authorityCode?: string;
  branchId?: string;
  classification?: string;
}

interface GetEligibility {
  id: string;
}

interface MyKnownError {
  // stack: {error: {message: string}; message?: ErrorMessageObject[] | string};
  // statusCode: number;
  status: number;
  message: string;
}

interface InitialState {
  payers: {
    data: Payer[];
  };
  payer: any | undefined;
  isLoading: boolean;
  error?: MyKnownError;
}

const initialState: InitialState = {
  payers: {
    data: [],
  },
  payer: undefined,
  isLoading: false,
};

export const getInsurances = createAsyncThunk<any[], any, {rejectValue: MyKnownError}>(
  'insurance/getInsurances',
  async (query: any, {rejectWithValue}) => {
    try {
      let response;
      if (query.branchId) {
        response = await axiosInstance.get(`/insurance/companies/branch/${query.branchId}`);
        response = response.data.insurances;
      } else {
        response = await axiosInstance.get(`/payers`, {params: query});
        response = response.data.data;
      }

      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getInsurance = createAsyncThunk<Payer, GetEligibility, {rejectValue: MyKnownError}>(
  'insurance/getInsurance',
  async (data: GetEligibility, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`payers`, {
        params: {
          payerCode: data.id,
        },
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    payerCleanUp: (state) => {
      state.payer = undefined;
    },
    insuranceCleanup: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsurances.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurances.rejected, (state, action) => {
        state.error = {message: 'Something went Wrong', status: 500};
        state.isLoading = false;
      })
      .addCase(getInsurances.fulfilled, (state, action) => {
        state.payers.data = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(getInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInsurance.rejected, (state, action) => {
        state.error = {message: 'Something went Wrong', status: 500};
        state.isLoading = false;
      })
      .addCase(getInsurance.fulfilled, (state, action) => {
        state.payer = action.payload;
        state.isLoading = false;
      });
  },
});

export const {payerCleanUp, insuranceCleanup} = insuranceSlice.actions;
export const insuranceSelect = (state: RootState) => state.insurance;
export default insuranceSlice.reducer;
