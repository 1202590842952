export const unitOfMeasures = [
  {label: 'Medical Doctor', value: 'Common UCUM Units v1.2'},
  {label: '10 liter per minute', value: '10.L/min'},
  {label: '10 liter per minute per square meter', value: '10.L/(min.m2)'},
  {label: '10 micronewton second per centimeter to the fifth power per square meter', value: '10.uN.s/(cm5.m2)'},
  {label: '10 thousand per microliter', value: '10*4/uL'},
  {label: '100 million', value: '10*8'},
  {label: '24 hour', value: '24.h'},
  {label: 'absorbance', value: '{absorbance}'},
  {label: 'activity', value: '{activity}'},
  {label: 'allergy unit', value: '[AU]'},
  {label: 'American Hospital Formulary unit', value: "{AHF'U}"},
  {label: 'ampere', value: 'A'},
  {label: 'ampere per meter', value: 'A/m'},
  {label: 'arbitrary unit', value: "[arb'U]"},
  {label: 'arbitrary unit per milliliter', value: "[arb'U]/mL"},
  {label: 'aspirin response unit', value: '{ARU}'},
  {label: 'atmosphere', value: 'atm'},
  {label: 'attogram per cell', value: 'ag/{cell}'},
  {label: 'bar', value: 'bar'},
  {label: 'Becquerel', value: 'Bq'},
  {label: 'Bethesda unit', value: "[beth'U]"},
  {label: 'billion per liter', value: '10*9/L'},
  {label: 'billion per microliter', value: '10*9/uL'},
  {label: 'billion per milliliter', value: '10*9/mL'},
  {label: 'binding index', value: '{binding_index}'},
  {label: 'Bodansky unit', value: "[bdsk'U]"},
  {label: 'breaths per minute', value: '{breaths}/min'},
  {label: 'CAG trinucleotide repeats', value: '{CAG_repeats}'},
  {label: 'calorie', value: 'cal'},
  {label: 'cells', value: '{cells}'},
  {label: 'cells per high power field', value: '{cells}/[HPF]'},
  {label: 'cells per microliter', value: '{cells}/uL'},
  {label: 'centigram', value: 'cg'},
  {label: 'centiliter', value: 'cL'},
  {label: 'centimeter', value: 'cm'},
  {label: 'centimeter of mercury', value: 'cm[Hg]'},
  {label: 'centimeter of water', value: 'cm[H2O]'},
  {label: 'centimeter of water per liter per second', value: 'cm[H2O]/L/s'},
  {label: 'centimeter of water per second per meter', value: 'cm[H2O]/s/m'},
  {label: 'centimeter per second', value: 'cm/s'},
  {label: 'centipoise', value: 'cP'},
  {label: 'centistoke', value: 'cSt'},
  {label: 'change in (delta) optical density', value: '{delta_OD}'},
  {label: 'clock time e.g 12:30PM', value: '{clock_time}'},
  {label: 'colony forming unit', value: '[CFU]'},
  {label: 'colony forming unit per liter', value: '[CFU]/L'},
  {label: 'colony forming unit per milliliter', value: '[CFU]/mL'},
  {label: 'complement activity enzyme unit', value: "{CAE'U}"},
  {label: 'complement CH100 unit', value: "{CH100'U}"},
  {label: 'copies', value: '{copies}'},
  {label: 'copies per microgram', value: '{copies}/ug'},
  {label: 'copies per milliliter', value: '{copies}/mL'},
  {label: 'count', value: '{count}'},
  {label: 'counts per minute', value: '{CPM}'},
  {label: 'counts per minute per thousand cells', value: '{CPM}/10*3{cell}'},
  {label: 'cubic centimeter', value: 'cm3'},
  {label: 'cubic inch (international)', value: '[cin_i]'},
  {label: 'cubic meter per second', value: 'm3/s'},
  {label: 'Cycle threshold value', value: '{Ct_value}'},
  {label: 'day', value: 'd'},
  {label: 'day per 7 day', value: 'd/(7.d)'},
  {label: 'days per week', value: 'd/wk'},
  {label: 'decibel', value: 'dB'},
  {label: 'decigram', value: 'dg'},
  {label: 'deciliter', value: 'dL'},
  {label: 'decimeter', value: 'dm'},
  {label: 'degree (plane angle)', value: 'deg'},
  {label: 'degree Celsius', value: 'Cel'},
  {label: 'degree Fahrenheit', value: '[degF]'},
  {label: 'degree Kelvin', value: 'K'},
  {label: 'degree Kelvin per Watt', value: 'K/W'},
  {label: 'degree per second', value: 'deg/s'},
  {label: 'dekaliter per minute', value: 'daL/min'},
  {label: 'dekaliter per minute per square meter', value: 'daL/min/m2'},
  {label: 'dilution', value: '{dilution}'},
  {label: 'diopter', value: '[diop]'},
  {label: 'dram (US and British)', value: '[dr_av]'},
  {label: 'drop (1/12 milliliter)', value: '[drp]'},
  {label: 'dyne second per centimeter', value: 'dyn.s/cm'},
  {label: 'dyne second per centimeter per square meter', value: 'dyn.s/(cm.m2)'},
  {label: 'Ehrlich unit', value: "{Ehrlich'U}"},
  {label: 'Ehrlich unit per 100 gram', value: "{Ehrlich'U}/100.g"},
  {label: 'Ehrlich unit per 2 hour', value: "{Ehrlich'U}/(2.h)"},
  {label: 'Ehrlich unit per day', value: "{Ehrlich'U}/d"},
  {label: 'Ehrlich unit per deciliter', value: "{Ehrlich'U}/dL"},
  {label: 'EIA index', value: '{EIA_index}'},
  {label: 'EIA titer', value: '{EIA_titer}'},
  {label: 'EIA unit', value: "{EIA'U}"},
  {label: 'EIA unit per enzyme unit', value: "{EIA'U}/U"},
  {label: 'EIA value', value: '{EV}'},
  {label: 'electron Volt', value: 'eV'},
  {label: 'ELISA unit', value: "{ELISA'U}"},
  {label: 'enzyme unit', value: 'U'},
  {label: 'enzyme unit per 10', value: 'U/10'},
  {label: 'enzyme unit per 10 billion', value: 'U/10*10'},
  {label: 'enzyme unit per 10 billion cells', value: 'U/10*10{cells}'},
  {label: 'enzyme unit per 10 gram of feces', value: 'U/(10.g){feces}'},
  {label: 'enzyme unit per 12 hour', value: 'U/(12.h)'},
  {label: 'enzyme unit per 2 hour', value: 'U/(2.h)'},
  {label: 'enzyme unit per 24 hour', value: 'U/(24.h)'},
  {label: 'enzyme unit per billion', value: 'U/10*9'},
  {label: 'enzyme unit per day', value: 'U/d'},
  {label: 'enzyme unit per deciliter', value: 'U/dL'},
  {label: 'enzyme unit per gram', value: 'U/g'},
  {label: 'enzyme unit per gram of creatinine', value: 'U/g{creat}'},
  {label: 'enzyme unit per gram of hemoglobin', value: 'U/g{Hb}'},
  {label: 'enzyme unit per gram of protein', value: 'U/g{protein}'},
  {label: 'enzyme unit per hour', value: 'U/h'},
  {label: 'enzyme unit per kilogram of hemoglobin', value: 'U/kg{Hb}'},
  {label: 'enzyme unit per liter', value: 'U/L'},
  {label: 'enzyme unit per liter at 25 deg Celsius', value: 'U{25Cel}/L'},
  {label: 'enzyme unit per liter at 37 deg Celsius', value: 'U{37Cel}/L'},
  {label: 'enzyme unit per milliliter', value: 'U/mL'},
  {label: 'enzyme unit per milliliter of red blood cells', value: 'U/mL{RBCs}'},
  {label: 'enzyme unit per millimole of creatinine', value: 'U/mmol{creat}'},
  {label: 'enzyme unit per million', value: 'U/10*6'},
  {label: 'enzyme unit per minute', value: 'U/min'},
  {label: 'enzyme unit per second', value: 'U/s'},
  {label: 'enzyme unit per trillion', value: 'U/10*12'},
  {label: 'enzyme unit per trillion red blood cells', value: 'U/10*12{RBCs}'},
  {label: 'equivalent', value: 'eq'},
  {label: 'equivalent per liter', value: 'eq/L'},
  {label: 'equivalent per micromole', value: 'eq/umol'},
  {label: 'equivalent per milliliter', value: 'eq/mL'},
  {label: 'equivalent per millimole', value: 'eq/mmol'},
  {label: 'erg', value: 'erg'},
  {label: 'Farad', value: 'F'},
  {label: 'feet (US) per feet (US)', value: '[ft_us]/[ft_us]'},
  {label: 'femtogram', value: 'fg'},
  {label: 'femtoliter', value: 'fL'},
  {label: 'femtometer', value: 'fm'},
  {label: 'femtomole', value: 'fmol'},
  {label: 'femtomole per gram', value: 'fmol/g'},
  {label: 'femtomole per liter', value: 'fmol/L'},
  {label: 'femtomole per milligram', value: 'fmol/mg'},
  {label: 'femtomole per milligram of cytosol protein', value: 'fmol/mg{cyt_prot}'},
  {label: 'femtomole per milligram of protein', value: 'fmol/mg{prot}'},
  {label: 'femtomole per milliliter', value: 'fmol/mL'},
  {label: 'fluid ounce (US)', value: '[foz_us]'},
  {label: 'fluorescent intensity unit', value: '{FIU}'},
  {label: 'foot (international)', value: '[ft_i]'},
  {label: 'fraction', value: '{fraction}'},
  {label: 'French (catheter gauge)', value: '[Ch]'},
  {label: 'GAA trinucleotide repeats', value: '{GAA_repeats}'},
  {label: 'gallon (US)', value: '[gal_us]'},
  {label: 'genomes per milliliter', value: '{genomes}/mL'},
  {label: 'globules (drops) per high power field', value: '{Globules}/[HPF]'},
  {label: 'gram', value: 'g'},
  {label: 'gram meter', value: 'g.m'},
  {label: 'gram meter per heart beat', value: 'g.m/{beat}'},
  {label: 'gram of creatinine', value: 'g{creat}'},
  {label: 'gram of hemoglobin', value: 'g{Hb}'},
  {label: 'gram of total nitrogen', value: 'g{total_nit}'},
  {label: 'gram of total protein', value: 'g{total_prot}'},
  {label: 'gram of wet tissue', value: 'g{wet_tissue}'},
  {label: 'gram per kilogram per 8 hour', value: 'g/kg/(8.h)'},
  {label: 'gram per 100 gram', value: 'g/(100.g)'},
  {label: 'gram per 12 hour', value: 'g/(12.h)'},
  {label: 'gram per 24 hour', value: 'g/(24.h)'},
  {label: 'gram per 3 days', value: 'g/(3.d)'},
  {label: 'gram per 4 hour', value: 'g/(4.h)'},
  {label: 'gram per 48 hour', value: 'g/(48.h)'},
  {label: 'gram per 5 hour', value: 'g/(5.h)'},
  {label: 'gram per 6 hour', value: 'g/(6.h)'},
  {label: 'gram per 72 hour', value: 'g/(72.h)'},
  {label: 'gram per 8 hour shift', value: 'g/(8.h){shift}'},
  {label: 'gram per cubic centimeter', value: 'g/cm3'},
  {label: 'gram per day', value: 'g/d'},
  {label: 'gram per deciliter', value: 'g/dL'},
  {label: 'gram per gram', value: 'g/g'},
  {label: 'gram per gram of creatinine', value: 'g/g{creat}'},
  {label: 'gram per gram of globulin', value: 'g/g{globulin}'},
  {label: 'gram per gram of tissue', value: 'g/g{tissue}'},
  {label: 'gram per hour', value: 'g/h'},
  {label: 'gram per hour per square meter', value: 'g/h/m2'},
  {label: 'gram per kilogram', value: 'g/kg'},
  {label: 'gram per kilogram per 8 hour shift', value: 'g/kg/(8.h){shift}'},
  {label: 'gram per kilogram per day', value: 'g/kg/d'},
  {label: 'gram per kilogram per hour', value: 'g/kg/h'},
  {label: 'gram per kilogram per minute', value: 'g/kg/min'},
  {label: 'gram per liter', value: 'g/L'},
  {label: 'gram per milligram', value: 'g/mg'},
  {label: 'gram per milliliter', value: 'g/mL'},
  {label: 'gram per millimole', value: 'g/mmol'},
  {label: 'gram per minute', value: 'g/min'},
  {label: 'gram per mole of creatinine', value: 'g/mol{creat}'},
  {label: 'gram per specimen', value: 'g/{specimen}'},
  {label: 'gram per square centimeter', value: 'g/cm2'},
  {label: 'gram per square meter', value: 'g/m2'},
  {label: 'gram per total output', value: 'g/{total_output}'},
  {label: 'gram per total weight', value: 'g/{total_weight}'},
  {label: 'Gray', value: 'Gy'},
  {label: 'heart beats per minute', value: '{beats}/min'},
  {label: 'Henry', value: 'H'},
  {label: 'Hertz', value: 'Hz'},
  {label: 'high power field', value: '[HPF]'},
  {label: 'hour', value: 'h'},
  {label: 'hour per day', value: 'h/d'},
  {label: 'hour per week', value: 'h/wk'},
  {label: 'IgA anticardiolipin unit per milliliter**', value: "[APL'U]/mL"},
  {label: 'IgA anticardiolipin unit**', value: "[APL'U]"},
  {label: 'IgA antiphosphatidylserine unit', value: "{APS'U}"},
  {label: 'IgG anticardiolipin unit per milliliter**', value: "[GPL'U]/mL"},
  {label: 'IgG anticardiolipin unit**', value: "[GPL'U]"},
  {label: 'IgG antiphosphatidylserine unit', value: "{GPS'U}"},
  {label: 'IgM anticardiolipin unit per milliliter**', value: "[MPL'U]/mL"},
  {label: 'IgM anticardiolipin unit**', value: "[MPL'U]"},
  {label: 'IgM antiphosphatidylserine unit', value: "{MPS'U}"},
  {label: 'IgM antiphosphatidylserine unit per milliliter', value: "{MPS'U}/mL"},
  {label: 'immune complex unit', value: "{ImmuneComplex'U}"},
  {label: 'immune status ratio', value: '{ISR}'},
  {label: 'immunofluorescence assay index', value: '{IFA_index}'},
  {label: 'Immunofluorescence assay titer', value: '{IFA_titer}'},
  {label: 'inch (international)', value: '[in_i]'},
  {label: 'inch (international) of water', value: "[in_i'H2O]"},
  {label: 'inches (US)', value: '[in_us]'},
  {label: 'index value', value: '{index_val}'},
  {label: 'index value', value: '{index}'},
  {label: 'influenza hemagglutination titer', value: '{HA_titer}'},
  {label: 'international normalized ratio', value: '{INR}'},
  {label: 'international unit', value: '[IU]'},
  {label: 'international unit per 2 hour', value: '[IU]/(2.h)'},
  {label: 'international unit per 24 hour', value: '[IU]/(24.h)'},
  {label: 'international unit per billion red blood cells', value: '[IU]/10*9{RBCs}'},
  {label: 'international unit per day', value: '[IU]/d'},
  {label: 'international unit per deciliter', value: '[IU]/dL'},
  {label: 'international unit per gram', value: '[IU]/g'},
  {label: 'international unit per gram of hemoglobin', value: '[IU]/g{Hb}'},
  {label: 'international unit per hour', value: '[IU]/h'},
  {label: 'international unit per kilogram', value: '[IU]/kg'},
  {label: 'international unit per kilogram per day', value: '[IU]/kg/d'},
  {label: 'international unit per liter', value: '[IU]/L'},
  {label: 'international unit per liter at 37 degrees Celsius', value: '[IU]/L{37Cel}'},
  {label: 'international unit per milligram of creatinine', value: '[IU]/mg{creat}'},
  {label: 'international unit per milliliter', value: '[IU]/mL'},
  {label: 'international unit per minute', value: '[IU]/min'},
  {label: 'joule', value: 'J'},
  {label: 'joule per liter', value: 'J/L'},
  {label: 'Juvenile Diabetes Foundation unit', value: "{JDF'U}"},
  {label: 'Juvenile Diabetes Foundation unit per liter', value: "{JDF'U}/L"},
  {label: 'kaolin clotting time', value: "{KCT'U}"},
  {label: 'katal', value: 'kat'},
  {label: 'katal per kilogram', value: 'kat/kg'},
  {label: 'katal per liter', value: 'kat/L'},
  {label: 'kilo enzyme unit', value: 'kU'},
  {label: 'kilo enzyme unit per gram', value: 'kU/g'},
  {label: 'kilo enzyme unit per liter', value: 'kU/L'},
  {label: 'kilo enzyme unit per liter class', value: 'kU/L{class}'},
  {label: 'kilo enzyme unit per milliliter', value: 'kU/mL'},
  {label: 'kilo international unit per liter', value: 'k[IU]/L'},
  {label: 'kilo international unit per milliliter', value: 'k[IU]/mL'},
  {label: 'kilocalorie', value: 'kcal'},
  {label: 'kilocalorie per 24 hour', value: 'kcal/(24.h)'},
  {label: 'kilocalorie per day', value: 'kcal/d'},
  {label: 'kilocalorie per hour', value: 'kcal/h'},
  {label: 'kilocalorie per kilogram per 24 hour', value: 'kcal/kg/(24.h)'},
  {label: 'kilocalorie per ounce (US & British)', value: 'kcal/[oz_av]'},
  {label: 'kilogram', value: 'kg'},
  {label: 'kilogram meter per second', value: 'kg.m/s'},
  {label: 'kilogram per cubic meter', value: 'kg/m3'},
  {label: 'kilogram per hour', value: 'kg/h'},
  {label: 'kilogram per liter', value: 'kg/L'},
  {label: 'kilogram per minute', value: 'kg/min'},
  {label: 'kilogram per mole', value: 'kg/mol'},
  {label: 'kilogram per second', value: 'kg/s'},
  {label: 'kilogram per second per square meter', value: 'kg/(s.m2)'},
  {label: 'kilogram per square meter', value: 'kg/m2'},
  {label: 'kiloliter', value: 'kL'},
  {label: 'kilometer', value: 'km'},
  {label: 'kilopascal', value: 'kPa'},
  {label: 'kilosecond', value: 'ks'},
  {label: 'King Armstrong unit', value: "[ka'U]"},
  {label: 'Kronus unit per milliliter', value: "{KRONU'U}/mL"},
  {label: 'Kunkel unit', value: "[knk'U]"},
  {label: 'liter', value: 'L'},
  {label: 'liter per 24 hour', value: 'L/(24.h)'},
  {label: 'liter per 8 hour', value: 'L/(8.h)'},
  {label: 'liter per day', value: 'L/d'},
  {label: 'liter per hour', value: 'L/h'},
  {label: 'liter per kilogram', value: 'L/kg'},
  {label: 'liter per liter', value: 'L/L'},
  {label: 'liter per minute', value: 'L/min'},
  {label: 'liter per minute per sqaure meter', value: 'L/min/m2'},
  {label: 'liter per minute per square meter', value: 'L/(min.m2)'},
  {label: 'liter per second', value: 'L/s'},
  {label: 'liter per second per square second', value: 'L/s/s2'},
  {label: 'log (base 10) copies per milliliter', value: '{Log_copies}/mL'},
  {label: 'log (base 10) international unit', value: '{Log_IU}'},
  {label: 'log (base 10) international unit per milliliter', value: '{Log_IU}/mL'},
  {label: 'log base 10', value: '{Log}'},
  {label: 'low power field', value: '[LPF]'},
  {label: 'lumen', value: 'lm'},
  {label: 'lumen square meter', value: 'lm.m2'},
  {label: 'Lyme index value', value: '{Lyme_index_value}'},
  {label: 'Maclagan unit', value: "[mclg'U]"},
  {label: 'megasecond', value: 'Ms'},
  {label: 'metabolic equivalent minute per week', value: '[MET].min/wk'},
  {label: 'meter', value: 'm'},
  {label: 'meter per second', value: 'm/s'},
  {label: 'meter per square second', value: 'm/s2'},
  {label: 'metric ton', value: 't'},
  {label: 'micro enzyme unit per gram', value: 'uU/g'},
  {label: 'micro enzyme unit per liter', value: 'uU/L'},
  {label: 'micro enzyme unit per milliliter', value: 'uU/mL'},
  {label: 'micro international unit', value: 'u[IU]'},
  {label: 'micro international unit per milliliter', value: 'u[IU]/mL'},
  {label: 'microequivalent', value: 'ueq'},
  {label: 'microequivalent per liter', value: 'ueq/L'},
  {label: 'microequivalent per milliliter', value: 'ueq/mL'},
  {label: 'microgram', value: 'ug'},
  {label: 'microgram per gram of feces', value: 'ug/g{feces}'},
  {label: 'microgram fibrinogen equivalent unit per milliliter', value: 'ug{FEU}/mL'},
  {label: 'microgram per 100 gram', value: 'ug/(100.g)'},
  {label: 'microgram per 24 hour', value: 'ug/(24.h)'},
  {label: 'microgram per 8 hour', value: 'ug/(8.h)'},
  {label: 'microgram per cubic meter', value: 'ug/m3'},
  {label: 'microgram per day', value: 'ug/d'},
  {label: 'microgram per deciliter', value: 'ug/dL'},
  {label: 'microgram per deciliter of red blood cells', value: 'ug/dL{RBCs}'},
  {label: 'microgram per gram', value: 'ug/g'},
  {label: 'microgram per gram of creatinine', value: 'ug/g{creat}'},
  {label: 'microgram per gram of dry tissue', value: 'ug/g{dry_tissue}'},
  {label: 'microgram per gram of dry weight', value: 'ug/g{dry_wt}'},
  {label: 'microgram per gram of hair', value: 'ug/g{hair}'},
  {label: 'microgram per gram of hemoglobin', value: 'ug/g{Hb}'},
  {label: 'microgram per gram of tissue', value: 'ug/g{tissue}'},
  {label: 'microgram per hour', value: 'ug/h'},
  {label: 'microgram per kilogram', value: 'ug/kg'},
  {label: 'microgram per kilogram per 8 hour', value: 'ug/kg/(8.h)'},
  {label: 'microgram per kilogram per day', value: 'ug/kg/d'},
  {label: 'microgram per kilogram per hour', value: 'ug/kg/h'},
  {label: 'microgram per kilogram per minute', value: 'ug/kg/min'},
  {label: 'microgram per liter', value: 'ug/L'},
  {label: 'microgram per liter of red blood cells', value: 'ug/L{RBCs}'},
  {label: 'microgram per liter per 24 hour', value: 'ug/L/(24.h)'},
  {label: 'microgram per milligram', value: 'ug/mg'},
  {label: 'microgram per milligram of creatinine', value: 'ug/mg{creat}'},
  {label: 'microgram per milliliter', value: 'ug/mL'},
  {label: 'microgram per milliliter class', value: 'ug/mL{class}'},
  {label: 'microgram per milliliter equivalent', value: 'ug/mL{eqv}'},
  {label: 'microgram per millimole', value: 'ug/mmol'},
  {label: 'microgram per millimole of creatinine', value: 'ug/mmol{creat}'},
  {label: 'microgram per minute', value: 'ug/min'},
  {label: 'microgram per nanogram', value: 'ug/ng'},
  {label: 'microgram per specimen', value: 'ug/{specimen}'},
  {label: 'microgram per square foot (international)', value: 'ug/[sft_i]'},
  {label: 'microgram per square meter', value: 'ug/m2'},
  {label: 'microinternational unit per liter', value: 'u[IU]/L'},
  {label: 'microkatal', value: 'ukat'},
  {label: 'microliter', value: 'uL'},
  {label: 'microliter per 2 hour', value: 'uL/(2.h)'},
  {label: 'microliter per hour', value: 'uL/h'},
  {label: 'micrometer', value: 'um'},
  {label: 'micromole', value: 'umol'},
  {label: 'micromole bone collagen equivalent per mole', value: 'umol{BCE}/mol'},
  {label: 'micromole per 2 hour', value: 'umol/(2.h)'},
  {label: 'micromole per 24 hour', value: 'umol/(24.h)'},
  {label: 'micromole per 8 hour', value: 'umol/(8.h)'},
  {label: 'micromole per day', value: 'umol/d'},
  {label: 'micromole per deciliter', value: 'umol/dL'},
  {label: 'micromole per deciliter of glomerular filtrate', value: 'umol/dL{GF}'},
  {label: 'micromole per gram', value: 'umol/g'},
  {label: 'micromole per gram of creatinine', value: 'umol/g{creat}'},
  {label: 'micromole per gram of hemoglobin', value: 'umol/g{Hb}'},
  {label: 'micromole per hour', value: 'umol/h'},
  {label: 'micromole per kilogram', value: 'umol/kg'},
  {label: 'micromole per kilogram of feces', value: 'umol/kg{feces}'},
  {label: 'micromole per liter', value: 'umol/L'},
  {label: 'micromole per liter of red blood cells', value: 'umol/L{RBCs}'},
  {label: 'micromole per liter per hour', value: 'umol/L/h'},
  {label: 'micromole per micromole', value: 'umol/umol'},
  {label: 'micromole per micromole of creatinine', value: 'umol/umol{creat}'},
  {label: 'micromole per milligram', value: 'umol/mg'},
  {label: 'micromole per milligram of creatinine', value: 'umol/mg{creat}'},
  {label: 'micromole per milliliter', value: 'umol/mL'},
  {label: 'micromole per milliliter per minute', value: 'umol/mL/min'},
  {label: 'micromole per millimole', value: 'umol/mmol'},
  {label: 'micromole per millimole of creatinine', value: 'umol/mmol{creat}'},
  {label: 'micromole per million red blood cell', value: 'umol/10*6{RBC}'},
  {label: 'micromole per minute', value: 'umol/min'},
  {label: 'micromole per minute per gram', value: 'umol/min/g'},
  {label: 'micromole per minute per gram of mucosa', value: 'umol/min/g{mucosa}'},
  {label: 'micromole per minute per gram of protein', value: 'umol/min/g{prot}'},
  {label: 'micromole per minute per liter', value: 'umol/min/L'},
  {label: 'micromole per mole', value: 'umol/mol'},
  {label: 'micromole per mole of creatinine', value: 'umol/mol{creat}'},
  {label: 'micromole per mole of hemoglobin', value: 'umol/mol{Hb}'},
  {label: 'microns per second', value: 'um/s'},
  {label: 'microOhm', value: 'uOhm'},
  {label: 'microsecond', value: 'us'},
  {label: 'microvolt', value: 'uV'},
  {label: 'mile (international)', value: '[mi_i]'},
  {label: 'milli enzyme unit per gram', value: 'mU/g'},
  {label: 'milli enzyme unit per milliliter', value: 'mU/mL'},
  {label: 'milli enzyme unit per milliliter per minute', value: 'mU/mL/min'},
  {label: 'milli enzyme unit per millimole of creatinine', value: 'mU/mmol{creat}'},
  {label: 'milli enzyme unit per millimole of red blood cells', value: 'mU/mmol{RBCs}'},
  {label: 'milli international unit per milliliter', value: 'm[IU]/mL'},
  {label: 'milli enzyme unit per gram of hemoglobin', value: 'mU/g{Hb}'},
  {label: 'milli enzyme unit per gram of protein', value: 'mU/g{prot}'},
  {label: 'milli enzyme unit per liter', value: 'mU/L'},
  {label: 'milli enzyme unit per milligram', value: 'mU/mg'},
  {label: 'milli enzyme unit per milligram of creatinine', value: 'mU/mg{creat}'},
  {label: 'milli international unit per liter', value: 'm[IU]/L'},
  {label: 'milliampere', value: 'mA'},
  {label: 'millibar', value: 'mbar'},
  {label: 'millibar per liter per second', value: 'mbar/L/s'},
  {label: 'millibar second per liter', value: 'mbar.s/L'},
  {label: 'milliequivalent', value: 'meq'},
  {label: 'milliequivalent per 2 hour', value: 'meq/(2.h)'},
  {label: 'milliequivalent per 24 hour', value: 'meq/(24.h)'},
  {label: 'milliequivalent per 8 hour', value: 'meq/(8.h)'},
  {label: 'milliequivalent per day', value: 'meq/d'},
  {label: 'milliequivalent per deciliter', value: 'meq/dL'},
  {label: 'milliequivalent per gram', value: 'meq/g'},
  {label: 'milliequivalent per gram of creatinine', value: 'meq/g{creat}'},
  {label: 'milliequivalent per hour', value: 'meq/h'},
  {label: 'milliequivalent per kilogram', value: 'meq/kg'},
  {label: 'milliequivalent per kilogram per hour', value: 'meq/kg/h'},
  {label: 'milliequivalent per liter', value: 'meq/L'},
  {label: 'milliequivalent per milliliter', value: 'meq/mL'},
  {label: 'milliequivalent per minute', value: 'meq/min'},
  {label: 'milliequivalent per specimen', value: 'meq/{specimen}'},
  {label: 'milliequivalent per square meter', value: 'meq/m2'},
  {label: 'milliequivalent per total volume', value: 'meq/{total_volume}'},
  {label: 'milligram', value: 'mg'},
  {label: 'milligram fibrinogen equivalent unit per liter', value: 'mg{FEU}/L'},
  {label: 'milligram per 10 hour', value: 'mg/(10.h)'},
  {label: 'milligram per 12 hour', value: 'mg/(12.h)'},
  {label: 'milligram per 2 hour', value: 'mg/(2.h)'},
  {label: 'milligram per 24 hour', value: 'mg/(24.h)'},
  {label: 'milligram per 6 hour', value: 'mg/(6.h)'},
  {label: 'milligram per 72 hour', value: 'mg/(72.h)'},
  {label: 'milligram per 8 hour', value: 'mg/(8.h)'},
  {label: 'milligram per collection', value: 'mg/{collection}'},
  {label: 'milligram per cubic meter', value: 'mg/m3'},
  {label: 'milligram per day', value: 'mg/d'},
  {label: 'milligram per day per 1.73 square meter', value: 'mg/d/{1.73_m2}'},
  {label: 'milligram per deciliter', value: 'mg/dL'},
  {label: 'milligram per deciliter of red blood cells', value: 'mg/dL{RBCs}'},
  {label: 'milligram per gram', value: 'mg/g'},
  {label: 'milligram per gram of creatinine', value: 'mg/g{creat}'},
  {label: 'milligram per gram of dry tissue', value: 'mg/g{dry_tissue}'},
  {label: 'milligram per gram of feces', value: 'mg/g{feces}'},
  {label: 'milligram per gram of tissue', value: 'mg/g{tissue}'},
  {label: 'milligram per gram of wet tissue', value: 'mg/g{wet_tissue}'},
  {label: 'milligram per hour', value: 'mg/h'},
  {label: 'milligram per kilogram', value: 'mg/kg'},
  {label: 'milligram per kilogram per 8 hour', value: 'mg/kg/(8.h)'},
  {label: 'milligram per kilogram per day', value: 'mg/kg/d'},
  {label: 'milligram per kilogram per hour', value: 'mg/kg/h'},
  {label: 'milligram per kilogram per minute', value: 'mg/kg/min'},
  {label: 'milligram per liter', value: 'mg/L'},
  {label: 'milligram per liter of red blood cells', value: 'mg/L{RBCs}'},
  {label: 'milligram per milligram', value: 'mg/mg'},
  {label: 'milligram per milligram of creatinine', value: 'mg/mg{creat}'},
  {label: 'milligram per milliliter', value: 'mg/mL'},
  {label: 'milligram per millimole', value: 'mg/mmol'},
  {label: 'milligram per millimole of creatinine', value: 'mg/mmol{creat}'},
  {label: 'milligram per minute', value: 'mg/min'},
  {label: 'milligram per specimen', value: 'mg/{specimen}'},
  {label: 'milligram per square meter', value: 'mg/m2'},
  {label: 'milligram per total output', value: 'mg/{total_output}'},
  {label: 'milligram per total volume', value: 'mg/{total_volume}'},
  {label: 'milligram per week', value: 'mg/wk'},
  {label: 'milliliter', value: 'mL'},
  {label: 'milliliter of fetal red blood cells', value: 'mL{fetal_RBCs}'},
  {label: 'milliliter per 10 hour', value: 'mL/(10.h)'},
  {label: 'milliliter per 12 hour', value: 'mL/(12.h)'},
  {label: 'milliliter per 2 hour', value: 'mL/(2.h)'},
  {label: 'milliliter per 24 hour', value: 'mL/(24.h)'},
  {label: 'milliliter per 4 hour', value: 'mL/(4.h)'},
  {label: 'milliliter per 5 hour', value: 'mL/(5.h)'},
  {label: 'milliliter per 6 hour', value: 'mL/(6.h)'},
  {label: 'milliliter per 72 hour', value: 'mL/(72.h)'},
  {label: 'milliliter per 8 hour', value: 'mL/(8.h)'},
  {label: 'milliliter per 8 hour per kilogram', value: 'mL/(8.h)/kg'},
  {label: 'milliliter per centimeter of water', value: 'mL/cm[H2O]'},
  {label: 'milliliter per day', value: 'mL/d'},
  {label: 'milliliter per deciliter', value: 'mL/dL'},
  {label: 'milliliter per heart beat', value: 'mL/{beat}'},
  {label: 'milliliter per heart beat per square meter', value: 'mL/{beat}/m2'},
  {label: 'milliliter per hour', value: 'mL/h'},
  {label: 'milliliter per kilogram', value: 'mL/kg'},
  {label: 'milliliter per kilogram per 8 hour', value: 'mL/kg/(8.h)'},
  {label: 'milliliter per kilogram per day', value: 'mL/kg/d'},
  {label: 'milliliter per kilogram per hour', value: 'mL/kg/h'},
  {label: 'milliliter per kilogram per minute', value: 'mL/kg/min'},
  {label: 'milliliter per millibar', value: 'mL/mbar'},
  {label: 'milliliter per millimeter', value: 'mL/mm'},
  {label: 'milliliter per minute', value: 'mL/min'},
  {label: 'milliliter per minute per 1.73 square meter', value: 'mL/min/{1.73_m2}'},
  {label: 'milliliter per minute per square meter', value: 'mL/min/m2'},
  {label: 'milliliter per second', value: 'mL/s'},
  {label: 'milliliter per square inch (international)', value: 'mL/[sin_i]'},
  {label: 'milliliter per square meter', value: 'mL/m2'},
  {label: 'millimeter', value: 'mm'},
  {label: 'millimeter of mercury', value: 'mm[Hg]'},
  {label: 'millimeter of water', value: 'mm[H2O]'},
  {label: 'millimeter per hour', value: 'mm/h'},
  {label: 'millimeter per minute', value: 'mm/min'},
  {label: 'millimole', value: 'mmol'},
  {label: 'millimole per 12 hour', value: 'mmol/(12.h)'},
  {label: 'millimole per 2 hour', value: 'mmol/(2.h)'},
  {label: 'millimole per 24 hour', value: 'mmol/(24.h)'},
  {label: 'millimole per 5 hour', value: 'mmol/(5.h)'},
  {label: 'millimole per 6 hour', value: 'mmol/(6.h)'},
  {label: 'millimole per 8 hour', value: 'mmol/(8.h)'},
  {label: 'millimole per day', value: 'mmol/d'},
  {label: 'millimole per deciliter', value: 'mmol/dL'},
  {label: 'millimole per ejaculate', value: 'mmol/{ejaculate}'},
  {label: 'millimole per gram', value: 'mmol/g'},
  {label: 'millimole per gram of creatinine', value: 'mmol/g{creat}'},
  {label: 'millimole per hour', value: 'mmol/h'},
  {label: 'millimole per hour per milligram of hemoglobin', value: 'mmol/h/mg{Hb}'},
  {label: 'millimole per hour per milligram of protein', value: 'mmol/h/mg{prot}'},
  {label: 'millimole per kilogram', value: 'mmol/kg'},
  {label: 'millimole per kilogram per 8 hour', value: 'mmol/kg/(8.h)'},
  {label: 'millimole per kilogram per day', value: 'mmol/kg/d'},
  {label: 'millimole per kilogram per hour', value: 'mmol/kg/h'},
  {label: 'millimole per kilogram per minute', value: 'mmol/kg/min'},
  {label: 'millimole per liter', value: 'mmol/L'},
  {label: 'millimole per liter of red blood cells', value: 'mmol/L{RBCs}'},
  {label: 'millimole per millimole', value: 'mmol/mmol'},
  {label: 'millimole per millimole of urea', value: 'mmol/mmol{urea}'},
  {label: 'millimole per millmole of creatinine', value: 'mmol/mmol{creat}'},
  {label: 'millimole per minute', value: 'mmol/min'},
  {label: 'millimole per mole', value: 'mmol/mol'},
  {label: 'millimole per mole of creatinine', value: 'mmol/mol{creat}'},
  {label: 'millimole per second per liter', value: 'mmol/s/L'},
  {label: 'millimole per specimen', value: 'mmol/{specimen}'},
  {label: 'millimole per square meter', value: 'mmol/m2'},
  {label: 'millimole per total volume', value: 'mmol/{total_vol}'},
  {label: 'million', value: '10*6'},
  {label: 'million colony forming unit per liter', value: '10*6.[CFU]/L'},
  {label: 'million international unit', value: '10*6.[IU]'},
  {label: 'million per 24 hour', value: '10*6/(24.h)'},
  {label: 'million per kilogram', value: '10*6/kg'},
  {label: 'million per liter', value: '10*6/L'},
  {label: 'million per microliter', value: '10*6/uL'},
  {label: 'million per milliliter', value: '10*6/mL'},
  {label: 'milliosmole', value: 'mosm'},
  {label: 'milliosmole per kilogram', value: 'mosm/kg'},
  {label: 'milliosmole per liter', value: 'mosm/L'},
  {label: 'millipascal', value: 'mPa'},
  {label: 'millipascal second', value: 'mPa.s'},
  {label: 'millisecond', value: 'ms'},
  {label: 'millivolt', value: 'mV'},
  {label: 'millivolt per second', value: 'mV/s'},
  {label: 'minidrop per minute', value: '{minidrop}/min'},
  {label: 'minidrop per second', value: '{minidrop}/s'},
  {label: 'minute', value: 'min'},
  {label: 'minute per day', value: 'min/d'},
  {label: 'minute per week', value: 'min/wk'},
  {label: 'mole', value: 'mol'},
  {label: 'mole per cubic meter', value: 'mol/m3'},
  {label: 'mole per kilogram', value: 'mol/kg'},
  {label: 'mole per kilogram per second', value: 'mol/kg/s'},
  {label: 'mole per liter', value: 'mol/L'},
  {label: 'mole per milliliter', value: 'mol/mL'},
  {label: 'mole per mole', value: 'mol/mol'},
  {label: 'mole per second', value: 'mol/s'},
  {label: 'molecule per platelet', value: '{#}/{platelet}'},
  {label: 'month', value: 'mo'},
  {label: 'month-day-year', value: '{mm/dd/yyyy}'},
  {label: 'multiple of the median', value: '{M.o.M}'},
  {label: 'mutation', value: '{mutation}'},
  {label: 'nanoenzyme unit per milliliter', value: 'nU/mL'},
  {label: 'nanoenzyme unit per red blood cell', value: 'nU/{RBC}'},
  {label: 'nanogram', value: 'ng'},
  {label: 'nanogram fibrinogen equivalent unit per milliliter', value: 'ng{FEU}/mL'},
  {label: 'nanogram per 24 hour', value: 'ng/(24.h)'},
  {label: 'nanogram per 8 hour', value: 'ng/(8.h)'},
  {label: 'nanogram per day', value: 'ng/d'},
  {label: 'nanogram per deciliter', value: 'ng/dL'},
  {label: 'nanogram per enzyme unit', value: 'ng/U'},
  {label: 'nanogram per gram', value: 'ng/g'},
  {label: 'nanogram per gram of creatinine', value: 'ng/g{creat}'},
  {label: 'nanogram per hour', value: 'ng/h'},
  {label: 'nanogram per kilogram', value: 'ng/kg'},
  {label: 'nanogram per kilogram per 8 hour', value: 'ng/kg/(8.h)'},
  {label: 'nanogram per kilogram per hour', value: 'ng/kg/h'},
  {label: 'nanogram per kilogram per minute', value: 'ng/kg/min'},
  {label: 'nanogram per liter', value: 'ng/L'},
  {label: 'nanogram per milligram', value: 'ng/mg'},
  {label: 'nanogram per milligram of creatinine', value: 'ng/mg{creat}'},
  {label: 'nanogram per milligram of protein', value: 'ng/mg{prot}'},
  {label: 'nanogram per milligram per hour', value: 'ng/mg/h'},
  {label: 'nanogram per milliliter of red blood cells', value: 'ng/mL{RBCs}'},
  {label: 'nanogram per milliliter per hour', value: 'ng/mL/h'},
  {label: 'nanogram per million', value: 'ng/10*6'},
  {label: 'nanogram per million red blood cells', value: 'ng/10*6{RBCs}'},
  {label: 'nanogram per millliiter', value: 'ng/mL'},
  {label: 'nanogram per minute', value: 'ng/min'},
  {label: 'nanogram per second', value: 'ng/s'},
  {label: 'nanogram per square meter', value: 'ng/m2'},
  {label: 'nanokatal', value: 'nkat'},
  {label: 'nanoliter', value: 'nL'},
  {label: 'nanometer', value: 'nm'},
  {label: 'nanometer per second per liter', value: 'nm/s/L'},
  {label: 'nanomole', value: 'nmol'},
  {label: 'nanomole bone collagen equivalent', value: 'nmol{BCE}'},
  {label: 'nanomole bone collagen equivalent per liter', value: 'nmol{BCE}/L'},
  {label: 'nanomole bone collagen equivalent per millimole of creatinine', value: 'nmol/mmol{creat}'},
  {label: 'nanomole of 1/2 cystine per milligram of protein', value: 'nmol/mg{prot}'},
  {label: 'nanomole of ATP', value: 'nmol{ATP}'},
  {label: 'nanomole per 24 hour', value: 'nmol/(24.h)'},
  {label: 'nanomole per day', value: 'nmol/d'},
  {label: 'nanomole per deciliter', value: 'nmol/dL'},
  {label: 'nanomole per deciliter of glomerular filtrate', value: 'nmol/dL{GF}'},
  {label: 'nanomole per gram', value: 'nmol/g'},
  {label: 'nanomole per gram of creatinine', value: 'nmol/g{creat}'},
  {label: 'nanomole per gram of dry weight', value: 'nmol/g{dry_wt}'},
  {label: 'nanomole per hour per liter', value: 'nmol/h/L'},
  {label: 'nanomole per hour per milligram of protein', value: 'nmol/h/mg{prot}'},
  {label: 'nanomole per hour per milliliter', value: 'nmol/h/mL'},
  {label: 'nanomole per liter', value: 'nmol/L'},
  {label: 'nanomole per liter of red blood cells', value: 'nmol/L{RBCs}'},
  {label: 'nanomole per liter per millimole of creatinine', value: 'nmol/L/mmol{creat}'},
  {label: 'nanomole per meter per milligram of protein', value: 'nmol/m/mg{prot}'},
  {label: 'nanomole per micromole of creatinine', value: 'nmol/umol{creat}'},
  {label: 'nanomole per milligram', value: 'nmol/mg'},
  {label: 'nanomole per milligram of creatinine', value: 'nmol/mg{creat}'},
  {label: 'nanomole per milligram of protein', value: 'nmol/mg{prot}'},
  {label: 'nanomole per milligram of protein per hour', value: 'nmol/mg{prot}/h'},
  {label: 'nanomole per milligram per hour', value: 'nmol/mg/h'},
  {label: 'nanomole per milliliter', value: 'nmol/mL'},
  {label: 'nanomole per milliliter per hour', value: 'nmol/mL/h'},
  {label: 'nanomole per milliliter per minute', value: 'nmol/mL/min'},
  {label: 'nanomole per millimole', value: 'nmol/mmol'},
  {label: 'nanomole per millimole of creatinine', value: 'nmol/mmol{creat}'},
  {label: 'nanomole per minute', value: 'nmol/min'},
  {label: 'nanomole per minute per milligram of hemoglobin', value: 'nmol/min/mg{Hb}'},
  {label: 'nanomole per minute per milligram of protein', value: 'nmol/min/mg{prot}'},
  {label: 'nanomole per minute per milligram protein', value: 'nmol/min/mg{protein}'},
  {label: 'nanomole per minute per milliliter', value: 'nmol/min/mL'},
  {label: 'nanomole per minute per million cells', value: 'nmol/min/10*6{cells}'},
  {label: 'nanomole per mole', value: 'nmol/mol'},
  {label: 'nanomole per mole creatinine', value: 'nmol/mol{creat}'},
  {label: 'nanomole per nanomole', value: 'nmol/nmol'},
  {label: 'nanomole per second', value: 'nmol/s'},
  {label: 'nanomole per second per liter', value: 'nmol/s/L'},
  {label: 'nanosecond', value: 'ns'},
  {label: 'Newton', value: 'N'},
  {label: 'Newton centimeter', value: 'N.cm'},
  {label: 'Newton second', value: 'N.s'},
  {label: 'number', value: '{#}'},
  {label: 'number per annum (year)', value: '{#}/a'},
  {label: 'number per day', value: '{#}/d'},
  {label: 'number per gram', value: '{#}/g'},
  {label: 'number per high power field', value: '{#}/[HPF]'},
  {label: 'number per liter', value: '{#}/L'},
  {label: 'number per low power field', value: '{#}/[LPF]'},
  {label: 'number per microliter', value: '{#}/uL'},
  {label: 'number per milliliter', value: '{#}/mL'},
  {label: 'number per minute', value: '{#}/min'},
  {label: 'number per week', value: '{#}/wk'},
  {label: 'Ohm', value: 'Ohm'},
  {label: 'Ohm meter', value: 'Ohm.m'},
  {label: 'one hundred thousand', value: '10*5'},
  {label: 'optical density unit', value: '{OD_unit}'},
  {label: 'osmole', value: 'osm'},
  {label: 'osmole per kilogram', value: 'osm/kg'},
  {label: 'osmole per liter', value: 'osm/L'},
  {label: 'ounce (US and British)', value: '[oz_av]'},
  {label: 'panbio unit', value: "{Pan_Bio'U}"},
  {label: 'part per billion', value: '[ppb]'},
  {label: 'part per million', value: '[ppm]'},
  {label: 'part per million in volume per volume', value: '[ppm]{v/v}'},
  {label: 'part per thousand', value: '[ppth]'},
  {label: 'part per trillion', value: '[pptr]'},
  {label: 'Pascal', value: 'Pa'},
  {label: 'per 10 billion', value: '/10*10'},
  {label: 'per 10 thousand red blood cells', value: '/10*4{RBCs}'},
  {label: 'per 100', value: '/100'},
  {label: 'per 100 cells', value: '/100{cells}'},
  {label: 'per 100 neutrophils', value: '/100{neutrophils}'},
  {label: 'per 100 spermatozoa', value: '/100{spermatozoa}'},
  {label: 'per 100 white blood cells', value: '/100{WBCs}'},
  {label: 'per arbitrary unit', value: "/[arb'U]"},
  {label: 'per billion', value: '/10*9'},
  {label: 'per centimeter of water', value: '/cm[H2O]'},
  {label: 'per cubic meter', value: '/m3'},
  {label: 'per day', value: '/d'},
  {label: 'per deciliter', value: '/dL'},
  {label: 'per entity', value: '/{entity}'},
  {label: 'per enzyme unit', value: '/U'},
  {label: 'per gram', value: '/g'},
  {label: 'per gram of creatinine', value: '/g{creat}'},
  {label: 'per gram of hemoglobin', value: '/g{Hb}'},
  {label: 'per gram of total nitrogen', value: '/g{tot_nit}'},
  {label: 'per gram of total protein', value: '/g{tot_prot}'},
  {label: 'per gram of wet tissue', value: '/g{wet_tis}'},
  {label: 'per high power field', value: '/[HPF]'},
  {label: 'per hour', value: '/h'},
  {label: 'per international unit', value: '/[IU]'},
  {label: 'per kilogram', value: '/kg'},
  {label: 'per kilogram of body weight', value: '/kg{body_wt}'},
  {label: 'per liter', value: '/L'},
  {label: 'per low power field', value: '/[LPF]'},
  {label: 'per microliter', value: '/uL'},
  {label: 'per milligram', value: '/mg'},
  {label: 'per milliliter', value: '/mL'},
  {label: 'per millimeter', value: '/mm'},
  {label: 'per millimole of creatinine', value: '/mmol{creat}'},
  {label: 'per million', value: '/10*6'},
  {label: 'per minute', value: '/min'},
  {label: 'per month', value: '/mo'},
  {label: 'per oil immersion field', value: '/{OIF}'},
  {label: 'per second', value: '/s'},
  {label: 'per square meter', value: '/m2'},
  {label: 'per thousand', value: '/10*3'},
  {label: 'per thousand red blood cells', value: '/10*3{RBCs}'},
  {label: 'per trillion', value: '/10*12'},
  {label: 'per trillion red blood cells', value: '/10*12{RBCs}'},
  {label: 'per twelve hour', value: '/(12.h)'},
  {label: 'per week', value: '/wk'},
  {label: 'per year', value: '/a'},
  {label: 'percent', value: '%'},
  {label: 'percent loss of acetylcholine receptor', value: '%{loss_AChR}'},
  {label: 'percent penetration', value: '%{penetration}'},
  {label: 'percent abnormal', value: '%{abnormal}'},
  {label: 'percent activity', value: '%{activity}'},
  {label: 'percent aggregation', value: '%{aggregation}'},
  {label: 'percent at 60 minute', value: '%{at_60_min}'},
  {label: 'percent basal activity', value: '%{basal_activity}'},
  {label: 'percent binding', value: '%{binding}'},
  {label: 'percent blockade', value: '%{blockade}'},
  {label: 'percent blocked', value: '%{blocked}'},
  {label: 'percent bound', value: '%{bound}'},
  {label: 'percent breakdown', value: '%{breakdown}'},
  {label: 'percent by volume', value: '%{vol}'},
  {label: 'percent deficient', value: '%{deficient}'},
  {label: 'percent dose', value: '%{dose}'},
  {label: 'percent excretion', value: '%{excretion}'},
  {label: 'percent hemoglobin', value: '%{Hb}'},
  {label: 'percent hemolysis', value: '%{hemolysis}'},
  {label: 'percent index', value: '%{index}'},
  {label: 'percent inhibition', value: '%{inhibition}'},
  {label: 'percent loss', value: '%{loss}'},
  {label: 'percent lysis', value: '%{lysis}'},
  {label: 'percent normal', value: '%{normal}'},
  {label: 'percent normal pooled plasma', value: '%{pooled_plasma}'},
  {label: 'percent of bacteria', value: '%{bacteria}'},
  {label: 'percent of baseline', value: '%{baseline}'},
  {label: 'percent of cells', value: '%{cells}'},
  {label: 'percent of red blood cells', value: '%{RBCs}'},
  {label: 'percent of white blood cells', value: '%{WBCs}'},
  {label: 'percent positive', value: '%{positive}'},
  {label: 'percent reactive', value: '%{reactive}'},
  {label: 'percent recovery', value: '%{recovery}'},
  {label: 'percent reference', value: '%{reference}'},
  {label: 'percent residual', value: '%{residual}'},
  {label: 'percent response', value: '%{response}'},
  {label: 'percent saturation', value: '%{saturation}'},
  {label: 'percent total', value: '%{total}'},
  {label: 'percent uptake', value: '%{uptake}'},
  {label: 'percent viable', value: '%{viable}'},
  {label: 'percentile', value: '{percentile}'},
  {label: 'pH', value: '[pH]'},
  {label: 'phenotype', value: '{phenotype}'},
  {label: 'picoampere', value: 'pA'},
  {label: 'picogram', value: 'pg'},
  {label: 'picogram per cell', value: 'pg/{cell}'},
  {label: 'picogram per deciliter', value: 'pg/dL'},
  {label: 'picogram per liter', value: 'pg/L'},
  {label: 'picogram per milligram', value: 'pg/mg'},
  {label: 'picogram per milligram of creatinine', value: 'pg/mg{creat}'},
  {label: 'picogram per milliliter', value: 'pg/mL'},
  {label: 'picogram per milliliter sulfidoleukotrienes', value: 'pg/mL{sLT}'},
  {label: 'picogram per millimeter', value: 'pg/mm'},
  {label: 'picogram per red blood cell', value: 'pg/{RBC}'},
  {label: 'picokatal', value: 'pkat'},
  {label: 'picoliter', value: 'pL'},
  {label: 'picometer', value: 'pm'},
  {label: 'picomole', value: 'pmol'},
  {label: 'picomole per 24 hour', value: 'pmol/(24.h)'},
  {label: 'picomole per day', value: 'pmol/d'},
  {label: 'picomole per deciliter', value: 'pmol/dL'},
  {label: 'picomole per gram', value: 'pmol/g'},
  {label: 'picomole per hour per milligram of protein', value: 'pmol/h/mg{prot}'},
  {label: 'picomole per hour per milligram protein', value: 'pmol/H/mg{protein}'},
  {label: 'picomole per hour per milliliter', value: 'pmol/h/mL'},
  {label: 'picomole per liter', value: 'pmol/L'},
  {label: 'picomole per micromole', value: 'pmol/umol'},
  {label: 'picomole per micromole of creatinine', value: 'pmol/umol{creat}'},
  {label: 'picomole per milligram of protein', value: 'pmol/mg{prot}'},
  {label: 'picomole per milliliter', value: 'pmol/mL'},
  {label: 'picomole per millimole of creatinine', value: 'pmol/mmol{creat}'},
  {label: 'picomole per minute', value: 'pmol/min'},
  {label: 'picomole per minute per milligram of protein', value: 'pmol/min/mg{prot}'},
  {label: 'picomole per red blood cell', value: 'pmol/{RBC}'},
  {label: 'picosecond', value: 'ps'},
  {label: 'picotesla', value: 'pT'},
  {label: 'pint (US)', value: '[pt_us]'},
  {label: 'pound (US and British)', value: '[lb_av]'},
  {label: 'pound per square inch', value: '[psi]'},
  {label: 'quart (US)', value: '[qt_us]'},
  {label: 'ratio', value: '{ratio}'},
  {label: 'red blood cell per microliter', value: '{RBC}/uL'},
  {label: 'relative percent', value: '%{relative}'},
  {label: 'relative saturation', value: '{rel_saturation}'},
  {label: 'risk', value: '{risk}'},
  {label: 'rubella virus', value: '{Rubella_virus}'},
  {label: 'saturation', value: '{saturation}'},
  {label: 'score', value: '{score}'},
  {label: 'second', value: 's'},
  {label: 'second per control', value: 's/{control}'},
  {label: 'shift', value: '{shift}'},
  {label: 'Siemens', value: 'S'},
  {label: 'Sievert', value: 'Sv'},
  {label: 'signal to cutoff ratio', value: '{s_co_ratio}'},
  {label: 'spermatozoa per milliliter', value: '{spermatozoa}/mL'},
  {label: 'square centimeter', value: 'cm2'},
  {label: 'square centimeter per second', value: 'cm2/s'},
  {label: 'square decimeter per square second', value: 'dm2/s2'},
  {label: 'square foot (international)', value: '[sft_i]'},
  {label: 'square inch (international)', value: '[sin_i]'},
  {label: 'square meter', value: 'm2'},
  {label: 'square meter per second', value: 'm2/s'},
  {label: 'square millimeter', value: 'mm2'},
  {label: 'square yard (international)', value: '[syd_i]'},
  {label: 'standard deviation', value: '{STDV}'},
  {label: 't score', value: '{Tscore}'},
  {label: 'tablespoon (US)', value: '[tbs_us]'},
  {label: 'teaspoon (US)', value: '[tsp_us]'},
  {label: 'Tesla', value: 'T'},
  {label: 'thousand', value: '10*3'},
  {label: 'thousand copies per milliliter', value: '10*3{copies}/mL'},
  {label: 'thousand per liter', value: '10*3/L'},
  {label: 'thousand per microliter', value: '10*3/uL'},
  {label: 'thousand per milliliter', value: '10*3/mL'},
  {label: 'thousand red blood cells', value: '10*3{RBCs}'},
  {label: 'thyroid-stimulating immunoglobulin index', value: '{TSI_index}'},
  {label: 'time stamp', value: '{TmStp}'},
  {label: 'titer', value: '{titer}'},
  {label: 'Todd unit', value: "[todd'U]"},
  {label: 'Torr', value: 'Torr'},
  {label: 'trillion per liter', value: '10*12/L'},
  {label: 'Troy ounce', value: '[oz_tr]'},
  {label: 'tuberculin unit', value: "[tb'U]"},
  {label: 'volt', value: 'V'},
  {label: 'Weber', value: 'Wb'},
  {label: 'week', value: 'wk'},
  {label: 'white blood cells', value: '{WBCs}'},
  {label: 'yard (international)', value: '[yd_i]'},
  {label: 'year', value: 'a'},
  {label: 'year', value: '{yyyy}'},
  {label: 'z score', value: '{Zscore}'},
];
