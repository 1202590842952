import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  sidebar?: React.ReactNode;
  body?: React.ReactNode;
}

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
  },
  sidebarContainer: {
    maxHeight: '100vh',
    height: '100%',
    position: 'fixed',
    zIndex: 30,
  },
  bodyContainer: {
    width: '100%',
    padding: 0,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  toastMsg: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 12,
  },
  innerToastMsg: {
    width: '400px',
    background: Colors.white,
    boxShadow: `${Colors.RMSC.toastShadow} 1px 1px 3px`,
    padding: '20px 30px',
    borderRadius: '10px',
    '& h2': {
      fontSize: '16px',
      margin: '0',
      color: Colors.blackLight,
    },
    '& p': {
      fontSize: '13px',
      margin: '0',
      color: Colors.purpleDark,
      marginTop: '5px',
    },
  },
  line: {
    height: '30px',
    width: '100%',
    background: Colors.timberwolf,
    borderRadius: '8px',
    position: 'relative',
  },
  progressBar: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      position: 'absolute',
      left: '0',
      top: '0',
      height: '100%',
      background: Colors.turquoiseBlue,
      borderRadius: '8px',
    },
  },

  '@keyframes animationLoaded': {
    '0%': {
      width: '0%',
    },
    '100%': {
      width: '100%',
    },
  },

  label: {
    fontSize: '18px',
    position: 'relative',
    top: '29px',
    zIndex: '1',
    color: Colors.white,
    margin: 'auto',
    fontWeight: 600,
  },
  innerToastMsgError: {
    width: '300px',
    background: Colors.white,
    boxShadow: `${Colors.RMSC.toastShadow} 1px 1px 3px`,
    padding: '20px 30px',
    borderRadius: '10px',
    '& span': {
      position: 'absolute',
      background: 'red',
      height: '44px',
      left: '14px',
      borderRadius: '20px',
      width: '6px',
    },
    '& h2': {
      fontSize: '16px',
      margin: '0',
      color: Colors.blackLight,
    },
    '& p': {
      fontSize: '13px',
      margin: '0',
      color: Colors.grayLighter,
    },
  },
  xBox: {
    width: '20px',
    cursor: 'pointer',
    right: '25px',
    position: 'fixed',
    marginTop: '5px',
  },
});
