import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  signin: {
    background: Colors.white,
    width: 471,
    height: 547,
    borderRadius: 10,
    padding: 20,
  },
  formContainer: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    display: 'flex',
    '& h1': {
      fontsize: '24px',
    },
    '& p': {
      fontsize: '12px',
    },
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  questionContainer: {
    '& p': {
      fontSize: 14,
    },
    '& span': {
      fontSize: 14,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  headerContainer: {
    '& h1': {
      fontSize: 24,
    },
    '& p': {
      fontSize: 12,
    },
  },
  error: {
    color: Colors.red,
  },
});
