import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import _, {get} from 'lodash';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import {
  IActivityForm,
  IBodySite,
  ICareTeam,
  IDiagnosis,
  IItem,
  IItemSystem,
  ISupportingInfo,
} from 'interfaces/pre-auth.interface';
import {KlaimFieldWrapper, KlaimDropdownSearch, KlaimForm} from 'modules/Common/components-v2/KlaimForm';
import dayjs from 'dayjs';
import ItemForm from './components/ItemForm';
import {cleanUpObject} from '../../../../../helpers/general-helper';
import KlaimDatePicker from '../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {useAppSelector} from '../../../../App/store';
import {activitySelect} from '../../../activitySlice';
import {usePreAuthFormContext} from '../../../../PreAuthV2/features/PreAuthFormV2/PreAuthFormContext';

interface ComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (value: any) => void;
  itemId: string;
  diagnosis?: IDiagnosis[];
  careTeam?: ICareTeam[];
  supportingInfo?: ISupportingInfo[];
  isClaim?: boolean;
  selectedActivityIndex?: number;
}

const ActivityModal: React.FC<ComponentProps> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  diagnosis = [],
  careTeam = [],
  supportingInfo = [],
  isClaim,
  selectedActivityIndex,
}) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [price, setPrice] = React.useState();
  const [selectedContract, setSelectedContract] = React.useState<any>({});
  const [discount, setDiscount] = React.useState();
  const {activities} = useAppSelector(activitySelect);

  React.useEffect(() => {
    return () => {
      setStartDate(new Date());
      setEndDate(new Date());
    };
  }, []);

  const initialDate = new Date();
  const getInitialValue = (path, defaultValue) =>
    get(activities, `[${selectedActivityIndex}].item.${path}`, defaultValue);
  const initialValues = {
    careTeam: [],
    item: {
      itemIsPackage: getInitialValue('itemIsPackage', false),
      itemSystem: getInitialValue('itemSystem', undefined),
      itemTax: getInitialValue('itemTax', 0),
      itemPatientShare: getInitialValue('itemPatientShare', 0),
      itemCode: selectedContract?.nphiesCode,
      itemQty: getInitialValue('itemQty', undefined),
      itemUnitPrice: price,
      itemCodeHcp: getInitialValue('itemCodeHcp', ''),
      itemCodeSfda: getInitialValue('itemCodeSfda', ''),
      factor: discount,
      itemCodeSfdaDisplay: '',
      itemCodeHcpDisplay: '',
      itemCodeDisplay: '',
      invoiceNo: {
        number: undefined,
        system: undefined,
        isClaim,
      },
      subSite: {
        system: undefined,
        code: undefined,
      },
      bodySite: {
        system: undefined,
        code: undefined,
      },
      shadowBilling: 'Unit',
      informationSequence: getInitialValue('informationSequence', []),
      diagnosisSequence: getInitialValue('diagnosisSequence', []),
      // itemCareTeamSeq: getInitialValue('itemCareTeamSeq', []),
      start: getInitialValue('start', new Date()),
      end: getInitialValue('end', new Date()),
    },
  };

  const validationSchema = Yup.object({
    item: Yup.object({
      invoiceNo: Yup.object().shape({
        system: Yup.string().when('isClaim', {
          is: (value) => value,
          then: Yup.string().required('Item Invoice System is required.'),
        }),
        number: Yup.string().when('isClaim', {
          is: (value) => value,
          then: Yup.string().required('Item Invoice Number is required.'),
        }),
      }),
      // itemSystem: Yup.number().required('Activity Type is required.'),
      itemCode: Yup.string(),
      // itemPatientShare: Yup.number().required('Patient share is required.'),
      // factor: Yup.number().required('Factor is required.'),
      itemQty: Yup.number().required('Item Quantity is required.'),
      itemUnitPrice: Yup.number().required('Item unit price is required.'),
      itemCareTeamSeq: Yup.array().of(Yup.number()).required('Care Team is Required'),
      bodySite: Yup.object().shape({
        system: Yup.number(),
        code: Yup.string().when('system', {
          is: (value) => value,
          then: Yup.string().required('Body Site Code is required.'),
        }),
      }),
      subSite: Yup.object().shape({
        system: Yup.number(),
        code: Yup.string().when('system', {
          is: (value) => value,
          then: (code) => code.required('Sub Site Code is required.'),
        }),
      }),
      // start: Yup.date(),
      // end: Yup.date(),
    }),
  });

  const handleSubmit = (value: any) => {
    if (onSubmit) {
      const payload: IActivityForm = {...value};
      const {start, end} = value.item;
      if (start === end) {
        payload.item = {...value.item, servicedDate: dayjs(start).format('YYYY-MM-DD')};
      } else {
        const servicedPeriod = {
          start: dayjs(start).format('YYYY-MM-DD'),
          end: dayjs(end).format('YYYY-MM-DD'),
        };
        payload.item = {...value.item, servicedPeriod};
      }
      // const itemCodeDisplay = activities.filter((el) => el.activityCode === payload.item.itemCode)[0]?.shortDescription;
      // const ctr = selectedContract as any;
      payload.item = {
        ...payload.item,
        itemCode: selectedContract?.nphiesCode,
        itemCodeHcp: selectedContract?.hcpCode,
        itemCodeHcpDisplay: selectedContract?.hcpDescription,
        itemQty: +payload.item.itemQty,
        itemPatientShare: value.item.itemPatientShare.toString(),
        start: startDate,
        end: endDate,
      };
      delete payload.item.start;
      delete payload.item.end;
      const cleanedUp = cleanUpObject(payload);
      onSubmit({...cleanedUp, item: {...cleanedUp.item, itemPatientShare: +value.item.itemPatientShare}});
      setIsOpen(false);
    }
  };

  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen}>
      <KlaimForm
        style={{height: '100%', display: 'flex', flexDirection: 'column'}}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <KlaimModalHeader title="Add Activity" />
        <KlaimModalBody style={{padding: 20, display: 'grid', gap: '1rem', maxHeight: 700, overflowY: 'scroll'}}>
          <div>Please Fill in the fields below to add new activity.</div>
          <ItemForm
            isClaim={isClaim}
            onSelectActivity={(activity) => {
              setSelectedContract(activity);
              setPrice(activity?.unitPrice);
              setDiscount(activity?.discount);
            }}
          />
          <KlaimFieldWrapper name={'item.itemCareTeamSeq'}>
            <KlaimDropdownSearch
              label="Select Care Team"
              isMulti
              options={careTeam.map((team, index) => {
                return {
                  label: `${team.name} - ${team.careTeamRoleCode}`,
                  value: index + 1,
                };
              })}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'item.diagnosisSequence'}>
            <KlaimDropdownSearch
              label="Select Diagnosis"
              isMulti
              isOptional
              options={diagnosis.map((diag, index) => {
                return {
                  label: `${diag.diagCode} - ${diag.diagType}`,
                  value: index + 1,
                };
              })}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name={'item.informationSequence'}>
            <KlaimDropdownSearch
              label="Select Additional Info"
              isMulti
              isOptional
              options={supportingInfo.map((info, index) => {
                return {
                  label: `${info.category}${info.code ? ` - (${info.code})` : ''}${
                    info.timingDate ? ` - ${info.timingDate}` : ''
                  }`,
                  value: index + 1,
                };
              })}
            />
          </KlaimFieldWrapper>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 8}}>
            {/*<KlaimFieldWrapper name={'item.start'} onChange={(date) => setStartDate(date)} style={{flexGrow: 1}}>*/}
            <div style={{flexGrow: 1}}>
              <KlaimDatePicker
                label="Start Date"
                minDate={new Date()}
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            {/*</KlaimFieldWrapper>*/}
            {/*<KlaimFieldWrapper name={'item.end'} onChange={(date) => setEndDate(date)} style={{flexGrow: 1}}>*/}
            <div style={{flexGrow: 1}}>
              <KlaimDatePicker
                label="End Date"
                minDate={new Date()}
                onChange={(date) => setEndDate(date)}
                value={endDate}
              />
            </div>
            {/*</KlaimFieldWrapper>*/}
          </div>
        </KlaimModalBody>
        <KlaimModalFooter buttonType="submit" />
      </KlaimForm>
    </KlaimModal>
  );
};

export default ActivityModal;
