import React from 'react';
import {useAppSelector} from 'modules/App/store';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {ComponentProps} from 'modules/Auth/v3/features/IsAuthorized/IsAuthorized';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';

const IsAuthorized: React.FC<ComponentProps> = ({children, requiredPermission, requiredModule, requiredCountries}) => {
  const {
    user: {klaim_super_admin: isAdmin, permissions, organization, modules},
    isLoading,
  } = useAppSelector(authSelect);
  if (isLoading) <LoadingSpinner />;
  if (isAdmin === false) {
    if (requiredPermission) {
      if (!permissions.includes(requiredPermission)) {
        return null;
      }
    }
    if (requiredModule) {
      if (!modules.includes(requiredModule)) {
        return null;
      }
    }
    if (requiredCountries) {
      if (!requiredCountries.includes(organization.country)) {
        return null;
      }
    }
  }
  return <div>{children}</div>;
};

export default IsAuthorized;
