import {PayerInsurerTypes, PayerReceiver} from './types';
import axios from '../../../services/axios';

export const getClaimById = (claimId: any, providerId: string) => {
  return axios.get(`/claims/${claimId}?providerId=${providerId}`);
};

export const getLatestClaimById = (claimId: any, providerId: string | string[] | null) => {
  return axios.get(`/claims/${claimId}/latest?providerId=${providerId}`);
};

export const getPayerInsurer = (memberId: string, providerId: string) => {
  return axios.get<PayerReceiver>(`/predictions/payer?memberId=${memberId}&providerId=${providerId}`);
};

export const deleteClaimDraft = (claimId: string, providerId: string) => {
  return axios.delete(`/claims/draft/${claimId}?providerId=${providerId}`);
};

export const saveClaimDraft = (claimForm: any) => {
  return axios.post(`/claims/draft`, claimForm);
};

export const updateClaimDraft = (id: any, claimForm: any) => {
  return axios.patch(`/claims/draft/${id}`, claimForm);
};

export const submitClaim = (claimForm: any) => {
  return axios.post(`/claims`, claimForm);
};

export const resendClaim = (claimForm: any, claimId: string[] | string | null) => {
  return axios.post(`/claims/${claimId}/resend`, claimForm);
};

export const resubmitClaim = (claimForm: any, claimId: string[] | string | null) => {
  return axios.post(`/claims/${claimId}/resubmit`, claimForm);
};

export const testClaim = (claimForm: any) => {
  return axios.post(`/claims/test`, claimForm);
};

export const testResubmitClaim = (claimForm: any, id: string[] | string | null) => {
  return axios.post(`/claims/${id}/resubmit/test`, claimForm);
};

export const getAllPayersReceivers = (classification: PayerInsurerTypes, authority: string, limit: number) => {
  return axios.get(`/payers?authorityCode=${authority}&limit=${limit}`);
};

export const getEncounters = (authorityCode: string) => {
  return axios.get(`/encounter?authorityCode=${authorityCode}`);
};

export const getDiagnosesCode = (search: string, authority: string) => {
  return axios.get(`/diagnoses?search=${search}&authorityCode=${authority}`);
};

export const getClinicians = (clinicianId: string, authority: string) => {
  return axios.get(`/clinician?clinician=${clinicianId}&name=${clinicianId}&authorityCode=${authority}`);
};

export const getCliniciansById = (clinicianId: string, authority: string) => {
  return axios.get(`/clinician?clinician=${clinicianId}&clinicianIds=${clinicianId}&authorityCode=${authority}`);
};

export const getActivityCode = (searchString: string, activityType: string, authority: string) => {
  return axios.get(`/activities?search=${searchString}&activityType=${activityType}&authorityCode=${authority}`);
};

export const testResendClaim = (claimForm: any, claimId: string[] | string | null) => {
  return axios.post(`/claims/${claimId}/resend/test`, claimForm);
};

export const resubmissionAttachment = (claimId: string[] | string | null, submissionId: string) => {
  return axios.get(`/claims/${claimId}/submissions/${submissionId}/attachment`, {responseType: 'blob'});
};

export const getClaimDraftById = (claimId: string[] | string, providerId: string[] | string | null) => {
  return axios.get(`/claims/${claimId}/draft?providerId=${providerId}`, {
    headers: {'Klaim-Middleware-Provider-Id': providerId, 'Content-Type': 'multipart/form-data'},
  });
};

export const uploadBulkClaims = (file: any, providerId: string[] | string | null) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`/claims/bulk-upload?providerId=${providerId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadBulkPatients = (file: any, providerId: string[] | string | null) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`patients/excel/bulk-upload?providerId=${providerId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
