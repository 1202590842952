import React, {useState} from 'react';
import * as Yup from 'yup';
import {Field, FieldProps, FormikHelpers} from 'formik';
import {Row, Col} from 'react-grid-system';
import {useHistory} from 'react-router';

import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  branchManagementSelect,
  uploadPKI,
  getProviders,
  validateCleanup,
  errorCleanup,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {KlaimFileUploadV2} from 'modules/Common/components-v2/KlaimForm';
import * as Toast from 'modules/Common/utils/toast';
import {useStylesFromThemeFunction} from './BranchVerify';
import LoadingSpinner from '../../../../Common/components/LoadingSpinner';

export interface IValues {
  [field: string]: any;
}

const KSAForm: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {providers, uploadResult} = useAppSelector(branchManagementSelect);
  const {error, isLoading} = useAppSelector(branchManagementSelect);

  React.useEffect(() => {
    if (error) {
      dispatch(errorCleanup());
      dispatch(validateCleanup());
    }
  }, []);

  const [uploadSuccess, setUploadSuccess] = useState(false);

  const initialValuesKSA = {
    authority_password: '',
    files: null,
  };
  const validationSchemaKSA = Yup.object({
    authority_password: Yup.string().required('Password is required.'),
  });

  const onFileUpload = (values: IValues) => {
    if (!values.files) {
      return Toast.error('PKI file is required.');
    }
    const formData = new FormData();
    formData.append('authority_password', values.authority_password);
    formData.append('file', values.files.file, values.files.fileName);
    dispatch(uploadPKI({values: formData}));
  };

  React.useEffect(() => {
    if (uploadResult.success && uploadResult.pkiSenderId) {
      setUploadSuccess(true);
      dispatch(getProviders(uploadResult.pkiSenderId));
    } else if (uploadResult.error) {
      Toast.error(uploadResult.error);
    }
  }, [uploadResult]);

  const ksaForm = (
    <KlaimForm
      onSubmit={onFileUpload}
      initialValues={initialValuesKSA}
      validationSchema={validationSchemaKSA}
      validateOnChange
    >
      <Header
        isLoading={isLoading}
        title="Add Branch / Department"
        backlink="/settings/branch-management"
        description={
          <div className={classes.uploadNote}>
            First we need to identify the entity by providing credentials for Ne <br /> so that you can proceed to the
            next step <span>Learn more</span>
          </div>
        }
        buttonType="submit"
        buttonTitle="Next"
        body={
          <div className={classes.bodyWrapper}>
            {error && <div className={classes.error}>{error.message}</div>}
            {isLoading ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={classes.formContainer}>
                <KlaimInput name="authority_password" type="password" label="Password" />
                <div className={classes.uploadNote}>
                  Upload the PKI certificate file. <span>Learn more</span>
                </div>
                <div className={classes.uploadBox}>
                  <Field name="file">
                    {({form: {values, setFieldValue, errors}}: FieldProps) => {
                      return (
                        <KlaimFileUploadV2
                          acceptedFileTypes=".p12"
                          onChange={(val: any) => {
                            dispatch(errorCleanup());
                            dispatch(validateCleanup());
                            setFieldValue('files', val[0]);
                          }}
                          dropAreaText="Drag and drop PKI"
                          percentage={100}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
            )}
          </div>
        }
      />
    </KlaimForm>
  );

  const ksaSuccessForm = (
    <KlaimForm
      onSubmit={() => {
        history.push('/settings/branch-management');
      }}
      initialValues={initialValuesKSA}
      validationSchema={validationSchemaKSA}
      validateOnChange
    >
      <Header
        isLoading={isLoading}
        title="Add Branch / Department"
        backlink="/settings/branch-management/add"
        description={
          <div className={classes.uploadNote}>
            Add the appropriate branch or department <span>Learn more</span>
          </div>
        }
        buttonType="submit"
        buttonTitle="Next"
        body={
          <div className={classes.bodyWrapper}>
            {error && <div className={classes.error}>{error.message}</div>}
            {isLoading ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={classes.formContainer}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={12}>
                        <KlaimInput name="name" label={'Facility Name'} value={providers.data[0]?.name} isDisabled />
                      </Col>
                      <Col md={12}>
                        <KlaimInput
                          name="name"
                          label="Facility Arabic Name"
                          value={providers.data[0]?.i18n}
                          isDisabled
                        />
                      </Col>
                      <Col md={7}>
                        <KlaimInput name="name" label="NPHIES ID" value={providers.data[0]?.authorityCode} isDisabled />
                      </Col>
                      <Col md={5}>
                        <KlaimInput name="name" label="CCHI ID" value={providers.data[0]?.cchiId} isDisabled />
                      </Col>
                      <Col md={7}>
                        <KlaimInput
                          name="invoice_system"
                          label="Invoice system url"
                          value={providers.data[0]?.invoice_system}
                        />
                      </Col>
                      <Col md={5}>
                        <KlaimInput
                          name="episode_system"
                          label="Episode system url"
                          value={providers.data[0]?.episode_system}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        }
      />
    </KlaimForm>
  );

  const finalizeKsaDisplay = uploadSuccess ? ksaSuccessForm : ksaForm;

  return finalizeKsaDisplay;
};

export default KSAForm;
