import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import {axiosInstance} from 'services/axios';
import {PractitionerData, PractitionerQuery, IPractitioner} from 'interfaces/pre-auth.interface';
import {MyKnownError} from 'interfaces/error-interface';

interface InitialState {
  practitioner: {data: IPractitioner[]};
  isLoading: boolean;
  error: any;
}

export const getPractitioners = createAsyncThunk<PractitionerData, PractitionerQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/getPractitioners',
  async (query: PractitionerQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/practitioners', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const initialState: InitialState = {
  practitioner: {
    data: [],
  },
  isLoading: false,
  error: undefined,
};

const practitionerSlice = createSlice({
  name: 'practitioner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPractitioners.fulfilled, (state, action) => {
        state.practitioner.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getPractitioners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPractitioners.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const practitionerSelect = (state: RootState) => state.practitioner;
export default practitionerSlice.reducer;
