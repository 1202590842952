import {Card, CardProps} from 'custom-test-antd';
import './ReportCard.pcss';

const ReportCard = ({children, className, ...cardProps}: CardProps) => (
  <Card className={`ReportCard ${className}`} {...cardProps}>
    {children}
  </Card>
);

export default ReportCard;
