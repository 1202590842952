import React from 'react';
import {Switch} from 'react-router-dom';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import {IRoute} from 'interfaces/route.interface';

const UserManagement: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  return (
    <Switch>
      {routes &&
        routes.map(({routeKey, ...route}) => {
          return <RouteWithSubRoutes routeKey={routeKey} {...route} />;
        })}
    </Switch>
  );
};

export default UserManagement;
