import React from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';
import {CollapsibleContext} from '../KlaimCollapsible/index';

interface ComponentProps {
  style?: React.CSSProperties;
  className?: string;
  buttonFill?: string;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      display: 'flex',
      padding: 10,
    },
    bodyContainer: {
      flex: '1 1 0%;',
    },
    button: {
      marginRight: 10,
      background: 'none',
      padding: 0,
    },
    buttonIconDefault: {
      transform: 'rotate(360deg)',
      transition: '500ms',
    },
    buttonIconCollapse: {
      transform: 'rotate(180deg)',
      transition: '500ms',
    },
  };
});

const KlaimCollapsibleHeader: React.FC<ComponentProps> = ({children, style, className, buttonFill}) => {
  const {isCollapse, setIsCollapse} = React.useContext(CollapsibleContext);
  const classes = useStylesFromThemeFunction();
  return (
    <div className={`${className} ${classes.container}`} style={style}>
      <KlaimIconButton
        onClick={() => setIsCollapse(!isCollapse)}
        className={`${classes.button} ${isCollapse ? classes.buttonIconDefault : classes.buttonIconCollapse}`}
      >
        <ChevronArrowDown fill={buttonFill} />
      </KlaimIconButton>
      <div className={classes.bodyContainer}>{children}</div>
    </div>
  );
};

export default KlaimCollapsibleHeader;
