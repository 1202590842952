import React from 'react';
import {useStylesFromThemeFunction} from 'modules/Main/Sidebar/UserProfile/UserProfile';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {useAppSelector} from 'modules/App/store';
import userIcon from 'assets/user_icon.svg';
import UserSettings from 'modules/Main/Sidebar/UserProfile/UserSettings';

interface ComponentProps {
  isOpen: boolean;
}

const UserProfile: React.FC<ComponentProps> = ({isOpen}) => {
  const node = React.useRef<HTMLDivElement>(null);
  const [userIsOpen, setUserIsOpen] = React.useState(false);
  const {user} = useAppSelector(authSelect);
  const classes = useStylesFromThemeFunction();
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setUserIsOpen(false);
  };
  return (
    <div
      ref={node}
      role="button"
      onClick={() => {
        setUserIsOpen(!userIsOpen);
      }}
      className={`${classes.hoverContainer} ${isOpen ? classes.container : classes.containerClose}`}
    >
      <div className={classes.imageContainer}>
        <img src={userIcon} alt="user-icon" />
      </div>
      <div className={`${isOpen ? classes.nameContainerOpen : classes.nameContainerClose}`}>
        {isOpen ? (
          <>
            <h1>{user.name}</h1>
            <p>{user.organization.name}</p>
          </>
        ) : null}
      </div>
      {userIsOpen && (
        <div
          className={`${classes.contentContainer} ${
            isOpen ? classes.contentContainerOpen : classes.contentContainerClose
          }`}
        >
          <UserSettings user={user} />
        </div>
      )}
    </div>
  );
};

export default UserProfile;
