import React from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import {get as getStorage} from 'local-storage';
import CalendarIconPrev from 'assets/component/CalendarIconPrev';
import CalendarIconNext from 'assets/component/CalendarIconNext';
import {useStylesFromThemeFunction} from 'modules/Common/components/KlaimCalendar/KlaimCalendar';
import {headerSelect} from 'modules/Header/headerSlice';
import {Colors} from 'modules/Common/constants/Colors';

interface ComponentProps {
  onChange: (date: Date[]) => void;
  formatView?: 'month' | 'year' | 'decade' | 'century';
  value?: Date[];
  selectsRange?: boolean;
  startDate?: Date;
  endDate?: Date;
  showMonthYearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const KlaimCalendar: React.FC<ComponentProps> = ({
  onChange,
  selectsRange = false,
  showMonthYearPicker,
  maxDate,
  minDate,
  startDate,
  endDate,
  value,
  formatView,
}) => {
  const classes = useStylesFromThemeFunction();
  const [dateRange, setDateRange] = React.useState<Date[]>([]);

  React.useEffect(() => {
    if (value === undefined) {
      const initialDateRange = [dayjs().toDate(), dayjs().add(1, 'month').toDate()];
      setDateRange(initialDateRange);
    }
  }, []);

  React.useEffect(() => {
    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, [startDate, endDate]);

  const onDateChange = (date: any) => {
    if (selectsRange) {
      setDateRange(date);
      if (date[0] && date[1]) {
        onChange(date);
      }
    } else {
      const singleDate = [date, null];
      setDateRange(singleDate);
      onChange(singleDate);
    }
  };

  return (
    <DatePicker
      selected={dateRange[0]}
      calendarClassName={classes.calendarWrapper}
      onChange={onDateChange}
      inline
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      minDate={minDate}
      maxDate={maxDate}
      selectsRange={selectsRange}
      showMonthYearPicker={showMonthYearPicker}
      renderCustomHeader={({date, changeYear, decreaseMonth, increaseMonth}) => {
        const shouldDisable = (_date: Date) => {
          let disable = false;
          if (showMonthYearPicker) disable = _date ? dayjs(_date).format('YYYY') === dayjs(date).format('YYYY') : false;
          else disable = _date ? dayjs(_date).format('MMMM') === dayjs(date).format('MMMM') : false;
          return disable;
        };
        const disableDecrement = minDate ? shouldDisable(minDate) : false;
        const disableIncrement = maxDate ? shouldDisable(maxDate) : false;
        return (
          <div className={classes.headerContainer}>
            <button
              disabled={disableDecrement}
              onClick={() => {
                if (showMonthYearPicker) {
                  changeYear(Number(dayjs(date).format('YYYY').toString()) - 1);
                } else {
                  decreaseMonth();
                }
              }}
            >
              <CalendarIconPrev fill={disableDecrement ? Colors.gray : Colors.purple} />
            </button>
            <div>
              <h1>{dayjs(date).format('YYYY').toString()}</h1>
              {!showMonthYearPicker && <p>{dayjs(date).format('MMMM').toString().toUpperCase()}</p>}
            </div>

            <button
              disabled={disableIncrement}
              onClick={() => {
                if (showMonthYearPicker) {
                  changeYear(Number(dayjs(date).format('YYYY').toString()) + 1);
                } else {
                  increaseMonth();
                }
              }}
            >
              <CalendarIconNext fill={disableIncrement ? Colors.gray : Colors.purple} />
            </button>
          </div>
        );
      }}
    />
  );
};

export default KlaimCalendar;
