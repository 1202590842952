import {ApiError, commonFetch, encodeQueryFilter} from '../apiHelper';
import {Report, ReportDownloadObject} from './reportTypes';
// import {Branch, BranchListResponse} from './branchTypes';

export class ReportsApi {
  static getReports = async (providerIds: string, type: string): Promise<Report[]> => {
    const queryParams: any = {
      providerId: providerIds,
      type,
    };

    const requestURL = `${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}/reports${encodeQueryFilter(queryParams)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static downloadReport = async (reportId: string, providerId: string): Promise<ReportDownloadObject> => {
    const queryParams = {
      providerId,
    };

    const requestURL = `${
      process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN
    }/reports/download/${reportId}${encodeQueryFilter(queryParams)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}
