import React from 'react';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import {StepperContext} from '../KlaimStepper/index';

interface ComponentProps {
  title: string;
  onClick?: () => void;
  onBack?: () => void;
  onSubmit?: () => void;
  setCurrentStep?: (s: number) => void;
  isNextDisabled?: boolean;
  isSubmitDisabled?: boolean;
  submitText?: string;
}

const KlaimStepperHeaderButton: React.FC<ComponentProps> = ({
  title,
  onClick,
  onBack,
  setCurrentStep,
  onSubmit,
  isNextDisabled = false,
  isSubmitDisabled = false,
  submitText = 'Submit',
}) => {
  const {totalSteps, step, setStep} = React.useContext(StepperContext);

  const handleStepChange = (s: number) => {
    setStep(s);
    if (setCurrentStep) setCurrentStep(s);
    handleClick();
  };
  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };

  const handleBack = () => {
    if (onBack) onBack();
  };
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <h2 style={{fontSize: 22, fontWeight: 'bold', color: '#2C273C'}}>{title}</h2>
      <div>
        {step + 1 > 1 && (
          <KlaimButton variant="primary-outline" disabled={isNextDisabled} onClick={handleBack}>
            Back
          </KlaimButton>
        )}
        {step + 1 < totalSteps && (
          <KlaimButton disabled={isNextDisabled} onClick={handleClick}>
            Next
          </KlaimButton>
        )}
        {step + 1 === totalSteps && (
          <KlaimButton disabled={isSubmitDisabled} onClick={() => handleSubmit()}>
            {submitText}
          </KlaimButton>
        )}
      </div>
    </div>
  );
};

export default KlaimStepperHeaderButton;
