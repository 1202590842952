import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  bodyWrapper: {
    '& p': {
      color: Colors.blueGrayDark,
      fontSize: '13px',
      marginBottom: '28px',
      width: '450px',
    },
    width: '60%',
  },
  errorContainer: {
    color: Colors.red,
    textAlign: 'left',
    '& span': {
      marginRight: '5px',
    },
  },
  toastContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
  },
  input: {
    padding: '10px',
    width: 300,
  },
});
