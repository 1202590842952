import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {eligibilityListSchema} from 'modules/App/schemas';
import {NormalizedList} from 'modules/App/types/normalizr';
import {EL} from '@klaim-ai/klaim-interfaces';

const eligibilityList = createSlice({
  initialState: {
    allIds: [],
    byId: {} as NormalizedList<EL.Contract.FindAll.Response> | undefined,
  },
  name: 'eligibilityList',
  reducers: {
    getEligibilityList: (state, {payload}: PayloadAction<EL.Contract.FindAll.Response>) => {
      const normalizedEligibilityList = normalize(payload.data, eligibilityListSchema);
      state.allIds = normalizedEligibilityList.result;
      state.byId = normalizedEligibilityList.entities.eligibility;
    },
  },
});

export const {getEligibilityList} = eligibilityList.actions;

export default eligibilityList;
