import React from 'react';
import TopMenu from 'modules/Settings/components/TopMenu';
import {Switch, RouteComponentProps, useLocation} from 'react-router-dom';
import {IRoute} from 'interfaces/route.interface';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {useStylesFromThemeFunction} from 'modules/Settings/features/AccessManagement/AccessManagement';
import {accessManagementSelect, getRoles} from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import RoleRow from 'modules/Settings/features/AccessManagement/RoleRow';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {permissions as permissionsConstant} from 'modules/Common/constants/Permissions';
import * as Toast from 'modules/Common/utils/toast';

export const AccessManagement: React.FC<RouteComponentProps> = ({history}) => {
  const dispatch = useAppDispatch();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const searchParam = query.get('search');
  const {roles, limit, isLoading, isDeleteSuccess} = useAppSelector(accessManagementSelect);
  const classes = useStylesFromThemeFunction();
  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [sortBy, setSortBy] = React.useState<string>('');

  const getRolesAction = () => {
    dispatch(
      getRoles({
        limit,
        offset: (offset - 1) * limit,
        search,
        sortBy,
      }),
    );
  };

  React.useEffect(() => {
    getRolesAction();
  }, [offset, search]);

  React.useEffect(() => {
    if (isDeleteSuccess) {
      getRolesAction();
      Toast.success('Role is deleted successfully', 'Success');
    }
  }, [isDeleteSuccess]);

  React.useEffect(() => {
    const searchAction = setTimeout(() => {
      setSearch(query.get('search') || '');
    }, 500);
    return () => clearTimeout(searchAction);
  }, [query.get('search')]);

  return (
    <div className={classes.accessManagementWrapper}>
      <TopMenu
        title="Access Management"
        description={
          <p>
            Manage your roles and see how many people are assigned to them. Create custom roles according to your needs.
          </p>
        }
        buttonTitle="Create Role"
        inputPlaceholder="Search role"
        onButtonClick={() => history.push('/settings/access-management/add-roles')}
        requiredPermission={permissionsConstant.add_user_roles.identifier}
      />
      <div className={classes.scrollContent}>
        <KlaimPaginationContainer
          pageSize={limit}
          totalCount={roles.data.total}
          currentPage={offset}
          onPageChange={setOffset}
          label="roles"
        >
          <p className={classes.tableName}>Roles list</p>
          <div className={classes.tableWrapper}>
            <div className={classes.roleListTable}>
              {isLoading ? (
                <div className={classes.loadingContainer}>
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {roles.data.total > 0 ? (
                    roles.data.roles.map((role: any, index: number) => (
                      <RoleRow key={index} role={role} index={index} />
                    ))
                  ) : (
                    <div style={{paddingLeft: 30, paddingTop: 10, paddingBottom: 10}}>
                      There are no roles yet, add your first role by clicking "Create Role"
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </KlaimPaginationContainer>
      </div>
    </div>
  );
};

const AccessManagementContainer: React.FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  return (
    <Switch>
      {routes &&
        routes.map(({routeKey, ...route}) => {
          return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
        })}
    </Switch>
  );
};

export default AccessManagementContainer;
