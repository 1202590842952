import React, {FC, useState} from 'react';
import dayjs from 'dayjs';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import ErrorsModal from 'modules/Claims/features/v3/ClaimDetailsV3/ErrorsModal';
import {PreAuthHistory} from 'interfaces/pre-auth.interface';
import {useAppDispatch} from 'modules/App/store';
import {get} from 'lodash';
import timeLogo from '../../../../../assets/time.svg';
import {claimStatues, claimType, visitTypeData} from '../../../../Claims/features/v3/ClaimsList/data';
import UserLogo from '../../../../../assets/user_small.svg';
import {useStylesFromThemeFunction} from '../../../../Claims/features/v3/ClaimsList/ClaimsList';
import moreIcon from '../../../../../assets/more_icon_2.svg';

interface ComponentProps {
  data: PreAuthHistory;
  handleView: (id: any, providerId: string) => void;
  handleResubmit: (id: any, providerId: string) => void;
  handleProceedToClaim: (id: any, providerId: string) => void;
  handleViewEligibilityDetails: (id: any, providerId: string) => void;
  openModal: any;
  setItem: any;
  setModalContent: any;
}

const formatDate = (date: string) => {
  return dayjs(date).format('D MMM YYYY');
};

const PreAuthHistoryItem: FC<ComponentProps> = ({
  data,
  openModal,
  setItem,
  handleView,
  handleResubmit,
  handleProceedToClaim,
  handleViewEligibilityDetails,
  setModalContent,
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {name} = data.submissions?.[0]?.patient || {name: ''};
  const {servicedDate} = data.submissions?.[0]?.claim?.item?.[0] || {};
  const [errors, setErrors] = useState([]);
  const [openErrors, setOpenErrors] = useState(false);

  const stateIndicator = (state: string) => {
    return (
      <div className={classes.statusCont}>
        <div className={`${classes.status} ${state}`} />
        <div className={classes.statusText}>{state}</div>
      </div>
    );
  };
  const actions = () => (
    <KlaimDropdownContainer isIconButton icon={moreIcon} position="center">
      <div
        role="button"
        onClick={() => handleView(data.id, data.submissions[0].senderId)}
        className={`${classes.button} ${classes.navLink}`}
      >
        View
      </div>
      {data.status !== 'error' && (
        <div
          role="button"
          onClick={() => handleResubmit(data.id, data.submissions[0].senderId)}
          className={`${classes.button} ${classes.navLink}`}
        >
          Resubmit
        </div>
      )}
      {data.eligibilityId && (
        <div
          role="button"
          onClick={() => handleViewEligibilityDetails(data.eligibilityId, data.submissions[0].senderId)}
          className={`${classes.button} ${classes.navLink}`}
        >
          View Eligibility Details
        </div>
      )}
      {data.status !== 'cancelling' && data.status !== 'cancelled' && data.status !== 'error' && (
        <>
          <div
            role="button"
            onClick={() => handleProceedToClaim(data.id, data.submissions[0].senderId)}
            className={`${classes.button} ${classes.navLink}`}
          >
            Proceed to Claim
          </div>
          <div
            role="button"
            onClick={() => {
              openModal(true);
              setItem(data);
              setModalContent('cancellation');
            }}
            className={`${classes.button} ${classes.navLink}`}
          >
            Cancellation
          </div>
          <div
            role="button"
            onClick={() => {
              openModal(true);
              setItem(data);
              setModalContent('communication');
            }}
            className={`${classes.button} ${classes.navLink}`}
          >
            Communication Request
          </div>
        </>
      )}
    </KlaimDropdownContainer>
  );
  const item = data as any;
  return (
    <>
      <div className={classes.tableRow} role={'link'}>
        {/*<div className={classes.left} style={{width: 40}}>*/}
        {/*<input*/}
        {/*  className={classes.checkBox}*/}
        {/*  type="checkbox"*/}
        {/*  checked={selectedIds.indexOf(el.id) !== -1}*/}
        {/*  onChange={(e) => {*/}
        {/*    if (e.target.checked) setSelectedIds([...selectedIds, el.id]);*/}
        {/*    else setSelectedIds(selectedIds.splice(selectedIds.indexOf(el.id), 1));*/}
        {/*  }}*/}
        {/*/>*/}
        {/*</div>*/}
        <div className={classes.label} style={{width: 170}}>
          <div className={classes.col}>
            <span style={{position: 'relative'}} className={classes.popupContainer}>
              {item.id && item.id.substring(0, 5)}...
              <div className={classes.popup}>
                <b>ID</b>
                <p>{item.id}</p>
              </div>
            </span>
            <span className={classes.date}>
              <img src={timeLogo} alt="timeLogo" />
              {formatDate(item.submissions[0].claim.item[0].servicedPeriod?.start)}
            </span>
            <span className={classes.date}>
              <b>
                {claimType(get(item, 'submissions[0].claim.claimSubtype'), item.midtableSupportingData?.specialityName)}
              </b>
            </span>
          </div>
        </div>
        <div className={classes.label} style={{width: 140}}>
          {get(item, 'submissions[0].payerName')}
          <br />
          {get(item, 'submissions[0].receiverName')}
        </div>
        <div className={classes.label} style={{width: 160}}>
          <div className={classes.col}>
            <span>{get(item, 'submissions[0].patient.name')}</span>
            <span className={classes.date}>
              <img src={UserLogo} alt="timeLogo" />
              <span style={{position: 'relative'}} className={classes.popupContainer}>
                {get(item, 'submissions[0].patient.documentId') &&
                  get(item, 'submissions[0].patient.documentId').substring(0, 5)}
                ...
                <div className={classes.popup}>
                  <b>ID</b>
                  <p>{get(item, 'submissions[0].patient.documentId')}</p>
                </div>
              </span>
            </span>
            <span className={classes.date}>
              <b>{item.patientNo}</b>
            </span>
          </div>
        </div>
        <div className={classes.label} style={{width: 250}}>
          {/* claimBillablePeriodEnd */}
          {/* claimBillablePeriodStart */}
          {`${
            get(item, 'submissions[0].claim.claimBillablePeriodStart')
              ? formatDate(get(item, 'submissions[0].claim.claimBillablePeriodStart'))
              : 'No Date'
          } - ${
            get(item, 'submissions[0].claim.claimBillablePeriodStart')
              ? formatDate(get(item, 'submissions[0].claim.claimBillablePeriodEnd'))
              : 'No Date'
          }`}
        </div>
        <div className={classes.label} style={{width: 120}}>
          <span
            onClick={() => {
              setErrors(get(item, 'responses[0].errors', null));
              if (get(item, 'responses[0].errors', []).length > 0) setOpenErrors(true);
            }}
            role={'button'}
            className={classes.statuse}
            style={{
              cursor: 'pointer',
              backgroundColor: claimStatues[item.status]?.bg,
              color: claimStatues[item.status]?.text,
            }}
          >
            {claimStatues[item.status]?.label}
          </span>
        </div>
        <div className={classes.label} style={{width: 200}}>
          <div className={classes.col}>
            <span className={classes.date}>{item.clinicName}</span>
            <span className={classes.date}>
              <img src={UserLogo} alt="timeLogo" />
              {item?.midtableSupportingData?.doctorName}
            </span>
            <span className={classes.date}>
              <b>{visitTypeData[item.visitType]}</b>
            </span>
            <span className={classes.date}>{get(item, 'submissions[0].senderName')}</span>
          </div>
        </div>
        {/* <div className={classes.label} style={{width: 70}}> */}
        {/*   <div className={classes.col}> */}
        {/*     {item.isSynchronized ? ( */}
        {/*       <span className={classes.approvedStatus}>YES</span> */}
        {/*     ) : ( */}
        {/*       <span className={classes.deniedStatus}>NO</span> */}
        {/*     )} */}
        {/*   </div> */}
        {/*  </div> */}
        <div className={classes.label} style={{width: 400, marginRight: 20}}>
          <div className={classes.col}>
            <div className={classes.row}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>Gross</div>
                <div className={classes.label}>{item.financialData?.totalGrossAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>NET</div>
                <div className={classes.label}>{item.financialData?.totalNetAmount}</div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>DISCOUNT</div>
                <div className={classes.label}>{item.financialData?.totalDiscountAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value}>VAT</div>
                <div className={classes.label}>{item.financialData?.totalVatAmount}</div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.spacedRow}>
                <div className={classes.value}>COPAY</div>
                <div className={classes.label}>{item.financialData?.totalCopaymentAmount}</div>
              </div>
              <div className={classes.spacedRow}>
                <div className={classes.value} />
                <div className={classes.label} />
              </div>
            </div>
          </div>
        </div>
        <div style={{width: 20}}>{actions()}</div>
      </div>
      <ErrorsModal open={openErrors} setOpen={setOpenErrors} errors={errors} />
    </>
  );
};

export default PreAuthHistoryItem;
