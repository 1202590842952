import React from 'react';
import {createUseStyles} from 'react-jss';
import AnimateHeight from 'react-animate-height';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {IPatientRel} from 'interfaces/pre-auth.interface';
import {KlaimDropdownSearch, KlaimToggle, KlaimInput} from 'modules/Common/components-v2/KlaimForm';
import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import {relationshipToPatient, policyHolderType} from 'modules/Common/constants/DropdownOptions';
import {Colors} from 'modules/Common/constants/Colors';
import CostToBeneficiary from './components/CostToBeneficiary';
import PatientRelationship from '../PatientRelationship';
import {usePreAuthFormContext} from '../PreAuthFormContext';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => ({
  baseFormGrid: {
    display: 'grid',
    rowGap: '1rem',
    columnGap: '2rem',
  },
  container: {
    padding: 20,
    backgroundColor: theme.container.color.secondary,
    borderRadius: 10,
    marginBottom: '1rem',
  },
  label: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '4px',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& span': {
      fontWeight: 'normal',
      fontSize: '13px',
    },
    fontWeight: 600,
    color: Colors.grayLabel,
  },
}));

interface ComponentProps {
  onChange: (value: any) => void;
  optional?: boolean;
}

const Coverage: React.FC<ComponentProps> = ({onChange, optional = false}) => {
  const [toggle, setToggle] = React.useState<boolean>(false);
  const classes = useStylesFromThemeFunction();
  const {constants, setter} = usePreAuthFormContext();
  const [height, setHeight] = React.useState(toggle ? 'auto' : '0%');

  React.useEffect(() => {
    if (!constants.coverage.coverageRel) {
      setter.setIsCoverageFulfilled(false);
    } else {
      setter.setIsCoverageFulfilled(true);
    }
    onChange(constants.coverage);
  }, [constants.coverage]);

  React.useEffect(() => {
    setHeight(toggle ? 'auto' : '0%');
  }, [toggle]);

  const handlePatientRelOnChange = (values: IPatientRel) => {
    setter.setPatientRel(values);
  };
  return (
    <>
      <div
        style={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: 'repeat(2, minmax(0, 2fr))',
          marginBottom: 20,
        }}
      >
        <KlaimInput
          name="subscriber_id"
          label="Subscriber ID"
          placeholder="ex. 2249059177"
          value={constants.coverage.subscriberId}
          isReadOnly={constants.eligibility}
          onChange={(value) => setter.setCoverage({...constants.coverage, subscriberId: value})}
          variant="secondary"
        />
        <KlaimDropdownSearch
          name="relationshipToPatient"
          label="Relationship to Patient"
          variant={'secondary'}
          options={relationshipToPatient}
          value={constants.coverage.coverageRel}
          onChange={(value) => {
            setter.setCoverage({...constants.coverage, coverageRel: value});
          }}
        />
        <div style={{gridColumn: 'span 2 / span 2'}}>
          {constants.coverage &&
            constants.coverage.coverageRel !== 'self' &&
            constants.coverage.coverageRel !== 'injured' &&
            constants.coverage.coverageRel !== '' && (
              <PatientRelationship onChange={handlePatientRelOnChange} initialValue={constants.patientRel} />
            )}
        </div>
      </div>
      {/*<label className={classes.label}>{optional && <span>(Optional)</span>}</label>*/}
      {/*<div className={`${classes.container} `}>*/}
      {/*  <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'}}>*/}
      {/*    <div style={{gridColumn: 'span 2 / span 2'}}>*/}
      {/*      <h3 style={{fontSize: 14}}>Optional Coverage items</h3>*/}
      {/*      <p style={{fontSize: 14}}>*/}
      {/*        Add optional coverage items like Policy No, Class Name, Cost to beneficiary etc.*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*    <div style={{display: 'flex', WebkitJustifyContent: 'flex-end', height: 18}}>*/}
      {/*      <KlaimToggle value={toggle} onChange={setToggle} width={56} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <AnimateHeight duration={500} height={height}>*/}
      {/*    <div className={`${classes.baseFormGrid}`}>*/}
      {/*      <div*/}
      {/*        style={{display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'}}*/}
      {/*        className={classes.baseFormGrid}*/}
      {/*      >*/}
      {/*        <KlaimDropdownSearch*/}
      {/*          name="policyHolder"*/}
      {/*          label="Policy Holder"*/}
      {/*          options={policyHolderType}*/}
      {/*          isOptional*/}
      {/*          value={constants.coverage.policyHolder}*/}
      {/*          onChange={(value) => {*/}
      {/*            setter.setCoverage({...constants.coverage, policyHolder: value});*/}
      {/*          }}*/}
      {/*        />*/}
      {/*        {constants.coverage.policyHolder === 'organization' && (*/}
      {/*          <>*/}
      {/*            <KlaimInput*/}
      {/*              optional*/}
      {/*              value={constants.coverage.organizationPolicyHolder?.identifier}*/}
      {/*              onChange={(e: any) => {*/}
      {/*                setter.setCoverage({*/}
      {/*                  ...constants.coverage,*/}
      {/*                  organizationPolicyHolder: {*/}
      {/*                    ...constants.coverage.organizationPolicyHolder,*/}
      {/*                    identifier: e,*/}
      {/*                  },*/}
      {/*                });*/}
      {/*              }}*/}
      {/*              label={'Organization Identifier'}*/}
      {/*              name={'identifier'}*/}
      {/*            />*/}
      {/*            <KlaimInput*/}
      {/*              optional*/}
      {/*              value={constants.coverage.organizationPolicyHolder?.name}*/}
      {/*              onChange={(e: any) => {*/}
      {/*                setter.setCoverage({*/}
      {/*                  ...constants.coverage,*/}
      {/*                  organizationPolicyHolder: {*/}
      {/*                    ...constants.coverage.organizationPolicyHolder,*/}
      {/*                    name: e,*/}
      {/*                  },*/}
      {/*                });*/}
      {/*              }}*/}
      {/*              label={'Organization Name'}*/}
      {/*              name={'name'}*/}
      {/*            />*/}
      {/*          </>*/}
      {/*        )}*/}
      {/*        <KlaimInput*/}
      {/*          optional*/}
      {/*          value={constants.coverage.schemeName}*/}
      {/*          onChange={(e: any) => {*/}
      {/*            setter.setCoverage({...constants.coverage, schemeName: e});*/}
      {/*          }}*/}
      {/*          label={'Class Name'}*/}
      {/*          name={'className'}*/}
      {/*        />*/}
      {/*        <KlaimDropdownSearch*/}
      {/*          isOptional*/}
      {/*          value={constants.coverage.schemeClass}*/}
      {/*          label={'Class'}*/}
      {/*          onChange={(e) => setter.setCoverage({...constants.coverage, schemeClass: e})}*/}
      {/*          options={[*/}
      {/*            {label: 'A', value: 'A'},*/}
      {/*            {label: 'B', value: 'B'},*/}
      {/*            {label: 'C', value: 'C'},*/}
      {/*          ]}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </AnimateHeight>*/}
      {/*</div>*/}
      {/*<label className={classes.label}>{optional && <span>(Optional)</span>}</label>*/}
      {/*<KlaimContainer variant="secondary">*/}
      {/*  <CostToBeneficiary*/}
      {/*    onChange={(e) => setter.setCoverage({...constants.coverage, costToBeneficiary: e})}*/}
      {/*    initialValue={constants.coverage.costToBeneficiary}*/}
      {/*  />*/}
      {/*</KlaimContainer>*/}
    </>
  );
};

export default Coverage;
