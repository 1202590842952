import {Report} from 'api/reports/reportTypes';
import {DownloadOutlined} from '@ant-design/icons';
import {Button, Table} from 'custom-test-antd';
import {FC} from 'react';
import ReportCard from '../ReportCard/ReportCard';

interface ComponentProps {
  reports: Report[];
  loading: boolean;
  downloading: number | null;
  onReportDownload: (report: Report, index: number) => void;
}

const ReportListCard: FC<ComponentProps> = ({reports, loading, downloading, onReportDownload}) => {
  const getColumns = () => [
    {
      title: 'Name',
      dataIndex: 'fileName',
    },
    {
      title: 'Action',
      width: 100,
      render: (_, report: Report, index: number) => (
        <div>
          <Button
            className="ReportList-downloadButton"
            icon={<DownloadOutlined rev />}
            type="link"
            loading={index === downloading}
            onClick={() => {
              onReportDownload(report, index);
            }}
          >
            Download
          </Button>
        </div>
      ),
    },
  ];
  return (
    <ReportCard title="Reports">
      <Table
        rightAngleTopCorners
        whiteHeader
        loading={loading}
        columns={getColumns()}
        dataSource={reports}
        rowKey="id"
      />
    </ReportCard>
  );
};

export default ReportListCard;
