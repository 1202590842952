import React, {FC, useState, useEffect} from 'react';
import {Field} from 'formik';
import infoIcon from 'assets/info_icon.svg';
import EditIcon from 'assets/edit.svg';
import HistoryIcon from 'assets/history_icon.svg';
import DoubleCheckIcon from 'assets/double_check.svg';
import CancelIcon from 'assets/cancel_icon.svg';
import Input from 'modules/Common/components/Input';
import {useStylesFromThemeFunction} from './ErxSelection';
import {ErxDrugsRefId, ErxGenericDrug} from '../../features/ErxReferencePrescription/types';
import ErxDrugSearch from '../ErxDrugSearch';

interface ComponentProps {
  name: string;
  index: number;
  activity: ErxDrugsRefId;
  editId: string;
  genericName: string;
  selectedValue: string;
  setSearchFilter: (searchKeyword: string) => void;
  actCodeIdentifier: string;
  toggle: (id: string) => void;
  getNewQuantity: (inputtedQuantinty: string) => void;
  newQuantity: string;
  getNewDuration: (inputtedDuration: string) => void;
  newDuration: string;
  updateDrugActivities: (id: string, values: any) => void;
  setEditId: (edit: string) => void;
  setGenericName: (genericName: string) => void;
}

const ErxSelection: FC<ComponentProps> = ({
  name,
  index,
  activity,
  editId,
  genericName,
  selectedValue,
  setSearchFilter,
  actCodeIdentifier,
  toggle,
  getNewQuantity,
  newQuantity,
  getNewDuration,
  newDuration,
  updateDrugActivities,
  setEditId,
  setGenericName,
}) => {
  const classes = useStylesFromThemeFunction();

  const getNewDrugValue = (
    drugCode: string,
    drugName: string,
    drugStrength: string,
    drugDosageFormPackage: string,
    drugManufacturer: string,
  ) => {
    setEditId('');
    setGenericName('');
  };

  const [drugs, setDrugs] = React.useState<ErxGenericDrug[]>([]);
  useEffect(() => {
    if (activity.generic) {
      setDrugs([
        ...drugs,
        ...activity.generic_drugs,
        {
          code: activity.code,
          generic_code: activity.code,
          name: activity.generic_name,
        },
      ]);
    } else {
      setDrugs([...drugs, ...activity.generic_drugs]);
    }
    // const drugOptions = activity.
  }, []);

  useEffect(() => {
    setCurrentDrug(drugs[0]);
  }, [drugs]);

  interface SelectedDrug {
    code: string;
    name: string;
    strength?: string;
    dosage?: string;
    manufacturer?: string;
    package_price?: string;
    unit_per_package?: string;
  }

  const packagePrice = parseFloat(activity.package_price) / parseFloat(activity.unit_per_package);
  const netPrice = Math.round(activity.quantity * packagePrice);
  const commentValue = 'DHA-PROVIDER-DEMO-TPA-DEMO-20210608191432-0001';
  const [show, setUnShow] = useState(false);
  const [selDrug, setSelDrug] = React.useState<SelectedDrug>();
  const [currentDrug, setCurrentDrug] = React.useState<SelectedDrug>();
  const onSelectDrug = (drugCode: string, isDefault: boolean, selected?: SelectedDrug) => {
    if (isDefault) {
      setSelDrug(drugs.find((drug) => drug.code === drugCode));
    }
    if (selected && isDefault === false) setSelDrug(selected);
    setUnShow(!show);
  };

  useEffect(() => {
    console.log('activity', activity);
    console.log('selDrug', selDrug);
  }, [selDrug]);

  const onUpdateSelectDrug = (actId: string) => {
    console.log('actId on selection', actId);
    const newValues = {
      id: actId,
      code: selDrug?.code,
      name: selDrug?.name,
      package_price: selDrug?.package_price,
      quantity: activity.quantity,
      unit_per_package: selDrug?.unit_per_package,
      duration: activity.duration,
      instructions: activity.instructions,
      clinicianId: activity.clinicianId,
    };
    updateDrugActivities(actId, newValues);
    setEditId('');
  };

  const showDrugSearch = () => {
    setUnShow(!show);
  };

  const [select, setUnselect] = useState(activity.isSelected);

  const toggleSelection = () => {
    setUnselect(!select);
  };

  return (
    <div className={classes.drugCard} key={index}>
      <div className={classes.cardRow}>
        <div className={classes.cardInfoSection}>
          <div className={classes.spaceCheckBox}>
            <Field
              type="checkbox"
              name={name}
              value={activity.id}
              // defaultChecked={select}
              // onChange={() => toggleSelection()}
            />
            <span className={classes.spaceCheckBoxField}>Drug selection for authorization</span>
          </div>
          {editId === activity.id ? (
            <div>
              <div className={classes.titleSearch}>Search Drug</div>
              <ErxDrugSearch
                getNewDrugValue={getNewDrugValue}
                genericName={genericName}
                activityCode={activity}
                setSearchFilter={setSearchFilter}
                drugs={drugs}
                onSelectDrug={onSelectDrug}
                show={show}
                showDrugSearch={showDrugSearch}
                selDrug={selDrug}
                currentDrug={currentDrug}
              />
            </div>
          ) : (
            <>
              <div>
                <p> Prescribed Drug {activity.id}</p>
                <div>{activity.name ? activity.name : currentDrug?.name}</div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={`${classes.cardRow} ${classes.flexUnset}`}>
        <div className={classes.cardInfoSection}>
          <p>Quantity</p>
          <span>{activity.quantity}</span>
        </div>

        <div className={classes.cardInfoSection}>
          <p>Units</p>
          <span>
            {activity?.unit_per_package} {activity?.unit_price}
          </span>
        </div>

        <div className={classes.cardInfoSection}>
          <p>Duration</p>
          <span>{activity?.duration}</span>
        </div>

        <div className={classes.cardInfoSection}>
          <p>Comment</p>
          <span>
            <img src={infoIcon} alt="info" className={classes.imgSpacing} />
            {commentValue.substring(0, 17)}
          </span>
        </div>
      </div>
      <div className={classes.cardRow}>
        <div className={classes.cardInfoSection}>
          <p>Instructions</p>
          <span>{activity?.instructions}</span>
        </div>
      </div>
      <div className={`${classes.cardRow2} ${classes.flexUnset}`}>
        <div className={classes.cardInfoSection2}>
          <p>Net</p>
          <span>{netPrice ? netPrice.toFixed(2) : '-'}</span>
        </div>
        <div className={classes.cardInfoSection2}>
          <p> Payment Amount</p>
          {/* <span>{activity.paymentAmount}</span> */}
          <span>-</span>
        </div>
        <div className={classes.cardInfoSection}>
          <p>Patient Share</p>
          {/* <span>{activity.patientShare}</span> */}
          <span>-</span>
        </div>
      </div>
      <div className={`${classes.cardRowEnd} ${classes.cardInfoSectionLast} `}>
        <div className={classes.cardInfoSection}>Collapse</div>
        <div className={classes.cardInfoSectionEnd}>
          {editId === activity.id ? (
            <>
              <button className={classes.buttonRemove} onClick={() => setEditId('')}>
                <span>
                  <img className={classes.Image} src={CancelIcon} alt="cancelIcon" />
                  Cancel
                </span>
              </button>
              <button className={classes.buttonRemove} onClick={() => onUpdateSelectDrug(activity.id)}>
                <span>
                  <img className={classes.Image} src={DoubleCheckIcon} alt="doubleCheckIcon" />
                  Update
                </span>
              </button>
            </>
          ) : (
            <>
              {/* <button className={classes.buttonRemove}>
                <span>
                  <img className={classes.Image} src={HistoryIcon} alt="historyIcon" />
                  History
                </span>
              </button> */}
              <button className={classes.buttonRemove} onClick={() => toggle(activity.id)}>
                <span>
                  <img className={classes.Image} src={EditIcon} alt="editIcon" />
                  Edit
                </span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErxSelection;
