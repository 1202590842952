import React from 'react';
import {useStylesFromThemeFunction} from './KlaimLabel';

interface ComponentProps {
  label: string;
  name: string;
  optional?: boolean;
  className?: string;
  isError?: boolean;
  isTouched?: boolean;
}
const KlaimLabel: React.FC<ComponentProps> = ({
  label,
  optional,
  name,
  className,
  isTouched,
  isError,
  children,
  ...rest
}) => {
  const classes = useStylesFromThemeFunction();
  const validateLabel = (touched?: boolean, error?: boolean) => {
    if (touched && error) {
      return classes.labelError;
    }
    if (touched) {
      return classes.labelValidated;
    }
  };
  return (
    <div>
      <div className={`${classes.contentContainer} ${className} `}>
        <label className={`${classes.label} ${validateLabel(isTouched, isError)}`} htmlFor={name}>
          {label}
          {optional && <span>(Optional)</span>}
        </label>
        {children}
      </div>
    </div>
  );
};

export default KlaimLabel;
