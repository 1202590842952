import {ApiError, commonFetch, encodeQueryFilter} from '../apiHelper';
import {Branch, BranchListResponse} from './branchTypes';

export class BranchApi {
  static getUserBranches = async (): Promise<Branch[]> => {
    const requestURL = `${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}/users/current/branches`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getBranches = async (
    filter: {search?: string; sortBy?: string},
    pagination: {pageSize: number; current: number},
  ): Promise<BranchListResponse> => {
    const queryParams: any = {
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
      search: filter.search ?? '',
      sortBy: filter.sortBy ?? '',
    };

    const requestURL = `${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}/branches${encodeQueryFilter(queryParams)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  // static deleteBranch = async (branchId: string): Promise<{success: boolean}> => {
  //   const requestURL = `${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}/branches/${branchId}`;
  //   const backendResponse = await commonFetch(requestURL, {
  //     method: 'DELETE',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   if (backendResponse.ok) {
  //     return backendResponse.json();
  //   }
  //   throw new ApiError(backendResponse);
  // };
}
