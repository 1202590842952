import React from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    buttonGroupContainer: {
      borderRadius: '.75rem',
      display: 'flex',
    },
  };
});

interface ComponentProps {
  onChange?: (value: any) => void;
  value?: string;
}

interface IToggleButton {
  selected: string;
  childCount: number;
  setSelected: (value: string) => void;
}

export const ToggleButtonGroupContext = React.createContext<IToggleButton>({
  selected: '',
  setSelected: () => {},
  childCount: 0,
});

const ToggleButtonGroup: React.FC<ComponentProps> = ({children, onChange, value}) => {
  const classes = useStylesFromThemeFunction();
  const [selected, setSelected] = React.useState(value || '');
  const [childCount, setChildCount] = React.useState(0);

  React.useEffect(() => {
    setChildCount(React.Children.count(children));
  }, []);

  React.useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {position: i + 1});
    }
    return child;
  });

  return (
    <ToggleButtonGroupContext.Provider value={{selected, setSelected, childCount}}>
      <div className={classes.buttonGroupContainer}>{childrenWithProps}</div>
    </ToggleButtonGroupContext.Provider>
  );
};

export default ToggleButtonGroup;
