import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    card: {
      background: 'white',
      borderRadius: 10,
      flexDirection: 'column',
      padding: '38px',
      marginBottom: '20px',
      boxShadow: '0px 0.5px 2px #60617029;',
    },
    title: {
      fontSize: '22px',
      fontWeight: 700,
    },
    content: {
      marginTop: '41px',
    },
    label: {
      color: '#696F81CC',
      fontSize: '10px',
      fontWeight: 600,
    },
    value: {
      color: '#222B45',
      fontSize: '14px',
      fontWeight: 400,
      marginTop: '10px',
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
    },
    hr: {
      width: '100%',
      height: 1,
      backgroundColor: '#EEEAFB',
      margin: '30px 0',
    },
    payed: {
      width: 16,
      height: 16,
      backgroundColor: '#41D8B6',
      borderRadius: 8,
    },
    cleared: {
      width: 16,
      height: 16,
      backgroundColor: '#6135FB',
      borderRadius: 8,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#222B45',
      fontSize: '14px',
      fontWeight: 400,
      gap: 14,
    },
  };
});
