import React from 'react';
import * as Yup from 'yup';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import KlaimForm from 'modules/Common/components/KlaimForm';
import Header from 'modules/Settings/components/SubHeader';
import KlaimInput from 'modules/Common/components/KlaimInput';
import {
  changePassword,
  changePasswordCleanup,
  accountSelect,
} from 'modules/Settings/features/AccountSettings/accountSettingsSlice';
import {useHistory} from 'react-router';
import ErrorIcon from 'assets/error_outline_red.svg';
import {setNewToast} from 'modules/Common/components/KlaimToast/toastSlice';
import {useStylesFromThemeFunction} from './ChangePassword';

export interface IValues {
  [field: string]: any;
}
const ChangePassword: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {isChangePassword, errors} = useAppSelector(accountSelect);

  React.useEffect(() => {
    if (isChangePassword) {
      history.push('/settings/account');
      dispatch(
        setNewToast({
          variant: 'success',
          title: 'Success',
          message: `Your password has been changed successfully.`,
        }),
      );
    }
    return () => {
      dispatch(changePasswordCleanup());
    };
  }, [isChangePassword]);

  const onSubmit = (values: IValues) => {
    dispatch(
      changePassword({
        oldPassword: values.oldPassword,
        password: values.password,
      }),
    );
  };

  const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required('Old Password is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  return (
    <KlaimForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange
      enableReinitialize
    >
      <Header
        title="Change Password"
        description={<p>Changing your password will sign you out of your devices, with some exceptions.</p>}
        buttonTitle="Save Changes"
        body={
          <div className={classes.inputContainer}>
            <KlaimInput placeholder="Old Password" label="Old Password" name="oldPassword" type="password" />
            <KlaimInput placeholder="New Password" label="New Password" name="password" type="password" />
            <KlaimInput
              placeholder="Confirm New Password"
              label="Confirm New Password"
              name="confirmPassword"
              type="password"
            />
            <div className={classes.paragraphContainer}>
              <p>
                Use at least 8 characters. Don’t use a password from another site or something too obvious (like your
                pet’s name).
              </p>
            </div>
          </div>
        }
        backlink="/settings/account"
      />
    </KlaimForm>
  );
};

export default ChangePassword;
