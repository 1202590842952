import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {ErxNewDrug, ErxSearch, ErxDrugError, InitialState} from './types';

export const getErxDrugBySearch = createAsyncThunk<ErxNewDrug[], ErxSearch, {rejectValue: ErxDrugError}>(
  'erxDrug/getErxDrugBySearch',
  async (query: ErxSearch, {rejectWithValue}) => {
    try {
      const response = await axios.get(`/drug`, {params: query});
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: InitialState = {
  data: [],
  isLoading: false,
  error: undefined,
};

const erxDrugSlice = createSlice({
  name: 'erxDrug',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getErxDrugBySearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getErxDrugBySearch.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getErxDrugBySearch.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      });
  },
});

export const erxDrugSearchSelect = (state: RootState) => state.pharmacy.erxDrug;
export default erxDrugSlice.reducer;
