import React, {useState} from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';
import {useTranslation} from 'react-i18next';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimFileUploadV2,
  KlaimForm,
} from 'modules/Common/components-v2/KlaimForm';
import {uploadBulkClaims} from 'modules/Claims/api';
import * as Yup from 'yup';
import * as Toast from '../../../../../../Common/utils/toast';
import {useAppSelector} from '../../../../../../App/store';
import {insuranceSelect} from '../../../../../../Insurance/insuranceSlice';
import {Payer} from '../../../../../../../interfaces/payers.interfaces';
import {IOptions} from '../../../../../../../interfaces/general.interfaces';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    fileUploadBox: {},
  };
});

interface ComponentsProps {
  open: boolean;
  providerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const BulkUploadModal: React.FC<ComponentsProps> = ({open, setOpen, providerId}) => {
  const {t} = useTranslation();
  const classes = useStylesFromThemeFunction();
  const [claims, setClaims] = useState();
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);
  const [selectedInsurance, setSelectedInsurance] = React.useState<Payer>();
  const [uploading, setUploading] = useState(false);
  const {payers} = useAppSelector(insuranceSelect);
  React.useEffect(() => {
    if (payers && payers.data.length > 0) {
      setInsuranceOptions(
        payers.data.map((payer: Payer) => {
          return {
            label: payer.name,
            value: payer.payerCode,
          };
        }),
      );
    }
  }, [payers]);

  const getPayerName = (tpa: string) => {
    if (payers) {
      const payerResult = payers.data.find((payer: Payer) => payer.payerCode === tpa);
      if (payerResult) {
        return payerResult.name;
      }
    }
    return '';
  };
  const isTpaRequired = (insurance: any) => insurance && insurance.tpas && insurance.tpas.length > 0;

  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title="Bulk Upload" />
      <KlaimModalBody style={{padding: 20}}>
        <KlaimForm
          enableReinitialize
          validationSchema={Yup.object({
            insurance: Yup.string().required('Insurance is required.'),
            tpa: Yup.string().when('insurance', {
              is: (insurance) => isTpaRequired(insurance),
              then: Yup.string().required('TPA is required'),
            }),
          })}
          initialValues={{insurance: ''}}
          onSubmit={() => {}}
          id="bulk-upload-form"
          style={{gap: 8, display: 'flex', flexDirection: 'column'}}
        >
          <KlaimFieldWrapper
            name={'insurance'}
            onChange={(e) => setSelectedInsurance(payers.data.find((payer) => payer.payerCode === e))}
          >
            <KlaimDropdownSearch variant="secondary" label="Insurance" options={insuranceOptions} />
          </KlaimFieldWrapper>
          {selectedInsurance && selectedInsurance.tpas && selectedInsurance.hasTPA && (
            <KlaimFieldWrapper name={'tpa'}>
              <KlaimDropdownSearch
                variant="secondary"
                label="TPA"
                options={selectedInsurance.tpas.map((tpa: any) => {
                  return {
                    label: getPayerName(tpa),
                    value: tpa,
                  };
                })}
              />
            </KlaimFieldWrapper>
          )}
          <KlaimFileUploadV2
            label={t('Attach Files')}
            className={`${classes.fileUploadBox}`}
            acceptedFileTypes=".xlsx"
            multiple
            onChange={(value: any) => {
              if (value && value.length > 0) {
                setClaims(value[0].file);
              }
            }}
            dropAreaText="Drag and drop file"
            percentage={100}
          />
        </KlaimForm>
        <br />
        <a href={'/assets/claims_excel_template.xlsx'} download="claims_excel_template.xlsx">
          Download excel template
        </a>
      </KlaimModalBody>
      <KlaimModalFooter
        submitButtonTitle="Upload"
        onSubmit={async () => {
          setUploading(true);
          uploadBulkClaims(claims, providerId)
            .then(() => Toast.success('Bulk claims uploaded', 'Success'))
            .catch(() => Toast.error('An error occurred', 'Upload error'))
            .finally(() => {
              setOpen(false);
              setUploading(false);
            });
        }}
        cancelButtonTitle={'Cancel'}
        submitLoading={uploading}
      />
    </KlaimModal>
  );
};

export default BulkUploadModal;
