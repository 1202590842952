import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    flex: 1,
  },
  addPadding: {
    padding: 10,
  },
});
