import React from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {createUseStyles} from 'react-jss';
import {
  KlaimForm,
  KlaimFieldWrapper,
  KlaimInput,
  KlaimDropdownSearch,
  KlaimDatePicker,
} from 'modules/Common/components-v2/KlaimForm';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import {ISupportingInfo, ISupportingInfoCategory} from 'interfaces/pre-auth.interface';
import {
  supportingInfoCategory,
  supportingInfoReason,
  supportingMissingToothReason,
  supportingReasonSystem,
  toothSurfacedCode1,
  fdiOralRegionCode2,
  visitReasonCode4,
} from 'modules/Common/constants/DropdownOptions';
import {removeEmptyProperty} from 'helpers/general-helper';
import {unitOfMeasures} from 'modules/Common/constants/DropdownOptions/unitOfMeasures';
import {get} from 'lodash';
import Attachment from './Attachment';
import DiagnosisCodeDropdown from '../../../../Diagnosis/features/DiagnosisCodeDropdown';
import LoincCodeDropdown from '../../../../Diagnosis/features/LoincCodeDropdown';

interface ComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (value: any) => void;
  diagnosis?: any;
}

const useStylesFromThemeFunction = createUseStyles({
  container: {
    maxWidth: 500,
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },
});

const formDisplay = {
  code: ['onset', 'missingtooth', 'lab-test', 'reason-for-visit', 'chief-complaint'],
  timingDate: ['onset', 'missingtooth', 'last-menstrual-period'],
  timingPeriod: ['hospitalized', 'employmentImpacted'],
  valueString: ['info'],
  valueAttachment: ['attachment'],
  reason: ['missingtooth'],
  unitOfMeasure: ['lab-test'],
  valueQuantity: [
    'days-supply',
    'vital-sign-weight',
    'vital-sign-systolic',
    'vital-sign-diastolic',
    'icu-hours',
    'ventilation-hours',
    'vital-sign-height',
    'birth-weight',
    'lab-test',
    'temperature',
    'pulse',
    'oxygen-saturation',
    'respiratory-rate',
  ],
  valueBoolean: [''],
  text: ['chief-complaint'],
};

const getCodeValue = (code: string) => {
  switch (code) {
    case 'missingtooth':
      return 2;
    case 'lab-test':
      return 3;
    case 'reason-for-visit':
      return 4;
    case 'onset':
    case 'chief-complaint':
      return 5;
    default:
      return 0;
  }
};

const SupportInfoModal: React.FC<ComponentProps> = ({isOpen, setIsOpen, onSubmit, diagnosis: diagnosiss}) => {
  const classes = useStylesFromThemeFunction();
  const [category, setCategory] = React.useState<ISupportingInfoCategory>('info');
  const [reasonSystem, setReasonSystem] = React.useState<number>(1);
  const [diagnosis, setDiagnosis] = React.useState(get(diagnosiss, '[0].diagCode', null));
  const initialValues = {
    category: 'info',
  };
  const validationSchema = Yup.object({
    category: Yup.string().required(),
  });
  const handleSubmit = (value: any) => {
    value = {...value, code: diagnosis};

    let temp: ISupportingInfo = removeEmptyProperty(value) as ISupportingInfo;

    if (formDisplay.valueQuantity.includes(temp.category)) {
      temp.valueQuantity = parseFloat(temp.valueQuantity.toString());
    }
    if (formDisplay.timingDate.includes(temp.category)) {
      temp.timingDate = dayjs(temp.timingDate).format('YYYY-MM-DD');
    }

    // const codeSystem = getCodeValue(value.category);
    // const unitsOfMeasure = getMeasurementCode(value.category);
    // if (codeSystem !== 0) {
    //   temp.codeSystem = codeSystem;
    // }
    // if (unitsOfMeasure) {
    //   temp.valueUcumUnit = unitsOfMeasure;
    // }
    setIsOpen(false);

    if (category === 'attachment')
      temp = {
        ...temp,
        valueAttachment: {
          content: temp.valueAttachment,
          title: 'attachment_file',
          date: dayjs(new Date()).format('YYYY-MM-DD'),
        },
      };
    if (category === 'chief-complaint')
      temp = diagnosis
        ? {
            ...temp,
            ...{
              category: 'chief-complaint',
              codeSystem: 5,
              code: diagnosis,
            },
          }
        : {
            ...temp,
            ...{
              category: 'chief-complaint',
              codeSystem: 5,
            },
          };
    if (onSubmit) onSubmit(temp);

    setCategory('info');
    setReasonSystem(1);
  };
  const handleSupportingInfoChange = (data: ISupportingInfoCategory) => {
    setCategory(data);
  };

  const getCodeOptions = (system: number) => {
    switch (system) {
      case 1:
        return toothSurfacedCode1;
      case 2:
        return fdiOralRegionCode2;
      case 4:
        return visitReasonCode4;
      default:
        return [];
    }
  };

  const getMeasurementCode = (code: string) => {
    switch (code) {
      case 'lab-test':
        return 'mg';
      case 'vital-sign-weight':
        return 'kg';
      case 'vital-sign-systolic':
      case 'vital-sign-diastolic':
        return 'mm[Hg]';
      case 'icu-hours':
      case 'ventilation-hours':
        return 'h';
      case 'vital-sign-height':
        return 'cm';
      case 'birth-weight':
        return 'kg';
      default:
        return '';
    }
  };

  const renderCodeComponent = () => {
    if (getCodeOptions(getCodeValue(category)).length > 0) {
      return <KlaimDropdownSearch label="Code" options={getCodeOptions(getCodeValue(category))} />;
    }
    if (getCodeValue(category) === 3) {
      return <LoincCodeDropdown authorityCode="NPHIES" label="Loinc Code" isSupportInfo />;
    }
    return <KlaimInput label="Code" />;
  };

  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen} onClose={() => setCategory('info')}>
      <KlaimForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <KlaimModalHeader title="Add Supporting Information" />
        <KlaimModalBody className={classes.container}>
          <p style={{gridColumn: 'span 2 / span 2'}}>Please fill in the fields below to add supporting information.</p>
          <KlaimFieldWrapper
            canReset
            name="category"
            onChange={handleSupportingInfoChange}
            style={{gridColumn: 'span 2 / span 2'}}
          >
            <KlaimDropdownSearch label="Additional Information Type" options={supportingInfoCategory} />
          </KlaimFieldWrapper>
          {formDisplay.code.includes(category) && (
            <>
              {/* <KlaimFieldWrapper name="codeSystem" onChange={(value) => setCode(value)}>
                <KlaimDropdownSearch label="Code System" options={additionalInfoCodeSystem} />
              </KlaimFieldWrapper> */}
              {/* Where to pull the Codes of LOINC for Laboratory */}
              {/* Where to pull the IDC 10 Codes for onset and chief complaint */}
              {getCodeValue(category) === 5 ? (
                <DiagnosisCodeDropdown
                  label="ICD10 Dagnosis"
                  authorityCode="NPHIES"
                  isSupportInfo
                  value={diagnosis}
                  onChange={(v) => setDiagnosis(v)}
                  optional
                />
              ) : (
                renderCodeComponent()
              )}
            </>
          )}
          {formDisplay.timingPeriod.includes(category) && (
            <>
              <KlaimFieldWrapper name="timingPeriod.start" canReset>
                <KlaimDatePicker label="Timing Period Start" />
              </KlaimFieldWrapper>
              <KlaimFieldWrapper name="timingPeriod.end" canReset>
                <KlaimDatePicker label="Timing Period End" />
              </KlaimFieldWrapper>
            </>
          )}
          {formDisplay.timingDate.includes(category) && (
            <KlaimFieldWrapper name="timingDate" canReset>
              <KlaimDatePicker label="Timing Date" name="timingDate" />
            </KlaimFieldWrapper>
          )}
          {formDisplay.reason.includes(category) && (
            <>
              <KlaimFieldWrapper name="reasonSystem" onChange={(value) => setReasonSystem(value)} canReset>
                <KlaimDropdownSearch
                  value={reasonSystem}
                  label="Reason System"
                  options={supportingReasonSystem}
                  isOptional
                />
              </KlaimFieldWrapper>
              <KlaimFieldWrapper name="reason" canReset>
                <KlaimDropdownSearch
                  label="Reason"
                  options={reasonSystem === 1 ? supportingInfoReason : supportingMissingToothReason}
                  isOptional
                />
              </KlaimFieldWrapper>
            </>
          )}
          {formDisplay.valueBoolean.includes(category) && (
            <KlaimFieldWrapper name="valueBoolean" canReset>
              <KlaimDropdownSearch
                label="Boolean"
                options={[
                  {
                    label: 'True',
                    value: true,
                  },
                  {
                    label: 'False',
                    value: false,
                  },
                ]}
                isOptional
              />
            </KlaimFieldWrapper>
          )}
          {formDisplay.valueString.includes(category) && (
            <KlaimFieldWrapper name="valueString" canReset>
              <KlaimInput label="String" />
            </KlaimFieldWrapper>
          )}
          {formDisplay.valueQuantity.includes(category) &&
            (formDisplay.unitOfMeasure.includes(category) ? (
              <div
                style={{
                  gridColumn: 'span 2 / span 2',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  gap: '1rem',
                }}
              >
                <KlaimFieldWrapper name="valueQuantity" canReset>
                  <KlaimInput type="number" label="Quantity" />
                </KlaimFieldWrapper>
                <KlaimFieldWrapper name="valueUcumUnit">
                  <KlaimDropdownSearch label="Unit of Measure" options={unitOfMeasures} />
                </KlaimFieldWrapper>
              </div>
            ) : (
              <div
                style={{
                  gridColumn: 'span 2 / span 2',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  gap: '1rem',
                }}
              >
                <KlaimFieldWrapper name="valueQuantity" canReset>
                  <KlaimInput type="number" label="Quantity" />
                </KlaimFieldWrapper>
                {getMeasurementCode(category) && (
                  <span style={{alignSelf: 'end', marginLeft: 4}}>{getMeasurementCode(category)}</span>
                )}
              </div>
            ))}
          {formDisplay.valueAttachment.includes(category) && (
            <KlaimFieldWrapper name="valueAttachment" style={{gridColumn: 'span 2 / span 2'}} canReset>
              <Attachment />
            </KlaimFieldWrapper>
          )}
          {formDisplay.text.includes(category) && (
            <KlaimFieldWrapper name="text" style={{gridColumn: 'span 2 / span 2'}} canReset>
              <KlaimInput label="Text" />
            </KlaimFieldWrapper>
          )}
        </KlaimModalBody>
        <KlaimModalFooter buttonType="submit" submitButtonTitle={'Add'} />
      </KlaimForm>
    </KlaimModal>
  );
};

export default SupportInfoModal;
