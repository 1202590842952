import React from 'react';
import {IOptions} from 'interfaces/general.interfaces';
import {IActivityCode} from 'interfaces/pre-auth.interface';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {activitySelect, getActivityCode} from 'modules/Activity/activitySlice';
import {KlaimDropdownSearch} from 'modules/Common/components-v2/KlaimForm';

interface ComponentProps {
  onChange?: (value: any) => void;
  authorityCode?: string;
  initialValue?: any;
  variant?: 'secondary' | 'primary' | undefined;
}
const ActivityCodeDropdown: React.FC<ComponentProps> = ({
  onChange,
  initialValue,
  variant = 'secondary',
  authorityCode = 'NPHIES',
}) => {
  const dispatch = useAppDispatch();
  const {activities} = useAppSelector(activitySelect);
  const [activityCodeList, setActivityCodeList] = React.useState<IOptions[]>([]);
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');
  React.useEffect(() => {
    dispatch(getActivityCode({authorityCode, limit: 100, search: search.replace('-', '')}));
  }, [search]);

  React.useEffect(() => {
    setActivityCodeList(
      activities.map((act: IActivityCode) => ({
        label: `${act.activityCode} - ${act.shortDescription}`,
        value: act.activityCode,
      })),
    );
  }, [activities]);

  const handleChange = (value: any) => {
    if (onChange) onChange(value);
  };
  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const handleInputChange = (newValue: any) => {
    setSearchTyping(newValue);
  };
  return (
    <KlaimDropdownSearch
      initialValue={initialValue}
      options={activityCodeList}
      label="Search Activity Code"
      variant={variant}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

export default ActivityCodeDropdown;
