import React from 'react';
import {FormikHelpers} from 'formik';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Register/Register';
import {authSelect, registerCleanUp} from 'modules/Auth/v3/features/Auth/authSlice';
import AccountSetup from 'modules/Auth/v3/features/Register/AccountSetup';
import CreateAccount from 'modules/Auth/v3/features/Register/CreateAccount';
import {useAppDispatch} from 'modules/App/store';
import {register, errorCleanUp, emailStore, registerSelect} from 'modules/Auth/v3/features/Register/registerSlice';
import CompletedRegistration from 'modules/Auth/v3/features/Register/CompletedRegistration';

export interface Values {
  [field: string]: any;
}

const Register: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {success} = useSelector(authSelect);
  const {error} = useSelector(registerSelect);
  const [currentStep, setCurrentStep] = React.useState(0);

  React.useEffect(() => {
    return () => {
      dispatch(registerCleanUp());
      dispatch(errorCleanUp());
    };
  }, [success]);

  React.useEffect(() => {
    if (error) {
      setCurrentStep(0);
    }
  }, [error]);

  const [data, setData] = React.useState({
    email: '',
    password: '',
    name: '',
    phone: '',
    organization_name: '',
    organization_slug: '',
    organization_country: '',
  });

  const makeRequest = (
    values: Values,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    dispatch(register({values, formikHelpers}));
    dispatch(emailStore(values?.email));
  };

  const handleNextStep = (
    newData: any,
    final = false,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    setData((prev) => ({...prev, ...newData}));

    if (final) {
      makeRequest(newData, formikHelpers);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev) => ({...prev, ...newData}));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <CreateAccount next={handleNextStep} data={data} />,
    <AccountSetup next={handleNextStep} prev={handlePrevStep} data={data} />,
    <CompletedRegistration prev={handlePrevStep} data={data} />,
  ];

  return (
    <div className={classes.createAccount}>
      <div className={classes.formContainer}>{steps[currentStep]}</div>
    </div>
  );
};

export default Register;
