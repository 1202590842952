import React from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {Colors} from 'modules/Common/constants/Colors';
import MultiSelect from 'react-multi-select-component';
import selectArrow from 'assets/arrow_down_icon.svg';
import hospitalIcon from 'assets/hospital_icon.svg';
import {Options} from 'modules/Dashboard/api/types';
import KlaimMultiSelect from 'modules/Common/components/KlaimMultiSelect';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    wrapper: {
      '& .container': {
        maxWidth: '100%',
        margin: 0,
      },
      '& .label': {
        color: Colors.grayLabel,
        fontSize: 13,
        fontWeight: 'bold',
        padding: '8px 0 4px 10px',
      },
      '& .dropCont': {
        '& .valueCont': {
          borderRight: '1px solid #ccc',
          width: 'calc(100% - 27px)',
        },
        '& .titleContainer': {
          position: 'relative',
        },
        '& .titleText': {
          color: 'hsl(0, 0%, 20%)',
          fontWeight: 400,
          fontSize: 14,
          marginLeft: 0,
        },
        '& .rightIcon': {
          height: 8,
          maxHeight: 8,
          position: 'absolute',
          right: 13,
        },
      },
    },
    container: {
      background: Colors.purple,
      backgroundImage: `url(${selectArrow})`,
      backgroundPositionX: '100%',
      backgroundPositionY: '50%',
      backgroundRepeat: 'no-repeat',
      border: `1px solid ${Colors.purple}`,
      borderRadius: 10,
      color: Colors.blueGrayDark,
      fontSize: 12,
      fontWeight: 500,
      height: '100%',
      MozAppearance: 'none',
      outline: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      WebkitAppearance: 'none',
      position: 'relative',
      width: '100%',
      maxWidth: '100%',
      maxHeight: 40,
      '&.hasIcon': {
        display: 'flex',
        alignItems: 'center',
        '& > img': {
          height: 15,
          maxHeight: 15,
        },
        '& .dropdown-container': {
          '& .dropdown-heading-value': {
            width: 'calc(100% - 0px)',
          },
        },
      },
      '&.arrowHidden': {
        backgroundImage: 'none',
        '& .dropdown-container': {
          '& .dropdown-heading': {
            paddingRight: 0,
          },
        },
      },
      '&.light': {
        background: Colors.blueGrayLight,
        borderColor: Colors.grayLight,
        '& .dropdown-container': {
          '& .dropdown-heading-value': {
            color: Colors.blueGrayDark,
          },
        },
      },
    },
    klaimSelect: {
      '& .dropdown-container': {
        '& .dropdown-heading': {
          paddingRight: 45,
          overflow: 'hidden',
        },
        '& .dropdown-heading-value': {
          '& .gray': {
            color: Colors.white,
            fontSize: 12,
            fontWeight: 600,
          },
          color: Colors.white,
          fontSize: 12,
          fontWeight: 600,
          margin: '1px 0 0 10px',
        },
        '& svg': {
          display: 'none',
        },
        background: 'transparent',
        border: '0 !important',
      },
      '&:focus': {
        outline: 'none',
      },
      '--rmsc-bg': Colors.white,
      '--rmsc-border': Colors.grayLight,
      '--rmsc-gray': Colors.gray,
      '--rmsc-h': '31px',
      '--rmsc-hover': Colors.RMSC.hover,
      '--rmsc-main': 'transparent',
      '--rmsc-p': '10px',
      '--rmsc-radius': '4px',
      '--rmsc-selected': Colors.RMSC.selected,
    },
    inputError: {
      color: `${Colors.red} !important`,
      fontSize: 12,
      marginBottom: `0 !important`,
      marginTop: -8,
      marginLeft: 15,
    },
  };
});

interface ComponentProps {
  options: Options[];
  value: Options[];
  onChange: (e: any) => void;
  overrideStrings?: any;
  leftIcon?: any;
  hasSelectAll?: boolean;
  light?: boolean;
  hideArrow?: boolean | false;
  disableSearch?: boolean;
  disableSelectAll?: boolean;
  isButtonRight?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  label?: string;
  error?: any;
}

const KlaimFormMultiSelect: React.FC<ComponentProps> = ({
  options,
  value,
  onChange,
  overrideStrings,
  disableSearch,
  disableSelectAll,
  leftIcon,
  isButtonRight,
  position,
  hasSelectAll,
  light,
  hideArrow,
  label,
  error,
}) => {
  const classes = useStylesFromThemeFunction();
  const [multiSelectValue, setMultiSelectValue] = React.useState<string>();

  React.useEffect(() => {
    const joinedValues = value
      .map((val: Options) => val.value)
      .join(',')
      .split(',')
      .splice(1, value.length - 1);

    setMultiSelectValue(
      `${value[0]?.label || 'Select'}${joinedValues.length > 0 ? `, +${joinedValues.length} more` : ''}`,
    );
  }, [value]);

  return (
    <div className={classes.wrapper}>
      {label && <div className="label">{label}</div>}
      <KlaimDropdownContainer
        position={position}
        title={multiSelectValue}
        leftIcon={leftIcon}
        rightIcon={selectArrow}
        isButtonRight={isButtonRight}
        showDescription
      >
        <KlaimMultiSelect
          onChange={onChange}
          options={options}
          selectAllTitle={overrideStrings && overrideStrings.selectAll ? overrideStrings.selectAll : 'Select All'}
          value={value}
          disableSearch={disableSearch}
          disableSelectAll={disableSelectAll}
        />
      </KlaimDropdownContainer>
      {error ? <p className={classes.inputError}>{error}</p> : null}
    </div>
  );
};
export default KlaimFormMultiSelect;
