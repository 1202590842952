import {createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store/index';

interface Toast {
  id: string;
  variant: string;
  title: string;
  message: string;
}
interface InitialState {
  toasts: Toast[];
}
const initialState: InitialState = {
  toasts: [],
};

const toastSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setNewToast: (state, action) => {
      state.toasts = [...state.toasts, action.payload];
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter((arrow) => arrow.id !== action.payload);
    },
  },
  extraReducers: (builder) => {},
});

export const {setNewToast, removeToast} = toastSlice.actions;
export const toast = (state: RootState) => state.toast;

export default toastSlice.reducer;
