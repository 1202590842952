import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import {MyKnownError} from 'interfaces/error-interface';
import {Midtable, MidtableData, MidTableQuery} from 'interfaces/midtable.interface';
import axiosInstance from 'services/axiosMidtable';

interface InitialState {
  midtables: {
    data: Midtable[];
    count: number;
  };
  midtableDrafts: {
    data: Midtable[];
    count: number;
  };
  totals: any;
  midtable?: Midtable;
  isLoading: boolean;
  error: any;
  apiCallSuccess: boolean;
  claim?: Midtable;
  maritalStatuses: any[];
  genders: any[];
  nationalities: any[];
}

const initialState: InitialState = {
  midtables: {
    data: [],
    count: 0,
  },
  midtableDrafts: {
    data: [],
    count: 0,
  },
  totals: {},
  midtable: undefined,
  claim: undefined,
  isLoading: false,
  error: undefined,
  apiCallSuccess: false,
  maritalStatuses: [],
  genders: [],
  nationalities: [],
};

// Claims APIS
export const getClaims = createAsyncThunk<MidtableData, MidTableQuery, {rejectValue: MyKnownError}>(
  'claims',
  async (query: MidTableQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/claims', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getClaimDrafts = createAsyncThunk<MidtableData, MidTableQuery, {rejectValue: MyKnownError}>(
  'claimDrafts',
  async (query: MidTableQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/claims', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getClaim = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'getClaim',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/claims/${query.id}`, {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteClaim = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteClaim',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/claims/${query.id}`, {});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const patchClaim = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'patchClaim',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/claims/${query.id}`, query);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const getClaimsTotal = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'getClaimsTotal',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/claims/total/amount`, {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const postClaimsBulk = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'postClaimsBulk',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/claims/bulk-submission`, query);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const postClaimsAll = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'postClaimsAll',
  async (query: any, {rejectWithValue}) => {
    const url = `${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}/midtable-bulk-proxy`;
    try {
      const response = await axiosInstance.get(`${url}/claims/bulk-submitter`, {
        params: query,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

// Diagnosis APIS
export const postDiagnosis = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'postDiagnosis',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/diagnoses/${data.id}`, data.payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const patchDiagnosis = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'patchDiagnosis',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/diagnoses/${data.claimId}/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const deleteDiagnosis = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteDiagnosis',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/diagnoses/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
// Radiology APIS
export const postRadiology = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'setRadiologies',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/radiologies/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const patchRadiology = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'patchRadiology',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/radiologies/${data.claimId}/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteRadiology = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteRadiology',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/radiologies/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
// Vital Signs APIs
export const postVitalSigns = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'postVitalSigns',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/vital-signs/${query.id}`, query.payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const patchVitalSigns = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'patchVitalSigns',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/vital-signs/${query.claimId}/${query.id}`, query.payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const deleteVitalSigns = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteVitalSigns',
  async (query: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/vital-signs/${query.id}`, query.payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
// Service API
export const deleteService = createAsyncThunk<Midtable, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'deleteService',
  async (data: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.delete(`/services/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Constants APIS
export const getMaritalStatuses = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'getMaritalStatuses',
  async (query: MidTableQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/marital-statuses', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getGenders = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'getGenders',
  async (query: MidTableQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/genders', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const getNationalities = createAsyncThunk<MidtableData, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'getNationalities',
  async (query: MidTableQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/nationalities', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const midtableSlice = createSlice({
  name: 'midtable',
  initialState,
  reducers: {
    errorCleanUp: (state) => {
      state.isLoading = false;
      state.error = undefined;
      state.apiCallSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaims.fulfilled, (state, action: any) => {
        state.midtables = {
          data: action.payload.data,
          count: action.payload.count,
        };
        state.isLoading = false;
      })
      .addCase(getClaimDrafts.fulfilled, (state, action: any) => {
        state.midtableDrafts = {
          data: action.payload.data,
          count: action.payload.count,
        };
        state.isLoading = false;
      })
      .addCase(getClaims.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClaims.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getClaim.fulfilled, (state, action) => {
        state.isLoading = false;
        state.midtable = action.payload as any;
      })
      .addCase(getClaim.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClaim.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteClaim.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apiCallSuccess = true;
      })
      .addCase(deleteClaim.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClaim.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(patchClaim.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(patchClaim.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchClaim.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getClaimsTotal.fulfilled, (state, action) => {
        state.totals = action.payload;
        state.isLoading = false;
      })
      .addCase(getClaimsTotal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClaimsTotal.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(postClaimsBulk.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postClaimsBulk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postClaimsBulk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(postClaimsAll.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postClaimsAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postClaimsAll.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(postDiagnosis.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postDiagnosis.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postDiagnosis.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(patchDiagnosis.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(patchDiagnosis.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchDiagnosis.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteDiagnosis.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteDiagnosis.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDiagnosis.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(postRadiology.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postRadiology.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postRadiology.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(patchRadiology.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(patchRadiology.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchRadiology.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteRadiology.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteRadiology.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRadiology.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(postVitalSigns.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(postVitalSigns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postVitalSigns.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(patchVitalSigns.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(patchVitalSigns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchVitalSigns.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteVitalSigns.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteVitalSigns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteVitalSigns.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteService.fulfilled, (state, action) => {
        state.apiCallSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getMaritalStatuses.fulfilled, (state, action: any) => {
        state.maritalStatuses = action.payload.items;
        state.isLoading = false;
      })
      .addCase(getMaritalStatuses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMaritalStatuses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getGenders.fulfilled, (state, action: any) => {
        state.genders = action.payload.items;
        state.isLoading = false;
      })
      .addCase(getGenders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGenders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getNationalities.fulfilled, (state, action: any) => {
        state.nationalities = action.payload.items;
        state.isLoading = false;
      })
      .addCase(getNationalities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNationalities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {errorCleanUp} = midtableSlice.actions;

export const midtableSelect = (state: RootState) => state.midtable;
export default midtableSlice.reducer;
