import React, {useEffect} from 'react';
import UserInfo from 'modules/Settings/components/UserInfo';
import {useAppSelector, useAppDispatch} from 'modules/App/store';
import {useStylesFromThemeFunction} from 'modules/Settings/features/AccountSettings/Info/Info';
import {accountSelect, returnFlagStatus} from 'modules/Settings/features/AccountSettings/accountSettingsSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import KlaimLink from 'modules/Common/components/KlaimLink';
import {KlaimToast} from 'modules/Common/components/KlaimToast';
import {getNewName} from 'modules/Auth/v3/features/Auth/authSlice';

const Info: React.FC = () => {
  const {
    user: {name, email, phone},
    isLoading,
    isChangePassword,
    flag,
  } = useAppSelector(accountSelect);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (flag) {
      dispatch(getNewName(name));
    }
    return () => {
      returnFlagStatus();
    };
  }, [flag]);

  const classes = useStylesFromThemeFunction();
  const contactRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const securityRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const parentRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  return (
    <div className={classes.accountSettingsWrapper}>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <div ref={parentRef}>
          <div className={classes.formInfoSection}>
            <div className={classes.formSectionTitleWrapper}>
              <h2>Basic info</h2>
            </div>
            <div className={classes.formSectionElements}>
              <div className={classes.formSectionElementsWrapper}>
                <UserInfo title="Full Name" content={name} editLink="/settings/account/name" />
              </div>
            </div>
            <div className={classes.formEmptyColumn} />
          </div>
          <div className={`${classes.formInfoSection} ${classes.marginTop5}`}>
            <div className={classes.formSectionTitleWrapper} ref={contactRef}>
              <h2>Contact info</h2>
            </div>
            <div className={classes.formSectionElements}>
              <div className={classes.formSectionElementsWrapper}>
                <UserInfo title="Email" content={email} />
              </div>
              <div className={classes.formSectionElementsWrapper}>
                <UserInfo title="Phone" content={phone} editLink="/settings/account/phone" />
              </div>
            </div>
            <div className={classes.formEmptyColumn} />
          </div>
          {/*<div className={`${classes.formInfoSection} ${classes.marginTop5}`}>*/}
          {/*  <div className={classes.formSectionTitleWrapper} ref={contactRef}>*/}
          {/*    <h2>Configuration</h2>*/}
          {/*  </div>*/}
          {/*  <div className={classes.formSectionElements}>*/}
          {/*    <div className={classes.formSectionElementsWrapper}>*/}
          {/*      <UserInfo title="Episode System URL" content={episodeUrl} editLink="/settings/account/episodeUrl" />*/}
          {/*    </div>*/}
          {/*    <div className={classes.formSectionElementsWrapper}>*/}
          {/*      <UserInfo title="Invoice System URL" content={invoiceUrl} editLink="/settings/account/invoiceUrl" />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className={classes.formEmptyColumn} />*/}
          {/*</div>*/}
          <div className={`${classes.formInfoSection} ${classes.marginTop5}`}>
            <div className={classes.formSectionTitleWrapper} ref={securityRef}>
              <h2>Security & Login</h2>
            </div>
            <div className={classes.formSectionElements}>
              <div className={classes.formSectionElementsWrapper}>
                <div className={classes.passwordReset}>
                  <div className={classes.passwordResetHeader}>
                    <span>Password</span>
                  </div>
                  <span className={classes.passwordResetContent}>
                    Changing your password will sign you out everywhere except for devices you use to verify it’s you
                    when signing in.
                  </span>
                  <KlaimLink path="/settings/account/change-password" text="Change Password" />
                </div>
              </div>
            </div>
            <div className={classes.formEmptyColumn} />
          </div>
        </div>
      )}
      {isChangePassword ? <KlaimToast /> : null}
    </div>
  );
};

export default Info;
