import io, {Socket} from 'socket.io-client';
import {get as getStorage} from 'local-storage';
import {DefaultEventsMap} from 'socket.io-client/build/typed-events';

/* SingleTon Socket Instance */
const socket = (() => {
  let instance: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;
  return {
    getInstance: (): Socket<DefaultEventsMap, DefaultEventsMap> | null => {
      const token = getStorage('accessToken');
      if (token) {
        if (instance === null) {
          instance = io(`${process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN}`, {
            transports: ['websocket'],
          });
        }
        return instance;
      }
      return null;
    },
  };
})();

export default socket;
