import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getIn} from 'formik';
import TypeSelect from '../../../Common/components/TypeSelect';
import {ResubmissionType} from '../../api/types';
import TextArea from '../../../Common/components/TextArea';
import FileUpload from '../../../Common/components/FileUpload';

interface ComponentProps {
  handleChange: any;
  values: any;
  setFieldValue: any;
  classes: any;
  errors: any;
  touched: any;
}

const Resubmit: React.FC<ComponentProps> = ({handleChange, values, setFieldValue, classes, errors, touched}) => {
  const {t} = useTranslation();
  const [resubmission, setResubmission] = useState('Correction');

  const setResubmissionType = (type: string) => {
    setResubmission(type);
    values.resubmission.type = type;
  };

  return (
    <div className={classes.formSectionElementsWrapper}>
      <>
        <h2>{t('Resubmission')}</h2>
        <TypeSelect
          className={classes.formElementWithBottomSpacing}
          values={[
            {title: t('Correction'), value: ResubmissionType.CORRECTION},
            {title: t('Communication'), value: ResubmissionType.INTERNAL_COMPLAINT},
          ]}
          selectedValue={resubmission}
          setSelectedValue={(e) => setResubmissionType(e)}
        />
        <TextArea
          label={t('Comment')}
          className={classes.formElementWithBottomSpacing}
          placeholder={t('Enter Comment')}
          value={values.resubmission.comment}
          onChange={handleChange}
          name={'resubmission.comment'}
          error={
            getIn(errors, 'resubmission.comment') && getIn(touched, 'resubmission.comment')
              ? getIn(errors, 'resubmission.comment')
              : null
          }
        />
        <FileUpload
          label={t('Attach Files')}
          className={`${classes.formElementWithBottomSpacing} ${classes.fileUploadBox}`}
          acceptedFileTypes="application/pdf"
          setFieldValue={setFieldValue}
          fieldValue="resubmission.attachment"
        />
      </>
    </div>
  );
};

export default Resubmit;
