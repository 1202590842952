import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  sidebarContainer: {
    width: 220,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 10,
      marginRight: 10,
      fontSize: 12,
      fontWeight: 600,
      color: Colors.grayLabel,
    },
  },
  privacy: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    fontSize: 12,
    fontWeight: 600,
    color: Colors.grayLabel,
    textDecoration: 'none',
  },
});
