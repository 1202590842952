import React from 'react';
import * as Yup from 'yup';
import helpIcon from 'assets/help_icon.svg';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Register/AccountSetup/AccountSetup';
import KlaimInput from 'modules/Common/components/KlaimInput';
import Button from 'modules/Common/components/Button';
import {useAppSelector} from 'modules/App/store';
import {registerSelect} from 'modules/Auth/v3/features/Register/registerSlice';
import KlaimTooltip from 'modules/Common/components/KlaimTooltip';
import {Form, Formik, FormikHelpers} from 'formik';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import KlaimSearchSelect from 'modules/Common/components/KlaimSearchSelect';

export interface Values {
  [field: string]: any;
}

interface ComponentProps {
  next?: any;
  prev?: any;
  data?: any;
}

const countryOptions = [
  {
    label: 'UAE',
    value: 'UAE',
  },
  {
    label: 'Saudi Arabia',
    value: 'KSA',
  },
];

const AccountSetup: React.FC<ComponentProps> = ({next, prev, data}) => {
  const classes = useStylesFromThemeFunction();
  const {isLoading} = useAppSelector(registerSelect);
  const requirements = [
    {message: 'Character Limit: 5 to 16.'},
    {message: 'No special characters, so only alphanumerical and - or _'},
    {message: 'All lower case '},
  ];

  const validationSchema = Yup.object({
    name: Yup.string().max(25, 'Maximum 25 characters or less').required('Name is required'),
    phone: Yup.string().max(13, 'Maximum 13 digits or less').required('Phone is required'),
    organization_name: Yup.string().max(25, 'Maximum 25 characters or less').required('Organization name is required'),
    // organization_slug: Yup.string()
    //   .lowercase()
    //   .min(5, 'Organization alias must be at least 5 characters')
    //   .max(16, 'Organization alias must be at most 16 characters')
    //   .matches(/^[a-zA-Z0-9-_]+$/, 'No special characters, so only alphanumerical and - or _')
    //   .required('Organization alias is required'),
    organization_country: Yup.string().required('Country is required'),
  });

  const handleSubmit = (
    values: any,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    const newObjectValues = {
      email: values.email,
      name: values.name,
      phone: values.phone,
      password: values.password,
      organization_country: values.organization_country,
      organization_name: values.organization_name,
      organization_slug: values.organization_name,
    };
    next(newObjectValues, true, formikHelpers);
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={data} validationSchema={validationSchema}>
      {({values}) => (
        <Form>
          <div className={classes.headerContainer}>
            <h1>Account Setup</h1>
            <p>Finish setting up your account by filling the following details.</p>
          </div>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div>
                <KlaimInput name="name" label="Full Name" placeholder="Name Surname" />
                <KlaimInput name="phone" label="Phone" placeholder="+971 00 000 0000" />
                <div className={classes.otherInputContainer}>
                  <KlaimSearchSelect name="organization_country" label="Country" defaultOptions={countryOptions} />
                </div>
                <KlaimInput name="organization_name" label="Organization name" placeholder="Name of organization" />
                <div className={classes.slugContainer}>
                  {/* <KlaimInput name="organization_slug" label="Organization alias" placeholder="Organization alias" />
                  <div className={classes.hintContainer}>
                    <KlaimTooltip
                      icon={helpIcon}
                      requirements={requirements}
                      description="Unique organization identifier"
                    />
                  </div> */}
                </div>
              </div>
              <div className={classes.footerContainer}>
                <Button
                  title="Prev"
                  type="button"
                  onClick={() => {
                    prev(values);
                  }}
                />
                <Button title="Finish" type="submit" />
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AccountSetup;
