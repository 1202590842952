import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    tableContainer: {
      borderBottom: `1px solid ${Colors.grayLight}`,
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: Colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      padding: 16,
    },
    pageLabel: {
      color: Colors.blueGrayDark,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.42px',
      opacity: '0.48',
    },
    pageContainer: {display: 'flex', margin: 0, padding: 0, listStyleType: 'none'},
    pageNumber: {
      marginLeft: 4,
      marginRight: 4,
      borderWidth: 1,
      borderRadius: 99999,
      height: 32,
      width: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: Colors.grayLight,
      },
    },
    numberContainer: {
      display: 'flex',
      backgroundColor: Colors.blueGrayLight,
      marginLeft: 8,
      marginRight: 8,
      borderRadius: 99999,
      paddingLeft: 4,
      paddingRight: 4,
    },
    pageDot: {
      marginLeft: 4,
      marginRight: 4,
      borderWidth: 1,
      borderRadius: 99999,
      height: 32,
      width: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activePageNumber: {
      borderStyle: 'solid',
      backgroundColor: Colors.purple,
      color: Colors.white,
      '&:hover': {
        backgroundColor: Colors.purple,
      },
    },
    iconContainer: {
      height: 32,
      width: 32,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      padding: 8,
      backgroundColor: Colors.blueGrayLight,
    },
    imgIcon: {},
    liReset: {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    dropdownContainer: {
      position: 'relative',
      backgroundColor: Colors.blueGrayLight,
      borderRadius: 99999,
      marginRight: 8,
    },
    dropdownContentContainer: {
      position: 'absolute',
      zIndex: 20,
      width: '100%',
    },
    dropDownButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 4,
      paddingRight: 4,
    },
    dropDownButton: {
      display: 'flex',
      overflow: 'hidden',
      borderWidth: 2,
      borderRadius: '50%',
      height: 32,
      width: 32,
      paddingLeft: 4,
      paddingRight: 4,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,
      background: 'none',
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    dropdownLabel: {
      paddingLeft: 4,
      paddingRight: 8,
    },
    numberDropdownContainer: {
      marginTop: 4,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: Colors.blueGrayLight,
      paddingLeft: 4,
      paddingRight: 4,
      paddingBottom: 4,
      boxShadow: `0px 0.5px 2px ${Colors.RMSC.shadow}`,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    numberDropdownButton: {
      border: 'none',
      backround: 'none',
      backgroundColor: Colors.blueGrayLight,
      padding: 4,
      marginTop: 4,
      fontSize: 14,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: Colors.grayLight,
        cursor: 'pointer',
      },
    },
    currentPage: {
      backgroundColor: Colors.purple,
      color: Colors.white,
      '&:hover': {
        backgroundColor: Colors.purple,
        color: Colors.white,
      },
    },
  };
});
