import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import axios from 'services/axios';
import {decodeJWT, TokenData} from 'services/jwt';
import * as Toast from '../../../../Common/utils/toast';

export interface AuthState {
  isLoading: boolean;
  error?: MyKnownError;
}

interface ErrorDetails {
  property: string;
  children: any;
  constraints: any;
}

interface MyKnownError {
  code: number;
  details?: ErrorDetails[];
  message: string;
}

interface AuthData {
  user: TokenData;
  accessToken: string;
}

interface UserAttributes {
  option?: string;
}

export const refresh = createAsyncThunk<
  AuthData,
  any,
  {
    rejectValue: MyKnownError;
  }
>('auth/login', async (data: any, {rejectWithValue}) => {
  try {
    const response = await axios.get('/auth/refresh-token', {
      headers: {
        Authorization: `Bearer ${data}`,
      },
    });
    return {
      user: decodeJWT(response.data.accessToken),
      accessToken: response.data.accessToken,
    };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const login = createAsyncThunk<
  AuthData,
  UserAttributes,
  {
    rejectValue: MyKnownError;
  }
>('auth/login', async (data: any, {rejectWithValue}) => {
  try {
    const response = await axios.post('/auth/login', data);
    return {
      user: decodeJWT(response.data.accessToken),
      accessToken: response.data.accessToken,
    };
  } catch (error: any) {
    Toast.error('Invalid email or password');
    return rejectWithValue(error.response.data);
  }
});

const initialState: AuthState = {
  isLoading: false,
  error: undefined,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    errorCleanUp: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const {errorCleanUp} = loginSlice.actions;

export const loginSelect = (state: RootState) => state.auth.login;
export default loginSlice.reducer;
