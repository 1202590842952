import {Form} from 'custom-test-antd';
import * as React from 'react';
import {Field, getIn, FieldConfig, FieldProps} from 'formik';
import {FormItemProps as _FormItemProps} from 'antd/lib/form/FormItem';

export type FormItemProps = {
  showValidateSuccess?: boolean;
  showInitialErrorAfterTouched?: boolean;
  antFieldProps?: _FormItemProps;
} & {name: string} & _FormItemProps &
  Pick<FieldConfig, 'validate'>;

export const makeField = <T,>(Component: any) => (props: FormItemProps & T) => {
  const {
    name,
    showValidateSuccess,
    showInitialErrorAfterTouched = false,
    validate,
    antFieldProps,
    ...restProps
  } = props;

  return (
    <Field name={name} validate={validate}>
      {(fieldProps: FieldProps) => {
        const {
          form: {errors = {}, touched = {}, initialErrors = {}},
        } = fieldProps;
        const error = getIn(errors, name, undefined);
        const initialError = getIn(initialErrors, name, undefined);
        let isTouched = getIn(touched, name, false);
        if (Array.isArray(isTouched)) {
          isTouched = isTouched.reduce((acc, value) => acc || value, false);
        }
        const hasError = error !== undefined && isTouched;
        const hasInitialError = initialError !== undefined;
        const isValid = !error && isTouched;
        const showHelp = hasError || (hasInitialError && (!isTouched || showInitialErrorAfterTouched));
        const validStatus = isValid && showValidateSuccess ? 'success' : undefined;
        return (
          <Form.Item
            validateStatus={hasError || (hasInitialError && !isTouched) ? 'error' : validStatus}
            help={
              showHelp && (
                <>
                  {hasError && <li>{error}</li>}
                  {hasInitialError && (!isTouched || showInitialErrorAfterTouched) && <li>{initialError}</li>}
                </>
              )
            }
            {...antFieldProps}
          >
            <Component {...restProps} {...fieldProps} />
          </Form.Item>
        );
      }}
    </Field>
  );
};
