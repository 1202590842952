import React from 'react';
import {IPractitioner} from 'interfaces/pre-auth.interface';
import {KlaimDropdownSearch} from 'modules/Common/components-v2/KlaimForm';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {getPractitioners, practitionerSelect} from '../../practitionerSlice';

interface Option {
  label: string;
  value: string;
}

interface ComponentProps {
  onChange?: (value: any) => void;
  value?: any;
}

const PractitionerDropdown: React.FC<ComponentProps> = ({onChange, value}) => {
  const dispatch = useAppDispatch();
  const {practitioner} = useAppSelector(practitionerSelect);
  const [limit, setLimit] = React.useState<number>(10);
  const [searchTyping, setSearchTyping] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');
  const [selectedPractitioner, setSelectedPractitioner] = React.useState<any>(undefined);
  const [diagnosisOption, setDiagnosisOption] = React.useState<Option[]>([]);
  React.useEffect(() => {
    dispatch(
      getPractitioners({
        search,
        limit,
      }),
    );
  }, [search]);

  React.useEffect(() => {
    if (practitioner.data) {
      const newData = practitioner.data.map((pract: IPractitioner) => {
        return {
          label: pract.name,
          value: pract.id.toString(),
        };
      });
      setDiagnosisOption(newData);
    }
  }, [practitioner]);

  React.useEffect(() => {
    if (value) {
      setSelectedPractitioner(value?.id?.toString());
    }
  }, [value]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const handleOnChange = (v: any) => {
    const diagnosisDetails = practitioner.data.find((pract: IPractitioner) => pract.id.toString() === v);
    if (onChange) {
      onChange(diagnosisDetails && diagnosisDetails ? diagnosisDetails : {});
    }
  };

  const handleInputChange = (newValue: any) => {
    setSearchTyping(newValue);
  };

  return (
    <KlaimDropdownSearch
      label="Search Practitioner"
      options={diagnosisOption}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      value={selectedPractitioner}
    />
  );
};

export default PractitionerDropdown;
