import produce from 'immer';
import {Report} from 'api/reports/reportTypes';

export interface ReportingContextState {
  reports: Report[];
  loading: boolean;
}

export const initialState = (): ReportingContextState => ({
  reports: [],
  loading: false,
});

export const SET_REPORTS = 'SET_REPORTS';
export const storeReports = (reports: Report[]) => ({
  type: SET_REPORTS,
  payload: {reports},
});

export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING';
export const setReportsLoading = (loading: boolean) => ({
  type: SET_REPORTS_LOADING,
  payload: {loading},
});

const reportingReducer = (state: ReportingContextState, action: any) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case SET_REPORTS: {
        const {reports} = (action as ReturnType<typeof storeReports>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.reports = reports;
        break;
      }
      case SET_REPORTS_LOADING: {
        // eslint-disable-next-line no-param-reassign
        draftState.loading = action.payload.loading;
        break;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  });

export default reportingReducer;
