import React, {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Field} from 'formik';
import infoIcon from 'assets/info_icon.svg';
import dayjs from 'dayjs';
import ErxStatusField from 'modules/Pharmacy/components/ErxStatusField';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {events, Mixpanel} from 'services/mixpanel';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import KlaimForm from 'modules/Common/components/KlaimForm';
import {setRequiredFilters, setNavOptions, headerSelect, Branches} from 'modules/Header/headerSlice';
import Header from 'modules/Header';
import {NavigationMenuItem} from 'interfaces/header.interfaces';
import {getErxById, erxDescriptionSelect, refreshErxById, dispenseErxByDescriptionId} from './erxDescriptionSlice';
import {DiagnoseDetails, DrugDetails, ErxStatus} from './types';
import {useStylesFromThemeFunction} from './ErxDescription';

const ErxDetails: FC = () => {
  const classes = useStylesFromThemeFunction();
  const {erxId}: {erxId: string} = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {data: erxDesc, viewId} = useAppSelector(erxDescriptionSelect);
  const [refNumber, setReferenceNumber] = useState('');
  const location = useLocation();
  const {selectedBranches: branches} = useAppSelector(headerSelect);

  const providerId = branches.map((branch: Branches) => branch.value).join(',');

  const erxOptions: NavigationMenuItem[] = [
    {title: 'eRx List', link: '/pharmacy'},
    {title: 'eRx Prior Request', link: '/pharmacy/prior-request'},
    {title: 'eRx Details', link: `/pharmacy/erx/${erxId}`},
  ];

  useEffect(() => {
    if (location.pathname === `/pharmacy/erx/${erxId}`) {
      dispatch(
        setRequiredFilters({
          navigation: true,
          searchByRef: true,
          searchPharmacyList: false,
          erxStatusFilter: false,
          dateRangeFilterMonth: false,
          branchesFilter: false,
          requestAuthorizationButton: false,
          dispenseDrugButton: true,
        }),
      );

      dispatch(
        setNavOptions({
          navOptions: erxOptions,
        }),
      );
    }
  }, [location]);

  useEffect(() => {
    dispatch(getErxById({erxId, providerId}));
  }, [branches]);

  useEffect(() => {
    function reRun() {
      dispatch(refreshErxById({erxId, providerId}));
    }

    reRun();
    const interval = setInterval(() => reRun(), 20000);
    return () => {
      clearInterval(interval);
    };
  }, [branches]);

  useEffect(() => {
    if (viewId) {
      history.push(`/pharmacy/erx/${viewId}`);
    }
  }, [viewId]);

  useEffect(() => {
    Mixpanel.track(events.pageViews.erxDetails);
  }, []);

  const checkValues = {
    activities: [],
  };

  const checkSchema = Yup.object({
    activities: Yup.array().required(),
  });

  interface Values {
    [field: string]: any;
  }

  const onSubmit = (value: Values) => {
    if (erxDesc) {
      dispatch(
        dispenseErxByDescriptionId({
          erxId,
          providerId,
          activityIds: value.activities,
        }),
      );
    }
  };

  // TODO Status icon,
  return (
    <>
      <KlaimForm initialValues={checkValues} validationSchema={checkSchema} onSubmit={onSubmit}>
        <Header />
        <div className={classes.cardSection}>
          <h2>Authorization</h2>

          <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
            <div className={classes.cardInfoSection}>
              <p>eRx reference</p>
              <span>{erxDesc?.reference}</span>
            </div>
            <div className={classes.cardInfoSection}>
              <p>Status</p>
              <ErxStatusField status={erxDesc?.status} submissionError={erxDesc?.authorization.submissionError} />
            </div>
            <div className={classes.cardInfoSection}>
              <p>Auth. Obtained in</p>
              <span>
                {'<'}
                {dayjs(erxDesc?.transaction_date_prior_authorization).diff(erxDesc?.transaction_date, 'minutes')} min
              </span>
            </div>
            <div className={classes.cardInfoSection}>
              <p>Authorization ID </p>
              <span>{erxDesc?.authorization.id}</span>
            </div>
          </div>
        </div>
        <div className={classes.dFlex}>
          <div className={`${classes.cardSection} ${classes.flex05}`}>
            <h2>Prescription Information</h2>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                <p> Provider Name</p>
                <span>
                  {erxDesc?.sender.name} ({erxDesc?.sender.id})
                </span>
              </div>
            </div>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                <p>Clinician</p>
                <span>
                  {erxDesc?.clinician.name} ({erxDesc?.clinician.id}))
                </span>
              </div>
            </div>
            <div className={`${classes.cardRow} ${classes.cardInfoSectionLast}`}>
              <div className={classes.cardInfoSection}>
                <p>Prescription Date & Time </p>
                <span>07 - Oct - 2020 21:59</span>
              </div>
            </div>
          </div>
          <div className={`${classes.cardSection} ${classes.ml20} ${classes.flex1}`}>
            <h2>Patient</h2>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                <p>Emirates ID</p>
                <span>{erxDesc?.patient.emirates_id}</span>
              </div>
              <div className={classes.cardInfoSection}>
                <p>Emirates ID</p>
                <span>{erxDesc?.patient.emirates_id}</span>
              </div>
            </div>
            <div className={classes.cardRow}>
              <div className={classes.cardInfoSection}>
                <p>Clinician</p>
                <span>{erxDesc?.clinician.name}</span>
              </div>
              <div className={classes.cardInfoSection}>
                <p>Weight</p>
                <span>{erxDesc?.patient.weight}</span>
              </div>
            </div>
            <div className={`${classes.cardRow} ${classes.cardInfoSectionLast}`}>
              <div className={classes.cardInfoSection}>
                <p>Insurance Plan</p>
                <span>{erxDesc?.payer.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.cardSection}>
          <h2>Diagnosis</h2>
          <div className={`${classes.cardRow2} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
            {erxDesc?.diagnoses &&
              erxDesc.diagnoses.map((diagnose: DiagnoseDetails, index: number) => (
                <div className={classes.cardInfoSection2} key={index}>
                  {diagnose.type === 'Principal' ? (
                    <div>
                      <p>Principal</p>
                      <span>
                        {diagnose.description.long} ({diagnose.code})
                      </span>
                    </div>
                  ) : (
                    <div>
                      <p>Secondary</p>
                      <span>
                        {diagnose.description.long} ({diagnose.code})
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={classes.cardSection}>
          <h2>Drugs</h2>
          <div className={classes.drugContainer}>
            {erxDesc?.drugs &&
              erxDesc.drugs.map((drug: DrugDetails, index: number) => {
                return (
                  <>
                    {drug.status === 'rejected' ? (
                      <div className={classes.drugCard2} key={index}>
                        <div className={classes.cardRow}>
                          <div className={classes.cardInfoSection}>
                            <div>
                              <Field type="checkbox" name="activities" value={drug.activityId} />
                              Drugs selection for dispensing
                            </div>
                            Prescribed Drug {drug.activityId}
                            <span>
                              {drug.code} {drug.dosageFormPackage}
                            </span>
                          </div>
                        </div>
                        <div className={`${classes.cardRow} ${classes.flexUnset}`}>
                          <div className={classes.cardInfoSection}>
                            <p>Quantity</p>
                            <span>{drug.quantity}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Units</p>
                            <span>Packet</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Duration</p>
                            <span>{drug.duration}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Comment</p>
                            <span>
                              <img src={infoIcon} alt="info" style={{marginRight: '10px'}} />
                              DHA-PROVIDER-DEMO-TPA-DEMO-20210608191432-0001
                            </span>
                          </div>
                        </div>
                        <div className={classes.cardRow}>
                          <div className={classes.cardInfoSection}>
                            <p>Instructions</p>
                            <span>{drug.instructions}</span>
                          </div>
                        </div>
                        <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
                          <div className={classes.cardInfoSection}>
                            <p>Status</p>
                            <span className={classes.statusColumn}>
                              <ErxStatusField status={drug?.status} />
                            </span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Net</p>
                            <span>{drug.net}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Payment Amount</p>
                            <span>{drug.paymentAmount}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p> Patient Share</p>
                            <span>{drug.patientShare}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.drugCard} key={index}>
                        <div className={classes.cardRow}>
                          <div className={classes.cardInfoSection}>
                            {drug.status === 'dispensed' ? null : (
                              <div>
                                <Field type="checkbox" name="activities" value={drug.activityId} />
                                Drugs selection for dispensing
                              </div>
                            )}
                            <p>Prescribed Drug {drug.activityId}</p>
                            <span>
                              {drug.code} {drug.dosageFormPackage}
                            </span>
                          </div>
                        </div>
                        <div className={`${classes.cardRow} ${classes.flexUnset}`}>
                          <div className={classes.cardInfoSection}>
                            <p>Quantity</p> <span>{drug.quantity}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Units</p>
                            <span>Packet</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Duration</p>
                            <span>{drug.duration}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Comment</p>
                            <span>
                              <img src={infoIcon} alt="info" style={{marginRight: '10px'}} />
                              DHA-PROVIDER-DEMO-TPA-DEMO-20210608191432-0001
                            </span>
                          </div>
                        </div>
                        <div className={classes.cardRow}>
                          <div className={classes.cardInfoSection}>
                            <p>Instructions</p>
                            <span>{drug.instructions}</span>
                          </div>
                        </div>
                        <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
                          <div className={classes.cardInfoSection}>
                            <p>Status</p>
                            <span className={classes.statusColumn}>
                              <ErxStatusField status={drug?.status} />
                            </span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Net</p>
                            <span>{drug.net}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Payment Amount</p>
                            <span>{drug.paymentAmount}</span>
                          </div>
                          <div className={classes.cardInfoSection}>
                            <p>Patient Share</p>
                            <span>{drug.patientShare}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </KlaimForm>
    </>
  );
};
export default ErxDetails;
