import React, {FC} from 'react';
import {IRoute} from 'interfaces/route.interface';
import klaim_logo from 'assets/klaim_logo.svg';
import facebook_logo from 'assets/facebook_logo.png';
import linkedin_logo from 'assets/linkedin_logo.png';
import instagram_logo from 'assets/instagram_logo.png';
import youtube_logo from 'assets/youtube_logo.png';
import twitter_logo from 'assets/twitter_logo.png';
import Envelope_gray from 'assets/Envelope_gray.svg';
import phone_gray from 'assets/phone_gray.svg';
import location_gray from 'assets/location_gray.svg';
import building_gray from 'assets/building_gray.svg';
import Layer_gray from 'assets/Layer_gray.svg';
import {useStylesFromThemeFunction} from './EmailSignature';

const EmailSignature: FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <div className={classes.logoContainer}>
          <img alt="logo" src={klaim_logo} />
          <div className={classes.firstDiv}>
            <img alt="logo" src={facebook_logo} />
            <img alt="logo" src={linkedin_logo} />
            <img alt="logo" src={instagram_logo} />
            <img alt="logo" src={youtube_logo} />
            <img alt="logo" src={twitter_logo} />
          </div>
        </div>
        <div className={classes.secondDiv}>
          <h1>Karim Dakki</h1>
          <h2>Co-Founder & CEO</h2>
          <hr />

          <div className={classes.contactsContainer}>
            <div className={classes.thirdDiv}>
              <div>
                <img alt="logo" src={Envelope_gray} />
              </div>
              <p className={classes.informationColor}>Karim@klaim.ai</p>
            </div>
            <div className={classes.thirdDiv}>
              <div>
                <img alt="logo" src={phone_gray} />
              </div>
              <p className={classes.informationColor}>+971 123 456 789</p>
            </div>
            <div className={classes.thirdDiv}>
              <div>
                <img alt="logo" src={building_gray} />
              </div>
              <p className={classes.informationColor}>Klaim Holdings LTD</p>
            </div>
            <div className={classes.thirdDiv}>
              <div>
                <img alt="logo" src={Layer_gray} />
              </div>
              <p className={classes.informationColor}>www.klaim.ai</p>
            </div>
            <div className={classes.forthDiv}>
              <div>
                <img alt="logo" src={location_gray} />
              </div>
              <p className={classes.informationColor}>Indigo Icon Tower 3404, JLT Cluster F, Dubai, UAE</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.fifthDiv}>
        <h3>We Improve Profitability and Cash Flow for Healthcare Providers</h3>
      </div>
      <div className={classes.sixDiv}>
        <p>
          <b>Confidentiality Note:</b> It is a long established fact that a reader will be distracted by the readable
          content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
          normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable
          English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model
          text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
          evolved over the years, sometimes by accident, sometimes on purpose.
        </p>
      </div>
    </div>
  );
};

export default EmailSignature;
