import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    accessManagementWrapper: {
      flex: '1 1',
      flexDirection: 'column',
    },
    borderBottom: {
      borderBottom: `1px solid ${Colors.grayLighter}`,
    },
    membersAsigned: {
      color: Colors.blueGrayDark,
      flex: '1',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    roleListTable: {
      width: '100%',
    },
    roleListTableRow: {
      alignItems: 'center',
      display: 'flex',
      height: '40px',
      justifyContent: 'space-between',
      padding: '0 30px',
      width: '100%',
    },
    roleName: {
      alignItems: 'center',
      color: Colors.blueGrayDark,
      display: 'flex',
      flex: '1',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0px',
    },
    scrollContent: {
      marginBottom: 'calc(50vh - 100px)',
      paddingBottom: '50px',
    },

    tableDataInfo: {
      color: Colors.blueGrayDark,
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.42px',
      marginTop: '8px',
      opacity: '0.48',
      padding: '0 34px',
    },
    tableName: {
      color: Colors.blueGrayDark,
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.42px',
      marginBottom: '8px',
      marginTop: '10px',
      opacity: '0.48',
      padding: '0 34px',
    },
    tableWrapper: {
      background: `${Colors.white} 0% 0% no-repeat padding-box`,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      boxShadow: `0px 0.5px 2px ${Colors.RMSC.shadow}`,
      display: 'flex',
      flex: '1 1',
      justifyContent: 'space-between',
    },
    loadingContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 200,
    },
  };
});
