import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  defaultStatus: {
    width: 10,
    height: 10,
    background: 'red',
    borderRadius: 999,
  },
  egligible: {
    background: Colors.lightGreen,
  },
  inegligible: {
    background: Colors.red,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  loader: {
    width: 7,
    height: 7,
    borderLeft: `2px solid ${Colors.purple}`,
    borderBottom: `2px solid ${Colors.purple}`,
    borderWidth: 2,
  },
  personalInformationContainer: {
    display: 'flex',
    gap: 3,
    alignItems: 'center',
  },
  iconContainer: {
    width: 20,
  },
  tableContainer: {
    background: Colors.white,
  },
  hover: {
    background: Colors.blueGrayLight,
  },
  container: {
    backgroundColor: Colors.white,
  },
  actionContainerDefault: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    background: 'none',
    border: 'none',
    color: Colors.grayLabel,
    width: 'auto',
    cursor: 'pointer',
  },
  actionEdit: {
    color: Colors.purple,
  },
  button: {
    marginTop: 4,
    outline: 'none',
    backgroundColor: Colors.white,
    border: 'none',
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.blueGrayLight,
    },
    padding: '5px 15px',
    borderRadius: 4,
    whiteSpace: 'nowrap',
    color: Colors.blueGrayDark,
  },
  buttonIcon: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  navLink: {
    textDecoration: 'none',
  },
});
