import Reports from './Reports';
import {ReportingProvider, useReportingDispatch} from './context/reportingContext';
import {filterReports} from './context/reportingThunks';
import {ReportCreationFields} from './components/ReportFilteringCard/ReportFilteringCard';

export const ReportsContainer = () => {
  const dispatch = useReportingDispatch();

  const onFindReports = (values: ReportCreationFields) => {
    dispatch(filterReports(values.branches.join(','), values.type));
  };

  return <Reports onFindReports={onFindReports} />;
};

export const ReportsWrapper = () => {
  return (
    <ReportingProvider>
      <ReportsContainer />
    </ReportingProvider>
  );
};

export default ReportsWrapper;
