import {FC} from 'react';
import infoIcon from 'assets/info_icon.svg';
import editRoleIcon from 'assets/edit_role_icon.svg';
import {useStylesFromThemeFunction, ComponentProps} from './ActionButton';

const ActionButton: FC<ComponentProps> = ({status}) => {
  const classes = useStylesFromThemeFunction();

  enum Status {
    submitted = 'submitted',
    denied = 'denied',
    approved = 'approved',
  }

  const getActionText = () => {
    const viewClaim = (
      <>
        <img src={infoIcon} alt="info" />
        <p>View</p>
      </>
    );
    const editClaim = (
      <>
        <img src={editRoleIcon} alt="info" />
        <p>Edit</p>
      </>
    );
    switch (status) {
      case Status.submitted:
        return viewClaim;
      case Status.denied:
        return editClaim;
      default:
        return viewClaim;
    }
  };
  return (
    <div className={`${classes.viewClaimBtn}`} role="button">
      {getActionText()}
    </div>
  );
};

export default ActionButton;
