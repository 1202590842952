import React from 'react';
import PhoneInput from 'react-phone-number-input';
import ReactInputMask from 'react-input-mask';
import PasswordEye from 'assets/component/PasswordEye';
import PasswordEyeSlashed from 'assets/component/PasswordEyeSlashed';
import helpIcon from 'assets/help_icon.svg';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Common/components-v2/KlaimForm/components/KlaimInput/KlaimInput';
import {Colors} from 'modules/Common/constants/Colors';
import KlaimTooltip from '../../../KlaimTooltip';

const KlaimInput: React.FC<ComponentProps> = ({
  label,
  name,
  placeholder,
  type = 'text',
  containerClassName,
  isDisabled = false,
  isReadOnly,
  optional,
  value = '',
  showTooltip,
  requirements = [],
  isError,
  isTouched,
  hasError,
  variant = 'primary',
  onChange,
  defaultCountry = 'US',
  mask,
  optionalMessage,
  style,
  ...props
}) => {
  const classes = useStylesFromThemeFunction();
  const [toggle, setToggle] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState(false);
  const [change, setChange] = React.useState<string | number>('');
  const isPasswordValidated = (error: boolean, touched: boolean) => {
    return error && touched ? classes.passwordError : classes.passwordValid;
  };

  const inputContainerClass = (v: string) => {
    switch (v) {
      case 'secondary': {
        return classes.inputSecondVariant;
      }
      default: {
        return '';
      }
    }
  };

  React.useEffect(() => {
    setChange(value);
    if (onChange) onChange(value);
  }, [value]);

  const handleChange = (inputValue: any) => {
    setChange(inputValue);
    if (onChange) onChange(inputValue);
  };

  const validateInput = (touched?: boolean, error?: boolean) => {
    if ((touched && error) || hasError) {
      return classes.inputContainerError;
    }
    if (touched) {
      return classes.inputContainerValidated;
    }
    return classes.inputContainerDefault;
  };

  const validateLabel = (touched?: boolean, error?: boolean) => {
    if ((touched && error) || hasError) {
      return classes.labelError;
    }
    if (touched) {
      return classes.labelValidated;
    }
    // return classes.labelDefault;
  };

  return (
    <div className={`${classes.contentContainer} ${containerClassName} `} style={style}>
      {label && (
        <label className={`${classes.label} ${validateLabel(isTouched, isError)}`} htmlFor={name}>
          {label}
          {(optional || optionalMessage) && <span>({optionalMessage || 'Optional'})</span>}
        </label>
      )}

      <div
        className={`${classes.inputContainer} ${inputContainerClass(variant)} ${
          isFocus ? classes.inputContainerFocus : validateInput(isTouched, isError)
        } `}
      >
        {(() => {
          switch (type) {
            case 'password':
              return (
                <div
                  className={`${classes.passwordContainer} ${
                    isTouched ? isPasswordValidated(isError !== undefined, isTouched) : ''
                  }`}
                >
                  <div className={`${classes.passwordSubContainer} `}>
                    <input
                      value={value}
                      className={classes.passwordInput}
                      type={toggle ? 'text' : 'password'}
                      placeholder={placeholder}
                      disabled={isDisabled}
                      autoComplete="on"
                      readOnly={isReadOnly}
                    />
                    <button className={classes.passwordIcon} type="button" onClick={() => setToggle(!toggle)}>
                      {toggle ? <PasswordEye fill={Colors.purple} /> : <PasswordEyeSlashed fill={Colors.purple} />}
                    </button>
                  </div>
                  {showTooltip && (
                    <div>
                      <KlaimTooltip requirements={requirements} icon={helpIcon} />
                    </div>
                  )}
                </div>
              );
            case 'phone':
              return (
                <PhoneInput
                  international
                  limitMaxLength
                  defaultCountry={defaultCountry}
                  value={value.toString()}
                  onChange={handleChange}
                  placeholder={placeholder}
                  className={`${variant === 'primary' && classes.inputNumberPrimary} ${classes.inputNumber} ${
                    isTouched ? validateInput(isTouched, isError) : ''
                  }`}
                />
              );
            case 'calendar':
              return (
                <ReactInputMask
                  mask="99-99-9999"
                  onBlur={() => setIsFocus(false)}
                  onFocus={() => setIsFocus(true)}
                  value={change}
                  onChange={(e) => handleChange(e.currentTarget.value)}
                  className={`${isDisabled ? classes.inputDisabled : ''} ${classes.inputDefault}`}
                  type={toggle ? type : 'text'}
                  placeholder={placeholder}
                  disabled={isDisabled}
                  autoComplete="on"
                  readOnly={isReadOnly}
                >
                  {(inputProps: any) => <input {...inputProps} />}
                </ReactInputMask>
              );

            default:
              return (
                <input
                  value={value}
                  className={`${isReadOnly && classes.inputReadOnly} ${classes.inputDefault} ${
                    isTouched ? validateInput(isTouched, isError) : ''
                  }`}
                  type={type}
                  placeholder={placeholder}
                  disabled={isDisabled}
                  autoComplete="on"
                  readOnly={isReadOnly}
                  onBlur={() => setIsFocus(false)}
                  onFocus={() => setIsFocus(true)}
                  onChange={(e) => handleChange(e.currentTarget.value)}
                  {...props}
                />
              );
          }
        })()}
        {type === 'password' && (
          <button className={classes.passwordIcon} type="button" onClick={() => setToggle(!toggle)}>
            {toggle ? <PasswordEye fill={Colors.purple} /> : <PasswordEyeSlashed fill={Colors.purple} />}
          </button>
        )}
      </div>
      {showTooltip && (
        <div>
          <KlaimTooltip requirements={requirements} icon={helpIcon} />
        </div>
      )}
    </div>
  );
};

export default KlaimInput;
