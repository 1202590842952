import React, {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {ErrorMessage, Field} from 'formik';
import dayjs from 'dayjs';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import KlaimForm from 'modules/Common/components/KlaimForm';
import {setRequiredFilters, setNavOptions, headerSelect, Branches} from 'modules/Header/headerSlice';
import Header from 'modules/Header';
import {useStylesFromThemeFunction} from 'modules/Pharmacy/features/ErxReferencePrescription/ErxReferencePrescription';
import {
  getErxByRefId,
  erxRefPrescriptionSelect,
  saveNewERX,
  getCurrentUserBranch,
} from 'modules/Pharmacy/features/ErxReferencePrescription/erxReferenceSlice';
import {
  ErxDiagnoses,
  ErxDrugsRefId,
  ObservationType,
  ActivityType,
  AuthorizationType,
  UserBranchesObject,
} from 'modules/Pharmacy/features/ErxReferencePrescription/types';
import ErxSelection from 'modules/Pharmacy/components/ErxSelection';
import ArrowDown from 'assets/arrow_down_color.svg';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {NoData} from 'modules/Common/components/NoData';
import * as Toast from 'modules/Common/utils/toast';
import {setNewToast, toast} from 'modules/Common/components/KlaimToast/toastSlice';
import {KlaimToast} from 'modules/Common/components/KlaimToast';
import {NavigationMenuItem} from 'interfaces/header.interfaces';

const ErxReferencePrescription: FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const [editId, setEditId] = useState('');
  const {erxRefId}: {erxRefId: string} = useParams();
  const dispatch = useAppDispatch();
  const [genericName, setGenericName] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [actCodeIdentifier, setActCodeIdentifier] = useState('');
  const [getNewDrugCode, setNewDrugCode] = useState('');
  const [newQuantity, setNewQuantity] = useState('');
  const [newDuration, setNewDuration] = useState('');
  const {data: erxRefData, id: responseAuthorizationId, branches, error, isLoading} = useAppSelector(
    erxRefPrescriptionSelect,
  );
  const {selectedBranches: branchesSelected} = useAppSelector(headerSelect);
  const {toasts} = useAppSelector(toast);
  const location = useLocation();

  const [erxActivities, setErxActivities] = useState<ErxDrugsRefId[]>();

  React.useEffect(() => {
    if (erxRefData) {
      setErxActivities(
        erxRefData.activities.map((drug) => ({...drug, isSelected: true, clinicianId: erxRefData.clinician.id})),
      );
    }
  }, [erxRefData]);

  const providerId = branchesSelected.map((branch: Branches) => branch.value).join(',');

  // const selectedDrug = (
  //   code: string,
  //   name: string,
  //   strength: string,
  //   dosageFormPackage: string,
  //   manufacturer: string,
  //   actCode: string,
  // ) => {
  //   console.log('selected Drug', `${code} - ${name} - ${strength}, ${dosageFormPackage}, ${manufacturer}`);
  //   console.log('code', code);
  //   setSelectedValue(`${code} - ${name} - ${strength}, ${dosageFormPackage}, ${manufacturer}`);
  //   setActCodeIdentifier(code);
  //   setNewDrugCode(code);
  //   setGenericName('');
  //   setEditId('');
  // };

  const erxOptions: NavigationMenuItem[] = [
    {title: 'eRx List', link: '/pharmacy'},
    {title: 'eRx Prior Request', link: '/pharmacy/prior-request'},
    {title: 'eRx Details', link: `/pharmacy/prescription/${erxRefId}`},
  ];

  useEffect(() => {
    if (location.pathname === `/pharmacy/prescription/${erxRefId}`) {
      dispatch(
        setRequiredFilters({
          navigation: true,
          searchByRef: true,
          searchPharmacyList: false,
          erxStatusFilter: false,
          dateRangeFilterMonth: false,
          branchesFilter: false,
          requestAuthorizationButton: true,
        }),
      );

      dispatch(
        setNavOptions({
          navOptions: erxOptions,
        }),
      );
    }
  }, [location]);

  useEffect(() => {
    dispatch(getErxByRefId({erxRefId, providerId}));
  }, [erxRefId, providerId, branches]);

  useEffect(() => {
    dispatch(getCurrentUserBranch({}));
  }, []);

  useEffect(() => {
    if (responseAuthorizationId) {
      history.push(`/pharmacy/erx/${responseAuthorizationId}`);
    }
  }, [responseAuthorizationId]);

  const toggle = (id: string) => {
    setEditId(id);
  };

  useEffect(() => {
    if (error) {
      dispatch(
        setNewToast({
          id: `${erxRefId}`,
          variant: 'error',
          title: 'eRx reference number',
          message: `${error.statusCode}`,
        }),
      );
    }
  }, [providerId, error]);

  const updateDrugActivities = (id: string, values?: any) => {
    console.log('activity Id', id);
    console.log('newValues', values);
    setErxActivities(
      erxActivities?.map((activ) => {
        if (activ.id === id) {
          activ = values;
          console.log('activ', activ);
        }
        return activ;
      }),
    );

    setEditId('');
  };

  const setSearchFilter = (searchKeyword: string) => {
    setGenericName(searchKeyword);
  };

  const getNewQuantity = (inputtedQuantinty: string) => {
    console.log('inputtedQuantity', inputtedQuantinty);
  };

  const getNewDuration = (inputtedDuration: string) => {
    console.log('inputtedDuration', inputtedDuration);
  };

  const checkValues = {
    activities: [],
    branches: '',
  };

  const checkSchema = Yup.object({
    activities: Yup.array().required(),
    branches: Yup.string().required(),
  });

  interface Values {
    [field: string]: any;
  }

  const now = dayjs();

  const checkedDrugs = (value: Values) => {
    if (!erxActivities) {
      return;
    }
    const tempData = erxActivities
      ?.filter((activity) => value.activities.includes(activity.id))
      .map((filteredActivity) => {
        console.log('filteredActivity', filteredActivity);
        const unitPrice = parseFloat(filteredActivity.package_price) / parseFloat(filteredActivity.unit_per_package);
        const packagePrice = unitPrice;
        const netPrice = Math.round(filteredActivity.quantity * packagePrice);
        // return '';
        return {
          activityId: filteredActivity.id,
          start: now.toISOString(),
          type: ActivityType.Drug,
          code: filteredActivity.code,
          quantity: filteredActivity.quantity,
          unit: filteredActivity.unit_per_package,
          net: netPrice,
          clinicianId: filteredActivity.clinicianId,
          observations: [
            {
              type: ObservationType.Text,
              code: 'code',
              value: filteredActivity.duration.toString(),
              valueType: 'valueType2',
            },
            {
              type: ObservationType.ERX,
              code: 'code2',
              value: '1',
              valueType: 'valueType2',
            },
          ],
        };
      });
    // console.log('tempData', tempData);
    if (erxRefData) {
      dispatch(
        saveNewERX({
          providerId: value.branches,
          erxRef: erxRefData.reference,
          priorRequest: {
            type: AuthorizationType.AUTHORIZATION,
            payerId: erxRefData.payer.id,
            receiverId: erxRefData.receiver.id,
            dateOrdered: now.format(''),
            memberId: erxRefData.patient.member_id,
            emiratesIdNumber: erxRefData.patient.emirates_id,
            weight: erxRefData.patient.weight,
            encounter: erxRefData.encounter,
            diagnoses: erxRefData.diagnoses,
            activities: tempData,
          },
        }),
      );
    }
  };

  // TODO Status icon,
  return (
    <div key={erxRefData?.id}>
      <KlaimForm initialValues={checkValues} validationSchema={checkSchema} onSubmit={checkedDrugs}>
        <Header />

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {erxRefData && providerId !== null ? (
              <>
                <div className={classes.cardSection}>
                  <h2>Branch</h2>
                  <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
                    <div className={classes.branchContainer}>
                      <span>Choose Branch</span>
                      <div className={classes.branchSelectContainer}>
                        <Field as="select" className={classes.selectBranches} name="branches">
                          {branches?.map((userBranch: UserBranchesObject, index: number) => {
                            return (
                              <option key={index} className={classes.optionsValue} value={userBranch.identifier}>
                                {userBranch.name}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <ErrorMessage name="branches">
                        {(msg) => <div className={classes.errorMessage}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>

                <div className={classes.cardSection}>
                  <h2>Authorization</h2>
                  <div className={`${classes.cardRow} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
                    <div className={classes.cardInfoSection}>
                      <p>eRx reference</p>
                      <span>{erxRefData?.reference}</span>
                    </div>
                    <div className={classes.cardInfoSection}>
                      <p>Auth. Obtained in</p>
                      <span>-</span>
                    </div>
                    <div className={classes.cardInfoSection}>
                      <p>Authorization ID</p>
                      <span>-</span>
                    </div>
                  </div>
                </div>

                <div className={classes.dFlex}>
                  <div className={`${classes.cardSection} ${classes.flex05}`}>
                    <h2>Prescription Information</h2>
                    <div className={classes.cardRow}>
                      <div className={classes.cardInfoSection}>
                        <p>Provider Name</p>
                        <span>
                          {`${erxRefData?.sender.name}`} ({`${erxRefData?.sender.id}`})
                        </span>
                      </div>
                    </div>
                    <div className={classes.cardRow}>
                      <div className={classes.cardInfoSection}>
                        <p>Clinician</p>
                        <span>
                          {`${erxRefData?.clinician.name}`} ({`${erxRefData?.clinician.id}`}))
                        </span>
                      </div>
                    </div>
                    <div className={`${classes.cardRow} ${classes.cardInfoSectionLast}`}>
                      <div className={classes.cardInfoSection}>
                        <p>Prescription Date & Time</p>
                        <span>{dayjs(`${erxRefData?.transaction_date}`).format('MMMM DD YYYY, hh:mm')}</span>
                      </div>
                    </div>
                  </div>

                  <div className={`${classes.cardSection} ${classes.ml20} ${classes.flex1}`}>
                    <h2>Patient</h2>
                    <div className={classes.cardRow}>
                      <div className={classes.cardInfoSection}>
                        <p>Emirates ID</p>
                        <span>{`${erxRefData?.patient.emirates_id}`}</span>
                      </div>
                      <div className={classes.cardInfoSection}>
                        <p>Emirates ID</p>
                        <span>{`${erxRefData?.patient.emirates_id}`}</span>
                      </div>
                    </div>
                    <div className={classes.cardRow}>
                      <div className={classes.cardInfoSection}>
                        <p>Clinician</p>
                        <span>{`${erxRefData?.clinician.name}`}</span>
                      </div>
                      <div className={classes.cardInfoSection}>
                        <p>Weight</p>
                        <span>{`${erxRefData?.patient.weight}`}</span>
                      </div>
                    </div>
                    <div className={`${classes.cardRow} ${classes.cardInfoSectionLast}`}>
                      <div className={classes.cardInfoSection}>
                        <p>Insurance Plan</p>
                        <span>{`${erxRefData?.payer.name}`}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.cardSection}>
                  <h2>Diagnosis</h2>
                  <div className={`${classes.cardRow2} ${classes.cardInfoSectionLast} ${classes.flexUnset}`}>
                    {erxRefData?.diagnoses &&
                      erxRefData.diagnoses.map((diagnose: ErxDiagnoses, index: number) => (
                        <div className={classes.cardInfoSection} key={index}>
                          {diagnose.type === 'Principal' ? (
                            <div>
                              <p>Principal</p>
                              <span>
                                {diagnose.description.long} ({diagnose.code})
                              </span>
                            </div>
                          ) : (
                            <div>
                              <p>Secondary</p>
                              <span>
                                {diagnose.description.long} ({diagnose.code})
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>

                <div className={classes.cardSection}>
                  <div className={classes.headerContainer}>
                    <div className={classes.drugsContainer}>
                      <img src={ArrowDown} alt="arrow_down" />
                      <h2>Drugs</h2>
                    </div>
                    <div className={classes.totalContainer}>
                      <div>
                        <h1>Total Net</h1>
                        <span>39.00</span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.drugContainer}>
                    {erxActivities &&
                      erxActivities.map((activity: ErxDrugsRefId, index: number) => {
                        return (
                          <ErxSelection
                            name="activities"
                            index={index}
                            activity={activity}
                            editId={editId}
                            setEditId={setEditId}
                            setGenericName={setGenericName}
                            genericName={genericName}
                            selectedValue={selectedValue}
                            setSearchFilter={setSearchFilter}
                            actCodeIdentifier={actCodeIdentifier}
                            toggle={toggle}
                            newQuantity={newQuantity}
                            getNewQuantity={getNewQuantity}
                            getNewDuration={getNewDuration}
                            newDuration={newDuration}
                            updateDrugActivities={updateDrugActivities}
                          />
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.cardSectionError}>
                <NoData />
                <KlaimToast />
              </div>
            )}
          </>
        )}
      </KlaimForm>
    </div>
  );
};
export default ErxReferencePrescription;
