import React from 'react';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {FormikHelpers} from 'formik';
import {useHistory, RouteComponentProps} from 'react-router-dom';
import Header from 'modules/Settings/components/SubHeader';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';

import {useAppDispatch} from 'modules/App/store';
import {
  branchManagementSelect,
  credentialsCleanup,
  editBranch,
  editCleanup,
  getBranch,
  validateCleanup,
  branchValidateCredentials,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import KlaimTypeSelect from 'modules/Common/components/KlaimTypeSelect';
import {useStylesFromThemeFunction} from 'modules/Settings/features/BranchManagement/BranchEdit/BranchEdit';

export interface IValues {
  [field: string]: any;
}

interface RouteParams {
  id: string;
}

interface FormValues {
  id: string;
  name: string;
  identifier: string;
  authority: string;
  authority_login: string;
  authority_password: string;
}

const BranchEdit: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: {id},
  },
}) => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {error, credentials, validated, isEdited, isLoading, branch} = useSelector(branchManagementSelect);
  const [formValues, setFormValues] = React.useState<FormValues>();
  const initialValues = {
    name: branch?.name || '',
    identifier: branch?.identifier || '',
    authority: branch?.authority || '',
    authority_login: branch?.authority_login || '',
    authority_password: credentials?.authority_password || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    identifier: Yup.string().required(),
    authority: Yup.string().required(),
    authority_login: Yup.string().required(),
    authority_password: Yup.string().required(),
  });

  const onSubmit: (
    values: IValues,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: IValues, formikHelpers) => {
    setFormValues({
      id,
      name: values.name,
      identifier: values.identifier,
      authority: values.authority,
      authority_login: values.authority_login,
      authority_password: values.authority_password,
    });
    dispatch(branchValidateCredentials({values}));
  };

  React.useEffect(() => {
    dispatch(getBranch({id}));
  }, [id]);

  React.useEffect(() => {
    if (validated === true && isEdited === true) {
      history.push('/settings/branch-management');
    }
    return () => {
      dispatch(validateCleanup());
      dispatch(editCleanup());
      dispatch(credentialsCleanup());
    };
  }, [isEdited]);

  React.useEffect(() => {
    if (formValues) {
      dispatch(
        editBranch({
          id,
          name: formValues.name,
          identifier: formValues.identifier,
          authority: formValues.authority,
          authority_login: formValues.authority_login,
        }),
      );
    }
  }, [validated]);

  return (
    <KlaimForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      enableReinitialize
    >
      <Header
        title="Edit Branch"
        backlink="/settings/branch-management"
        description={
          <p>
            Update the authority login and password and click ‘Done’ to update the facility’s government portal
            credentials.
          </p>
        }
        buttonType="submit"
        buttonTitle="Done"
        body={
          <div className={classes.bodyWrapper}>
            {isLoading ? (
              <div>Loading... </div>
            ) : (
              <>
                {error && (
                  <div className={classes.errorMessage}>{error.stack ? error.stack.message : error.message}</div>
                )}
                <KlaimInput name="name" label="Facility Name" placeholder="CLINIC XYZ" value={branch?.name} />
                <KlaimInput
                  name="identifier"
                  label="Provider ID"
                  placeholder="XX-XX-100-10"
                  value={branch?.identifier}
                  isReadOnly
                />

                <KlaimTypeSelect
                  name="authority"
                  values={[
                    {title: 'eClaimLink', value: 'DHA'},
                    {title: 'Shafafiya', value: 'HAAD'},
                  ]}
                />
                {/* <KlaimInput name="authority" label="Authority" placeholder="DHA" /> */}
                <KlaimInput
                  name="authority_login"
                  label="Authority Login"
                  placeholder="your_credentials"
                  value={branch?.authority_login}
                  isReadOnly
                />
                <KlaimInput
                  name="authority_password"
                  label="Authority Password"
                  type="password"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                />
              </>
            )}
          </div>
        }
      />
    </KlaimForm>
  );
};

export default BranchEdit;
