import React, {FC, useEffect, useState} from 'react';
import Table from 'modules/Common/components/Table';
import dayjs from 'dayjs';
import {useHistory} from 'react-router';
import EllipsisText from 'react-ellipsis-text';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import infoIcon from 'assets/info_icon.svg';
import actionButtonIcon from 'assets/action_button_icon.svg';
import calendarIcon from 'assets/calendar_icon.svg';
import insuranceIcon from 'assets/insurance_icon.svg';
import {headerSelect, OptionValue, Branches} from 'modules/Header/headerSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {NoData} from 'modules/Common/components/NoData';
import {events, Mixpanel} from 'services/mixpanel';
import {useAppDispatch, useAppSelector} from '../../../App/store';
import ErxStatusField from '../../components/ErxStatusField';
import {erxListSelect, getErxList, getPayersFromInsurers} from './erxListSlice';
import {useStylesFromThemeFunction} from './ErxList';
import {ErxDetails} from './types';
import {NoBranches} from '../../../Common/components/NoBranches';

const DATE_FORMAT = 'DD MMM YYYY HH:MM';

const ErxList: FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    data: {data: dataList, count: dataCount},
    isLoading,
    error,
  } = useAppSelector(erxListSelect);
  const {
    searchErx: search,
    selectedErxStatus: statusToString,
    startDate,
    endDate,
    selectedBranches: branches,
    selectedErxInsurer: insurance,
  } = useAppSelector(headerSelect);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [classification, setClassification] = useState('Insurance');

  const status = statusToString?.map((data: OptionValue) => data.value).join(',');
  const providerId = branches.map((branch: Branches) => branch.value).join(',');
  const insurers = insurance.map((insur: OptionValue) => insur.value).join(',');

  useEffect(() => {
    dispatch(
      getErxList({
        limit,
        offset: (offset - 1) * limit,
        search,
        status,
        startDate,
        endDate,
        providerId,
        insurers,
      }),
    );
  }, [limit, offset, search, status, startDate, endDate, providerId, insurers]);

  useEffect(() => {
    dispatch(
      getPayersFromInsurers({
        classification,
      }),
    );
  }, []);

  useEffect(() => {
    Mixpanel.track(events.pageViews.erxList);
  }, []);

  const handleViewErx = (id: string) => {
    history.push(`/pharmacy/erx/${id}`);
  };

  const userBranches = () => {
    const userBranch = localStorage.getItem('userBranches');
    return userBranch != null;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.patientListContainer}>
          <div className={classes.paginationContainer}>
            {providerId ? (
              <KlaimPaginationContainer
                pageSize={limit}
                totalCount={dataCount}
                currentPage={offset}
                onPageChange={setOffset}
                label="Pharmacy"
              >
                <Table
                  loading={isLoading}
                  tableHeadings={[
                    <p className={classes.tableHeading}>eRx Ref</p>,
                    <p className={classes.tableHeading}>Authorization ID</p>,
                    <p className={classes.tableHeading}>Member ID</p>,
                    <p className={classes.tableHeading}>Insurer</p>,
                    <p className={classes.tableHeading}>Request Date</p>,
                    <p className={classes.tableHeading}>Status</p>,
                    <p className={classes.tableHeading}>Action</p>,
                  ]}
                  renderBody={() => (
                    <>
                      {dataList &&
                        dataList.map((erx: ErxDetails, i: number) => {
                          return (
                            <tr className={classes.tableRow} key={i}>
                              <td>{erx.erxRef || '-'}</td>
                              <td>
                                <EllipsisText text={erx.authorizationId} length={'30'} />
                              </td>
                              <td>
                                <EllipsisText text={erx.memberId} length={'30'} />
                              </td>
                              <td>
                                <EllipsisText text={erx.payerId} length={'30'} />
                              </td>
                              <td>{dayjs(erx.transactionDate).format(DATE_FORMAT)}</td>
                              <td>
                                <ErxStatusField status={erx.status} submissionError={erx.submissionError} />
                              </td>
                              <td>
                                <div className={classes.viewErxBtn} role="button" onClick={() => handleViewErx(erx.id)}>
                                  <img src={infoIcon} alt="view" />
                                  <p className={classes.viewErx}>View</p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                />
              </KlaimPaginationContainer>
            ) : (
              <div className={classes.paginationContainer2}>
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErxList;
