import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  sidebar?: React.ReactNode;
  body?: React.ReactNode;
  forceFullBody?: boolean;
}

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
  },
  sidebarContainer: {
    maxHeight: '100vh',
    height: '97%',
    padding: '20px',
    position: 'fixed',
    overflow: 'hidden',
  },
  bodyContainer: {
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
  },
  bodySubContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
