import React from 'react';
import * as Yup from 'yup';
import {FormikHelpers} from 'formik';
import {useHistory, Link} from 'react-router-dom';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Forgot/EmailPasswordReset/EmailPasswordReset';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import * as Toast from 'modules/Common/utils/toast';
import Button from 'modules/Common/components/Button';
import {useAppDispatch} from 'modules/App/store';
import {forgotPassword} from 'modules/Auth/v3/features/Forgot/forgotPasswordSlice';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';

export interface Values {
  [field: string]: any;
}

const EmailPasswordReset: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
  });

  const emailRedirection = (status: any) => {
    if (status === 400) {
      Toast.error('Email is not registered');
    }
    if (status === undefined) {
      history.push('/auth/email-instructions');
    }
  };

  const onSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    dispatch(forgotPassword({values, formikHelpers})).then((result) => {
      emailRedirection(result.payload?.status);
    });
  };

  return (
    <div className={classes.emailPasswordReset}>
      <KlaimForm
        customClass={classes.formContainer}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <div className={classes.headerContainer}>
          <h1>Update your password</h1>
          <p className={classes.message}>
            Use a valid email address that has an account with Klaim so we can send you instructions.
          </p>
        </div>
        <KlaimInput name="email" label="Email" />
        <div className={classes.questionContainer}>
          <p>
            No need? <KlaimSpan onClick={() => history.push('/auth')}>Login</KlaimSpan>
          </p>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
          <Link to={'/auth'} style={{textDecoration: 'none'}}>
            <KlaimButton>Back</KlaimButton>
          </Link>
          <KlaimButton type="submit">Send me instructions</KlaimButton>
        </div>
      </KlaimForm>
    </div>
  );
};

export default EmailPasswordReset;
