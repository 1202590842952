import {useState} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {get as getStorage} from 'local-storage';

function useApiRequest<T>(
  dispatcher?: (arg0: T) => void,
): [makeRequest: (url: string, body?: any, method?: string, headers?: any) => Promise<T>, loading: boolean] {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const makeRequest = async (url: string, body?: any, method = 'GET', headers?: any) => {
    setLoading(true);
    try {
      let defaultHeaders = {...headers};
      const accessToken = getStorage('accessToken');
      if (accessToken) {
        defaultHeaders = {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
      let apiResponse;
      switch (method) {
        case 'GET':
          apiResponse = await axios.get<T>(process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN + url, {
            headers: defaultHeaders,
          });
          break;
        case 'POST':
          apiResponse = await axios.post<T>(process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN + url, body, {
            headers: defaultHeaders,
          });
          break;
        case 'PUT':
          apiResponse = await axios.put<T>(process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN + url, body, {
            headers: defaultHeaders,
          });
          break;
        default:
          apiResponse = await axios.get<T>(process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN + url);
          break;
      }
      const {data} = apiResponse;
      if (dispatcher) {
        dispatch(dispatcher(data));
      }
      setLoading(false);
      return Promise.resolve(data);
    } catch (error) {
      const {data} = error.response;
      setLoading(false);
      if (data && data.msg) {
        return Promise.reject(data.msg);
      }
      return Promise.reject(new Error('Something went wrong, Error occured').message);
    }
  };

  return [makeRequest, loading];
}

export default useApiRequest;
