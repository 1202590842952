import axios from 'axios';
import {get as getStorage} from 'local-storage';

const urlEndpoint = process.env.REACT_APP_KLAIM_MIDDLEWARE_DOMAIN;

export function restAPIRequest<T>() {
  const request = async (endpoint: string, body?: any, method = 'GET', headers?: any) => {
    try {
      if (urlEndpoint !== '' || urlEndpoint !== null) {
        let defaultHeaders = {...headers};
        const accessToken = getStorage('accessToken');

        if (accessToken) {
          defaultHeaders = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }

        let apiResponse;

        switch (method) {
          case 'GET':
            apiResponse = await axios.get(urlEndpoint + endpoint, {
              headers: defaultHeaders,
            });
            break;
          case 'POST':
            apiResponse = await axios.post<T>(urlEndpoint + endpoint, body, {
              headers: defaultHeaders,
            });
            break;
          case 'PUT':
            apiResponse = await axios.put<T>(urlEndpoint + endpoint, body, {
              headers: defaultHeaders,
            });
            break;
          default:
            apiResponse = await axios.get(urlEndpoint + endpoint);
            break;
        }

        const {data} = apiResponse;

        return Promise.resolve(data);
      }
      return {};
    } catch (error) {
      const {data} = error.response;
      if (data && data.message) {
        return Promise.reject(data.message);
      }
      return Promise.reject(new Error('An unknown error occured. Please try again'));
    }
  };

  return request;
}
