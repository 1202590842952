import React, {FC} from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import Section from './Section';
import vs from '../../../../../assets/vital-signs.jpg';
import {
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
  KlaimTextArea,
  KlaimDatePicker,
} from '../../../../Common/components-v2/KlaimForm';
import {useStylesFromThemeFunction} from './MidTableForm';
import {deleteVitalSigns, patchVitalSigns, postVitalSigns} from '../../../midTableSlice';

const VitalSigns: FC<any> = ({data, providerId}) => {
  const classes = useStylesFromThemeFunction();
  const init = data?.vitalSigns[0] ? data?.vitalSigns[0] : [{}];
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    if (data?.vitalSigns[0]?.id) {
      dispatch(
        patchVitalSigns({
          payload: {...values, providerId: `${providerId}`},
          claimId: data.id,
          id: data.vitalSigns[0].id,
        }),
      );
    } else {
      dispatch(postVitalSigns({payload: {...values, providerId: `${providerId}`}, id: data.id}));
    }
  };

  const DATE_FORMAT = `DD MMM 'YY`;
  const formatDate = (date) => (date ? dayjs(date).format(DATE_FORMAT) : null);

  return (
    <Section
      title={'Vital Signs'}
      img={vs}
      headerRight={
        <div className={classes.buttonsRow}>
          <button
            className={classes.delete}
            onClick={() => {
              dispatch(deleteVitalSigns({id: data.id}));
            }}
          >
            Delete
          </button>
          {data?.vitalSigns?.id ? (
            <button className={classes.update} form={'vital-form'}>
              Update
            </button>
          ) : (
            <button className={classes.save} form={'vital-form'}>
              Save
            </button>
          )}
        </div>
      }
    >
      <KlaimForm
        enableReinitialize
        initialValues={{
          significantSign: init.significantSign,
          chiefComplaint: init.chiefComplaint,
          internalNotes: init.internalNotes,
          temperature: init.temperature,
          respiratoryRate: init.respiratoryRate,
          bloodPressure: init.bloodPressure,
          height: init.height,
          weight: init.weight,
          pulse: init.pulse,
          durationOfIllness: init.durationOfIllness,
          lmp: new Date(init.lmp || null),
        }}
        validationSchema={Yup.object({
          significantSign: Yup.string(),
          chiefComplaint: Yup.string(),
          internalNotes: Yup.string(),
          temperature: Yup.number(),
          respiratoryRate: Yup.string(),
          bloodPressure: Yup.string(),
          height: Yup.string(),
          weight: Yup.string(),
          pulse: Yup.string(),
          durationOfIllness: Yup.string(),
          lmp: Yup.string(),
        })}
        onSubmit={handleSubmit}
        id="vital-form"
        customClass={classes.form}
      >
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            onChange={() => {}}
            className={classes.respFieldWrapper}
            name={'durationOfIllness'}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Duration of Stay" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="temperature"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            type={'number'}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="TEMP" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="bloodPressure" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="" label="Blood Pressure" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="weight"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Weight" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="height"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Height" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="pulse"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimInput variant="secondary" placeholder="" label="Pulse" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="respiratoryRate" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimInput variant="secondary" placeholder="" label="Resp Rate" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name="lmp"
            onChange={() => {}}
            className={classes.respFieldWrapper}
            style={{flex: '.5 1 1%'}}
          >
            <KlaimDatePicker label="LMP" variant="secondary" name="lmp" maxDate={new Date()} />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          <KlaimFieldWrapper name="chiefComplaint" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimTextArea label="Chief Complaint & Main Symptoms" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper name="significantSign" onChange={() => {}} className={classes.respFieldWrapper}>
            <KlaimTextArea label="Significant Signs" />
          </KlaimFieldWrapper>
        </div>
      </KlaimForm>
    </Section>
  );
};

export default VitalSigns;
