import React from 'react';
import {ErrorMessage, Field, FieldProps} from 'formik';
import {
  ComponentProps,
  useStylesFromThemeFunction,
  customStyles,
} from 'modules/Common/components/KlaimSearchSelect/KlaimSearchSelect';
import Select from 'react-select';

const KlaimSearchSelect: React.FC<ComponentProps> = ({
  label,
  name,
  placeholder,
  containerClassName,
  errorClassName,
  optional = false,
  defaultOptions = [],
  className,
}) => {
  const classes = useStylesFromThemeFunction();

  const isValidated = (error: boolean, touched: any) => {
    return error && touched ? classes.error : classes.validated;
  };

  return (
    <Field name={name}>
      {({field, meta, form}: FieldProps) => {
        return (
          <div
            className={`${classes.contentContainer} ${containerClassName} ${
              meta.touched ? isValidated(meta.error !== undefined, meta.touched) : ''
            }
            `}
          >
            {label && (
              <label className={classes.label} htmlFor={name}>
                {label}
                {optional && <span>(Optional)</span>}
              </label>
            )}
            <Select
              classNamePrefix="searchSelect"
              {...field}
              styles={customStyles}
              isSearchable
              name={name}
              options={defaultOptions}
              className={className}
              placeholder={placeholder}
              value={defaultOptions.find((o: any) => o.value === field.value)}
              onChange={(option) => {
                form.setFieldValue(name, option.value);
              }}
              onBlur={() => form.setFieldTouched(field.name)}
            />
            <ErrorMessage name={name}>
              {(errorMessage) => (
                <div className={errorClassName !== undefined ? errorClassName : classes.error}>{errorMessage}</div>
              )}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
};

export default KlaimSearchSelect;
