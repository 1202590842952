import {combineReducers} from '@reduxjs/toolkit';
import authReducer from './features/Auth/authSlice';
import forgotPasswordReducer from './features/Forgot/forgotPasswordSlice';
import loadingReducer from './features/Loading/loadingSlice';
import loginReducer from './features/Login/loginSlice';
import registerReducer from './features/Register/registerSlice';
import inviteReducer from './features/Invite/inviteSlice';

export default combineReducers({
  login: loginReducer,
  register: registerReducer,
  forgotPassword: forgotPasswordReducer,
  loading: loadingReducer,
  auth: authReducer,
  invite: inviteReducer,
});
