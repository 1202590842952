import {Colors} from 'modules/Common/constants/Colors';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },
    genderIcon: {
      marginRight: 20,
    },
    genderIconTd: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      fontWeight: theme.font.weightSemibold,
    },
    paginationContainer: {
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
    },
    paginationContainer2: {
      paddingBottom: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: `${Colors.white}`,
    },
    patientListContainer: {
      background: 'white',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tableHeading: {
      marginBottom: 0,
      marginTop: 0,
    },
    tableWrapper: {
      flexGrow: 1,
    },
    totalAmountText: {
      marginBottom: 0,
      marginTop: 0,
    },
    viewPatientBtn: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
    },
    viewPatientText: {
      fontWeight: theme.font.weightSemibold,
      marginLeft: 10,
    },
  };
});
