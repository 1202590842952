import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useStylesFromThemeFunction} from '../../styles';
import KlaimDropdownContainer from '../../../../../Common/components/KlaimDropdownContainer';
import moreIcon from '../../../../../../assets/more_icon_2.svg';
import {contractStatues} from '../../../Contracts/constants';

const Component: React.FC<any> = ({insurance, getProviderIds, branches, dispatch, onDeleteItem}) => {
  const history = useHistory();

  const [mappedBranches, setMappedBranches] = useState(null);

  useEffect(() => {
    if (branches && branches.length > 0) {
      const map = {};
      branches.forEach((branch) => {
        map[branch.identifier] = branch.name;
      });
      setMappedBranches(map);
    }
  }, [branches]);

  const getControls = (item) => (
    <KlaimDropdownContainer isIconButton icon={moreIcon} position="left">
      <div
        role="button"
        onClick={() => history.push(`/settings/insurances-management/edit/${item._id}`)}
        className={`${s.button} ${s.navLink}`}
      >
        Edit
      </div>
      <div
        role="button"
        className={`${s.button} ${s.navLink}`}
        onClick={() => {
          onDeleteItem(insurance);
        }}
      >
        Delete
      </div>
    </KlaimDropdownContainer>
  );

  const s = useStylesFromThemeFunction();
  return (
    <div className={s.tableRow}>
      <span className={s.value} style={{width: '20%'}}>
        {insurance.tenantId} <br />
        {mappedBranches && mappedBranches[insurance.tenantId]}
      </span>
      <span className={s.value} style={{width: '20%'}}>
        {insurance.longName}
      </span>
      <span className={s.value} style={{width: '35%'}}>
        {insurance.tpas?.map(({longName}) => longName).join(', ')}
      </span>
      <span className={s.value} style={{width: '5%'}}>
        <span
          onClick={() => {}}
          role={'button'}
          className={s.statuse}
          style={{
            cursor: 'pointer',
            backgroundColor: contractStatues[insurance.hasTpa?.toString()]?.bg,
            color: contractStatues[insurance.hasTpa.toString()]?.text,
          }}
        >
          {contractStatues[insurance.hasTpa?.toString()]?.label}
        </span>
      </span>
      <span
        className={s.label}
        style={{width: '20%', display: 'flex', position: 'relative', justifyContent: 'flex-end'}}
      >
        <span className={s.value}>{getControls(insurance)}</span>
      </span>
    </div>
  );
};

export default Component;
