import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import {Colors} from 'modules/Common/constants/Colors';
import {useStylesFromThemeFunction, MenuItem} from 'modules/Header/features/Navigation/Navigation';
import {useAppSelector} from 'modules/App/store';
import {headerSelect} from 'modules/Header/headerSlice';
import OverviewIcon from 'assets/component/OverviewIcon';
import AgeingIcon from 'assets/component/AgeingIcon';
import CollectionIcon from 'assets/component/CollectionIcon';
import PaymentIcon from 'assets/component/PaymentIcon';
import RejectionIcon from 'assets/component/RejectionIcon';
import ClinicianIcon from 'assets/component/ClinicianIcon';
import {NavigationMenuItem} from 'interfaces/header.interfaces';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';

interface ComponentProps extends RouteComponentProps {
  isButtonHidden: boolean;
}

const Navigation: React.FC<ComponentProps> = ({location, isButtonHidden = false}) => {
  const node = React.useRef<HTMLDivElement>(null);
  const classes = useStylesFromThemeFunction();
  const {navOptions} = useAppSelector(headerSelect);

  const [activeMenu, setActiveMenu] = React.useState<NavigationMenuItem>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<string>('');

  useEffect(() => {
    const result = navOptions.find((item) => item.link === location.pathname);
    if (result) {
      setActiveMenu(result);
      setIsOpen(false);
    } else if (navOptions.length > 0) setActiveMenu(navOptions[0]);
  }, [location.pathname, navOptions]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (node.current !== null) {
      if (node.current?.contains(e.target)) {
        return;
      }
    }
    setIsOpen(false);
  };

  const isActive = (title: string) => {
    return (activeMenu && activeMenu.title) === title;
  };

  const Icon = ({icon, fill}: {icon: any; fill?: string}) => {
    switch (icon) {
      case 'overview': {
        return <OverviewIcon fill={fill} />;
      }
      case 'rejection': {
        return <RejectionIcon fill={fill} />;
      }
      case 'ageing': {
        return <AgeingIcon fill={fill} />;
      }
      case 'payments': {
        return <PaymentIcon fill={fill} />;
      }
      case 'clinicians': {
        return <ClinicianIcon fill={fill} />;
      }
      case 'collection': {
        return <CollectionIcon fill={fill} />;
      }
      default:
        return null;
    }
  };

  return (
    <div
      className={classes.dropdownListContainer}
      ref={node}
      onMouseEnter={() => {
        if (!isButtonHidden) setIsOpen(true);
      }}
    >
      <div className={classes.dropdownButtonContainer}>
        <div className={classes.buttonTextContainer}>
          {activeMenu && (
            <>
              <div className={classes.leftIconContainer}>
                {activeMenu.leftIcon && <Icon icon={activeMenu.leftIcon} />}
              </div>
              {activeMenu.title}
            </>
          )}
        </div>
        {!isButtonHidden && (
          <KlaimIconButton className={classes.button} onClick={() => setIsOpen(!isOpen)}>
            <ChevronArrowDown fill={Colors.white} />
          </KlaimIconButton>
        )}
      </div>
      {isOpen && (
        <div
          className={classes.dropdownListChildContainer}
          onMouseLeave={() => {
            setIsOpen(false);
          }}
        >
          {navOptions.map((item) => {
            if (!item.isHidden) {
              return (
                <div className={classes.dropdownLink} key={item.title}>
                  <Link to={item.link}>
                    <div
                      className={hover === item.title ? classes.hoverLink : classes.linkContainer}
                      onMouseLeave={() => {
                        setHover('false');
                      }}
                      onMouseEnter={() => setHover(item.title)}
                    >
                      {item.leftIcon && (
                        <div className={classes.leftIconContainer}>
                          <Icon icon={item.leftIcon} fill={isActive(item.title) ? Colors.purple : undefined} />
                        </div>
                      )}
                      <span className={`${isActive(item.title) && classes.activeLink}`}>{item.title}</span>
                    </div>
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default withRouter(Navigation);
