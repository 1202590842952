import React from 'react';
import {StepperContext} from '../KlaimStepper/index';

interface ComponentProps {
  step?: number;
}

const KlaimStepperStep: React.FC<ComponentProps> = ({children, step: childStep}) => {
  const {step} = React.useContext(StepperContext);
  return step === childStep ? <div>{children}</div> : null;
};

export default KlaimStepperStep;
