import {Dispatch} from 'react';
import {ReportsApi} from 'api';
import {ReportingContextState, setReportsLoading, storeReports} from './reportingReducer';

// eslint-disable-next-line import/prefer-default-export
export const filterReports = (providerIds: string, type: 'claim' | 'prior-auth' | 'midtable') => async (
  dispatch: Dispatch<any>,
  getState: () => ReportingContextState,
) => {
  dispatch(setReportsLoading(true));
  try {
    const result = await ReportsApi.getReports(providerIds, type);
    dispatch(storeReports(result));
  } catch (error) {
    dispatch(storeReports([]));
  } finally {
    dispatch(setReportsLoading(false));
  }
};
