import React, {FC} from 'react';
import {useStylesFromThemeFunction} from './MidTableForm';

const Section: FC<any> = ({title, img, children, headerRight}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeader}>
        <div className={classes.div}>
          <img alt={'doctor'} src={img} className={classes.sectionIcon} />
          <span className={classes.sectionTitle}>{title}</span>
        </div>
        {headerRight}
      </div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};

export default Section;
