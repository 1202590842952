import React from 'react';
import {useAppSelector} from 'modules/App/store';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {useTranslation} from 'react-i18next';
import SideMenuLink from 'modules/Common/components/SideMenuLink';
import activeEligibilityMenuIcon from 'assets/active_eligibility_menu_icon.svg';
import eligibilityMenuIcon from 'assets/eligibility_menu_icon.svg';
import activeAppointmentsMenuIcon from 'assets/active_appointments_menu_icon.svg';
import appointmentsMenuIcon from 'assets/appointments_menu_icon.svg';
import klaimLogo from 'assets/klaim_logo.svg';
import dashboardMenuIcon from 'assets/dashboard_menu_icon.svg';
import activeDashboardMenuIcon from 'assets/active_dashboard_menu_icon.svg';
import claimsMenuIcon from 'assets/claims_menu_icon.svg';
import activeClaimsMenuIcon from 'assets/active_claims_menu_icon.svg';
import pharmacyMenuIcon from 'assets/pharmacy_menu_icon.svg';
import reportMenuIcon from 'assets/report_menu_icon.svg';
import activeReportMenuIcon from 'assets/active_report_menu_icon.svg';
import activePharmacyMenuIcon from 'assets/active_pharmacy_menu_icon.svg';
import activePreAuthMenuIcon from 'assets/active_preauth_menu_icon.svg';
import activePatientsMenuIcon from 'assets/active_patient_menu_icon.svg';
import patientsMenuIcon from 'assets/patient_menu_icon.svg';
import preAuthMenuIcon from 'assets/preauth_menu_icon.svg';
import activeBillingIcon from 'assets/active_billing_icon.svg';
import billingIcon from 'assets/billing_icon.svg';
import settingsIcon from 'assets/settings_icon.svg';
import activeSettingsIcon from 'assets/active_settings_icon.svg';
import notificationIcon from 'assets/notification_icon.svg';
import activeNotificationIcon from 'assets/active_notification_icon.svg';
import ChevronArrowDown from 'assets/component/ChevronArrowDown';
import kLogo from 'assets/klaim_logo_k.svg';
import {modules} from 'modules/Common/constants/Permissions';
import IsAuthorized from 'modules/Auth/v3/features/IsAuthorized';
import {useStylesFromThemeFunction, SideMenuNav} from './Sidebar';
import UserProfile from './UserProfile';

interface ComponentProps {
  handleResize: () => void;
  sidebarIsOpen: boolean;
  setSidebarIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<ComponentProps> = ({sidebarIsOpen, setSidebarIsOpen, handleResize}) => {
  const {t} = useTranslation();
  const {user, isClaimAllowed} = useAppSelector(authSelect);
  const classes = useStylesFromThemeFunction();

  const sideMenuNavList: SideMenuNav[] = [
    // {
    //   link: '/dashboard/overview',
    //   title: 'Dashboard',
    //   baseLink: '/dashboard',
    //   activeIcon: activeDashboardMenuIcon,
    //   icon: dashboardMenuIcon,
    //   requiredModule: modules.dashboard,
    //   countries: process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES
    //     ? process.env.REACT_APP_DASHBOARDS_AVAILABLE_COUNTRIES.split(',')
    //     : [],
    // },
  ];

  sideMenuNavList.push({
    link: '/eligibility',
    title: t('Eligibility'),
    activeIcon: activeEligibilityMenuIcon,
    icon: eligibilityMenuIcon,
    countries: ['KSA'],
  });

  sideMenuNavList.push({
    link: '/pre-auth',
    title: 'Pre-Auth.',
    activeIcon: activePreAuthMenuIcon,
    icon: preAuthMenuIcon,
    countries: ['KSA'],
    isHidden: false,
  });
  sideMenuNavList.push({
    link: '/report',
    title: t('Reports'),
    activeIcon: activeReportMenuIcon,
    icon: reportMenuIcon,
    requiredModule: modules.reports,
    countries: process.env.REACT_APP_REPORTS_AVAILABLE_COUNTRIES
      ? process.env.REACT_APP_REPORTS_AVAILABLE_COUNTRIES.split(',')
      : [],
  });
  if (isClaimAllowed) {
    sideMenuNavList.push({
      link: '/claims',
      title: t('Claims'),
      activeIcon: activeClaimsMenuIcon,
      icon: claimsMenuIcon,
      countries: process.env.REACT_APP_CLAIMS_AVAILABLE_COUNTRIES
        ? [...process.env.REACT_APP_CLAIMS_AVAILABLE_COUNTRIES.split(','), 'KSA']
        : [],
      requiredModule: modules.claims,
    });
  }
  sideMenuNavList.push({
    link: '/pharmacy',
    title: t('Pharmacy'),
    activeIcon: activePharmacyMenuIcon,
    icon: pharmacyMenuIcon,
    countries: process.env.REACT_APP_PHARMACY_AVAILABLE_COUNTRIES
      ? process.env.REACT_APP_PHARMACY_AVAILABLE_COUNTRIES.split(',')
      : [],
    requiredModule: modules.pharmacy,
  });
  sideMenuNavList.push({
    link: '/payments',
    title: t('Payment Reconciliation'),
    activeIcon: activeReportMenuIcon,
    icon: reportMenuIcon,
    requiredModule: modules.payments,
    countries: process.env.REACT_APP_PAYMENTS_AVAILABLE_COUNTRIES
      ? process.env.REACT_APP_PAYMENTS_AVAILABLE_COUNTRIES.split(',')
      : [],
  });
  sideMenuNavList.push({
    link: '/midtable',
    title: t('MidTable'),
    activeIcon: activeReportMenuIcon,
    icon: reportMenuIcon,
    requiredModule: modules.payments,
    countries: process.env.REACT_APP_MIDTABLE_AVAILABLE_COUNTRIES
      ? process.env.REACT_APP_MIDTABLE_AVAILABLE_COUNTRIES.split(',')
      : [],
  });
  sideMenuNavList.push({
    link: '/appointments',
    title: t('Appointments'),
    activeIcon: activeAppointmentsMenuIcon,
    icon: appointmentsMenuIcon,
    isHidden: true,
    countries: [],
  });
  sideMenuNavList.push({
    link: '/appointments',
    title: t('Appointments'),
    activeIcon: activeAppointmentsMenuIcon,
    icon: appointmentsMenuIcon,
    isHidden: true,
    countries: [],
  });
  sideMenuNavList.push({
    link: '/patients',
    title: 'Patients',
    activeIcon: activePatientsMenuIcon,
    icon: patientsMenuIcon,
    countries: ['KSA'],
  });
  sideMenuNavList.push({
    link: '/reporting',
    title: t('Reporting'),
    activeIcon: activeReportMenuIcon,
    icon: reportMenuIcon,
    requiredModule: modules.payments,
    countries: process.env.REACT_APP_MIDTABLE_AVAILABLE_COUNTRIES
      ? process.env.REACT_APP_MIDTABLE_AVAILABLE_COUNTRIES.split(',')
      : [],
  });
  sideMenuNavList.push({
    link: '/billing',
    title: t('Billing'),
    activeIcon: activeBillingIcon,
    icon: billingIcon,
    isHidden: true,
    countries: [],
  });
  sideMenuNavList.push({
    link: '/notification',
    title: 'Notification',
    activeIcon: activeNotificationIcon,
    icon: notificationIcon,
    isHidden: true,
    countries: [],
  });
  sideMenuNavList.push({
    link: '/settings',
    title: 'Settings',
    activeIcon: activeSettingsIcon,
    icon: settingsIcon,
    isHidden: true,
    countries: [],
  });

  return (
    <div id="side-bar" className={classes.container}>
      <div className={`${classes.sidebar} ${sidebarIsOpen ? '' : classes.sidebarClose}`}>
        <div className={sidebarIsOpen ? classes.sidebarLogoContainer : classes.sidebarLogoContainerClose}>
          <img src={sidebarIsOpen ? klaimLogo : kLogo} alt="logo" />
        </div>
        <div
          role="button"
          onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
          className={`${classes.caretContainer} ${
            sidebarIsOpen ? classes.caretContainerOpen : classes.caretContainerClose
          }`}
        >
          <ChevronArrowDown className={`${classes.caret} ${sidebarIsOpen ? classes.caretOpen : classes.caretClose}`} />
        </div>
        <div className={classes.sideMenuWrapper}>
          <div>
            {sideMenuNavList.map(
              ({link, title, activeIcon, icon, subMenus, baseLink, isHidden, countries, requiredModule}, index) => {
                if (countries.filter((country) => country === user.organization.country).length === 0 || isHidden)
                  return null;

                return (
                  <IsAuthorized requiredCountries={countries} requiredModule={requiredModule} key={title}>
                    <SideMenuLink
                      key={index}
                      sidebarIsOpen={sidebarIsOpen}
                      link={link}
                      title={t(title)}
                      activeIcon={activeIcon}
                      icon={icon}
                    />
                  </IsAuthorized>
                );
              },
            )}
          </div>
        </div>
      </div>
      <UserProfile isOpen={sidebarIsOpen} />
    </div>
  );
};

export default Sidebar;
