import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {FC} from 'react';
import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    klaimBtn: {
      '&:hover': {
        cursor: 'pointer',
      },
      alignItems: 'center',
      background: '#6135fb',
      border: 'none',
      borderRadius: 10,
      color: 'white',
      display: 'flex',
      fontWeight: theme.font.weightSemibold,
      justifyContent: 'center',
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
    },
    klaimBtnDisabled: {
      background: '#d3c6fe',
    },
    klaimBtnIconLeft: {
      marginRight: 20,
    },
    klaimBtnIconMode: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    klaimBtnLoading: {
      background: '#dfd7fb',
    },
    klaimBtnOutlined: {
      background: 'white',
      border: 'solid 1px #6135fb',
      color: '#6135fb',
      '&:hover': {
        opacity: 0.8,
      },
    },
  };
});

interface ComponentProps {
  title?: string;
  onClick?: () => void;
  buttonClassName?: string;
  loading?: boolean;
  iconLeft?: any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  theme?: KlaimTheme;
  variant?: 'default' | 'outlined';
}

const Button: FC<ComponentProps> = ({
  title,
  onClick,
  buttonClassName,
  loading,
  iconLeft,
  disabled = false,
  type,
  theme,
  variant,
}) => {
  const classes = useStylesFromThemeFunction({theme});
  return (
    <button
      type={type}
      className={`${classes.klaimBtn} ${disabled && classes.klaimBtnDisabled} ${loading && classes.klaimBtnLoading} ${
        iconLeft && classes.klaimBtnIconMode
      } ${buttonClassName} ${variant === 'outlined' && classes.klaimBtnOutlined}`}
      onClick={() => !disabled && onClick && onClick()}
    >
      {iconLeft && <img src={iconLeft} alt="icon-left" className={classes.klaimBtnIconLeft} />}
      {!loading && title ? title : null}
      {loading && <LoadingSpinner />}
    </button>
  );
};

export default Button;
