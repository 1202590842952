import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'modules/App/store';
import {EL} from '@klaim-ai/klaim-interfaces';
import axiosInstance from 'services/axios';
import {actions} from 'services/mixpanel';

interface InitialState {
  isLoading: boolean;
  eligibilities: {
    data: EL.Model.Eligibility[];
    count: number;
  };
  eligibility: EL.Model.Eligibility | undefined;
  editEligibility: EL.Model.Eligibility | undefined;
  isEligibilityCheckSuccess: boolean;
  error?: MyKnownError;
}

const initialState: InitialState = {
  isLoading: false,
  eligibilities: {
    data: [],
    count: 0,
  },
  eligibility: undefined,
  editEligibility: undefined,
  isEligibilityCheckSuccess: false,
};

interface ErrorMessageObject {
  property: string;
  constraints: {
    matches: string;
  };
}

interface MyKnownError {
  stack?: {
    error: {
      message: string;
    };
    message?: ErrorMessageObject[] | string;
    response?: {
      error?: {
        message?: string;
        code?: string;
      };
    };
  };
  statusCode?: number;
  status: number;
  message?: string;
}

interface EligibilityQuery {
  limit: number;
  offset: number;
  search?: string;
  sortBy?: string;
  providerId?: string;
}

interface EligibilityData {
  data: EL.Model.Eligibility[];
  count: number;
  limit: number;
  skip: number;
}

interface EligibilityID {
  id: string;
  providerId: string;
}

const serverErrorMsg = 'Internal Server Error';

export const getEligibilities = createAsyncThunk<EligibilityData, EligibilityQuery, {rejectValue: MyKnownError}>(
  'eligibility/getEligibilities',
  async (query: EligibilityQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/eligibility', {params: query});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const checkEligibility = createAsyncThunk<EL.Model.Eligibility, any, {rejectValue: MyKnownError}>(
  'eligibility/checkEligibility',
  async ({providerId, ...data}: any, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post('/eligibility', {...data, providerId});
      return response.data;
    } catch (err: any) {
      console.log('err.response.data', err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getEligibility = createAsyncThunk<EL.Model.Eligibility, EligibilityID, {rejectValue: MyKnownError}>(
  'eligibility/getEligibility',
  async ({id, ...params}: EligibilityID, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/eligibility/${id}`, {params});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getEligibilityForm = createAsyncThunk<EL.Model.Eligibility, EligibilityID, {rejectValue: MyKnownError}>(
  'eligibility/getEligibilityForm',
  async ({id, ...params}: EligibilityID, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/eligibility/${id}`, {params});
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const eligibilitySlice = createSlice({
  name: 'eligibility',
  reducers: {
    eligibilityCoverageCleanUp: (state) => {
      state.eligibility = undefined;
    },
    eligibilityCleanup: (state) => {
      state.eligibility = undefined;
      state.editEligibility = undefined;
    },
    eligibilitySuccessCleanup: (state) => {
      state.isEligibilityCheckSuccess = false;
    },
    eligibilityErrorCleanup: (state) => {
      state.error = undefined;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getEligibilities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEligibilities.fulfilled, (state, action) => {
        state.eligibilities = action.payload;
        state.isLoading = false;
      })
      .addCase(getEligibilities.rejected, (state) => {
        state.error = {message: serverErrorMsg, status: 500};
        state.isLoading = false;
      });
    builder
      .addCase(checkEligibility.pending, (state) => {
        state.isLoading = true;
        state.isEligibilityCheckSuccess = false;
      })
      .addCase(checkEligibility.fulfilled, (state, action) => {
        state.eligibility = action.payload;
        state.isLoading = false;
        state.isEligibilityCheckSuccess = true;
      })
      .addCase(checkEligibility.rejected, (state, action) => {
        const message = action?.payload?.stack?.response?.error?.message;
        state.error = {message: message ?? serverErrorMsg, status: action?.payload?.status ?? 500};
        state.isLoading = false;
        state.isEligibilityCheckSuccess = true;
      });
    builder
      .addCase(getEligibility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEligibility.fulfilled, (state, action) => {
        state.eligibility = action.payload;
        state.isLoading = false;
      })
      .addCase(getEligibility.rejected, (state) => {
        state.error = {message: serverErrorMsg, status: 500};
        state.isLoading = false;
      });
    builder
      .addCase(getEligibilityForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEligibilityForm.fulfilled, (state, action) => {
        state.editEligibility = action.payload;
        state.isLoading = false;
      })
      .addCase(getEligibilityForm.rejected, (state) => {
        state.error = {message: 'Something went Wrong', status: 500};
        state.isLoading = false;
      });
  },
});

export const {
  eligibilityCleanup,
  eligibilityCoverageCleanUp,
  eligibilitySuccessCleanup,
  eligibilityErrorCleanup,
} = eligibilitySlice.actions;
export const eligibilitySelect = (state: RootState) => state.eligibility;
export default eligibilitySlice.reducer;
