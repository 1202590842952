import React from 'react';
import * as Yup from 'yup';
import {FormikHelpers} from 'formik';
import {useHistory} from 'react-router-dom';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Login/Login';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Button from 'modules/Common/components/Button';
import KlaimCheckbox from 'modules/Common/components/KlaimCheckbox';
import {useAppDispatch} from 'modules/App/store';
import {errorCleanUp, login, refresh, loginSelect} from 'modules/Auth/v3/features/Login/loginSlice';
import queryString from 'query-string';

export interface Values {
  [field: string]: any;
}

const Login: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [refreshing, setRefreshing] = React.useState(false);
  const qs = queryString.parse(history.location.search);
  const accessToken = qs.access_token;

  React.useEffect(() => {
    return () => {
      dispatch(errorCleanUp());
    };
  }, []);

  React.useEffect(() => {
    if (accessToken && !refreshing) {
      dispatch(refresh(accessToken));
      setRefreshing(true);
    }
  }, [refreshing]);

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => {
    dispatch(login(values));
  };

  return (
    <div className={classes.signin}>
      <KlaimForm
        customClass={classes.formContainer}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <div className={classes.headerContainer}>
          <h1>Sign in</h1>
          <p>Login with your Klaim credentials.</p>
        </div>
        <KlaimInput name="email" label="Email" placeholder="your@email.com" />
        <KlaimInput name="password" label="Password" type="password" showTooltip />
        <div className={classes.questionContainer}>
          <KlaimSpan onClick={() => history.push('/auth/update-password')}>Forgot Password?</KlaimSpan>
          <p>
            New User? <KlaimSpan onClick={() => history.push('/auth/create-account')}>Create an account</KlaimSpan>
          </p>
        </div>
        <div className={classes.footerContainer}>
          <KlaimCheckbox label="Remember me" name="rememberMe" />
          <Button title="Login" type="submit" />
        </div>
      </KlaimForm>
    </div>
  );
};

export default Login;
