import React, {FC, useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import {IRoute} from 'interfaces/route.interface';
import Header from 'modules/Header';
import {setRequiredFilters, setNavOptions} from 'modules/Header/headerSlice';
import {useAppDispatch} from 'modules/App/store';
import {NavigationMenuItem} from 'interfaces/header.interfaces';
import {useStylesFromThemeFunction} from './Payments';
import RouteWithSubRoutes from '../Route/RouteWithSubRoutes';

const Payments: FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const paymentsOptions: NavigationMenuItem[] = [
    {title: 'Payment List', link: '/payments'},
    {title: 'Payment Details', link: '/payments/details', isHidden: true},
  ];

  const userBranches = () => {
    const branches = localStorage.getItem('userBranches');
    return branches != null;
  };

  useEffect(() => {
    if (location.pathname === `/payments`)
      dispatch(
        setRequiredFilters({
          navigation: true,
          search: true,
          dateRangeFilterDays: true,
          branchesFilter: true,
          insurersFilter: true,
        }),
      );

    dispatch(
      setNavOptions({
        navOptions: paymentsOptions,
      }),
    );
  }, [location]);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={!userBranches() ? classes.disabled : ''}>
          <Header />
        </div>
        <Switch>
          {routes &&
            routes.map(({routeKey, ...route}, index) => {
              return <RouteWithSubRoutes routeKey={routeKey} {...route} key={index} />;
            })}
        </Switch>
      </div>
    </div>
  );
};

export default Payments;
