import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    bodyWrapper: {
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        marginBottom: '28px',
        width: '450px',
      },
      width: '60%',
    },
    staffManagementContainer: {
      padding: '10px',
    },
    photoUploadContainer: {
      padding: '10px',
      '& span': {
        fontWeight: 'bold',
        fontSize: '12px',
      },
    },
    errorMessage: {
      padding: '10px',
      color: 'red',
    },
    selectDiv: {padding: 10},
    select: {
      padding: 0,
      '& .klaimSelectLabel': {
        fontSize: 13,
        fontWeight: 'bold',
      },
    },
  };
});
