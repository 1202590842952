import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface SideMenuNav {
  link: string;
  title: string;
  baseLink?: string;
  activeIcon?: string;
  icon?: string;
  permission?: string;
  countries: string[];
  subMenus?: SideMenuNav[];
  isHidden?: boolean;
  requiredModule?: string;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      height: '100vh',
      display: 'flex',
      position: 'fixed',
      flexDirection: 'column',
      boxShadow: `${Colors.RMSC.shadow} 1px 5px 20px`,
      zIndex: 50,
      background: Colors.white,
    },
    sidebar: {
      flex: '1 1 0%',
      padding: 10,
      isolation: 'isolate',
      width: 206,
      overflowWrap: 'break-word',
      transition: [['width', '0.3s', 'ease']],
    },
    sidebarLogoContainer: {
      display: 'flex',
      marginLeft: 50,
      transition: [['all', '0.3s', 'ease']],
      marginTop: 25,
    },
    sidebarLogoContainerClose: {
      display: 'flex',
      marginLeft: 25,
      transition: [['all', '0.3s', 'ease']],
      marginTop: 25,
    },
    sideMenuWrapper: {
      marginTop: 50,
      marginBottom: 30,
      display: 'flex',
      flex: '1 1 0%',
      flexDirection: 'column',
      overflowY: 'scroll',
      overflowX: 'hidden',
      paddingLeft: '10px',
      paddingRight: '10px',
      height: '100%',
      justifyContent: 'space-between',
    },
    sideMenuSubItem: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '15px',
      marginBottom: '35px',
    },
    caret: {
      width: 10,
      height: 10,
    },
    caretContainer: {
      borderTopRightRadius: 99,
      borderBottomRightRadius: 99,
      background: Colors.white,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 10,
      boxShadow: `${Colors.RMSC.shadow} 1px 5px 20px`,
      paddingRight: 10,
      borderLeft: `1px solid ${Colors.grayLight}`,
      position: 'absolute',
      display: 'flex',
      alignItems: ' center',
      '&:hover': {
        background: Colors.blueGrayLight,
      },
    },
    caretContainerOpen: {
      top: 105,
      left: 206,
      transition: [['all', '0.3s', 'ease']],
    },
    caretContainerClose: {
      top: 105,
      left: 90,
      transition: [['all', '0.3s', 'ease']],
    },
    caretOpen: {
      transform: 'rotate(90deg)',
      transition: [['all', '0.3s', 'ease']],
    },
    caretClose: {
      transform: 'rotate(-90deg)',
      transition: [['all', '0.3s', 'ease']],
    },
    sidebarOpen: {
      width: 'auto',
    },
    sidebarClose: {
      width: 90,
      '& p': {
        display: 'none',
      },
      transition: [['width', '0.3s', 'ease']],
    },
  };
});
