import logoBackground from 'assets/logo_background.svg';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles({
  '@keyframes bluebar': {
    '50%': {
      left: '250px',
    },
  },
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  loadingAnimation: {},
  logoContainer: {
    backgroundImage: `url(${logoBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    width: '300px',
    height: '280px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    position: 'absolute',
    height: '90px',
    width: '150px',
  },
  loadingBar: {
    '&:before': {
      content: '""',
      width: '100px',
      height: '2px',
      background: '#0073b1',
      position: 'absolute',
      left: ' -34px',
      animation: '$bluebar 1.5s infinite ease',
    },
    width: '100%',
    height: '2px',
    background: '#cfcfcf',
    marginTop: '22px',
    position: 'relative',
    overflow: 'hidden',
  },
});
