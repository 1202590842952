import React from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';
import SearchIcon from 'assets/component/SearchIcon';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import CloseIcon from 'assets/component/CloseIcon';
import {KlaimIconButton} from 'modules/Common/components-v2/KlaimForm';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    querySearchContainer: {
      marginLeft: 10,
      marginRight: 10,
      border: `${theme.container.border.secondary} 1px solid`,
      borderRadius: 10,
      background: theme.container.background.secondary,
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      paddingRight: 10,
      maxWidth: 400,
      width: '100%',
      gap: 5,
    },
    querySearchInputContainer: {
      border: 'none',
      width: 'auto',
      overflow: 'hidden',
      display: 'flex',
      background: theme.container.background.secondary,
      fontSize: 15,
      flex: '1 1 0%',
      '&:focus': {
        outline: 'none',
      },
    },
  };
});

const useQueryRoute = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchQuery: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const query = useQueryRoute();

  const [searchTyping, setSearchTyping] = React.useState<string>('');

  React.useEffect(() => {
    const delayFn = setTimeout(() => {
      if (searchTyping) {
        query.delete('search');
        query.append('search', searchTyping || '');
      } else {
        query.delete('search');
      }
      history.push({search: query.toString()});
    }, 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTyping(e.currentTarget.value);
  };
  return (
    <div className={classes.querySearchContainer} id="search-input-query">
      <SearchIcon />
      <input
        placeholder="Search "
        onChange={(e) => onChange(e)}
        value={searchTyping}
        className={classes.querySearchInputContainer}
      />
      {!_.isEmpty(searchTyping) && (
        <KlaimIconButton onClick={() => setSearchTyping('')}>
          <CloseIcon />
        </KlaimIconButton>
      )}
    </div>
  );
};

export default SearchQuery;
