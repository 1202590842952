import {FC} from 'react';
import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    marginLeft: {
      marginLeft: '-10px',
    },
    marginRight: {
      marginRight: '8px',
    },
  };
});

interface ComponentProps {
  imageSrc: any;
}

const StaffProfile: FC<ComponentProps> = ({imageSrc}) => {
  const classes = useStylesFromThemeFunction();
  const staffCount = imageSrc.length;

  return imageSrc.map((img: any, index: any) => {
    return <img key={index} className={`${index > 0 ? classes.marginLeft : ''}`} src={img} alt={`img-${index}`} />;
  });
};

export default StaffProfile;
