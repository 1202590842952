import React from 'react';
import {Row, Col} from 'react-grid-system';
import {EL} from '@klaim-ai/klaim-interfaces';

import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import PatientDetail from '../../../../../Patient/components/PatientDetails';

interface ComponentProps {
  eligibility?: EL.Model.Eligibility;
}

const Patient: React.FC<ComponentProps> = ({eligibility}) => {
  const patient = eligibility?.patient;
  return (
    <PatientDetail patient={eligibility?.patient}>
      <KlaimValue direction="column" label={`ID Number`} value={patient?.documentId} />
      <KlaimValue direction="column" label={`Klaim ID`} value={patient?.id} />
    </PatientDetail>
  );
};

export default Patient;
