import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from 'services/axios';
import {RootState} from 'modules/App/store';
import {
  HistoriesData,
  HistoriesQuery,
  HistoryQuery,
  CancelQuery,
  IClaimRequest,
  CommunicationRequestQuery,
} from 'interfaces/pre-auth.interface';
import {MyKnownError} from 'interfaces/error-interface';
import {ClaimHistoriesData, ClaimHistory} from '../../interfaces/claim.interface';

interface InitialState {
  histories: {
    data: ClaimHistory[];
    count: number;
  };
  totals: any;
  history?: ClaimHistory;
  isLoading: boolean;
  error: any;
  isCreatePatientSuccess: boolean;
  isUpdatePatientSuccess: boolean;
  isCreateClaimSuccess: boolean;
  isCancellationSuccess: boolean;
  priodClaimResubmitNewId: string;
  isCommunicationRequestSuccess?: any;
  cancelledPA: any;
  communicationRequest: any;
  claim?: ClaimHistory;
  jsonResponse: any;
}

const initialState: InitialState = {
  histories: {
    data: [],
    count: 0,
  },
  totals: {},
  history: undefined,
  claim: undefined,
  isLoading: false,
  error: undefined,
  isCreatePatientSuccess: false,
  isUpdatePatientSuccess: false,
  isCreateClaimSuccess: false,
  isCancellationSuccess: false,
  priodClaimResubmitNewId: '',
  isCommunicationRequestSuccess: false,
  cancelledPA: {},
  communicationRequest: {},
  jsonResponse: {},
};

/* @deprecated */
export const getHistories = createAsyncThunk<ClaimHistoriesData, HistoriesQuery, {rejectValue: MyKnownError}>(
  'claim/getHistories',
  async (query: HistoriesQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/claims', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getClaimsHistory = createAsyncThunk<ClaimHistoriesData, HistoriesQuery, {rejectValue: MyKnownError}>(
  'claimsHistories',
  async (query: HistoriesQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/claims?isShort=true', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const downloadExcel = createAsyncThunk<ClaimHistoriesData, HistoriesQuery, {rejectValue: MyKnownError}>(
  'downloadExcel',
  async (query: HistoriesQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/claims/report', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getJsonResponse = createAsyncThunk<ClaimHistoriesData, any, {rejectValue: MyKnownError}>(
  'getJsonResponse',
  async ({id, ...params}: HistoryQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/claims/${id}/fhirBody`, {params});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getClaimsHistoryTotal = createAsyncThunk<
  Record<string, number>,
  Record<string, unknown>,
  {rejectValue: MyKnownError}
>('getClaimsHistoryTotal', async (query: any, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.get(`/claims/total/amount`, {params: {...query}});
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getHistory = createAsyncThunk<ClaimHistory, HistoryQuery, {rejectValue: MyKnownError}>(
  'claim/getHistory',
  async ({id, ...params}: HistoryQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`/claims/${id}`, {params});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const cancelPA = createAsyncThunk<ClaimHistory, CancelQuery, {rejectValue: MyKnownError}>(
  'claim/cancelPA',
  async (query: CancelQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/claims/cancel`, query.payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const communicationRequest = createAsyncThunk<
  ClaimHistory,
  CommunicationRequestQuery,
  {rejectValue: MyKnownError}
>('claim/communicationRequest', async (query: CommunicationRequestQuery, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.post(`/claims/${query.id}/communication-request`, query.payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createClaim = createAsyncThunk<ClaimHistory, IClaimRequest, {rejectValue: MyKnownError}>(
  'claim/createClaim',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post('/claims', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resubmitClaim = createAsyncThunk<ClaimHistory, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'claim/resubmitClaim',
  async ({id, ...data}, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/claims/${id}/resubmit`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const sendCommRequest = createAsyncThunk<ClaimHistory, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'claim/sendCommRequest',
  async ({id, ...data}, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/claims/${id}/communication-request`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const CancelRequest = createAsyncThunk<ClaimHistory, Record<string, unknown>, {rejectValue: MyKnownError}>(
  'claim/CancelRequest',
  async ({...data}, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/claims/cancel`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    errorPatientCleanup: (state) => {
      state.error = undefined;
    },
    errorClaimCleanup: (state) => {
      state.error = undefined;
    },
    createPatientCleanup: (state) => {
      state.isCreatePatientSuccess = false;
    },
    updatePatientCleanup: (state) => {
      state.isUpdatePatientSuccess = false;
    },
    createClaimCleanup: (state) => {
      state.isCreateClaimSuccess = false;
      state.claim = undefined;
    },
    getClaimCleanup: (state) => {
      state.history = undefined;
      state.jsonResponse = {};
    },
    cancelClaimCleanup: (state) => {
      state.isCancellationSuccess = false;
    },
    communicationRequestCleanup: (state) => {
      state.isCommunicationRequestSuccess = false;
      state.isCancellationSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistories.fulfilled, (state, action) => {
        state.histories = {
          data: action.payload.data.data,
          count: action.payload.data.count,
        };
        state.isLoading = false;
      })
      .addCase(getHistories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getClaimsHistory.fulfilled, (state, action) => {
        state.histories = {
          data: action.payload.data.data,
          count: action.payload.data.count,
        };
        state.isLoading = false;
      })
      .addCase(getClaimsHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClaimsHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(downloadExcel.fulfilled, (state, action) => {
        state.histories = {
          data: action.payload.data.data,
          count: action.payload.data.count,
        };
        state.isLoading = false;
      })
      .addCase(downloadExcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadExcel.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getClaimsHistoryTotal.fulfilled, (state, action) => {
        state.totals = action.payload;
        state.isLoading = false;
      })
      .addCase(getClaimsHistoryTotal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClaimsHistoryTotal.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getHistory.fulfilled, (state, action) => {
        state.history = action.payload;
        state.isLoading = false;
      })
      .addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getJsonResponse.fulfilled, (state, action) => {
        state.jsonResponse = action.payload;
        state.isLoading = false;
      })
      .addCase(getJsonResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJsonResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(cancelPA.fulfilled, (state, action) => {
        state.cancelledPA = action.payload;
        state.isCancellationSuccess = true;
        state.isLoading = false;
      })
      .addCase(cancelPA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelPA.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(resubmitClaim.fulfilled, (state, action) => {
        state.priodClaimResubmitNewId = action.payload.id;
        state.isCancellationSuccess = true;
        state.isLoading = false;
      })
      .addCase(resubmitClaim.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resubmitClaim.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action;
      });
    builder
      .addCase(sendCommRequest.fulfilled, (state, action) => {
        state.isCancellationSuccess = true;
        state.isLoading = false;
      })
      .addCase(sendCommRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendCommRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action;
      });
    builder
      .addCase(CancelRequest.fulfilled, (state, action) => {
        state.isCancellationSuccess = true;
        state.isLoading = false;
      })
      .addCase(CancelRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CancelRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action;
      });
    builder
      .addCase(communicationRequest.fulfilled, (state, action) => {
        state.communicationRequest = action.payload;
        state.isCommunicationRequestSuccess = true;
        state.isLoading = false;
      })
      .addCase(communicationRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(communicationRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createClaim.fulfilled, (state, action) => {
        state.claim = action.payload;
        state.isCreateClaimSuccess = true;
        state.isLoading = false;
      })
      .addCase(createClaim.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createClaim.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateClaimSuccess = false;
        state.error = action.payload.message;
      });
  },
});

export const {
  errorPatientCleanup,
  createPatientCleanup,
  updatePatientCleanup,
  createClaimCleanup,
  getClaimCleanup,
  cancelClaimCleanup,
  communicationRequestCleanup,
  errorClaimCleanup,
} = claimSlice.actions;
export const claimSelect = (state: RootState) => state.claimKsa;
export default claimSlice.reducer;
