import React from 'react';
import * as Yup from 'yup';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import {
  KlaimRadioSelect,
  KlaimDropdownSearch,
  KlaimForm,
  KlaimFieldWrapper,
} from 'modules/Common/components-v2/KlaimForm';
import {diagnosisOnAdmission, diagnosisType} from 'modules/Common/constants/DropdownOptions';
import {get} from 'lodash';
import DiagnosisCodeDropdown from 'modules/Diagnosis/features/DiagnosisCodeDropdown';
import {IDiagnosis} from 'interfaces/pre-auth.interface';
import {ComponentProps, useStylesFromThemeFunction} from './DiagnosisModal';

const DiagnosisModal: React.FC<ComponentProps> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  diagnosisList,
  selectedDiagnosisIndex,
  disableOnAdmission,
}) => {
  const classes = useStylesFromThemeFunction();
  const initialValues: IDiagnosis = {
    diagType: get(diagnosisList, `[${selectedDiagnosisIndex}].diagType`, ''),
    diagOnAdmission: get(diagnosisList, `[${selectedDiagnosisIndex}].diagOnAdmission`, 'y'),
    diagCode: get(diagnosisList, `[${selectedDiagnosisIndex}].diagCode.diagnosisCode`, ''),
  };

  const validationSchema = Yup.object({
    diagType: Yup.string().required('Diagnosis type is required.'),
    diagOnAdmission: Yup.string().required(),
    diagCode: Yup.string().required('Diagnosis is required.'),
  });

  const handleSubmit = (data: any) => {
    console.log(data);
    if (onSubmit) onSubmit(data);
  };

  const getDiagnosisList = () => {
    if (
      diagnosisList.length === 0 ||
      diagnosisList.findIndex((item) => item.diagType === 'principal') === selectedDiagnosisIndex
    )
      return diagnosisType.filter((el) => el.value === 'principal');
    return diagnosisList.findIndex((item) => item.diagType === 'principal') === -1
      ? diagnosisType
      : diagnosisType.filter((el) => el.value !== 'principal');
  };

  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen}>
      <KlaimForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <KlaimModalHeader title="Add Diagnosis" />
        <KlaimModalBody>
          <div className={classes.container}>
            <p>Please fill in the fields below to add new diagnosis.</p>
            {!disableOnAdmission && (
              <KlaimFieldWrapper name={'diagOnAdmission'}>
                <KlaimRadioSelect label="On Admission" selections={diagnosisOnAdmission} />
              </KlaimFieldWrapper>
            )}
            <KlaimFieldWrapper name={'diagType'}>
              <KlaimDropdownSearch label="Select Diagnosis Type" options={getDiagnosisList()} />
            </KlaimFieldWrapper>
            <KlaimFieldWrapper name={'diagCode'}>
              <DiagnosisCodeDropdown authorityCode="NPHIES" />
            </KlaimFieldWrapper>
          </div>
        </KlaimModalBody>
        <KlaimModalFooter buttonType={'submit'} />
      </KlaimForm>
    </KlaimModal>
  );
};

export default DiagnosisModal;
