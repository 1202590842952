import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    modal: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteBranchContainer: {
      width: 472,
      height: 359,
      borderRadius: 10,
      backgroundColor: Colors.blueGrayLight,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerContainer: {
      '& h1': {
        fontSize: 18,
        fontWeight: 'normal',
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: Colors.white,
      padding: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    messageContainer: {
      textAlign: 'center',
      color: Colors.blueGrayDark,
      '& h2': {
        fontSize: 18,
        fontWeight: 600,
      },
      '& p': {
        fontSize: 13,
      },
    },
    nameText: {
      color: Colors.purple,
      fontWeight: 600,
    },
    optionsButtonContainer: {
      display: 'flex',
      padding: 25,
      justifyContent: 'flex-end',
      gap: 10,
      backgroundColor: Colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    backButton: {
      background: Colors.white,
      color: Colors.black,
      border: `1px solid ${Colors.black}`,
    },
    branchManagementTableRow: {
      alignItems: 'center',
      background: Colors.white,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0',
      top: '2.5em',
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
    },
    hospitalInfo: {
      '& img': {
        marginRight: '26px',
      },
      alignItems: 'center',
      color: Colors.blueGrayDark,
      display: 'flex',
      flex: '1',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0px',
    },
    numberOfBranches: {
      justifyContent: 'center',
      alignItems: 'center',
      background: Colors.blueGrayLight,
      borderRadius: '50%',
      display: 'flex',
      height: 32,
      width: 32,
    },
    numberOfBranchesWrapper: {
      flex: '0.2',
    },
    rowData: {
      color: Colors.blueGrayDark,
      flex: '1',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    staffProfile: {
      '& span': {
        color: Colors.blueGrayDark,
        fontSize: '12px',
        fontWeight: '500',
        marginLeft: '8px',
        opacity: '0.38',
      },
      alignItems: 'center',
      display: 'flex',
      flex: '1',
    },
    topNone: {
      top: 70,
    },
    treeViewList: {
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },

    button: {
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: 6,
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    buttonIcon: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      height: 14,
    },
    navLink: {
      textDecoration: 'none',
    },
  };
});
