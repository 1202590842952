export interface ErxRefId {
  erxRefId: string;
  providerId: string;
}

export interface ErxPrescriptionError {
  statusCode: number;
  status: number;
  message: string;
}

export interface DefaultAttributes {
  attribute?: any;
}

export interface ErxDetailsByRefId {
  id: string;
  reference: string;
  sender: {
    id: string;
    name: string;
  };
  receiver: {
    id: string;
    name: string;
  };
  payer: {
    id: string;
    name: string;
  };
  transaction_date: string;
  clinician: {
    id: string;
    name: string;
  };
  patient: {
    member_id: string;
    emirates_id: string;
    date_of_birth: string;
    weight: number;
  };
  encounter: {
    facility: {
      id: string;
      name: string;
    };
    type: number;
  };
  diagnoses: ErxDiagnoses[];
  activities: ErxDrugsRefId[];
}

export interface ErxDiagnoses {
  type: string;
  code: string;
  description: {
    short: string;
    long: string;
  };
}

export interface ErxGenericDrug {
  code: string;
  generic_code: string;
  name: string;
  strength?: string;
  dosage_form_package?: string;
  manufacturer?: string;
  package_price?: string;
  unit_per_package?: string;
  unit_price?: string;
  isGeneric?: boolean;
}

export interface ErxDrugsRefId {
  id: string;
  start_date: string;
  type: number;
  code: string;
  generic: boolean;
  name: string;
  strength: string;
  dosage_form_package: string;
  manufacturer: string;
  quantity: number;
  duration: number;
  refills: number;
  route_of_admin: {
    code: string;
    name: string;
  };
  instructions: string;
  frequency: {
    unit_per_frequency: number;
    value: number;
    type: string;
  };
  package_price: string;
  unit_per_package: string;
  unit_price?: string;
  generic_drugs: ErxGenericDrug[];
  generic_name: string;
  isSelected?: boolean;
  clinicianId?: string | undefined;
}

export interface ErxActivities {
  id: string;
  code: string;
  dosage_form_package: string;
  duration: number;
  frequency: {
    type: string;
    unit_per_frequency: string;
    value: number;
  };
  generic: boolean;
  generic_drugs: [
    {
      code: string;
      generic_code: string;
      name: string;
      strength: string;
      dosage_form_package: string;
      manufacturer: string;
      package_price: string;
      unit_per_package: string;
      unit_price?: string;
    },
  ];
  instructions: string;
  manufacturer: string;
  name: string;
  package_price: string;
  quantity: number;
  refills: number;
  route_of_admin: {
    code: string;
    name: string;
  };
  start_date: string;
  strength: string;
  type: number;
  unit_per_package: string;
  unit_price?: string;
}

export enum ObservationType {
  LOINC = 'LOINC',
  Text = 'Text',
  File = 'File',
  UniversalDental = 'Universal Dental',
  Financial = 'Financial',
  Grouping = 'Grouping',
  ERX = 'ERX',
  Result = 'Result',
}

export enum ActivityType {
  CPT = 3,
  HCPCS = 4,
  Drug = 5,
  Dental = 6,
  ServiceCode = 8,
  DRG = 9,
  ScientificCode = 10,
}

export enum AuthorizationType {
  ELIGIBILITY = 'Eligibility',
  AUTHORIZATION = 'Authorization',
  CANCELLATION = 'Cancellation',
  EXTENSION = 'Extension',
  STATUS_INQUIRY = 'Status Inquiry',
}

export interface ErxObservation {
  type: string;
  code: string;
  value: string;
  valueType: string;
}

export interface ErxActivitiesRequest {
  activityId: string;
  start: string;
  type: number;
  code: string;
  quantity: number;
  unit: string;
  net: any;
  clinicianId: string | undefined;
  observations: ErxObservation[];
}

export interface SaveNewErx {
  providerId: string;
  erxRef: string;
  priorRequest: {
    type: string;
    payerId: string;
    receiverId: string;
    dateOrdered: string;
    memberId: string;
    emiratesIdNumber: string;
    weight: number;
    encounter: {
      type: number;
    };
    diagnoses: ErxDiagnoses[];
    activities: ErxActivitiesRequest[];
  };
}

export interface ResponseNewErxId {
  id: string;
}

export interface UserBranchesObject {
  id: number;
  name: string;
  identifier: string;
}

export interface InitialState {
  branches?: UserBranchesObject[];
  id?: ResponseNewErxId;
  data?: ErxDetailsByRefId;
  isLoading: boolean;
  error?: ErxPrescriptionError;
}
