import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    accountSettingsWrapper: {
      background: Colors.white,
      borderRadius: '10px',
      boxShadow: `0px 0.5px 2px ${Colors.RMSC.shadow}`,
      display: 'flex',
      flex: '1 1',
      flexDirection: 'column',
      overflow: 'scroll',
      paddingBottom: 40,
      paddingTop: 40,
    },
    formEmptyColumn: {
      flex: 1,
    },
    formInfoSection: {
      display: 'flex',
      flexDirection: 'row',
    },
    formSectionElements: {
      flex: 2,
    },
    formSectionElementsWrapper: {
      flex: 1,
      paddingLeft: 30,
      paddingTop: 44,
    },
    formSectionTitleWrapper: {
      '& h5': {
        cursor: 'pointer',
        fontSize: 14,
      },
      color: Colors.blueGrayDark,
      flex: 1,
      paddingLeft: 60,
      textAlign: 'left',
    },
    marginTop5: {
      marginTop: '50px',
    },
    passwordReset: {
      display: 'flex',
      flexDirection: 'column',
    },
    passwordResetContent: {
      color: Colors.blueGrayDark,
      fontSize: '13px',
      marginTop: '15px',
    },
    passwordResetHeader: {
      '& span': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        fontWeight: '600',
      },
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '10px',
    },
    scrollWrapper: {},
    loadingContainer: {
      minHeight: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});
