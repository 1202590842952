import {RootState} from 'modules/App/store';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from 'services/axios';
import {MyKnownError} from 'interfaces/error-interface';
import {DiagnosisData, DiagnosisQuery, IDiagnosisResponse} from 'interfaces/pre-auth.interface';
import {LoincData, ILoincResponse} from '../../interfaces/pre-auth.interface';

interface InitialState {
  diagnosis: IDiagnosisResponse[];
  loinc: ILoincResponse[];
  isLoading: boolean;
  error: any;
}

const initialState: InitialState = {
  diagnosis: [],
  loinc: [],
  isLoading: false,
  error: undefined,
};

export const getDiagnosis = createAsyncThunk<DiagnosisData, DiagnosisQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/getDiagnosis',
  async (query: DiagnosisQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/diagnoses', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getLoincs = createAsyncThunk<LoincData, DiagnosisQuery, {rejectValue: MyKnownError}>(
  'preAuthV2/getLoincs',
  async (query: DiagnosisQuery, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get('/loincs', {params: query});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const diagnosisSlice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiagnosis.fulfilled, (state, action) => {
        state.diagnosis = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getDiagnosis.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getDiagnosis.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(getLoincs.fulfilled, (state, action) => {
        state.loinc = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getLoincs.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getLoincs.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const diagnosisSelect = (state: RootState) => state.diagnosis;
export default diagnosisSlice.reducer;
