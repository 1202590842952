import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {ReactText} from 'react';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      minWidth: 100,
      maxWidth: 560,
      // overflowX: 'scroll',
      maxHeight: 250,
      overflowY: 'scroll',
      position: 'relative',
      padding: 4,
    },

    buttonContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 10,
      // flex: '1 1 0%',
      fontWeight: 600,
      fontSize: 14,
      color: Colors.blueGrayDark,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      '& button': {
        border: 'none',
        background: 'none',
        padding: 0,
        margin: 0,
      },
    },
    uncheck: {
      width: 10,
      height: 10,
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: Colors.blueGrayDark,
      marginRight: 4,
      padding: 2,
    },
    check: {
      width: 10,
      height: 10,
      borderRadius: 4,
      backgroundColor: Colors.purple,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: Colors.purple,
      marginRight: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 2,
    },
    checkAllContainer: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeft: 0,
      borderRight: 0,
      borderStyle: 'solid',
      backgroundColor: Colors.blueGrayLight,
      borderColor: Colors.grayLight,
      paddingTop: 7,
      marginBottom: 2,
    },
    deselectImg: {
      height: 12,
      width: 12,
    },
    button: {
      backgroundColor: Colors.purple,
      border: 'none',
      paddingLeft: 16,
      paddingRight: 16,
    },
    optionText: {
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
      fontWeight: 600,
    },
    hiddenDiv: {
      height: 40,
    },
    subSearchContainer: {
      paddingRight: 4,
      paddingLeft: 4,
      paddingTop: 5,
      paddingBottom: 5,
      overflow: 'hidden',
      backgroundColor: Colors.blueGrayLight,
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: Colors.grayLight,
      display: 'flex',
      alignItems: 'center',
      '& input:focus': {
        outline: 'none',
      },
      position: 'sticky',
      top: 0,
      width: '98.5%',
    },

    searchContainer: {
      padding: 8,
      paddingLeft: 10,
      paddingRight: 14,
      paddingTop: 8,
      // paddingBottom: 10,
    },

    searchInput: {
      height: '100%',
      width: '100%',
      padding: 0,
      borderStyle: 'none',
      backgroundColor: Colors.blueGrayLight,
    },
  };
});

export interface OptionValue {
  label: string;
  value: string;
  leftIcon?: string;
  rightIcon?: string;
}

export interface ComponentProps {
  options: OptionValue[];
  setValue?: React.Dispatch<React.SetStateAction<OptionValue[]>>;
  selectAllTitle?: string;
  onChange?: (values: OptionValue[]) => void;
  value?: OptionValue[];
  disableSearch?: boolean;
  disableSelectAll?: boolean;
  className?: string;
}
