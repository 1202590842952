import React, {FC} from 'react';
import dayjs from 'dayjs';
import {createUseStyles} from 'react-jss';
import KlaimTooltip from 'modules/Common/components/KlaimTooltip';
import timelineOut from '../../../../assets/timeline_out.svg';
import timelineIn from '../../../../assets/timeline_in.svg';
import {Colors} from '../../../Common/constants/Colors';
import {ActivityHistory, ClaimSubmissionError, Submission} from '../../api/types';
import {resubmissionAttachment} from '../../api';
import * as Toast from '../../../Common/utils/toast';

const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    contentContainer: {
      position: 'absolute',
      left: -190,
      bottom: 50,
      background: Colors.errorBackgroundRed,
      color: Colors.errorTextRed,
      width: 311,
      maxHeight: 300,
      overflowY: 'scroll',
      padding: 10,
      borderRadius: 5,
      '& h3': {
        fontSize: 14,
        margin: 10,
      },
      '& li': {
        fontSize: 12,
      },
      '& ul': {
        margin: 10,
        paddingLeft: 15,
      },
    },
    tooltipArrow: {
      width: 15,
      height: 15,
      background: Colors.errorBackgroundRed,
      position: 'absolute',
      bottom: 45,
      transform: 'rotate(45deg)',
    },
    tooltip: {
      background: Colors.errorBackgroundRed,
      color: Colors.errorTextRed,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      height: 20,
      position: 'relative',
    },
    errorMessage: {
      color: Colors.red,
    },
    timelineInfo: {
      display: 'flex',
      padding: '0 80px 0 80px',
      position: 'relative',
      flexDirection: 'column',
    },
    timelineInfoSingle: {
      display: 'flex',
      position: 'absolute',
      top: '0',
      right: '0',
      width: '45%',
      height: '100%',
      flexDirection: 'column',
      overflow: 'auto',
    },
    claimSubmission: {
      paddingBottom: '30px',
    },
    cardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
      },
      display: 'flex',
      flexDirection: 'column',
      fontSize: '14px',
      letterSpacing: '0.13px',
      color: Colors.pinkDark,
      fontWeight: 600,
      borderBottom: 'none !important',
    },
    dFlex: {
      display: 'flex',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    justifyFlexEnd: {
      justifyContent: 'flex-end',
    },
    w573: {
      width: '600px',
    },
    timelineCard: {
      border: `0.5px solid ${Colors.grayLight}`,
      padding: '22px',
      borderRadius: '10px',
      '& $cardRow:last-child': {
        borderBottom: 'unset !important',
        marginBottom: 'unset !important',
        paddingBottom: 'unset !important',
      },
      marginBottom: '30px',
    },
    timelineCardInfoSection: {
      '& span': {
        fontSize: '15px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
      },
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      fontSize: '17px',
      letterSpacing: '0.13px',
      color: Colors.pinkDark,
      fontWeight: 600,
      marginBottom: '28px',
    },
    w100: {
      width: '100%',
    },
    cardRow: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${Colors.pinkLight}`,
      paddingBottom: '25px',
      marginBottom: '25px',
    },
    verticalBorder: {
      display: 'flex',
      position: 'relative',
      borderLeft: `1px solid ${Colors.pinkLight}`,
      justifyContent: 'center',
    },
    claimRemittance: {
      paddingBottom: '30px',
    },
    submissionIcon: {
      position: 'absolute',
      top: 30,
    },
    remittanceIcon: {
      position: 'absolute',
      top: 30,
    },
    submissionCard: {
      flexDirection: 'row',
    },
    '@media screen and (max-width: 1440px)': {
      timelineInfo: {
        padding: 0,
      },
      submissionCard: {
        flexDirection: 'row-reverse',
      },
      colFullWidth: {
        flex: '1 !important',
        maxWidth: '100% !important',
      },
      hideCol: {
        display: 'none',
      },
      timelineInfoSingle: {
        position: 'relative',
        width: '100%',
      },
    },
    colMd5: {
      flex: '0 0 41.666667%',
      maxWidth: '41.666667%',
      boxSizing: 'border-box',
    },
    colMd2: {
      width: '16.66666667%',
      boxSizing: 'border-box',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    colFullWidth: {},
    hideCol: {},
    colMdFullWidth: {
      flex: '1 !important',
      maxWidth: '100% !important',
    },
    hideMdCol: {
      display: 'none !important',
    },
    submissionCardSingle: {
      flexDirection: 'row-reverse !important',
    },
  };
});

interface ComponentProps {
  timeline?: any;
  layout?: 'single' | 'double';
}

const DATE_FORMAT = 'DD MMM YYYY HH:MM';

const Timeline: FC<ComponentProps> = ({timeline, layout}) => {
  const classes = useStylesFromThemeFunction();

  const displayResubmissionType = (type?: string) => {
    switch (type) {
      case 'correction':
        return 'Correction';
      case 'internal complaint':
        return 'Internal Complaint';
      case 'reconciliation':
        return 'Reconciliation';
      default:
        return type;
    }
  };

  const downloadResubmissionAttachment = async (submission: Submission) => {
    try {
      const attachment = await resubmissionAttachment('claimId', submission.id);
      // FileSaver.saveAs(attachment, claimId + '.pdf');
      console.log(attachment);
    } catch (e) {
      Toast.error(e.message);
    }
  };

  const getSubmissionErrorDisplayName = (code: ClaimSubmissionError) => {
    switch (code) {
      case 'invalid_credentials':
        return 'Invalid Credentials';
      case 'dhpo_error':
        return 'Invalid';
      case 'unexpected_error':
      default:
        return 'Unexpected Error';
    }
  };
  return (
    <div className={layout === 'single' ? classes.timelineInfoSingle : classes.timelineInfo}>
      {timeline &&
        timeline.length &&
        timeline.map((history: ActivityHistory, index: number) => {
          return (
            <>
              {history?.claimSubmission && (
                <div className={classes.w100}>
                  <div
                    className={`${classes.dFlex} ${
                      layout === 'single' ? classes.submissionCardSingle : classes.submissionCard
                    }`}
                  >
                    <div
                      className={`${classes.timelineCard} ${classes.colMd5} ${
                        layout === 'single' ? classes.colMdFullWidth : classes.colFullWidth
                      }`}
                    >
                      <div className={classes.timelineCardInfoSection}>
                        <div className={`${classes.cardRow}`}>
                          <div>
                            {history?.claimSubmission?.resubmission_type ? 'Resubmission' : 'Submission'}
                            <span>{dayjs(history.claimSubmission.submission_date).format(DATE_FORMAT)}</span>
                          </div>
                          {history?.claimSubmission?.submission_error?.code && (
                            <div className={classes.cardInfoSection}>
                              <span className={classes.errorMessage}>
                                <p>{history?.claimSubmission?.submission_error?.code}</p>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={`${classes.cardRow}`}>
                        {history?.claimSubmission?.status === 'error' ||
                          (history?.claimSubmission?.submission_error && (
                            <div className={classes.cardInfoSection}>
                              <span className={classes.errorMessage}>
                                <KlaimTooltip
                                  title={``}
                                  description={history?.claimSubmission?.submission_error?.details}
                                  value={`${getSubmissionErrorDisplayName(
                                    history?.claimSubmission?.submission_error?.code,
                                  )}`}
                                  tooltipArrowClass={classes.tooltipArrow}
                                  contentClass={classes.contentContainer}
                                  tooltipClass={classes.tooltip}
                                />
                              </span>
                            </div>
                          ))}
                        <div className={classes.cardInfoSection}>
                          Gross Amount
                          <span>
                            {history?.claimSubmission?.gross_amount
                              ? `${history.claimSubmission.gross_amount.toFixed(2)} SAR`
                              : ''}
                          </span>
                        </div>
                        <div className={classes.cardInfoSection}>
                          Patient Share
                          <span>
                            {history?.claimSubmission?.patient_share
                              ? `${history.claimSubmission.patient_share.toFixed(2)} SAR`
                              : '0'}
                          </span>
                        </div>
                        <div className={classes.cardInfoSection}>
                          Net Amount
                          <span>
                            {history?.claimSubmission?.net_amount
                              ? `${history.claimSubmission.net_amount.toFixed(2)} SAR`
                              : ''}
                          </span>
                        </div>
                      </div>
                      {history?.claimSubmission?.resubmission_type && (
                        <div className={`${classes.cardRow}`}>
                          <div className={classes.cardInfoSection}>
                            Type
                            <span>{displayResubmissionType(history.claimSubmission.resubmission_type)}</span>
                          </div>
                          {history?.claimSubmission?.resubmission_attachment_id && (
                            <div className={classes.cardInfoSection}>
                              Attachment
                              <span>
                                {history?.claimSubmission?.patient_share
                                  ? `${history.claimSubmission.patient_share.toFixed(2)} SAR`
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {history?.claimSubmission?.resubmission_comment && (
                        <div className={`${classes.cardRow}`}>
                          <div className={classes.cardInfoSection}>
                            Comment
                            <span>{history?.claimSubmission?.resubmission_comment}</span>
                          </div>
                          {history?.claimSubmission?.resubmission_attachment_id && (
                            <div className={classes.cardInfoSection}>
                              Attachment
                              <span>{history?.claimSubmission?.resubmission_comment}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={`${classes.dFlex} ${classes.justifyContentCenter} ${classes.colMd2}`}>
                      <div className={classes.verticalBorder}>
                        <div className={classes.submissionIcon}>
                          <img src={timelineOut} alt="timeline-out" />
                        </div>
                      </div>
                    </div>
                    <div className={`${classes.colMd5} ${layout === 'single' ? classes.hideMdCol : classes.hideCol}`} />
                  </div>
                </div>
              )}
              {history?.activityRemittanceAdvice && (
                <div className={classes.w100}>
                  <div className={`${classes.dFlex}`}>
                    <div className={`${classes.colMd5} ${layout === 'single' ? classes.hideMdCol : classes.hideCol}`} />
                    <div className={`${classes.dFlex} ${classes.justifyContentCenter} ${classes.colMd2}`}>
                      <div className={classes.verticalBorder}>
                        <div className={classes.remittanceIcon}>
                          <img src={timelineIn} alt="timeline-out" />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${classes.timelineCard} ${classes.colMd5} ${
                        layout === 'single' ? classes.colMdFullWidth : classes.colFullWidth
                      }`}
                    >
                      <div className={classes.timelineCardInfoSection}>
                        Remittance Advice <span>{dayjs(history.date).format(DATE_FORMAT)}</span>
                      </div>
                      <div className={`${classes.cardRow}`}>
                        <div className={classes.cardInfoSection}>
                          Payment Amount
                          <span>
                            {history?.activityRemittanceAdvice?.payment_amount
                              ? `${history.activityRemittanceAdvice.payment_amount.toFixed(2)} SAR`
                              : ''}
                          </span>
                        </div>
                        <div className={classes.cardInfoSection}>
                          Payment Ref
                          <span>
                            {history?.activityRemittanceAdvice?.payment_ref
                              ? history.activityRemittanceAdvice.payment_ref
                              : ''}
                          </span>
                        </div>
                        <div className={classes.cardInfoSection}>
                          Settlement Date
                          <span>{dayjs(history?.activityRemittanceAdvice.settlement_date).format(DATE_FORMAT)}</span>
                        </div>
                      </div>
                      {history?.activityRemittanceAdvice?.denial && (
                        <div className={`${classes.cardRow}`}>
                          <div className={classes.cardInfoSection}>
                            Denial Code{' '}
                            <span>
                              {history?.activityRemittanceAdvice?.denial?.description &&
                                (history?.activityRemittanceAdvice?.denial?.description
                                  ? `${history.activityRemittanceAdvice.denial.description} - `
                                  : '')}
                              (
                              {history?.activityRemittanceAdvice?.denial?.code
                                ? history.activityRemittanceAdvice.denial.code
                                : ''}{' '}
                              )
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};

export default Timeline;
