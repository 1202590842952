import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface ComponentProps {
  title?: string;
  value?: string;
  leftIcon?: string;
  rightIcon?: string;
  isOpen?: boolean;
  isIconButton?: boolean;
  icon?: string;
  isButtonRight?: boolean;
  customClass?: string;
  className?: string;
  showDescription?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'center';
  style?: React.CSSProperties;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      maxWidth: 400,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    titleContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 6,
      paddingBottom: 6,
      backgroundColor: Colors.blueGrayLight,
      borderWidth: 1,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      flex: '1 1 0%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
    },

    titleText: {
      fontSize: 14,
      fontWeight: 600,
      color: Colors.blueGrayDark,
      marginLeft: 10,
      marginRight: 10,
      whiteSpace: 'nowrap',
    },
    titleIcon: {
      height: 18,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    button: {
      backgroundColor: Colors.purple,
      border: 'none',
      paddingLeft: 16,
      paddingRight: 16,
    },

    dropdownButtonContainer: {
      display: 'flex',
      overflow: 'hidden',
      borderWidth: 1,
      borderRadius: 12,
      borderColor: Colors.grayLight,
      borderStyle: 'solid',
      height: 40,
    },

    bodyContainer: {
      position: 'relative',
      zIndex: 2000,
    },
    paddingContainer: {
      paddingTop: 10,
      position: 'absolute',
    },
    default: {
      right: 0,
    },
    left: {
      left: -10,
      bottom: 0,
      zIndex: 1000,
    },
    right: {
      left: 'calc(100%)',
      bottom: 0,
    },
    top: {
      bottom: 50,
    },
    bottom: {
      top: 0,
    },
    topLeft: {
      right: 'calc(100%)',
      bottom: 50,
    },
    topRight: {
      left: 'calc(100%)',
      bottom: 50,
    },
    bottomLeft: {
      top: -40,
      right: 'calc(100%)',
    },
    bottomRight: {
      top: -40,
      left: 'calc(100%)',
    },
    childContainer: {
      minWidth: 50,
      borderWidth: 1,
      borderRadius: 10,
      borderColor: Colors.gray,
      // padding: 8,
      boxShadow: '0px 8px 16px 2px rgb(96 97 112 / 16%)',
      MozBoxShadow: '0px 8px 16px 2px rgba(96, 97, 112, 0.16)',
      WebkitBoxShadow: '0px 8px 16px 2px rgb(96 97 112 / 16%)',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: Colors.white,
    },
  };
});
