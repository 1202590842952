import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'services/axios';
import {decodeJWT, TokenData} from 'services/jwt';
import {FormikHelpers} from 'formik';
import {RootState} from 'modules/App/store';

export interface AuthState {
  error?: MyKnownError;
  email: string;
  isLoading: boolean;
}

interface MyKnownError {
  code: number;
  details?: ErrorDetails[];
  message: string;
  stack: {
    error: {
      message: string;
    };
    errors: string[];
  };
}

interface ErrorDetails {
  property: string;
  children: any;
  constraints: any;
}

interface AuthData {
  user: TokenData;
  accessToken: string;
}

interface RegisterAttributes {
  values: any;
  formikHelpers: FormikHelpers<{
    [field: string]: any;
  }>;
}

export const register = createAsyncThunk<
  any,
  RegisterAttributes,
  {
    rejectValue: MyKnownError;
  }
>('register/register', async ({values, formikHelpers}: RegisterAttributes, {rejectWithValue}) => {
  try {
    const response = await axios.post('/auth/register', values);
    return {
      user: decodeJWT(response.data.accessToken),
      accessToken: response.data.accessToken,
    };
  } catch (error: any) {
    let reduce = [];
    if (error.response.data.stack) {
      if (error.response.data.stack.message) {
        if (error.response.data.stack.message.length > 0) {
          reduce = error.response.data.stack.message.reduce((acc: any, errorx: any) => {
            return [...acc, errorx.constraints.matches];
            // return {
            //   ...acc,
            //   [errorx.property]: errorx.constraints.matches,
            // };
          }, []);
          // formikHelpers.setErrors(reduce);
        }
      }
    }
    if (error.response.data.stack) {
      if (error.response.data.stack.error) {
        return rejectWithValue({
          code: error.response.data.statusCode,
          message: error.response.data.stack.error,
          stack: {
            error: {
              message: reduce.length > 0 ? 'Invalid Field(s)' : error.response.data.stack.error.message,
            },
            errors: reduce,
          },
        });
      }
    }
    return rejectWithValue(error.response.data);
  }
});

const initialState: AuthState = {
  error: undefined,
  email: '',
  isLoading: false,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    errorCleanUp: (state) => {
      state.error = undefined;
    },
    emailStore: (state, action) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state) => {
        state.isLoading = true;
      });
  },
});

export const {errorCleanUp, emailStore} = registerSlice.actions;
export const registerSelect = (state: RootState) => state.auth.register;
export default registerSlice.reducer;
