import React, {useContext} from 'react';
import _ from 'lodash';
import {IOptions} from 'interfaces/general.interfaces';
import {Branch} from 'interfaces/branches.interfaces';
import {IPatient} from 'interfaces/patient.interface';
import {Payer} from 'interfaces/payers.interfaces';
import {
  IActivityForm,
  ICareTeam,
  IClaim,
  ICoverage,
  IPatientRel,
  IPreAuthRequest,
  Member,
  PreAuthHistory,
} from 'interfaces/pre-auth.interface';
import {useDispatch} from 'react-redux';
import {createPreAuth, preAuthV2Select, resubmitPreAuth} from 'modules/PreAuthV2/PreAuthV2Slice';
import {useAppSelector} from 'modules/App/store';
import {insuranceSelect} from 'modules/Insurance/insuranceSlice';
import {eligibilitySelect} from 'modules/Eligibility/eligibilitySlice';
import validationSchema from './validationSchema';

interface IContextValues {
  initialValues: IPreAuthRequest;
  constants?: {
    preAuthStep: number;
    isNextDisabled: boolean;
    isSubmitDisabled: boolean;
    insuranceOptions: IOptions[];
    isErrorOpen: boolean;
    isSubmitting: boolean;
    coverage: ICoverage;
    claim: IClaim;
    resubmissionType: string;
    receiverId: string;
    payerId: string;
    selectedPatient: IPatient;
    isCoverageFulfilled: boolean;
    selectedBranch: Branch;
    selectedInsurance: Payer;
    selectedTpa: string;
    patientRel: IPatientRel | undefined;
    eligibility: any;
    member: Member;
    errorMessages: string[];
    activities: IActivityForm[];
    preAuthDetail?: PreAuthHistory;
  };
  setter?: {
    setPreAuthStep: React.Dispatch<React.SetStateAction<number>>;
    setIsNextDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setInsuranceOptions: React.Dispatch<React.SetStateAction<IOptions[]>>;
    setErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    setCoverage: React.Dispatch<React.SetStateAction<ICoverage>>;
    setClaim: React.Dispatch<React.SetStateAction<IClaim>>;
    setResubmissionType: React.Dispatch<React.SetStateAction<string>>;
    setSelectedPatient: React.Dispatch<React.SetStateAction<IPatient>>;
    setIsCoverageFulfilled: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedBranch: React.Dispatch<React.SetStateAction<Branch>>;
    setSelectedInsurance: React.Dispatch<React.SetStateAction<Payer>>;
    setSelectedTpa: React.Dispatch<React.SetStateAction<string>>;
    setReceiverId: React.Dispatch<React.SetStateAction<string>>;
    setPayerId: React.Dispatch<React.SetStateAction<string>>;
    setPatientRel: React.Dispatch<React.SetStateAction<IPatientRel | undefined>>;
    setEligibility: React.Dispatch<React.SetStateAction<any>>;
    setMember: React.Dispatch<React.SetStateAction<Member>>;
    setActivities: React.Dispatch<React.SetStateAction<IActivityForm[]>>;
  };
  methods?: {
    onPreAuthSubmit: (id: string) => void;
    getPayerName: (tpa: string) => string;
  };
}

const PreAuthFormTemplate = React.createContext<IContextValues>({
  initialValues: {
    senderId: '',
    providerId: '',
    senderName: '',
    payerName: '',
    receiverId: '',
    payerId: '',
    type: 'priorauth-request',
    patientId: '',
    coverage: {
      subscriberId: '',
      coverageRel: 'self',
      policyHolder: 'patient',
      organizationPolicyHolder: {
        identifier: '',
        name: '',
      },
      costToBeneficiary: [],
    },
    claim: {
      claimType: 'institutional',
      claimSubtype: 'ip',
      claimPriority: 'stat',
      supportingInfo: [],
      diagnosis: [],
      careTeam: [],
      referral: false,
    },
  },
});

export const usePreAuthFormContext = () => {
  return useContext(PreAuthFormTemplate);
};

function PreAuthFormContext({children}) {
  const dispatch = useDispatch();
  const {payers} = useAppSelector(insuranceSelect);
  const {history: preAuthDetail} = useAppSelector(preAuthV2Select);
  const {editEligibility} = useAppSelector(eligibilitySelect);

  const [initialValues] = React.useState<IPreAuthRequest>({
    senderId: '',
    providerId: '',
    senderName: '',
    payerName: '',
    receiverId: '',
    payerId: '',
    type: 'priorauth-request',
    patientId: '',
    coverage: {
      subscriberId: '',
      coverageRel: 'self',
      policyHolder: 'patient',
      // organizationPolicyHolder: {
      //   identifier: '',
      //   name: '',
      // },
      costToBeneficiary: [],
    },
    claim: {
      claimType: 'institutional',
      claimSubtype: 'ip',
      claimPriority: 'stat',
      supportingInfo: [],
      diagnosis: [],
      careTeam: [],
      referral: false,
      eligibilityOffline: '',
      eligibilityOfflineDate: '',
    },
  });

  React.useEffect(() => {
    if (preAuthDetail && preAuthDetail.submissions.length > 0) {
      const priorAuthSubmissions = preAuthDetail.submissions.filter(
        (submission) => submission.type === 'priorauth-request',
      );

      const latestSubmission = priorAuthSubmissions[priorAuthSubmissions.length - 1];

      const initialMember = latestSubmission.coverage?.member && {
        id: latestSubmission.coverage?.member?.id,
        system: latestSubmission.coverage?.member?.system,
      };

      const initialActivity: IActivityForm[] = latestSubmission.claim?.item.map((item) => {
        return {
          item,
        };
      });

      if (initialMember) {
        setMember(initialMember || initialValues.member);
      }
      if (initialActivity) {
        setActivities(initialActivity || []);
      }
    }
  }, [preAuthDetail]);

  const [preAuthStep, setPreAuthStep] = React.useState<number>(0);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(false);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);
  const [isErrorOpen, setErrorOpen] = React.useState<boolean>(false);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  // Pre Auth Payload

  const [coverage, setCoverage] = React.useState<ICoverage>(initialValues.coverage);
  const [claim, setClaim] = React.useState<IClaim>(initialValues.claim);
  const [activities, setActivities] = React.useState<IActivityForm[]>([]);
  const [resubmissionType, setResubmissionType] = React.useState('prior');
  const [selectedPatient, setSelectedPatient] = React.useState<IPatient>();
  const [isCoverageFulfilled, setIsCoverageFulfilled] = React.useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = React.useState<Branch>();
  const [selectedInsurance, setSelectedInsurance] = React.useState<any>();
  const [selectedTpa, setSelectedTpa] = React.useState<string>('');
  const [receiverId, setReceiverId] = React.useState<string>('');
  const [payerId, setPayerId] = React.useState<string>('');
  const [patientRel, setPatientRel] = React.useState<IPatientRel | undefined>(initialValues.patientRel);
  const [eligibility, setEligibility] = React.useState<any>(false);
  const [member, setMember] = React.useState<Member>({
    id: '',
    system: '',
    // subscriberId: '',
  });

  const getInsuranceName = () => {
    return selectedInsurance ? selectedInsurance?.name : '';
  };

  const getInsuranceCode = () => {
    return selectedInsurance ? selectedInsurance?.payerCode : '';
  };

  const getPayerName = (tpa: string) => {
    if (payers) {
      const payerResult = payers.data.find((payer: Payer) => payer.payerCode === tpa);
      if (payerResult) {
        return payerResult.name;
      }
    }
    return '';
  };

  const onPreAuthSubmit = async (id?: string) => {
    const pr = id
      ? {
          relatedToPriorClaim: {system: 'http://klaim.ai/fhir/authorization', value: id},
          relatedToPriorClaimRel: resubmissionType,
        }
      : {};

    const tempClaim = {
      ...claim,
      ...pr,
      referral: claim.referral,
      item: claim.item.map((it) => {
        const item = {
          ...it,
          itemUnitPrice: it?.itemUnitPrice || 0,
          bodySite:
            it.bodySite && it.bodySite.code !== undefined && it.bodySite.system !== undefined
              ? {
                  code: it.bodySite.code,
                  system: Number(it.bodySite.system),
                }
              : undefined,
          subSite:
            it.subSite && it.subSite.code !== undefined && it.subSite.system !== undefined
              ? {
                  code: it.subSite.code,
                  system: Number(it.subSite.system),
                }
              : undefined,
        };
        if (item.bodySite === undefined) {
          delete item.bodySite;
        }
        if (item.subSite === undefined) {
          delete item.subSite;
        }
        return item;
      }),
      careTeam: claim.careTeam
        ? claim.careTeam.map((careTeam: ICareTeam) => {
            return {
              code: careTeam.code,
              practitionerType: careTeam.practitionerType,
              practitionerCode: careTeam.practitionerCode,
              specialty: careTeam.specialty,
              careTeamRoleCode: careTeam.careTeamRoleCode,
              onSite: careTeam.onSite,
              name: careTeam.name,
            };
          })
        : [],
    };

    const payload: IPreAuthRequest = {
      senderId: selectedBranch ? selectedBranch.identifier : '',
      providerId: selectedBranch ? selectedBranch.identifier : '',
      senderName: selectedBranch ? selectedBranch.name : '',
      payerName: selectedTpa ? getPayerName(selectedTpa) : getInsuranceName(),
      receiverId: selectedTpa,
      payerId: selectedInsurance || selectedTpa,
      // receiverId,
      // payerId,
      type: 'priorauth-request',
      patientId: selectedPatient?.id || '',
      coverage: {
        ...coverage,
        member,
      },
      claim: Object.keys(tempClaim)
        .filter((k) => !_.isEmpty(tempClaim[k as keyof IClaim]))
        .reduce((a, k) => ({...a, [k]: tempClaim[k as keyof IClaim]}), {}),
      patientRel,
    };

    payload.claim.referral = tempClaim.referral;

    payload.claim.supportingInfo = payload.claim?.supportingInfo?.map((si) => {
      const tmpSupportingInfo = {...si};
      delete tmpSupportingInfo.code;
      return tmpSupportingInfo;
    });

    if (patientRel && patientRel.isValid) {
      payload.patientRel = patientRel;
    }

    if (eligibility || editEligibility) {
      payload.eligibilityId = eligibility.id || editEligibility.id;
    }

    const {errors} = await validationSchema.validate(payload, {abortEarly: false}).catch((err) => {
      return err;
    });

    if (errors && errors.length > 0) {
      setErrorMessages(errors);
    }

    if (id) {
      dispatch(resubmitPreAuth({id, ...payload}));
    } else {
      dispatch(createPreAuth(payload));
    }
  };

  return (
    <PreAuthFormTemplate.Provider
      value={{
        initialValues,
        constants: {
          preAuthStep,
          isNextDisabled,
          isSubmitDisabled,
          insuranceOptions,
          isErrorOpen,
          isSubmitting,
          coverage,
          claim,
          resubmissionType,
          selectedPatient,
          isCoverageFulfilled,
          selectedBranch,
          selectedInsurance,
          selectedTpa,
          patientRel,
          eligibility,
          member,
          errorMessages,
          activities,
          preAuthDetail,
          payerId,
          receiverId,
        },
        setter: {
          setPreAuthStep,
          setIsNextDisabled,
          setIsSubmitDisabled,
          setInsuranceOptions,
          setErrorOpen,
          setSubmitting,
          setCoverage,
          setClaim,
          setResubmissionType,
          setSelectedPatient,
          setIsCoverageFulfilled,
          setSelectedBranch,
          setSelectedInsurance,
          setSelectedTpa,
          setPatientRel,
          setEligibility,
          setMember,
          setActivities,
          setPayerId,
          setReceiverId,
        },
        methods: {
          onPreAuthSubmit,
          getPayerName,
        },
      }}
    >
      {children}
    </PreAuthFormTemplate.Provider>
  );
}

export default PreAuthFormContext;
