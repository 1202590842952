import React from 'react';
import ReactDOM from 'react-dom';
import {get as getStorage} from 'local-storage';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {jss, ThemeProvider} from 'react-jss';
import rtl from 'jss-rtl';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import routes from 'modules/Route/routes';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import Loading from 'modules/Loading';
import {loadingSelect} from 'modules/Auth/v3/features/Loading/loadingSlice';
import {authSelect, validate} from 'modules/Auth/v3/features/Auth/authSlice';
import {KlaimToast} from 'modules/Common/components/KlaimToast';
import {Mixpanel} from 'services/mixpanel';
import axios from 'services/axios';
import {klaimTheme} from 'modules/App/App';
import CustomErrorPromptModal from '../Eligibility/components/CustomErrorPromptModal';

if (localStorage.getItem('lang') === 'ar') {
  jss.use(rtl());
}

function App() {
  const dispatch = useAppDispatch();
  const {isCheckLoading} = useAppSelector(loadingSelect);
  const {user} = useAppSelector(authSelect);

  React.useEffect(() => {
    const accessToken = getStorage('accessToken');
    if (accessToken) {
      dispatch(validate());
      axios.defaults.headers.common.Authorization = accessToken;
    }
  }, []);

  React.useEffect(() => {
    if (user.email) {
      Mixpanel.init();
      Mixpanel.identify(user);
    }
  }, [user]);

  const userConfirmation = React.useCallback((message, callback) => {
    const node = document.getElementById('custom-prompt');
    if (node) {
      const cleanUp = (answer: any) => {
        ReactDOM.unmountComponentAtNode(node);
        callback(answer);
      };

      ReactDOM.render(<CustomErrorPromptModal message={message} cleanUp={cleanUp} />, node);
    }
  }, []);

  if (isCheckLoading) {
    return <Loading />;
  }

  return (
    <div dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : ''}>
      <KlaimToast />
      <ThemeProvider theme={klaimTheme}>
        <Router getUserConfirmation={userConfirmation}>
          <Switch>
            {routes.map(({routeKey, ...route}) => {
              return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
            })}
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
