import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import 'antd/dist/antd.less';
import 'custom-test-antd/dist/index.css';
import 'custom-test-antd/dist/theme/theme.less';
import 'custom-test-antd/dist/theme/variables.pcss';
import './index.css';
import App from 'modules/App';
import store from 'modules/App/store';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
