import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {useStylesFromThemeFunction} from '../styles';
import {KlaimButton} from '../../../../Common/components-v2/KlaimForm';
import ListItem from './ListItem';
import {useAppDispatch, useAppSelector} from '../../../../App/store';
import {headerSelect} from '../../../../Header/headerSlice';
import {errorCleanUp, getInsurances, insurancesManagementSelect} from '../InsuranceSlice';
import {branchManagementSelect, getBranches} from '../../BranchManagement/branchManagementSlice';
import * as Toast from '../../../../Common/utils/toast';

import {deleteInsurance} from '../InsuranceSlice';
import KlaimModal from 'modules/Common/components/KlaimModal';
import Button from 'modules/Common/components/Button';

const Component: React.FC = () => {
  const s = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {startDate, endDate, submitOnClick} = useAppSelector(headerSelect);
  const [offset, setOffset] = useState(1);
  const [limit] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const {
    insurances: {data: dataList, result: dataCount},
  } = useAppSelector(insurancesManagementSelect);
  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  const getData = () => {
    if (branches && branches.length > 0) {
      dispatch(
        getInsurances({
          limit,
          offset: (offset - 1) * limit,
          startDate,
          endDate,
          providerId: getProviderIds(),
        }),
      );
    }
  };
  useEffect(() => {
    getData();
  }, [limit, offset, startDate, endDate, branches]);

  const {error, apiCallSuccess} = useAppSelector(insurancesManagementSelect);

  useEffect(() => {
    if (error) {
      Toast.error('There was an error while treating your request');
      dispatch(errorCleanUp());
    }
  }, [error]);

  useEffect(() => {
    if (apiCallSuccess) {
      Toast.success('Operation succeeded');
      setTimeout(() => {
        dispatch(errorCleanUp());
        getData();
      }, 1000);
    }
  }, [apiCallSuccess]);

  return (
    <div style={{padding: 16}}>
      <KlaimModal isOpen={deleteOpen} contentStyle={{
        inset: 'auto',
        justifyContent: 'center',
        width: 500,
        marginTop: '20%',
        marginLeft: '25%',
        transform: 'translate(50%,-50%)'
      }}>
        <div>
          <h1>Delete Draft</h1>
          <h2>Are you sure you want to delete draft?</h2>
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
            <Button
              title="No, Go back"
              onClick={() => setDeleteOpen(false)}
            />
            <Button
              title="Delete Draft"
              onClick={() => {
                if (!selectedItem) {
                  return;
                }
            
                dispatch(
                  deleteInsurance({
                    id: selectedItem._id,
                    providerId: getProviderIds(),
                  }),
                );
                setSelectedItem(undefined)
                setDeleteOpen(false);
              }}
            />
          </div>
        </div>
      </KlaimModal>
      <div className={s.container} style={{padding: 16}}>
        <h1 className={s.header}>Insurance Companies</h1>
        {/* <span className={s.description}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis corporis explicabo maxime necessitatibus
          nesciunt nisi, quidem ratione tempora?
        </span> */}
        <div className={s.spacedRow}>
          {/* <input className={s.searchInput} placeholder={'Search Insurance'} /> */}
          <div />
          <KlaimButton variant="primary" onClick={() => history.push('/settings/insurances-management/add')}>
            {'Add Insurance'}
          </KlaimButton>
        </div>
      </div>
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={dataCount}
        currentPage={offset}
        onPageChange={setOffset}
        label="insurances"
      >
        <div className={s.container}>
          <div className={s.tableHeader}>
            <span className={s.label} style={{width: '20%'}}>
              Branch NPHIES ID
            </span>
            <span className={s.label} style={{width: '20%'}}>
              HIC Name
            </span>
            <span className={s.label} style={{width: '35%'}}>
              TPA Name
            </span>
            <span className={s.label} style={{width: '5%'}}>
              Has TPA
            </span>
            <span className={s.label} style={{width: '20%', display: 'flex', justifyContent: 'flex-end'}}>
              Action
            </span>
          </div>
          {dataList.map((el: any) => (
            <ListItem
              insurance={{...el, code: el.code, tpa: el.name, status: 'actives'}}
              dispatch={dispatch}
              getProviderIds={getProviderIds}
              branches={branches}
              onDeleteItem={(item) => {
                setSelectedItem(item);
                setDeleteOpen(true);
              }}
            />
          ))}
        </div>
      </KlaimPaginationContainer>
    </div>
  );
};

export default Component;
