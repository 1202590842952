export enum AuthorizationType {
  ELIGIBILITY = 'Eligibility',
  AUTHORIZATION = 'Authorization',
  CANCELLATION = 'Cancellation',
  EXTENSION = 'Extension',
  STATUS_INQUIRY = 'Status Inquiry',
}

export enum EncounterType {
  NoBedNoEmergencyRoom = 1,
  NoBedEmergencyRoom = 2,
  InpatientBedNoEmergencyRoom = 3,
  InpatientBedEmergencyRoom = 4,
  DaycaseBedNoEmergencyRoom = 5,
  DaycaseBedEmergencyRoom = 6,
  NationalsScreening = 7,
  NewVisaScreening = 8,
  RenewalVisaScreening = 9,
  Home = 12,
  AssistedLivingFacility = 13,
  MobileUnit = 15,
  AmbulanceLand = 41,
  AmbulanceAirWater = 42,
}

export enum ActivityType {
  CPT = 3,
  HCPCS = 4,
  Drug = 5,
  Dental = 6,
  ServiceCode = 8,
  DRG = 9,
  ScientificCode = 10,
}

export enum ObservationType {
  LOINC = 'LOINC',
  Text = 'Text',
  File = 'File',
  UniversalDental = 'Universal Dental',
  Financial = 'Financial',
  Grouping = 'Grouping',
  ERX = 'ERX',
  Result = 'Result',
}

export const DrugPackage = [
  {
    label: 'Pack(s)',
    value: 'pack',
  },
  {label: 'Unit(s)', value: 'unit'},
];

export const PriorRequestInitialForm = {
  authorityCode: '',
  idType: 'manual',
  providerId: '',
  type: AuthorizationType.AUTHORIZATION,
  payerId: '',
  receiverId: '',
  dateOrdered: '',
  memberId: '',
  emiratesIdNumber: '',
  encounter: {
    type: EncounterType.NoBedNoEmergencyRoom,
  },
  diagnoses: [{type: 'Principal', code: '', diagnoseId: '0'}],
  clinicianId: '',
  activities: [
    {
      activityId: '0',
      start: '',
      type: ActivityType.Drug,
      code: '',
      quantity: '',
      unit: '',
      net: '',
      duration: '',
      observations: [
        {
          type: ObservationType.Text,
          code: 'Duration',
          value: '',
          valueType: 'Days',
        },
      ],
    },
  ],
};

export interface User {
  is_organization_owner: boolean;
  id: number;
  email: string;
  encrypted_password: string;
  is_active: boolean;
  name?: any;
  phone?: any;
  klaim_super_admin: boolean;
  password_reset_token?: any;
  password_reset_token_expires_at?: any;
  invitation_hash: string;
  is_invitation_accepted: boolean;
  avatar?: any;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
}

export interface Branch {
  id: number;
  name: string;
  authority: string;
  identifier: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  users: User[];
}

export interface Data {
  branches: Branch[];
  total: number;
}

export interface BranchData {
  data: Data;
  limit?: any;
  skip?: any;
}

export interface Drug {
  _id: string;
  status: string;
  name: string;
  strength: string;
  manufacturer: string;
  authorityCode: string;
  drugCode: string;
  genericCode: string;
  genericName: string;
  dosageFormPackage: string;
  packagePrice: number;
  unitPerPackage: number;
  unitPrice?: any;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface DrugData {
  data: Array<Drug>;
}
