import React from 'react';
import {useAppDispatch} from 'modules/App/store';
import {logout} from 'modules/Auth/v3/features/Auth/authSlice';
import Button from 'modules/Common/components/Button';

interface ComponentProps {
  className?: string;
}

const Logout: React.FC<ComponentProps> = ({className}) => {
  const dispatch = useAppDispatch();
  const onClick = () => {
    dispatch(logout());
  };
  return <Button buttonClassName={className} title="Log Out" onClick={onClick} />;
};

export default Logout;
