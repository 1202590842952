import React, {FC, useEffect, useState} from 'react';
import Table from 'modules/Common/components/Table';
import dayjs from 'dayjs';
import {useHistory} from 'react-router';
import EllipsisText from 'react-ellipsis-text';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import infoIcon from 'assets/info_icon.svg';
import {headerSelect, OptionValue, Branches} from 'modules/Header/headerSlice';
import {NoData} from 'modules/Common/components/NoData';
import {events, Mixpanel} from 'services/mixpanel';
import {get, isArray} from 'lodash';
import {useAppDispatch, useAppSelector} from '../../../App/store';
import {PaymentsListSelect, getPaymentsList} from './PaymentsListSlice';
import {useStylesFromThemeFunction} from './PaymentsList';
import {PaymentDetails} from './types';
import {authSelect} from '../../../Auth/v3/features/Auth/authSlice';
import {branchManagementSelect} from '../../../Settings/features/BranchManagement/branchManagementSlice';
import {insuranceSelect} from '../../../Insurance/insuranceSlice';
import {getPayersDataService} from '../../../Dashboard/api/requests';

const DATE_FORMAT = 'DD MMM YYYY';

const Component: FC = () => {
  const classes = useStylesFromThemeFunction();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    data: {data: dataList, count: dataCount},
    isLoading,
    error,
  } = useAppSelector(PaymentsListSelect);
  const {startDate, endDate, selectedBranches: branches} = useAppSelector(headerSelect);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [payers, setPayers] = useState(undefined);

  const providerId = branches.map((branch: Branches) => branch.value).join(',');

  const {userBranches} = useAppSelector(branchManagementSelect);
  useEffect(() => {
    getPayersDataService({limit: 300}).then((el) => setPayers(el?.data?.data));
  }, []);

  useEffect(() => {
    dispatch(
      getPaymentsList({
        limit,
        offset: (offset - 1) * limit,
        startDate,
        endDate,
        providerId,
      }),
    );
  }, [limit, offset, startDate, endDate, providerId]);

  useEffect(() => {
    Mixpanel.track(events.pageViews.paymentListView);
  }, []);

  const handleViewPayment = (id: string) => {
    history.push(`/payments/details?paymentId=${id}`);
  };

  const getPayerName = (payerId) => {
    if (isArray(payers)) {
      const value = payers.find((el) => el.payerCode === payerId);
      return value ? value.name : payerId;
    }
    return payerId;
  };

  const getBranchName = (branchId) => {
    if (userBranches) {
      return userBranches.find((el) => el.identifier === branchId).name;
    }
  };

  const stateIndicator = (state: string) => {
    return (
      <div className={classes.statusCont}>
        <div className={`${classes.status} ${state}`} />
        <div className={classes.statusText}>{state}</div>
      </div>
    );
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.patientListContainer}>
          <div className={classes.paginationContainer}>
            {providerId ? (
              <KlaimPaginationContainer
                pageSize={limit}
                totalCount={dataCount}
                currentPage={offset}
                onPageChange={setOffset}
                label="Payments"
              >
                <Table
                  loading={isLoading}
                  tableHeadings={[
                    <p className={classes.tableHeading}>Payment ID</p>,
                    <p className={classes.tableHeading}>First Recon. Date</p>,
                    <p className={classes.tableHeading}>Status</p>,
                    <p className={classes.tableHeading}>Payer/TPA ID</p>,
                    <p className={classes.tableHeading}>Branch</p>,
                    <p className={classes.tableHeading}>Payment Date</p>,
                    <p className={classes.tableHeading}>Amount</p>,
                    <p className={classes.tableHeading}>Action</p>,
                  ]}
                  renderBody={() => (
                    <>
                      {dataList &&
                        dataList.map((payment: PaymentDetails, i: number) => {
                          return (
                            <tr className={classes.tableRow} key={i}>
                              <td>
                                <EllipsisText text={payment.id || ''} length={'30'} />
                              </td>
                              <td>
                                <EllipsisText
                                  text={
                                    payment.firstReconciliationDate &&
                                    dayjs(payment.firstReconciliationDate).format(DATE_FORMAT)
                                  }
                                  length={'30'}
                                />
                              </td>
                              <td>{stateIndicator(payment.status)}</td>
                              <td>
                                <EllipsisText text={getPayerName(payment.payerId)} length={'30'} />
                              </td>
                              <td>
                                <EllipsisText text={getBranchName(payment.tenantId)} length={'30'} />
                              </td>
                              <td>
                                <EllipsisText
                                  text={
                                    dayjs(
                                      get(
                                        payment.responses.filter((el) => el.type === 'payment-reconciliation'),
                                        '[0].response.paymentDate',
                                        '',
                                      ),
                                      'YYYY-MM-DD',
                                    ).format(DATE_FORMAT) || ''
                                  }
                                  length={'30'}
                                />
                              </td>
                              <td>
                                <EllipsisText
                                  text={`${get(payment, 'responses[0].response.totalPayment.value', '')} ${get(
                                    payment,
                                    'responses[0].response.totalPayment.currency',
                                    '',
                                  )}`}
                                  length={'30'}
                                />
                              </td>
                              <td>
                                <div
                                  className={classes.viewPaymentBtn}
                                  role="button"
                                  onClick={() => handleViewPayment(payment.id)}
                                >
                                  <img src={infoIcon} alt="view" />
                                  <p className={classes.viewPayment}>View</p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                />
              </KlaimPaginationContainer>
            ) : (
              <div className={classes.paginationContainer2}>
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
