import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    border: `2px solid ${Colors.grayLight}`,
    borderRadius: '100px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    '& img': {
      paddingLeft: 6,
      paddingRight: 6,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& span': {
      margin: '0px',
      fontSize: '12px',
      padding: '4px',
      fontWeight: 'bold',
    },
  },
  borderLeft: {
    borderLeft: `2px solid ${Colors.grayLight}`,
  },
  borderRight: {
    borderRight: `2px solid ${Colors.grayLight}`,
  },
  backgroundColor1: {
    backgroundColor: 'rgba(108, 99, 255, 0.2)',
    borderColor: 'rgba(108, 99, 255, 0.3)',
    '& span': {
      borderColor: 'rgba(108, 99, 255, 0.3)',
    },
    color: 'rgba(108, 99, 255, 1)',
  },
  backgroundColor2: {
    backgroundColor: 'rgba(13, 219, 68, 0.2)',
    borderColor: 'rgba(13, 219, 68, 0.3)',
    '& span': {
      borderColor: 'rgba(13, 219, 68, 0.3)',
    },
    color: 'rgba(13, 219, 68, 1)',
  },
  backgroundColor3: {
    backgroundColor: 'rgba(219, 108, 13, 0.2)',
    borderColor: 'rgba(219, 108, 13, 0.3)',
    '& span': {
      borderColor: 'rgba(219, 108, 13, 0.3)',
    },
    color: 'rgba(219, 108, 13, 1)',
  },
  backgroundColor4: {
    backgroundColor: 'rgba(219, 84, 13, 0.2)',
    borderColor: 'rgba(219, 84, 13, 0.3)',
    '& span': {
      borderColor: 'rgba(219, 84, 13, 0.3)',
    },
    color: 'rgba(219, 84, 13, 1)',
  },
  backgroundColor5: {
    backgroundColor: 'rgba(137, 30, 204, 0.2)',
    borderColor: 'rgba(137, 30, 204, 0.3)',
    '& span': {
      borderColor: 'rgba(137, 30, 204, 0.3)',
    },
    color: 'rgba(137, 30, 204, 1)',
  },
  backgroundColor6: {
    backgroundColor: 'rgba(219, 13, 29, 0.2)',
    borderColor: 'rgba(219, 13, 29, 0.3)',
    '& span': {
      borderColor: 'rgba(219, 13, 29, 0.3)',
    },
    color: 'rgba(219, 13, 29, 1)',
  },
  backgroundColor7: {
    backgroundColor: 'rgba(247, 100, 179, 0.2)',
    borderColor: 'rgba(247, 100, 179, 0.3)',
    '& span': {
      borderColor: 'rgba(247, 100, 179, 0.3)',
    },
    color: 'rgba(247, 100, 179, 1)',
  },
  backgroundColor8: {
    backgroundColor: 'rgba(9, 195, 153, 0.2)',
    borderColor: 'rgba(9, 195, 153, 0.3)',
    '& span': {
      borderColor: 'rgba(9, 195, 153, 0.3)',
    },
    color: 'rgba(9, 195, 153, 1)',
  },
  backgroundColor9: {
    backgroundColor: 'rgba(204, 30, 97, 0.2)',
    borderColor: 'rgba(204, 30, 97, 0.3)',
    '& span': {
      borderColor: 'rgba(204, 30, 97, 0.3)',
    },
    color: 'rgba(204, 30, 97, 1)',
  },
});
