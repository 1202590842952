import React from 'react';

interface IScreen {
  index: number;
  name: string;
}

export interface IScreens {
  currentScreen: IScreen;
  setCurrentScreen: (currentScreen: IScreen) => void;
  screens: IScreen[];
  setScreens: (screens: IScreen[]) => void;
}

export const KlaimScreenContext = React.createContext<IScreens>({
  currentScreen: {
    index: 0,
    name: 'default',
  },
  setCurrentScreen: () => {},
  screens: [],
  setScreens: () => {},
});

const KlaimScreens: React.FC = ({children}) => {
  const [currentScreen, setCurrentScreen] = React.useState<IScreen>({
    index: 0,
    name: 'default',
  });
  const [screens, setScreens] = React.useState<IScreen[]>([]);
  return (
    <KlaimScreenContext.Provider value={{currentScreen, setCurrentScreen, screens, setScreens}}>
      {children}
    </KlaimScreenContext.Provider>
  );
};

export default KlaimScreens;
