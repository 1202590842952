import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
    // padding: 10,
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: `1px ${Colors.purpleLight} solid`,
  },
  defaultContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& h3': {
      fontWeight: 'bold',
      fontSize: 18,
      marginRight: 20,
    },
    justifyContent: 'space-between',
    '& button': {
      background: 'none',
      border: 'none',
      padding: '10px 0 10px 0',
      cursor: 'pointer',
    },
  },
});
