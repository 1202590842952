import React from 'react';
import EligibilityDetails from 'modules/Eligibility/features/EligibilityDetails';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {error as errorToast} from 'modules/Common/utils/toast';
import {
  eligibilityCleanup,
  eligibilityErrorCleanup,
  eligibilitySelect,
  getEligibility,
} from 'modules/Eligibility/eligibilitySlice';
import {headerSelect, setNavButton} from '../../../Header/headerSlice';

interface RouteParams {
  id: string;
}

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EligibilityHistoryDetails: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: {id},
  },
}) => {
  const dispatch = useAppDispatch();
  const {eligibility, error} = useAppSelector(eligibilitySelect);
  const query = useQuery();
  const providerId = query.get('providerId');
  React.useEffect(() => {
    dispatch(getEligibility({id, providerId: providerId || ''}));
  }, [providerId, id]);

  React.useEffect(() => {
    dispatch(setNavButton(false));
    return () => {
      dispatch(eligibilityCleanup());
      dispatch(eligibilityErrorCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      errorToast(error.message);
    }
  }, [error]);

  return (
    <div>
      <EligibilityDetails eligibility={eligibility} />
    </div>
  );
};

export default EligibilityHistoryDetails;
