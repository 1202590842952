import React from 'react';
import RouteWithSubRoutes from 'modules/Route/RouteWithSubRoutes';
import {Switch} from 'react-router-dom';
import {ComponentProps, useStylesFromThemeFunction} from 'modules/Main/Body/Body';

const Body: React.FC<ComponentProps> = ({routes, sidebarIsOpen}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div
      id="body"
      className={`${classes.container} ${sidebarIsOpen ? classes.bodyContainerOpen : classes.bodyContainerClose}`}
    >
      <Switch>
        {routes &&
          routes.map(({routeKey, ...route}) => {
            return <RouteWithSubRoutes key={routeKey} routeKey={routeKey} {...route} />;
          })}
      </Switch>
    </div>
  );
};

export default Body;
