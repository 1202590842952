import React from 'react';
import {useLocation, RouteComponentProps} from 'react-router-dom';
import MaleIcon from 'assets/Male.png';
import FemaleIcon from 'assets/Female.png';
import moreIcon from 'assets/more_icon.svg';
import Table from 'modules/Common/components/Table';
import {useStylesFromThemeFunction} from 'modules/Eligibility/features/EligibilityHistory/EligibilityHistory';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import OptionTabs from 'modules/Eligibility/components/OptionTabs';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  eligibilityCleanup,
  eligibilityErrorCleanup,
  eligibilitySelect,
  getEligibilities,
} from 'modules/Eligibility/eligibilitySlice';
import {headerSelect, setNavButton, setRequiredFilters} from 'modules/Header/headerSlice';
import {EL} from '@klaim-ai/klaim-interfaces';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {error as errorToast} from 'modules/Common/utils/toast';
import dayjs from 'dayjs';
import ErrorExclamationIcon from 'assets/component/ErrorExclamaitonIcon';
import EditEligibilityIcon from 'assets/component/EditEligibilityIcon';
import KlaimDropdownContainer from '../../../Common/components/KlaimDropdownContainer';

const EligibilityHistory: React.FC<RouteComponentProps> = ({match, history}) => {
  const classes = useStylesFromThemeFunction();
  const location = useLocation();

  const searchTyping = new URLSearchParams(location.search).get('search');
  const node = React.useRef<HTMLTableRowElement>(null);
  const [hover, setHover] = React.useState<number | undefined>(undefined);
  const [optionTabs, setOptionTabs] = React.useState<number | undefined>(undefined);
  const [yPosition, setYPosition] = React.useState<number>(0);
  const [xPosition, setXPosition] = React.useState<number>(0);
  const [limit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const {selectedBranches} = useAppSelector(headerSelect);

  const dispatch = useAppDispatch();
  const {
    eligibilities: {data, count},
    isLoading,
    error,
  } = useAppSelector(eligibilitySelect);

  React.useEffect(() => {
    if (selectedBranches.length > 0) {
      dispatch(
        getEligibilities({
          limit,
          offset: (offset - 1) * limit,
          search,
          providerId: selectedBranches.map((provider) => provider.value).join(','),
        }),
      );
    }
  }, [offset, selectedBranches, search]);

  React.useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchTyping || ''), 500);
    return () => clearTimeout(delayFn);
  }, [searchTyping]);

  React.useEffect(() => {
    dispatch(
      setRequiredFilters({
        branchesFilter: true,
        eligibilityButtons: true,
        searchParams: true,
      }),
    );
    dispatch(setNavButton(true));
    return () => {
      dispatch(eligibilityCleanup());
      dispatch(eligibilityErrorCleanup());
      dispatch(
        setRequiredFilters({
          branchesFilter: false,
          eligibilityButtons: false,
          searchParams: false,
        }),
      );
      dispatch(setNavButton(false));
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      errorToast(error.message);
    }
  }, [error]);

  let exitTimeout: NodeJS.Timeout;

  const handleViewClick = (id: any, providerId: string) => {
    history.push(`${match.url}/details/${id}?providerId=${providerId}`);
  };

  const statusCheck = (status: string) => {
    if (status.toLowerCase() === 'inforce') {
      return (
        <div className={classes.statusContainer}>
          <div className={`${classes.defaultStatus} ${classes.egligible}`} />
          <span>Eligible</span>
        </div>
      );
    }
    if (status.toLowerCase() === 'notinforce') {
      return (
        <div className={classes.statusContainer}>
          <div className={`${classes.defaultStatus} ${classes.inegligible}`} />
          <span>InEligible</span>
        </div>
      );
    }
    return '';
  };

  const statusActionCheck = (status: string, id: string, onViewClick: (id: string) => void) => {
    if (status.toLowerCase() === 'inforce') {
      return (
        <div
          className={`${classes.button} ${classes.actionContainerDefault} ${classes.navLink}`}
          onClick={() => onViewClick(id)}
          role="button"
        >
          <ErrorExclamationIcon />
          View
        </div>
      );
    }
    if (status.toLowerCase() === 'notinforce') {
      return (
        <div
          className={`${classes.button} ${classes.navLink} ${classes.actionContainerDefault} ${classes.actionEdit}`}
          onClick={() => {
            history.push(`${match.url}/form/${id}`);
          }}
          role="button"
        >
          <EditEligibilityIcon /> Edit
        </div>
      );
    }
    return '';
  };

  const handleView = (id: string, providerId: string) => {
    history.push(`${match.url}/details/${id}?providerId=${providerId}`);
  };

  const handlePA = (id: string, providerId) => {
    history.push(`/pre-auth/form?providerId=${providerId}&eligibilityId=${id}`);
  };
  const handleResubmit = (id: string) => {
    history.push(`${match.url}/form/${id}`);
  };

  const handleClaim = (id: string, providerId) => {
    history.push(`/claims/form?providerId=${providerId}&eligibilityId=${id}`);
  };

  if (isLoading) {
    return <LoadingSpinner customClass={classes.loader} />;
  }

  return (
    <div className={classes.container}>
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={count}
        currentPage={offset}
        onPageChange={setOffset}
        label="Eligibilites"
      >
        <Table
          tableHeadings={[
            <p>Patient info</p>,
            <p>Status</p>,
            <p>Insurance ID</p>,
            <p>Age</p>,
            <p>Entry Date</p>,
            <p>Action</p>,
          ]}
          renderBody={() => (
            <>
              {data &&
                data.map((tblData: EL.Model.Eligibility, i: number) => {
                  return (
                    <tr
                      onMouseEnter={() => {
                        if (exitTimeout) {
                          clearTimeout(exitTimeout);
                        }
                        setHover(i);
                      }}
                      onMouseLeave={() => {
                        // exitTimeout = setTimeout(() => {
                        setHover(undefined);
                        setOptionTabs(undefined);
                        // }, 100);
                      }}
                      onContextMenu={(e: React.MouseEvent) => {
                        e.preventDefault();
                        setOptionTabs(i);
                        setYPosition(e.clientY);
                        setXPosition(e.clientX);
                      }}
                      className={` ${hover === i ? classes.hover : classes.tableContainer}`}
                      key={i}
                      ref={node}
                    >
                      <td className={classes.personalInformationContainer}>
                        {optionTabs === i && (
                          <OptionTabs
                            positionX={xPosition}
                            positionY={yPosition}
                            onViewClick={() => handleViewClick(tblData.id, tblData.tenantId)}
                            id={tblData.id}
                          />
                        )}
                        <div className={classes.iconContainer}>
                          <img src={tblData.patient.gender === 'male' ? MaleIcon : FemaleIcon} alt="genderIcon" />
                        </div>
                        <span>{tblData.patient.name}</span>
                      </td>
                      <td>{statusCheck(tblData.status)}</td>
                      <td>{tblData.payerId}</td>
                      <td>{dayjs(new Date()).diff(tblData.patient.dateOfBirth, 'year')} years old</td>
                      <td>{dayjs(tblData.date).format('DD MMM YYYY ')}</td>
                      <td>
                        <KlaimDropdownContainer isIconButton icon={moreIcon} position="bottomLeft">
                          {tblData.status.toLowerCase() === 'inforce' && (
                            <>
                              <div
                                role="button"
                                onClick={() => handleView(tblData.id, tblData.tenantId)}
                                className={`${classes.button} ${classes.navLink}`}
                              >
                                View
                              </div>
                              <div
                                role="button"
                                onClick={() => handlePA(tblData.id, tblData.tenantId)}
                                className={`${classes.button} ${classes.navLink}`}
                              >
                                Proceed to PA
                              </div>
                              {/* <div
                                role="button"
                                onClick={() => handleClaim(tblData.id, tblData.tenantId)}
                                className={`${classes.button} ${classes.navLink}`}
                              >
                                Proceed to Claim
                              </div> */}
                            </>
                          )}
                          {tblData.status.toLowerCase() === 'notinforce' && (
                            <div
                              role="button"
                              onClick={() => handleResubmit(tblData.id)}
                              className={`${classes.button} ${classes.navLink}`}
                            >
                              Edit
                            </div>
                          )}
                        </KlaimDropdownContainer>
                      </td>
                    </tr>
                  );
                })}
            </>
          )}
        />
      </KlaimPaginationContainer>
    </div>
  );
};

export default EligibilityHistory;
