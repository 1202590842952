export enum ReportDateTypes {
  SubmissionDate = 'submission',
  SubmissionDateLabel = 'First Submission Date',
  EncounterDate = 'encounter',
  EncounterDateLabel = 'Encounter Date',
  StartDateLabel = 'Start Date',
  RemittanceDate = 'remittance',
  RemittanceDateLabel = 'Remittance Date',
  SubmissionDateOptionLabel = 'Submission Date',
  SettlementDateLabel = 'Settlement Date',
}

export enum ReportConsolidatedTypes {
  ClaimOptionValue = 'claim',
  ClaimLabel = 'Claim',
  ActivityLabel = 'Activity',
  ActivityValue = 'activity',
  RemitancesAdviceLabel = 'Remittance Advice (and activity)',
  RemitancesAdviceValue = 'remittance_advice',
}

export enum ReportFilterDateFormat {
  dateFormat = 'MM/dd/yyyy',
}

export enum ProgressBarStatus {
  Start = 'Start',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancel = 'Cancel',
  NotFound = 'No Found',
  Error = 'Something Went Wrong',
}

export enum ResponseStatus {
  Success = 'Success',
}

export enum ReportFilterTypes {
  InsurerLabel = 'Insurer',
  TPALabel = 'TPA',
  Insurance = 'Insurance',
}
