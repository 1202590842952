import React from 'react';
import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom';
import moreIcon from 'assets/more_icon.svg';
import EditIcon from 'assets/component/EditIcon';
import DeleteIcon from 'assets/component/DeleteIcon';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {
  insuranceCompanyManagementSelect,
  deleteInsurance,
  deleteCleanup,
} from 'modules/Settings/features/InsuranceCompanies/insuranceManagementSlice';
import {useStylesFromThemeFunction} from 'modules/Settings/features/InsuranceCompanies/InsuranceCompaniesList/ListItem/ListItem';
import KlaimModal from 'modules/Common/components/KlaimModal';
import {useAppSelector} from 'modules/App/store';
import * as Toast from 'modules/Common/utils/toast';

interface ComponentProps {
  insurance: any;
  index: number;
  offset: number;
  limit: number;
}
const ListItem: React.FC<ComponentProps> = ({
  insurance: {id, name, branch, insurances, tpas, active},
  index,
  offset,
  limit,
}) => {
  const {isDeleted} = useAppSelector(insuranceCompanyManagementSelect);
  const classes = useStylesFromThemeFunction();
  const dispatch = useDispatch();
  const [rowsOpen, setRowsOpen] = React.useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const status: string = active ? 'active' : 'inactive';

  // const tpaPlus = tpas?.length > 1 ? `+ ${tpas?.length - 1}` : '';
  // const tpaDisplay = tpas?.length > 0 ? `${tpas ? tpas[0] : ''} ${tpaPlus}` : 'No TPA added';
  const tpaPlusDisplay = () => {
    if (insurances?.length > 0) {
      if (insurances[0].tpas.length > 0) {
        return `+ ${insurances[0].tpas.length - 1}`;
      }
      return '';
    }
    return '';
  };
  const tpaDisplay =
    insurances?.length > 0
      ? `${insurances[0].tpas[0] ? insurances[0].tpas[0] : 'No TPA added'} ${tpaPlusDisplay()}`
      : 'No TPA added';

  const insurancesPlus = insurances?.length > 1 ? `+ ${insurances?.length - 1}` : '';
  const insurancesDisplay =
    insurances?.length > 0
      ? `${insurances && insurances[0] && insurances[0].name ? insurances[0].name : '-'} ${insurancesPlus}`
      : 'No Insurance Added';

  const triggerDelete = (_id: number) => {
    dispatch(deleteInsurance({id: _id}));
  };

  React.useEffect(() => {
    let deleteTimeout: any;
    if (isDeleted === true) {
      deleteTimeout = setTimeout(() => {
        Toast.success('Insurance company is deleted successfully', 'Success');
        setDeleteOpen(false);
      }, 500);
    }
    clearTimeout(deleteTimeout);
    return () => {
      dispatch(deleteCleanup());
      clearTimeout(deleteTimeout);
    };
  }, [isDeleted]);

  return (
    <li className={classes.treeViewList} key={id}>
      <KlaimModal
        isOpen={deleteOpen}
        onRequestClose={() => setDeleteOpen(false)}
        contentStyle={{
          left: '50%',
          maxHeight: '250px',
          maxWidth: '550px',
          padding: 10,
          top: '35%',
          transform: 'translateX(-50%)',
        }}
      >
        <div className={classes.modalContainer}>
          <p className={classes.modalText}>
            Are you sure you want to delete insurance: <span>{name}</span>
          </p>
          <div className={classes.modalButtonContainer}>
            <button
              className={`${classes.modalButton} ${classes.modalYesButton}`}
              onClick={() => {
                triggerDelete(id);
              }}
            >
              Yes
            </button>
            <button
              className={`${classes.modalButton} ${classes.modalCancelButton}`}
              onClick={() => setDeleteOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </KlaimModal>
      <div className={`${classes.RowWrapper} ${classes.topNone}`} role="button" onClick={() => setRowsOpen(!rowsOpen)}>
        <div className={classes.rowData}>{name}</div>
        <div className={classes.rowData}>{tpaDisplay}</div>
        <div className={classes.rowData}>{insurancesDisplay}</div>
        <div className={classes.rowData}>{branch?.name || '-'}</div>
        <div className={classes.rowData}>
          <div className={`bordered ${status}`}>{status}</div>
        </div>

        <KlaimDropdownContainer isIconButton icon={moreIcon}>
          {/* <NavLink to={`/settings/insurance-companies/edit/${id}`} className={`${classes.button} ${classes.navLink}`}>
            <div className={classes.buttonIcon}>
              <EditIcon />
            </div>
            Edit Insurance
          </NavLink> */}
          <button className={classes.button} onClick={() => setDeleteOpen(true)}>
            <div className={classes.buttonIcon}>
              <DeleteIcon />
            </div>
            Delete Insurance
          </button>
        </KlaimDropdownContainer>
      </div>
    </li>
  );
};
export default ListItem;
