import React from 'react';
import {useTranslation} from 'react-i18next';
import {getIn} from 'formik';
import {ID_TYPE} from '../../api/types';
import Input from '../../../Common/components/Input';
import SelectOptions from '../../../Common/components/Select';

interface ComponentProps {
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  setFieldValue: any;
  classes: any;
}

export const getAutoFormattedValue = (after: string, before: string, pattern: [number, string][]) => {
  const isDeleting = !after || !before || after.length <= before.length;
  if (isDeleting) return undefined;

  if (before.length > 0) {
    const lastCharBefore = before[before.length - 1];
    const newChar = after[after.length - 1];
    const newCharShouldBeIgnored = ['/', ' ', ':'].includes(lastCharBefore) && ['/', ' ', ':'].includes(newChar);
    if (newCharShouldBeIgnored) return before;
  }

  const autoFormattingNewChar = pattern.find(([position]) => position === after.length);
  if (autoFormattingNewChar) return after + autoFormattingNewChar[1];

  const autoFormattingMissingChar = pattern.find(
    ([position, char]) => position === before.length && after[after.length - 1] !== char,
  );
  if (autoFormattingMissingChar)
    return after.slice(0, after.length - 1) + autoFormattingMissingChar[1] + after[after.length - 1];
};

const PatientInformation: React.FC<ComponentProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  classes,
}) => {
  const {t} = useTranslation();

  const handleInputChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event;
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const handleInputIDChangeSecond = (event: any, fieldName: any, field: any, arrayHelpers?: any) => {
    handleChange(event);
    fieldName[field] = event.target.value !== undefined ? event.target.value : '';
    if (arrayHelpers) {
      arrayHelpers();
    }
    setFieldTouched(fieldName);
  };

  const setEmiratesIdNumber = (e: string) => {
    switch (e) {
      case 'national':
        values.emiratesIdNumber = '000-0000-0000000-0';
        break;
      case 'expatriate':
        values.emiratesIdNumber = '111-1111-1111111-1';
        break;
      case 'other':
        values.emiratesIdNumber = '222-2222-2222222-2';
        break;
      case 'unknown':
        values.emiratesIdNumber = '999-9999-9999999-9';
        break;
      case 'manual':
        values.emiratesIdNumber = '';
        break;
      default:
        break;
    }
  };

  const onEmiratesIdChange = (before: string, after: string) => {
    const pattern: [number, string][] = [
      [3, '-'],
      [8, '-'],
      [16, '-'],
    ];
    const newValue = getAutoFormattedValue(after, before, pattern);
    if (!newValue) return;
    setFieldValue('emiratesIdNumber', newValue);
  };

  return (
    <div className={classes.formSectionElementsWrapper}>
      <h2>{t('Patient Information')}</h2>
      <SelectOptions
        options={ID_TYPE}
        selectClassName={classes.formElementWithBottomSpacing}
        label={t('ID Type')}
        value={values.idType}
        onChange={(e) => {
          handleInputChangeSecond(e, values, 'idType');
          setEmiratesIdNumber(e);
        }}
        placeholder={t('Choose ID Type')}
        isClearable={false}
      />
      {values.idType === 'manual' && (
        <Input
          name="emiratesIdNumber"
          label="Emirates ID"
          value={values.emiratesIdNumber}
          placeholder="123-1234-1234567-1"
          inputContainerClassName={classes.formElementWithBottomSpacing}
          onChange={(e) => handleInputChangeSecond(e, values, 'emiratesIdNumber')}
          onValueChange={(e) => onEmiratesIdChange(values.emiratesIdNumber, e)}
          maxLength={18}
          error={
            getIn(errors, 'emiratesIdNumber') && getIn(touched, 'emiratesIdNumber')
              ? getIn(errors, 'emiratesIdNumber')
              : null
          }
        />
      )}
      <Input
        name="encounter.patientId"
        label="Patient ID"
        value={typeof values.encounter?.patientId === undefined ? '' : values.encounter?.patientId}
        placeholder={t('Enter Patient ID')}
        inputContainerClassName={classes.formElementWithBottomSpacing}
        onChange={(e) => handleInputIDChangeSecond(e, values.encounter, 'patientId')}
        error={
          getIn(errors, 'encounter.patientId') && getIn(touched, 'encounter.patientId')
            ? getIn(errors, 'encounter.patientId')
            : null
        }
      />
    </div>
  );
};

export default PatientInformation;
