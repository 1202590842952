import React, {useState} from 'react';
import * as Yup from 'yup';
import {ISupportingInfo} from 'interfaces/pre-auth.interface';
import dayjs from 'dayjs';
import SupportingInfoSubForm from 'modules/SupportingInfo/features/SupportingInfoSubForm';
import {useAppSelector} from '../../../../../App/store';
import {KlaimButton, KlaimFieldWrapper, KlaimForm, KlaimInput} from '../../../../../Common/components-v2/KlaimForm';
import KlaimDatePicker from '../../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import vs from '../../../../../../assets/vital-signs.jpg';
import Section from './Section';
import {branchManagementSelect} from '../../../../../Settings/features/BranchManagement/branchManagementSlice';
import * as Toast from '../../../../../Common/utils/toast';
import {checkErroredFields} from '..';

interface ComponentsProps {
  submission?: any;
  setSubmission?: any;
  editingItem?: any;
  providerId?: any;
  errors?: any;
}

const DATE_FORMAT = `YYYY-MM-DD`;
const formatDate = (date) => dayjs(date).format(DATE_FORMAT);
const EditActivityModal: React.FC<ComponentsProps> = ({editingItem, submission, setSubmission, providerId, errors}) => {
  const [episodeNo, setEpisodeNo] = useState(submission?.claim?.episode?.value);
  const [claimBillablePeriodStart, setClaimBillablePeriodStart] = useState(
    submission?.claim?.claimBillablePeriodStart
      ? dayjs(submission?.claim?.claimBillablePeriodStart, DATE_FORMAT).toDate()
      : new Date(),
  );
  const [claimBillablePeriodEnd, setClaimBillablePeriodEnd] = useState(
    submission?.claim?.claimBillablePeriodEnd
      ? dayjs(submission?.claim?.claimBillablePeriodEnd, DATE_FORMAT).toDate()
      : new Date(),
  );
  const {
    branches: {
      data: {branches},
    },
  } = useAppSelector(branchManagementSelect);
  const branch = branches.find((b) => b.identifier === providerId) || ({} as any);
  // const {invoice_system, episode_system} = branch;

  const handleSupportInfoChange = (value: ISupportingInfo[]) => {
    setSubmission({...submission, claim: {...submission.claim, supportingInfo: value}});
  };

  return (
    <div>
      <KlaimForm
        enableReinitialize
        initialValues={{
          item: editingItem,
          episodeNo: submission?.claim?.episode?.value,
          claimBillablePeriodStart,
          claimBillablePeriodEnd,
          // claimBillablePeriodStart: formatDate(submission?.claim?.claimBillablePeriodStart),
        }}
        validationSchema={Yup.object({})}
        onSubmit={() => {}}
        id="patient-form"
      >
        <Section title={'Claim'} img={vs}>
          <div style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', marginTop: 16}}>
            <KlaimFieldWrapper
              name={'claimBillablePeriodStart'}
              onChange={(value) => {
                setClaimBillablePeriodStart(value);
              }}
            >
              <KlaimDatePicker variant="secondary" label="Billable Period Start" value={claimBillablePeriodStart} />
            </KlaimFieldWrapper>
            <KlaimFieldWrapper name={'claimBillablePeriodEnd'} onChange={(value) => setClaimBillablePeriodEnd(value)}>
              <KlaimDatePicker variant="secondary" label="Billable Period End" />
            </KlaimFieldWrapper>
            <KlaimFieldWrapper
              name={'episodeNo'}
              onChange={(value) => {
                setEpisodeNo(value);
              }}
            >
              <KlaimInput label="Episode No" variant="secondary" placeholder="Episode No" value={episodeNo} />
            </KlaimFieldWrapper>

            <div style={{display: 'flex'}}>
              <KlaimButton
                style={{width: '100%'}}
                onClick={() => {
                  const obj = JSON.parse(JSON.stringify(submission));
                  setSubmission({
                    ...obj,
                    claim: {
                      ...obj.claim,
                      claimBillablePeriodStart: formatDate(claimBillablePeriodStart),
                      claimBillablePeriodEnd: formatDate(claimBillablePeriodEnd),
                      episode: {
                        value: episodeNo,
                        system: branch.episode_system,
                      },
                    },
                  });
                  Toast.success('value saved locally!');
                }}
                variant="primary"
              >
                {'SAVE'}
              </KlaimButton>
            </div>
          </div>
        </Section>
        {/*<br />*/}
        <Section title={'Supporting Info'} img={vs}>
          <SupportingInfoSubForm
            onChange={handleSupportInfoChange}
            initialSupportingInfos={submission?.claim?.supportingInfo || []}
            diagnosis={submission?.claim?.diagnosis}
            initialValues={null}
          />
        </Section>
        <Section
          title={'Claim Services'}
          img={vs}
          style={{
            marginBottom: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            border: checkErroredFields(errors, 'item') ? '1px solid red' : '',
          }}
        />
      </KlaimForm>
    </div>
  );
};

export default EditActivityModal;
