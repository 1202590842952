import React from 'react';
import {
  ComponentProps,
  OptionValue,
  useStylesFromThemeFunction,
} from 'modules/Common/components/KlaimSelect/KlaimSelect';

const KlaimSelect: React.FC<ComponentProps> = ({options, setValue, onChange, value, filter}) => {
  const classes = useStylesFromThemeFunction();
  const [selected, setSelected] = React.useState<OptionValue | null>(value !== undefined ? value : null);
  const [filtered, setFiltered] = React.useState<OptionValue[]>([]);
  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    setFiltered(options.filter((option) => option.value.includes(search.toLowerCase())));
  }, [search]);

  const onSelect = (option: OptionValue) => {
    setSelected(option);
    if (setValue) setValue(option);
    if (onChange) onChange(option);
  };

  return (
    <div className={classes.container}>
      {filter && (
        <div className={classes.searchContainer}>
          <input className={classes.searchInput} onChange={(e) => setSearch(e.target.value)} />
        </div>
      )}
      {filtered.map((option: OptionValue) => {
        return (
          <div className={classes.buttonContainer} key={option.value}>
            <button
              className={`${classes.optionButton} ${value && value.value === option.value && classes.activeText}`}
              type="button"
              onClick={() => onSelect(option)}
            >
              {option.leftIcon && <img src={option.leftIcon} alt="left" />}
              <span className={`${classes.optionText} `}>{option.label}</span>
              {option.rightIcon && <img src={option.rightIcon} alt="right" />}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default KlaimSelect;
