import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (value: any) => void;
  selectedCareTeamIndex?: number;
  careTeams?: any[];
}

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    maxWidth: 500,
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    rowGap: '1.25rem',
  },
});
