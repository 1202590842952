import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import React from 'react';

export interface ComponentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  branch: string;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'grid',
      rowGap: '1rem',
      margin: 20,
    },
    subContainer: {
      padding: 20,
      borderRadius: 10,
      background: theme.container.color.tertiary,
    },
    subContainerFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});
