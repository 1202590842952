import React from 'react';
import Datetime from 'react-datetime';
import {useStylesFromThemeFunction, ComponentProps} from './KlaimDateTimePicker';
import KlaimLabel from '../KlaimLabel';
import 'react-datetime/css/react-datetime.css';

const KlaimDatePicker: React.FC<ComponentProps> = (props) => {
  const {
    value = new Date(),
    onChange,
    isError,
    placeholder,
    isTouched,
    errors,
    name,
    label,
    variant,
    minDate,
    maxDate,
  } = props;
  const classes = useStylesFromThemeFunction(variant);
  const validateInput = (touched?: boolean, error?: boolean) => {
    if (touched && error) {
      return classes.inputContainerError;
    }
    if (touched) {
      return classes.inputContainerValidated;
    }
    return classes.inputContainerDefault;
  };
  return (
    <div className={classes.calendar}>
      {label && <KlaimLabel name={name} label={label} {...props} />}
      <Datetime
        className={`${classes.calendar} ${validateInput(isTouched, isError)}`}
        onChange={(date) => onChange(date)}
        value={value}
        dateFormat={'DD/MM/YYYY HH:MM'}
        inputProps={{
          placeholder,
        }}
      />
      <p className={'inputError'}>{errors}</p>
    </div>
  );
};

export default KlaimDatePicker;
