import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import React, {FC} from 'react';
import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    input: {
      '&:focus': {
        outline: 'none',
      },
      background: '#f5f7fb',
      border: '1px solid #e7ecf5',
      borderRadius: 10,
      boxSizing: 'border-box',
      height: 100,
      MozBoxSizing: 'border-box',
      paddingLeft: 15,
      paddingRight: 10,
      WebkitBoxSizing: 'border-box',
      width: '100%',
    },
    inputLabel: {
      color: '#222b45',
      fontSize: 12,
      fontWeight: theme.font.weightSemibold,
      marginBottom: 3,
      marginLeft: 10,
    },
    inputError: {
      color: 'red',
      fontSize: 12,
      marginBottom: 0,
      marginTop: 0,
      marginLeft: '15px',
      position: 'unset !important',
    },
    positionAbsolute: {
      position: 'absolute',
    },
  };
});

interface ComponentProps {
  label?: string;
  className?: string;
  value?: string;
  error?: string;
  onChange?: (e: any) => void;
  onValueChange?: (value: string) => void;
  placeholder?: string;
  theme?: KlaimTheme;
  name?: string;
}

const TextArea: FC<ComponentProps> = ({
  className,
  label,
  theme,
  value,
  onChange,
  onValueChange,
  placeholder,
  name,
  error = 'dada',
}) => {
  const classes = useStylesFromThemeFunction({theme});
  return (
    <div className={`${classes.positionAbsolute} ${className}`}>
      {label && <div className={classes.inputLabel}>{label}</div>}
      <textarea
        className={classes.input}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          if (onValueChange) {
            onValueChange(e.target.value);
          }
        }}
        name={name}
      />{' '}
      <p className={classes.inputError}>{error}</p>
    </div>
  );
};

export default TextArea;
