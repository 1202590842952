import React from 'react';
import {
  useStylesFromThemeFunction,
  ComponentProps,
} from 'modules/Common/components-v2/KlaimForm/components/KlaimToggle/KlaimToggle';

const KlaimToggle: React.FC<ComponentProps> = ({
  title,
  onChange,
  value: defaultValue = false,
  style,
  variant = 'primary',
}) => {
  const classes = useStylesFromThemeFunction();
  const [toggled, setToggled] = React.useState<boolean>(defaultValue);
  const onToggleChange = (e: any) => {
    setToggled(!toggled);
    if (onChange) onChange(!toggled);
  };
  return (
    <div className={`${classes.toggleContainer} ${variant === 'secondary' && classes.secondary}`} style={style}>
      <label className={classes.label}>{title}</label>
      <label className="switch">
        <input checked={toggled} type="checkbox" onChange={(e) => onToggleChange(e)} />
        <span className="slider" />
      </label>
    </div>
  );
};

export default KlaimToggle;
