import React from 'react';
import {EL} from '@klaim-ai/klaim-interfaces';
import {eligibilityErrorCleanup, eligibilitySelect} from 'modules/Eligibility/eligibilitySlice';
import {error as errorToast} from 'modules/Common/utils/toast';
import {useAppDispatch, useAppSelector} from '../../../App/store';
import {setRequiredFilters} from '../../../Header/headerSlice';
import Insurance from './components/Insurance';
import Patient from './components/Patient';

interface ComponentProps {
  eligibility?: EL.Model.Eligibility;
  insuranceName?: string;
}

const EligibilityDetails: React.FC<ComponentProps> = ({eligibility, insuranceName}) => {
  const {error} = useAppSelector(eligibilitySelect);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(
      setRequiredFilters({
        eligibilityButtons: true,
      }),
    );
    return () => {
      dispatch(eligibilityErrorCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      errorToast(error.message);
    }
  }, [error]);

  return (
    <div id="eligibility-details" style={{display: 'flex', flexDirection: 'column', flexGrow: 1, height: 100}}>
      <Patient eligibility={eligibility} />
      <Insurance eligibility={eligibility} />
      {/* <Action /> */}
    </div>
  );
};

export default EligibilityDetails;
