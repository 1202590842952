import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {
  KlaimCollapsible,
  KlaimCollapsibleHeader,
  KlaimCollapsibleBody,
} from 'modules/Common/components-v2/KlaimCollapsible';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import {ISupportingInfo} from 'interfaces/pre-auth.interface';
import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import SupportInfoModal from './SupportInfoModal';
import {getSupportInfoTitle} from '../../../../helpers/general-helper';

interface ComponentProps {
  onChange?: (value: any) => void;
  initialValues: ISupportingInfo[];
  diagnosis?: any;
  initialSupportingInfos?: any[];
}

const useStylesFromThemeFunction = createUseStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      fontSize: 14,
      color: Colors.purple,
    },
  },
  removeContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 10,
  },
});

const SupportingInfoSubForm: React.FC<ComponentProps> = ({
  onChange,
  initialValues,
  diagnosis,
  initialSupportingInfos,
}) => {
  const [supportingInfos, setSupportingInfos] = React.useState<ISupportingInfo[]>(
    initialSupportingInfos || initialValues,
  );
  const [selectedInfoIndex, setSelectedInfoIndex] = React.useState<any>(undefined);
  const classes = useStylesFromThemeFunction();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleSubmit = (value: any) => {
    if (selectedInfoIndex === undefined) {
      setSupportingInfos([...supportingInfos, {...value}]);
    } else {
      setSupportingInfos((state) => {
        const arr = [...state];
        arr[selectedInfoIndex] = {...value};
        return [...arr];
      });
    }
    setIsOpen(false);
  };
  const handleRemove = (index: number) => {
    setSupportingInfos(supportingInfos.filter((_, i: number) => i !== index));
  };
  const handleEdit = (index: number) => {
    setSelectedInfoIndex(index);
    setIsOpen(true);
  };
  React.useEffect(() => {
    if (!isOpen) setSelectedInfoIndex(undefined);
  }, [isOpen]);

  React.useEffect(() => {
    if (onChange) onChange(supportingInfos);
  }, [supportingInfos]);

  React.useEffect(() => {
    if ((!supportingInfos || supportingInfos.length === 0) && initialSupportingInfos?.length > 0) {
      setSupportingInfos(initialSupportingInfos);
    }
  }, [initialSupportingInfos]);

  return (
    <div style={{marginTop: 20}}>
      <KlaimContainer variant={'secondary'} style={{padding: 20}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <div>
            <h3 style={{fontSize: 14, fontWeight: 600, color: '#222B45', marginBottom: 8}}>
              Supporting Informations{supportingInfos.length > 0 && ` (${supportingInfos.length})`}
            </h3>
            <p style={{maxWidth: 500, fontSize: 12, color: '#696F81'}}>Add Supporting info</p>
          </div>
          <KlaimButton variant="link" onClick={() => setIsOpen(true)}>
            Add Support Info
          </KlaimButton>
        </div>
        {supportingInfos &&
          supportingInfos.map((suppInfo: ISupportingInfo, index: number) => (
            <KlaimCollapsible
              style={{
                marginTop: 20,
              }}
              key={index}
            >
              <KlaimCollapsibleHeader>
                <div className={classes.headerContainer}>
                  <h2>{getSupportInfoTitle(suppInfo.category)}</h2>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {/*<KlaimButton*/}
                    {/*  style={{*/}
                    {/*    minWidth: 'initial',*/}
                    {/*    minHeight: 'initial',*/}
                    {/*    backgroundColor: '#00000000',*/}
                    {/*    color: '#6135FB',*/}
                    {/*    borderRadius: 2,*/}
                    {/*    border: 0,*/}
                    {/*    padding: 6,*/}
                    {/*  }}*/}
                    {/*  onClick={() => handleEdit(index)}*/}
                    {/*>*/}
                    {/*  Edit*/}
                    {/*</KlaimButton>*/}
                    <KlaimButton
                      style={{
                        minWidth: 'initial',
                        minHeight: 'initial',
                        backgroundColor: '#00000000',
                        color: '#F00',
                        borderRadius: 2,
                        border: 0,
                        padding: 6,
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </KlaimButton>
                  </div>
                </div>
              </KlaimCollapsibleHeader>
              <KlaimCollapsibleBody>
                <div
                  style={{
                    padding: '1rem',
                    marginLeft: '2rem',
                    borderLeft: `2px solid ${Colors.blueGrayDark}`,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
                  }}
                >
                  {suppInfo.codeSystem && (
                    <KlaimValue direction="column" label="Code System" value={suppInfo.codeSystem} />
                  )}
                  {suppInfo.text && <KlaimValue direction="column" label="Text" value={suppInfo.text} />}

                  {suppInfo.code && <KlaimValue direction="column" label="Code" value={suppInfo.code} />}
                  {suppInfo.timingPeriod && (
                    <>
                      <KlaimValue direction="column" label="Timing Period Start" value={suppInfo.timingPeriod.start} />
                      <KlaimValue direction="column" label="Timing Period End" value={suppInfo.timingPeriod.end} />ß
                    </>
                  )}
                  {suppInfo.timingDate && (
                    <KlaimValue direction="column" label="Timing Date" value={suppInfo.timingDate} />
                  )}
                  {suppInfo.reasonSystem && (
                    <KlaimValue direction="column" label="Reason System" value={suppInfo.reasonSystem} />
                  )}
                  {suppInfo.reason && <KlaimValue direction="column" label="Reason" value={suppInfo.reason} />}
                  {suppInfo.valueBoolean && (
                    <KlaimValue direction="column" label="Boolean" value={suppInfo.valueBoolean.toString()} />
                  )}
                  {suppInfo.valueString && (
                    <KlaimValue direction="column" label="String" value={suppInfo.valueString} />
                  )}
                  {suppInfo.valueQuantity && (
                    <KlaimValue direction="column" label="Quantity" value={suppInfo.valueQuantity} />
                  )}
                  {suppInfo.valueAttachment && (
                    <KlaimValue direction="column" label="Attachment" value="Has Attachment" />
                  )}
                </div>
              </KlaimCollapsibleBody>
            </KlaimCollapsible>
          ))}
      </KlaimContainer>
      <SupportInfoModal isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={handleSubmit} diagnosis={diagnosis} />
    </div>
  );
};

export default SupportingInfoSubForm;
