import {combineReducers} from '@reduxjs/toolkit';
import accessManagementReducer from 'modules/Settings/features/AccessManagement/accessManagmentSlice';
import accountReducer from './features/AccountSettings/accountSettingsSlice';
import branchManagementReducer from './features/BranchManagement/branchManagementSlice';
import practitionerManagementReducer from './features/Practitioners/PractitionersSlice';
import insuranceManagementReducer from './features/Insurance/InsuranceSlice';
import insuranceCompanyManagementReducer from './features/InsuranceCompanies/insuranceManagementSlice';
import staffManagementReducer from './features/UserManagement/userManagementSlice';
import contractManagementReducer from './features/Contracts/ContractSlice';

export default combineReducers({
  accessManagement: accessManagementReducer,
  account: accountReducer,
  branchManagement: branchManagementReducer,
  insuranceManagement: insuranceManagementReducer,
  practitionerManagement: practitionerManagementReducer,
  insuranceCompanyManagement: insuranceCompanyManagementReducer,
  userManagement: staffManagementReducer,
  contractManagement: contractManagementReducer,
});
