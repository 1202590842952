import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {IPatient} from 'interfaces/patient.interface';

export interface ComponentProps {
  label?: string;
  name?: string;
  placeholder?: string;
  errorClassName?: string;
  containerClassName?: string;
  optional?: boolean;
  className?: any;
  onChange?: (value: any, insurance?: any) => void;
  branch: string;
  initialValue?: IPatient;
  patientId?: string;
  isEligiblity?: boolean;
  isHidden?: boolean;
}

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    contentContainer: {
      padding: 0,
    },
    label: {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 600,
      color: Colors.grayLabel,
    },
    error: {
      color: Colors.red,
      '& .searchSelect__control': {
        border: `1px solid ${Colors.red} !important`,
      },
      '& .searchSelect__menu': {
        color: Colors.black,
      },
    },
    validated: {
      '& .searchSelect__control': {
        border: `1px solid ${Colors.lightGreen} !important`,
      },
    },
    input: {
      height: 25,
      flex: 1,
      border: 'none',
      padding: 10,
      '&:focus': {
        outline: 'none',
      },
      background: 'none',
    },
    gridRow: {
      gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
      display: 'grid',
      padding: 8,
      borderRadius: 8,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: Colors.grayLight,
      },
    },
  };
});

export const customStyles = {
  control: (base: any, state: {isFocused: any}) => ({
    ...base,
    background: Colors.blueGrayLight,
    borderRadius: 10,
    height: 40,
    '&:hover': {borderColor: `${Colors.grayLight}`},
    border: `1px solid ${Colors.grayLight}`,
    boxShadow: 'none',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    borderRadius: 10,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 10,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: Colors.blueGrayDark,
    '&:hover': {
      color: Colors.blueGrayDark,
    },
  }),
  option: (base: any, state: {isSelected: any}) => ({
    ...base,
    backgroundColor: state.isSelected && Colors.purple,
    '&:hover': {backgroundColor: !state.isSelected && Colors.blueGrayLight},
    ':active': {
      ...base[':active'],
      backgroundColor: Colors.blueGrayLight,
    },
  }),
  singleValue: (base: any) => ({
    fontSize: '15px',
    marginLeft: '1px',
  }),
};
