import React from 'react';
import {KlaimScreenContext} from '../../index';

interface ComponentProps {
  goToIndex: number;
}

const KlaimScreenButtonWrapper: React.FC<ComponentProps> = ({children, goToIndex}) => {
  const {setCurrentScreen, screens} = React.useContext(KlaimScreenContext);
  const onClick = () => {
    setCurrentScreen(screens[goToIndex]);
  };

  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {onClick});
    }
    return child;
  });
  return <div>{childrenWithProps}</div>;
};

export default KlaimScreenButtonWrapper;
