import React from 'react';
import {useLocation} from 'react-router-dom';
import TopMenu from 'modules/Settings/components/TopMenu';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {useStylesFromThemeFunction} from 'modules/Settings/features/UserManagement/Users/Users';
import StaffManagementRow from 'modules/Settings/features/UserManagement/Users/UserTableRow';
import {permissions as permissionsConstant} from 'modules/Common/constants/Permissions';
import {
  getUsers,
  userManagementSelect,
  errorCleanUp,
} from 'modules/Settings/features/UserManagement/userManagementSlice';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import * as Toast from 'modules/Common/utils/toast';
import {RouteComponentProps} from 'react-router';

const Users: React.FC<RouteComponentProps> = ({history}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {
    users: {
      data: {users, total},
      limit,
    },
    isLoading,
    isDeleted,
    isEdit,
    isAdded,
  } = useAppSelector(userManagementSelect);
  const [offset, setOffset] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');
  const [sortBy] = React.useState<string>('');

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  React.useEffect(() => {
    const searchAction = setTimeout(() => {
      setSearch(query.get('search') || '');
    }, 2000);
    return () => clearTimeout(searchAction);
  }, [query.get('search')]);

  React.useEffect(() => {
    dispatch(
      getUsers({
        limit,
        offset: (offset - 1) * limit,
        search,
        sortBy,
      }),
    );
    return () => {
      dispatch(errorCleanUp());
    };
  }, [limit, offset, search, sortBy, isDeleted, isEdit, isAdded]);

  React.useEffect(() => {
    if (isEdit) Toast.success('User updated successfully', 'Success');
    if (isDeleted) Toast.success('User deleted successfully', 'Success');
  }, [isDeleted, isEdit]);

  return (
    <div className={classes.staffManagementWrapper}>
      <TopMenu
        title="User Management"
        description={<p>Look up, add and modify your users. Every invitation sent will also be listed here.</p>}
        buttonTitle="Add User"
        inputPlaceholder="Search User"
        onButtonClick={() => history.push('/settings/user-management/add-members')}
        requiredPermission={permissionsConstant.add_users.identifier}
      />
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={total}
        currentPage={offset}
        onPageChange={setOffset}
        label="personnels"
      >
        <p className={classes.subHeader}>User List</p>
        <div className={classes.staffManagement}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {users.length > 0 ? (
                users.map((user) => <StaffManagementRow user={user} />)
              ) : (
                <div style={{padding: 10}}>There are no users yet, add your first user by clicking "Add User"</div>
              )}
            </>
          )}
        </div>
      </KlaimPaginationContainer>
    </div>
  );
};

export default Users;
