import React, {forwardRef} from 'react';
import {Select as _Select, SelectProps as _SelectProps, RefSelectProps} from 'custom-test-antd';
import {FieldProps} from 'formik';

import Field from './Field';
import {FormikFieldProps} from './FieldProps';
import {FormItemProps, makeField} from './makeField';

type InternalSelectProps<T = any> = FieldProps & _SelectProps<T>;

type SelectFieldProps<T = any> = FormikFieldProps & _SelectProps<T>;

const InternalSelect = React.forwardRef(
  (
    {children, field, form, meta, onChange, onBlur, ...restProps}: InternalSelectProps,
    ref: React.Ref<RefSelectProps>,
  ) => {
    const {value, name} = field;
    const {setFieldValue, setFieldTouched} = form;
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_Select
        ref={ref}
        onChange={(newValue, option) => {
          setFieldValue(name, newValue);
          if (onChange) {
            onChange(newValue, option);
          }
        }}
        onBlur={(newValue) => {
          setFieldTouched(name);
          if (onBlur) {
            onBlur(newValue);
          }
        }}
        // setting undefined will show the placeholder
        value={value === '' || value === null ? undefined : value}
        {...restProps}
      >
        {children}
      </_Select>
    );
  },
);

InternalSelect.displayName = 'InternalSelect';

interface SelectComponent
  extends React.ForwardRefExoticComponent<InternalSelectProps & React.RefAttributes<RefSelectProps>> {
  Option: typeof _Select.Option;
  OptGroup: typeof _Select.OptGroup;
}

export const Select = InternalSelect as SelectComponent;
Select.Option = _Select.Option;
Select.OptGroup = _Select.OptGroup;

const InternalSelectField = forwardRef(
  ({name, validate, fast, ...restProps}: SelectFieldProps, ref: React.Ref<RefSelectProps>) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => <InternalSelect ref={ref} {...fieldProps} {...restProps} />}
    </Field>
  ),
);

InternalSelectField.displayName = 'InternalSelectField';

export type SelectProps = SelectFieldProps & React.RefAttributes<RefSelectProps>;

interface SelectFieldComponent extends React.ForwardRefExoticComponent<SelectProps> {
  Option: typeof _Select.Option;
  OptGroup: typeof _Select.OptGroup;
}

export const SelectField = InternalSelectField as SelectFieldComponent;
SelectField.Option = _Select.Option;
SelectField.OptGroup = _Select.OptGroup;

export type SelectWrapperProps = FormItemProps & _SelectProps & FormikFieldProps & React.RefAttributes<RefSelectProps>;

interface SelectWrapperComponent extends React.ForwardRefExoticComponent<SelectWrapperProps> {
  Option: typeof Select.Option;
  OptGroup: typeof Select.OptGroup;
}

export const SelectWrapper = makeField<_SelectProps>(Select) as SelectWrapperComponent;
SelectWrapper.Option = Select.Option;
SelectWrapper.OptGroup = Select.OptGroup;
