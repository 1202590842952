import React from 'react';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import Permission from 'modules/Settings/features/AccessManagement/PermissionBox/Permission';
import {useStylesFromThemeFunction} from 'modules/Settings/features/AccessManagement/PermissionBox/PermissionBox';
import {ErrorMessage} from 'formik';
import {Colors} from '../../../../Common/constants/Colors';

interface ComponentProps {
  permissions: any;
  rolePermissions: any;
  setFieldValues: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const PermissionBox: React.FC<ComponentProps> = ({setFieldValues, rolePermissions, permissions}) => {
  const classes = useStylesFromThemeFunction();

  const [newPermissions, setNewPermissions] = React.useState([]);
  const [selected, setSelected] = React.useState(rolePermissions || []);
  const [downloadPermissions, setDownloadPermissions] = React.useState<Array<any>>([]);
  const [viewPermissions, setViewPermissions] = React.useState<Array<any>>([]);
  const [listPermissions, setListPermissions] = React.useState<Array<any>>([]);
  const [deletePermissions, setDeletePermissions] = React.useState<Array<any>>([]);
  const [createPermissions, setCreatePermissions] = React.useState<Array<any>>([]);
  const [editPermissions, setEditPermissions] = React.useState<Array<any>>([]);
  const [submitPermissions, setSubmitPermissions] = React.useState<Array<any>>([]);
  const [removePermissions, setRemovePermissions] = React.useState<Array<any>>([]);
  const [resubmitPermissions, setReSubmitPermissions] = React.useState<Array<any>>([]);
  const [addPermissions, setAddPermissions] = React.useState<Array<any>>([]);
  const [cancelPermissions, setCancelPermissions] = React.useState<Array<any>>([]);
  const [sendPermissions, setSendPermissions] = React.useState<Array<any>>([]);
  const [pharmacyPermissions, setPharmacyPermissions] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    const newPermissionsx = permissions.filter((perm: any) => {
      const findx = !selected.find((rolePerm: any) => perm.id === rolePerm.id);
      return findx;
    });

    const downloadPermissionsx: Array<any> = [];
    const viewPermissionsx: Array<any> = [];
    const listPermissionsx: Array<any> = [];
    const createPermissionsx: Array<any> = [];
    const deletePermissionsx: Array<any> = [];
    const editPermissionsx: Array<any> = [];
    const removePermissionsx: Array<any> = [];
    const submitPermissionsx: Array<any> = [];
    const addPermissionsx: Array<any> = [];
    const resubmitPermissionsx: Array<any> = [];
    const cancelPermissionsx: Array<any> = [];
    const pharmacyPermissionsx: Array<any> = [];
    const sendPermissionsx: Array<any> = [];

    newPermissionsx.forEach((el: any) => {
      if (el.name.startsWith('Download')) {
        downloadPermissionsx.push(el);
      }

      if (el.name.startsWith('View')) {
        viewPermissionsx.push(el);
      }

      if (el.name.startsWith('List')) {
        listPermissionsx.push(el);
      }

      if (el.name.startsWith('Delete')) {
        deletePermissionsx.push(el);
      }

      if (el.name.startsWith('Create')) {
        createPermissionsx.push(el);
      }

      if (el.name.startsWith('Edit')) {
        editPermissionsx.push(el);
      }

      if (el.name.startsWith('Remove')) {
        removePermissionsx.push(el);
      }

      if (el.name.startsWith('Submit') && !el.name.includes('eRx')) {
        submitPermissionsx.push(el);
      }

      if (el.name.startsWith('Add')) {
        addPermissionsx.push(el);
      }

      if (el.name.startsWith('Resubmit') && !el.name.includes('eRx')) {
        resubmitPermissionsx.push(el);
      }

      if (el.name.startsWith('Cancel') && !el.name.includes('eRx')) {
        cancelPermissionsx.push(el);
      }

      if (el.name.startsWith('Send') && !el.name.includes('eRx')) {
        sendPermissionsx.push(el);
      }

      if (el.name.includes('eRx') || el.name.includes('eRX')) {
        pharmacyPermissionsx.push(el);
      }
    });

    setNewPermissions(newPermissionsx);
    setViewPermissions(viewPermissionsx);
    setDownloadPermissions(downloadPermissionsx);
    setListPermissions(listPermissionsx);
    setAddPermissions(addPermissionsx);
    setDeletePermissions(deletePermissionsx);
    setCreatePermissions(createPermissionsx);
    setEditPermissions(editPermissionsx);
    setRemovePermissions(removePermissionsx);
    setSubmitPermissions(submitPermissionsx);
    setReSubmitPermissions(resubmitPermissionsx);
    setCancelPermissions(cancelPermissionsx);
    setPharmacyPermissions(pharmacyPermissionsx);
    setSendPermissions(sendPermissionsx);
    setFieldValues('permissions', selected);
  }, [selected, permissions]);

  React.useEffect(() => {
    setSelected(rolePermissions);
  }, [rolePermissions]);

  const onAdd = (permission: any) => {
    const newSelected = [...selected, permission];
    setSelected(newSelected);
  };

  const onRemove = (permission: any) => {
    const newSelected = selected.filter((perm: any) => perm.id !== permission.id);
    setSelected(newSelected);
  };

  return (
    <div className={classes.permissionBoxContainer}>
      <label className={classes.labelText}>Permissions</label>
      <div id="permissionBox" className={classes.rolePermissionContainer}>
        {selected &&
          selected.map((per: any, index: number) => {
            document.getElementById('permissionBox').style.borderColor = Colors.grayLight;
            return <Permission key={index} isAdded permission={per} index={index} onRemove={onRemove} />;
          })}
      </div>
      <ErrorMessage name="permissions">
        {(message) => {
          const borderColorChanged =
            message && (document.getElementById('permissionBox').style.borderColor = Colors.red);
          return <span className={classes.errorText}>{message}</span>;
        }}
      </ErrorMessage>
      <p className={classes.paragraphText}>
        Add permissions for the new roles into the column above by choosing from the list of permissions below
      </p>
      <div className={classes.permissionsLabelView}>
        <p>View Permissions</p>
      </div>
      <div className={classes.permissionsContainer}>
        {viewPermissions &&
          viewPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Download Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {downloadPermissions &&
          downloadPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Pharmacy Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {pharmacyPermissions &&
          pharmacyPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>List Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {listPermissions &&
          listPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Create Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {createPermissions &&
          createPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Edit Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {editPermissions &&
          editPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Resubmit Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {resubmitPermissions &&
          resubmitPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Add Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {addPermissions &&
          addPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Submit Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {submitPermissions &&
          submitPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Delete Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {deletePermissions &&
          deletePermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Cancel Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {cancelPermissions &&
          cancelPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Remove Permissions</p>
      </div>

      <div className={classes.permissionsContainer}>
        {removePermissions &&
          removePermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>

      <div className={classes.permissionsLabel}>
        <p className={classes.permissionsLabel}>Send Permissions</p>
      </div>
      <div className={classes.permissionsContainer}>
        {sendPermissions &&
          sendPermissions.map((permission: any, index: number) => (
            <Permission key={index} permission={permission} index={index} onAdd={onAdd} />
          ))}
      </div>
    </div>
  );
};

export default PermissionBox;
