import React from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from '../../../../interfaces/klaim-theme.interface';

interface ComponentProps {
  direction?: 'row' | 'column';
  label: string;
  value: string | number;
  style?: React.CSSProperties;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      display: 'flex',
    },
    label: {
      fontSize: 10,
      fontWeight: 'semibold',
      color: '#696F81',
      textTransform: 'uppercase',
    },
    value: {
      fontSize: 14,
      color: '#2C273C',
      textTransform: 'capitalize',
    },
    containerColumn: {
      flexDirection: 'column',
    },
    containerRow: {
      alignItems: 'center',
    },
    labelRow: {
      marginRight: 8,
    },
    labelColumn: {
      marginBottom: 8,
    },
  };
});

const KlaimValue: React.FC<ComponentProps> = ({direction = 'row', label, value, style}) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div
      className={`${classes.container} ${direction === 'row' ? classes.containerRow : classes.containerColumn}`}
      style={style}
    >
      <span className={`${classes.label} ${direction === 'row' ? classes.labelRow : classes.labelColumn}`}>
        {label}
      </span>
      <span className={`${classes.value}`}>{value}</span>
    </div>
  );
};

export default KlaimValue;
