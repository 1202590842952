import React from 'react';
import {
  KlaimCollapsible,
  KlaimCollapsibleHeader,
  KlaimCollapsibleBody,
} from 'modules/Common/components-v2/KlaimCollapsible';
import {KlaimButton} from 'modules/Common/components-v2/KlaimForm';
import {IDiagnosis} from 'interfaces/pre-auth.interface';
import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import DiagnosisModal from './DiagnosisModal';

interface ComponentProps {
  onChange: (diagnosisList: IDiagnosis[]) => void;
  initialValues: any[];
  disableOnAdmission?: boolean;
}

const DiagnosisSubForm: React.FC<ComponentProps> = ({onChange, initialValues, disableOnAdmission}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [diagnosisList, setDiagnosisList] = React.useState<any[]>(initialValues);
  const [selectedDiagnosisIndex, setSelectedDiagnosisIndex] = React.useState<any>(undefined);
  const handleSubmit = (value: any) => {
    if (selectedDiagnosisIndex === undefined) {
      setDiagnosisList([...diagnosisList, {...value}]);
    } else {
      setDiagnosisList((state) => {
        const arr = [...state];
        arr[selectedDiagnosisIndex] = {...value};
        return [...arr];
      });
    }
    setIsOpen(false);
  };
  const handleRemove = (index: number) => {
    setDiagnosisList(diagnosisList.filter((_, i: number) => i !== index));
  };
  const handleEdit = (index: number) => {
    setSelectedDiagnosisIndex(index);
    setIsOpen(true);
  };
  React.useEffect(() => {
    if (!isOpen) setSelectedDiagnosisIndex(undefined);
  }, [isOpen]);

  React.useEffect(() => {
    const sanitizeData = diagnosisList.map((diag) => {
      const {diagCode, ...rest} = diag;
      return {
        ...rest,
        diagCode: diagCode.diagnosisCode,
      };
    });
    onChange(sanitizeData);
  }, [diagnosisList]);

  return (
    <div style={{marginTop: 20}}>
      <KlaimContainer variant="secondary" style={{padding: 20}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            marginBottom: 10,
          }}
        >
          <div>
            <h3 style={{fontSize: 14, fontWeight: 600, color: '#222B45', marginBottom: 8}}>
              Diagnosis{diagnosisList.length > 0 && ` (${diagnosisList.length})`}
            </h3>
            <p style={{maxWidth: 500, fontSize: 12, color: '#696F81'}}>Add Diagnosis</p>
          </div>
          <KlaimButton variant="link" onClick={() => setIsOpen(true)}>
            Add Diagnosis
          </KlaimButton>
        </div>
        <>
          {diagnosisList.map((diagnosis, index) => (
            <KlaimCollapsible variant={'primary-outline'} key={index} style={{marginBottom: 10}}>
              <KlaimCollapsibleHeader>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div
                    style={{
                      color: '#6135F9',
                      fontSize: 15,
                      fontWeight: 600,
                      textTransform: 'capitalize',
                    }}
                  >
                    {diagnosis.diagType}
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <KlaimButton
                      style={{
                        minWidth: 'initial',
                        minHeight: 'initial',
                        backgroundColor: '#00000000',
                        color: '#6135FB',
                        borderRadius: 2,
                        border: 0,
                        padding: 6,
                      }}
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </KlaimButton>
                    <KlaimButton
                      style={{
                        minWidth: 'initial',
                        minHeight: 'initial',
                        backgroundColor: '#00000000',
                        color: '#F00',
                        borderRadius: 2,
                        border: 0,
                        padding: 6,
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </KlaimButton>
                  </div>
                </div>
              </KlaimCollapsibleHeader>
              <KlaimCollapsibleBody>
                <div style={{padding: '0px 40px 0px 40px'}}>
                  <div
                    style={{
                      textTransform: 'capitalize',
                      fontSize: 15,
                    }}
                  >
                    <span style={{marginRight: 4}}>({diagnosis.diagCode.diagnosisCode || diagnosis.diagCode})</span>
                    {diagnosis.diagCode.longDescription}
                  </div>
                  <div style={{marginTop: 10}}>
                    <span style={{fontSize: 13, fontWeight: 600}}>On Admission:</span>
                    <span style={{fontSize: 14}}>{diagnosis.diagOnAdmission === 'y' ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </KlaimCollapsibleBody>
            </KlaimCollapsible>
          ))}
        </>
      </KlaimContainer>

      <DiagnosisModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={handleSubmit}
        diagnosisList={diagnosisList}
        selectedDiagnosisIndex={selectedDiagnosisIndex}
        disableOnAdmission={disableOnAdmission}
      />
    </div>
  );
};

export default DiagnosisSubForm;
