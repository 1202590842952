import React from 'react';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalFooter,
  KlaimModalHeader,
} from 'modules/Common/components-v2/KlaimModalv2';

interface ComponentsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const SubmittingModal: React.FC<ComponentsProps> = ({open, setOpen}) => {
  return (
    <KlaimModal open={open} setOpen={setOpen}>
      <KlaimModalHeader title="Redirecting" />
      <KlaimModalBody style={{padding: 20}}>Redirecting to Pre Auth Details.</KlaimModalBody>
      <KlaimModalFooter submitButtonTitle="Ok" onSubmit={() => setOpen(false)} showCancelButton={false} />
    </KlaimModal>
  );
};

export default SubmittingModal;
