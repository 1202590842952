import React from 'react';
import {ErrorMessage, Field, FieldProps, useFormikContext, getIn} from 'formik';
import {createUseStyles} from 'react-jss';
import _ from 'lodash';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

interface ComponentProps {
  type?: 'text' | 'number';
  name: string;
  onChange?: (value: any) => void;
  variant?: 'primary' | 'secondary' | 'primary-outline' | 'secondary-outline';
  style?: React.CSSProperties;
  canReset?: boolean;
  className?: any;
}

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    errorMessage: {
      color: theme.formElement.color.error,
    },
  };
});

const KlaimFieldWrapper: React.FC<ComponentProps> = (props) => {
  const {setFieldTouched, setFieldValue, getFieldMeta} = useFormikContext();
  const classes = useStylesFromThemeFunction();

  const {
    children,
    onChange: onChangeProp,
    name,
    variant = 'primary',
    style,
    className,
    type = 'text',
    canReset,
  } = props;
  const {value: initialValue} = getFieldMeta(name);
  React.useEffect(() => {
    // if (!_.isEmpty(initialValue)) {
    //   setFieldTouched(name, true);
    // }
    return () => {
      if (canReset) setFieldValue(name, undefined);
    };
  }, []);
  return (
    <div style={style} className={className}>
      <Field {...props} type={type}>
        {(fieldProps: FieldProps) => {
          const {field, form, meta} = fieldProps;
          const onChange = (value: any) => {
            form.setFieldValue(field.name, value);
            // if (value) {
            //   form.setFieldValue(field.name, value);
            // } else {
            //   form.setFieldValue(field.name, undefined);
            // }
            if (onChangeProp) onChangeProp(value);
          };
          const isTouched = meta.touched;
          const isError = getIn(form.errors, field.name) && isTouched;
          const errorMessage = getIn(form.errors, field.name);

          return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return (
                <div>
                  {React.cloneElement(child, {
                    onChange,
                    value: field.value,
                    isError,
                    isTouched,
                  })}
                  {isError && (
                    <div>
                      {typeof errorMessage === 'object' ? (
                        Object.keys(errorMessage).map((key) => (
                          <div className={classes.errorMessage}>{errorMessage[key]}</div>
                        ))
                      ) : (
                        <div className={classes.errorMessage}>{errorMessage}</div>
                      )}
                    </div>
                  )}
                </div>
              );
            }
            return child;
          });
        }}
      </Field>
    </div>
  );
};

export default KlaimFieldWrapper;
