import {FC} from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    activeBtn: {
      background: '#EAEEF7',
      border: '1px solid #6135FB !important',
      color: '#6135FB !important',
      fontWeight: '500',
    },
    buttonWrapper: {
      alignItems: 'center',
      border: '1px solid #E7ECF5',
      color: '#302E3898',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '15px',
      fontWeight: 600,
      height: '40px',
      justifyContent: 'center',
      width: '100%',
    },
    label: {
      color: '#222b45',
      fontSize: 12,
      fontWeight: theme.font.weightSemibold,
      marginBottom: 3,
      marginLeft: 10,
    },
    typeWrapper: {
      '& div:first-child': {
        borderRadius: '10px 0 0 10px',
      },
      '& div:last-child': {
        borderRadius: '0px 10px 10px 0px',
      },
      background: '#f5f7fb',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  };
});

interface ComponentProps {
  values?: Array<TypeOptions>;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  label?: string;
  theme?: KlaimTheme;
  className?: string;
}

interface TypeOptions {
  title: string;
  value: string;
}

const TypeSelect: FC<ComponentProps> = ({values, selectedValue, setSelectedValue, label, theme, className}) => {
  const classes = useStylesFromThemeFunction({theme});
  return (
    <div className={className}>
      {label && <div className={classes.label}>{label}</div>}
      <div className={`${classes.typeWrapper}`}>
        {values?.map((v, index) => {
          return (
            <div
              key={index}
              className={`${selectedValue === v.value ? classes.activeBtn : ''} ${classes.buttonWrapper}`}
              role="button"
              onClick={() => setSelectedValue(v.value)}
            >
              {v.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TypeSelect;
