import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  formatView?: 'month' | 'year' | 'decade' | 'century';
  value?: Date[] | undefined;
  selectsRange?: boolean;
  errorClassName?: string;
  showMonthYearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  optional?: boolean;
  isError?: boolean;
  isTouched?: boolean;
  placeholder?: string;
  variant?: 'primary' | 'with-input';
  onChange?: (date: any) => void;
}

export const useStylesFromThemeFunction = createUseStyles({
  inputClass: {
    padding: '10px 10px ',
    background: Colors.blueGrayLight,
    borderRadius: 10,
    border: `${Colors.grayLight} 1px solid`,
    display: 'flex',
    margin: 0,
    '& input': {
      background: 'none',
      fontFamily: 'poppins',
      border: 'none',
      fontSize: 16,
      flex: 1,
      '&:focus': {
        outline: 'none',
        borderColor: Colors.purple,
      },
    },
  },
  textValid: {
    color: Colors.successTextGreen,
  },
  textError: {
    color: Colors.red,
  },
  calendarFocus: {
    borderRadius: 10,
    border: `${Colors.purple} 1px solid`,
  },
  calendarError: {
    borderRadius: 10,
    border: `${Colors.red} 1px solid`,
  },
  calendarValid: {
    borderRadius: 10,
    border: `${Colors.successTextGreen} 1px solid`,
  },
  calendarWrapper: {
    '& .react-datepicker__header:not(.react-datepicker__header--has-time-select)': {
      background: Colors.white,
      border: 0,
      '& button': {
        background: 'none',
        border: 'none',
      },
      '& h1': {
        color: Colors.purple,
        fontWeight: 500,
        fontSize: 28,
      },
      '& p': {
        color: Colors.grayLabel,
        fontWeight: 600,
        fontSize: 14,
      },
      '& .react-datepicker__day-names': {
        background: Colors.blueGrayLight,
        borderTop: `1px solid ${Colors.grayLight}`,
        borderBottom: `1px solid ${Colors.grayLight}`,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        gap: 5,
        marginLeft: 'auto',
      },
      '& .react-datepicker__day-name': {
        height: 20,
        fontSize: 12,
        margin: 0,
        lineHeight: 2,
      },
    },
    '& .react-datepicker__month-container': {
      width: '100%',
      '& .react-datepicker__month-wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'center',
        '& .react-datepicker__month-text': {
          width: '25%',
          padding: 3,
          '&.react-datepicker__month--in-range': {
            background: Colors.purple,
            '&.react-datepicker__month-text--keyboard-selected': {
              // background: Colors.white,
              // color: Colors.black,
              background: `${Colors.purple} !important`,
              color: `${Colors.white} !important`,
            },
          },
          '&.react-datepicker__month-text--keyboard-selected': {
            background: Colors.purple,
          },
          '& .react-datepicker__day': {
            background: Colors.purple,
          },
          '& .react-datepicker__day--in-range ': {
            background: Colors.purple,
          },
          '& .react-datepicker__month--range-end': {
            background: Colors.purple,
          },
        },
      },
      '& .react-datepicker__month': {
        '& .react-datepicker__week': {
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          marginTop: 2,
          marginBottom: 2,
          '& .react-datepicker__day': {
            borderRadius: 5,
          },
          '& .react-datepicker__day--in-range': {
            background: Colors.purple,
          },
          '& .react-datepicker__day--in-selecting-range': {
            background: Colors.purpleLight,
            color: Colors.purple,
          },
          '& .react-datepicker__day--selected': {
            '& :hover': {
              background: Colors.purple,
            },
            background: Colors.purple,
            color: Colors.white,
          },
          '& .react-datepicker__day--outside-month': {
            background: Colors.grayWithOpacity,
          },
        },
      },
    },
    '& .react-datepicker__triangle': {
      display: 'none',
    },
    background: 'cf0',
    border: 0,
    minWidth: 200,
  },
  errorLabel: {
    color: Colors.red,
  },
  label: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '4px',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 'normal',
      fontSize: '13px',
    },
    fontWeight: 700,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropCalendarContainer: {
    padding: '10px 10px ',
    background: Colors.blueGrayLight,
    borderRadius: 10,
    border: `${Colors.grayLight} 1px solid`,
  },
  dropCalendarFocused: {
    border: `${Colors.purple} 1px solid`,
  },
  dropCalendarWrapper: {
    position: 'relative',
    padding: 10,
    fontFamily: 'poppins',
    fontSize: 16,
  },
  calendarContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 2,
  },
  calendarClass: {
    padding: '10px 10px ',
    background: Colors.blueGrayLight,
    borderRadius: 10,
    border: `${Colors.grayLight} 1px solid`,
    display: 'flex',
    margin: 0,
    width: '95%',
    fontFamily: 'poppins',
    fontSize: 16,
    '&:focus': {
      outline: 'none',
      borderColor: Colors.purple,
    },
  },
  calendarInlineContainer: {
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
  },
  error: {
    color: Colors.red,
    marginTop: 8,
  },
  validated: {
    color: Colors.lightGreen,
  },
  errorChild: {
    border: `${Colors.red} 1px solid`,
  },
  validatedChild: {
    border: `${Colors.lightGreen} 1px solid`,
  },
});
