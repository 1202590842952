import {createUseStyles, ThemeProvider} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import PlusButtonIcon from 'assets/plus_icon_with_background.svg';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export interface ComponentProps {
  branchesFilter?: boolean;
  dateTypeFilter?: boolean;
  dateRangeFilter?: boolean;
  statusFilter?: boolean;
  optionsFilter?: boolean;
  filterByDate?: boolean;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    filterContainer: {
      display: 'flex',
      justifyContent: 'justify-end',
      flexWrap: 'wrap',
    },

    filterItem: {
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 8,
    },
    optionFilters: {
      display: 'flex',
      cursor: 'pointer',
      gap: 5,
      fontSize: '14px',
      marginBottom: 20,
      '& img': {
        display: 'flex',
        justifyContent: 'start',
      },
      '& :hover': {
        background: Colors.blueGrayLight,
        color: Colors.purple,
      },
      '& label': {
        display: 'flex',
        justifyContent: 'start',
      },
    },
    filtersOptions: {
      padding: '20px',
      zIndex: '1',
      width: '130px',
    },
    headerContainer: {
      alignItems: 'center',
      background: 'white',
      borderRadius: 10,
      boxShadow: '0px 17px 20px #6061702E;',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 20,
      oveflow: 'hidden',
      position: 'relative',
    },
    headerContainer2: {
      alignItems: 'center',
      background: 'white',
      borderRadius: 10,
      boxShadow: '0px 16px 22px 3px rgb(96 97 112 / 16%)',
      display: 'flex',
      flexDirection: 'row',
      height: 83,
      marginBottom: 30,
      MozBoxShadow: '0px 16px 22px 3px rgba(96, 97, 112, 0.16)',
      paddingLeft: 30,
      paddingRight: 30,
      WebkitBoxShadow: '0px 16px 22px 3px rgb(96 97 112 / 16%)',
      zIndex: 3,
    },
    firstRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      width: '100%',
      padding: '24px',
      zIndex: 1,
      borderRadius: 10,
    },
    collapsibleRow: {
      zIndex: 0,
      width: 'calc(100% - 0px)',
      marginTop: '-55px',
      transition: 'all .8s ease',
      padding: '6px 12px',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    collapsibleRowExpended: {
      marginTop: '0px',
    },
    collapsibleTrigger: {
      backgroundColor: 'white',
      padding: 8,
      cursor: 'pointer',
      borderRadius: 6,
      marginLeft: 6,
      border: 'solid 1px #6135fb33',
      '& img': {
        margin: '0px !important',
      },
    },
    collapsibleHeader: {},
    searchWrapper: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
    },
    dropdownClassName: {
      paddingRight: 0,
      width: 200,
    },
    inputContainer: {
      '& input': {
        paddingLeft: 40,
      },
      marginLeft: '10px',
      marginRight: '10px',
      width: '300px',
    },
    inputWrapper: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    inputSpacing: {
      marginLeft: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    inputStyle: {
      '&:focus': {
        outline: 'none',
      },
      background: '#f5f7fb',
      border: '1px solid #e7ecf5',
      borderRadius: 10,
      boxSizing: 'border-box',
      height: 40,
      MozBoxSizing: 'border-box',
      paddingLeft: 10,
      paddingRight: 10,
      WebkitBoxSizing: 'border-box',
      width: '100%',
    },
    buttonIcon: {
      position: 'absolute',
      width: 42,
      height: 40,
      backgroundImage: `url(${PlusButtonIcon})`,
      backgroundRepeat: 'no-repeat',
      border: 0,
      backgroundColor: 'red',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      cursor: 'pointer',
    },
    mr20: {
      marginLeft: '20px',
      display: 'flex',
    },
    klaimBtn: {
      '&:hover': {
        cursor: 'pointer',
      },
      alignItems: 'center',
      background: '#6135fb',
      border: 'none',
      borderRadius: 10,
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
    },
    cancelBtn: {
      '&:hover': {
        cursor: 'pointer',
      },
      background: '#F5F7FB',
      alignItems: 'center',
      border: 'none',
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
      marginRight: 10,
    },
    branchesWidth: {
      width: 550,
    },
    editClaimBtn: {
      height: 42,
      fontWeight: 600,
    },
    submitAllBtn: {
      height: 42,
      fontWeight: 600,
      marginLeft: 10,
    },
    buttonContainer: {
      padding: 10,
      // background: Colors.blueGrayLight,
      background: Colors.purple,
      color: Colors.white,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 10,
      border: 'none',
      transition: 'all 0.1s',
      fontSize: 14,
      fontWeight: 'bold',
      minWidth: 137,
      '& p': {
        marginRight: 10,
      },
      '&:hover': {
        minWidth: 137,
        cursor: 'pointer',
        // background: Colors.purple,
        // color: Colors.white,
      },
      fontFamily: 'Poppins',
    },
  };
});
