import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    tableRow: {
      alignItems: 'center',
      background: Colors.white,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0',
      top: '2.5em',
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
    },
    hospitalInfo: {
      '& img': {
        marginRight: '26px',
      },
      alignItems: 'center',
      color: Colors.blueGrayDark,
      display: 'flex',
      flex: '1',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0px',
    },
    listCount: {
      justifyContent: 'center',
      alignItems: 'center',
      background: Colors.blueGrayLight,
      borderRadius: '50%',
      display: 'flex',
      height: 32,
      width: 32,
    },
    listCountWrapper: {
      flex: '0.2',
    },
    rowData: {
      color: Colors.blueGrayDark,
      flex: '1',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    staffProfile: {
      '& span': {
        color: Colors.blueGrayDark,
        fontSize: '12px',
        fontWeight: '500',
        marginLeft: '8px',
        opacity: '0.38',
      },
      alignItems: 'center',
      display: 'flex',
      flex: '1',
    },
    topNone: {
      top: 70,
    },
    treeViewList: {
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },

    button: {
      marginTop: 4,
      outline: 'none',
      backgroundColor: Colors.white,
      border: 'none',
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.blueGrayLight,
      },
      padding: 6,
      borderRadius: 4,
      whiteSpace: 'nowrap',
      color: Colors.blueGrayDark,
    },
    buttonIcon: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      height: 14,
    },
    modalText: {
      marginBottom: 10,
    },
    modalButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingLeft: 80,
      paddingRight: 80,
    },
    modalYesButton: {
      backgroundColor: Colors.purple,
    },
    modalCancelButton: {
      backgroundColor: Colors.red,
    },
    modalButton: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
      width: 100,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: Colors.blueGrayLight,
      borderWidth: 1,
      color: Colors.white,
      fontWeight: 600,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: 14,
      paddingBottom: 40,
      paddingTop: 40,
    },
    navLink: {
      textDecoration: 'none',
    },
  };
});
