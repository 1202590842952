import React from 'react';
import {NavLink} from 'react-router-dom';
import Avatar from 'assets/Avatar.png';
import userIcon from 'assets/user_icon.svg';
import moreIcon from 'assets/more_icon.svg';
import KlaimDropdownContainer from 'modules/Common/components/KlaimDropdownContainer';
import {useStylesFromThemeFunction} from 'modules/Settings/features/UserManagement/Users/UserTableRow/UserTableRow';
import {addUser, UserData} from 'modules/Settings/features/UserManagement/userManagementSlice';
import EditIcon from 'assets/component/EditIcon';
import DeleteIcon from 'assets/component/DeleteIcon';
import DeleteUserModal from 'modules/Settings/features/UserManagement/DeleteUserModal';
import KlaimTooltip from 'modules/Common/components/KlaimTooltip';
import {useAppDispatch} from 'modules/App/store';

interface ComponentProps {
  user: UserData;
}

const UserTableRow: React.FC<ComponentProps> = ({user}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);

  const onResendInvite = () => {
    dispatch(
      addUser({
        email: user.email,
        role: user.role.id,
        branches:
          user.branches.length > 0
            ? user.branches.map((branch) => {
                return {id: branch.id};
              })
            : [],
        isInvite: true,
      }),
    );
  };

  const tooltipShows = () => {
    if (user.branches.length > 1) {
      return (
        <KlaimTooltip
          tooltipClass={classes.tooltip}
          tooltipArrowClass={'arrow'}
          contentClass={'tooltipContent'}
          title="Branches"
          requirements={user.branches.map((branches) => {
            return {message: branches.identifier};
          })}
          value={`, +${user.branches.length - 1} more`}
        />
      );
    }
    return '';
  };

  return (
    <div className={classes.staffManagementTableRow}>
      <DeleteUserModal
        userId={user.id}
        isDeleteUserOpen={deleteOpen}
        setDeleteUserOpen={setDeleteOpen}
        email={user.email}
      />
      <div className={classes.hospitalInfo}>
        <img className={classes.avatar} src={userIcon} alt="user-icon" />
        {user.is_invitation_accepted ? user.name || '----' : 'Pending Invitation'}
      </div>
      <div className={classes.labelContainer}>
        <p>{user.email && user.email}</p>
      </div>
      <div className={classes.labelContainer}>
        <p>{user.role && user.role.name}</p>
      </div>
      <div className={classes.labelContainer}>
        {user.branches.length > 0 && (
          <p className={classes.branchesLabelContainer}>
            {user.branches[0]?.name}
            {tooltipShows()}
          </p>
        )}
      </div>
      <div className={classes.labelContainer}>{user.is_active ? 'Active' : 'Inactive'}</div>
      <KlaimDropdownContainer customClass={classes.labelContainer} isIconButton icon={moreIcon}>
        {user.is_invitation_accepted === false && (
          <button className={classes.button} onClick={() => onResendInvite()}>
            <div className={classes.buttonIcon} />
            Resend Invite
          </button>
        )}
        <NavLink to={`/settings/user-management/edit/${user.id}`} className={`${classes.button} ${classes.navLink}`}>
          <div className={classes.buttonIcon}>
            <EditIcon />
          </div>
          Edit User
        </NavLink>
        <button className={classes.button} onClick={() => setDeleteOpen(true)}>
          <div className={classes.buttonIcon}>
            <DeleteIcon />
          </div>
          Delete User
        </button>
      </KlaimDropdownContainer>
    </div>
  );
};

export default UserTableRow;
