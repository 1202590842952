import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
    },
    eligibilityBody: {
      width: '100%',
      borderRadius: 10,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  };
});
