import React, {FC, useEffect, useState} from 'react';
import Input from 'modules/Common/components/Input';
import searchIcon from 'assets/search_icon.svg';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {DrugPackage} from 'modules/Pharmacy/features/PriorRequest/types';
import {useStylesFromThemeFunction} from './ErxDrug';
import {ErxDrugsRefId, ErxGenericDrug} from '../../features/ErxReferencePrescription/types';
import {getErxDrugBySearch, erxDrugSearchSelect} from './erxDrugSlice';

interface SelectedDrug {
  code: string;
  name: string;
  strength?: string;
  dosage?: string;
  manufacturer?: string;
}

interface Props {
  drugs: ErxGenericDrug[];
  genericName: string;
  setSearchFilter: any;
  activityCode: ErxDrugsRefId;
  onSelectDrug: (drugCode: string, isDefault: boolean, selected?: SelectedDrug) => void;
  getNewDrugValue: (
    drugCode: string,
    drugName: string,
    drugStrength: string,
    drugDosageFormPackage: string,
    drugManufacturer: string,
  ) => void;
  show: boolean;
  showDrugSearch: () => void;
  selDrug: any;
  currentDrug: any;
}

const ErxStatusField: FC<Props> = ({
  setSearchFilter,
  genericName,
  activityCode,
  getNewDrugValue,
  drugs,
  onSelectDrug,
  show,
  showDrugSearch,
  selDrug,
  currentDrug,
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {data: searchDrug} = useAppSelector(erxDrugSearchSelect);

  useEffect(() => {
    if (genericName) {
      dispatch(getErxDrugBySearch({genericName}));
    }
  }, [genericName]);

  // generic_drugs state
  // if generic - true

  const initialDrugValue = () => {
    if (selDrug !== undefined) {
      return `${selDrug.code} - ${selDrug.name}`;
    }
    return activityCode.generic === false ? `${activityCode.name}` : `${activityCode.generic_name}`;
  };
  // if(activityCode.generic === false) {
  //   const initialDrugValue = activityCode.generic_drugs.map((drug, i) => {
  //     return `${drug.code} - ${drug.name} - ${drug.strength}, ${drug.dosage_form_package}, ${drug.manufacturer}`;
  //   },
  // }

  //   const mappedDrugs = `${drug.code} - ${drug.name} - ${drug.strength}, ${drug.dosage_form_package}, ${drug.manufacturer}`;
  // const truncatedValue = mappedDrugs.replace(/(.{62})..+/, '$1...');
  //   return truncatedValue;
  // });

  // const oldValue = `${activityCode.code} - ${activityCode.name} - ${activityCode.strength}, ${activityCode.dosage_form_package}, ${activityCode.manufacturer}`;
  // const truncatedValue = oldValue.replace(/(.{62})..+/, '$1...');

  return (
    <>
      <div className={classes.dropdownContainer}>
        <button onClick={() => showDrugSearch()} className={classes.selectBranches}>
          {initialDrugValue()}
        </button>
        {show && (
          <div className={classes.searchBox}>
            <div className={classes.inputWrapper}>
              <Input
                inputContainerClassName={classes.inputContainer}
                placeholder="Search drug code..."
                iconLeft={searchIcon}
                value={genericName}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </div>
            <div>
              {drugs.map((drug, index) => (
                <div key={index}>
                  <div
                    role="button"
                    className={classes.searchListSpacing}
                    onClick={() => onSelectDrug(drug.code, true)}
                  >
                    <img className={classes.imageIcon} src={searchIcon} alt="search" />
                    {drug.code} - {drug.name} - {drug.strength}
                  </div>
                  <hr className={classes.hrCustom} />
                </div>
              ))}
            </div>
            {genericName === '' ? null : (
              <>
                {searchDrug &&
                  searchDrug.map((drug, index) => {
                    return (
                      <div key={index}>
                        <div
                          className={classes.searchListSpacing}
                          role="button"
                          onClick={() => onSelectDrug(drug.drugCode, false, {code: drug.drugCode, name: drug.name})}
                        >
                          <img className={classes.imageIcon} src={searchIcon} alt="search" />
                          {drug.drugCode} - {drug.name} - {drug.strength}, {drug.dosageFormPackage}, {drug.manufacturer}
                        </div>
                        <hr className={classes.hrCustom} />
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default ErxStatusField;
