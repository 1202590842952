import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles({
  expiredLink: {
    background: Colors.white,
    width: 471,
    height: 547,
    borderRadius: 10,
    padding: 20,
  },
  subExpiredLink: {
    width: 375,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    display: 'flex',
    '& h1': {
      fontsize: '24px',
    },
    '& p': {
      fontsize: '12px',
    },
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  headerContainer: {
    '& h1': {
      fontSize: 24,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    width: 300,
    color: Colors.grayLighter,
    fontWeight: 600,
    fontSize: 12,
  },
  footerContainer: {
    '& form': {
      display: 'inline-block',
      fontSize: 14,
      width: '100%',
      marginLeft: '-10px',
      '& .resendButton': {
        marginLeft: '10px',
      },
    },
  },
  emailInput: {},
});
