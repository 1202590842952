import React from 'react';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import {useAppSelector} from 'modules/App/store';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import UAEClaimDetails from 'modules/Claims/features/uae/ClaimDetails';
import KSAClaimDetails from 'modules/Claims/features/v3/ClaimDetailsV3';

interface RouteParams {
  id: string;
}

const ClaimDetailsContainer: React.FC<RouteComponentProps<RouteParams>> = (props) => {
  const {
    user: {organization},
  } = useAppSelector(authSelect);

  if (organization.country === 'KSA') {
    return <KSAClaimDetails {...props} />;
  }
  return <UAEClaimDetails {...props} />;
};

export default ClaimDetailsContainer;
