import {createUseStyles} from 'react-jss';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    statusColumn: {
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    statusDot: {
      marginRight: '5px',
      width: 12,
      height: 12,
      borderRadius: '100%',
    },
    '@keyframes spin': {
      '0%': {transform: 'rotate(0deg)'},
      '100%': {transform: 'rotate(360deg)'},
    },
    loader: {
      animation: '$spin 2s linear infinite',
      border: '3px solid #ffffff',
      borderRadius: '50%',
      borderTop: '3px solid #6135FB',
      height: 12,
      width: 12,
      marginRight: '5px',
    },
  };
});
