import {FC, useEffect, useRef, useState} from 'react';
import Table from 'modules/Common/components/Table';
import GenderIcon from 'modules/Common/components/GenderIcon';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {NoData} from 'modules/Common/components/NoData';
import ActionButton from 'modules/Claims/components/ActionButton';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {headerSelect} from 'modules/Header/headerSlice';
import {getPreAuthList, preAuthListSelect} from './preAuthHistorySlice';
import {PreAuthHistoryInterface} from './types';
import {useStylesFromThemeFunction} from './PreAuthHistory';

const PreAuthHistory: FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {
    data: {data: dataList, count: dataCount},
    isLoading,
    error,
  } = useAppSelector(preAuthListSelect);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const {searchErx: search} = useAppSelector(headerSelect);

  useEffect(() => {
    dispatch(
      getPreAuthList({
        limit,
        offset: (offset - 1) * limit,
        search,
      }),
    );
  }, [limit, offset, search]);

  return (
    <div className={classes.container}>
      <div className={classes.patientListContainer}>
        <div className={classes.paginationContainer}>
          {/* {providerId ? ( */}
          <KlaimPaginationContainer
            pageSize={1}
            totalCount={20}
            currentPage={1}
            onPageChange={() => null}
            label="Pre-Auth History"
          >
            <Table
              loading={false}
              tableHeadings={[
                <p className={classes.tableHeading}>Patient info</p>,
                <p className={classes.tableHeading}>Claim ID</p>,
                <p className={classes.tableHeading}>Payer ID</p>,
                <p className={classes.tableHeading}>Amount</p>,
                // <p className={classes.tableHeading}>Status</p>,
                <p className={classes.tableHeading}>Submission Date</p>,
                <p className={classes.tableHeading}>Action</p>,
              ]}
              renderBody={() => (
                <>
                  {/* {dataList &&  */}
                  {/*  dataList.map((data: PreAuthHistoryInterface, i: number) => { */}
                  {/* return (  */}
                  <tr>
                    <td className={classes.genderIconTd}>
                      <GenderIcon gender="male" className={classes.genderIcon} />
                      1785487 - Maha Asghar
                    </td>
                    <td>I012-000-112411962-01</td>
                    <td>1785487</td>
                    <td>120.00</td>
                    {/* <td>  */}
                    {/*   <EligibilityStatus customStatus="Denied" /> */}
                    {/* </td> */}
                    <td>25 Oct 2020 20:58</td>
                    <td>
                      <ActionButton status="denied" />
                    </td>
                  </tr>
                  {/* );  */}
                  {/* })}  */}
                </>
              )}
            />
          </KlaimPaginationContainer>
          {/* ) : (
            <div className={classes.paginationContainer2}>
              <NoData />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default PreAuthHistory;
