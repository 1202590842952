import React, {FC} from 'react';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {createUseStyles} from 'react-jss';

const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    headerContainer: {
      alignItems: 'center',
      background: 'white',
      borderRadius: 10,
      boxShadow: '0px 16px 22px 3px rgb(96 97 112 / 16%)',
      display: 'flex',
      flexDirection: 'row',
      height: 83,
      marginBottom: 30,
      MozBoxShadow: '0px 16px 22px 3px rgba(96, 97, 112, 0.16)',
      paddingLeft: 30,
      paddingRight: 30,
      WebkitBoxShadow: '0px 16px 22px 3px rgb(96 97 112 / 16%)',
      zIndex: 3,
    },
  };
});

interface ComponentProps {
  children: any;
}

const HeaderContainer: FC<ComponentProps> = ({children}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div className={classes.headerContainer} id="header-container">
      {children}
    </div>
  );
};

export default HeaderContainer;
