import React from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import TopMenu from 'modules/Settings/components/TopMenu';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {patientSelect, errorCleanup, getPatients} from 'modules/Patients/patientsSlice';
import CollapsibleRow from 'modules/Patients/PatientsList/CollapsibleRow/index';
import LoadingSpinner from 'modules/Common/components/LoadingSpinner';
import KlaimPaginationContainer from 'modules/Common/components/KlaimPaginationContainer';
import {permissions as permissionsConstant} from 'modules/Common/constants/Permissions';
import {IPatientResponse} from 'interfaces/patient.interface';
import {useStylesFromThemeFunction} from './PatientsList';
import {branchManagementSelect, getBranches} from '../../Settings/features/BranchManagement/branchManagementSlice';
import BulkUploadModal from './BulkUploadModal';
import Button from '../../Common/components/Button';

const Patients: React.FC<RouteComponentProps> = ({history}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {
    patients: {count, data},
    isLoading,
  } = useAppSelector(patientSelect);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [offset, setOffset] = React.useState<number>(1);
  const [retries, setRetries] = React.useState(5);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const query = useQuery();
  const searchParam = query.get('search');

  const {
    branches: {
      data: {branches},
    },
    isLoading: isBranchLoading,
  } = useAppSelector(branchManagementSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier);
  };

  const limit = 10;
  React.useEffect(() => {
    if (branches.length > 0)
      dispatch(
        getPatients({
          providerId: getProviderIds().join(','),
          limit,
          offset: (offset - 1) * limit,
          search: searchParam,
        }),
      );
  }, [limit, offset, branches, searchParam]);

  React.useEffect(() => {
    if (!isBranchLoading && branches.length === 0 && retries > 0) {
      dispatch(getBranches({}));
      setRetries(retries - 1);
    }
  }, [branches, isBranchLoading]);

  React.useEffect(() => {
    return () => {
      dispatch(errorCleanup());
    };
  }, []);

  return (
    <div className={classes.sectionWrapper}>
      <TopMenu
        title="Patients Management"
        description={<p>Look up, add and modify your branches and who has access to each branch.</p>}
        buttonTitle="Add Patient"
        inputPlaceholder="Search branches"
        onButtonClick={() => history.push('/patients/add')}
        // requiredPermission={permissionsConstant.add_branches.identifier}
      />
      <KlaimPaginationContainer
        pageSize={limit}
        totalCount={count}
        currentPage={offset}
        onPageChange={setOffset}
        label="patients"
      >
        <div className={classes.bulkHeader}>
          <p className={classes.tableName}>Patients</p>
          <Button variant={'outlined'} onClick={() => setIsOpen(true)} title="Bulk Upload" />
        </div>
        <div className={classes.section}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <ul className={classes.table}>
              {data.length > 0 ? (
                data.map((patient: IPatientResponse, index: number) => (
                  <CollapsibleRow
                    key={`_${patient.id}_`}
                    patient={patient}
                    index={index}
                    offset={offset}
                    limit={limit}
                  />
                ))
              ) : (
                <div style={{paddingLeft: 30}}>No patient found Add by clicking "Add Patient"</div>
              )}
            </ul>
          )}
        </div>
      </KlaimPaginationContainer>
      <BulkUploadModal
        open={isOpen}
        setOpen={(v) => setIsOpen(v)}
        refreshList={() => {
          setTimeout(() => {
            dispatch(
              getPatients({
                providerId: getProviderIds().join(','),
                limit,
                offset: (offset - 1) * limit,
                search: searchParam,
              }),
            );
          }, 1500);
        }}
      />
    </div>
  );
};

export default Patients;
