import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {headerSelect, setRequiredFilters} from 'modules/Header/headerSlice';
import CollapsibleSection from 'modules/Common/components/CollapsibleSection';
import {
  KlaimStepper,
  KlaimStepperSteps,
  KlaimStepperStep,
  KlaimStepperHeaderButton,
} from 'modules/Common/components-v2/KlaimStepper';
import {
  preAuthV2Select,
  createPreAuthCleanup,
  getHistory,
  errorPreAuthCleanup,
  getPreAuthCleanup,
} from 'modules/PreAuthV2/PreAuthV2Slice';
import {Payer} from 'interfaces/payers.interfaces';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import PatientSelect from 'modules/Patient/features/PatientSelect';
import {getInsurances, insuranceSelect} from 'modules/Insurance/insuranceSlice';
import {
  KlaimDatePicker,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimInput,
  KlaimToggle,
} from 'modules/Common/components-v2/KlaimForm';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import {resubmissionTypes} from 'modules/Common/constants/DropdownOptions';
import PatientDetail from 'modules/Patient/components/PatientDetails';
import KlaimValue from 'modules/Common/components-v2/KlaimValue';
import dayjs from 'dayjs';
import {get} from 'lodash';
import {IOptions} from 'interfaces/general.interfaces';
import Coverage from './Coverage';
import Claim from './Claim';
import EligibilityDropdown from '../../../Eligibility/features/EligibilityDropdown';
import ErrorModal from './modals/ErrorModal';
import SubmittingModal from './modals/SubmittingModal';
import {usePreAuthFormContext} from './PreAuthFormContext';
import {eligibilityCleanup, eligibilitySelect, getEligibilityForm} from '../../../Eligibility/eligibilitySlice';
import {
  branchManagementSelect,
  getBranches,
  getUserCurrentBranches,
} from '../../../Settings/features/BranchManagement/branchManagementSlice';
import {Desc, OfflineContainer, Title} from './styles';
import {
  insurancesManagementSelect,
  getInsurances as getInsurancesM,
} from '../../../Settings/features/Insurance/InsuranceSlice';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    title: {
      fontSize: 22,
      color: '#2C273C',
      fontWeight: 700,
      marginBottom: 20,
    },
    detail: {
      color: '#302E38',
      fontSize: 16,
      fontWeight: 400,
      marginTop: 10,
      marginBottom: 10,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      '& h1': {
        fontSize: 15,
      },
      '& button': {
        fontWeight: 500,
        letterSpacing: 1.2,
      },
    },
    bodyContainer: {
      [`@media (min-width: ${theme.screen.desktop}px)`]: {
        width: '80%',
      },
    },
  };
});

export interface Values {
  [field: string]: any;
}

interface RouteParams {
  id: string;
}

const useQuery = () => {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const PreAuthFormV2: React.FC<RouteComponentProps<RouteParams>> = ({
  history,
  match: {
    params: {id},
  },
}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const {initialValues, setter, constants, methods} = usePreAuthFormContext();
  const {userBranches} = useAppSelector(branchManagementSelect);
  const {payers} = useAppSelector(insuranceSelect);
  const {preAuth, isCreatePreAuthSuccess, error} = useAppSelector(preAuthV2Select);
  const {editEligibility, error: eligibilityError} = useAppSelector(eligibilitySelect);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);
  const query = useQuery();
  const {history: preAuthDetail} = useAppSelector(preAuthV2Select);
  const eligibilityId = query.get('eligibilityId');
  const providerId = query.get('providerId');
  const TPA = ['7001463160', '7001580856', '7001604342', '7001599658'];
  const [selectedTPA, setSelectedTPA] = React.useState(null);
  const [isOffline, setIsOffline] = React.useState(false);

  React.useEffect(() => {
    if (id) dispatch(getHistory({id, providerId: query.get('providerId') || ''}));
  }, [id]);

  React.useEffect(() => {
    const insurance = insurances.find(
      (el: any) => el.hcpCode === (constants.selectedTpa || selectedTPA || constants?.selectedInsurance?.payerCode),
    ) as any;
    if (insurance?.hasTpa) {
      setter.setPayerId(insurance?.hcpCode);
      setter.setReceiverId(insurance?.tpas[0].hcpCode);
    } else {
      setter.setPayerId(insurance?.hcpCode);
      setter.setReceiverId(insurance?.hcpCode);
    }
  }, [selectedTPA, constants.selectedTpa, constants?.selectedInsurance?.payerCode]);

  React.useEffect(() => {
    if (userBranches.length === 1) {
      setter.setSelectedBranch(userBranches[0]);
    }
  }, [userBranches]);

  React.useEffect(() => {
    if (providerId && eligibilityId) {
      dispatch(getEligibilityForm({id: eligibilityId, providerId}));
    }
  }, [providerId, eligibilityId]);

  React.useEffect(() => {
    if (editEligibility) {
      const selectedBranch = userBranches.find((branch) => branch.identifier === editEligibility.tenantId);
      setter.setSelectedBranch(selectedBranch);
      setter.setSelectedBranch(selectedBranch);
      setter.setEligibility(editEligibility.id);
      setter.setSelectedPatient(editEligibility.patient);
      setter.setMember(editEligibility.member);
      const selectedInsurance = payers.data.find((payer) => payer.payerCode === editEligibility?.payerId);
      setter.setSelectedInsurance(selectedInsurance);
      setter.setSelectedTpa(selectedInsurance.payerCode);
      setSelectedTPA(selectedInsurance.payerCode);
      setter.setCoverage({...constants.coverage, subscriberId: editEligibility.subscriberId});
    }
  }, [editEligibility, userBranches]);

  React.useEffect(() => {
    dispatch(getUserCurrentBranches({}));
    dispatch(getInsurances({authorityCode: 'NPHIES', limit: 300}));
    dispatch(
      setRequiredFilters({
        dateTypeFilter: false,
        dateRangeFilterMonth: false,
        branchesFilter: false,
      }),
    );
  }, []);

  React.useEffect(() => {
    if (error) {
      setter.setErrorOpen(true);
    } else {
      // dispatch(errorPreAuthCleanup());
      setter.setErrorOpen(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (constants.member) {
      setter.setCoverage({...constants.coverage});
    }
  }, [constants.member]);

  React.useEffect(() => {
    if (preAuthDetail && preAuthDetail.submissions.length > 0 && id) {
      const priorAuthSubmissions = preAuthDetail.submissions.filter(
        (submission) => submission.type === 'priorauth-request',
      );

      const latestSubmission = priorAuthSubmissions[priorAuthSubmissions.length - 1];

      if (latestSubmission.coverage) {
        setter.setCoverage(latestSubmission.coverage);
      }
      if (latestSubmission.receiverId) {
        setter.setSelectedInsurance(payers.data.find((payer) => payer.payerCode === latestSubmission.receiverId));
      }
      if (latestSubmission.senderId) {
        setter.setSelectedBranch(userBranches.find((branch) => branch.identifier === latestSubmission.senderId));
      }
      if (latestSubmission.claim) {
        setter.setClaim(latestSubmission.claim);
      }

      if (latestSubmission.patient) {
        setter.setSelectedPatient(latestSubmission.patient);
      }
    }
  }, [preAuthDetail, userBranches, payers]);

  React.useEffect(() => {
    if (
      constants.claim.careTeam &&
      constants.claim.diagnosis &&
      constants.claim.item &&
      constants.claim.careTeam?.length > 0 &&
      constants.claim.diagnosis?.length > 0 &&
      constants.claim.item?.length > 0
    ) {
      setter.setIsSubmitDisabled(false);
    } else {
      setter.setIsSubmitDisabled(true);
    }
  }, [constants.claim]);

  React.useEffect(() => {
    if (
      constants.selectedBranch &&
      constants.selectedPatient &&
      constants.isCoverageFulfilled &&
      constants.selectedInsurance &&
      constants.member.id &&
      constants.member.system
    ) {
      setter.setIsNextDisabled(false);
    } else {
      setter.setIsNextDisabled(true);
    }
  }, [
    constants.selectedBranch,
    constants.selectedPatient,
    constants.isCoverageFulfilled,
    constants.selectedInsurance,
    constants.member,
  ]);

  const {
    insurances: {data: insurances},
  } = useAppSelector(insurancesManagementSelect);

  React.useEffect(() => {
    if (insurances && insurances.length > 0) {
      setInsuranceOptions(
        insurances.map((payer: any) => {
          let value = payer.hcpCode;
          if (payer.hasTpa) {
            value = `${value}-${payer.tpas[0].hcpCode}`;
          }
          return {
            label: `${payer.longName} ${payer.hasTpa ? `(${payer.tpas[0].name})` : ''}`,
            value,
          };
        }),
      );
    }
  }, [insurances]);

  const {
    branches: {
      data: {branches},
    },
  } = useAppSelector(branchManagementSelect);
  const {startDate, endDate} = useAppSelector(headerSelect);

  const getProviderIds = () => {
    return branches.map((b: any) => b.identifier).join(',');
  };

  useEffect(() => {
    dispatch(getBranches({}));
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getInsurancesM({
  //       limit: 100,
  //       offset: 0,
  //       startDate,
  //       endDate,
  //       providerId: getProviderIds(),
  //     }),
  //   );
  // }, [branches]);

  React.useEffect(() => {
    if (insurances && insurances.length > 0) {
      setter.setInsuranceOptions(
        insurances.map((payer: any) => {
          return {
            label: `${payer.longName} ${payer.hasTpa ? `(${payer.tpas[0].name})` : ''}`,
            value: payer.hcpCode,
          };
        }),
      );
    }
  }, [insurances]);

  const handleModalClose = () => {
    dispatch(errorPreAuthCleanup());
  };

  const cleanUpForm = () => {
    setter.setCoverage(initialValues.coverage);
    setter.setPatientRel(undefined);
    setter.setClaim(initialValues.claim);
    setter.setSelectedBranch(undefined);
    setter.setSelectedPatient(undefined);
    setter.setMember({...initialValues.member, id: '', system: ''});
    setter.setSelectedInsurance(undefined);
    setter.setSubmitting(false);
    createPreAuthCleanup();
  };

  React.useEffect(() => {
    if (
      constants.selectedInsurance &&
      constants.selectedInsurance.tpas &&
      constants.selectedInsurance.tpas.length > 0
    ) {
      setter.setSelectedTpa(constants.selectedInsurance.tpas[0]);
    }
  }, [constants.selectedInsurance]);

  React.useEffect(() => {
    if (isCreatePreAuthSuccess && preAuth) {
      setter.setSubmitting(true);
      setTimeout(() => {
        setter.setSubmitting(false);
        history.push(`/pre-auth/details/${preAuth.id}?providerId=${preAuth.submissions[0].senderId}`);
      }, 3000);
    }
  }, [preAuth, isCreatePreAuthSuccess]);

  React.useEffect(() => {
    return () => {
      cleanUpForm();
      dispatch(eligibilityCleanup());
      dispatch(getPreAuthCleanup());
    };
  }, []);

  const onNext = () => {
    setter.setPreAuthStep(constants.preAuthStep + 1);
  };

  const onBack = () => {
    setter.setPreAuthStep(constants.preAuthStep - 1);
  };

  const handleCoverageChange = (value: any) => {
    setter.setCoverage(value);
  };

  const handleInsuranceChange = (value: any) => {
    setter.setSelectedInsurance(payers.data.find((payer) => payer.payerCode === value));
  };

  const handleEligibilitySelect = (value: any) => {
    if (value) {
      setter.setMember({
        id: value.member.id,
        system: value.member.system,
        // subscriberId: value.subscriberId,
      });
      setter.setCoverage({...constants.coverage, subscriberId: value.subscriberId});
      const setInsurance = payers.data.find((payer) => payer.payerCode === value?.payerId);
      setter.setSelectedInsurance(setInsurance);
      setter.setEligibility(value);
      setter.setSelectedTpa(value.receiverId);
      setSelectedTPA(value.receiverId);
      setter.setSelectedInsurance(value.payerId);
    } else {
      setter.setEligibility(undefined);
      setter.setMember({
        id: '',
        system: '',
      });
      setter.setCoverage({...constants.coverage, subscriberId: ''});
    }
  };
  useEffect(() => {
    if (constants.member?.id && constants.member.system && constants.selectedTpa) {
      setter.setIsNextDisabled(false);
    } else {
      setter.setIsNextDisabled(true);
    }
  }, [constants]);

  useEffect(() => {
    if (constants?.selectedBranch) {
      dispatch(
        getInsurancesM({
          limit: 100,
          offset: 0,
          startDate,
          endDate,
          providerId: constants.selectedBranch.identifier,
        }),
      );
    }
  }, [constants.selectedBranch]);

  return (
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}} id="pre-auth-form">
      <>
        {constants.selectedPatient && (
          <PatientDetail patient={constants.selectedPatient}>
            <KlaimValue direction="column" label={`Insurance`} value={constants?.selectedInsurance?.name || '-'} />
            <KlaimValue direction="column" label={`Subscriber ID`} value={constants?.coverage?.subscriberId || '-'} />
            <KlaimValue direction="column" label={`ID Number`} value={constants?.selectedPatient?.documentId || '-'} />
            <KlaimValue direction="column" label={`Expiry Date`} value={'-'} />
          </PatientDetail>
        )}
        <KlaimStepper initialStep={constants.preAuthStep} setStep={setter.setPreAuthStep}>
          <CollapsibleSection
            renderHeader={() => {
              return (
                <div style={{zIndex: 100, paddingTop: 20, paddingBottom: 20, paddingRight: 20}}>
                  <KlaimStepperHeaderButton
                    onSubmit={() => methods.onPreAuthSubmit(id)}
                    isSubmitDisabled={constants.isSubmitDisabled}
                    isNextDisabled={constants.isNextDisabled}
                    title={'Pre-Auth Request'}
                    onClick={onNext}
                    onBack={onBack}
                    submitText={id ? 'Re-submit' : undefined}
                  />
                </div>
              );
            }}
            renderContent={() => {
              return (
                <div
                  id="renderContent"
                  className={classes.bodyContainer}
                  style={{
                    padding: 20,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 20,
                    minHeight: 500,
                  }}
                >
                  <KlaimStepperSteps>
                    <KlaimStepperStep>
                      <p className={classes.detail}>
                        To create a Pre-auth request, please choose a branch and a patient to start.
                      </p>
                      {id && (
                        <KlaimDropdownSearch
                          variant="secondary"
                          value={constants.resubmissionType}
                          onChange={(value) => setter.setResubmissionType(value)}
                          style={{marginTop: 10, marginBottom: 10}}
                          name="relatedToPriorClaimRel"
                          label="Type of Resubmission"
                          options={resubmissionTypes}
                        />
                      )}
                      <div>
                        <h2 className={classes.title}>Patient</h2>
                        <div
                          style={{
                            display: 'grid',
                            rowGap: '1rem',
                            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                          }}
                        >
                          {userBranches.length > 1 && (
                            <KlaimDropdownSearch
                              value={constants.selectedBranch?.identifier}
                              options={
                                userBranches
                                  ? userBranches.map((branch) => {
                                      return {
                                        label: branch.name,
                                        value: branch.identifier,
                                      };
                                    })
                                  : []
                              }
                              name="branch"
                              label="Choose Branch"
                              variant={'secondary'}
                              onChange={(value) =>
                                setter.setSelectedBranch(userBranches.find((branch) => value === branch.identifier))
                              }
                            />
                          )}

                          {constants.selectedBranch && (
                            <>
                              <PatientSelect
                                patientId={
                                  constants?.selectedPatient?.id || preAuthDetail
                                    ? get(preAuthDetail, 'submissions[0].patient.documentId')
                                    : ''
                                }
                                initialValue={{
                                  id: constants?.selectedPatient?.id || (preAuthDetail ? preAuthDetail.patientId : ''),
                                  ...constants?.selectedPatient,
                                }}
                                label="Select Patient"
                                onChange={(value) => {
                                  if (value) {
                                    if (typeof value.fullName !== 'object') {
                                      delete value.fullName;
                                    }
                                    setter.setSelectedPatient(value);
                                  }
                                }}
                                branch={constants.selectedBranch.identifier}
                              />
                            </>
                          )}

                          {constants.selectedPatient && constants.selectedBranch && (
                            <>
                              {!editEligibility && (
                                <EligibilityDropdown
                                  label="Select Eligibility"
                                  onChange={handleEligibilitySelect}
                                  documentId={constants.selectedPatient.documentId}
                                  branch={constants.selectedBranch.identifier}
                                  initialValue={preAuthDetail ? preAuthDetail.eligibilityId : null}
                                  optional
                                />
                              )}

                              <div
                                style={{
                                  display: 'grid',
                                  gap: '1rem',
                                  gridTemplateColumns: 'repeat(2, minmax(0, 2fr))',
                                }}
                              >
                                <KlaimInput
                                  variant={'secondary'}
                                  label="Member ID"
                                  isReadOnly={constants.eligibility}
                                  value={constants.member.id}
                                  placeholder="ex. 3947519"
                                  onChange={(e) => setter.setMember({...constants.member, id: e})}
                                />
                                <KlaimInput
                                  variant={'secondary'}
                                  label="Member System"
                                  isReadOnly={constants.eligibility}
                                  value={constants.member.system}
                                  placeholder="ex. http://Medgulf.com.sa/memberidentifier/"
                                  onChange={(e) => setter.setMember({...constants.member, system: e})}
                                />
                                <KlaimDropdownSearch
                                  label="Insurance/TPA"
                                  variant={'secondary'}
                                  options={insuranceOptions}
                                  value={
                                    constants?.selectedTpa &&
                                    constants.selectedInsurance?.toString() !== constants.selectedTpa
                                      ? `${constants?.selectedInsurance}-${constants?.selectedTpa}`
                                      : constants?.selectedInsurance
                                  }
                                  initialValue={
                                    constants?.selectedTpa &&
                                    constants.selectedInsurance?.toString() !== constants.selectedTpa
                                      ? `${constants?.selectedInsurance}-${constants?.selectedTpa}`
                                      : constants?.selectedInsurance
                                  }
                                  name={'tpa'}
                                  onChange={(v) => {
                                    if (v.includes('-')) {
                                      const insurance = v.split('-')[0];
                                      const tpa = v.split('-')[1];
                                      setter.setSelectedTpa(insurance);
                                      setSelectedTPA(insurance);
                                    } else {
                                      setter.setSelectedTpa(v);
                                      setSelectedTPA(v);
                                    }
                                  }}
                                />
                                {/* <div>
                                  {selectedTPA && TPA.includes(`${selectedTPA}`) && (
                                    <KlaimDropdownSearch
                                      label="Insurance"
                                      variant={'secondary'}
                                      name={'insurance'}
                                      onChange={handleInsuranceChange}
                                      options={payers.data
                                        .filter((el) => !TPA.includes(el.payerCode))
                                        .map((tpa: any) => {
                                          return {
                                            label: tpa.name,
                                            value: tpa.payerCode,
                                          };
                                        })}
                                      value={constants.selectedInsurance.payerCode}
                                    />
                                  )}
                                </div> */}

                                <div style={{gridColumn: 'span 2 / span 2'}}>
                                  <Coverage onChange={handleCoverageChange} optional />
                                </div>
                              </div>

                              <OfflineContainer>
                                <div>
                                  <Title>Offline Eligibility</Title>
                                  <Desc>
                                    Add optional coverage items like Policy No, Class Name, Class, Network, Amount etc.
                                  </Desc>
                                </div>
                                <KlaimToggle
                                  width={56}
                                  title=""
                                  onChange={(v) => {
                                    setIsOffline(v);
                                    setter.setIsNextDisabled(!v);
                                  }}
                                />
                              </OfflineContainer>
                              {isOffline && (
                                <div
                                  style={{
                                    display: 'grid',
                                    gap: '1rem',
                                    gridTemplateColumns: 'repeat(2, minmax(0, 2fr))',
                                    marginTop: 8,
                                  }}
                                >
                                  <KlaimInput
                                    variant={'secondary'}
                                    label="Offline Eligibility Reference"
                                    placeholder="Offline Eligibility Reference"
                                    value={constants.claim?.eligibilityOffline}
                                    onChange={(e) => {
                                      setter.setClaim({...constants.claim, eligibilityOffline: e});
                                    }}
                                  />
                                  <KlaimDatePicker
                                    variant={'secondary'}
                                    label="Date"
                                    name="timingDate"
                                    value={dayjs(constants.claim?.eligibilityOfflineDate || new Date()).toDate()}
                                    onChange={(e) => {
                                      setter.setClaim({
                                        ...constants.claim,
                                        eligibilityOfflineDate: dayjs(e).format('YYYY-MM-DD').toString(),
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </KlaimStepperStep>
                    <KlaimStepperStep>
                      <Claim claim={constants.claim} setClaim={setter.setClaim} />
                    </KlaimStepperStep>
                  </KlaimStepperSteps>
                </div>
              );
            }}
            collapsed
          />
        </KlaimStepper>

        <SubmittingModal open={constants.isSubmitting} setOpen={setter.setSubmitting} />
        <ErrorModal
          onClose={handleModalClose}
          open={constants.isErrorOpen}
          setOpen={setter.setErrorOpen}
          error={error || []}
        />
      </>
    </div>
  );
};

export default PreAuthFormV2;
