import React from 'react';
import * as Yup from 'yup';
import {
  KlaimModal,
  KlaimModalBody,
  KlaimModalHeader,
  KlaimModalFooter,
} from 'modules/Common/components-v2/KlaimModalv2';
import {KlaimDropdownSearch, KlaimForm, KlaimFieldWrapper, KlaimToggle} from 'modules/Common/components-v2/KlaimForm';
import {get} from 'lodash';
import {careTeamRoleCodes} from 'modules/Common/constants/DropdownOptions';
import PractitionerDropdown from 'modules/Practitioner/feature/PractitionerDropdown';
import {ICareTeamCode} from 'interfaces/pre-auth.interface';
import {ComponentProps, useStylesFromThemeFunction} from './CareTeamModal';

interface ICareTeamValues {
  careTeamRoleCode: string;
  practitioner?: {
    license_number: string;
  };
  isOnsite: boolean;
}

const CareTeamModal: React.FC<ComponentProps> = ({isOpen, setIsOpen, onSubmit, selectedCareTeamIndex, careTeams}) => {
  const classes = useStylesFromThemeFunction();
  const initialValues: ICareTeamValues = {
    careTeamRoleCode: get(careTeams, `[${selectedCareTeamIndex}].careTeamRoleCode`, 'primary'),
    practitioner: get(careTeams, `[${selectedCareTeamIndex}].practitioner`, undefined),
    isOnsite: get(careTeams, `[${selectedCareTeamIndex}].isOnsite`, true),
  };

  const validationSchema = Yup.object({
    careTeamRoleCode: Yup.string().required('Practitioner role is required.'),
    practitioner: Yup.object().shape({
      license_number: Yup.string().required('Practitioner is required'),
    }),
    isOnsite: Yup.boolean().required(),
  });

  // TODO
  // Practitioner type research
  const handleSubmit = ({isOnsite, careTeamRoleCode, practitioner}: any) => {
    if (onSubmit)
      onSubmit({
        onSite: isOnsite,
        code: practitioner.roles[0].code,
        practitionerType: practitioner.roles[0].type,
        practitionerCode: practitioner.license_number,
        specialty: practitioner.speciality || practitioner.roles[0].specialty,
        careTeamRoleCode: careTeamRoleCode as ICareTeamCode,
        practitioner,
        name: practitioner?.name,
      });
  };

  return (
    <KlaimModal maxWidth={600} open={isOpen} setOpen={setIsOpen}>
      <KlaimForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <KlaimModalHeader title="Add Practitioner" />
        <KlaimModalBody>
          <div className={classes.container} style={{gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '1rem'}}>
            <p style={{gridColumn: 'span 2 / span 2'}}>Please fill in the fields below to add new practitioner.</p>
            <KlaimFieldWrapper name="practitioner" style={{gridColumn: 'span 2 / span 2'}}>
              <PractitionerDropdown />
            </KlaimFieldWrapper>
            <KlaimFieldWrapper name="careTeamRoleCode">
              <KlaimDropdownSearch
                label="Practitioner's role"
                placeholder="primary"
                variant="secondary"
                options={careTeamRoleCodes}
              />
            </KlaimFieldWrapper>
            <div style={{display: 'flex', alignItems: 'end'}}>
              <div style={{flex: '1 1 0%'}}>
                <KlaimFieldWrapper name="isOnsite">
                  <KlaimToggle width={56} title="On site?" />
                </KlaimFieldWrapper>
              </div>
            </div>
            {/* <CareTeamForm /> */}
          </div>
        </KlaimModalBody>
        <KlaimModalFooter buttonType={'submit'} />
      </KlaimForm>
    </KlaimModal>
  );
};

export default CareTeamModal;
