import React from 'react';
import ReactModal from 'react-modal';
import {
  ComponentProps,
  useStylesFromThemeFunction,
} from 'modules/Common/components-v2/KlaimModalv2/components/KlaimModal/KlaimModal';

interface IModalContext {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContext = React.createContext<IModalContext>({
  open: true,
  setOpen: () => {},
});

const KlaimModal: React.FC<ComponentProps> = ({children, maxWidth, width, open, setOpen, onClose}) => {
  ReactModal.setAppElement('#root');
  React.useEffect(() => {
    return () => {
      setOpen(false);
      if (onClose) onClose();
    };
  }, []);
  const classes = useStylesFromThemeFunction();
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <ReactModal
      onAfterClose={handleClose}
      isOpen={open}
      className={classes.modal}
      style={{
        overlay: {
          zIndex: 100,
        },
      }}
    >
      <ModalContext.Provider value={{open, setOpen}}>
        <div className={classes.container} style={{maxWidth: maxWidth || 500, width: width || '100%'}}>
          {children}
        </div>
      </ModalContext.Provider>
    </ReactModal>
  );
};

export default KlaimModal;
