import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    inputContainer: {
      maxWidth: 600,
    },
    paragraphContainer: {
      fontSize: 13,
      paddingLeft: 20,
      marginTop: 10,
    },
    errorContainer: {
      backgroundColor: Colors.lighterRed,
      marginLeft: '10px',
      marginRight: '10px',
      borderRadius: 10,
    },
    title: {
      '& img': {
        paddingRight: '10px',
      },
      display: 'flex',
      alignItems: 'center',
      color: 'red',
      padding: '20px 20px 0 20px',
      fontSize: '15px',
      fontWeight: 'bold',
    },
    subTitle: {
      padding: '0 20px 20px 50px',
      fontSize: '14px',
    },
  };
});
