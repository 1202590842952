import React from 'react';
import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {ToggleButtonGroupContext} from '../ToggleButtonGroup';

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    buttonStart: {
      borderTopLeftRadius: '.75rem',
      borderBottomLeftRadius: '.75rem',
    },
    button: {
      flex: '1 1 0%',
      borderColor: theme.formButton.border.secondary,
      color: theme.formButton.color.tertiary,
      background: theme.container.background.tertiary,
    },
    buttonEnd: {
      borderTopRightRadius: '.75rem',
      borderBottomRightRadius: '.75rem',
    },
    buttonActive: {
      borderStyle: 'solid',
      borderWidth: '.05rem',
      borderColor: theme.formButton.border.tertiary,
    },
  };
});

interface ComponentProps {
  value: string;
  position?: number;
}

const ToggleButton: React.FC<ComponentProps> = ({children, value, position = 0}) => {
  const classes = useStylesFromThemeFunction();
  const {selected, setSelected, childCount} = React.useContext(ToggleButtonGroupContext);
  return (
    <button
      className={`${classes.button} ${childCount === position && classes.buttonEnd} ${
        position === 1 && classes.buttonStart
      } ${selected === value && classes.buttonActive}`}
      onClick={() => setSelected(value)}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
