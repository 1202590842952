import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';
import {Country} from 'react-phone-number-input';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';

export interface ComponentProps {
  label?: string;
  name?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  type?: 'text' | 'email' | 'number' | 'password' | 'checkbox' | 'phone' | 'calendar';
  labelClassName?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  errorClassName?: string;
  containerClassName?: string;
  optional?: boolean;
  value?: string | number;
  showTooltip?: boolean;
  requirements?: string[];
  isError?: boolean;
  isTouched?: boolean;
  defaultCountry?: Country;
  variant?: 'primary' | 'secondary';
  onChange?: (value: any | undefined) => void;
  mask?: string;
  min?: string;
  max?: string;
  step?: string;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    contentContainer: {
      '& span': {
        paddingLeft: '10px',
      },
    },
    label: {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 600,
    },
    labelError: {
      color: theme.formElement.color.error,
    },
    labelValidated: {
      color: theme.formElement.color.validated,
    },
    labelDefault: {
      color: Colors.grayLabel,
    },
  };
});
