import React, {FC, useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import {IRoute} from 'interfaces/route.interface';
import Header from 'modules/Header';
import {setRequiredFilters, setNavOptions} from 'modules/Header/headerSlice';
import {useAppDispatch} from 'modules/App/store';
import {NavigationMenuItem} from 'interfaces/header.interfaces';
import {useStylesFromThemeFunction} from './MidTable';
import RouteWithSubRoutes from '../Route/RouteWithSubRoutes';

const Payments: FC<{routes: IRoute[] | undefined}> = ({routes}) => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const midTableOptions: NavigationMenuItem[] = [
    {title: 'MidTable', link: '/midtable'},
  ];
  const checkIfNewClaim = () => {
    return location.pathname.includes('form');
  };
  const checkIfClaimDetails = () => {
    return location.pathname.includes('details');
  };
  const setHeaderFilters = () => {
    return !checkIfNewClaim() && !checkIfClaimDetails();
  };
  useEffect(() => {
    if (location.pathname.endsWith('/midtable'))
      dispatch(
        setRequiredFilters({
          navigation: true,
          search: setHeaderFilters(),
          submitButton: true,
          submitFormButton: false,
          submitAllButton: true,
          // statusFilter: setHeaderFilters(),
          payersFilter: setHeaderFilters(),
          receiversFilter: setHeaderFilters(),
          typesFilter: setHeaderFilters(),
          visitFilter: setHeaderFilters() && !location.pathname.includes('errors'),
          dateRangeFilterDays: setHeaderFilters(),
          branchesFilter: setHeaderFilters(),
          syncedFilter: setHeaderFilters() && !location.pathname.includes('errors'),
          specialtiesFilter: setHeaderFilters(),
        }),
      );

    dispatch(
      setNavOptions({
        navOptions: midTableOptions,
      }),
    );
  }, [location]);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <Header />
        <Switch>
          {routes &&
            routes.map(({routeKey, ...route}, index) => {
              return <RouteWithSubRoutes routeKey={routeKey} {...route} key={index} />;
            })}
        </Switch>
      </div>
    </div>
  );
};

export default Payments;
