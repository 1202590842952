import {createUseStyles} from 'react-jss';
import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {Colors} from '../../constants/Colors';

export interface ComponentProps {
  placeholder?: string;
  setSearch?: (search: string) => void;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      height: 40,
      borderStyle: 'solid',
      borderRadius: 10,
      borderWidth: 1,
      borderColor: Colors.blueGrayLight,
      overflow: 'hidden',
      paddingLeft: 6,
      paddingRight: 6,
      marginLeft: 0,
      marginRight: 4,
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      paddingTop: 2,
      paddingBottom: 2,
      alignItems: 'center',
    },
    input: {
      paddingLeft: 6,
      paddingRight: 6,
      width: '100%',
      height: '100%',
      outline: 'none',
      border: 'none',
      fontSize: 14,
    },
    imageIcon: {
      width: 20,
      height: 20,
    },
  };
});
