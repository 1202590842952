import React from 'react';
import {Link, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TokenData} from 'services/jwt';
import userIcon from 'assets/user_icon.svg';
import accessManagementIcon from 'assets/access_management_icon.svg';
import accountSettingsIcon from 'assets/account_settings_icon.svg';
import branchManagementIcon from 'assets/branch_management_icon.svg';
import insuranceManagementIcon from 'assets/insurance_management_icon.svg';
import activeInsuranceManagementIcon from 'assets/active_insurance_management_icon.svg';
import practitionersManagementIcon from 'assets/practitioners_icon.svg';
import activePractitionersManagementIcon from 'assets/active_practitioners_icon.svg';
import userManagementIcon from 'assets/user_management_icon.svg';
import activeAccessManagementIcon from 'assets/active_access_management_icon.svg';
import activeAccountSettingsIcon from 'assets/active_account_management_icon.svg';
import activeBranchManagementIcon from 'assets/active_branch_management_icon.svg';
import activeUserManagementIcon from 'assets/active_user_management_icon.svg';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import {SettingsMenuNav, useStylesFromThemeFunction} from 'modules/Main/Sidebar/UserProfile/UserSettings/UserSettings';
import Logout from 'modules/Auth/v3/features/Logout';
import {useSelector} from 'react-redux';
import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';

// interface ComponentProps extends RouteComponentProps {
//   user: TokenData;
// }

interface ComponentProps extends RouteComponentProps {
  user: TokenData;
}

const UserSettings: React.FC<ComponentProps> = ({user, history, location}) => {
  const classes = useStylesFromThemeFunction();
  const {t} = useTranslation();
  const [activeMenu, setActiveMenu] = React.useState<SettingsMenuNav>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<string>('');
  const {user: userIdentifier} = useSelector(authSelect);

  React.useEffect(() => {
    const result = menu.find((item) => item.link.split('/')[2] === location.pathname.split('/')[2]);

    setActiveMenu(result);
    if (location.pathname === '/settings') history.push('/settings/account');
  }, [location.pathname]);

  const isActive = (title: string) => {
    return (activeMenu && activeMenu.title) === title || hover === title;
  };

  const handleMenu = () => {
    if (userIdentifier.organization.country === 'KSA') {
      return menu;
    }
    return menu2;
  };
  const menu: SettingsMenuNav[] = [
    {
      link: '/settings/account',
      title: 'Account Settings',
      baseLink: '/settings/account',
      icon: accountSettingsIcon,
      activeIcon: activeAccountSettingsIcon,
    },
    {
      link: '/settings/branch-management',
      title: 'Branch Management',
      baseLink: '/settings/branch-management',
      icon: branchManagementIcon,
      activeIcon: activeBranchManagementIcon,
    },
    // {
    //   link: '/settings/insurance-companies',
    //   title: 'Insurance Companies',
    //   baseLink: '/settings/insurance-companies',
    //   icon: insuranceManagementIcon,
    //   activeIcon: activeInsuranceManagementIcon,
    // },
    {
      link: '/settings/practitioners',
      title: 'Facility Practitioners Management',
      baseLink: '/settings/practitioners',
      icon: practitionersManagementIcon,
      activeIcon: activePractitionersManagementIcon,
    },
    {
      link: '/settings/contracts-management',
      title: 'Contract Management',
      baseLink: '/settings/contracts-management',
      icon: accessManagementIcon,
      activeIcon: activeAccessManagementIcon,
    },
    {
      link: '/settings/user-management',
      title: 'User Management',
      baseLink: '/settings/user-management',
      icon: userManagementIcon,
      activeIcon: activeUserManagementIcon,
    },
    {
      link: '/settings/access-management',
      title: 'Access Management',
      baseLink: '/settings/access-management',
      icon: accessManagementIcon,
      activeIcon: activeAccessManagementIcon,
    },
    {
      link: '/settings/insurances-management',
      title: 'Insurance Management',
      baseLink: '/settings/insurances-management',
      icon: accessManagementIcon,
      activeIcon: activeAccessManagementIcon,
    },
  ];

  const menu2: SettingsMenuNav[] = [
    {
      link: '/settings/account',
      title: 'Account Settings',
      baseLink: '/settings/account',
      icon: accountSettingsIcon,
      activeIcon: activeAccountSettingsIcon,
    },
    {
      link: '/settings/branch-management',
      title: 'Branch Management',
      baseLink: '/settings/branch-management',
      icon: branchManagementIcon,
      activeIcon: activeBranchManagementIcon,
    },
    {
      link: '/settings/user-management',
      title: 'User Management',
      baseLink: '/settings/user-management',
      icon: userManagementIcon,
      activeIcon: activeUserManagementIcon,
    },
    {
      link: '/settings/access-management',
      title: 'Access Management',
      baseLink: '/settings/access-management',
      icon: accessManagementIcon,
      activeIcon: activeAccessManagementIcon,
    },
  ];
  return (
    <div className={classes.userSettingsContainer}>
      <div className={classes.userDetailsContainer}>
        <div className={classes.imageContainer}>
          <img src={userIcon} alt="user-icon" />
        </div>
        <div className={classes.detailsContainer}>
          <h1>{user.name}</h1>
          <KlaimSpan>{user.organization.name}</KlaimSpan>
          <p>{user.role.name}</p>
        </div>
      </div>
      <div className={classes.settingsContainer}>
        <h3>SETTINGS</h3>
        <div>
          {handleMenu().map((item) => {
            return (
              <div className={classes.navLink} key={item.title}>
                <Link to={item.link}>
                  <div
                    className={classes.linkContainer}
                    onMouseLeave={() => {
                      setHover('false');
                    }}
                    onMouseEnter={() => setHover(item.title)}
                  >
                    {item.icon && <img src={isActive(item.title) ? item.activeIcon : item.icon} alt="icon" />}
                    <span className={`${isActive(item.title) && classes.activeLink}`}>{item.title}</span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Logout className={classes.button} />
      </div>
      <div className={classes.footerContainer}>
        <div className={classes.policyContainer}>
          <a
            className={classes.privacyTerms}
            target="_blank"
            rel="noreferrer"
            href="https://register.klaim.ai/privacy-policy/"
          >
            Privacy Policy
          </a>
          <a
            className={classes.privacyTerms}
            target="_blank"
            rel="noreferrer"
            href="https://register.klaim.ai/terms-of-service/"
          >
            Terms of Use
          </a>
        </div>
        <p>Copyright © 2022 Klaim All rights reserved</p>
      </div>
    </div>
  );
};

export default withRouter(UserSettings);
