import React from 'react';
import {useHistory} from 'react-router-dom';

const RootRedirect: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/eligibility');
  }, []);
  return null;
};

export default RootRedirect;
