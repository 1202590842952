import React from 'react';
import {useAppSelector} from 'modules/App/store';

import {authSelect} from 'modules/Auth/v3/features/Auth/authSlice';
import KSAForm from 'modules/Settings/features/BranchManagement/BranchVerify/KSAForm';
import UAEForm from 'modules/Settings/features/BranchManagement/BranchVerify/UAEForm';

export interface IValues {
  [field: string]: any;
}

const Verify: React.FC = () => {
  const {
    user: {organization},
  } = useAppSelector(authSelect);

  return organization.country === 'UAE' ? <UAEForm /> : <KSAForm />;
};

export default Verify;
