import React from 'react';
import {EL} from '@klaim-ai/klaim-interfaces';
import {Colors} from 'modules/Common/constants/Colors';
import CollapsibleContainer from './CollapsableContainer';
import CollapsibleTable from './CollapsibleTable';
import KlaimValue from '../../../../../../Common/components-v2/KlaimValue';

interface ComponentProps {
  coverage: any;
}

const CoveragePlan: React.FC<ComponentProps> = ({coverage}) => {
  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
          marginBottom: 20,
          padding: 10,
        }}
      >
        <KlaimValue label="In Force" value={coverage.inForce ? 'Yes' : 'No'} direction="column" />
        <KlaimValue label="Member ID" value={coverage.member.id} direction="column" />
        <KlaimValue label="Class" value={coverage.schemeClass} direction="column" />
        <KlaimValue label="Class Name" value={coverage.schemeName} direction="column" />
        <KlaimValue label="Policy Number" value={coverage.policyNumber} direction="column" />
        <KlaimValue label="Dependant" value={coverage.dependent} direction="column" />
      </div>

      <div style={{border: 1, borderColor: Colors.pinkLight, borderStyle: 'solid', marginBottom: 10}} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
          marginBottom: 20,
          padding: 10,
        }}
      >
        <KlaimValue label="Relationship" value={coverage.relationship} direction="column" />
        <KlaimValue
          label="Validity Timeframe"
          value={coverage.endOn || ''}
          style={{gridColumn: 'span 2 / span 2'}}
          direction="column"
        />
        <KlaimValue label="Subrogation" value={coverage.subrogation ? 'Yes' : 'No'} direction="column" />
        <KlaimValue label="Network" value={coverage.network} direction="column" />
        <KlaimValue label="Type" value={coverage.type} direction="column" />
      </div>

      <div>
        <div style={{marginBottom: 20}}>
          <CollapsibleContainer costToBenificiaries={coverage.costToBeneficiary} />
        </div>
        {coverage.benefits.length > 0 &&
          coverage.benefits.map((benefit, index) => {
            return (
              <>
                <div style={{marginBottom: 10}} key={index}>
                  <CollapsibleTable coverageDetails={benefit.coverageDetails} />
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default CoveragePlan;
