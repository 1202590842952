import {createUseStyles} from 'react-jss';
import {IRoute} from 'interfaces/route.interface';

export interface ComponentProps {
  routes: IRoute[] | undefined;
  handleResize: () => void;
  sidebarIsOpen: boolean;
}

export const useStylesFromThemeFunction = createUseStyles({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100vh',
  },
  bodyContainerOpen: {
    marginLeft: 205,
    transition: [['all', '0.3s', 'ease']],
  },
  bodyContainerClose: {
    marginLeft: 89,
    transition: [['all', '0.3s', 'ease']],
  },
});
