import {KlaimTheme} from 'interfaces/klaim-theme.interface';
import {Colors} from 'modules/Common/constants/Colors';
import {createUseStyles} from 'react-jss';

export interface ComponentProps {
  resizable?: boolean;
  errorClassName?: string;
  label?: string;
  onChange?: (e: any) => void;
  variant?: 'primary' | 'secondary';
  placeholder?: string;
  optional?: boolean;
  name?: string;
  isError?: boolean;
  isTouched?: boolean;
  value?: string;
}

export const useStylesFromThemeFunction = createUseStyles((theme: KlaimTheme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      '& label': {
        marginLeft: 10,
        fontWeight: 600,
        fontSize: 13,
      },
    },
    textAreaContainer: {
      position: 'relative',
    },
    label: {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 'bold',
    },
    textArea: {
      height: 100,
      border: `${Colors.grayLight} 1px solid`,
      borderRadius: 10,
      padding: 10,
      fontFamily: 'inherit',
      outline: 'none',
      '&:focus': {
        border: `${Colors.purple} 1px solid`,
      },
    },
    error: {
      color: Colors.red,
    },
    validated: {
      color: Colors.lightGreen,
    },
    errorChild: {
      border: `${Colors.red} 1px solid`,
    },
    validatedChild: {
      border: `${Colors.lightGreen} 1px solid`,
    },
    resizeDisabled: {
      resize: 'none',
    },
    textAreaSecondVariant: {
      backgroundColor: theme.formElement.background.secondary,
    },
    labelError: {
      color: theme.formElement.color.error,
    },
    labelValidated: {
      color: theme.formElement.color.validated,
    },
    labelDefault: {
      color: Colors.grayLabel,
    },
  };
});
