import React from 'react';
import * as Yup from 'yup';
import {FormikHelpers} from 'formik';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import helpIcon from 'assets/help_icon.svg';
import {useStylesFromThemeFunction} from 'modules/Auth/v3/features/Forgot/UpdatePassword/UpdatePassword';
import KlaimForm from 'modules/Common/components/KlaimForm';
import KlaimInput from 'modules/Common/components/KlaimInput';
import KlaimSpan from 'modules/Common/components/KlaimSpan';
import KlaimTooltip from 'modules/Common/components/KlaimTooltip';
import Button from 'modules/Common/components/Button';
import {useAppDispatch} from 'modules/App/store';
import Loading from 'modules/Loading';
import {loadingSelect} from 'modules/Auth/v3/features/Loading/loadingSlice';
import {changePasswordRequest, forgotPasswordValidate} from 'modules/Auth/v3/features/Forgot/forgotPasswordSlice';
import ExpiredLink from 'modules/Auth/v3/components/ExpiredLink';
import * as Toast from 'modules/Common/utils/toast';

export interface Values {
  [field: string]: any;
}

const UpdatePassword: React.FC = () => {
  const classes = useStylesFromThemeFunction();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {hash}: any = useParams();
  const {isHashValidating, isValidated} = useSelector(loadingSelect);

  const requirements = [
    {message: 'At least 8 characters - the more characters, the better.'},
    {message: 'A mixture of both uppercase and lowercase letters.'},
    {message: 'Contain mix of letters, numbers, and/or symbols.'},
    {message: 'Cannot start or end with a blank space.'},
  ];

  const initialValues = {
    password: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/,
        'Password must contain 8 characters above and at least one uppercase, one number and one special case character, with no space',
      )
      .required('Password is required'),
  });

  const onSubmit = async (values: Values) => {
    const res = await changePasswordRequest(values, hash);
    if (res.status === 200) {
      Toast.success('Your password is successfuly changed', 'Changed!');
      const to = setTimeout(() => {
        clearTimeout(to);
        history.push('/auth/login');
      }, 1000);
    } else if (res.status === 404 && res.message === 'Not Found') {
      Toast.error('This verification link appears to be invalid', 'Invalid Link');
    } else if (res.status === 404 && res.message !== 'Not Found') {
      Toast.error('This verification link is expired', 'Expired!');
    }
  };

  React.useEffect(() => {
    dispatch(forgotPasswordValidate());
  }, []);

  const UpdatePasswordPage = () => {
    if (isHashValidating === true && isValidated === false) {
      return <Loading />;
    }
    if (isHashValidating === false && isValidated === true) {
      return (
        <div className={classes.updatePassword}>
          <KlaimForm
            customClass={classes.formContainer}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            <div className={classes.headerContainer}>
              <h1>Update your password</h1>
              <p className={classes.message}>
                Type your new password here. Make sure that your password follows the requirements.
              </p>
            </div>
            <div className={classes.passwordContainer}>
              <KlaimInput name="password" label="Password" type="password" />
              <KlaimTooltip requirements={requirements} icon={helpIcon} />
            </div>
            <div className={classes.questionContainer}>
              <p>
                Remembered? <KlaimSpan onClick={() => history.push('/auth')}>Login</KlaimSpan>
              </p>
            </div>
            <div className={classes.footerContainer}>
              <Button title="Update password" type="submit" />
            </div>
          </KlaimForm>
        </div>
      );
    }
    if (isHashValidating === false && isValidated === false) {
      return <ExpiredLink />;
    }
  };

  return <>{UpdatePasswordPage()}</>;
};

export default UpdatePassword;
