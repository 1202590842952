import {createUseStyles} from 'react-jss';
import {Colors} from 'modules/Common/constants/Colors';

export interface IValues {
  [field: string]: any;
}
// export interface Values {
//   [field: string]: any;
// }

export const useStylesFromThemeFunction = createUseStyles(() => {
  return {
    dropDownContainer: {
      width: 420,
      marginLeft: -10,
    },
    bodyWrapper: {
      '& p': {
        color: Colors.blueGrayDark,
        fontSize: '13px',
        width: '450px',
      },
      width: 400,
    },
    label: {
      paddingBottom: '4px',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        fontWeight: 'normal',
        fontSize: '13px',
      },
      fontWeight: 'bold',
      color: Colors.grayLabel,
    },
    emailSent: {
      color: Colors.grayLighter,
      display: 'flex',
      fontSize: '13px',
      alignItems: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
      '& img': {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    wrongEmail: {
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 10,
    },
    error: {
      color: Colors.red,
      fontSize: 12,
      paddingTop: 0,
      paddingBottom: 0,
      '& input': {
        color: 'black',
        borderColor: Colors.red,
        borderWidth: '1px',
      },
    },
    fullWidth: {
      width: 360,
    },
    buttonContainer: {
      width: 360,
      flex: '1 1 0%',
      fontWeight: 600,
      fontSize: 14,
      color: Colors.blueGrayDark,
      display: 'flex',
      alignItems: 'center',
      marginTop: 2,

      '& button': {
        textAlign: 'left',
        border: 'none',
        background: 'none',
        paddingTop: 6,
        paddingBottom: 6,
        margin: 0,
        width: '100%',
        '&:hover': {
          backgroundColor: Colors.blueGrayLight,
          cursor: 'pointer',
        },
      },
    },
    buttonActive: {
      backgroundColor: Colors.blueGrayLight,
    },

    optionText: {whiteSpace: 'nowrap', color: Colors.blueGrayDark, fontWeight: 600},
  };
});
