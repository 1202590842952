import React, {FC} from 'react';
import genderFemaleIcon from 'assets/gender_female_icon.svg';
import genderMaleIcon from 'assets/gender_male_icon.svg';
import genderOtherIcon from 'assets/gender_other_icon.svg';
import genderUnknownIcon from 'assets/gender_unknown_icon.svg';

interface ComponentProps {
  gender: 'male' | 'female' | 'other' | 'unknown' | undefined | string;
  className?: string;
}

const GenderIcon: FC<ComponentProps> = ({gender, className}) => {
  const patientGender = (g?: string) => {
    switch (g) {
      case 'male':
        return genderMaleIcon;
      case 'female':
        return genderFemaleIcon;
      case 'other':
        return genderOtherIcon;
      case 'unknown':
        return genderUnknownIcon;
      default:
        return '';
    }
  };

  return <img src={patientGender(gender)} alt="gender" className={className} />;
};

export default GenderIcon;
