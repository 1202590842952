import React, {FC} from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimForm,
  KlaimInput,
} from '../../../../../Common/components-v2/KlaimForm';
import doctor from '../../../../../../assets/doc.jpg';
import KlaimDatePicker from '../../../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
import {useStylesFromThemeFunction} from './ClaimForm';
import Section from './Section';
import DropdownOptions, {
  MidtableGenders,
  MidtableMaritalStatuses,
} from '../../../../../Common/constants/DropdownOptions';
import {checkErroredFields} from '..';

const Patient: FC<any> = ({data, coverage, providerId, nationalities, submissionData, setSubmission, errors}) => {
  const classes = useStylesFromThemeFunction();
  const updateSubmission = (field, value) => {
    setSubmission((submission) => ({
      ...submission,
      patient: {...submission.patient, [field]: value},
    }));
  };

  const updateClaim = (field, value) => {
    setSubmission((submission) => ({
      ...submission,
      claim: {...submission.claim, [field]: value},
    }));
  };

  const updateCoverageMember = (field, value) => {
    setSubmission((submission) => ({
      ...submission,
      coverage: {
        ...submission.coverage,
        member: {
          ...submission.coverage?.member,
          id: value,
        },
      },
    }));
  };

  const updateCoverage = (field, value) => {
    setSubmission((submission) => ({
      ...submission,
      coverage: {
        ...submission.coverage,
        [field]: value,
      },
    }));
  };

  const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');

  return (
    <Section title={'Patient Information'} img={doctor}>
      <KlaimForm
        enableReinitialize
        initialValues={{
          name: data?.name,
          gender: data?.gender,
          dateOfBirth: data?.dateOfBirth,
          maritalStatus: data?.maritalStatus,
          documentId: data?.documentId,
          documentIdType: data?.documentIdType,
          authorizationOfflineDate: data?.authorizationOfflineDate,
          relatedToPa: submissionData?.claim?.relatedToPa,
          memberId: coverage?.member?.id,
          policyNo: coverage?.policyNo,
        }}
        validationSchema={Yup.object({
          name: Yup.string(),
          gender: Yup.string(),
          dateOfBirth: Yup.string(),
          maritalStatus: Yup.number(),
          documentId: Yup.string(),
          documentIdType: Yup.string(),
        })}
        onSubmit={() => {}}
        id="patient-form"
        customClass={classes.form}
      >
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            name="name"
            onChange={(e) => {
              updateSubmission('name', e);
            }}
            className={classes.respFieldWrapper}
            style={{flex: '1 1 6%'}}
          >
            <KlaimInput variant="secondary" placeholder="1331000003" label="Full Name" />
          </KlaimFieldWrapper>

          <KlaimFieldWrapper
            name={'gender'}
            onChange={(e) => {
              updateSubmission('gender', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimDropdownSearch
              variant="secondary"
              label={'Gender'}
              options={MidtableGenders}
              placeholder={'Select Gender'}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name={'dateOfBirth'}
            onChange={(e) => {
              updateSubmission('dateOfBirth', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimDatePicker label="Date of Birth" name="dateOfBirth" variant="secondary" maxDate={new Date()} />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name={'maritalStatus'}
            onChange={(e) => {
              updateSubmission('maritalStatus', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimDropdownSearch
              variant="secondary"
              label={'Marital'}
              options={MidtableMaritalStatuses}
              placeholder={'Select Marital'}
            />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            name={'documentId'}
            onChange={(e) => {
              updateSubmission('documentId', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimInput variant="secondary" label="Document Id" placeholder="xxx" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name={'documentIdType'}
            onChange={(e) => {
              updateSubmission('documentIdType', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimDropdownSearch
              variant="secondary"
              label={'ID Type'}
              options={DropdownOptions.idType}
              placeholder={'Select ID Type'}
            />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            name={'relatedToPa'}
            onChange={(e) => {
              updateClaim('relatedToPa', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimInput
              variant="secondary"
              label="Pre-Auth Ref"
              placeholder="xxx"
              hasError={checkErroredFields(errors, 'preAuth')}
            />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name={'authorizationOfflineDate'}
            onChange={(e) => {
              updateClaim('authorizationOfflineDate', formatDate(e));
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimDatePicker
              label="Pre-Auth Ref Date"
              name="authorizationOfflineDate"
              variant="secondary"
              maxDate={new Date()}
              hasError={checkErroredFields(errors, 'preAuth')}
            />
          </KlaimFieldWrapper>
        </div>
        <div className={classes.respRow}>
          <KlaimFieldWrapper
            name={'memberId'}
            onChange={(e) => {
              updateCoverageMember('memberId', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimInput variant="secondary" label="Member ID" placeholder="xxx" />
          </KlaimFieldWrapper>
          <KlaimFieldWrapper
            name={'policyNo'}
            onChange={(e) => {
              updateCoverage('policyNo', e);
            }}
            className={classes.respFieldWrapper}
          >
            <KlaimInput variant="secondary" label="Policy Number" placeholder="xxx" />
          </KlaimFieldWrapper>
        </div>
      </KlaimForm>
    </Section>
  );
};

export default Patient;
