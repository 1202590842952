import React, {useEffect} from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import _, {isEmpty} from 'lodash';
import {RouteComponentProps} from 'react-router-dom';
import {FormikHelpers} from 'formik';
import 'react-phone-number-input/style.css';
import {IOptions} from 'interfaces/general.interfaces';
import {Branch} from 'interfaces/branches.interfaces';
import CollapsibleSection from 'modules/Common/components/CollapsibleSection';
import {useStylesFromThemeFunction, Values} from 'modules/Eligibility/features/EligibilityForm/EligibilityForm';
import DropdownOptions from 'modules/Common/constants/DropdownOptions';
import {useAppDispatch, useAppSelector} from 'modules/App/store';
import {
  checkEligibility,
  eligibilityCleanup,
  eligibilityErrorCleanup,
  eligibilitySelect,
  eligibilitySuccessCleanup,
  getEligibilityForm,
} from 'modules/Eligibility/eligibilitySlice';
import EligibilityDetails from 'modules/Eligibility/features/EligibilityDetails';
import {insuranceSelect, getInsurances} from 'modules/Insurance/insuranceSlice';
import {error as errorToast} from 'modules/Common/utils/toast';
import {
  KlaimForm,
  KlaimToggle,
  KlaimButton,
  KlaimDropdownSearch,
  KlaimFieldWrapper,
  KlaimInput,
} from 'modules/Common/components-v2/KlaimForm';
import KlaimContainer from 'modules/Common/components-v2/KlaimContainer';
import {
  branchManagementSelect,
  getUserCurrentBranches,
} from 'modules/Settings/features/BranchManagement/branchManagementSlice';
import {Payer} from 'interfaces/payers.interfaces';
import EligibilityFormModal from './EligibilityFormModal';
import EligibilityFormPrompt from '../../components/EligiblityFormPrompt';
import KlaimDatePicker from '../../../Common/components-v2/KlaimForm/components/KlaimDatePicker';
// import HipdInput from '../../../Hipd/features/HipdSearchInput';
// import {hipdSelect} from '../../../Hipd/hipdSlice';
import PatientSelect from '../../../Patient/features/PatientSelect';
import {IPatient} from '../../../../interfaces/patient.interface';
import {setNavButton} from '../../../Header/headerSlice';
import {
  getInsurances as getInsurancesM,
  insurancesManagementSelect,
} from '../../../Settings/features/Insurance/InsuranceSlice';

interface RouteParams {
  id: string;
}

const EligibilityForm: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: {id},
  },
}) => {
  const classes = useStylesFromThemeFunction();
  const [idTypeValue, setIdTypeValue] = React.useState<string>('');
  const [patientName, setPatientName] = React.useState<string>('');
  const [maritalStatus, setMaritalStatus] = React.useState<string>('');
  const [mobileNumber, setMobileNumber] = React.useState<string>('');
  const [dateOfBirth, setDateOfBirth] = React.useState<string>('');
  const [gender, setGender] = React.useState<string>('');
  const [documentId, setDocumentId] = React.useState<string>('');
  const [patientRel, setPatientRel] = React.useState<string>('');
  const [referral, setReferral] = React.useState<boolean>(false);
  const [newBorn, setNewBorn] = React.useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = React.useState<Branch>();
  const [branchOptions, setBranchOptions] = React.useState<IOptions[]>([]);
  const [insuranceOptions, setInsuranceOptions] = React.useState<IOptions[]>([]);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {isEligibilityCheckSuccess, editEligibility, eligibility, error} = useAppSelector(eligibilitySelect);
  const {payers} = useAppSelector(insuranceSelect);
  const {userBranches} = useAppSelector(branchManagementSelect);
  // const {hipd} = useAppSelector(hipdSelect);
  const [selectedInsurance, setSelectedInsurance] = React.useState<Payer>();
  const [selectedTPA, setSelectedTPA] = React.useState(null);
  // const [selectedAddressUse, setSelectedAddressUse] = React.useState();
  const [patient, setPatient] = React.useState<IPatient>();
  const TPA = ['7001463160', '7001580856', '7001604342', '7001599658'];
  // TODO: use object instead of string to store hcpcode of insurance and tpa
  const insuranceCodeTpaDelimiter = '_____';

  React.useEffect(() => {}, [selectedTPA]);

  React.useEffect(() => {
    if (userBranches) {
      setBranchOptions(
        userBranches.map((branch) => {
          return {
            label: branch.name,
            value: branch.identifier,
          };
        }),
      );
    }

    if (userBranches.length > 0 && id) {
      dispatch(getEligibilityForm({id, providerId: userBranches.map((provider) => provider.identifier).join(',')}));
    }
    dispatch(setNavButton(false));
  }, [userBranches, id]);

  React.useEffect(() => {
    if (userBranches.length === 1) {
      setSelectedBranch(userBranches[0]);
    }
  }, [userBranches]);

  useEffect(() => {
    dispatch(
      getInsurancesM({
        limit: 100,
        offset: 0,
        startDate,
        endDate,
        providerId: selectedBranch ? selectedBranch.identifier : '',
      }),
    );
  }, [selectedBranch]);
  const {
    insurances: {data: insurances},
  } = useAppSelector(insurancesManagementSelect);
  React.useEffect(() => {
    if (insurances && insurances.length > 0) {
      setInsuranceOptions(
        insurances.map((payer: any) => {
          let value = payer.hcpCode;
          if (payer.hasTpa) {
            value = `${value}${insuranceCodeTpaDelimiter}${payer.tpas[0].hcpCode}`;
          }
          return {
            label: `${payer.longName} ${payer.hasTpa ? `(${payer.tpas[0].name})` : ''}`,
            value,
          };
        }),
      );
    }
  }, [insurances]);
  // React.useEffect(() => {
  //   if (payers && payers.data.length > 0) {
  //     setInsuranceOptions(
  //       payers.data.map((payer: Payer) => {
  //         return {
  //           label: payer.name,
  //           value: payer.payerCode,
  //         };
  //       }),
  //     );
  //   }
  // }, [payers]);

  React.useEffect(() => {
    console.log(error);
    if (error) {
      errorToast(error.message);
      // dispatch(eligibilityErrorCleanup());
    }
  }, [error]);

  React.useEffect(() => {
    dispatch(getUserCurrentBranches({}));
    dispatch(getInsurances({authorityCode: 'NPHIES', limit: 300}));
    return () => {
      dispatch(eligibilityCleanup());
      dispatch(eligibilitySuccessCleanup());
      dispatch(eligibilityErrorCleanup());
    };
  }, []);

  React.useEffect(() => {
    if (editEligibility && userBranches) {
      setIdTypeValue(editEligibility?.patient.documentIdType || '');
      setSelectedBranch(userBranches.find((branch) => branch.identifier === editEligibility?.tenantId));
    }
  }, [editEligibility]);

  const isTpaRequired = (insurance: any) => {
    if (insurance && insurance.tpas && insurance.tpas.length > 0) {
      return true;
    }
    return false;
  };

  const validationSchema = Yup.object({
    idType: Yup.string().required('ID type is required.'),
    idNumber: Yup.string()
      .when('idType', {
        is: (idType) => idType === 'NI',
        then: Yup.string()
          .required('I am required now the checkbox is checked')
          .matches(/\b[1]\d{9}\b/, 'National ID should be 10 digits starting with 1'),
      })
      .when('idType', {
        is: (idType) => idType === 'PRC',
        then: Yup.string()
          .required('I am required now the checkbox is checked')
          .matches(/\b[2]\d{9}\b/, 'Permanent resident number should be 10 digits starting with 2'),
      })
      .when('idType', {
        is: (idType) => idType === 'BN',
        then: Yup.string()
          .required('I am required now the checkbox is checked')
          .matches(/\b[3]\d{9}\b/, 'Border number should be 10 digits starting with 2'),
      }),
    providerId: Yup.string().required('Branch is required.'),
    patient: Yup.object({
      // firstName: Yup.string().required('Firstname is required.'),
      // middleName: Yup.string(),
      // lastName: Yup.string().required('Lastname is required.'),
      name: Yup.string().required('Name is required.'),
      dateOfBirth: Yup.string().required('Date of birth is required.'),
      gender: Yup.string().required('Gender is required.'),
      maritalStatus: Yup.string().required('Civil status is required.'),
      // mobileNumber: Yup.string().required('Mobile number is required.'),
      // address: Yup.object({
      //   use: Yup.string(),
      //   text: Yup.string().when('use', {
      //     is: (use) => use && use.length > 0,
      //     then: Yup.string().required('Address is required'),
      //   }),
      //   country: Yup.string().when('use', {
      //     is: (use) => use && use.length > 0,
      //     then: Yup.string().required('Country is required'),
      //   }),
      //   city: Yup.string().when('use', {
      //     is: (use) => use && use.length > 0,
      //     then: Yup.string().required('City is required'),
      //   }),
      // }),
    }),
    // insurance: Yup.string().required('Insurance is required.'),
    // tpa: Yup.string().when('insurance', {
    //   is: (insurance) => isTpaRequired(insurance),
    //   then: Yup.string().required('TPA is required'),
    // }),
    // start: Yup.date().required('Start date is required.'),
    // end: Yup.date().required('End date is required.'),
    referral: Yup.boolean(),
  });

  const getInitialBranch = () => {
    if (editEligibility?.tenantId && !selectedBranch) return editEligibility?.tenantId;
    if (selectedBranch) return selectedBranch.identifier;
    return undefined;
  };

  const getInitialIdType = () => {
    if (editEligibility?.patient.documentIdType && !idTypeValue) return editEligibility?.patient.documentIdType;
    if (idTypeValue) return idTypeValue;
    return 'NI';
  };

  const getInitialMaritalStatus = () => {
    if (editEligibility && !maritalStatus) return editEligibility?.patient.maritalStatus;
    if (patient && !maritalStatus) return patient.maritalStatus;
    if (maritalStatus) return maritalStatus;
    return '';
  };

  const getInitialDateOfBirth = () => {
    if (editEligibility && !dateOfBirth) return editEligibility?.patient.dateOfBirth;
    if (patient && patient.dateOfBirth && !dateOfBirth) return new Date(patient.dateOfBirth);
    if (dateOfBirth) return dateOfBirth;
    return '';
  };

  const getIntitialMobileNumber = () => {
    if (editEligibility && !mobileNumber) return editEligibility?.patient.phoneNumber;
    if (patient && !mobileNumber) return patient.phoneNumber;
    if (mobileNumber) return mobileNumber;
    return '';
  };

  const getInitialInsurance = () => {
    if (editEligibility && !selectedInsurance && payers) {
      const editInsurance = payers.data.find((payer) => payer.payerCode === editEligibility?.payerId);
      setSelectedInsurance(editInsurance);
      return editInsurance;
    }
    if (selectedInsurance) return selectedInsurance.payerCode;
    return '';
  };

  const getInitialGender = () => {
    if (editEligibility && !gender) return editEligibility?.patient.gender;
    if (patient && !gender) return patient.gender;
    if (gender) return gender;
    return '';
  };

  const getInitialName = () => {
    if (editEligibility && !patient && patientName) return editEligibility?.patient.name;
    if (patient && !patientName) return patient.name;
    if (patientName) return patientName;
    return '';
  };

  const getIdNumber = () => {
    if (editEligibility && !patient && patientName) return editEligibility?.patient.documentId;
    if (patient && !documentId) return patient.documentId;
    if (documentId) return documentId;
    return '';
  };

  const getNewBorn = () => {
    if (editEligibility && !patient) return editEligibility?.patient.newBorn;
    if (patient && patient.newBorn && !newBorn) return patient.newBorn;
    if (newBorn) return newBorn;
    return false;
  };

  const getReferral = () => {
    if (editEligibility && !patient) return editEligibility?.referral;
    if (referral) return referral;
    return false;
  };

  const dateNow = new Date();
  const initialValues = {
    idType: getInitialIdType(),
    idNumber: getIdNumber(),
    providerId: getInitialBranch(),
    patient: {
      newBorn: getNewBorn(),
      // firstName: (patientName && patientName[0]) || '',
      // middleName: (patientName && patientName[1]) || '',
      // lastName: (patientName && patientName[2]) || '',
      name: getInitialName(),
      dateOfBirth: getInitialDateOfBirth(),
      gender: getInitialGender(),
      maritalStatus: getInitialMaritalStatus(),
      mobileNumber: getIntitialMobileNumber(),
      // address: {
      //   use: editEligibility?.patient.address?.use || '',
      //   text: editEligibility?.patient.address?.text || '',
      //   country: editEligibility?.patient.address?.country || '',
      //   city: editEligibility?.patient.address?.city || '',
      // },
    },
    insurance: getInitialInsurance(),
    tpa: editEligibility?.payerId,
    start: dateNow,
    end: dateNow,
    referral: getReferral(),
  };

  React.useEffect(() => {
    if (initialValues.idNumber) {
      if (initialValues.idNumber.startsWith('2')) {
        setIdTypeValue('PRC');
      } else {
        setIdTypeValue('NI');
      }
    }
  }, [initialValues.idNumber]);

  const getInsuranceName = () => {
    return selectedInsurance ? selectedInsurance?.name : '';
  };

  const getInsuranceCode = () => {
    return selectedInsurance ? selectedInsurance?.payerCode : '';
  };

  const onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<{
      [field: string]: any;
    }>,
  ) => void | Promise<any> = (values: Values) => {
    // const {firstName, middleName, lastName, ...rest} = values.patient;
    const {name, ...rest} = values.patient;
    let tpaSelectedTpa = null;
    let tpaSelectedInsurance = selectedTPA;
    if (selectedTPA.includes(insuranceCodeTpaDelimiter)) {
      // eslint-disable-next-line prefer-destructuring
      tpaSelectedInsurance = selectedTPA.split(insuranceCodeTpaDelimiter)[0];
      // eslint-disable-next-line prefer-destructuring
      tpaSelectedTpa = selectedTPA.split(insuranceCodeTpaDelimiter)[1];
    }
    const insurance = insurances.find((el: any) =>
      tpaSelectedTpa
        ? el.hcpCode === tpaSelectedInsurance && el.tpas?.[0]?.hcpCode === tpaSelectedTpa
        : el.hcpCode === tpaSelectedInsurance && !el.hasTpa,
    ) as any;
    let payerId;
    let receiverId;
    if (insurance?.hasTpa) {
      payerId = insurance?.hcpCode;
      receiverId = insurance?.tpas[0].hcpCode;
    } else {
      payerId = insurance?.hcpCode;
      receiverId = insurance?.hcpCode;
    }
    const payload: any = {
      patient: {
        newBorn: rest.newBorn,
        name,
        dateOfBirth: dayjs(rest.dateOfBirth).format('YYYY-MM-DD'),
        gender: rest.gender,
        documentId: values.idNumber,
        documentIdType: values.idType,
        maritalStatus: rest.maritalStatus,
        phone: rest.mobileNumber,
      },
      senderName: selectedBranch ? selectedBranch.name : '',
      payerName: tpaSelectedInsurance ? getPayerName(tpaSelectedInsurance) : getInsuranceName(),
      // receiverId: selectedTPA,
      // payerId: selectedInsurance ? getInsuranceCode() : selectedTPA,
      payerId,
      receiverId,
      serviceStartDate: dayjs(values.start).format('YYYY-MM-DD'),
      serviceEndDate: dayjs(values.end).format('YYYY-MM-DD'),
      providerId: selectedBranch ? selectedBranch.identifier : '',
      referral: values.referral,
      patientRel,
    };

    // if (rest.address.country) {
    //   payload.patient.address = {
    //     use: rest.address.use,
    //     text: rest.address.text,
    //     country: rest.address.country,
    //     city: rest.address.city,
    //   };
    // }

    dispatch(eligibilityErrorCleanup());
    dispatch(checkEligibility(payload));
    setIsModalOpen(true);
  };

  const onChangeType = (value: any) => {
    setIdTypeValue(value);
  };

  const startDateChange = (date: any) => {
    setStartDate(date);
  };

  const endDateChange = (date: any) => {
    setEndDate(date);
  };

  const handleInsuranceChange = (value: any) => {
    setSelectedInsurance(payers.data.find((payer) => payer.payerCode === value));
  };

  const getPayerName = (tpa: any) => {
    if (payers) {
      const payerResult = payers.data.find((payer: Payer) => payer.payerCode === tpa);
      if (payerResult) {
        return payerResult.name;
      }
    }
    return '';
  };

  const getSelectedTpa = () => {
    const filtered = payers.data.filter((pd) => pd.payerCode === selectedTPA);
    if (filtered && filtered.length > 0) {
      return filtered[0];
    }
    return null;
  };

  return (
    <>
      {(editEligibility || !id) && (
        <KlaimForm
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          style={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}
          id="eligibility-form"
        >
          <CollapsibleSection
            collapsed
            renderHeader={() => {
              return (
                <div
                  className={classes.headerContainer}
                  style={{zIndex: 100, paddingTop: 20, paddingBottom: 20, paddingRight: 20}}
                >
                  <h1>Patient Data</h1>
                  <KlaimButton type="submit" style={{fontWeight: 600}}>
                    Check Eligibility
                  </KlaimButton>
                </div>
              );
            }}
            renderContent={() => {
              return (
                <div
                  className={classes.container}
                  style={{padding: 20, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, minHeight: 500}}
                >
                  <div>
                    <div className={classes.labelContainer}>
                      <h2>Identify</h2>
                    </div>
                    <div className={classes.formContainer}>
                      <div className={classes.inputContainer}>
                        {/* <HipdInput /> */}
                        {userBranches.length > 1 && (
                          <KlaimFieldWrapper
                            name={'providerId'}
                            onChange={(value: any) => {
                              setSelectedBranch(userBranches.find((branch) => value === branch.identifier));
                            }}
                          >
                            <KlaimDropdownSearch
                              options={branchOptions}
                              placeholder={'Select Branch'}
                              name="patient"
                              label="Choose Branch"
                              variant={'secondary'}
                            />
                          </KlaimFieldWrapper>
                        )}
                        {selectedBranch && (
                          <PatientSelect
                            label="Search Patient"
                            branch={selectedBranch.identifier}
                            isEligiblity
                            initialValue={editEligibility ? editEligibility.patient : undefined}
                            onChange={(patientValue, insurance) => {
                              setDocumentId('');
                              setNewBorn(false);
                              setReferral(false);
                              setGender('');
                              setMobileNumber('');
                              setMaritalStatus('');
                              setPatientName('');
                              setPatient(patientValue);
                              // setSelectedInsurance(insurance.)
                            }}
                            isHidden={!isEmpty(editEligibility)}
                          />
                        )}
                        {selectedBranch && patient && (
                          <>
                            <KlaimFieldWrapper name={'idType'} onChange={onChangeType}>
                              <KlaimDropdownSearch
                                variant="secondary"
                                label={'ID Type'}
                                options={DropdownOptions.idType}
                                placeholder={'Select ID Type'}
                              />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper name="idNumber" onChange={(data) => setDocumentId(data)}>
                              <KlaimInput variant="secondary" placeholder="13 - 31 - 00 - 00 - 03" label="ID Number" />
                            </KlaimFieldWrapper>
                            <div
                              style={{display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '1rem'}}
                            >
                              <KlaimContainer variant="primary-outline" style={{padding: 8}}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <label style={{fontWeight: 600, fontSize: 13, color: '#222b45'}}>
                                    Patient is a new born
                                  </label>
                                  <KlaimFieldWrapper name="patient.newBorn" onChange={(data) => setNewBorn(data)}>
                                    <KlaimToggle />
                                  </KlaimFieldWrapper>
                                </div>
                              </KlaimContainer>
                              <KlaimContainer variant="primary-outline" style={{padding: 8}}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <label style={{fontWeight: 600, fontSize: 13, color: '#222b45'}}>Transfer</label>
                                  <KlaimFieldWrapper name="referral" onChange={(data) => setReferral(data)}>
                                    <KlaimToggle />
                                  </KlaimFieldWrapper>
                                </div>
                              </KlaimContainer>
                            </div>
                            {newBorn && (
                              <KlaimFieldWrapper name="patientRel" onChange={(data) => setPatientRel(data)}>
                                <KlaimInput
                                  variant="secondary"
                                  placeholder="13 - 31 - 00 - 00 - 03"
                                  label="patientRel"
                                />
                              </KlaimFieldWrapper>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {patient && selectedBranch && (
                    <>
                      <div>
                        <div className={classes.labelContainer}>
                          <h2>Patient Information</h2>
                        </div>
                        <div className={classes.formContainer}>
                          <div className={classes.inputContainer}>
                            {/* <KlaimFieldWrapper name="patient.firstName">
                              <KlaimInput variant="secondary" label="Name" placeholder="Name" />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper name="patient.middleName">
                              <KlaimInput variant="secondary" label="Middle Name" optional placeholder="Middle Name" />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper name="patient.lastName">
                              <KlaimInput variant="secondary" label="Last Name" placeholder="Surname" />
                            </KlaimFieldWrapper> */}
                            <KlaimFieldWrapper name="patient.name" onChange={(data) => setPatientName(data)}>
                              <KlaimInput variant="secondary" label="Name" placeholder="Name" />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper name="patient.dateOfBirth" onChange={(data) => setDateOfBirth(data)}>
                              <KlaimDatePicker
                                label="Date of Birth"
                                name="dateOfBirth"
                                variant="secondary"
                                maxDate={new Date()}
                              />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper name={'patient.gender'} onChange={(data) => setGender(data)}>
                              <KlaimDropdownSearch
                                label={'Gender'}
                                options={DropdownOptions.gender}
                                placeholder={'Select Gender'}
                                variant={'secondary'}
                              />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper
                              name={'patient.maritalStatus'}
                              onChange={(data) => setMaritalStatus(data)}
                            >
                              <KlaimDropdownSearch
                                variant={'secondary'}
                                label={'Marital Status'}
                                options={DropdownOptions.maritalStatus}
                                placeholder={'Select Marital Status'}
                              />
                            </KlaimFieldWrapper>
                            <KlaimFieldWrapper name="patient.mobileNumber" onChange={(data) => setMobileNumber(data)}>
                              <KlaimInput
                                variant="secondary"
                                type="phone"
                                label="Mobile Number"
                                defaultCountry="SA"
                                placeholder="+966 000 XXX XXXX"
                              />
                            </KlaimFieldWrapper>
                            {/* <KlaimFieldWrapper
                              name={'patient.address.use'}
                              onChange={(value: any) => setSelectedAddressUse(value)}
                            >
                              <KlaimDropdownSearch
                                variant={'secondary'}
                                label={'Address Type'}
                                options={DropdownOptions.addressType}
                                placeholder={'Select Address Type'}
                                isOptional
                              />
                            </KlaimFieldWrapper> */}
                            {/* {selectedAddressUse && (
                              <>
                                <KlaimFieldWrapper name="patient.address.country">
                                  <KlaimDropdownSearch
                                    label="Country"
                                    placeholder="Country"
                                    variant="secondary"
                                    options={country}
                                    isOptional
                                  />
                                </KlaimFieldWrapper>
                                <KlaimFieldWrapper name="patient.address.city">
                                  <KlaimInput label="City" placeholder="City" variant="secondary" optional />
                                </KlaimFieldWrapper>
                                <KlaimFieldWrapper name="patient.address.text">
                                  <KlaimInput label="Address" placeholder="Address" variant="secondary" optional />
                                </KlaimFieldWrapper>
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={classes.labelContainer}>
                          <h2>Select Insurance</h2>
                        </div>
                        <div className={classes.formContainer}>
                          <div className={classes.inputContainer}>
                            <KlaimFieldWrapper name={'tpa'} onChange={setSelectedTPA}>
                              <KlaimDropdownSearch
                                variant="secondary"
                                label="Insurance/TPA"
                                options={insuranceOptions}
                              />
                            </KlaimFieldWrapper>
                            {selectedTPA && TPA.includes(`${selectedTPA}`) && (
                              <KlaimFieldWrapper name={'insurance'} onChange={handleInsuranceChange}>
                                <KlaimDropdownSearch
                                  variant="secondary"
                                  label="Insurance"
                                  options={payers.data
                                    .filter((el) => !TPA.includes(el.payerCode))
                                    .map((tpa: any) => {
                                      return {
                                        label: tpa.name,
                                        value: tpa.payerCode,
                                      };
                                    })}
                                />
                              </KlaimFieldWrapper>
                            )}
                            {/*  <div
                              style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'}}
                            >
                              <KlaimFieldWrapper name="start" onChange={startDateChange}>
                                <KlaimDatePicker
                                  label="Start Date"
                                  name="start"
                                  variant="secondary"
                                  minDate={new Date()}
                                  maxDate={endDate || new Date()}
                                />
                              </KlaimFieldWrapper>
                              <KlaimFieldWrapper name="end" onChange={endDateChange}>
                                <KlaimDatePicker
                                  label="End Date"
                                  name="end"
                                  variant="secondary"
                                  minDate={startDate}
                                  maxDate={new Date()}
                                />
                              </KlaimFieldWrapper>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }}
          />
          <KlaimFieldWrapper name={'prompt'}>
            <EligibilityFormPrompt />
          </KlaimFieldWrapper>
        </KlaimForm>
      )}

      <EligibilityFormModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        eligibility={eligibility}
        isEligibilityCheckSuccess={isEligibilityCheckSuccess}
        selectedTPA={getSelectedTpa()}
      />
      {/*{editEligibility && <EligibilityDetails eligibility={editEligibility} />}*/}
    </>
  );
};

export default EligibilityForm;

